<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new dashboard-header__new--wide" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon> {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <button
                    @click="newProduct"
                    class="btn btn-secondary"
                    type="button">
                    <md-icon>add_circle</md-icon> {{ $t('product.page.list.button.create') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="product-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="productListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                    </div>

                    <custom-filter
                        v-if="productListTypes.length > 0"
                        :options="productListTypes"
                        :value="productListActiveFilters.type"
                        :label="''"
                        :dropdown-size="340"
                        :component-size="340"
                        @selected="filterType"
                    ></custom-filter>

                    <button
                        v-if="true"
                        style="margin-left:15px"
                        @click="goToProductVisibilityPage"
                        class="btn btn-secondary"
                        type="button">Termékek láthatósága
                    </button>
                </div>

                <div class="product-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <product-admin-pages selected-page="ProductList"></product-admin-pages>
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <custom-table
                ref="table"
                :columns="tableColumns"
                :pages="productListPages"
                :meta="productListMeta"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    {{ props.formattedRow[props.column.field] }}
                    <div class="col-options" v-if="props.column.field === 'options'">
                        <template v-if="props.row.actions.length > 0">
                            <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                                <md-icon class="element-options">more_vert</md-icon>
                            </button>

                            <dropdown
                                @clicked="onDropdownClick($event, props.page)"
                                :open="props.row.showDropdown"
                                :options="props.row.actions"
                                :elementId="props.row.id"
                            ></dropdown>
                        </template>
                    </div>
                </template>
                <div slot="empty_state">{{ $t('product.page.list.text.empty_state') }}</div>
            </custom-table>
        </div>

        <modal-product-add-select
            :open="openProductAddSelectModal"
            @close="productAddSelectEnd"
            @selected="productAddSelected">
        </modal-product-add-select>

        <modal-product-edit
            :open="openProductModal"
            :productId="selectedProduct"
            :productNumber="selectedProductNumber"
            @opened="productEditOpened"
            @close="productEditEnd">
        </modal-product-edit>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="productDelete"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationRestoreModal"
            :data="confirmationRestoreModalData"
            @accepted="productRestore"
            @close="openConfirmationRestoreModal = false"
        ></modal-confirmation>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalProductEdit from '../components/modals/ModalProductEdit.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import CustomTable from '../components/CustomTable'
import ReAuthenticate from '../components/auth/ReAuthenticate.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ModalProductAddSelect from '../components/modals/ModalProductAddSelect.vue'
import ProductAdminPages from "@/components/sidebars/ProductAdminPages";

Vue.use(MdIcon)

export default {
    name: 'ProductList',

    data() {
        return {
            loading: true,
            openProductModal: false,
            openProductAddSelectModal: false,
            selectedProduct: null,
            selectedProductNumber: null,
            openGroupListDropdown: false,
            productGroups: [],

            openConfirmationDeleteModal: false,
            openConfirmationRestoreModal: false,
            confirmationProductId: null,

            confirmationDeleteModalData: {
                acceptText: this.$t('product.page.list.confirmation.delete.ok'),
                cancelText: this.$t('product.page.list.confirmation.delete.cancel'),
                message: this.$t('product.page.list.confirmation.delete.question')
            },

            confirmationRestoreModalData: {
                acceptText: this.$t('product.page.list.confirmation.restore.ok'),
                cancelText: this.$t('product.page.list.confirmation.restore.cancel'),
                message: this.$t('product.page.list.confirmation.restore.question')
            },

            searchTimer: null,

            tableColumns: [
                {
                    label: this.$t('product.page.list.column.product_number'),
                    field: 'productNumber',
                    width: '5%'
                },
                {
                    label: this.$t('product.page.list.column.name'),
                    field: 'name',
                    width: '25%',
                },
                {
                    label: this.$t('product.page.list.column.type'),
                    field: 'type',
                    width: '15%'
                },
                {
                    label: this.$t('product.page.list.column.price'),
                    field: 'price',
                    width: '15%'
                },
                {
                    label: '',
                    field: 'options',
                    width: '5%'
                }
            ]
        }
    },

    watch: {
        '$route'() {
            this.refresh()
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.commit('resetProducts');
                this.fetchProducts()
            } else {
                this.$router.push('/');
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    methods: {
        filterType(e) {
            this.$store.commit('setProductListActiveFilters', {
                ...this.productListActiveFilters,
                type: e.id
            })
            this.refresh()
        },

        onSearchPhraseChanged(phrase) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.productListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setProductListActiveFilters', {
                    ...this.productListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_PRODUCTS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeProductListPage', page)
        },

        onRowClick(params) {
            if (!params.row.showDropdown && !params.event.target.className.includes('element-options')) {
                this.selectedProductNumber = null
                this.selectedProduct = params.row.id
                this.openProductModal = true
            }
        },

        onDropdownClick(val) {
            if (val.action === 'product-delete'){
                this.confirmationProductId = val.id
                this.openConfirmationDeleteModal = true
            } else if (val.action === 'product-restore') {
                this.confirmationProductId = val.id
                this.openConfirmationRestoreModal = true
            }
        },

        newProduct() {
            this.selectedProduct = null
            this.selectedProductNumber = null
            this.openProductAddSelectModal = true
        },

        productAddSelectEnd(refresh) {
            this.openProductAddSelectModal = false
            if (refresh) {
                this.refreshPages()
            }
        },

        productAddSelected(data) {
            this.selectedProduct = null
            this.selectedProductNumber = data.productNumber
            this.openProductModal = true
        },

        productEditOpened() {
            this.openProductAddSelectModal = false
        },

        productEditEnd(refresh) {
            this.openProductModal = false
            if (refresh) {
                this.refreshPages()
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        goToProductVisibilityPage() {
            //this.$store.commit('setDealerListScrollPosition', this.$refs.table.getScrollPosition())
            this.$router.push({name: 'ProductVisibilityPage'})
        },

        selectedProductType(payload) {
            this.openProductModal = false
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_PRODUCTS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },

        refreshPages() {
            this.loading = true
            this.$store.dispatch('FETCH_PRODUCT_PAGES').then(() => {
                this.loading = false
            })
        },

        fetchProducts() {
            if (this.userHasProductAdmin) {
                this.loading = true
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.$store.dispatch('FETCH_PRODUCTS', {page: 1, keepOtherPages: false}).then(() => {
                        this.loading = false
                    })
                })
            }
        },

        productDelete() {
            let payload = {
                id: this.confirmationProductId
            }

            this.$store.dispatch('DELETE_PRODUCT', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.delete.notification.success')])
                this.confirmationProductId = null
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteModal = false
            })
        },

        productRestore() {
            let payload = {
                id: this.confirmationProductId
            }

            this.$store.dispatch('RESTORE_PRODUCT', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.restore.notification.success')])
                this.confirmationProductId = null
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.restore.notification.failed')])
            }).finally(() => {
                this.openConfirmationRestoreModal = false
            })
        }

    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        searchData() {
            return {
                placeholder: this.$t('product.page.list.search.placeholder'),
                tooltipText: this.$t('product.page.list.search.tooltip'),
                countText: this.$tc('product.page.list.search.name', this.productListMeta.total)
            }
        },

        ...mapGetters(['productListPages', 'productListMeta', 'productListTypes', 'productListActiveFilters', 'userHasProductAdmin'])
    },

    components: {
        ProductAdminPages,
        MainNavigation,
        BasicLinks,
        CustomTable,
        ProductTypeDropdown,
        Search,
        Dropdown,
        ModalProductEdit,
        ModalProductAddSelect,
        Notification,
        CustomFilter,
        ReAuthenticate,
        ModalConfirmation
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header__new {
        &--wide {
            flex-basis: 400px;
        }

        .btn-secondary {
            margin-left: 10px;
            width: 165px;
        }
    }

    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: calc(100% - 70px);
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }
}
</style>
