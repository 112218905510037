import { render, staticRenderFns } from "./ProductVisibilityPage.vue?vue&type=template&id=1a79d84f&scoped=true"
import script from "./ProductVisibilityPage.vue?vue&type=script&lang=js"
export * from "./ProductVisibilityPage.vue?vue&type=script&lang=js"
import style0 from "./ProductVisibilityPage.vue?vue&type=style&index=0&id=1a79d84f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a79d84f",
  null
  
)

export default component.exports