<template>
    <div>
        <h1>Adatkezelési tájékoztató</h1>
        <p>A BMI Magyarország Kft. a weboldal kapcsán kezelt személyes adataira vonatkozóan az alábbiakról kívánja Önt tájékoztatni.</p>
        <p>A BMI Magyarország Kft. (székhely: 8200 Veszprém, Házgyári út 1., adószám: 10488580-2-19; cégjegyzékszám: 19-09-500022) a jelen adatkezelési tájékoztatóban foglaltak alapján kezeli az Ön személyes adatait.</p>
        <p>Jelen tájékoztató célja, hogy BMI Magyarország Kft. (a továbbiakban: Adatkezelő vagy Vállalkozó) által kezelt személyes adatokra vonatkozó elveket, eljárásokat, alkalmazott gyakorlatot összefoglalja, arról a felhasználóit tájékoztassa.</p>
        <p>A Vállalkozó szervezeti egységeinél adatkezelést végző alkalmazottak és a Vállalkozó megbízásából az adatfeldolgozásban résztvevő, annak valamely műveletétvégző szervezetek alkalmazottjai kötelesek a megismert személyes adatokat a jelen tájékoztatónak megfelelőenkezelni.</p>
        <p>Az Adatkezelő kötelezettséget vállal arra, hogy tevékenységével kapcsolatos minden adatkezelés megfelel a jelen tájékoztatóban és a hatályos jogszabályokban meghatározott elvárásoknak. A tájékoztató elsősorban az alábbi jogszabályokban foglalt követelményekkel összhangban készült:</p>
        <ul>
            <li>Magyarország Alaptörvénye („Alaptörvény”);</li>
            <li>az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény (a továbbiakban: Ektv.),</li>
            <li>Az Európai Parlament és a Tanács (EU) 2016/679 rendelete a természetes személyeknek a személyes adatok kezelése tekintetében történővédelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet) („Rendelet”);</li>
            <li>A számvitelről szóló 2000. évi C. törvény (Sztv.);</li>
            <li>A munka törvénykönyvéről szóló 2012. évi I. törvény („Mt”),</li>
            <li>A személy-és vagyonvédelmi, valamint a magánnyomozói tevékenység szabályairól szóló 2005. évi CXXXIII. törvény (Szvtv.).</li>
        </ul>
        <p>Az adatkezelési tevékenységek nyilvántartását a Tásaság folyamatosan, de legalább évente frissíteni köteles. Amennyiben új adatkezelési tevékenység merülne fel, úgy a tájékoztató kiegészítését haladéktalanul elvégezzük.</p>
        <h3>1. Fogalmi háttér</h3>
        <p>A jelen tájékoztató fogalmi rendszere megfelel a Rendelet 4. cikkében meghatározott értelmező fogalom magyarázatoknak, így különösen:</p>
        <ul>
            <li><strong>Azonosítható természetes személy</strong>: az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, azonosító szám, helymeghatározó adat, online azonosító vagy a természetes személy fizikai, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható;</li>
            <li><strong>Személyes adat</strong>: az érintettel kapcsolatba hozható adat - különösen az érintett neve, azonosító jele, valamint egy vagy több fizikai, fiziológiai, mentális, gazdasági, kulturális vagy szociális azonosságára jellemző ismeret -, valamint az adatból levonható, az érintettre vonatkozó következtetés;</li>
            <li><strong>Különleges adat</strong>: a faji eredetre, a nemzetiséghez tartozásra, a politikai véleményre vagy pártállásra, a vallásos vagy más világnézeti meggyőződésre, az érdek-képviseleti szervezeti tagságra, a szexuális életre vonatkozó személyes adat, az egészségi állapotra, a kóros szenvedélyre vonatkozó személyes adat, valamint a bűnügyiszemélyes adat;</li>
            <li><strong>Hozzájárulás</strong>: az Érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az Érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őtérintő Személyes adatok kezeléséhez;</li>
            <li><strong>Adatkezelő</strong>: az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely önállóan vagy másokkal együtt az adat kezelésének célját meghatározza, az adatkezelésre (beleértve a felhasznált eszközt) vonatkozó döntéseket meghozza és végrehajtja, vagy az adatfeldolgozóval végrehajtatja;</li>
            <li><strong>Címzett</strong>: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a Személyes adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a tagállami joggal összhangban férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az említett adatok e közhatalmi szervek általi kezelése meg kell, hogy feleljen az Adatkezelés céljainak megfelelően az alkalmazandó adatvédelmi szabályoknak.</li>
            <li><strong>Adatkezelés</strong>: az alkalmazott eljárástól függetlenül az adaton végzett bármely műveletvagy a műveletek összessége, így különösen gyűjtése, felvétele, rögzítése, rendszerezése, tárolása, megváltoztatása, felhasználása, lekérdezése, továbbítása, nyilvánosságra hozatala, összehangolása vagy összekapcsolása, zárolása, törlése és megsemmisítése, valamint az adat további felhasználásának megakadályozása, fénykép-, hang- vagy képfelvétel készítése, valamint a személy azonosítására alkalmas fizikai jellemzők (pl. ujj- vagy tenyérnyomat, DNS-minta, íriszkép) rögzítése;</li>
            <li><strong>Adattovábbítás</strong>: az adat meghatározott harmadik személy számára történőhozzáférhetővé tétele;</li>
            <li><strong>Adatfeldolgozás</strong>: az adatkezelési műveletekhez kapcsolódó technikai feladatok elvégzése, függetlenül a műveletek végrehajtásához alkalmazott módszertől és eszköztől, valamint az alkalmazás helyétől, feltéve hogy a technikai feladatot az adaton végzik;</li>
            <li><strong>Profilalkotás</strong>: Személyes adatok automatizált kezelésének bármely olyan formája, amelynek során a Személyes adatokat valamely természetes személyhez fűződőbizonyos személyes jellemzők értékelésére, különösen a munkahelyi teljesítményhez, gazdasági helyzethez, egészségi állapothoz, személyes preferenciákhoz érdeklődéshez, megbízhatósághoz, viselkedéshez, tartózkodási helyhez vagy mozgáshoz kapcsolódó jellemzők elemzésére vagy előrejelzésére használják;</li>
            <li><strong>Adatfeldolgozó</strong>: az a természetes vagy jogi személy, illetve jogi személyiséggel nem rendelkező szervezet, aki vagy amely szerződés alapján - beleértve a jogszabály rendelkezése alapján kötött szerződést is - adatok feldolgozását végzi;</li>
            <li><strong>Adatvédelmi incidens</strong>: személyes adat jogellenes kezelése vagy feldolgozása, így különösen a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés.</li>
        </ul>
        <p>Amennyiben a mindenkori hatályos adatvédelmi jogszabály fogalom magyarázatai eltérnek jelen tájékoztató fogalom magyarázataitól, akkor a jogszabály által meghatározott fogalmak az irányadóak.</p>
        <h3>2. Az adatkezelő és az adatkezelésre jogosult személyek, adatvédelmi tisztviselő</h3>
        <p>Az Adatkezelő a BMI Magyarország Kft.</p>
        <p>A BMI Magyarország Kft. adatvédelmi tisztviselőt nem nevezett ki, mivel az adatkezelés mértéke nem teszi szűkségessé.</p>
        <h3>3. Igénybe vett adatfeldolgozók</h3>
        <p>A Vállalkozó a következő adatfeldolgozó szolgáltatásait veszi igénybe a tevékenysége végzése során, illetve részére továbbít adatokat:</p>
        <p>Rendelések kezelése és számlázás:</p>
        <ul>
            <li>SAP Hungary Kft. (székhely: 1031 Budapest, Záhony u. 7, Graphisoft Park, adószám: 12342659-2-44)</li>
        </ul>
        <p>Weboldal üzemeltetése tekintetében:</p>
        <ul>
            <li>XTRADEVELOPERS Kft. (székhely: 1125 Budapest, Rőzse utca 6-8. 3. ép. 1. em. 27., adószám: 26663229-2-43)</li>
        </ul>
        <p>A Vállalkozó vásárlóitól érkezett megrendelésék házhozszállításához:</p>
        <ul>
            <li>L. K. Mobil Kft. (székhely: 8900 Zalaegerszeg, Eötvös u. 1. 1. em. 1., adószám: 12460346-2-20)</li>
            <li>Kissfuvar Kft. (székhely: 6000 Kecskemét, Segesvár u. 12., adószám: 11424970-2-03)</li>
            <li>Gászler Kft. (székhely: 1171 Budapest, Dettai u. 3., adószám: 10653434-2-42)</li>
            <li>Persped Kft. (székhely: 8200 Veszprém, Házgyári út 16., adószám: 11860767-2-19)</li>
        </ul>
        <h3>4. A weboldalon történő vásárlás során kezelt személyes adatok</h3>
        <h4>4.1. Az adatkezelés jogalapjai, céljai</h4>
        <p>Az adatkezelés jogalapja a szerződés teljesítése. Az adatkezelés célja a webáruházában történő árkalkuláció elvégzése és a rendelés leadása, számla kiállítása, a vásárlók nyilvántartása, egymástól való megkülönböztetése, a megrendelések teljesítése, a vásárlás és fizetés dokumentálása, a számviteli kötelezettség teljesítése, a vásárlói kapcsolattartás.</p>
        <h4>4.2. A kezelt adatok köre, célja és az adatkezelés időtartama</h4>
        <table>
            <tr>
                <th>Adat megnevezése</th>
                <th>Adat kezelés célja</th>
                <th>Tárolás időtartama</th>
            </tr>
            <tr>
                <td>Cégnév</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Cím</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Kapcsolattartó neve</td>
                <td>Vásárló azonosítása</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Kapcsolattartó e-mail cím</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>További kapcsolattartók neve</td>
                <td>Vásárló azonosítása</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>További kapcsolattartók e-mail</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Készített árajánlatok</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Korábbi megrendelések</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
            <tr>
                <td>Szállítási cím</td>
                <td>szerződés teljesítése</td>
                <td>5 év</td>
            </tr>
        </table>
        <p>Az adatkezelés ellen adatkezelőnk elérhetőségén bármikor tiltakozhat. Tájékoztatni szeretnénk, hogy a hozzájárulás visszavonása nem érinti a visszavonás előtti időszakravonatkozó adatkezelés jogszerűségét.</p>
        <p>Az adatkezelés a szerződés teljesítéséig, illetve a Ptk. 6:22. § alapján a vásárlás időpontjátólszámított 5 évig tart; illetve a Számv. tv. alapján köteles megőrizni termék, illetve szolgáltatás vásárlása esetén a Számv. tv. 169. § (2) bekezdése alapján nyolc évig.</p>
        <h4>4.3. A személyes adatok címzettjei</h4>
        <p>A megadott adatokhoz a Vállalkozó és alkalmazottai kizárólag feladatkörükből eredőtevékenységük teljesítése érdekében, csak az ahhoz szükséges mértékig férhetnek hozzá.</p>
        <h3>5. A weboldalon megadott elérhetőségeink kapcsán kezelt személyes adatokra vonatkozó tájékoztató</h3>
        <h4>5.1. Az adatkezelés jogalapjai, céljai</h4>
        <p>A kezelt személyes adatok jogalapja az Ön önkéntes, hozzájáruláson alapuló nyilatkozata. Az adatokat az Ön beazonosítása, illetve az Önnek történő visszajelzés érdekében kezeljük.</p>
        <h4>5.2. A kezelt adatok köre, és az adatkezelés időtartama</h4>
        <p>A kapcsolatfelvétel során, a support e-mail címre küldött emailben megadott adatokat, feltehetően nevet, e-mail címet, illetve opcionálisan a telefonszámot kezeljük. Az adatok kezelése az adott megkeresés lezárásáig tart.</p>
        <p>A hozzájárulását a lenti elérhetőségeink bármelyikén bármikor visszavonhatja. Tájékoztatni szeretnénk, hogy a hozzájárulás visszavonása nem érinti a visszavonás előtti időszakravonatkozó adatkezelés jogszerűségét.</p>
        <h4>5.3. A személyes adatok címzettjei</h4>
        <p>A megadott adatokhoz a szolgáltató értékesítéssel foglalkozó munkatársai az üzenetben jelzettek / kértek teljesítése érdekében, csak az ahhoz szükséges mértékig férhetnek hozzá.</p>
        <h3>6. A sütikre vonatkozó tájékoztató</h3>
        <h4>6.1. Sütik és webjelzők</h4>
        <p>A süti betűkből és számokból álló kis információs csomag. A webszerver a sütit automatikusan megküldi a látogató böngészőjének a honlap első alkalommal történő meglátogatásakor. A látogató számítógépe vagy mobil eszköze eltárolja a sütit a sütit elhelyező személy által meghatározott időtartamig.</p>
        <p>A böngésző a honlap következő alkalommal történő meglátogatásakor visszaküldi a sütit a webszerver részére. A webszerver a megküldött adatok alapján azonosítani tudja a sütit küldőszámítógépet vagy mobil eszközt és az adott eszköz által megküldött sütiket össze tudja kapcsolni.</p>
        <p>A süti a honlapon történt látogatások között folytatott tevékenységre vonatkozó információt szolgáltat a webszerver részére.</p>
        <p>A webjelző a honlapon elhelyezett kis méretű, általában észrevehetetlen, kép. A webjelzőkelhelyezésével a látogató honlapon végzett műveletei követhetők és a nyert adatokból statisztika készíthető.</p>
        <p>BMI Magyarország Kft. sütiket és webjelzőket helyez el a honlapon annak érdekében, hogy felismerje azon személyt, aki a honlapot korábban már meglátogatta; feltérképezze a látogató érdeklődési körét; javítsa a látogató felhasználói élményét és személyre szabott hirdetéseket jelentessen meg a látogató részére, továbbá a honlap biztonságának javítása érdekében.</p>
        <h4>6.2. Az adatkezelés jogalapjai, céljai</h4>
        <p>Az adatok kezelésének jogalapja az Ön hozzájárulása, amelyet bármikor az internetes böngészőmegfelelő beállításaival adhat meg vagy utasíthat vissza.</p>
        <p>Az adatkezelés célja a felhasználók azonosítása, egymástól való megkülönböztetése, illetve a felhasználók aktuális munkamenetének azonosítása, az annak során megadott adatok tárolása, az adatvesztés megakadályozása.</p>
        <h4>6.3. A kezelt adatok köre, és az adatkezelés időtartama</h4>
        <p>Az adatkezelés egyedi azonosítószámokra, dátumokra, időpontokra terjed ki. Az érintettek köre a weboldalt látogatók, illetve a felhasználók.</p>
        <table>
            <tr>
                <th>Cookie megnevezése</th>
                <th>Cookie céljának meghatározása</th>
                <th>Lejárat dátuma</th>
            </tr>
            <tr>
                <td>BramacPersist</td>
                <td>Munkamenet süti, az oldal alapvetőműködéséhez szükséges.</td>
                <td>Oldal vagy böngészőbezárásáig.</td>
            </tr>
            <tr>
                <td>_ga</td>
                <td>Egyedi mérő kód, ami statisztikai adatokat generál a honlap használatával kapcsolatban</td>
                <td>2 év</td>
            </tr>
            <tr>
                <td>_gid</td>
                <td>Egyedi mérő kód, ami statisztikai adatokat generál a honlap használatával kapcsolatban</td>
                <td>1 nap</td>
            </tr>
        </table>
        <h4>6.4. A személyes adatok címzettjei</h4>
        <p>A rögzített adatokat BMI Magyarország Kft. alkalmazottai kezelik. A sütikkel rögzített adatok kapcsán automatizált döntéshozatalt nem végzünk, harmadik személytől Önre vonatkozóan nem gyűjtünk személyes adatokat.</p>
        <h3>7. A 16. évnél fiatalabb személyek személyes adatainak kezelése</h3>
        <p>A 16. életévét be nem töltött személy személyes adatai csak a felette szülői felügyeletet gyakorló nagykorú személy hozzájárulása esetén kezelhetők. Az adatkezelőnek nem áll módjában a hozzájáruló személy jogosultságát, illetve nyilatkozatának tartalmát ellenőrizni,így a felhasználó, illetve a felette szülői felügyeletet gyakorló személy szavatol azért, hogy a hozzájárulás megfelel a jogszabályoknak. Hozzájáruló nyilatkozat hiányában az adatkezelő16. életévét be nem töltött érintettre vonatkozó személyes adatot nem gyűjt. Amennyiben a tudomásunkra jut, hogy 16 év alatti gyermektől személyes adatokat gyűjtöttünk be megfelelőszülő hozzájárulás nélkül, úgy a lehető̋ leghamarabb megtesszük az adatok törléséhez szükséges lépéseket.</p>
        <h3>8. Az Ön jogai</h3>
        <p>Az Érintett Adatkezelő infohu@bmigroup.com e-mail címen, vagy az Adatkezelőszékhelyére postai úton küldött levelében, vagy személyesen leadott kérelmében írásban élhet az Adatkezelőnél a személyes adataival kapcsolatos jogaival.</p>
        <ul>
            <li>8.1. Ön bármikor jogosult tájékoztatást kérni BMI Magyarország Kft.-től az általunk kezelt, jelen tájékoztatóban is nevesített Önre vonatkozó személyes adatokról, az adatkezelés céljáról, jogalapjáról, időtartamáról, továbbá arról, hogy kik és milyen célból kapják vagy kapták meg adatait. (mindegyik adatkezelés esetében)</li>
            <li>8.2. Ön bármikor jogosult kérni BMI Magyarország Kft.-től, hogy helyesbítse az Ön pontatlan személyes adatait vagy egészítse ki az Ön hiányos személyes adatait. (mindegyik adatkezelés esetében)</li>
            <li>8.3. Ön bármikor jogosult kérni a Társaságtól, hogy a rendelkezésre álló elektronikus formában kezelt személyes adatait Önnek, illetve más adatkezelő részére általánosan használt formátumban átadja. (mindegyik adatkezelés esetében)</li>
            <li>
                8.4.Ön bármikor jogosult kérni BMI Magyarország Kft.-től, hogy korlátozza az adatkezelést. Az adatkezelés korlátozása esetén BMI Magyarország Kft. a személyes adatok tárolása kivételével csak az Ön hozzájárulásával vagy jogi igények előterjesztéséhez,érvényesítéséhez vagy védelméhez vagy más személy jogainak védelme érdekében kezelheti. Ön a személyes adatok kezelésének korlátozását kérheti, ha
                <ul>
                    <li>Ön vitatja a személyes adatok pontosságát,</li>
                    <li>az adatkezelés jogellenes, Ön a személyes adatok törlését ellenzi,</li>
                    <li>BMI Magyarország Kft.-nek a személyes adatokra adatkezelés céljából nincs szüksége, azonban Ön jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez a személyes adatok korlátozását igényli vagy</li>
                </ul>BMI Magyarország Kft. az adatkezelés korlátozásának feloldásáról tájékoztatja Önt a feloldást megelőzően. (mindegyik adatkezelés esetében)
            </li>
            <li>
                8.5.Ön bármikor jogosult kérni BMI Magyarország Kft.-től, hogy törölje az Ön személyes adatait. BMI Magyarország Kft. törli az Ön személyes adatait, ha
                <ul>
                    <li>BMI Magyarország Kft.-nek nincs szüksége a személyes adatokra abból a célból, amely célból gyűjtötte vagy kezelte,</li>
                    <li>Ön tiltakozik az adatkezelés ellen a saját helyzetével kapcsolatos okból és nincs elsőbbségetélvezőjogszerű ok az adatkezelésre,</li>
                    <li>a személyes adatait jogellenesen kezelték vagy</li>
                    <li>BMI Magyarország Kft. jogi kötelezettsége teljesítéséhez köteles a személyes adatokat törölni.</li>
                </ul>
            </li>
        </ul>
        <p>BMI Magyarország Kft. az adatkezeléssel kapcsolatos kérelmeket azok benyújtásától számított legfeljebb 30 napon belül kivizsgálja, annak megalapozottságáról döntést hoz, és döntéséről a kérelmezőt írásban tájékoztatja. (mindegyik adatkezelés esetében)</p>
        <h3>9. Adatvédelem</h3>
        <p>Az Adatkezelő gondoskodik az Érintettek adatainak védelméről. Az Adatkezelő megfelelőintézkedéseket foganatosít annak érdekében, hogy megakadályozza a kezelt személyes adatokhoz való illetéktelen hozzáférést és az azokkal való visszaélést.</p>
        <p>Az Adatkezelő minden ésszerű és szükséges lépést megtesz annak érdekében, hogy megvédje a tárolt személyes adatokat a visszaéléstől, az elvesztéstől és az illetéktelen hozzáféréstől.</p>
        <h4>9.1. Adatvédelemmel kapcsolatos belső dokumentumok</h4>
        <ul>
            <li>Incidenskezelési szabályzat és iránymutatás</li>
            <li>Információbiztonsági irányelvek (Információbiztonsági irányítási rendszer – ISMS)</li>
            <li>Adatvédelmi irányelvek</li>
        </ul>
        <h4>9.2. Az adattárolás fizikai helye</h4>
        <p>Az üzemeltető szolgáltató szervereit telephelyén, illetve az alábbi magyarországi szerverteremben tárolja:</p>
        <ul>
            <li>Invitech DC14 (1143 Budapest, Ilka utca 31.)</li>
        </ul>
        <p>A szerverekhez hozzáféréssel kizárólag Szolgáltató, annak alkalmazottja vagy megbízott rendszergazdája rendelkezik. Szolgáltató biztosítja nevezettek titoktartási kötelezettségének meglétét. A szerveren tárolt adatokhoz a szerverparkok alkalmazottainak nincs hozzáférési jogosultsága.</p>
        <h3>10. Panaszkezelés</h3>
        <ul>
            <li>
                10.1. Amennyiben úgy véli, hogy személyes adatait BMI Magyarország Kft. nem a tájékoztatóban jelzetteknek megfelelően kezelte, illetve a jogait maradéktalanul nem tudta érvényesíteni, úgy kérnénk, hogy elérhetőségeinek bármelyikén vegye fel velünk a kapcsolatot
                <ul>
                    <li>infohu@bmigroup.com</li>
                </ul>
            </li>
            <li>
                10.2. A személyes adataira vonatkozó jogainak megsértése esetén a hatósághoz az alábbi elérhetőségen nyújthatja be a panaszát:
                <table>
                    <tr>
                        <td>Hatóság:</td>
                        <td>Nemzeti Adatvédelmi és Információszabadság Hatóság</td>
                    </tr>
                    <tr>
                        <td>Levelezési cím:</td>
                        <td>1530 Budapest, Pf.: 5.</td>
                    </tr>
                    <tr>
                        <td>Cím:</td>
                        <td>1125 Budapest, Szilágyi Erzsébet fasor 22/c</td>
                    </tr>
                    <tr>
                        <td>Telefon:</td>
                        <td>+36 (1) 391-1400</td>
                    </tr>
                    <tr>
                        <td>Fax:</td>
                        <td>+36 (1) 391-1410</td>
                    </tr>
                    <tr>
                        <td>E-mail cím:</td>
                        <td>ugyfelszolgalat@naih.hu</td>
                    </tr>
                </table>
            </li>
            <li>10.3. Személyes adatait kezelése során történő jogsértés esetén bírósághoz is fordulhat.</li>
        </ul>
    </div>
</template>

<script>
import Vue from "vue";
import { MdIcon } from "vue-material/dist/components";

Vue.use(MdIcon);

export default {
    name: 'PrivacyContent'
};
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 32px;
        margin: 25px 0;
    }

    h2, h3, h4 {
        font-weight: bold;
        margin: 32px 0 16px;
    }

    p {
        margin-bottom: 16px;
    }

    table {
        margin: 16px 0;

        tr {
            td, th {
                border: 1px solid #535353;
                padding: 5px;
            }
        }
    }

    ul {
        padding-left: 20px;

        li {
            margin: 16px 0;
        }
    }
</style>
