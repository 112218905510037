<template>
    <div class="tags-bar-component" :class="[orderSettings.tags.open ? 'active' : '']">
        <div class="settings-sidebar-layer" @click="closeBar"></div>
        <div class="settings-sidebar">
            <div class="settings-sidebar-head">
                <img src="@/assets/svg/tags.svg">
                <span class="name">{{ $t('offer.tags.title') }}</span>
                <button type="button" @click="closeBar">
                    <md-icon>keyboard_arrow_right</md-icon>
                </button>
            </div>

            <div class="settings-sidebar-body" v-if="!loading">
                <ul class="color-select">
                    <li v-for="(tag, tagIndex) in tagsWithChildren" :key="tagIndex" :class="tagClass(tag)">
                        <span @click="selectTag(tag)">{{ tag.title }}</span>
                        <ul style="margin-left: 15px; margin-top: 5px;" class="color-select"
                            v-if="isSelected(tag) && profile.canSelectChildTags">
                            <li v-for="(childTag, childIndex) in tag.children" :key="childIndex"
                                :class="tagClass(childTag)">
                                <span @click="selectTag(childTag)">{{ childTag.title }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { MdIcon } from 'vue-material/dist/components';
import Notification from '../../components/Notification.vue';

Vue.use(MdIcon);

export default {
    name: 'TagsBar',
    props: ['loading'],

    data() {
        return {
            selectedTags: [],
            editedTags: false,
            competitorTagId: 138,
        };
    },

    watch: {
        'orderSettings.tags.open': {
            handler(val) {
                if (val) {
                    this.editedTags = false;
                } else if (this.editedTags) {
                    this.saveTags();
                }
            },
        },

        'offerInformation.tags': {
            handler(val) {
                val.forEach(row => this.selectedTags.push(row.id));
            },
        },
    },

    mounted() {
        this.$store.dispatch('FETCH_TAGS');
    },

    methods: {
        selectTag(tag) {
            const { id } = tag;
            const isMultipleSelectionAllowed = this.selectedTags.includes(this.competitorTagId);
            const isAlreadySelected = this.selectedTags.includes(id);

            if (isMultipleSelectionAllowed) {
                if (isAlreadySelected) {
                    this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== id);
                } else if ((id === this.competitorTagId || this.isChildTagOf(tag, this.competitorTagId)) && this.selectedTags.length === 1) {
                    this.selectedTags.push(id);
                }
            } else {
                this.selectedTags = isAlreadySelected ? [] : [id];
            }

            if (!isAlreadySelected) {
                this.unselectChildTags(tag);
            }
            this.editedTags = true;
        },

        isChildTagOf(tag, parentId) {
            if (tag.parent === parentId) {
                return true;
            }
            if (tag.parent) {
                const parentTag = this.tags.find(t => t.id === tag.parent);
                return this.isChildTagOf(parentTag, parentId);
            }
            return false;
        },

        unselectChildTags(tag) {
            if (tag.children && tag.children.length > 0) {
                tag.children.forEach(childTag => {
                    const childId = childTag.id;
                    if (this.selectedTags.includes(childId)) {
                        this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== childId);
                    }
                });
            }
        },

        saveTags() {
            this.$store.dispatch('UPDATE_OFFER_TAGS', {
                id: this.offerInformation.id,
                tags: this.selectedTags,
            }).then(() => {
                this.$store.commit('setSelectedTagsCount', this.selectedTags.length);
                this.$store.dispatch('FETCH_TAGS');
            });
        },

        closeBar() {
            this.$store.commit('closeSettingsSidebar', 'tags');
        },

        isSelected(tag) {
            if (this.selectedTags.includes(tag.id)) {
                return true;
            }
            if (this.selectedTags.includes(tag.parent) && tag.children && tag.children.length > 0) {
                return tag.children.some(childTag => this.selectedTags.includes(childTag.id));
            }
            return false;
        },

        tagClass(tag) {
            return [
                `tag-${tag.color}`,
                { 'is-selected': this.isSelected(tag) },
            ];
        },
    },

    computed: {
        ...mapGetters(['orderSettings', 'modals', 'offerInformation', 'profile', 'tags']),

        tagsWithChildren() {
            return this.tags.map(tag => {
                if (tag.children && tag.children.length > 0) {
                    return {
                        ...tag,
                        children: tag.children.map(childTag => ({
                            ...childTag,
                            parent: tag.id,
                        })),
                    };
                }
                return tag;
            });
        },
    },

    components: { Notification },
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/settings-sidebar.scss';
@import '../../assets/sass/ui/color-select.scss';

.tags-bar-component {}
</style>
