<template>
    <div>
        <h1>Felhasználási feltételek a www.epartnerbmi.hu weboldal külső felhasználói számára</h1>
        <p><strong>1.</strong> A weboldal szolgáltatásait, mint külső felhasználó a BMI Magyarország Kft. (BMI csoport) szerződött partnerei, valamint a szerződött partner által kijelölt munkatársak vehetik igénybe. [együttesen: felhasználó(k)]</p>
        <p><strong>2.</strong> jogosultság igénylése:</p>
        <p><strong>3.</strong> A weboldalra történő bejelentkezéshez e-mail cím, a SAP azonosító, felhasználónév, és jelszó megadása szükséges. </p>
        <p><strong>4.</strong> A felhasználó a BMI Magyarország Kft. előzetes, írásos engedélye nélkül nem adhatja át kívülálló harmadik személynek a weboldal használatához szükséges e-mail címét, a SAP azonosítóját és jelszavát.</p>
        <p><strong>5.</strong> A szerződött partner, mint kereskedő a weboldal felületén jogosult az általa kijelölt munkatársat, munkatársakat kereskedői alfelhasználó jogosultsági szinttel rendelkező felhasználóként megjelölni.</p>
        <p><strong>6.</strong> Amennyiben a kereskedői alfelhasználó jogosultsági szinttel rendelkező felhasználó munkaviszonya megszűnik annál a szerződött partnernél, amelynek képviseletében jogosultságot szerzett a www.epartnerbmi.hu weboldal használatára, úgy a szerződött partner köteles gondoskodni a felhasználó jogosultságának haladéktalan törléséről. Ennek elmulasztásából fakadó kárért felelős.</p>
        <p><strong>7.</strong> A szerződött partner által kijelölt, kereskedői alfelhasználó jogosultsági szinttel rendelkező munkatársaknak a weboldal használatával kapcsolatos magatartásáért, az általuk e körben okozott kárért, a szerződött partner a felelős. </p>
        <p><strong>8.</strong> A felhasználó a weboldalon jogosult használni az ajánlatadó felületet, amelyen árkalkulációk készíthetőek.</p>
        <p><strong>9.</strong> Az árkalkuláció készítés folyamatában a felhasználó kiválasztja a termékeket, megadja az igényelt mennyiségeket, hozzáadja a kiegészítőket és tartozékokat. A Bramac vagy a Villas Szaktanácsadóval előzetesen megállapodott árkedvezményeket a felhasználónak a rendszerben meg kell adnia. A kedvezmények soronként, vagy termékcsoportonként adhatóak meg, vagy ha a felhasználó az időszakosan elérhető akcióban kíván vásárolni, úgy azt bejelölheti. A kalkulációban megjelölt termékek árának számítását és a különböző logisztikai díjakat a program automatikusan számolja. Bizonyos termékek esetén kapcsolódó termékek felajánlásra kerülnek, továbbá lehetőség van színt és más paraméterek alapján tartozékokat választani. A folyamat végén amennyiben árkalkuláció készült, PDF exportálást ajánl fel a rendszer. Az elkészült kalkuláció végén a felhasználó közvetlenül megrendelést hozhat létre, a sor végén a “kosárba teszem” gomb megnyomásával. Ebben az esetben további számlázási és szállítási adatokat szükséges megadni. </p>
        <p><strong>10.</strong> A megrendelés először „függő megrendelés” státuszt kap, majd a BMI Magyarország Kft. belső rendszere szerinti megrendelés jóváhagyás, elfogadás után kerül beküldésre a megrendelés a SAP rendszerbe, és ekkor minősül az a BMI Magyarország Kft. által elfogadott megrendelésnek.</p>
        <p><strong>11.</strong> A felhasználó a felületen az árkalkuláció készítése mellett, a már elfogadott ajánlatokat is megrendeléssé konvertálhatja. Továbbá a webshop integrációval közvetlenül is leadhat megrendelést. Ezen megrendelések a BMI Magyarország Kft. belső jóváhagyási folyamata után bekerülnek a SAP rendszerbe, és elindul a teljesítés.</p>
        <p><strong>12.</strong> A megrendelés minden esetben csak akkor válik szerződéssé, amikor az „Jóváhagyott” státusszal megjelenik a lista oldalon.</p>
        <p><strong>13.</strong> A lista oldalon </p>
        <ul>
            <li>a felhasználónak lehetősége van keresni, szűrni, rendezni a tételeket, illetve archiválni a már lezárt ajánlatkéréseket,</li>
            <li>a felhasználó láthatja az ajánlatok státuszait, melyek a következők lehetnek: “Árkalkuláció”, “Függő megrendelés”, “Jóváhagyott”, “Jóváhagyásra vár”, “Megrendelés”,</li>
            <li>a felhasználó megnyithatja szerkesztésre a már elkészített ajánlatokat, új pdf-et generálhat belőle,</li>
            <li>a felhasználó új projektet hozhat létre, módosíthat projektet, illetve meglévő projekthez is adhat új árkalkulációt.</li>
        </ul>
        <p><strong>14.</strong> Felhasználó köteles a weboldalon található árral, árengedménnyel, készlettel kapcsolatos információkat üzleti titokként kezelni, jogosulatlanul harmadik félnek nem adhatja át ezen adatokat.</p>
        <p><strong>15.</strong> A weboldal szolgáltatásait a felhasználók kizárólag valamely gazdasági társaság képviseletében vehetik igénybe, így a felhasználó által leadott és a BMI Magyarország Kft. által jóváhagyott megrendelésekre nem vonatkozik a magánszemélyeket megillető, az internetes vásárlástól történő elállás joga. </p>
        <p><strong>16.</strong> A weboldalon keresztül megrendelt termékek esetén a felhasználó a megrendelés BMI Magyarország Kft. általi jóváhagyását követően a mindenkor érvényes kereskedelmi szerződésben megfogalmazott feltételek szerint módosíthatja vagy lemondhatja.</p>
        <p><strong>17.</strong> A weboldalon leadott megrendelések teljesítésére a BMI Magyarország Kft. www.bramac.hu oldalon található Általános Eladási és Szállítási Feltételei és a szerződött partnerekkel kötött, egyes Kereskedelmi Szerződésekben foglaltak az irányadók.</p>
        <p><strong>18.</strong> A weboldalon működtetett előzetes árkalkuláció és megrendelés kezelő szoftver a BMI Magyarország Kft. tulajdona, így a BMI Magyarország Kft. írásos hozzájárulása nélkül annak struktúrája, tartalma (képek, adatok, stb.), működése nem másolható, felhasználható, illetve jogosulatlan másolata, használata esetén a jogsértővel szemben a BMI Magyarország Kft. jogi eszközökkel és kártérítési igénnyel fellép.</p>

    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'TermsContent'
}
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 32px;
        margin: 25px 0;
    }

    h2, h3, h4 {
        font-weight: bold;
        margin: 32px 0 16px;
    }

    p {
        margin-bottom: 16px;
    }

    table {
        margin: 16px 0;

        tr {
            td, th {
                border: 1px solid #535353;
                padding: 5px;
            }
        }
    }

    ul {
        padding-left: 20px;
        margin-bottom: 15px;

        & > li {
            margin: 16px 0 0 15px;
        }
    }
</style>
