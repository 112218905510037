import { render, staticRenderFns } from "./CommentsBar.vue?vue&type=template&id=4a60b9eb&scoped=true"
import script from "./CommentsBar.vue?vue&type=script&lang=js"
export * from "./CommentsBar.vue?vue&type=script&lang=js"
import style0 from "./CommentsBar.vue?vue&type=style&index=0&id=4a60b9eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a60b9eb",
  null
  
)

export default component.exports