<template>
    <div class="modal wider" :class="{ 'is-open': modals.planCalculation.open }">
        <div class="modal-box dark">
            <div class="modal-box-header two-line">
                <span class="title title-secondary">{{ title }}</span>
                <span class="title">{{ viewOnly ? $t('offer.plan_calculation.calculation.view_title') : $t('offer.plan_calculation.calculation.title') }}</span>
                <button type="button" @click="close" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <tabs v-if="hasPlanCalculation" :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el}"></tabs>
                <div class="data-container" v-if="hasPlanCalculation" v-show="tabValue === 'data'">
                    <div class="input-block" v-if="groupTypes">
                        <span class="text-input-label">{{ $t('offer.plan_calculation.calculation.data.field.group') }}</span>
                        <custom-group-select
                            :disabled="viewOnly"
                            :modal="true"
                            :options="groupTypes"
                            :selectedItem="selectedGroupType"
                            @changed="updateGroupType">
                        </custom-group-select>
                    </div>
                    <div class="input-block" v-if="variants">
                        <span class="text-input-label">{{ $t('offer.plan_calculation.calculation.data.field.color') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :large="true"
                            :options="variants"
                            :selectedItem="selectedVariant"
                            @changed="updateVariant"
                        ></custom-select>
                    </div>
                    <div class="basic-sizes-block">
                        <div class="input-col">
                            <label class="label" for="typical-lath-gaps">{{ $t('offer.plan_calculation.calculation.data.field.typical_lath_gaps') }}</label>
                            <div class="input-row">
                                <input type="number" class="small" :disabled="viewOnly" v-model="calculation.typicalLathGaps" id="typical-lath-gaps" min="0" step="0.01" v-zerohandling>
                                <span class="unit">cm</span>
                            </div>
                        </div>
                        <div class="input-col">
                            <label class="label" for="cutting-losses">{{ $t('offer.plan_calculation.calculation.data.field.cutting_losses') }}</label>
                            <div class="input-row">
                                <input type="number" class="small" :disabled="viewOnly" v-model="calculation.cuttingLosses" id="cutting-losses" min="0" step="0.01" v-zerohandling>
                                <span class="unit">%</span>
                            </div>
                        </div>
                        <div class="input-col">
                            <label class="label" for="roof-pitch">{{ $t('offer.plan_calculation.calculation.data.field.roof_pitch') }}</label>
                            <div class="input-row">
                                <input type="number" class="small" :disabled="viewOnly" v-model="calculation.roofPitch" id="roof-pitch" min="0" step="0.01" v-zerohandling>
                                <span class="unit">°</span>
                            </div>
                        </div>
                        <div class="input-col">
                            <label class="label">{{ $t('offer.plan_calculation.calculation.data.field.material_req') }}</label>
                            <div class="input-row">
                                <input type="number" class="small" :value="calculation.materialReq" readonly disabled>
                                <span class="unit">db/m²</span>
                            </div>
                        </div>
                    </div>
                    <div class="types-block" v-for="selectableProduct in normalSelectableProducts" :key="selectableProduct.key">
                        <span class="label">{{ selectableProduct.label }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="selectableProduct.selected"
                            :options="selectableProduct.options"
                            @changed="selectedProduct('selected', selectableProduct.key, $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                </div>
                <div class="extended-container" v-if="hasPlanCalculation" v-show="tabValue === selectableProduct.key" v-for="selectableProduct in extendedSelectableProducts" :key="selectableProduct.key">
                    <div class="input-block input-block--full-width">
                        <span class="text-input-label">{{ selectableProduct.label }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="selectableProduct.selected"
                            :options="selectableProduct.options"
                            @changed="selectedProduct('selected', selectableProduct.key, $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                    <div class="input-block input-block--roof-surface">
                        <label class="label" :for="`roof-surface_${selectableProduct.key}`">{{ $t('offer.plan_calculation.calculation.extended.field.roof_surface') }}</label>
                        <input type="number" :disabled="viewOnly" v-model="selectableProduct.roofSurface" @input="calculateRoofSurface()" :id="`roof-surface_${selectableProduct.key}`" min="0" step="0.01" v-zerohandling>
                        <span class="unit">m²</span>
                    </div>
                    <div class="input-block">
                        <span class="label">{{ $t('offer.plan_calculation.calculation.extended.field.function') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="selectableProduct.function"
                            :options="selectableProduct.functionOptions"
                            @changed="selectedProduct('function', selectableProduct.key, $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                    <div class="input-block">
                        <span class="label">{{ $t('offer.plan_calculation.calculation.extended.field.type') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="selectableProduct.type"
                            :options="selectableProduct.typeOptions"
                            @changed="selectedProduct('type', selectableProduct.key, $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                </div>
                <div class="consignment-container consignment-data-block" v-if="hasPlanCalculation" v-show="tabValue === 'consignation'">
                    <div class="col col-first">
                        <div class="input-row">
                            <label class="label" for="roof-surface">{{ $t('offer.plan_calculation.calculation.consignation.field.roof_surface') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.roofSurface" @input="roofSurfaceCalculable = false" id="roof-surface" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.square_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="comignolo">{{ $t('offer.plan_calculation.calculation.consignation.field.comignolo') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.comignolo" id="comignolo" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="arris">{{ $t('offer.plan_calculation.calculation.consignation.field.arris') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.arris" id="arris" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="valley">{{ $t('offer.plan_calculation.calculation.consignation.field.valley') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.valley" id="valley" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-left">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_left') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.capLeft" id="cap-left" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-right">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_right') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.capRight" id="cap-right" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-oblique">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_oblique') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.capOblique" id="cap-oblique" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-row">
                            <label class="label" for="half-gable">{{ $t('offer.plan_calculation.calculation.consignation.field.half_gable') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.halfGable" id="half-gable" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="gutter">{{ $t('offer.plan_calculation.calculation.consignation.field.gutter') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.gutter" id="gutter" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="perimeter-trim">{{ $t('offer.plan_calculation.calculation.consignation.field.perimeter_trim') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.perimeterTrim" id="perimeter-trim" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="chimney-edge">{{ $t('offer.plan_calculation.calculation.consignation.field.chimney_edge') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.chimneyEdge" id="chimney-edge" min="0" step="0.01" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="roof-pen-number">{{ $t('offer.plan_calculation.calculation.consignation.field.roof_pen_number') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.roofPenNumber" id="roof-pen-number" min="0" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="number-of-arris-tile">{{ $t('offer.plan_calculation.calculation.consignation.field.number_of_arris_tile') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.numberOfArrisTile" id="number-of-arris-tile" min="0" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="distributor-ridge-tile">{{ $t('offer.plan_calculation.calculation.consignation.field.distributor_ridge_tile') }}</label>
                            <input type="number" class="small" :disabled="viewOnly" v-model="calculation.distributorRidgeTile" id="distributor-ridge-tile" min="0" step="1" v-zerohandling>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                    </div>
                </div>
                <div class="additional-container" v-show="!hasPlanCalculation || tabValue === 'additional'">
                    <div class="input-block input-block--full-width">
                        <label class="label" for="sender-name">{{ $t('offer.plan_calculation.calculation.additional.field.sender_name') }}</label>
                        <input type="text" :disabled="viewOnly" v-model="calculation.senderName" id="sender-name">
                    </div>
                    <div class="input-block input-block--first">
                        <span class="label">{{ $t('offer.plan_calculation.calculation.additional.field.contractor') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="calculation.contractor"
                            :options="calculation.contractorOptions"
                            @changed="selectedAdditional('contractor', $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                    <div class="input-block">
                        <span class="label">{{ $t('offer.plan_calculation.calculation.additional.field.building_purpose') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="calculation.buildingPurpose"
                            :options="calculation.buildingPurposeOptions"
                            @changed="selectedAdditional('buildingPurpose', $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                    <div class="input-block input-block--first">
                        <span class="label">{{ $t('offer.plan_calculation.calculation.additional.field.solar_type') }}</span>
                        <custom-select
                            :disabled="viewOnly"
                            :modal="true"
                            :bold="false"
                            :selectedItem="calculation.solarType"
                            :options="calculation.solarTypeOptions"
                            @changed="selectedAdditional('solarType', $event)"
                            :large="true"
                        ></custom-select>
                    </div>
                    <div class="input-block input-block--amounts">
                        <label class="label" for="solar-power">{{ $t('offer.plan_calculation.calculation.additional.field.solar_power') }}</label>
                        <input type="number" :disabled="viewOnly" v-model="calculation.solarPower" id="solar-power" min="0" step="0.01" v-zerohandling>
                        <span class="unit">{{ $t('offer.plan_calculation.calculation.units.kw') }}</span>
                    </div>
                    <div class="input-block input-block--amounts">
                        <label class="label" for="therm-surface">{{ $t('offer.plan_calculation.calculation.additional.field.therm_surface') }}</label>
                        <input type="number" :disabled="viewOnly" v-model="calculation.thermSurface" id="therm-surface" min="0" step="0.01" v-zerohandling>
                        <span class="unit">{{ $t('offer.plan_calculation.calculation.units.square_meter') }}</span>
                    </div>
                </div>
                <template v-if="!viewOnly">
                    <hr>
                    <button class="btn btn-primary button-submit" type="button" @click="submitPlan()" v-if="hasPlanCalculation && (tabValue !== 'additional' || !orderData.calculation)">
                        {{ $t('offer.plan_calculation.calculation.button.plan_calculation') }}<md-icon>arrow_right_alt</md-icon>
                    </button>
                    <button class="btn button-save button-submit" type="button" @click="savePlanData()" v-else>
                        {{ $t('offer.plan_calculation.calculation.button.save') }}
                    </button>
                </template>
            </div>
        </div>
        <div class="modal-layer"></div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="calculateTab()"
            @close="openConfirmationModal = false"
        ></modal-confirmation>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import CustomGroupSelect from '../../components/dropdowns/CustomGroupSelect.vue'

import ModalConfirmation from '../../components/modals/ModalConfirmation.vue'

import Tabs from '../../components/Tabs.vue'

Vue.use(MdIcon)

import { getProductsWithValue } from '@/plugins/helpers'

export default {
    name: 'ModalCalculation',
    props: ['open', 'viewOnly'],

    data() {
        return {
            tabValue: 'data',
            groupTypes: null,
            selectedGroupType: null,
            variants: null,
            selectedVariant: null,
            roofSurfaceCalculable: false,
            title: null,
            calculation: {
                senderName: null,
                contractor: null,
                contractorOptions: [],
                buildingPurpose: null,
                buildingPurposeOptions: [],
                solarType: null,
                solarTypeOptions: [],
                solarPower: null,
                thermSurface: null,
                typicalLathGaps: 0,
                cuttingLosses: 0,
                roofPitch: 0,
                tileWidth: 0,
                materialReq: 0,
                selectableProducts: [],
                roofSurface: 0,
                comignolo: 0,
                arris: 0,
                valley: 0,
                capLeft: 0,
                capRight: 0,
                capOblique: 0,
                halfGable: 0,
                gutter: 0,
                perimeterTrim: 0,
                chimneyEdge: 0,
                roofPenNumber: 0,
                numberOfArrisTile: 0,
                distributorRidgeTile: 0
            },
            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('offer.plan_calculation.calculation.confirmation.ok'),
                cancelText: this.$t('offer.plan_calculation.calculation.confirmation.cancel'),
                message: this.$t('offer.plan_calculation.calculation.confirmation.question')
            }
        }
    },

    methods: {
        updateGroupType (val) {
            this.selectedGroupType = val
            this.variants = val.variants
            this.selectedVariant = this.variants.filter(v => v.color === this.selectedVariant.color)[0] || this.variants[0]
            this.calculation.typicalLathGaps = this.selectedGroupType.typicalLathGaps
            this.calculation.roofPitch = this.selectedGroupType.roofPitch
            this.calculation.tileWidth = this.selectedGroupType.tileWidth
        },

        submitPlan() {
            if (getProductsWithValue(this.orderData.categories).length > 0) {
                this.openConfirmationModal = true
            } else {
                this.calculateTab()
            }
        },

        savePlanData() {
            this.$store.commit('setCalculation', {
                ...this.orderData.calculation,
                senderName: this.calculation.senderName,
                contractor: this.calculation.contractor ? this.calculation.contractor.id : null,
                buildingPurpose: this.calculation.buildingPurpose ? this.calculation.buildingPurpose.id : null,
                solarType: this.calculation.solarType ? this.calculation.solarType.id : null,
                solarPower: this.calculation.solarPower,
                thermSurface: this.calculation.thermSurface,
            })

            this.close()
        },

        calculateTab() {
            this.$store.dispatch('UPDATE_CATEGORY_LIST_BY_PLAN', this.planForServer()).then(() => {
                this.close()
            })
        },

        updateVariant(val) {
            this.selectedVariant = val
        },

        calculateRoofSurface() {
            if(this.roofSurfaceCalculable) {
                let sum = 0
                this.calculation.selectableProducts.forEach((selectableProduct) => {
                    if (selectableProduct.extended && selectableProduct.roofSurface) {
                        sum += parseFloat(selectableProduct.roofSurface)
                    }
                })
                this.calculation.roofSurface = sum
            }
        },

        selectedProduct(attributeKey, key, val) {
            this.calculation.selectableProducts.forEach((selectableProduct) => {
                if (selectableProduct.key === key) {
                    selectableProduct[attributeKey] = val
                }
            })
        },

        selectedAdditional(attributeKey, val) {
            this.calculation[attributeKey] = val
        },

        planForServer() {
            let selectableProducts = []

            this.calculation.selectableProducts.forEach(el => {
                selectableProducts.push({
                    key: el.key,
                    selectedId: el.selected ? el.selected.id : null,
                    function: el.function ? el.function.id : null,
                    type: el.type ? el.type.id : null,
                    roofSurface: el.roofSurface,
                })
            })

            return {
                variantId: this.selectedVariant ? this.selectedVariant.id : null,
                calculation: {
                    senderName: this.calculation.senderName,
                    contractor: this.calculation.contractor ? this.calculation.contractor.id : null,
                    buildingPurpose: this.calculation.buildingPurpose ? this.calculation.buildingPurpose.id : null,
                    solarType: this.calculation.solarType ? this.calculation.solarType.id : null,
                    solarPower: this.calculation.solarPower,
                    thermSurface: this.calculation.thermSurface,
                    typicalLathGaps: this.calculation.typicalLathGaps,
                    cuttingLosses: this.calculation.cuttingLosses,
                    roofPitch: this.calculation.roofPitch,
                    selectableProducts: selectableProducts,
                    roofSurface: this.calculation.roofSurface,
                    comignolo: this.calculation.comignolo,
                    arris: this.calculation.arris,
                    valley: this.calculation.valley,
                    capLeft: this.calculation.capLeft,
                    capRight: this.calculation.capRight,
                    capOblique: this.calculation.capOblique,
                    halfGable: this.calculation.halfGable,
                    gutter: this.calculation.gutter,
                    perimeterTrim: this.calculation.perimeterTrim,
                    chimneyEdge: this.calculation.chimneyEdge,
                    roofPenNumber: this.calculation.roofPenNumber,
                    numberOfArrisTile: this.calculation.numberOfArrisTile,
                    distributorRidgeTile: this.calculation.distributorRidgeTile
                }
            }
        },

        close () {
            this.openConfirmationModal = false
            this.$store.commit('closeModal', 'planCalculation')
        }
    },

    watch: {
        planCalculation: {
            handler: function(val) {
                if(val) {
                    this.title = val.title
                    this.selectedGroupType = val.groupType
                    this.groupTypes = val.groupTypes
                    this.selectedVariant = val.variant
                    this.variants = val.variants
                    this.calculation = val.calculation
                    this.roofSurfaceCalculable = val.isNew
                } else {
                    this.tabValue = 'data'
                }
            }
        },

        'calculation.typicalLathGaps': {
            handler: function(val) {
                let value = null
                if (val > 0) {
                    value = 1 / ( this.calculation.tileWidth * ( val / 100 ) )
                    value = Math.round(value * 100) / 100
                }
                this.calculation.materialReq = value
            }
        }
    },

    computed: {
        hasPlanCalculation() {
            return this.calculation.selectableProducts !== null && Array.isArray(this.calculation.selectableProducts)
        },

        normalSelectableProducts() {
            if(!this.hasPlanCalculation) {
                return []
            }
            return this.calculation.selectableProducts.filter(p => !p.extended)
        },

        extendedSelectableProducts() {
            if(!this.hasPlanCalculation) {
                return []
            }
            return this.calculation.selectableProducts.filter(p => p.extended)
        },

        tabElements() {
            if(!this.hasPlanCalculation) {
                return []
            }

            return [
                {
                    handle: 'data',
                    handleName: this.$t('offer.plan_calculation.calculation.data.title')
                },
                ...this.extendedSelectableProducts.map(p => {
                    return {
                        handle: p.key,
                        handleName: p.extendedLabel
                    }
                }),
                {
                    handle: 'consignation',
                    handleName: this.$t('offer.plan_calculation.calculation.consignation.title')
                },
                {
                    handle: 'additional',
                    handleName: this.$t('offer.plan_calculation.calculation.additional.title')
                }
            ]
        },

        ...mapGetters(['modals', 'planCalculation', 'orderData'])
    },

    components: { Tabs, CustomSelect, CustomGroupSelect, ModalConfirmation }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        &-body {
            input[type='number'] {
                max-width: 75px;
            }

            hr {
                width: 100%;
                margin-top: 0;
                margin-bottom: 30px !important;
            }

            .label {
                display: block;
                color: $color-gray-5;
                font-size: 13px;
                margin-bottom: 7px;
            }

            .unit {
                display: inline-block;
                font-size: 13px;
                font-weight: bold;
                color: $color-gray-5;
                margin-left: 5px;
            }

            .data-container {
                margin-top: 10px;

                .input-block {
                    margin-top: 10px;
                    margin-bottom: 25px;
                    width: 272px;
                    display: inline-block;
                    margin-left: 9px;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .basic-sizes-block {
                    margin-bottom: 25px;

                    .input-col {
                        width: calc(25% - 10px);
                        display: inline-block;
                        margin-left: 10px;

                        &:first-child {
                            margin-left: 0;
                        }

                        .input-row {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .types-block {
                    margin-bottom: 25px;
                    width: 272px;
                    display: inline-block;
                    margin-left: 14px;

                    &:nth-of-type(2n) {
                        margin-left: 0;
                    }

                    &__extended {
                        width: 100%;
                    }
                }
            }

            .extended-container {
                margin-top: 20px;

                .input-block {
                    margin-top: 10px;
                    margin-bottom: 25px;
                    width: calc(50% - 70px);
                    display: inline-block;
                    margin-left: 15px;

                    &--full-width {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                    }

                    &--roof-surface {
                        margin-left: 0;
                        width: 100px;
                    }
                }
            }

            .consignment-container {
                margin-top: 20px;

                .title {
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold;
                    color: $color-black-1;
                    margin-bottom: 30px;
                }

                .col {
                    float: left;
                    display: block;
                    width: 50%;

                    &.col-first {
                        padding-right: 30px;
                    }

                    .input-row {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-bottom: 15px;

                        .label {
                            display: inline-block;
                            margin-right: 12px;
                            text-align: right;
                        }
                    }
                }
            }

            .additional-container {
                margin-top: 20px;

                .input-block {
                    margin-top: 10px;
                    margin-bottom: 25px;
                    width: calc(50% - 15px);
                    display: inline-block;
                    margin-left: 15px;

                    &--first {
                        margin-left: 0;
                    }

                    &--amounts {
                        width: calc(25% - 15px);
                    }

                    &--full-width {
                        margin-left: 0;
                        width: 100%;
                        display: block;

                        input {
                            width: 100%;
                        }
                    }
                }
            }

            .button-submit {
                i {
                    margin-left: 10px;
                }
            }


            .button-save {
                height: 40px;
                margin-left: auto;
                margin-right: 20px;
                background: #009fe3;
                color: $color-white;
                border: 0;

                &:hover {
                    opacity: 0.85;
                }

                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }
}
</style>
