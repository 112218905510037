<template>
    <div class="modal is-scrollable" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('privacy.window.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain">{{ $t('privacy.window.button.close') }}</button>
            </div>

            <div class="modal-box-body">
                <privacy-content></privacy-content>
            </div>
        </div>

        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import PrivacyContent from '../../contents/PrivacyContent.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalObjectAdd',
    props: ['open'],

    data() {
        return {
            projectName: '',
            offerData: {},
            results: []
        }
    },

    computed: {

    },

    methods: {

    },

    components: {
        PrivacyContent
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';
@import '../../assets/sass/partials/contacts.scss';

.modal {
    &-box {
        width: 1200px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow-y: visible;

            input,
            .input-label {
                width: 100%;
            }

            .autocomplete-container {
                position: relative;
            }

            .autocomplete-input {
                display: block;
                position: relative;

                &__cancel {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 33px;
                    cursor: pointer;
                }
            }

            .dropdown-list {
                top: 76px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 24px;

                    button {
                        text-decoration: none;
                    }

                    .address {
                        display: block;
                        color: $color-gray-2;
                        font-size: 10px;
                        line-height: 14px;
                    }
                }
            }
        }
    }

    &.is-scrollable {
        .modal-box-body {
            overflow-y: scroll;
            //min-height: 420px;
        }
    }
}

.button-save {
    margin-right: 25px;
}

.contacts-container {
    border-top: 1px solid $color-gray-border;
    padding-top: 20px;
}

.project-details {
    display: block;
    margin: 10px 0;

    dl {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        padding: 15px 0;
        border-bottom: 1px dashed $color-gray-border;

        dt {
            font-weight: 200;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}
</style>
