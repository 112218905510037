<template>
    <div class="filter-component" :class="{'is-one-line' : !label.length, 'has-width': !!dropdownSize}">
        <input
            :style="{
                minWidth: componentSize ? `${componentSize}px` : 'auto',
                width: fullWidth ? '100%' : 'auto'
            }"
            type="text"
            readonly="readonly"
            :value="selected ? selected.name : null"
            @click="open = !open"
        >
        <md-icon class="icon-filter">filter_list</md-icon>
        <span class="filter-label" v-if="label.length > 0">{{ label }}</span>
        <ul class="dropdown-list" :style="dropdownSize ? {minWidth: `${dropdownSize}px`} : null" v-show="open">
            <li
                v-for="option in options"
                :key="option.id"
                @click="(e) => {e.preventDefault();setFilter(option)}"
                :style="'depth' in option ? `padding-left: ${22 + (option.depth * 11)}px` : ''"
            >{{ option.name }}</li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'CustomFilter',
    props: ['options', 'value', 'label', 'dropdown-size', 'component-size', 'fullWidth'],

    data() {
        return {
            open: false
        }
    },

    methods: {
        setFilter(val) {
            this.$emit('selected', val)
            this.open = false
        },

        onClickOutside() {
            this.open = false
        }
    },

    computed: {
        selected() {
            return this.options.length > 0 ? (this.options.filter((el) => el.id === this.value)[0] || this.options[0]) : null
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';

.filter-component {
    position: relative;
    background: $color-white;

    .icon-filter {
        position: absolute;
        top: 16px;
        left: 7px;
        font-size: 18px !important;
    }

    input {
        background: transparent;
        cursor: pointer;
        padding: 22px 15px 6px 35px;
        z-index: 2;
    }

    .filter-label {
        position: absolute;
        font-size: 9px;
        color: $color-gray-5;
        text-transform: uppercase;
        top: 12px;
        left: 36px;
    }

    .dropdown-list {
        top: 53px;
        max-width: 235px;
        z-index: 100;

        li {
            position: relative;
            padding-left: 22px;

            &.selected,
            &:hover {
                &:before {
                    position: absolute;
                    content: '';
                    height: 100%;
                    width: 2px;
                    top: 0;
                    left: 0;
                    background: $color-red-2;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
            }
        }
    }

    &.is-one-line {
        input {
            padding: 14px 15px 14px 35px;
        }
    }

    &.has-width {
        .dropdown-list {
            max-width: initial;
        }
    }
}
</style>
