<template>
    <div class="services-bar-component" :class="[orderSettings.services.open ? 'active' : '', viewOnly ? 'disabled': '']">
        <div class="settings-sidebar-layer" @click="closeBar"></div>
        <div class="settings-sidebar">
            <div class="settings-sidebar-head">
                <img src="@/assets/svg/services.svg">
                <span class="name">{{ $t('offer.page.edit.category.sidebars.services.title') }}</span>
                <button type="button" @click="closeBar">
                    <md-icon>keyboard_arrow_right</md-icon>
                </button>
            </div>

            <div class="settings-sidebar-body" v-if="!loading">
                <div class="settings-sidebar-body-first-part" v-if="localPallets.length > 0" :class="{'disabled-part': disableServices}">
                    <div class="checkbox bg-white">
                        <input type="checkbox" v-model="localCustomPallets" @change="calculatePallets()" id="custom-pallets-1">
                        <label for="custom-pallets-1">{{ $t('offer.page.edit.category.sidebars.services.pallets.field.custom_pallets.label') }}</label>
                    </div>
                    <div class="checkbox bg-white" v-if="hasDemolitionFee">
                        <input type="checkbox" v-model="localCustomDemolitionPallets" @change="calculateDemolitionFee()" id="custom-demolition-pallets-1">
                        <label for="custom-demolition-pallets-1">{{ $t('offer.page.edit.category.sidebars.services.pallets.field.custom_demolition_pallets.label') }}</label>
                    </div>
                    <div
                        v-for="(pallet, index) in localPallets"
                        :key="index"
                        class="input-wrapper">
                        <img alt="" src="@/assets/svg/pallets.svg">
                        <label :for="'input-pallets-' + pallet.id">{{pallet.name}}</label>
                        <input
                            :value="pallet.amount"
                            @input="setPalletAmounts($event, index)"
                            :id="'input-pallets-' + pallet.id"
                            :disabled="!localCustomPallets"
                            v-zerohandling
                            type="number"
                            step="1"
                            class="small">
                        <span class="unit">{{ $t('offer.page.edit.category.sidebars.services.pallets.text.unit') }}</span>
                    </div>
                </div>

                <div class="settings-sidebar-body-second-part">
                    <div class="services-container" v-if="localServices.length > 0" :class="{'disabled-part': disableServices}">
                        <div class="label-row">
                            <span>{{ $t('offer.page.edit.category.sidebars.services.pallets.text.pallet_price') }}</span>
                            <span>{{ $t('offer.page.edit.category.sidebars.services.pallets.text.summary') }}</span>
                        </div>
                        <div
                            v-for="service in localServices"
                            :key="service.id"
                            class="input-row">
                            <div v-if="service.switchable" class="toggle" >
                                <input type="checkbox" v-model="service.active" :id="'service-' + service.id" @change="setServices()">
                                <label :for="'service-' + service.id">{{service.name}}</label>
                            </div>
                            <!-- <h5 v-if="!service.switchable" class="title">{{service.name}}</h5> -->
                            <div v-if="!service.switchable" class="toggle" >
                                <input type="checkbox" checked :id="'service-' + service.id" disabled>
                                <label :for="'service-' + service.id">{{service.name}}</label>
                            </div>
                            <span v-if="!service.calcDemolition">
                                <template v-if="!disableServices">
                                    {{palletsSumById(service.pallets)}}
                                    <span>x</span> {{showNetPrice ? Math.ceil(service.price) : Math.ceil(service.price * 1.27) | formatPrice}}
                                    <span>Ft</span>
                                </template>
                                <template v-else>-</template>
                            </span>
                            <span v-if="service.calcDemolition">
                                <template v-if="!disableServices">
                                    <input
                                        v-model="service.amount"
                                        @input="setServices()"
                                        :id="'input-services-' + service.id"
                                        :disabled="!localCustomDemolitionPallets"
                                        v-zerohandling
                                        min="0"
                                        step="1"
                                        type="number"
                                        class="small">
                                    <span>x</span> {{showNetPrice ? Math.ceil(service.price) : Math.ceil(service.price * 1.27) | formatPrice}}
                                    <span>Ft</span>
                                </template>
                                <template v-else>-</template>
                            </span>
                            <span v-if="service.active && !service.calcDemolition">
                                <template v-if="!disableServices">
                                    {{showNetPrice ? Math.ceil(palletsSumById(service.pallets) * service.price) : Math.ceil(palletsSumById(service.pallets) * service.price * 1.27) | formatPrice}}
                                    <span>Ft</span>
                                </template>
                                <template v-else>-</template>
                            </span>
                            <span v-else-if="service.active && service.calcDemolition">
                                <template v-if="!disableServices">
                                    {{showNetPrice ? service.amount * service.price : service.amount * service.price * 1.27 | formatPrice}} <span>Ft</span>
                                </template>
                                <template v-else>-</template>
                            </span>
                            <span v-else>
                                <template v-if="!disableServices">
                                    0 <span>Ft</span>
                                </template>
                                <template v-else>-</template>
                            </span>
                            <div class="text-info">{{service.description}}</div>
                        </div>
                    </div>

                    <div class="transport-container">
                        <span class="label">{{ $t('offer.page.edit.category.sidebars.services.shipping.title') }}</span>

                        <div class="input-wrapper input-wrapper-warehouse">
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="trl1"
                                    id="trl1"
                                    value="true"
                                    v-model="localWarehouse"
                                    @change="setWarehouse()"
                                >
                                <label for="trl1">{{ $t('offer.page.edit.category.sidebars.services.shipping.type.warehouse') }}</label>
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="trl2"
                                    id="trl2"
                                    value="false"
                                    v-model="localWarehouse"
                                    @change="setWarehouse()"
                                >
                                <label for="trl2">{{ $t('offer.page.edit.category.sidebars.services.shipping.type.construction') }}</label>
                            </div>
                        </div>

                        <div class="radio-box-container">
                            <div
                                v-for="(transport, index) in localTransports"
                                :key="transport.id"
                                v-show="transportPrices.buyer[index].amount !== false"
                                class="radio-box w-full">
                                <input type="radio" :name="'transport-' + transport.id" :id="'transport-' + transport.id" :value="transport.id" v-model="localSelectedTransport" @change="setSelectedTransport(transport.turnOffCrane)">
                                <label :for="'transport-' + transport.id">
                                    <span class="primary">{{transport.name}}</span>
                                    <span class="secondary">
                                        {{transport.description}}
                                        <template v-if="transportPrices.buyer[index].amount === null">{{ $t('offer.page.edit.category.sidebars.services.shipping.text.custom_price') }}</template>
                                    </span>
                                    <span class="last" v-if="transportPrices && transportPrices.buyer[index].amount">
                                        <span>{{showNetPrice ? transportPrices.buyer[index].amount : transportPrices.buyer[index].amount * 1.27 | formatPrice }}</span> Ft
                                    </span>
                                    <span class="last" v-if="transportPrices && transportPrices.buyer[index].amount === 0">
                                        <span>{{ $t('offer.page.edit.category.sidebars.services.shipping.text.free') }}</span>
                                    </span>
                                    <md-icon>check</md-icon>
                                </label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-number">
                            <label for="input-custom-transport">{{ $t('offer.page.edit.category.sidebars.services.shipping.field.custom_transport.label') }}</label>
                            <input
                                :value="customTransport"
                                @input="setCustomTransport($event)"
                                id="input-custom-transport"
                                v-zerohandling
                                step="1"
                                min="0"
                                type="number"
                                class="small">
                            <span class="unit">Ft</span>
                        </div>

                        <template v-if="isOffer">
                            <span class="label" v-if="isWorkerOffer">{{ $t('offer.page.edit.category.sidebars.services.shipping.text.dealer_transport_sales') }}</span>
                            <span class="label" v-else>{{ $t('offer.page.edit.category.sidebars.services.shipping.text.dealer_transport') }}</span>
                            <div class="input-wrapper input-wrapper-custom-dealer-transport">
                                <div class="checkbox">
                                    <input type="checkbox" id="chb-warehouse" v-model="localCustomDealerTransport" @change="setCustomDealerTransport()">
                                    <label for="chb-warehouse" v-if="isWorkerOffer">{{ $t('offer.page.edit.category.sidebars.services.shipping.field.custom_dealer_transport.label_sales') }}</label>
                                    <label for="chb-warehouse" v-else>{{ $t('offer.page.edit.category.sidebars.services.shipping.field.custom_dealer_transport.label') }}</label>
                                </div>
                            </div>
                            <div class="input-wrapper input-wrapper-dealer-transport input-wrapper-number">
                                <label for="input-dealer-transport" v-if="isWorkerOffer">{{ $t('offer.page.edit.category.sidebars.services.shipping.field.dealer_transport.label_sales') }}</label>
                                <label for="input-dealer-transport" v-else>{{ $t('offer.page.edit.category.sidebars.services.shipping.field.dealer_transport.label') }}</label>
                                <input
                                    :value="dealerTransport"
                                    @input="setDealerTransport($event)"
                                    :disabled="!localCustomDealerTransport"
                                    id="input-dealer-transport"
                                    v-zerohandling
                                    step="1"
                                    min="0"
                                    type="number"
                                    class="small">
                                <span class="unit">Ft</span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import Tabs from '../../components/Tabs.vue'

import { ZeroHandling } from '@/plugins/directives'

Vue.use(MdIcon)

export default {
    name: 'ServicesBar',
    props: ['loading', 'showNetPrice', 'isWorkerOffer'],
    data() {
        return {
            localPallets: [],
            localCustomPallets: false,
            localCustomDemolitionPallets: false,
            localServices: [],
            localTransports: [],
            localSelectedTransport: null,
            localWarehouse: false,
            localCustomDealerTransport: false
        }
    },

    directives: { ZeroHandling },

    watch: {
        'loading': {
            handler: function(val) {
                if (!val) {
                    this.localPallets = this.pallets
                    this.localCustomPallets = this.customPallets
                    this.localCustomDemolitionPallets = this.customDemolitionPallets
                    this.localServices = this.services
                    this.localTransports = this.transports
                    this.localSelectedTransport = this.selectedTransport
                    this.localWarehouse = this.warehouse
                    this.localCustomDealerTransport = this.customDealerTransport
                }
            }
        },

        'orderSettings.services.open': {
            handler: function(val) {
                if (val) {
                    this.localPallets = this.pallets
                    this.localCustomPallets = this.customPallets
                    this.localCustomDemolitionPallets = this.customDemolitionPallets
                    this.localServices = this.services
                    this.localTransports = this.transports
                    this.localSelectedTransport = this.selectedTransport
                    this.localWarehouse = this.warehouse
                    this.localCustomDealerTransport = this.customDealerTransport
                } else {
                    this.setServicesData()
                }
            }
        }
    },

    mounted() {},

    methods: {
        closeBar() {
            // this.setServicesData()
            this.$store.commit('closeSettingsSidebar', 'services')
        },

        palletsSumById(list) {
            let sum = 0
            list.forEach(element => {
                this.localPallets.forEach(pallet => {
                    if (pallet.id === element) {
                        sum += Number(pallet.amount)
                    }
                })
            })

            return sum
        },

        setPalletAmounts(e, index) {
            Vue.set(this.localPallets[index], 'amount', Number(e.target.value))
            Vue.set(this.localPallets[index], 'amountNoRounded', Number(e.target.value))
            this.$store.commit('setPallets', this.localPallets)
        },

        calculatePallets() {
            this.$store.commit('setCustomPallets', this.localCustomPallets)
            this.$store.commit('calculatePalletsData')
        },

        calculateDemolitionFee() {
            this.$store.commit('setCustomDemolitionPallets', this.localCustomDemolitionPallets)
            this.$store.commit('calculatePalletsData')
        },

        setServicesData() {
            this.$store.commit('setPallets', this.localPallets)
            this.$store.commit('setCustomDemolitionPallets', this.hasDemolitionFee ? this.localCustomDemolitionPallets : null)
            this.$store.commit('setCustomPallets', this.localCustomPallets)
            this.$store.commit('setServices', this.localServices)
            // this.$store.commit('setTransports', this.localTransports)
            this.$store.commit('setSelectedTransport', this.localSelectedTransport)
        },

        setServices() {
            this.$store.commit('setServices', this.localServices)
        },

        setSelectedTransport(turnOffCrane) {
            if (turnOffCrane) {
                this.localServices.forEach((service, index) => {
                    if (service.switchableByWarehouse) {
                        this.localServices[index].active = false
                        this.$store.commit('setServices', this.localServices)
                    }
                })
            }

            this.$store.commit('setSelectedTransport', this.localSelectedTransport)
        },

        setWarehouse() {
            if (this.localWarehouse === 'true') {
                this.localWarehouse = true
            } else if (this.localWarehouse === 'false') {
                this.localWarehouse = false
            }

            this.localServices.forEach((service, index) => {
                if (service.switchableByWarehouse) {
                    this.localServices[index].active = !this.localWarehouse
                }
            })

            this.$store.commit('setServices', this.localServices)
            this.$store.commit('setWarehouse', this.localWarehouse)
        },

        setDealerTransport(e) {
            this.$store.commit('setDealerTransport', Number(e.target.value))
        },

        setCustomDealerTransport() {
            this.$store.commit('setCustomDealerTransport', this.localCustomDealerTransport)
        },

        setCustomTransport(e) {
            this.$store.commit('setCustomTransport', Number(e.target.value))
        }
    },

    computed: {
        ...mapGetters(['orderSettings', 'pallets', 'customPallets', 'customDemolitionPallets', 'customTransport', 'dealerTransport', 'customDealerTransport', 'services', 'transports', 'selectedTransport', 'warehouse', 'transportPrices', 'viewOnly']),

        isOffer() {
            return this.$route.name === 'OfferCategory' || this.$route.name === 'NewOfferCategory' || this.$route.name === 'NewRelatedOffer'
        },

        disableServices() {
            let setDisable = false

            this.transports.forEach(el => {
                if (el.id === this.localSelectedTransport && el.withServices === false) {
                    setDisable = true
                }
            })

            return setDisable
        },

        hasDemolitionFee() {
            let has = false
            this.localServices.forEach(el => {
                if (el.calcDemolition) {
                    has = true
                }
            })

            return has
        }
    },

    components: { Tabs }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/settings-sidebar.scss';

.services-bar-component {
    .settings-sidebar-head {
        img {
            position: relative;
            top: 3px;
        }
    }

    .settings-sidebar-body {
        padding: 0;

        &-first-part {
            padding: 33px 26px;
            background: $color-gray-bg-2;

            .checkbox {
                width: 49%;
                min-width: 125px;
            }

            .input-wrapper {
                margin-top: 20px;

                span,
                input {
                    display: inline-block;
                }

                span, label {
                    font-size: 13px;
                    font-weight: bold;
                    margin-left: 5px;

                    &.unit {
                        color: #7f7f7f;
                    }
                }

                img {
                    vertical-align: middle;
                }

                input {
                    width: 54px;
                    margin-left: 20px;
                    margin-right: 5px;
                }

                label {
                    display: inline-block;
                    width: 150px;
                }
            }
        }

        &-second-part {
            padding: 25px 36px 15px 20px;

            .services-container {
                .label-row {
                    color: $color-gray-5;
                    font-size: 10px;
                    font-weight: bold;
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 10px;
                    text-transform: uppercase;

                    span {
                        &:nth-child(1) {
                            flex-basis: 110px;
                        }

                        &:nth-child(2) {
                            flex-basis: 100px;
                        }
                    }
                }

                .input-row {
                    border-bottom: 1px solid $color-gray-bg-2;
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    padding-bottom: 15px;
                    margin: 20px 0 10px;

                    .toggle {
                        flex-basis: calc(100% - 210px);

                        label {
                            max-width: 120px;
                        }
                    }

                    span {
                        font-size: 13px;
                        line-height: 20px;
                        font-weight: bold;

                        &:nth-child(2) {
                            flex-basis: 110px;
                        }

                        &:nth-child(3) {
                            flex-basis: 100px;
                        }

                        input[type=number] {
                            max-width: 54px;
                            display: block;
                        }

                        span {
                            font-family: $font-primary;
                        }
                    }

                    .title {
                        width: 122px;
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 20px;
                        padding-left: 16px;
                    }

                    .text-info {
                        flex-basis: 100%;
                        margin-top: 7px;
                        padding-left: 50px;
                    }
                }
            }

            .transport-container {
                .label {
                    display: block;
                    font-size: 15px;
                    font-weight: bold;
                    margin-top: 25px;
                    margin-bottom: 10px;
                }

                .radio-box-container {
                    margin-top: 20px;
                    margin-bottom: 45px;
                }

                .input-wrapper-warehouse {
                    display: inline-block;
                    margin-top: 25px;

                    .radio {
                        margin-left: 3px;

                        input {
                            float: left;
                        }
                    }
                }

                .input-wrapper-custom-dealer-transport {
                    display: inline-block;
                    margin-top: 10px;

                    .radio {
                        margin-left: 3px;

                        input {
                            float: left;
                        }
                    }
                }

                .input-wrapper-number {
                    align-items: center;
                    margin-top: 15px;

                    span,
                    input,
                    label {
                        display: inline-block;
                    }

                    span,
                    label {
                        font-size: 13px;
                        font-weight: bold;
                        margin-left: 5px;

                        &.unit {
                            color: #7f7f7f;
                            margin-left: 5px;
                        }
                    }

                    label {
                        width: 60%;
                    }

                    input[type=number] {
                        width: 31%;
                    }

                    &:last-child {
                        margin-bottom: 50px;
                    }

                    &.input-wrapper-dealer-transport {
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .disabled-part {
        position: relative;
        opacity: 0.3;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: not-allowed;
        }

        input {
            color: transparent;
        }

        .checkbox,
        .toggle {
            label {
                &:before {
                    background: $color-gray-3;
                    border-color: $color-gray-3;
                }

                &:after {
                    border-color: $color-gray-3;
                }
            }
        }
    }
}
</style>
