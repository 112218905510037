<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('order.accessory_order_statistics.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="col-left">
                    <span class="label label-date">{{ $t('order.accessory_order_statistics.field.period.label') }}</span>
                    <div class="row-input">
                        <div class="date-box">
                            <md-icon>calendar_today</md-icon>
                            <span class="date-period">{{ datePeriod }}</span>
                        </div>
                        <div>
                            <button class="btn btn-plain" type="button" @click="getThisMonth">{{ $t('order.accessory_order_statistics.option.period.this_month') }}</button>
                            <button class="btn btn-plain" type="button" @click="getThreeMonths">{{ $t('order.accessory_order_statistics.option.period.three_months') }}</button>
                        </div>
                    </div>
                    <div class="row-calendars">
                        <div class="col-calendar">
                            <span class="label">{{ $t('order.accessory_order_statistics.field.date_from.label') }}</span>
                            <date-pick
                                v-model="dateFrom"
                                :hasInputElement="false"
                                :nextMonthCaption="lang.nextMonthCaption"
                                :prevMonthCaption="lang.prevMonthCaption"
                                :setTimeCaption="lang.setTimeCaption"
                                :weekdays="lang.weekdays"
                                :months="lang.months"
                                :isDateDisabled="isFutureDate"
                            ></date-pick>
                        </div>
                        <div class="col-calendar">
                            <span class="label">{{ $t('order.accessory_order_statistics.field.date_to.label') }}</span>
                            <date-pick
                                v-model="dateTo"
                                :hasInputElement="false"
                                :nextMonthCaption="lang.nextMonthCaption"
                                :prevMonthCaption="lang.prevMonthCaption"
                                :setTimeCaption="lang.setTimeCaption"
                                :weekdays="lang.weekdays"
                                :months="lang.months"
                                :isDateDisabled="isFutureDate"
                            ></date-pick>
                        </div>
                    </div>
                    <button class="btn btn-secondary button-download" @click="downloadList" :disabled="orderCounts < 1">
                        <md-icon>save_alt</md-icon>
                        {{ $t('order.accessory_order_statistics.button.download') }}
                    </button>
                    <a href="" download ref="pdfButton" class="pdf-download"></a>
                </div>
                <div class="col-right">
                    <div class="row-number">
                        <span class="label">{{ $t('order.accessory_order_statistics.text.all_orders') }}</span>
                        <span class="number">{{orderCounts}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import moment from '../../plugins/moment'
import config from '../../plugins/config.js'
import axios from 'axios'

import DatePick from 'vue-date-pick'

Vue.use(MdIcon)

export default {
    name: 'ModalAccessoryOrderStatistics',
    props: ['open'],

    data() {
        return {
            dateFrom: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            dateTo: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            orderCounts:0,
            orderList: [],
            lang: {
                nextMonthCaption: this.$t('common.datepicker.next_month_caption'),
                prevMonthCaption: this.$t('common.datepicker.prev_month_caption'),
                setTimeCaption: this.$t('common.datepicker.set_time_caption'),
                weekdays: [
                    this.$t('common.datepicker.weekdays.mo'),
                    this.$t('common.datepicker.weekdays.tu'),
                    this.$t('common.datepicker.weekdays.we'),
                    this.$t('common.datepicker.weekdays.th'),
                    this.$t('common.datepicker.weekdays.fr'),
                    this.$t('common.datepicker.weekdays.sa'),
                    this.$t('common.datepicker.weekdays.su'),
                ],
                months: [
                    this.$t('common.datepicker.months.jan'),
                    this.$t('common.datepicker.months.feb'),
                    this.$t('common.datepicker.months.mar'),
                    this.$t('common.datepicker.months.apr'),
                    this.$t('common.datepicker.months.may'),
                    this.$t('common.datepicker.months.jun'),
                    this.$t('common.datepicker.months.jul'),
                    this.$t('common.datepicker.months.aug'),
                    this.$t('common.datepicker.months.sep'),
                    this.$t('common.datepicker.months.oct'),
                    this.$t('common.datepicker.months.nov'),
                    this.$t('common.datepicker.months.dec')
                ]
            },
            loading: true
        }
    },

    watch: {
        open: {
            handler: function(val) {
                if (val) {
                    this.fetchStatisticsData()
                }
            }
        },

        dateFrom: {
            handler: function() {
                this.countOrders()
            }
        },

        dateTo: {
            handler: function() {
                this.countOrders()
            }
        }
    },

    methods: {
        getThisMonth() {
            this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().endOf('month').format('YYYY-MM-DD')
        },

        getThreeMonths() {
            this.dateFrom = moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().endOf('month').format('YYYY-MM-DD')
        },

        downloadList() {
            let newData = {
                dateFrom: this.dateFrom,
                dateTo: this.dateTo
            }

            axios.post('accessory-order-statistics', newData, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
        },

        isFutureDate(date) {
            const lastDate = new Date(moment().endOf('month'))
            return date > lastDate
        },

        fetchStatisticsData() {
            return axios.get('accessory-order-statistics', config.AXIOS_DEFAULTS)
                .then(data => {
                    this.orderList = data.orders
                    this.countOrders()
                })
                .finally(() => {
                    this.loading = false
                })
        },

        countOrders() {
            let allCount = 0
            let dateFromTs = moment(this.dateFrom, 'YYYY-MM-DD').startOf('day').unix()
            let dateToTs = moment(this.dateTo, 'YYYY-MM-DD').endOf('day').unix()
            this.orderList.forEach(order => {
                if (order.orderedAt >= dateFromTs && order.orderedAt <= dateToTs) {
                    allCount++
                }
            })
            Vue.set(this, 'orderCounts', allCount)
        }
    },

    computed:  {
        datePeriod() {
            const begin = moment(this.dateFrom, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range')))
            let end

            if (this.dateFrom.substring(0, 4) === this.dateTo.substring(0, 4)) {
                end = moment(this.dateTo, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range_without_year')))
            } else {
                end = moment(this.dateTo, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range')))
            }

            return `${begin} - ${end}`
        }
    },

    components: { DatePick }
}
</script>

<style lang="scss">
@import '../../../node_modules/vue-date-pick/dist/vueDatePick.css';
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/datepick.scss';
.col-calendar {
    .vdpComponent {
        position: relative;
        top: 0;
        width: auto;
    }

    .vdpHeader {
        padding: 0 5px 30px;

        .vdpArrow {
            top: 10px;
            left: 0px;

            &.vdpArrowPrev {
                &:after {
                    margin-top: -5px;
                    margin-left: -3px;
                }
            }

            &.vdpArrowNext {
                left: auto;
                right: 0;

                &:after {
                    margin-top: -5px;
                    margin-left: -11px;
                }
            }
        }
    }

    .vdpInnerWrap {
        min-width: 275px;
    }

    .vdpPeriodControl>button {
        font-size: 13px;
        font-weight: 600;
    }

    .vdpPeriodControls .vdpPeriodControl:last-child button {
        font-size: 11px;
        font-weight: 500;
    }

    .vdpHeadCellContent {
        font-size: 13px;
    }
}

</style>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 1050px;

        @media #{$bp-laptop} {
            top: 10px;
        }

        &-body {
            display: flex;
            padding: 0;

            @media #{$bp-laptop} {
                max-height: calc(100vh - 100px);
            }

            .col-left {
                width: 65%;
                padding: 30px;

                input {
                    width: 100%;
                }

                .label {
                    display: block;
                    font-size: 16px;
                    margin-bottom: 15px;
                }

                .autocomplete-container {
                    position: relative;
                }

                .row-input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .date-box {
                    display: inline-flex;
                    padding: 5px 10px 12px 0;
                    align-items: center;

                    .date-period {
                        font-size: 15px;
                        font-weight: 500;
                        margin: 7px 15px 3px 7px
                    }
                }

                .btn-plain {
                    position: relative;
                    margin-left: 25px;
                }

                .row-calendars {
                    display: flex;
                    margin-top: 25px;

                    .col-calendar {
                        width: 50%;
                        text-align: center;

                        &:first-child {
                            padding-right: 10px;
                        }

                        &:last-child {
                            padding-left: 10px;
                        }

                        .label {
                            font-size: 14px;
                            margin-bottom: 15px;
                        }
                    }
                }

                .button-download {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }

                .pdf-download {
                    visibility: hidden;
                }
            }

            .col-right {
                width: 35%;
                background: $color-gray-bg-2;

                .row-number {
                    padding: 30px 30px 40px;

                    &:first-child {
                        padding-top: 100px;
                        border-bottom: 2px solid $color-gray-border;
                    }

                        .label {
                            display: block;
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 30px;
                        }

                        .number {
                            font-size: 58px;
                            font-weight: 600;
                        }
                }
            }
        }
    }
}
</style>
