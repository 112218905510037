<template>
    <div>
        <div
            class="profile-component"
            :class="{ 'open': open }"
            @click="open = !open"
            v-click-outside="onClickOutside"
        >
            <div class="full-name" v-if="profile">{{ profile.name }}</div>

            <div class="monogram" v-if="profile">
                <span>{{ profile.monogram }}</span>
            </div>

            <div class="dropdown-button">
                <md-icon>expand_more</md-icon>
            </div>

            <dropdown @clicked="onClickAction" :open="open" :options="dropdownOptions"></dropdown>
            <a href="" download ref="pdfButton" class="button-download"></a>
        </div>

        <modal-profile-settings :open="openModalSettings" @close="openModalSettings = false"></modal-profile-settings>
        <modal-offer-statistics :open="openOfferStatisticsModal" @close="openOfferStatisticsModal = false"></modal-offer-statistics>
        <modal-accessory-order-statistics :open="openAccessoryOrderStatisticsModal" @close="openAccessoryOrderStatisticsModal = false"></modal-accessory-order-statistics>
        <modal-calculation-export :open="openCalculationModal" @close="openCalculationModal = false"></modal-calculation-export>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import Dropdown from '../components/dropdowns/Dropdown.vue'
import vClickOutside from 'v-click-outside'

import ModalProfileSettings from '../components/modals/ModalProfileSettings.vue'
import ModalOfferStatistics from '../components/modals/ModalOfferStatistics.vue'
import ModalAccessoryOrderStatistics from '../components/modals/ModalAccessoryOrderStatistics.vue'
import ModalCalculationExport from '../components/modals/ModalCalculationExport.vue'
import axios from "axios";
import config from "@/plugins/config";

Vue.use(MdIcon)

export default {
    name: 'Profile',
    data() {
        return {
            open: false,
            openModalSettings: false,
            openOfferStatisticsModal: false,
            openAccessoryOrderStatisticsModal: false,
            openCalculationModal: false
        }
    },

    methods: {
        onClickOutside() {
            this.open = false
        },

        onClickAction(action) {
            switch (action) {
            case 'logout':
                this.$store.dispatch('LOGOUT_USER')
                break
            case 'products_csv':
                axios.post('products/export', null, config.AXIOS_DEFAULTS)
                    .then(data => {
                        const downloadButton = this.$refs.pdfButton
                        downloadButton.href = data.downloadUrl
                        downloadButton.click()
                    })
                break
            case 'settings':
                this.openModalSettings = true
                break
            case 'offer_statistics':
                this.openOfferStatisticsModal = true
                break
            case 'accessory_order_statistics':
                this.openAccessoryOrderStatisticsModal = true
                break
            case 'calculation':
                this.openCalculationModal = true
                break
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        ...mapGetters(['profile', 'userOrderCreate', 'userOfferStatistics', 'userAccessoryOrderStatistics', 'userCanExportPlanCalculations']),

        dropdownOptions() {
            return [
                {
                    handle: 'settings',
                    actionName: this.$t('settings.menu_title'),
                    icon: 'settings'
                },
                this.userOrderCreate ?
                    {
                        handle: 'products_csv',
                        actionName: 'Cikktörzs letöltése',
                        icon: 'download'
                    } : {},
                this.userOfferStatistics ?
                    {
                        handle: 'offer_statistics',
                        actionName: this.$t('offer.statistics.menu_title'),
                        icon: 'equalizer'
                    } : {},
                this.userAccessoryOrderStatistics ?
                    {
                        handle: 'accessory_order_statistics',
                        actionName: this.$t('order.accessory_order_statistics.menu_title'),
                        icon: 'equalizer'
                    } : {},
                this.userCanExportPlanCalculations ?
                    {
                        handle: 'calculation',
                        actionName: this.$t('offer.plan_calculation.export.menu_title'),
                        icon: 'equalizer'
                    } : {},
                {
                    handle: 'logout',
                    actionName: this.$t('common.logout'),
                    icon: 'logout'
                }
            ]
        }
    },

    components: {
        Dropdown,
        ModalProfileSettings,
        ModalOfferStatistics,
        ModalAccessoryOrderStatistics,
        ModalCalculationExport
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';

.profile-component {
    position: relative;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    .full-name {
        font-size: 14px;
        margin-right: 15px;
    }

    .monogram {
        position: relative;
        width: 44px;
        height: 44px;
        background: $color-green-1;
        border-radius: 50%;
        text-align: center;
        padding-top: 12px;
        margin-right: 5px;

        span {
            color: $color-white;
            font-size: 12px;
            font-weight: bold;
        }
    }

    .dropdown-button {
        position: absolute;
        background: $color-white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        right: 0;
        bottom: 1px;

        i {
            position: relative;
            font-size: 16px !important;
            width: 10px;
            top: 2px;
            left: -2px;
            transition: transform 0.3s ease-in;
        }
    }

    &.open {
        .dropdown-button i {
            transform: rotate(180deg);
            transition: transform 0.3s ease-out;
        }
    }

    .dropdown-component {
        top: 53px;
        right: 0;
        width: max-content;

        &:before,
        &:after {
            content: '';
            position: absolute;
            right: 6px;
            top: -5px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 5px 4px;
            border-color: transparent transparent $color-gray-border transparent;
            z-index: 100;
        }

        &:after {
            top: -3px;
            border-color: transparent transparent $color-white transparent;
        }
    }
}
</style>
