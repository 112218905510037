<template>
    <div class="object-list-page" v-if="userHasObjects" :class="{ 'is-loading' : loading }">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new" v-click-outside="() => {this.openNewObjectDropdown = false}">
                <button
                    v-if="userCanCreateObject"
                    class="btn btn-primary"
                    type="button"
                    @click="openNewObjectDropdown = !openNewObjectDropdown">
                    <md-icon>add_circle</md-icon>
                    {{ $t('object.page.list.button.create') }}
                </button>
                <product-type-dropdown
                    :open="openNewObjectDropdown"
                    :productTypes="objectStandaloneTypes"
                    @selectedType="selectedType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="object-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="objectListActiveFilters.search" :searchData="searchData"
                                @searchPhrase="onSearchPhraseChanged"></search>
                    </div>

                    <div style="display:contents;" v-if="userCanSeeFilters">
                        <custom-select
                            class="filter-component"
                            v-if="objectStandaloneTypes.length > 0"
                            :selectedItem="selectedCategory"
                            :filterable="true"
                            :large="true"
                            :fixedWidth="true"
                            :width="200"
                            :options="objectStandaloneTypes"
                            @changed="filterCategories">
                        </custom-select>

                        <custom-select
                            class="filter-component"
                            v-if="offerListDealers.length > 1"
                            :selectedItem="selectedDealer"
                            :filterable="true"
                            :large="true"
                            :fixedWidth="true"
                            :width="200"
                            :options="offerListDealers"
                            @changed="filterDealers">
                        </custom-select>

                        <custom-select
                            class="filter-component"
                            v-if="offerListAdvisories.length > 1"
                            :selectedItem="selectedAdvisory"
                            :filterable="true"
                            :large="true"
                            :fixedWidth="true"
                            :width="200"
                            :options="offerListAdvisories"
                            @changed="filterAdvisories">
                        </custom-select>

                        <div class="filter-component input-wrapper shipping-date">
                            <custom-datepicker
                                :initDate="null"
                                @changed="(el) => {fromDate = el}"
                            />
                        </div>

                        <div class="filter-component input-wrapper shipping-date">
                            <custom-datepicker
                                :initDate="null"
                                @changed="(el) => {toDate = el}"
                            />
                        </div>
                    </div>
                </div>

                <div class="object-list-page__header-controls">
                    <div>
                        <button @click="exportSelected()" type="button">
                            <md-icon>download</md-icon>
                        </button>
                        <a href="" download ref="pdfButton" class="pdf-download"></a>
                    </div>
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <sidebar-filter
                v-if="objectListFilters"
                :elements="objectListFilters"
                :selected="objectListActiveFilters.filter"
                @changed="sidebarChanged">
            </sidebar-filter>

            <basic-links position="bottom"></basic-links>

            <div class="button-container">
                <button class="btn btn-plain btn-plain-wicon button-close" type="button"
                        @click="collapsedColumn = true">
                    <md-icon>arrow_back_ios</md-icon>
                </button>
            </div>

            <button class="btn btn-plain btn-plain-wicon button-open" type="button" @click="collapsedColumn = false">
                <md-icon>arrow_forward_ios</md-icon>
            </button>
        </div>

        <div class="list-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <custom-table
                ref="table"
                :columns="tableColumns"
                :pages="objectListPages"
                :meta="objectListMeta"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-cell-click="onCellClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-name" v-if="props.column.field === 'name'">
                        <span class="small-line">{{ props.row.number }}</span>
                        <span class="large-line">{{ props.row.name }}</span>
                    </div>

                    <div class="col-dealer" v-else-if="props.column.field === 'dealer'">
                        <span class="small-line"> </span>
                        <span class="large-line">{{ props.row.dealer }}</span>
                    </div>

                    <div class="col-created" v-else-if="props.column.field === 'createdAt'">
                        <span class="small-line">{{ props.row.createdAtFormatted }}</span>
                        <span class="large-line">{{ props.row.createdPerson }}</span>
                    </div>

                    <div class="col-modified" v-else-if="props.column.field === 'modified'">
                        <span class="small-line">{{ props.row.modifiedFormatted }}</span>
                        <span class="large-line">{{ props.row.modifiedPerson }}</span>
                    </div>

                    <div class="col-options" v-else-if="props.column.field === 'options'"
                         @click="props.row.showDropdown = !props.row.showDropdown">
                        <button v-if="props.row.actions.length > 0" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            v-if="props.row.actions.length > 0"
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            :elementPayload="props.row.tags"
                        ></dropdown>
                    </div>

                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="empty_state">{{ $t('object.page.list.text.empty_state') }}</div>
            </custom-table>
        </div>
        <notification></notification>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="objectDelete"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import Vue from 'vue'
import vClickOutside from 'v-click-outside'
import {MdIcon} from 'vue-material/dist/components'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Search from '../components/Search.vue'
import SidebarFilter from '../components/SidebarFilter.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import CustomSelect from '@/components/dropdowns/CustomSelect.vue'
import CustomTable from '../components/CustomTable'
import CustomDatepicker from '../components/dropdowns/CustomDatepicker.vue'
import moment from 'moment'
import axios from "axios";
import config from "@/plugins/config";

Vue.use(MdIcon)

export default {
    name: 'ObjectList',

    data() {
        return {
            loading: true,

            searchTimer: null,

            openNewObjectDropdown: false,

            tableColumns: [
                {
                    label: this.$t('object.page.list.column.name'),
                    field: 'name',
                    width: '35%'
                },
                {
                    label: this.$t('object.page.list.column.dealer'),
                    field: 'dealer',
                    width: '27%',
                },
                {
                    label: this.$t('object.page.list.column.created_at'),
                    field: 'createdAt',
                    width: '200px',
                    // type: 'date'
                },
                {
                    label: this.$t('object.page.list.column.modified'),
                    field: 'modified',
                    width: '200px'
                },
                {
                    label: '',
                    field: 'options',
                    width: '80px'
                }
            ],

            selectedDealer: {},
            selectedAdvisory: {},
            selectedCategory: {},
            fromDate: '',
            toDate: '',
            initFromDate: '',
            initToDate: '',
            collapsedColumn: false,

            deleteObjectData: null,
            openConfirmationDeleteModal: false,
            confirmationDeleteModalData: {
                acceptText: this.$t('object.page.list.confirmation.delete.ok'),
                cancelText: this.$t('object.page.list.confirmation.delete.cancel'),
                message: this.$t('object.page.list.confirmation.delete.question')
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!from || (from.name !== 'Object')) {
                vm.$store.commit('resetObjects')
            }
        })
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasObjects) {
                const hasData = Object.keys(this.objectListPages).length > 0
                if (hasData) {
                    this.$refs.table.scrollTo(this.objectListScrollPosition)
                    this.$store.dispatch('FETCH_OBJECT_PAGES').then(() => {
                        this.loading = false
                    })
                } else {
                    this.$store.dispatch('FETCH_OBJECTS', {page: 1, keepOtherPages: false}).then(() => {
                        this.loading = false
                    })
                }

                this.initFromDate = '';
                this.initToDate = '';

                this.$store.dispatch('FETCH_OBJECT_STANDALONE_TYPES').then(() => {
                    this.objectStandaloneTypes.unshift({
                        id: 0,
                        name: 'Összes kategória'
                    });

                    this.selectedCategory = this.objectStandaloneTypes[0];
                });

                this.$store.dispatch('FETCH_OFFER_DEALERS').then(() => {
                    this.selectedDealer = this.offerListDealers[0];
                });

                this.$store.dispatch('FETCH_OFFER_ADVISORIES').then(() => {
                    this.selectedAdvisory = this.offerListAdvisories[0];
                });

            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        'fromDate': {
            handler: function () {
                this.filterFromDate();
            }
        },

        'toDate': {
            handler: function () {
                this.filterToDate();
            }
        },
    },

    methods: {
        exportSelected() {
            axios.post('objects/export', this.objectListActiveFilters, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
        },

        sidebarChanged(el) {
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                filter: el
            })
            this.refresh()
        },

        filterDealers(e) {
            this.selectedDealer = e;
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                dealer: e.id
            })
            this.refresh()
        },

        filterCategories(e) {
            this.selectedCategory = e;
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                type: e.id
            })
            this.refresh()
        },

        filterAdvisories(e) {
            this.selectedAdvisory = e;
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        filterFromDate(e){
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                from_date: this.fromDate
            })
            this.refresh()
        },

        filterToDate(e){
            this.$store.commit('setObjectListActiveFilters', {
                ...this.objectListActiveFilters,
                to_date: this.toDate
            })
            this.refresh()
        },

        onSearchPhraseChanged(phrase) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.objectListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setObjectListActiveFilters', {
                    ...this.objectListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_OBJECTS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeObjectListPage', page)
        },

        selectedType(payload) {
            this.openNewObjectDropdown = false
            this.$router.push({name: 'NewSimpleObject', params: {standaloneType: payload.id}})
        },

        onCellClick(params) {
            if (!params.row.showDropdown && params.column.field !== 'options' && !params.event.target.className.includes('element-options')) {
                this.$store.commit('setObjectListScrollPosition', this.$refs.table.getScrollPosition())
                this.$router.push({name: 'Object', params: {objectId: params.row.id}})
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        onDropdownClick(val, page) {
            if (val.action === 'object-delete') {
                this.openConfirmationDeleteModal = true
                this.deleteObjectData = {
                    id: val.id,
                    page
                }
            }
        },

        objectDelete() {
            this.openConfirmationDeleteModal = false
            this.$store.dispatch('DELETE_OBJECT', this.deleteObjectData.id).then(() => this.refresh(this.deleteObjectData.page, true))
        },

        refresh(page = 1, keepOtherPages = false) {
            this.loading = true
            this.$store.dispatch('FETCH_OBJECTS', {page, keepOtherPages}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        searchData() {
            return {
                placeholder: this.$t('object.page.list.search.placeholder'),
                tooltipText: this.$t('object.page.list.search.tooltip'),
                countText: this.$tc('object.page.list.search.name', this.objectListMeta.total)
            }
        },

        ...mapGetters([
            'objectListPages',
            'offerListDealers',
            'offerListAdvisories',
            'objectListMeta',
            'objectListFilters',
            'objectListActiveFilters',
            'objectListScrollPosition',
            'userCanCreateObject',
            'userHasObjects',
            'objectStandaloneTypes',
            'userCanSeeFilters'
        ])
    },

    components: {
        MainNavigation,
        Search,
        SidebarFilter,
        BasicLinks,
        CustomTable,
        Dropdown,
        Notification,
        ModalConfirmation,
        ProductTypeDropdown,
        CustomSelect,
        CustomDatepicker
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.object-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 500px;
            min-width: 150px;
            margin: 0px 5px 5px 0px;
        }

        .filter-component {
            margin: 5px 5px 5px 0px;
        }
    }

    &__header-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__header-search-flex {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        align-content: space-between;
    }

    &__header-controls {
        padding-right: 30px;
    }

    .col-options {
        position: relative;
    }
}
</style>
