import Paragraph from '@editorjs/paragraph';
import i18n from '../i18n'
import './sub_title.css'

export default class SubTitle extends Paragraph {
    static get enableLineBreaks() {
        return true;
    }

    static get toolbox() {
        return {
            icon: '<svg width="18" height="18" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 405.333 405.333" style="enable-background:new 0 0 405.333 405.333;" xml:space="preserve"><g><g><g><polygon points="213.333,149.333 213.333,213.333 277.333,213.333 277.333,362.667 341.333,362.667 341.333,213.333 405.333,213.333 405.333,149.333 \t\t\t"/><polygon points="277.333,42.667 0,42.667 0,106.667 106.667,106.667 106.667,362.667 170.667,362.667 170.667,106.667 277.333,106.667 \t\t\t"/>\t\t</g>\t</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.sub_title')
        };
    }

    drawView() {
        let div = super.drawView();

        div.classList.add('ejs-sub_title');

        div.addEventListener('keydown', (event) => {
            const [ENTER] = [13]; // key codes

            switch (event.keyCode) {
            case ENTER:
                document.execCommand('insertHTML', false, '<br/>');
                return false;
            }
        }, false);

        return div;
    }
}
