<template>
    <div class="order-details-page page-details" v-if="userAuthenticated">
        <job-navigation
            :loading="loading"
            :fixedToTop="true"
            :sub-title="customerOrderId"
            :isOrderDetails="true"
            :orderId="orderId"
            :offerId="offerId"
            @saveOffer="saveOrder(false)"
            @goToOffer="goToOffer()"
            @goToOrderProducts="saveOrder(true)"
            @closePage="closePage()"></job-navigation>

        <div class="wrapper" v-show="!loading">
            <div class="main-col">
                <h2 class="box-label">{{ $t('order.page.details.text.title') }}</h2>

                <div class="box order-data">
                    <span class="text-info">{{ $t('order.page.details.text.customer_order_id_info') }}</span>

                    <div class="input-wrapper" :class="{'error' : validation.customerOrderId}">
                        <label class="text-input-label" for="input-customer-order-id">{{ $t('order.page.details.field.customer_order_id.label')}}</label>
                        <input
                            v-model="details.customerOrderId"
                            type="text"
                            maxlength="20"
                            id="input-customer-order-id"
                            v-autocomplete-off>
                        <span class="input-error">{{ validation.customerOrderId }}</span>
                        <small class="text-info" for="input-customer-order-id">Amennyiben van saját rendelési száma, kérjük itt adja meg!</small>
                    </div>

                    <div class="object-container">
                        <div class="input-wrapper" :class="{'error' : validation.objectNumber}">
                            <label class="text-input-label" for="input-object-number">{{
                                    $t('order.page.details.field.object_number.label')
                                }}</label>
                            <input type="text" v-model="details.objectNumber" id="input-object-number" maxlength="8">
                            <span class="input-error">{{ validation.objectNumber }}</span>
                        </div>
                    </div>
                </div>

                <template v-if="details.canChangeCustomer">
                    <h2 class="box-label">{{ $t('order.page.details.customer.title') }}</h2>

                    <div class="box customer-data">
                        <div class="input-wrapper input-wrapper-name" :class="{'error' : validation.customerId}">
                            <label class="text-input-label">{{
                                    $t('order.page.details.customer.field.name.label')
                                }}</label>
                            <custom-select
                                :selectedItem="details.customer"
                                :options="details.customers"
                                @changed="(el) => {updateCustomerData(el)}"
                                :large="true">
                            </custom-select>
                            <span class="input-error">{{ validation.customerId }}</span>
                        </div>

                        <div class="input-wrapper input-wrapper-phone">
                            <label class="text-input-label" for="phone-order-details">{{
                                    $t('order.page.details.customer.field.phone.label')
                                }}</label>
                            <input id="phone-order-details" :value="details.customerPhone" disabled="disabled">
                        </div>
                        <div class="input-wrapper input-wrapper-email">
                            <label class="text-input-label" for="email-order-details">{{
                                    $t('order.page.details.customer.field.email.label')
                                }}</label>
                            <input
                                type="email"
                                id="email-order-details"
                                :value="details.customerEmail"
                                disabled="disabled">
                        </div>
                    </div>
                </template>

                <h2 class="box-label">{{ $t('order.page.details.transport.title') }}</h2>

                <div class="box shipping-data">
                    <span class="input-wrapper-label">{{ $t('order.page.details.transport.field.type.label') }}</span>
                    <div class="input-wrapper transport-container" :class="{'error' : validation.transportId}">
                        <div class="radio" v-for="transport in details.transports" :key="transport.id">
                            <input
                                type="radio"
                                :name="'transport-'+ transport.id"
                                :id="'transport-'+ transport.id"
                                :value="transport.id"
                                v-model="details.transportId"
                            >
                            <label :for="'transport-'+ transport.id">{{ transport.name }}</label>
                            <button class="transport-info" v-if="transport.tableInfo !== null"
                                    @click="transportInfoTitle = transport.name; transportInfo = transport.tableInfo">
                                <md-icon>info</md-icon>
                            </button>
                        </div>
                        <span class="input-error">{{ validation.transportId }}</span>
                    </div>

                    <span class="input-wrapper-label" v-if="withAddress">{{ $t('order.page.details.transport.field.name.with_address.label') }}</span>
                    <span class="input-wrapper-label"  v-else>{{ $t('order.page.details.transport.field.name.without_address.label') }}</span>

                    <template v-if="withAddress">
                        <div class="input-wrapper place">
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="warehouse"
                                    id="sl1"
                                    :value="true"
                                    v-model="details.warehouse"
                                    @change="setWarehouse()"
                                >
                                <label for="sl1">{{ $t('order.page.details.transport.option.type.warehouse') }}</label>
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="warehouse"
                                    id="sl2"
                                    :value="false"
                                    v-model="details.warehouse"
                                    @change="setWarehouse()"
                                >
                                <label for="sl2">{{
                                        $t('order.page.details.transport.option.type.construction')
                                    }}</label>
                            </div>
                        </div>

                        <div class="input-wrapper" :class="{'error' : validation.shippingName}">
                            <label class="text-input-label" for="shipping-name-order-details">{{
                                    $t('order.page.details.transport.field.shipping_name.label')
                                }}</label>
                            <input type="text" disabled v-if="details.warehouse">
                            <input
                                type="text"
                                id="shipping-name-order-details"
                                v-model="details.shippingName"
                                v-else
                            >
                            <span class="input-error">{{ validation.shippingName }}</span>
                        </div>

                        <div class="input-col col-15">
                            <div class="input-wrapper shipping-zip" :class="{'error' : validation.shippingZip}">
                                <label class="text-input-label" for="shipping-zip-order-details">{{
                                        $t('order.page.details.transport.field.shipping_zip.label')
                                    }}</label>
                                <input type="text" disabled v-if="details.warehouse">
                                <input
                                    ref="addressZip"
                                    v-model="details.shippingZip"
                                    @input="inputZip"
                                    @focus="checkZip"
                                    @blur="emptyCityList"
                                    type="number"
                                    min="1000"
                                    max="9999"
                                    id="shipping-zip-order-details"
                                    v-autocomplete-off
                                    v-disable-mouse-wheel
                                    v-else
                                >
                                <span class="input-error">{{ validation.shippingZip }}</span>
                                <ul class="dropdown-list" v-show="cityList.length > 0">
                                    <li
                                        v-for="(city, index) in cityList"
                                        :key="index"
                                        @click="writeCityName(city)">
                                        {{ city }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="input-col col-85">
                            <div class="input-wrapper shipping-city" :class="{'error' : validation.shippingCity}">
                                <label class="text-input-label" for="shipping-city-order-details">{{
                                        $t('order.page.details.transport.field.shipping_city.label')
                                    }}</label>
                                <input type="text" disabled v-if="details.warehouse">
                                <input
                                    ref="addressCity"
                                    v-model="details.shippingCity"
                                    @input="inputCity"
                                    @focus="checkCity"
                                    @blur="blurCity"
                                    id="shipping-city-order-details"
                                    type="text"
                                    v-autocomplete-off
                                    v-else
                                >
                                <span class="input-error">{{ validation.shippingCity }}</span>
                                <ul class="dropdown-list" v-show="zipList.length > 0">
                                    <li
                                        v-for="(zip, index) in zipList"
                                        :key="index"
                                        @click="writeZip(zip)">
                                        {{ zip }}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="input-wrapper" :class="{'error' : validation.shippingAddress}">
                            <label class="text-input-label" for="shipping-address-order-details">{{
                                    $t('order.page.details.transport.field.shipping_address.label')
                                }}</label>
                            <input type="text" disabled v-if="details.warehouse">
                            <input
                                type="text"
                                id="shipping-address-order-details"
                                v-model="details.shippingAddress"
                                v-autocomplete-off
                                v-else
                            >
                            <span class="input-error">{{ validation.shippingAddress }}</span>
                        </div>

                        <div class="input-wrapper loading" v-if="soloTruckIsAvailable">
                            <div class="checkbox">
                                <input type="checkbox" id="chb-1" v-model="details.shippingSoloTruck">
                                <label for="chb-1">{{
                                        $t('order.page.details.transport.field.solo_truck.label')
                                    }}</label>
                                <span class="text-info">{{ $t('order.page.details.transport.text.solo_truck') }}</span>
                            </div>
                        </div>
                    </template>

                    <div class="input-wrapper" v-if="withTruckType" :class="{'error' : validation.shippingTruckTypeId}">
                        <div class="radio-container">
                            <div class="radio"
                                 v-for="type in details.shippingTruckTypes"
                                 v-if="!details.shippingSoloTruck || type.availableForSoloTruck"
                                 :key="type.id">
                                <input
                                    type="radio"
                                    :name="'struck-' + type.id"
                                    :id="'struck-' + type.id"
                                    :value="type.id"
                                    v-model="details.shippingTruckTypeId"
                                >
                                <label :for="'struck-' + type.id">{{ type.name }}</label>
                            </div>
                        </div>
                        <span class="input-error">{{ validation.shippingTruckTypeId }}</span>
                    </div>

                    <div class="input-wrapper" :class="{'error' : validation.shippingPhone}">
                        <label class="text-input-label" for="shipping-phone-order-details">{{
                                $t('order.page.details.transport.field.shipping_phone.label')
                            }}</label>
                        <input
                            type="text"
                            id="shipping-phone-order-details"
                            v-model="details.shippingPhone"
                            v-mask="{mask: '+36 99/999 9999', greedy: true, autoUnmask: true}"
                            :placeholder="this.$t('order.page.details.transport.field.shipping_phone.placeholder')"
                        >
                        <span class="input-error">{{ validation.shippingPhone }}</span>
                    </div>

                    <div class="input-wrapper" :class="{'error' : validation.shippingEmail}">
                        <label class="text-input-label" for="shipping-email-order-details">{{
                                $t('order.page.details.transport.field.shipping_email.label')
                            }}</label>
                        <input
                            type="text"
                            id="shipping-email-order-details"
                            v-model="details.shippingEmail"
                            :placeholder="this.$t('order.page.details.transport.field.shipping_email.placeholder')"
                        >
                        <span class="input-error">{{ validation.shippingEmail }}</span>
                    </div>

                    <template v-if="withAddress">
                        <div class="input-wrapper permission" :class="{'error' : validation.needPermission}">
                            <span class="input-wrapper-label input-wrapper-label-small">{{
                                    $t('order.page.details.transport.field.need_permission.label')
                                }}</span>
                            <div class="radio" v-if="needPermissionChangeable">
                                <input
                                    type="radio"
                                    name="r-permission"
                                    id="permission1"
                                    :value="true"
                                    v-model="details.needPermission"
                                >
                                <label for="permission1">{{
                                        $t('order.page.details.transport.option.need_permission.yes')
                                    }}</label>
                            </div>
                            <div class="radio">
                                <input
                                    type="radio"
                                    name="r-permission"
                                    id="permission2"
                                    :value="false"
                                    v-model="details.needPermission"
                                >
                                <label for="permission2">{{
                                        $t('order.page.details.transport.option.need_permission.no')
                                    }}</label>
                            </div>
                            <span class="input-error">{{ validation.needPermission }}</span>
                        </div>

                        <div class="input-wrapper comment-path" :class="{'error' : validation.shippingPathInfo}">
                            <span class="text-input-label">{{
                                    $t('order.page.details.transport.field.shipping_path_info.label')
                                }}</span>
                            <textarea
                                :placeholder="this.$t('order.page.details.transport.field.shipping_path_info.placeholder')"
                                v-model="details.shippingPathInfo"
                            ></textarea>
                            <br>
                            <span class="input-error">{{ validation.shippingPathInfo }}</span>
                        </div>
                    </template>

                    <div class="input-wrapper comment" :class="{'error' : validation.shippingComment}">
                        <span class="text-input-label">{{
                                $t('order.page.details.transport.field.comment.label')
                            }}</span>
                        <textarea
                            :placeholder="this.$t('order.page.details.transport.field.comment.placeholder')"
                            v-model="details.shippingComment"
                        ></textarea>
                        <br>
                        <span class="input-error">{{ validation.shippingComment }}</span>
                    </div>

                    <span class="input-wrapper-label">
                        {{ $t('order.page.details.transport.field.shipping_date.label') }}
                    </span>

                    <div class="input-wrapper shipping-date" :class="{'error' : validation.shippingDate}">
                        <span class="text-info">{{ $t('order.page.details.transport.text.shipping_date') }}</span>
                        <datepicker
                            id="shipping-date"
                            :initDate="details.shippingDate"
                            :transport-id="details.transportId"
                            :isFullWeek="true"
                            @changed="setShippingDate" />
                        <span class="input-error">{{ validation.shippingDate }}</span>
                    </div>
                </div>

                <h2 class="box-label">{{ $t('order.page.details.discounts.title') }}</h2>

                <div class="box discount-data">
                    <span class="input-wrapper-label">{{ $t('order.page.details.discounts.field.type') }}</span>
                    <div class="input-wrapper" :class="{'error' : validation.discountType}">
                        <!--                        <div class="radio">-->
                        <!--                            <input type="radio" name="discount-2" id="discount-2" :value="2"-->
                        <!--                                   v-model="details.discountType">-->
                        <!--                            <label for="discount-2">{{ $t('common.discounts.type.sale') }}</label>-->
                        <!--                        </div>-->
                        <div class="radio">
                            <input type="radio" name="discount-5" id="discount-5" :value="5"
                                   v-model="details.discountType">
                            <label for="discount-5">{{ $t('common.discounts.type.base') }}</label>
                        </div>
                        <div class="radio" v-if="details.groupDiscounts.length > 0">
                            <input type="radio" name="discount-3" id="discount-3" :value="3"
                                   v-model="details.discountType">
                            <label for="discount-3">{{ $t('common.discounts.type.group') }}</label>
                        </div>
                        <div class="radio">
                            <input type="radio" name="discount-4" id="discount-4" :value="4"
                                   v-model="details.discountType">
                            <label for="discount-4">{{ $t('common.discounts.type.product') }}</label>
                        </div>
                        <span class="input-error">{{ validation.discountType }}</span>
                    </div>
                </div>

                <table class="table discounts-data" v-if="details.discountType === 3">
                    <tr>
                        <th>{{ $t('order.page.details.discounts.column.name') }}</th>
                        <th>{{ $t('order.page.details.discounts.column.amount') }}</th>
                    </tr>
                    <tr v-for="discount in details.groupDiscounts" :key="discount.id">
                        <td>{{ discount.name }}</td>
                        <td>
                            <input
                                v-model="discount.dealer"
                                @input="setProductDiscounts()"
                                :class="{'error' : discount.dealer < 0 || discount.dealer > 100}"
                                type="number"
                                class="small"
                                v-zerohandling
                                step='0.01'
                                min="0"
                                max="100">
                            %
                        </td>
                    </tr>
                </table>

                <h2 class="box-label">
                    <span>{{ $t('order.page.details.products.title') }}</span>
                </h2>

                <button type="button" class="btn btn-plain btn-collapse-products"
                        @click="openProductsTable = !openProductsTable">
                    <span>{{ $t('order.page.details.products.button.details') }}</span>
                    <md-icon v-show="!openProductsTable">keyboard_arrow_down</md-icon>
                    <md-icon v-show="openProductsTable">keyboard_arrow_up</md-icon>
                </button>

                <table v-show="openProductsTable" class="table products-data">
                    <tr>
                        <th>{{ $t('order.page.details.products.column.name') }}</th>
                        <th>{{ $t('order.page.details.products.column.amount') }}</th>
                        <th :colspan="numberOfSubDiscounts"
                            v-if="details.discountType === 3 || details.discountType === 4">
                            {{ $t('order.page.details.products.column.discount') }}
                        </th>
                        <th></th>
                    </tr>

                    <template v-for="product in activeProducts">
                        <tr :key="product.id">
                            <td>{{ product.name }}</td>

                            <td v-if="product.baseAmount !== null">
                                <span class="first-row">{{ product.amount }}</span>
                                <span class="second-row">({{ product.baseAmount }})</span>
                            </td>
                            <td v-else>{{ product.amount }}</td>

                            <template v-if="details.discountType === 3 && product.subDiscounts.length === 0">
                                <td :colspan="numberOfSubDiscounts">{{ product.discount | formatPrice() }}%</td>
                            </template>
                            <template v-else-if="details.discountType === 3 && product.subDiscounts.length > 0">
                                <td class="sub-discounts" v-for="subDiscount in product.subDiscounts"
                                    :key="subDiscount.id">
                                    <span class="first-row">{{
                                            product.discount[subDiscount.id] | formatPrice()
                                        }}%</span>
                                    <span class="second-row">{{ subDiscount.name }}</span>
                                </td>
                            </template>

                            <template v-if="details.discountType === 4 && product.subDiscounts.length === 0">
                                <td :colspan="numberOfSubDiscounts">
                                    <input
                                        v-model="product.discount"
                                        :class="{'error' : product.discount < 0 || product.discount > 100}"
                                        type="number"
                                        class="small"
                                        v-zerohandling
                                        step='0.01'
                                        min="0"
                                        max="100">
                                    %
                                </td>
                            </template>
                            <template v-else-if="details.discountType === 4 && product.subDiscounts.length > 0">
                                <td class="sub-discounts" v-for="subDiscount in product.subDiscounts"
                                    :key="subDiscount.id">
                                    <span class="input-row"><input
                                        v-model="product.discount[subDiscount.id]"
                                        :class="{'error' : product.discount[subDiscount.id] < 0 || product.discount[subDiscount.id] > 100}"
                                        type="number"
                                        class="small"
                                        v-zerohandling
                                        step='0.01'
                                        min="0"
                                        max="100">
                                        %</span>
                                    <span class="second-row">{{ subDiscount.name }}</span>
                                </td>
                            </template>
                            <td>
                                <button
                                    v-if="deletableProducts.length > 1 && !product.linked"
                                    @click="confirmDeleteProduct(product)"
                                    class="btn btn-plain btn-plain-wicon"
                                    type="button">
                                    <md-icon>delete</md-icon>
                                </button>
                            </td>
                        </tr>
                    </template>
                </table>

                <div class="box submit">
                    <button
                        v-if="details.canFinishOrder"
                        @click="openConfirmationModal = true"
                        class="btn btn-primary"
                        type="button">
                        <md-icon>done</md-icon>
                        {{ $t('order.page.details.button.finish_order') }}
                    </button>

                    <button
                        v-else
                        @click="saveOrder({name: 'OrderStatus', params: {orderId}})"
                        class="btn btn-secondary"
                        type="button">
                        {{ $t('order.page.details.button.save_and_back_to_details') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="saveOrder(true)">
                        {{ $t('order.page.details.button.save_and_back_to_products') }}
                    </button>

                    <hr>
                    <span>{{ $t('order.page.details.text.save') }}</span>
                </div>
            </div>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <modal-confirmation
            :open="openClosingConfirmationModal"
            :data="closingConfirmationModalData"
            @accepted="saveOrder({name: 'OrderList'})"
            @close="openClosingConfirmationModal = false"
            @secondaryAction="exitOrder"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            :isAcceptButtonDisabled="isAcceptButtonDisabled"
            @accepted="sendOrder"
            @close="openConfirmationModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openDeleteProductConfirmationModal"
            :data="deleteProductConfirmationModalData"
            @accepted="deleteProduct()"
            @close="openDeleteProductConfirmationModal = false"
        ></modal-confirmation>

        <modal-transport-info
            :info="transportInfo"
            :title="transportInfoTitle"
            :open="transportInfo !== null"
            @close="transportInfo = null">
        </modal-transport-info>

        <modal-guarantee
            :isOrder="true"
            :open="showGuaranteeAlert"
            :modal-text="guaranteeModalText"
            @close="showGuaranteeAlert = false"
        />

        <modal-shipping
            :open="showShippingModal"
            :comment="details.shippingComment"
            :date="details.shippingDate"
            :transport-id="details.transportId"
            @accepted="refreshShippingDetails"
            @close="showShippingModal = false"
        />

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

import {stickyColHandler, scrollToError} from '@/plugins/helpers'
import {MdIcon} from 'vue-material/dist/components'

import ModalGuarantee from '../components/modals/ModalGuarantee.vue'
import JobNavigation from '../components/navigations/JobNavigation.vue'
import CustomSelect from '../components/dropdowns/CustomSelect.vue'
import Tabs from '../components/Tabs.vue'
import Datepicker from '../components/dropdowns/Datepicker.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ModalShipping from '../components/modals/ModalShipping.vue'
import ModalTransportInfo from '../components/modals/ModalTransportInfo'
import i18n from '../i18n'

const deleteValidationKeysWhenWarehouse = ['shippingName', 'shippingZip', 'shippingCity', 'shippingAddress']

Vue.use(MdIcon)

export default {
    name: 'OrderDetails',

    data() {
        return {
            loading: true,
            disableScroll: false,
            transportInfo: null,
            transportInfoTitle: null,
            isAcceptButtonDisabled: false,

            showGuaranteeAlert: false,
            guaranteeModalText: null,

            customerOrderId: null,
            details: {
                customerOrderId: null,
                shippingDate: null,
                objectNumber: null,
                transports: [],
                groupDiscounts: [],
                products: [],
                customer: {
                    name: null
                }
            },
            showShippingModal: false,
            openProductsTable: true,
            openConfirmationModal: false,
            openClosingConfirmationModal: false,
            openDeleteProductConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('order.page.details.confirmation.finish_order.ok'),
                cancelText: this.$t('order.page.details.confirmation.finish_order.cancel'),
                message: this.$t('order.page.details.confirmation.finish_order.question')
            },
            closingConfirmationModalData: {
                acceptText: this.$t('order.page.details.confirmation.exit.ok'),
                cancelText: this.$t('order.page.details.confirmation.exit.cancel'),
                secondaryActionText: this.$t('order.page.details.confirmation.exit.secondary'),
                message: this.$t('order.page.details.confirmation.exit.question')
            },
            deleteProductConfirmationModalData: {
                acceptText: this.$t('order.page.details.confirmation.delete_product.ok'),
                cancelText: this.$t('order.page.details.confirmation.delete_product.cancel'),
                message: this.$t('order.page.details.confirmation.delete_product.question')
            },
            cityList: [],
            zipList: [],
            productToDelete: null
        }
    },

    watch: {
        '$route'() {
            this.fetchOrderData()
        },

        'details.customerOrderId': {
            handler: function () {
                this.removeValidation('customerOrderId')
            }
        },

        'details.objectNumber': {
            handler: function () {
                this.removeValidation('objectNumber')
            }
        },

        'details.customerId': {
            handler: function () {
                this.removeValidation('customerId')
            }
        },

        'details.shippingName': {
            handler: function () {
                this.removeValidation('shippingName')
            }
        },

        'details.shippingZip': {
            handler: function () {
                this.removeValidation('shippingZip')
            }
        },

        'details.shippingCity': {
            handler: function () {
                this.removeValidation('shippingCity')
            }
        },

        'details.shippingAddress': {
            handler: function () {
                this.removeValidation('shippingAddress')
            }
        },

        'details.shippingTruckTypeId': {
            handler: function () {
                this.removeValidation('shippingTruckTypeId')
            }
        },

        'details.shippingDate': {
            handler: function () {
                this.removeValidation('shippingDate')
            }
        },

        'details.shippingPhone': {
            handler: function () {
                if (this.details.shippingPhone && this.details.shippingPhone.length === 11) {
                    this.details.shippingPhone = this.details.shippingPhone.slice(2);
                }
                this.removeValidation('shippingPhone')
            }
        },

        'details.needPermission': {
            handler: function () {
                this.removeValidation('needPermission')
            }
        },

        'details.shippingLocationId': {
            handler: function (val) {
                if (Number(val) === 1) {
                    this.emptyShippingInputs()
                }
            }
        },

        'details.discountType': {
            handler: function (newValue, oldValue) {
                this.removeValidation('discountType')
                if (newValue === 3 || (newValue === 4 && oldValue > 0)) {
                    this.setProductDiscounts()
                }
            }
        },

        'details.transportId': {
            handler: function (newValue, oldValue) {
                this.removeValidation('customerId')
                if (typeof oldValue !== 'undefined' && oldValue !== null) {
                    this.setDefaultShippingTruckType()
                }
                if (newValue != oldValue && oldValue != null) {
                    // Date picker component checks on Invalid date as a string so we have to change it. :(
                    this.details.shippingDate = 'Invalid date';
                }
            }
        },

        'details.warehouse': {
            handler: function (newValue, oldValue) {
                if (typeof oldValue !== 'undefined' && oldValue !== null) {
                    this.setDefaultShippingTruckType()
                }
                if (newValue) {
                    this.removeValidation(deleteValidationKeysWhenWarehouse)
                }
            }
        },

        'details.shippingSoloTruck': {
            handler: function (newValue, oldValue) {
                if (typeof oldValue !== 'undefined' && oldValue !== null) {
                    this.setDefaultShippingTruckType()
                }
            }
        },

        orderDetails: {
            handler: function (val) {
                this.details = val

                if (this.details.customer === null) {
                    this.details.customer = {
                        id: null,
                        name: ''
                    }
                }
            }
        },

        validation: {
            handler: function () {
                if (this.disableScroll) {
                    this.disableScroll = false
                } else {
                    scrollToError()
                }
            }
        }
    },

    mounted() {
        this.$store.commit('emptyValidation')

        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.fetchOrderData()
        }).catch((redirect) => {
            redirect && this.$router.push({name: 'OrderList'})
        })

        this.$store.dispatch('FETCH_ZIPCODE_LIST')
        this.$store.dispatch('FETCH_CITY_LIST')

        stickyColHandler()
    },

    methods: {
        setShippingDate(el) {
            if (el != null) {
                this.details.shippingDate = el;
                this.showShippingModal = true;
            }
        },
        refreshShippingDetails(data) {
            this.details.shippingDate = data.shippingDate;
            this.details.shippingComment = data.shippingComment;
            this.showShippingModal = false;
        },

        fetchOrderData() {
            this.loading = true

            if (this.orderId) {
                let message = {
                    id: this.orderId,
                    type: 'order'
                }

                this.$store.dispatch('FETCH_ORDER_DETAILS', message).then(() => {
                    this.customerOrderId = this.orderDetails.customerOrderId
                    this.loading = false
                }).catch(() => {
                    this.$router.push({name: 'OrderList'})
                })
            } else {
                let message = {
                    id: this.offerId,
                    discountType: this.discountType,
                    type: 'offer'
                }

                this.$store.dispatch('FETCH_ORDER_DETAILS', message).then(() => {
                    this.customerOrderId = this.orderDetails.customerOrderId
                    this.loading = false
                }).catch(() => {
                    this.$router.push({name: 'OrderList'})
                })
            }
        },

        setDefaultShippingTruckType() {
            let availableIds = []

            this.details.shippingTruckTypeId = null

            this.details.transports.forEach(t => {
                if (this.details.transportId === t.id && t.withTruckType) {
                    this.details.shippingTruckTypes.forEach(tt => {
                        if (this.details.warehouse && tt.defaultForWarehouseTransport) {
                            if (!this.details.shippingSoloTruck || tt.availableForSoloTruck) {
                                this.details.shippingTruckTypeId = tt.id
                            }
                        } else if (!this.details.shippingSoloTruck || tt.availableForSoloTruck) {
                            availableIds.push(tt.id)
                        }
                    })
                }
            })

            if (this.details.shippingTruckTypeId === null && availableIds.length === 1) {
                this.details.shippingTruckTypeId = availableIds[0]
            }
        },

        setProductDiscounts() {
            let discounts = {}

            this.details.groupDiscounts.forEach((discount) => {
                let value = Number(discount.dealer)
                discounts[discount.id] = value > 100 ? 100 : (value < 0 ? 0 : value)
            })

            this.details.products.forEach((product) => {
                let discount

                if (product.subDiscounts.length > 0) {
                    discount = {}
                    product.subDiscounts.forEach(subDiscount => {
                        discount[subDiscount.id] = discounts[subDiscount.id]
                    })
                } else {
                    discount = 0
                    product.discounts.forEach((productDiscount) => {
                        if (discounts[productDiscount] > 0) {
                            discount = discounts[productDiscount]
                        }
                    })
                }

                product.discount = discount
            })
        },

        updateCustomerData(element) {
            this.details.customer = element
            this.details.customerPhone = element.phone
            this.details.customerEmail = element.email
        },

        setWarehouse() {
            if (this.details.warehouse === 'true') {
                this.details.warehouse = true
            } else if (this.details.warehouse === 'false') {
                this.details.warehouse = false
            }
        },

        inputZip() {
            this.details.shippingCity = ''
            this.cityList = []
            this.checkZip()
        },

        checkZip() {
            this.$refs.addressZip.removeAttribute('tabindex')

            let zipLength = String(this.details.shippingZip).length
            if (zipLength > 4) {
                this.details.shippingZip = Number((String(this.details.shippingZip)).substring(0, 4))
            }

            if (this.details.shippingZip !== null && String(this.details.shippingZip).length === 4) {
                this.setCity()
            } else {
                this.cityList = []
            }
        },

        inputCity() {
            this.details.shippingZip = ''
            this.zipList = []
            this.checkCity()
        },

        checkCity() {
            this.$refs.addressCity.removeAttribute('tabindex')

            if (this.details.shippingCity !== null && this.details.shippingCity.length > 2) {
                this.setZip()
            } else {
                this.zipList = []
            }
        },

        blurCity() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.details.shippingCity.toLowerCase() && el.name !== this.details.shippingCity) {
                    this.details.shippingCity = el.name
                }
            })
            this.emptyZipList()
        },

        setCity() {
            this.zipCodesList.forEach(el => {
                const sameZip = Number(el.zip) === Number(this.details.shippingZip)
                if (sameZip && el.cities.length === 1) {
                    this.$refs.addressCity.setAttribute('tabindex', '-1')
                    this.details.shippingCity = el.cities[0]
                } else if (sameZip && el.cities.length > 1) {
                    this.cityList = el.cities
                }
            })
        },

        setZip() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.details.shippingCity.toLowerCase()) {
                    if (el.zips.length === 1) {
                        this.$refs.addressZip.setAttribute('tabindex', '-1')
                        this.details.shippingZip = el.zips[0]
                    } else {
                        this.zipList = el.zips
                    }
                }
            })
        },

        writeCityName(name) {
            this.$refs.addressCity.setAttribute('tabindex', '-1')
            this.details.shippingCity = name
            this.emptyZipList()
        },

        writeZip(zip) {
            this.$refs.addressZip.setAttribute('tabindex', '-1')
            this.details.shippingZip = zip
            this.emptyCityList()
        },

        emptyCityList() {
            let that = this
            setTimeout(function () {
                if (that.cityList.length > 0) {
                    that.cityList = []
                }
            }, 250)
        },

        emptyZipList() {
            let that = this
            setTimeout(function () {
                if (that.zipList.length > 0) {
                    that.zipList = []
                }
            }, 250)
        },

        emptyShippingInputs() {
            this.details.shippingName = ''
            this.details.shippingCompany = ''
            this.details.shippingZip = ''
            this.details.shippingCity = ''
            this.details.shippingAddress = ''
        },

        closePage() {
            if (this.viewOnly || this.loading) {
                this.$router.push({name: 'OrderList'})
            }

            this.openClosingConfirmationModal = true
        },

        goToOffer() {
            this.$router.push({name: 'OfferCategory', params: {offerId: this.offerId}})
        },

        checkGuarantee() {
            this.showGuaranteeAlert = false;
            this.$store.dispatch('CHECK_OFFER_FOR_GUARANTEES', {offerId: this.offerId}).then((data) => {
                if (data) {
                    if (data.hasSpecificItems && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }

                    if (data.hasSpecificItems == false && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }
                }
            })
        },

        saveOrder(exit) {
            this.$store.commit('emptyValidation')

            if (this.loading) {
                if (this.orderId) {
                    this.$router.push({name: 'Order', params: {orderId: this.orderId}})
                } else {
                    this.$router.push({name: 'OrderList'})
                }
                return
            }

            let message = {}
            if (this.orderId) {
                message = {
                    id: this.orderId,
                    type: 'order',
                    data: this.createData()
                }
            } else {
                message = {
                    id: this.offerId,
                    type: 'offer',
                    data: this.createData()
                }
            }

            this.$store.dispatch('SEND_ORDER_DETAILS', message).then((data) => {
                if (exit) {
                    if (typeof exit === 'function') {
                        exit(data)
                    } else if (typeof exit === 'string' || (typeof exit === 'object' && exit !== null && exit.hasOwnProperty('name'))) {
                        this.$router.push(exit)
                    } else {
                        this.$router.push({name: 'Order', params: {orderId: data.id}})
                    }
                } else if (!this.orderId) {
                    this.$router.push({name: 'OrderDetails', params: {orderId: data.id}})
                } else {
                    this.$store.commit('setOrderDetails', data)
                    this.$store.commit('setTitle', data.title)
                    this.customerOrderId = this.orderDetails.customerOrderId
                }
            }).then(() => {
                this.checkGuarantee();
            })
        },

        sendOrder() {
            this.isAcceptButtonDisabled = true
            this.$store.commit('emptyValidation')

            let message = {}
            if (this.orderId) {
                message = {
                    id: this.orderId,
                    type: 'order',
                    data: this.createData()
                }
            } else {
                message = {
                    id: this.offerId,
                    type: 'offer',
                    data: this.createData()
                }
            }

            this.$store.dispatch('FINISH_ORDER', message).then((data) => {
                this.isAcceptButtonDisabled = false
                this.$router.push({name: 'OrderStatus', params: {orderId: data.id}})
            }).catch(response => {
                this.isAcceptButtonDisabled = false
                this.openConfirmationModal = false
                if (response.errors) {
                    this.$store.commit('setValidation', response.errors)
                } else {
                    this.$store.commit('setNotification', [i18n.t('order.page.details.notification.finish_order.failed')])
                }
            })

        },

        createData() {
            return {
                customerOrderId: this.details.customerOrderId,
                objectNumber: this.details.objectNumber,
                transportId: this.details.transportId,
                warehouse: this.details.warehouse,
                needPermission: this.needPermissionChangeable ? this.details.needPermission : false,
                customerId: this.details.customer.id,
                shippingName: this.details.shippingName,
                shippingPhone: this.details.shippingPhone ? '36' + this.details.shippingPhone : null,
                shippingEmail: this.details.shippingEmail,
                shippingZip: this.details.shippingZip,
                shippingCity: this.details.shippingCity,
                shippingAddress: this.details.shippingAddress,
                shippingSoloTruck: this.details.shippingSoloTruck,
                shippingTruckTypeId: this.details.shippingTruckTypeId,
                shippingComment: this.details.shippingComment,
                shippingPathInfo: this.details.shippingPathInfo,
                shippingDate: this.details.shippingDate,
                discountType: this.details.discountType,
                discounts: this.details.groupDiscounts.map(d => {
                    return {
                        id: d.id,
                        dealer: d.dealer
                    }
                }),
                products: this.details.products.map(p => {
                    return {
                        id: p.id,
                        discount: p.discount,
                        deleted: p.deleted || false
                    }
                })
            }
        },

        exitOrder() {
            this.$router.push({name: 'OrderList'})
        },

        confirmDeleteProduct(product) {
            this.productToDelete = {...product}
            this.openDeleteProductConfirmationModal = true
        },

        deleteProduct() {
            if (this.productToDelete !== null) {
                this.details.products.forEach((p, i) => {
                    if (!this.productToDelete.linked && p.id === this.productToDelete.id) {
                        Vue.set(this.details.products[i], 'deleted', true)
                    } else if (this.productToDelete.linkedProducts && this.productToDelete.linkedProducts.includes(p.id)) {
                        Vue.set(this.details.products[i], 'deleted', true)
                    }
                })
            }

            this.openDeleteProductConfirmationModal = false
        },

        removeValidation(keys) {
            if (this.validation || Object.keys(this.validation).length > 0) {
                if (typeof keys === 'string') {
                    keys = [keys]
                }

                let validation = {...this.validation}
                let changed = false

                keys.forEach(key => {
                    if (validation[key]) {
                        delete validation[key]
                        changed = true
                    }
                })

                if (changed) {
                    this.disableScroll = true
                    this.$store.commit('setValidation', validation)
                }
            }
        }
    },

    computed: {
        ...mapGetters(['orderData', 'orderDetails', 'displayNetPrice', 'zipCodesList', 'citiesList', 'validation', 'userAuthenticated']),

        orderId() {
            return this.$route.params.orderId
        },

        offerId() {
            return this.$route.params.offerId
        },

        discountType() {
            return this.$route.params.discountType || null
        },

        activeProducts() {
            return this.details.products.filter(p => !p.deleted)
        },

        deletableProducts() {
            return this.details.products.filter(p => !p.deleted && !p.linked)
        },

        numberOfSubDiscounts() {
            let max = 1

            this.details.products.forEach(p => {
                if (!p.deleted && p.subDiscounts.length > max) {
                    max = p.subDiscounts.length
                }
            })

            return max
        },

        withAddress() {
            let withAddress = false
            this.details.transports.forEach(t => {
                if (t.id === this.details.transportId) {
                    withAddress = t.withAddress
                }
            })
            return withAddress
        },

        withTruckType() {
            let withTruckType = false
            this.details.transports.forEach(t => {
                if (t.id === this.details.transportId) {
                    withTruckType = t.withTruckType
                }
            })
            return withTruckType
        },

        needPermissionChangeable() {
            let needPermissionChangeable = false
            this.details.transports.forEach(t => {
                if (t.id === this.details.transportId) {
                    needPermissionChangeable = t.needPermissionChangeable
                }
            })
            return needPermissionChangeable
        },

        soloTruckIsAvailable() {
            let available = false
            this.details.shippingTruckTypes.forEach(t => {
                available = available || t.availableForSoloTruck
            })
            return available
        }
    },

    components: {
        JobNavigation,
        CustomSelect,
        Tabs,
        ModalGuarantee,
        Datepicker,
        Notification,
        ModalConfirmation,
        ModalTransportInfo,
        ModalShipping
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/layout/page-details.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/box.scss';
@import '../assets/sass/ui/table.scss';
@import '../assets/sass/ui/loading.scss';

.order-details-page {
    overflow: hidden;
    background: $color-gray-bg-1;
    padding-top: 60px;

    .table {
        width: 100%;
    }

    .input-col {
        display: inline-block;

        &.col-50 {
            width: calc(49.5% + 1px);

            &:first-child {
                margin-right: 0.4%;
            }
        }

        &.col-15 {
            width: 15%;
            margin-right: 0.4%;

            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }

        &.col-85 {
            width: 84%;

            @media only screen and (max-width: 600px) {
                width: 100%;
            }
        }
    }

    .price-buttons-group {
        text-align: right;
        margin-bottom: 10px;

        button {
            &:nth-child(1) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                margin-right: -3px;
            }

            &:nth-child(2) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .order-data {
        .input-wrapper {
            margin-top: 20px;
        }

        #input-customer-order-id {
            width: 200px;
        }

        #input-object-number {
            width: 100px;
        }

        .object-container {
            margin-top: 35px;
            border-top: 1px solid $color-gray-border;
        }
    }

    .customer-data {
        .input-wrapper-name {
            margin-bottom: 40px;
            max-width: 485px;

            input {
                width: 485px;
            }
        }

        .input-wrapper-phone,
        .input-wrapper-email {
            display: inline-block;
        }

        .input-wrapper-phone {
            @media only screen and (max-width: 600px) {
                width: 100%;
            }

            input {
                width: 165px;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }

        .input-wrapper-email {
            margin-left: 65px;
            min-width: 255px;

            @media only screen and (max-width: 600px) {
                margin-top: 15px;
                margin-left: 0;
                width: 100%;
            }

            input {
                width: 100%;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
            }
        }
    }

    .shipping-data {
        margin-bottom: 60px;

        input[type='text'],
        input[type='number'] {
            width: 100%;
        }

        .logistics-container {
            margin-top: 25px;
            margin-bottom: 60px;

            .label-row {
                color: $color-gray-5;
                font-size: 10px;
                font-weight: bold;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
                text-transform: uppercase;

                span {
                    &:nth-child(1) {
                        flex-basis: 135px;
                    }

                    &:nth-child(2) {
                        flex-basis: 100px;
                    }
                }
            }

            .input-row {
                border-bottom: 1px solid $color-gray-bg-2;
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
                padding-bottom: 20px;
                margin: 20px 0 10px;

                .toggle {
                    flex-basis: calc(100% - 235px);
                }

                span {
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: bold;

                    &:nth-child(2) {
                        flex-basis: 135px;
                    }

                    &:nth-child(3) {
                        flex-basis: 100px;
                    }

                    span {
                        font-family: $font-primary;
                    }
                }

                .text-info {
                    flex-basis: 100%;
                    margin-top: 7px;
                    padding-left: 50px;
                }

                .title {
                    width: 122px;
                    font-size: 13px;
                    font-weight: bold;
                    line-height: 20px;
                    padding-left: 50px;
                }
            }
        }

        .pallets-container {
            background: $color-gray-bg-2;
            margin: -25px -40px 30px -40px;
            padding: 40px;
            padding-bottom: 20px;
            margin-bottom: 0;

            .input-wrapper {
                margin-top: 25px;

                * {
                    display: inline-block;
                }

                img {
                    position: relative;
                    top: 12px;
                    margin-right: 5px;
                }

                input {
                    max-width: 54px;
                    margin-left: 10px;
                    margin-right: 5px;
                }

                label {
                    width: 150px;
                }

                .unit {
                    color: $color-gray-5;
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }

        .transport-container {
            .radio {
                display: block;
                margin-bottom: 7px;
            }
        }

        .input-wrapper {
            margin-bottom: 20px;

            &.tabs {
                margin-top: -15px;
                margin-bottom: 60px;
            }

            &.place {
                margin-bottom: 20px;
            }

            &.loading {
                .text-info {
                    padding-left: 28px;
                    margin-top: 2px;
                }

                i {
                    position: relative;
                    top: 2px;
                    font-size: 14px !important;
                }
            }

            &.comment {
                margin-bottom: 50px;
            }

            &.comment-path {
                margin-bottom: 25px;

                textarea {
                    min-height: 70px
                }
            }

            &.shipping-zip {
                .dropdown-list {
                    top: 76px;
                    width: 270px;
                }
            }

            &.shipping-city {
                .dropdown-list {
                    top: 76px;
                    width: 150px;
                }
            }

            &.shipping-date {
                margin-bottom: 0;

                .text-info {
                    margin-bottom: 10px;
                }
            }

            &.permission {
                .input-error {
                    left: 0;
                }
            }
        }
    }

    .discount-data {
        padding-bottom: 25px;
        margin-bottom: 20px;

        .radio {
            display: block;
            margin-bottom: 7px;
        }
    }

    .transport-info {
        i {
            vertical-align: middle;
        }
    }

    .text-info-discounts {
        margin-bottom: 40px;
        font-size: 15px;
    }

    .discounts-data {
        margin-bottom: 50px;

        th,
        td {
            &:nth-child(1) {
                width: 75%;
            }

            &:nth-child(2) {
                width: 25%;
            }
        }

        td {
            &:nth-child(2) {
                font-weight: bold;
                display: flex;
                align-items: center;
            }

            input {
                display: inline-block;
                width: 64px;
                margin-right: 5px;
                text-align: center;

                &.error {
                    color: $color-red-1;
                }
            }
        }
    }

    .products-data {
        margin-bottom: 20px;

        th,
        td {
            &:nth-child(1) {
                width: 60%;
            }

            &:nth-child(2) {
                width: 20%;

                .first-row {
                    display: block;
                }

                .second-row {
                    font-size: 13px;
                    color: $color-gray-5;
                }
            }

            &.sub-discounts {
                width: 13%;

                .input-row {
                    white-space: nowrap;
                }

                .first-row {
                    display: block;
                }

                .second-row {
                    font-size: 12px;
                    color: $color-gray-5;
                }
            }
        }

        td {
            input {
                display: inline-block;
                width: 64px;
                text-align: center;

                &.error {
                    color: $color-red-1;
                }
            }
        }
    }

    .btn-collapse-products {
        text-decoration: none;
        margin-left: 18px;

        span {
            text-decoration: underline;
            font-size: 14px;
        }

        i {
            font-size: 20px !important;
        }
    }

    .submit {
        i {
            font-size: 18px !important;
            font-weight: 600;
        }

        .btn {
            &-primary {
                margin-right: 20px;
            }

            &-secondary {
                margin-right: 20px;
            }
        }
    }
}
</style>
