<template>
    <div>
        <h1>Felhasználási feltételek a www.epartnerbmi.hu weboldal cégen belüli (belső) felhasználói számára</h1>
        <p><strong>1.</strong> A www.epartnerbmi.hu weboldalt, mint belső felhasználó, a BMI Magyarország Kft. (BMI csoport) szaktanácsadó, alkalmazás-technikus, a vevőszolgálati területfelelős, és értékesítési vezető munkakörű munkavállalói használhatják munkaköri feladataik ellátásával kapcsolatosan. A weboldal használatára a BMI Magyarország Kft. „Szuper admin” és „Bramac admin” megnevezés alatt további munkavállalókat jelöl ki. [a továbbiakban együttesen: felhasználó(k)]</p>
        <p><strong>2.</strong> A felhasználóknak a weboldalra történő bejelentkezéshez e-mail cím, felhasználónév, és jelszó megadása szükséges. </p>
        <p><strong>3.</strong> A felhasználó a BMI Magyarország Kft. előzetes, írásos engedélye nélkül nem adhatja át kívülálló harmadik személynek a weboldal használatához szükséges e-mail címét és jelszavát. Ezen kötelezettség megszegéséből fakadó kárért a felhasználó felelős.</p>
        <p><strong>4.</strong> Az egyes belső felhasználókat a weboldal használatával összefüggésben megillető jogosultságok az alábbiak:</p>
        <ul>
            <li><strong>a.) </strong> a szaktanácsadó
                <ul>
                    <li>jogosult a területéhez tartozó szerződött partnerek (kereskedők) részére árkalkulációt készíteni, </li>
                    <li>jogosult megrendeléseket készíteni, módosítani, azokat beküldeni a SAP rendszerbe, </li>
                    <li>jogosult a hozzá tartozó kereskedői csoportokat, külső felhasználókat és a csoporthoz tartozó termékeket áttekinteni. </li>
                </ul>
            </li>
            <li><strong>b.) </strong> az alkalmazás technikus
                <ul>
                    <li>jogosult az ajánlatadás folyamatában az az ajánlatokhoz kapcsolódóan az egyes termékekre vonatkozóan terv számítást végezni és az építési tervek alapján meghatározni a szükséges mennyiségeket. </li>
                </ul>
            </li>
            <li><strong>c.) </strong> a vevőszolgálati területfelelős
                <ul>
                    <li>látja a beérkező megrendeléseket,</li>
                    <li>a megrendelések elfogadása esetén jogosult azokat tényleges megrendelésre átfordítani.</li>
                </ul>
            </li>
            <li><strong>d.) </strong> az értékesítési vezető
                <ul>
                    <li>jogosult engedélyezni a megrendelések teljesítését. </li>
                </ul>
            </li>
            <li><strong>e.) </strong> a „Szuper admin” felhasználó
                <ul>
                    <li>jogosult kezelni a termékeket, a rendszer összes felhasználóját, a naplózást és a hibakezelést.</li>
                </ul>
            </li>
            <li><strong>f.) </strong> a „Bramac admin” felhasználó
                <ul>
                    <li>jogosult kezelni a szaktanácsadókat,</li>
                    <li>kezelheti a szaktanácsadókhoz tartozó külső felhasználókat,</li>
                    <li>jogosult kezelni az ajánlatokat.</li>
                </ul>
            </li>
        </ul>
        <p><strong>5.</strong> Az árkalkuláció készítés folyamatában a felhasználó kiválasztja a termékeket, megadja az igényelt mennyiségeket, hozzáadja a kiegészítőket és tartozékokat. A megállapodott árkedvezményeket a felhasználónak a rendszerben meg kell adnia. A kedvezmények soronként, vagy termékcsoportonként adhatóak meg. A kalkulációban megjelölt termékek árának számítását és a különböző logisztikai díjakat a program automatikusan számolja. Bizonyos termékek esetén kapcsolódó termékek felajánlásra kerülnek, továbbá lehetőség van színt és más paraméterek alapján tartozékokat választani. A folyamat végén amennyiben árkalkuláció készült, PDF exportálást ajánl fel a rendszer. Az elkészült kalkuláció végén a felhasználó közvetlenül megrendelést hozhat létre. Ebben az esetben további számlázási és szállítási adatokat szükséges megadni. </p>
        <p><strong>6.</strong> A lista oldalon</p>
        <ul>
            <li><strong>a.) </strong> lehetőség van keresni, szűrni, rendezni a tételeket, illetve archiválni a már lezárt ajánlatkéréseket,
            </li>
            <li><strong>b.) </strong> láthatóak az ajánlatok státuszai, melyek a következők lehetnek: “Árkalkuláció”, “Függő megrendelés”, “Jóváhagyott”, “Jóváhagyásra vár”, “Megrendelés”,
            </li>
            <li><strong>c.) </strong> a már elkészített ajánlatok megnyithatóak szerkeszthetőek, új pdf generálható belőlük,
            </li>
            <li><strong>d.) </strong> új projekt hozható létre, a projekt módosítható, illetőleg meglévő projekthez új árkalkuláció adható.
            </li>
        </ul>
        <p><strong>7.</strong> Rendszerműködés szempontjából két típusú megrendelés folyamat kerül alkalmazásra:</p>
        <ul>
            <li><strong>a.) Közvetlen megrendelés</strong>, ha a tanácsadóval egyeztetett kedvezményeket manuálisan írja be a külső felhasználó (a kereskedő)
                <ul>
                    <li>Amennyiben egyedi megrendelés készül, előzetesen a rendszeren kívül a kereskedő és a szaktanácsadók telefonon egyeztetik a kedvezmény mértékét.</li>
                    <li>Megrendelés után “Függő megrendelés” státuszt kap. </li>
                    <li>Első validációs lépésben a regionális értékesítési vezető hagyja jóvá a rendszerben a megrendelést. Amennyiben nincs jóváhagyás, a szaktanácsadó szerkesztés módban módosítja a megrendelést. </li>
                    <li>Második validációs lépésben az értékesítési vezetőnek kell jóváhagynia a megrendelést. </li>
                    <li>A jóváhagyást követően értesítést kap a vevőszolgálati területfelelős felhasználó, aki utána beküldi az SAP rendszerbe.</li>
                    <li>Bekerül a megrendelés a SAP rendszerbe</li>
                </ul>
            </li>
            <li><strong>b.) Standard akciós megrendelés, ha a megrendelés az időszakosan elérhető akciók kiválasztásával történik:</strong>
                <ul>
                    <li>Amennyiben akciós megrendelés készül, az interakció után a rendszer értesítést küld a tanácsadónak, illetőleg bekerül az a SAP rendszerbe.</li>
                </ul>
            </li>
        </ul>
        <p><strong>8. A termékek kezelése:</strong> Az alkalmazásba a BMI Magyarország Kft. és az Icopal Kft. összes releváns terméke beépítésre került. A termékek összeköttetésben vannak a SAP rendszerben található termékekkel, így a SAP rendszerében történő árlista módosulásokat az alkalmazás automatikusan frissíti.</p>
        <p><strong>9. Egyedi beállítások kezelése:</strong> A felhasználónak lehetősége van egyedi beállítások kezelésére, ami a felhasználóhoz tartozik, így minden ajánlatot a saját beállításai szerint azonos formában láthat. (Ilyen például az árakhoz tartozó beállítások pl.: nettó-bruttó, áfával-áfa nélkül, vagy milyen eseményekről kapjon értesítéseket.)</p>
        <p><strong>10. Objekt lapok:</strong> Az objekt lapokat a nagy összegű, vagy a nagy volumenben beérkezett árajánlat kéréshez szükséges elkészíteni. Az objekt lapok az árkalkuláció paraméterei alapján automatikusan kerülnek kitöltésre, és nyomtatható pdf formátumban kerülnek elmentésre. A rendszerből a pdf-ek kinyomtathatóak.</p>
        <p><strong>11. Tervszámítás:</strong> Az előzetes árkalkuláció készítésének folyamata során előfordulnak olyan esetek, amikor a partnerek rendelkeznek tervrajzokkal az új vagy felújítandó épületről. Ebben az esetben az alkalmazástechnikus a tervek alapján ki tudja számolni, vagy az építészeti programokból ki tudja nyerni a tényleges tetőfelületet és a többi fontos méretet. Ezekből az úgynevezett konszignációs adatokból az előzetes árkalkulátor ki tudja számolni a megadott tetőcserép típusra a szükséges termékek mennyiségét, ezzel megkönnyítve és meggyorsítva az előzetes árkalkuláció készítésének a folyamatát.</p>
        <p><strong>12.</strong> Felhasználó köteles a weboldalon található árral, árengedménnyel, készlettel kapcsolatos információkat üzleti titokként kezelni, jogosulatlanul harmadik félnek nem adhatja át ezen adatokat.</p>
        <p><strong>13.</strong> A weboldalon leadott megrendelések teljesítésére a BMI Magyarország Kft. www.bramac.hu oldalon található Általános Eladási és Szállítási Feltételei és a szerződött partnerekkel kötött, egyes Kereskedelmi Szerződésekben foglaltak az irányadók.</p>
        <p><strong>14.</strong> A weboldalon működtetett előzetes árkalkuláció és megrendelés kezelő szoftver a BMI Magyarország Kft. tulajdona, így a BMI Magyarország Kft. írásos hozzájárulása nélkül annak struktúrája, tartalma (képek, adatok, stb.), működése nem másolható, felhasználható, illetve jogosulatlan másolata, használata esetén a jogsértővel szemben a BMI Magyarország Kft. jogi eszközökkel és kártérítési igénnyel fellép.</p>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'TermsContentInternal'
}
</script>

<style lang="scss" scoped>
    h1 {
        font-size: 32px;
        margin: 25px 0;
    }

    h2, h3, h4 {
        font-weight: bold;
        margin: 32px 0 16px;
    }

    p {
        margin-bottom: 16px;
    }

    table {
        margin: 16px 0;

        tr {
            td, th {
                border: 1px solid #535353;
                padding: 5px;
            }
        }
    }

    ul {
        padding-left: 20px;

        & > li {
            list-style-type: none;
            margin: 16px 0;

            & > ul > li {
                list-style-type: disc;
                margin-left: 15px;
            }
        }
    }
</style>
