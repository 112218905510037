<template>
    <div class="dealer-list-page" v-if="userHasDealers">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new" :class="{'dashboard-header__new--wide': userCanCreateDealer && userCanChangeDealerProducts}">
                <button
                    v-if="userCanCreateDealer"
                    @click="newDealer"
                    class="btn btn-primary"
                    type="button">
                    <md-icon>add_circle</md-icon> {{ $t('dealer.page.list.button.create') }}
                </button>
                <button
                    v-if="userCanChangeDealerProducts"
                    @click="goToDealerProducts"
                    class="btn btn-secondary"
                    type="button">{{ $t('dealer.page.list.button.products') }}</button>
            </div>

            <div class="dashboard-header__filters">
                <div class="dealer-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="dealerListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                    </div>

                    <custom-filter
                        v-if="dealerListAdvisories.length > 0"
                        :options="dealerListAdvisories"
                        :value="dealerListActiveFilters.advisory"
                        :label="''"
                        @selected="filterAdvisory"
                    ></custom-filter>

                    <custom-filter
                        v-if="dealerListTypes.length > 0"
                        :options="dealerListTypes"
                        :value="dealerListActiveFilters.type"
                        :label="''"
                        @selected="filterType"
                    ></custom-filter>
                </div>

                <div>
                    <button @click="exportSelected()" type="button">
                        <md-icon>download</md-icon>
                    </button>
                    <a href="" download ref="pdfButton" class="pdf-download"></a>
                </div>

                <div class="dealer-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <sidebar-filter
                v-if="dealerListFilters"
                :elements="dealerListFilters"
                :selected="dealerListActiveFilters.filter"
                @changed="sidebarChanged"
            ></sidebar-filter>

            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <custom-table
                ref="table"
                :columns="tableColumns"
                :pages="dealerListPages"
                :meta="dealerListMeta"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <span class="col-advisories" v-if="props.column.field === 'advisoryUsers'">
                        <span v-for="(name, index) in props.row.advisoryUsers" :key="index">{{ name }}</span>
                    </span>

                    <div class="col-options" v-else-if="props.column.field === 'options' && props.row.actions.length > 0">
                        <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                        ></dropdown>
                    </div>

                    <div v-else-if="props.column.field !== 'options'">{{ props.formattedRow[props.column.field] }}</div>
                </template>
                <div slot="empty_state">{{ $t('dealer.page.list.text.empty_state') }}</div>
            </custom-table>
        </div>

        <modal-dealer-add
            :open="openDealerModal"
            :dealerId="selectedDealer"
            @close="dealerEditEnd">
        </modal-dealer-add>

        <notification></notification>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="dealerDelete"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationForceDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="forceDealerDelete"
            @close="openConfirmationForceDeleteModal = false"
        ></modal-confirmation>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalDealerAdd from '../components/modals/ModalDealerAdd.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation'
import SidebarFilter from '../components/SidebarFilter'
import CustomTable from '../components/CustomTable'
import axios from "axios";
import config from "@/plugins/config";

Vue.use(MdIcon)

export default {
    name: 'DealerList',

    data() {
        return {
            loading: true,
            openDealerModal: false,
            selectedDealer: null,

            searchTimer: null,

            deleteDealerId: null,
            openConfirmationDeleteModal: false,
            openConfirmationForceDeleteModal: false,
            confirmationDeleteModalData: {
                acceptText: this.$t('dealer.page.list.confirmation.delete.ok'),
                cancelText: this.$t('dealer.page.list.confirmation.delete.cancel'),
                message: this.$t('dealer.page.list.confirmation.delete.question')
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!from || (from.name !== 'DealerProducts')) {
                vm.$store.commit('resetDealers')
            }
        })
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasDealers) {
                const hasData = Object.keys(this.dealerListPages).length > 0

                if (hasData) {
                    this.$refs.table.scrollTo(this.dealerListScrollPosition)
                    this.$store.dispatch('FETCH_DEALER_PAGES').then(() => this.loading = false)
                } else {
                    this.$store.dispatch('FETCH_DEALERS', {page: 1, keepOtherPages: false}).then(() => this.loading = false)
                }
            } else {
                this.$router.push('/');
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    methods: {
        exportSelected() {
            axios.post('dealers/export', this.dealerListActiveFilters, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
        },

        sidebarChanged(el) {
            this.$store.commit('setDealerListActiveFilters', {
                ...this.dealerListActiveFilters,
                filter: el
            })
            this.refresh()
        },

        filterAdvisory(e) {
            this.$store.commit('setDealerListActiveFilters', {
                ...this.dealerListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        filterType(e) {
            this.$store.commit('setDealerListActiveFilters', {
                ...this.dealerListActiveFilters,
                type: e.id
            })
            this.refresh()
        },

        onSearchPhraseChanged(phrase) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.dealerListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setDealerListActiveFilters', {
                    ...this.dealerListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        onRowClick(params) {
            if (!params.row.showDropdown && !params.event.target.className.includes('element-options')) {
                this.selectedDealer = params.row.id
                this.openDealerModal = true
            }
        },

        onDropdownClick(val) {
            switch (val.action) {
            case 'dealer-products':
                this.$store.commit('setDealerListScrollPosition', this.$refs.table.getScrollPosition())
                this.$router.push({name: 'DealerProducts', params: {userId: val.id}})
                break
            case 'dealer-delete':
                this.deleteDealerId = val.id
                this.openConfirmationDeleteModal = true
                break
            case 'dealer-force-delete':
                this.deleteDealerId = val.id
                this.openConfirmationForceDeleteModal = true
                break
            }
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_DEALERS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeDealerListPage', page)
        },

        dealerDelete() {
            let payload = {
                id: this.deleteDealerId
            }

            this.$store.dispatch('DELETE_DEALER', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('dealer.page.list.confirmation.delete.notification.success')])
                this.deleteDealerId = null
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('dealer.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteModal = false
            })
        },

        forceDealerDelete() {
            let payload = {
                id: this.deleteDealerId
            }

            this.$store.dispatch('FORCE_DELETE_DEALER', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('dealer.page.list.confirmation.delete.notification.success')])
                this.deleteDealerId = null
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('dealer.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationForceDeleteModal = false
            })
        },

        dealerEditEnd(refresh) {
            this.openDealerModal = false
            if (refresh) {
                this.refreshPages()
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        newDealer() {
            this.selectedDealer = null
            this.openDealerModal = true
        },

        goToDealerProducts() {
            this.$store.commit('setDealerListScrollPosition', this.$refs.table.getScrollPosition())
            this.$router.push({name: 'DealerProducts'})
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_DEALERS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },

        refreshPages() {
            this.loading = true
            this.$store.dispatch('FETCH_DEALER_PAGES').then(() => {
                this.loading = false
            })
        }
    },

    computed: {
        ...mapGetters(['dealerListPages', 'dealerListFilters', 'dealerListAdvisories', 'dealerListTypes', 'dealerListMeta', 'dealerListActiveFilters', 'dealerListScrollPosition', 'userHasDealers', 'userCanCreateDealer', 'userCanChangeDealerProducts']),

        searchData() {
            return {
                placeholder: this.$t('dealer.page.list.search.placeholder'),
                tooltipText: this.$t('dealer.page.list.search.tooltip'),
                countText: this.$tc('dealer.page.list.search.name', this.dealerListMeta.total)
            }
        },

        tableColumns() {
            return [
                {
                    label: this.$t('dealer.page.list.column.name'),
                    field: 'name',
                    width: '45%'
                },
                {
                    label: this.$t('dealer.page.list.column.sap_number'),
                    field: 'sapNumber',
                    width: '12%'
                },
                {
                    label: this.$t('dealer.page.list.column.advisory_users'),
                    field: 'advisoryUsers',
                    width: '12%'
                },
                {
                    label: this.$t('dealer.page.list.column.type'),
                    field: 'type',
                    width: '15%',
                },
                {
                    label: '',
                    field: 'options',
                    width: '6%'
                }
            ]
        }
    },

    components: {
        MainNavigation,
        BasicLinks,
        CustomTable,
        Search,
        Dropdown,
        ModalDealerAdd,
        Notification,
        CustomFilter,
        ModalConfirmation,
        SidebarFilter
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.dealer-list-page {
    .dashboard-header__new {
        &--wide {
            flex-basis: 400px;
        }

        .btn-secondary {
            margin-left: 10px;
            width: 165px;
        }
    }

    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: 95%;
    }

    &__header-controls {
        padding-right: 30px;
    }

    .col-advisories {
        span {
            display: block;
        }
    }

    .list-column {
        font-weight: 500;
    }
}
</style>
