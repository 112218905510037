<template>
    <div :style="isProductAdminRouteStyle" class="basic-links-component" :class="position">
        <button class="btn btn-plain btn-bug-reporting" type="button" @click="openModalBugReport = true">
            <md-icon>feedback</md-icon>
            <span>{{ $t('common.sidebar.button.feedback') }}</span>
        </button>
        <hr>
        <router-link :to="{name: 'Privacy'}" class="btn btn-plain">{{ $t('common.sidebar.button.privacy') }}</router-link>
        <router-link :to="{name: 'Terms'}" class="btn btn-plain">{{ $t('common.sidebar.button.terms') }}</router-link>
        <a :href="gtc" target="_blank" class="btn btn-plain">{{ $t('common.sidebar.button.gtc') }}</a>
        <div class="version-line">{{ $t('common.sidebar.version', {version}) }}</div>
        <modal-bug-report :open="openModalBugReport" @close="() => {this.openModalBugReport = false}"></modal-bug-report>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import ModalBugReport from '../components/modals/ModalBugReport.vue'

import config from '../plugins/config.js'

Vue.use(MdIcon)

export default {
    name: 'BasicLinks',
    props: ['position'],

    data() {
        return {
            openModalBugReport: false,
            version: process.env.VERSION,
            priceListDate: null
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_PRICE_LIST').then(data => {
            this.priceListDate = data.date
        })
    },

    computed: {
        gtc() {
            return config.GTC_URL
        },
        isProductAdminRouteStyle(){
            return this.$route.name === 'ProductList' ? { position: 'absolute' } : {}
        }
    },

    components: { ModalBugReport }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.basic-links-component {
    width: 100%;

    &.bottom {
        //position: absolute;
        //height: 100px;
        bottom: 0;
        background: white;
        //overflow-y: scroll;
    }

    a {
        display: block;
        font-weight: bold;
        padding: 2px 0;
        margin: 6px auto 6px 24px;
        color: $color-gray-3;
    }

    hr {
        margin: 12px 0 !important;
    }

    .btn-bug-reporting {
        color: $color-blue-1;
        text-decoration: none;
        margin-left: 24px;
        margin-bottom: 5px;

        i {
            position: relative;
            top: 4px;
            color: $color-blue-1;
        }
    }

    .version-line {
        font-size: 10px;
        text-transform: uppercase;
        color: $color-gray-3;
        margin: 30px auto 15px 24px;
    }
}

@media only screen and (max-height: 780px) {
    .basic-links-component {
        &.bottom {
            position: static;
            margin-top: 80px;
        }
    }
}
</style>
