<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ user.title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <div class="col-info" v-if="user.info">
                    {{ user.info }}
                    <hr>
                </div>

                <div class="col-left">
                    <div class="input-wrapper" :class="{'error' : errors.username}">
                        <label class="text-input-label"
                               for="username-user-add">{{ $t('user.manage.field.username.label') }}</label>
                        <input
                            type="text"
                            v-model="user.username"
                            id="username-user-add"
                            :disabled="!user.editable">
                        <span class="input-error">{{ errors.username }}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.name}">
                        <label class="text-input-label" for="name-user-add">{{
                                $t('user.manage.field.name.label')
                            }}</label>
                        <input type="text" v-model="user.name" id="name-user-add" :disabled="!user.editable">
                        <span class="input-error">{{ errors.name }}</span>
                    </div>
                    <div v-if="hasSapCode" class="input-wrapper" :class="{'error' : errors.sapCode}">
                        <label class="text-input-label"
                               for="email-user-sap-code">{{ $t('user.manage.field.sap_code.label') }}</label>
                        <input type="text" v-model="user.sapCode" id="email-user-sap-code" :disabled="!user.editable">
                        <span class="input-error">{{ errors.sapCode }}</span>
                    </div>
                    <div v-if="hasPhone" class="input-wrapper" :class="{'error' : errors.phone}">
                        <label class="text-input-label" for="email-user-phone">{{
                                $t('user.manage.field.phone.label')
                            }}</label>
                        <input type="text" v-model="user.phone" id="email-user-phone" :disabled="!user.editable">
                        <span class="input-error">{{ errors.phone }}</span>
                    </div>
                    <template v-if="user.advisoryTypes && user.advisoryTypes.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.advisoryTypeId}">
                            <label class="text-input-label">{{ $t('user.manage.field.advisory_type.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.advisoryType"
                                :options="user.advisoryTypes"
                                @changed="selectedAdvisoryType"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.advisoryTypeId }}</span>
                        </div>
                    </template>
                </div>
                <div class="col-right">
                    <div class="input-wrapper" :class="{'error' : errors.email}">
                        <label class="text-input-label" for="email-user-add">{{
                                $t('user.manage.field.email.label')
                            }}</label>
                        <input type="text" v-model="user.email" id="email-user-add" :disabled="!user.editable">
                        <span class="input-error">{{ errors.email }}</span>
                    </div>
                    <template v-if="user.parents && user.parents.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.parentId}">
                            <label class="text-input-label">{{ $t('user.manage.field.parent.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.parent"
                                :options="user.parents"
                                @changed="selectedParent"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.parentId }}</span>
                        </div>
                    </template>
                    <template v-if="user.appEngineers && user.appEngineers.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.appEngineerId}">
                            <label class="text-input-label">{{ $t('user.manage.field.app_engineer.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.appEngineer"
                                :options="user.appEngineers"
                                @changed="selectedAppEngineer"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.appEngineerId }}</span>
                        </div>
                    </template>
                    <template v-if="user.authorizingUsers && user.authorizingUsers.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.authorizingUserId}">
                            <label class="text-input-label">{{ $t('user.manage.field.authorizing_user.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.authorizingUser"
                                :options="user.authorizingUsers"
                                @changed="selectedAuthorizingUser"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.authorizingUserId }}</span>
                        </div>
                    </template>
                    <template v-if="user.temporaryUsers && user.temporaryUsers.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.temporaryUserId}">
                            <label class="text-input-label">{{ $t('user.manage.field.temporary_user.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.temporaryUser"
                                :options="user.temporaryUsers"
                                @changed="selectedTemporaryUser"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.temporaryUserId }}</span>
                        </div>
                    </template>

                    <template v-if="showRoles()">
                        <div class="input-wrapper" :class="{'error' : errors.userRole}">
                            <label class="text-input-label">{{ $t('user.manage.field.role.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.userRole"
                                :options="filteredUserRoles"
                                @changed="selectedRole"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.role }}</span>
                        </div>
                    </template>

                    <template v-if="user.dealers && user.dealers.length > 0">
                        <div class="input-wrapper" :class="{'error' : errors.dealerId}">
                            <label class="text-input-label">{{ $t('user.manage.field.dealer.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user.dealer"
                                :options="user.dealers"
                                @changed="selectedDealer"
                                :large="true"
                                :disabled="!user.editable"
                                :cursorNotAllowed="user.editable"
                                :bold="user.editable"
                            ></custom-select>
                            <span class="input-error">{{ errors.dealerId }}</span>
                        </div>
                    </template>

                    <div v-if="hasPosition" class="input-wrapper" :class="{'error' : errors.position}">
                        <label class="text-input-label"
                               for="email-user-position">{{ $t('user.manage.field.position.label') }}</label>
                        <input type="text" v-model="user.position" id="email-user-position" :disabled="!user.editable">
                        <span class="input-error">{{ errors.position }}</span>
                    </div>
                </div>
                <div class="button-container" v-if="user.editable">
                    <button v-if="isEdit" class="btn btn-primary button-save" type="button" @click="saveUser"
                            :disabled="loading">
                        {{ $t('user.manage.button.save') }}
                    </button>
                    <button v-else class="btn btn-primary button-save" type="button" @click="addUser"
                            :disabled="loading">
                        {{ $t('user.manage.button.add') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">
                        {{ $t('user.manage.button.cancel') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="() => {this.saveUser(); this.confirmedEmailSending = true; this.openConfirmationModal = false}"
            @close="openConfirmationModal = false"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import {MdIcon} from 'vue-material/dist/components'

import '@desislavsd/vue-select/dist/vue-select.css'

import CustomSelect from '../dropdowns/CustomSelect'
import ModalConfirmation from '../../components/modals/ModalConfirmation.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalUserAdd',
    props: ['open', 'userRole', 'userId', 'allUserRoles'],

    data() {
        return {
            loading: false,
            confirmedEmailSending: false,
            errors: {},
            user: {
                editable: false,
                title: '',
                username: '',
                email: '',
                name: '',
                sapCode: null,
                position: null,
                phone: null,
                parent: null,
                parents: [],
                appEngineer: null,
                appEngineers: [],

                temporaryUser: null,
                temporaryUsers: [],

                authorizingUser: null,
                authorizingUsers: [],

                advisoryType: null,
                advisoryTypes: [],

                dealer: null,
                dealers: [],

                userRole: null,
                role: null,
                roles: []
            },
            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('user.manage.confirmation.ok'),
                cancelText: this.$t('user.manage.confirmation.cancel'),
                message: this.$t('user.manage.confirmation.question')
            }
        }
    },

    watch: {
        'open': function (opened) {
            let payload
            if (opened) {
                this.loading = true
                setTimeout(() => {
                    this.errors = {}
                    this.confirmedEmailSending = false

                    payload = {
                        id: this.userId,
                        role: this.userRole
                    }

                    this.$store.dispatch('FETCH_USER', payload).then(user => {
                        this.user = user
                        this.user.userRole = user.userRole
                        this.user.role = user.role
                        this.user.roles = user.roles
                        this.loading = false
                    }).catch(e => {
                        console.error(e)
                        this.$emit('close')
                    })
                }, 300);
            }
        },
    },

    methods: {
        selectedRole(val) {
            let newRole = {
                id: val.id,
                name: val.name
            };
            this.user.userRole = newRole
        },

        selectedDealer(val) {
            this.user.dealer = val
        },

        selectedParent(val) {
            this.user.parent = val
        },

        selectedAppEngineer(val) {
            this.user.appEngineer = val
        },

        selectedTemporaryUser(val) {
            this.user.temporaryUser = val
        },

        selectedAuthorizingUser(val) {
            this.user.authorizingUser = val
        },

        selectedAdvisoryType(val) {
            this.user.advisoryType = val
        },

        addUser() {
            if (this.confirmedEmailSending) {
                this.saveUser()
            } else {
                this.openConfirmationModal = true
            }
        },

        showRoles() {
            return true;
            //return this.user.roles && this.user.roles.length
        },

        saveUser() {
            this.errors = {}

            let payload = {
                id: this.userId,
                role: this.userRole,
                dealerOnly: this.userDealerOnly,
                data: {
                    username: this.user.username,
                    name: this.user.name,
                    email: this.user.email,
                    role: this.user.userRole ? this.user.userRole.id : null,
                    dealerId: this.user.dealer ? this.user.dealer.id : null,
                    parentId: this.user.parent ? this.user.parent.id : null,
                    appEngineerId: this.user.appEngineer ? this.user.appEngineer.id : null,
                    temporaryUserId: this.user.temporaryUser ? this.user.temporaryUser.id : null,
                    authorizingUserId: this.user.authorizingUser ? this.user.authorizingUser.id : null,
                    advisoryTypeId: this.user.advisoryType ? this.user.advisoryType.id : null,
                    sapCode: this.user.sapCode ? this.user.sapCode : null,
                    position: this.user.position ? this.user.position : null,
                    phone: this.user.phone ? this.user.phone : null,
                }
            }

            this.$store.dispatch('SEND_USER', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    computed: {
        isEdit() {
            return this.userId !== null;
        },
        hasSapCode() {
            return this.user.hasOwnProperty('sapCode')
        },
        hasPosition() {
            return this.user.hasOwnProperty('position')
        },
        hasPhone() {
            return this.user.hasOwnProperty('phone')
        },
        filteredUserRoles() {
            // Restricted roles cannot be anything from disallowed roles array!
            const restrictedRoles = ['application_engineer', 'dealer_offer', 'dealer_order', 'dealer_accessory', 'dealer_offer_order', "dealer_order_offer", 'sap_user', 'student']
            const disallowedRoles = ['admin', 'superadmin', 'leader', 'advisory'];

            if(this.user.userRole != null && restrictedRoles.includes(this.user.userRole.id)){
                return this.allUserRoles.filter(role => !disallowedRoles.includes(role.id));
            }

            return this.allUserRoles;
        }
    },

    components: {CustomSelect, ModalConfirmation, Notification}
}
</script>

<style lang="scss">
@import '../../assets/sass/ui/vueselectbar.scss';
</style>
<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/select.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    height: auto;

    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            input,
            select {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }
            }

            .col-info {
                font-size: 13px;
                font-weight: bold;
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
