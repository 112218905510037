<template>
    <div class="modal" :class="{ 'is-open': modals.editComment.open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <button type="button" @click="close" class="btn btn-plain btn-plain-wicon">
                    <md-icon>keyboard_arrow_left</md-icon>
                </button>
                <span class="title">{{ $t('offer.edit_comment.title') }}</span>
            </div>
            <div class="modal-box-body">
                <label class="label" for="textarea-edit-comment">{{ $t('offer.edit_comment.field.comment.label') }}</label>
                <textarea v-model="commentText" id="textarea-edit-comment"></textarea>
                <button
                    @click="save"
                    v-show="commentText.length > 4"
                    class="btn btn-secondary"
                >{{ $t('offer.edit_comment.button.save') }}</button>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalEditComment',
    props: ['initIndex', 'initText'],

    data() {
        return {
            commentIndex: 0,
            commentText: ''
        }
    },

    watch: {
        'modals.editComment.open': function(val) {
            if (val) {
                this.commentIndex = this.initIndex
                this.commentText = this.initText
            }
        }
    },

    methods: {
        save() {
            let message = {
                index: this.commentIndex,
                type: 'personal',
                text: this.commentText
            }
            this.$store.commit('updateSavedComment', message)
            this.$store.commit('closeModal', 'editComment')
        },

        close() {
            this.$store.commit('closeModal', 'editComment')
        }
    },

    computed: {
        ...mapGetters(['modals'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            button {
                float: none;

                i {
                    font-size: 36px !important;
                }
            }
        }

        &-body {
            .label {
                font-size: 14px;
            }

            textarea {
                margin: 10px 0;
            }
        }
    }
}
</style>
