<template>
    <div class="object-add-page" v-if="userHasObjects" >
        <job-navigation
            :loading="loading"
            :isObjectAdd="true"
            @saveObjectAdd="saveObjectAdd"
            :fixedToTop="true"
            @exportObject="exportToPdf"
            :orderId="orderId"
            :viewOnly="viewOnly"
            :loadingExport="exporting"
            @closePage="closePage()">
        </job-navigation>

        <div class="container" v-show="!loading">
            <h2>{{ $t('object.page.edit.details.title') }}</h2>

            <div class="box box--object">
                <div class="label-group label-group-code input-wrapper" :class="{'error' : error.number}">
                    <div class="label-row">
                        <div>
                            <span class="label">{{ $t('object.page.edit.details.field.number.advisory') }}</span>

                            <div class="sup-group">
                                <input :disabled="viewOnly" type="text" placeholder="B12" maxlength="3" ref="sztCode" v-model="objectData.number.advisory" v-on:keyup="lengthCheck(3, 'sztCode', 'countyCode')">
                            </div>
                        </div>

                        <div>
                            <span class="label">{{ $t('object.page.edit.details.field.number.county') }}</span>

                            <div class="sup-group">
                                <input :disabled="viewOnly" type="text" placeholder="12" maxlength="2" ref="countyCode" v-model="objectData.number.county" v-on:keyup="lengthCheck(2, 'countyCode', 'objectCode')">
                            </div>
                        </div>

                        <div>
                            <span class="label">{{ $t('object.page.edit.details.field.number.increment') }}</span>

                            <div class="sup-group">
                                <input :disabled="viewOnly" type="text" placeholder="123" maxlength="3" ref="objectCode" v-model="objectData.number.increment">
                            </div>
                        </div>
                    </div>
                    <span class="input-error">{{ error.number }}</span>
                </div>

                <div class="label-group input-wrapper">
                    <div class="label-row">
                        <div>
                            <span class="label">{{ $t('object.page.edit.details.field.project_name.label') }}</span>

                            <div class="sup-group">
                                <input :disabled="viewOnly" type="text" class="project-data" v-model="objectData.projectName">
                            </div>
                        </div>
                    </div>
                    <div class="label-row">
                        <div>
                            <span class="label">{{ $t('object.page.edit.details.field.project_address.label') }}</span>

                            <div class="sup-group">
                                <input :disabled="viewOnly" type="text" class="project-data" v-model="objectData.projectAddress">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="contacts-container" >
                    <template v-if="orderId === null">
                        <span class="text-input-label">{{ $t('object.page.edit.details.dealer.title') }}</span>
                        <div class="contacts-row input-wrapper" :class="{'error' : error.dealerName}">
                            <span class="label">{{ $t('object.page.edit.details.dealer.field.name.label') }}:</span>
                            <div class="element-wrapper">
                                <input :disabled="viewOnly" type="text" v-model="objectData.dealerName">
                                <span class="input-error">{{ error.dealerName }}</span>
                            </div>
                        </div>
                        <div class="contacts-row input-wrapper" :class="{'error' : error.dealerSap}">
                            <span class="label">{{ $t('object.page.edit.details.dealer.field.sap_number.label') }}:</span>
                            <div class="element-wrapper">
                                <input :disabled="viewOnly" type="text" v-model="objectData.dealerSap">
                                <span class="input-error">{{ error.dealerSap }}</span>
                            </div>
                        </div>
                        <div class="contacts-row input-wrapper" :class="{'error' : error.dealerAddress}">
                            <span class="label">{{ $t('object.page.edit.details.dealer.field.address.label') }}:</span>
                            <div class="element-wrapper">
                                <input :disabled="viewOnly" type="text" v-model="objectData.dealerAddress">
                                <span class="input-error">{{ error.dealerAddress }}</span>
                            </div>
                        </div>
                    </template>
                    <span class="text-input-label mt-15">{{ $t('object.page.edit.details.persons.title') }}</span>
                    <div class="contacts-row">
                        <span class="label">{{ $t('object.page.edit.details.persons.field.investor.label') }}</span>
                        <div class="element-wrapper">
                            <span class="element" v-if="objectData.investor">
                                {{ objectData.investor.name }} <span class="address">{{ objectData.investor.address }}</span>
                                <button
                                    v-if="!viewOnly"
                                    type="button"
                                    @click="removePerson('investor')"
                                >
                                    <md-icon>close</md-icon>
                                </button>
                            </span>
                        </div>
                        <button
                            v-if="!objectData.investor && !viewOnly"
                            type="button"
                            class="btn btn-plain btn-plain-wicon button-add"
                            @click="openModalAddPerson('investor')"
                        >
                            <md-icon>add_circle</md-icon>
                            <span>{{ $t('object.page.edit.button.add') }}</span>
                        </button>
                    </div>
                    <div class="contacts-row">
                        <span class="label">{{ $t('object.page.edit.details.persons.field.planner.label') }}</span>
                        <div class="element-wrapper">
                            <span class="element" v-if="objectData.planner">
                                {{ objectData.planner.name }} <span class="address">{{ objectData.planner.address }}</span>
                                <button
                                    v-if="!viewOnly"
                                    type="button"
                                    @click="removePerson('planner')"
                                >
                                    <md-icon>close</md-icon>
                                </button>
                            </span>
                        </div>
                        <button
                            v-if="!objectData.planner && !viewOnly"
                            type="button"
                            class="btn btn-plain btn-plain-wicon button-add"
                            @click="openModalAddPerson('planner')"
                        >
                            <md-icon>add_circle</md-icon>
                            <span>{{ $t('object.page.edit.button.add') }}</span>
                        </button>
                    </div>
                    <div class="contacts-row">
                        <span class="label">{{ $t('object.page.edit.details.persons.field.implementer.label') }}</span>
                        <div class="element-wrapper">
                            <span class="element" v-if="objectData.implementer">
                                {{ objectData.implementer.name }} <span class="address">{{ objectData.implementer.address }}</span>
                                <button
                                    v-if="!viewOnly"
                                    type="button"
                                    @click="removePerson('implementer')"
                                >
                                    <md-icon>close</md-icon>
                                </button>
                            </span>
                        </div>
                        <button
                            v-if="!objectData.implementer && !viewOnly"
                            type="button"
                            class="btn btn-plain btn-plain-wicon button-add"
                            @click="openModalAddPerson('implementer')"
                        >
                            <md-icon>add_circle</md-icon>
                            <span>{{ $t('object.page.edit.button.add') }}</span>
                        </button>
                    </div>
                    <div class="contacts-row">
                        <span class="label">{{ $t('object.page.edit.details.persons.field.roof_cover.label') }}</span>
                        <div class="element-wrapper">
                            <span class="element" v-if="objectData.roofCover">
                                {{ objectData.roofCover.name }} <span class="address">{{ objectData.roofCover.address }}</span>
                                <button
                                    v-if="!viewOnly"
                                    type="button"
                                    @click="removePerson('roofCover')"
                                >
                                    <md-icon>close</md-icon>
                                </button>
                            </span>
                        </div>
                        <button
                            v-if="!objectData.roofCover && !viewOnly"
                            type="button"
                            class="btn btn-plain btn-plain-wicon button-add"
                            @click="openModalAddPerson('roofCover')"
                        >
                            <md-icon>add_circle</md-icon>
                            <span>{{ $t('object.page.edit.button.add') }}</span>
                        </button>
                    </div>
                </div>

                <div class="label-group" v-if="objectData.objectType !== 'villas'">
                    <span class="label">{{ $t('object.page.edit.details.field.reference.label') }}</span>
                    <span class="toggle toggle--reference">
                        <input :disabled="viewOnly" type="checkbox" id="reference" v-model="objectData.reference" :checked="objectData.reference" @click="objectData.reference = !objectData.reference">
                        <label for="reference"></label>
                    </span>
                </div>
            </div>

            <template v-if="objectData.objectType !== 'villas'">
                <h2>{{ $t('object.page.edit.building_details.title') }}</h2>

                <div class="box">
                    <div class="label-group input-wrapper" :class="{'error' : error.classificationId}">
                        <span class="label">{{ $t('object.page.edit.building_details.field.classification.label') }}</span>
                        <custom-select :disabled="viewOnly" :selectedItem="objectData.classification" :options="objectData.classifications" :large="true" @changed="(e) => {objectData.classification = e}"></custom-select>
                        <span class="input-error">{{ error.classificationId }}</span>
                    </div>

                    <div class="label-group input-wrapper" :class="{'error' : error.type}">
                        <span class="label">{{ $t('object.page.edit.building_details.field.type.label') }}</span>
                        <div class="label-row">
                            <div class="radio-group">
                                <div class="radio">
                                    <input :disabled="viewOnly" type="radio" id="type_1" v-model="objectData.type" value="1" :checked="objectData.type === 1">
                                    <label for="type_1">{{ $t('object.page.edit.building_details.option.type.new') }}</label>
                                </div>
                                <div class="radio">
                                    <input :disabled="viewOnly" type="radio" id="type_2" v-model="objectData.type" value="2" :checked="objectData.type === 2">
                                    <label for="type_2">{{ $t('object.page.edit.building_details.option.type.renovation') }}</label>
                                </div>
                            </div>
                        </div>
                        <span class="input-error">{{ error.type }}</span>
                    </div>
                </div>
            </template>

            <template v-if="(objectData.models !== null && objectData.objectType === 'standalone') || objectData.model">
                <h2>{{ $t('object.page.edit.roof_details.title') }}</h2>

                <div class="box">
                    <div class="label-group input-wrapper" :class="{'error' : error.modelId}">
                        <span class="label">{{ $t('object.page.edit.roof_details.field.model.label') }}</span>

                        <custom-select v-if="objectData.objectType === 'standalone'" :disabled="viewOnly" :selectedItem="objectData.model" :options="objectData.models" :large="true" @changed="(e) => {objectData.model = e; objectData.modelVariant = e.colors[0]}"></custom-select>

                        <input v-else v-model="objectData.model" :disabled="true">

                        <span class="input-error">{{ error.modelId }}</span>
                    </div>

                    <div class="label-group input-wrapper" :class="{'error' : error.modelVariantId || error.size}">
                        <div class="label-row label-row--color">
                            <div>
                                <span class="label">{{ $t('object.page.edit.roof_details.field.color.label') }}</span>

                                <custom-select v-if="objectData.objectType === 'standalone'" :disabled="viewOnly || colors.length === 0" :placeholder="colors.length === 0 ? this.$t('object.page.edit.roof_details.option.color.select_model') : null" :selectedItem="objectData.modelVariant" :options="colors" :large="true" @changed="(e) => {objectData.modelVariant = e}"></custom-select>

                                <input v-else v-model="objectData.color" :disabled="true">

                                <span class="input-error">{{ error.modelVariantId }}</span>
                            </div>

                            <div>
                                <span class="label">{{ $t('object.page.edit.roof_details.field.size.label') }}</span>

                                <div class="sup-group">
                                    <input type="text" v-model="objectData.size" :disabled="objectData.objectType !== 'standalone'">
                                    <span class="unit">m<sup>2</sup></span>
                                </div>

                                <span class="input-error">{{ error.size }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="objectData.products !== null && objectData.standaloneProducts === null">
                <h2>{{ $t('object.page.edit.products.title') }}</h2>
                <div class="box">
                    <div class="label-group input-wrapper">
                        <div class="label-row">
                            <div class="table-container">
                                <div>
                                    <table class="table products-data">
                                        <tr>
                                            <th>{{ $t('object.page.edit.products.column.name') }}</th>
                                            <th>{{ $t('object.page.edit.products.column.amount') }}</th>
                                            <th>{{ $t('object.page.edit.products.column.discount') }}</th>
                                        </tr>

                                        <template v-for="product in objectData.products">
                                            <tr :key="product.id">
                                                <td>{{product.name}}</td>
                                                <td v-if="product.baseAmount !== null">
                                                    <span class="first-row">{{product.amount}}</span>
                                                    <span class="second-row">({{product.baseAmount}})</span>
                                                </td>
                                                <td v-else>{{product.amount}}</td>
                                                <td>{{product.discount}}%</td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="objectData.products !== null && objectData.standaloneProducts !== null">
                <h2>{{ $t('object.page.edit.products.title') }}</h2>
                <div class="box">
                    <div class="autocomplete-container">
                        <input
                            v-model="searchText"
                            @input="openResultsList = true"
                            id="search-text-object-add"
                            type="text"
                            :placeholder="this.$t('object.page.edit.products.text.product_placeholder')">

                        <ul v-show="openResultsList && results.length > 0" class="dropdown-list">
                            <li
                                @click="selectProduct(result)"
                                v-for="result in resultsList"
                                :key="result.id"
                            >
                                <span v-html="result.styledName" class="result-name"></span>
                            </li>
                        </ul>
                    </div>

                    <hr>

                    <div class="label-group input-wrapper">
                        <div class="label-row">
                            <div class="table-container">
                                <div>
                                    <table class="table standalone-products-data">
                                        <tr>
                                            <th>{{ $t('object.page.edit.products.column.name') }}</th>
                                            <th>{{ $t('object.page.edit.products.column.product_number') }}</th>
                                            <th>{{ $t('object.page.edit.products.column.discount') }}</th>
                                            <th></th>
                                        </tr>

                                        <template v-for="product in objectData.products">
                                            <tr :key="product.id">
                                                <td>{{product.name}}</td>
                                                <td>{{product.productNumber}}</td>
                                                <td>
                                                    <input
                                                        v-model="product.discount"
                                                        :class="{'error' : product.discount < 0 || product.discount > 100}"
                                                        type="number"
                                                        class="small"
                                                        v-zerohandling
                                                        step='0.01'
                                                        min="0"
                                                        max="100">
                                                    %
                                                </td>
                                                <td>
                                                    <button
                                                        @click="deleteProduct(product)"
                                                        class="btn btn-plain btn-plain-wicon"
                                                        type="button">
                                                        <md-icon>delete</md-icon>
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="objectData.discounts">
                <h2>{{ $t('object.page.edit.discounts.title') }}</h2>
                <div class="box">
                    <div class="label-group">
                        <div class="label-row label-row--discounts">
                            <div>
                                <span class="label">{{ $t('object.page.edit.discounts.field.base_tile') }}</span>

                                <div class="sup-group">
                                    <span class="unit unit-before">25% + </span>
                                    <input type="number" min="0" max="100" step="0.01" v-zerohandling :disabled="objectData.objectType !== 'standalone'" v-model="objectData.discounts.baseTile">
                                    <span class="unit">%</span>
                                </div>
                            </div>

                            <div>
                                <span class="label">{{ $t('object.page.edit.discounts.field.other_tile') }}</span>

                                <div class="sup-group">
                                    <span class="unit unit-before">15% + </span>
                                    <input type="number" min="0" max="100" step="0.01" v-zerohandling :disabled="objectData.objectType !== 'standalone'" v-model="objectData.discounts.otherTile">
                                    <span class="unit">%</span>
                                </div>
                            </div>

                            <div>
                                <span class="label">{{ $t('object.page.edit.discounts.field.foil') }}</span>

                                <div class="sup-group">
                                    <span class="unit unit-before">10% + </span>
                                    <input type="number" min="0" max="100" step="0.01" v-zerohandling :disabled="objectData.objectType !== 'standalone'" v-model="objectData.discounts.foil">
                                    <span class="unit">%</span>
                                </div>
                            </div>

                            <div>
                                <span class="label">{{ $t('object.page.edit.discounts.field.accessory') }}</span>

                                <div class="sup-group">
                                    <span class="unit unit-before">10% + </span>
                                    <input type="number" min="0" max="100" step="0.01" v-zerohandling :disabled="objectData.objectType !== 'standalone'" v-model="objectData.discounts.accessory">
                                    <span class="unit">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <h2>{{ $t('object.page.edit.comments.title') }}</h2>
            <div class="box">
                <div class="label-group">
                    <p v-if="objectData.number.advisory === objectData.info.advisoryNumber" class="label-group__text">{{ objectData.info.text }}</p>
                    <span class="label">{{ $t('object.page.edit.comments.field.comment') }}</span>
                    <textarea :disabled="viewOnly" rows="10" v-model="objectData.comment"></textarea>
                </div>
            </div>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <a href="" download ref="pdfButton" class="button-download"></a>

        <modal-object-add-person
            :open="openModalObject"
            :personType="personType"
            @addPerson="addPerson"
            @close="() => {this.openModalObject = false}"
        ></modal-object-add-person>

        <modal-confirmation
            :open="openClosingConfirmationModal"
            :data="closingConfirmationModalData"
            @accepted="saveObjectAdd(true)"
            @close="openClosingConfirmationModal = false"
            @secondaryAction="exitObjectAdd"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../plugins/config.js'
import axios from 'axios'

import { scrollToError } from '@/plugins/helpers'

import CustomSelect from '../components/dropdowns/CustomSelect.vue'
import JobNavigation from '../components/navigations/JobNavigation.vue'
import Notification from '../components/Notification.vue'
import ModalObjectAddPerson from '../components/modals/ModalObjectAddPerson.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'

export default {
    name: 'ObjectAdd',

    data() {
        return {
            loading: true,
            searchText: '',
            openResultsList: false,
            results: [],
            exporting: false,
            openModalObject: false,
            personType: '',
            type: '',
            roofSize: 0,
            note: '',
            closeTargetUrl: null,
            openClosingConfirmationModal: false,
            objectData: {
                id: null,
                number: {
                    advisory: null,
                    county: null,
                    increment: null
                },
                investor: {
                    name: null,
                    address: null
                },
                planner: {
                    name: null,
                    address: null
                },
                implementer: {
                    name: null,
                    address: null
                },
                roofCover: {
                    name: null,
                    address: null
                },
                reference: false,
                classifications: [],
                classification: {
                    name: null,
                    id: null
                },
                type: null,
                model: null,
                color: null,
                size: null,
                discounts: {
                    baseTile: 0,
                    otherTile: 0,
                    foil: 0,
                    accessory: 0
                },
                info: {
                    advisoryNumber: null,
                    text: null
                },
                comment: null,
                date: null,
                objectType: null,
                standaloneType: null,
                products: null,
                standaloneProducts: null
            },
            closingConfirmationModalData: {
                acceptText: this.$t('object.page.edit.confirmation.save.ok'),
                cancelText: this.$t('object.page.edit.confirmation.save.cancel'),
                secondaryActionText: this.$t('object.page.edit.confirmation.save.secondary'),
                message: this.$t('object.page.edit.confirmation.save.question')
            },
            orderId: null,
            permissions: {},
            error: {}
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.loadObject()
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        searchText: {
            handler: function(val) {
                const search = val.trim()

                if (search.length < 1) {
                    this.results = []
                    return true
                }

                const parts = search.split(/\s+/)

                this.results = this.objectData.standaloneProducts.filter((product) => {
                    return parts.every(p => {
                        return product.nameWithProductNumber.toLowerCase().includes(p.toLowerCase())
                    }) && !this.objectData.products.some(p => Number(p.id) === Number(product.id))
                }).slice(0, 50)
            }
        },

        '$route' () {
            this.loadObject()
        },

        validation: {
            handler: function() {
                scrollToError()
            }
        }
    },

    methods: {
        selectProduct(product) {
            this.objectData.products.push({
                id: product.id,
                name: product.name,
                productNumber: product.productNumber,
                discount: 0
            });

            this.searchText = ''
            this.openResultsList = false
        },

        deleteProduct(product) {
            this.objectData.products = this.objectData.products.filter(p => Number(p.id) !== Number(product.id))
        },

        loadObject() {
            let url

            if (this.$route.params.orderId) {
                url = 'objects/create-for-order/' + this.$route.params.orderId
            } else if (this.$route.params.objectId) {
                url = 'objects/' + this.$route.params.objectId
            } else {
                url = 'objects/create/' + this.$route.params.standaloneType
            }

            this.loading = true

            return axios.get(url, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.setObject(data)
                    this.loading = false
                }).catch(() => {
                    this.$store.commit('setNotification', [this.$t('object.page.edit.notification.load_failed')])
                    this.exitObjectAdd()
                })
        },

        closePage(targetUrl) {
            this.closeTargetUrl = targetUrl

            if (this.viewOnly) {
                this.exitObjectAdd()
            }

            this.openClosingConfirmationModal = true
        },

        exportToPdf() {
            if (this.viewOnly) {
                this.generatePdf(this.objectData.id)
            } else {
                this.saveObjectAdd().then(data => {
                    this.generatePdf(data.object.id)
                })
            }
        },

        generatePdf(objectId) {
            this.exporting = true
            this.$store.commit('setNotification', [this.$t('object.page.edit.notification.pdf_export')])

            this.$store.dispatch('GENERATE_OBJECT_PDF', {objectId: objectId})
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
                .finally(() => {
                    this.exporting = false
                })
        },

        openModalAddPerson(type) {
            this.personType = type
            this.openModalObject = true
        },

        addPerson(data) {
            this.openModalObject = false
            this.objectData[this.personType] = data
        },

        removePerson(type) {
            this.objectData[type] = null
        },

        lengthCheck(length, element, nextElement) {

            if (this.$refs[element].value.length === length) {
                this.$refs[nextElement].focus()
            }
        },

        setObject(data) {
            this.$store.commit('setTitle', data.title)
            this.$store.commit('setProjectData', data.project)

            this.orderId = data.order ? data.order.id : null
            this.objectData = data.object
            this.permissions = data.permissions
        },

        exitObjectAdd() {
            if(this.orderId) {
                this.$router.push({name: 'OrderStatus', params: {orderId: this.orderId}})
            } else if(this.closeTargetUrl) {
                this.$router.push(this.closeTargetUrl)
            } else {
                this.$router.push({name: 'ObjectList'})
            }
        },

        saveObjectAdd(exit) {
            this.loading = true;
            let url, method, data

            this.openClosingConfirmationModal = false

            if (this.objectData.id === null) {
                if (this.orderId) {
                    url = 'objects/create-for-order/' + this.orderId
                } else {
                    url = 'objects/create/' + this.objectData.standaloneType
                }
                method = 'post';
            } else {
                url = 'objects/' + this.objectData.id
                method = 'put';
            }

            let number = ''

            number += this.objectData.number.advisory
            number += this.objectData.number.county
            number += this.objectData.number.increment

            if (this.objectData.objectType === 'standalone') {
                data = {
                    number: number,

                    projectName: this.objectData.projectName,
                    projectAddress: this.objectData.projectAddress,

                    dealerSap: this.objectData.dealerSap,
                    dealerName: this.objectData.dealerName,
                    dealerAddress: this.objectData.dealerAddress,

                    investorName: this.objectData.investor === null ? null : this.objectData.investor.name,
                    investorAddress: this.objectData.investor === null ? null : this.objectData.investor.address,

                    plannerName: this.objectData.planner === null ? null : this.objectData.planner.name,
                    plannerAddress: this.objectData.planner === null ? null : this.objectData.planner.address,

                    implementerName: this.objectData.implementer === null ? null : this.objectData.implementer.name,
                    implementerAddress: this.objectData.implementer === null ? null : this.objectData.implementer.address,

                    roofCoverName: this.objectData.roofCover === null ? null : this.objectData.roofCover.name,
                    roofCoverAddress: this.objectData.roofCover === null ? null : this.objectData.roofCover.address,

                    reference: this.objectData.reference,

                    classificationId: this.objectData.classification !== null ? this.objectData.classification.id : null,

                    type: this.objectData.type,

                    modelVariantId: this.objectData.modelVariant !== null ? this.objectData.modelVariant.id : null,

                    size: this.objectData.size,

                    discounts: this.objectData.discounts,

                    products: this.objectData.products !== null ? this.objectData.products.map(p => {
                        return {
                            id: p.id,
                            discount: p.discount
                        }
                    }) : null,

                    comment: this.objectData.comment
                }
            } else if (this.objectData.objectType === 'villas') {
                data = {
                    number: number,

                    projectName: this.objectData.projectName,
                    projectAddress: this.objectData.projectAddress,

                    investorName: !this.objectData.investor ? null : this.objectData.investor.name,
                    investorAddress: !this.objectData.investor ? null : this.objectData.investor.address,

                    plannerName: !this.objectData.planner ? null : this.objectData.planner.name,
                    plannerAddress: !this.objectData.planner ? null : this.objectData.planner.address,

                    implementerName: !this.objectData.implementer ? null : this.objectData.implementer.name,
                    implementerAddress: !this.objectData.implementer ? null : this.objectData.implementer.address,

                    roofCoverName: !this.objectData.roofCover ? null : this.objectData.roofCover.name,
                    roofCoverAddress: !this.objectData.roofCover ? null : this.objectData.roofCover.address,

                    dealerSap: this.objectData.dealerSap,
                    dealerName: this.objectData.dealerName,
                    dealerAddress: this.objectData.dealerAddress,

                    comment: this.objectData.comment
                }
            } else {
                data = {
                    number: number,

                    projectName: this.objectData.projectName,
                    projectAddress: this.objectData.projectAddress,

                    investorName: this.objectData.investor === null ? null : this.objectData.investor.name,
                    investorAddress: this.objectData.investor === null ? null : this.objectData.investor.address,

                    plannerName: this.objectData.planner === null ? null : this.objectData.planner.name,
                    plannerAddress: this.objectData.planner === null ? null : this.objectData.planner.address,

                    implementerName: this.objectData.implementer === null ? null : this.objectData.implementer.name,
                    implementerAddress: this.objectData.implementer === null ? null : this.objectData.implementer.address,

                    roofCoverName: this.objectData.roofCover === null ? null : this.objectData.roofCover.name,
                    roofCoverAddress: this.objectData.roofCover === null ? null : this.objectData.roofCover.address,

                    reference: this.objectData.reference,

                    classificationId: this.objectData.classification !== null ? this.objectData.classification.id : null,

                    type: this.objectData.type,

                    comment: this.objectData.comment
                }
            }

            return axios[method](url, data, config.AXIOS_DEFAULTS)
                .then(data => {
                    if (this.objectData.id === null) {
                        this.$router.replace({name: 'Object', params: {objectId: data.object.id}})
                    } else {
                        this.setObject(data)
                    }

                    this.error = {}

                    this.$store.commit('setNotification', [this.$t('object.page.edit.notification.save_success')])

                    if(exit) {
                        this.exitObjectAdd()
                    }
                    this.loading = false
                    return data
                })
                .catch(response => {
                    this.error = response.errors
                    this.loading = false
                    scrollToError()
                })
        },
    },

    computed:  {
        ...mapGetters(['validation', 'userHasObjects']),

        resultsList() {
            const parts = this.searchText.trim().split(/\s+/)

            return this.results.map(r => {
                let styledName = String(r.nameWithProductNumber)

                for(let part of parts) {
                    styledName = styledName.replace(new RegExp(part, 'ig'), (m) => {
                        return String.fromCharCode(1) + m + String.fromCharCode(2)
                    })
                }

                styledName = styledName.replaceAll(String.fromCharCode(1), '<strong>')
                styledName = styledName.replaceAll(String.fromCharCode(2), '</strong>')

                return {
                    ...r,
                    styledName
                }
            })
        },

        viewOnly() {
            return !this.permissions.canEdit
        },

        colors() {
            if(!this.objectData.model) {
                return []
            }

            return this.objectData.model.colors
        }
    },

    components: {
        JobNavigation,
        Notification,
        CustomSelect,
        ModalObjectAddPerson,
        ModalConfirmation
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/table.scss';
@import '../assets/sass/partials/contacts.scss';
@import '../assets/sass/ui/loading.scss';

.object-add-page {
    background: $color-gray-bg-1;
    padding-top: 50px;

    .table-container {
        width: 100%;
    }

    .table {
        th {
            width: 100%;
        }
    }

    .container {
        max-width: 680px;
        width: 100%;
        margin: 60px auto 0;

        h2 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .box {
            background: $color-white;
            box-shadow: 0px 4px 8px #d9d9d9;
            border: 1px solid $color-gray-border;
            padding: 40px;
            margin-bottom: 50px;

            &--object {
                .toggle {
                    label {
                        left: 20px;
                    }

                    &--reference {

                        label {
                            left: 30px;
                            top: -15px;
                        }
                    }
                }
            }

            input {
                width: 100%;

                &[type="text"] {
                    width: 200px;
                }

                &.project-data {
                    width: 600px;
                    margin-bottom: 7px;
                }
            }

            .autocomplete-container {
                position: relative;
            }

            .label {
                font-size: 15px;
                line-height: 2;
            }

            .label-group {
                .label-row {
                    justify-content: space-between;
                }

                &__text {
                    font-size: 12px;
                    margin-bottom: 20px;
                }

                &-code {
                    .label-row {
                        & > div {
	                        width: 32%;
                        }
                    }
                }
            }

            .label-row {
                display: flex;
                align-items: center;

                &--color {
                    display: flex;

                    > div {
                        &:nth-child(1) {
                            flex: 1;
                        }

                        &:nth-child(2) {
                            flex-basis: 150px;
                            margin-left: 20px;
                        }
                    }
                }

                &--discounts {
                    display: flex;
                    justify-content: space-between;

                    > div {
                        margin-left: 10px;
                        text-align: center;

                        &:first-child {
                            margin-left: 0;
                        }

                        &:last-child {
                            margin-right: 0;
                        }

                        .label {
                            font-size: 13px;
                        }
                    }

                    input {
                        width: 70px;
                    }
                }
            }

            .label-group {
                display: block;
                margin-bottom: 1.5em;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .radio-group {
                display: block;
                padding-left: 20px;
            }

            .button-group {
                .btn {
                    &:nth-child(1) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        margin-right: -3px;
                    }

                    &:nth-child(2) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            .sup-group {
                display: flex;
                align-items: center;

                span.unit {
                    font-style: normal;
                    font-size: .85em;
                    margin-left: 5px;
                    white-space: nowrap;
                    color: $color-gray-3;

                    &.unit-before {
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }

            .contacts-container {
                .contacts-row {
                    &:last-of-type {
                        border-bottom: none;
                        padding-bottom: 0;
                    }

                    .address {
                        font-weight: normal;
                    }

                    .input-error {
                        bottom: 6px;
                    }
                }

                .label {
                    font-size: 12px;
                }
            }
        }

        .products-data {
            th,
            td {
                &:nth-child(1) {
                    width: 57%;
                }

                &:nth-child(2) {
                    width: 28%;

                    .first-row {
                        display: block;
                    }

                    .second-row {
                        font-size: 13px;
                        color: $color-gray-5;
                    }
                }

                &:nth-child(3) {
                    width: 15%;
                }
            }
        }

        .standalone-products-data {
            th,
            td {
                &:nth-child(1) {
                    width: 40%;
                }

                &:nth-child(2) {
                    width: 10%;

                    .first-row {
                        display: block;
                    }

                    .second-row {
                        font-size: 13px;
                        color: $color-gray-5;
                    }
                }

                &:nth-child(3) {
                    width: 15%;
                    input {
                        display: inline-block;
                        width: 64px;
                        text-align: center;

                        &.error {
                            color: $color-red-1;
                        }
                    }
                }

                &:nth-child(4) {
                    width: 5%;
                }
            }
        }
    }

    .mt-15 {
        margin-top: 15px;
    }
}
</style>
