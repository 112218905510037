// Returns an array with selected products
import sanitizeHtml from 'sanitize-html'

export function getProductsWithValue(list, isOrder) {
    let selectedProducts = []

    if (isOrder) {
        list.forEach(tab => {
            tab.categories.forEach(element => {
                element.products.forEach(product => {
                    if (product.amount && product.amount > 0) {
                        let productData = {}
                        productData.id = product.id
                        productData.amount = product.amount
                        productData.unitId = product.amountUnit.id
                        productData.unitMultiplier = product.amountUnit.multiplier
                        productData.weight = product.weight
                        productData.salesDiscount = product.salesDiscount
                        productData.buyerDiscount = product.buyerDiscount
                        productData.dealerDiscount = product.dealerDiscount
                        productData.is_adr = product.is_adr

                        selectedProducts.push(productData)
                    }
                })
            })
        })
    } else {
        list.forEach(element => {
            element.products.forEach(product => {
                if (product.amount && product.amount > 0) {
                    let productData = {}
                    productData.id = product.id
                    productData.amount = product.amount
                    productData.unitId = product.amountUnit.id
                    productData.unitMultiplier = product.amountUnit.multiplier
                    productData.weight = product.weight
                    productData.salesDiscount = product.salesDiscount
                    productData.buyerDiscount = product.buyerDiscount
                    productData.dealerDiscount = product.dealerDiscount
                    productData.is_adr = product.is_adr

                    //productData.listPrice = product.listPrice

                    if (product.listPrice.custom) {
                        productData.listPrice = product.listPrice.net
                    }

                    productData.listPrice = product.listPrice.net
                    productData.name = product.name
                    productData.category_id = product.category_id

                    // TODO
                    // Get more needed data for saving it to the db in case of custom elems

                    selectedProducts.push(productData)
                }
            })
        })
    }

    return selectedProducts
}

export function getProductsWithValueByTabs(list) {
    let selectedTabs = []
    let selectedProducts = []

    list.forEach(tab => {
        selectedProducts = []
        tab.categories.forEach(element => {
            element.products.forEach(product => {
                if (product.amount && product.amount > 0) {
                    let productData = {}
                    productData.id = product.id
                    productData.amount = product.amount
                    productData.unitId = product.amountUnit.id
                    productData.dealerDiscount = product.dealerDiscount
                    selectedProducts.push(productData)
                }
            })
        })

        selectedTabs.push({
            id: tab.id,
            variantId: tab.variant ? tab.variant.id : null,
            groupId: tab.groupId,
            products: selectedProducts
        })
    })


    return selectedTabs
}

export function stickyColHandler() {
    let stickyCol = document.getElementById('sticky-col')

    if (stickyCol) {
        let stickyColTop = stickyCol.offsetTop
        let stickyColLeft = stickyCol.offsetLeft

        stickyCol.style.width = stickyCol.offsetWidth + 'px'

        document.addEventListener('scroll', () => {
            if (window.scrollY > stickyColTop - 60) {
                if (!stickyCol.classList.contains('stick')) {
                    stickyCol.classList.add('stick')
                    stickyCol.style.left = stickyColLeft + 'px'
                }
            } else {
                if (stickyCol.classList.contains('stick')) {
                    stickyCol.classList.remove('stick')
                }
            }
        })
    }
}

export function scrollToError() {
    setTimeout(function() {
        let errorElement = document.getElementsByClassName('error')[0]
        if (typeof errorElement !== 'undefined') {
            let distance = errorElement.offsetTop - 60

            window.scrollTo({
                top: distance,
                behavior: 'smooth',
            })
        }
    }, 50)
}

export function getSelectedIds(elements) {
    let selectedIds = []

    elements.forEach(el => {
        if (el.selected) {
            selectedIds.push(el.id)
        }
    })

    return selectedIds
}

export function loadOffer(commit, data) {
    commit('setOrderDataTabs', [])
    commit('setSelectedOrderDataTab', null)
    commit('setTitle', data.title)
    commit('setProjectData', data.project)
    commit('setOriginalOffer', data)
    commit('setOfferInformation', data.offer)
    commit('setOfferActions', data.actions)
    commit('setDisplayNetPrice', data.showNetPrice)
    commit('setPermissions', data.permissions)
    commit('setViewOnly', !data.permissions.canEditOffer)
    commit('setCanEditSales', data.permissions.canEditSales)
    commit('setCanTag', data.permissions.canTag)
    commit('setSelectedTagsCount', data.offer.tags.length)

    if (data.offer.type === 'text') {
        commit('setProductText', data.productText)
        commit('setProductTextDebug', null)
    } else {
        commit('setPlanCalculation', null)
        commit('setDiscounts', data.discounts)
        commit('setPallets', data.pallets)
        commit('setCustomPallets', data.customPallets)
        commit('setCustomDemolitionPallets', data.customDemolitionPallets)
        commit('setCustomTransport', data.customTransport)
        commit('setDealerTransport', data.dealerTransport)
        commit('setCustomDealerTransport', data.customDealerTransport)
        commit('setServices', data.services)
        commit('setTransports', data.transports)
        commit('setSelectedTransport', data.transportId)
        commit('setWarehouse', data.warehouse)
        commit('saveSystemWarrantee', data.systemWarrantee)
        commit('saveComments', convertCommentsArray(data.comments))
        loadTab(commit, data.tab)
    }
}

export function loadGroupTransports(commit, data, orderDataTab) {
    commit('setTransports', data.transports[orderDataTab.groupId])
    commit('setGroupTransports', data.transports)

    loadTab(commit, orderDataTab)
}

export function loadOrder(commit, data, selectedOrderDataTab = 0) {
    commit('setOrderDataTabs', data.tabs)
    if(selectedOrderDataTab > data.tabs.length - 1) {
        selectedOrderDataTab = data.tabs.length - 1
    }
    commit('setSelectedOrderDataTab', selectedOrderDataTab)
    loadTab(commit, data.tabs[selectedOrderDataTab], false)
    commit('setTitle', data.title)
    commit('setProjectData', null)
    commit('setOrderInformation', data.order)
    commit('setOfferActions', [])
    commit('setPermissions', data.permissions)
    commit('setViewOnly', false)
    commit('setDiscounts', data.discounts)
}

export function loadTab(commit, data, loadPalletsAndTransports = true) {

    commit('setGroupTypes', data.groupTypes)
    commit('setSelectedGroupType', data.groupType)
    commit('setVariants', data.variants)
    commit('setVariantType', data.variantType)
    commit('setSelectedVariant', data.variant)
    commit('setCalculation', data.calculation)
    commit('setCategories', data.categories)
    commit('setActiveProductTypes', data.activeProductTypes)
    commit('updateDifferentColors', data.differentColors)
    commit('updateRestricted', data.restricted)
    commit('setHasCalculation', data.hasCalculation)
    commit('setMultiLevel', data.multiLevel)
    commit('setRecommendedProducts', data.recommendedProducts)
    commit('setHasColor', data.hasColor)
    commit('setBasicInformation', data.information)
    commit('setCategoryTitle', data.title)

    if(loadPalletsAndTransports) {
        commit('calculatePalletsData')
        commit('calculateTransportsData')
    }

    if (data.recommendedProducts.length > 0) {
        commit('specifyRecommendedProduct')
    }
}

export function simpleLoadTab(commit, data) {
    commit('setGroupTypes', data.groupTypes)
    commit('setSelectedGroupType', data.groupType)
    commit('setVariants', data.variants)
    commit('setVariantType', data.variantType)
    commit('setSelectedVariant', data.variant)
    commit('setCalculation', data.calculation)
    commit('setCategories', data.categories)
    commit('setActiveProductTypes', data.activeProductTypes)
    commit('updateDifferentColors', data.differentColors)
    commit('updateRestricted', data.restricted)
    commit('setHasCalculation', data.hasCalculation)
    commit('setMultiLevel', data.multiLevel)
    commit('setHasColor', data.hasColor)
    commit('setBasicInformation', data.information)
    commit('setCategoryTitle', data.title)
}

export function isInViewport(element) {
    let distance = element.getBoundingClientRect()
    return (
        distance.top >= 0 &&
        distance.left >= 0 &&
        distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export function getProductPrice(product, type, gross) {
    if (product.listPriceGross == null) {
        product.listPrice.gross = product.listPrice.net * 1.27
    }
    if(product.listPrice.subDiscounts && product.listPrice.subDiscounts.length > 0) {
        let sum = 0;

        for(let subDiscount of product.listPrice.subDiscounts) {
            if(type === 'sales') {
                sum += (gross ? subDiscount.gross : subDiscount.net) * (product.salesDiscount ? ((100 - (product.salesDiscount[subDiscount.id] || 0)) / 100) : 1)
            } else if(type === 'buyer') {
                sum += (gross ? subDiscount.gross : subDiscount.net) * (product.buyerDiscount ? ((100 - (product.buyerDiscount[subDiscount.id] || 0)) / 100) : 1)
            } else if(type === 'dealer') {
                sum += (gross ? subDiscount.gross : subDiscount.net) * (product.dealerDiscount ? ((100 - (product.dealerDiscount[subDiscount.id] || 0)) / 100) : 1)
            } else {
                sum += gross ? subDiscount.gross : subDiscount.net
            }
        }

        return sum
    } else {
        if(type === 'sales') {
            return (gross ? product.listPrice.gross : product.listPrice.net) * ((100 - product.salesDiscount) / 100)
        } else if(type === 'buyer') {
            return (gross ? product.listPrice.gross : product.listPrice.net) * ((100 - product.buyerDiscount) / 100)
        } else if(type === 'dealer') {
            return (gross ? product.listPrice.gross : product.listPrice.net) * ((100 - product.dealerDiscount) / 100)
        } else {
            return gross ? product.listPrice.gross : product.listPrice.net
        }
    }
}

export function getTotalPrices(list, discountType) {
    let listPriceNet = 0
    let salesPriceNet = 0
    let buyerPriceNet = 0
    let dealerPriceNet = 0
    let listPriceGross = 0
    let salesPriceGross = 0
    let buyerPriceGross = 0
    let dealerPriceGross = 0
    let netPrice = 0
    let grossPrice = 0
    let amount = 0

    list.forEach(category => {
        category.products.forEach(product => {
            netPrice = getProductPrice(product, 'list')
            grossPrice = getProductPrice(product, 'list', true)

            amount = product.amount * (product.amountUnit.multiplier ? product.amountUnit.multiplier : 1)

            listPriceNet += amount * netPrice
            listPriceGross += amount * grossPrice

            if (discountType === 3 || discountType === 4) {
                salesPriceNet += amount * getProductPrice(product, 'sales')
                buyerPriceNet += amount * getProductPrice(product, 'buyer')
                dealerPriceNet += amount * getProductPrice(product, 'dealer')
                salesPriceGross += amount * getProductPrice(product, 'sales', true)
                buyerPriceGross += amount * getProductPrice(product, 'buyer', true)
                dealerPriceGross += amount * getProductPrice(product, 'dealer', true)
            } else {
                salesPriceNet += amount * netPrice
                buyerPriceNet += amount * netPrice
                dealerPriceNet += amount * netPrice
                salesPriceGross += amount * grossPrice
                buyerPriceGross += amount * grossPrice
                dealerPriceGross += amount * grossPrice
            }
        })
    })

    return {
        listPriceNet: listPriceNet,
        salesPriceNet: salesPriceNet,
        buyerPriceNet: buyerPriceNet,
        dealerPriceNet: dealerPriceNet,
        listPriceGross: listPriceGross,
        salesPriceGross: salesPriceGross,
        buyerPriceGross: buyerPriceGross,
        dealerPriceGross: dealerPriceGross
    }
}

export function getPlainCommentsArray(arr) {
    if (arr.length < 1) {
        return []
    }

    return arr.map(function(item) {
        return item['text']
    })
}

export function convertCommentsArray(arr) {
    if (arr.length < 1) {
        return [{text: ''}]
    }

    return arr.map(function(item) {
        return {text: item}
    })
}

export function palletsSum(list, prop) {
    let sum = 0
    list.forEach(element => {
        if (typeof element[prop] === 'undefined') {
            sum += element.amount
        } else {
            sum += element[prop]
        }
    })

    return sum
}

export function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

// Offer / order tables helper functions

export function hShowColumn(property, propertyList, hideProperties, isSwitchable) {
    let status = false
    propertyList.forEach( (el) => {
        if ( el.replace("\\s+","") === property.replace("\\s+","") && ( isSwitchable || !hideProperties ) ) {
            status = true
        }
    })
    return status
}

// Text offer html parser

export function getClipboardData(html, force = false) {
    let data = []
    let titleIndex = -1
    let subTitleIndex = -1
    let lastProductIndex = -1
    let lastListIndex = -1

    html = html || ""

    if(html.indexOf('MsoNormal') === -1 && html.indexOf('ProgId') === -1) {
        if(!force) {
            return false
        }

        return {
            blocks: [
                {
                    type: 'paragraph',
                    data: {
                        text: trimString(sanitizeHtml(html, {
                            allowedTags: ['b', 'i', 'em', 'strong', 'sub'],
                        }))
                    }
                }
            ]
        }
    }

    if(html.toLowerCase().indexOf('<body') === -1) {
        html = `<body>${html}</body>`
    }

    let root = document.createElement('html');
    root.innerHTML = html

    root.querySelectorAll('body > *').forEach((element) => {
        parseWordElement(element).forEach((result) => {
            if (result.type === 'title' || (result.type === 'title_or_sub_title' && titleIndex === -1)) {
                titleIndex = data.length
                data.push({
                    tool: 'title',
                    text: result.text
                })
            } else if (result.type === 'sub_title' || (result.type === 'title_or_sub_title' && titleIndex > -1)) {
                if(subTitleIndex === -1) {
                    subTitleIndex = data.length
                    data.push({
                        tool: 'sub_title',
                        text: result.text
                    })
                } else {
                    data[subTitleIndex].text += '<br>' + result.text
                }
            } else if (result.type === 'separator') {
                lastListIndex = -1
                lastProductIndex = -1
                data.push({
                    tool: 'separator'
                })
            } else if (result.type === 'empty') {
                lastListIndex = -1
                lastProductIndex = -1
            } else if (result.type === 'sum') {
                lastListIndex = data.length - 1
                if(data[lastListIndex].tool === 'separator') {
                    data = data.slice(0, lastListIndex)
                }
                data.push({
                    tool: 'sum',
                    price: result.text
                })
            } else if (result.type === 'product') {
                lastListIndex = -1
                lastProductIndex = data.length
                data.push({
                    tool: 'product',
                    text: result.text,
                    items: []
                })
            } else if (result.type === 'custom_list_item') {
                if (lastProductIndex > -1) {
                    data[lastProductIndex].items.push(result.text)
                } else if(lastListIndex > -1) {
                    data[lastListIndex].items.push(result.text)
                } else {
                    lastListIndex = data.length - 1
                    data[lastListIndex] = {
                        tool: 'custom_list',
                        text: data[lastListIndex].text,
                        items: [result.text]
                    }
                }
            } else if (result.type === 'paragraph') {
                if (lastProductIndex > -1) {
                    let numberOfItems = data[lastProductIndex].items.length
                    if(numberOfItems === 0) {
                        data[lastProductIndex].text += '<br>' + result.text
                    } else {
                        data[lastProductIndex].items[numberOfItems - 1] += '<br>' + result.text
                    }
                } else {
                    lastListIndex = -1
                    lastProductIndex = -1

                    data.push({
                        tool: 'paragraph',
                        text: result.text
                    })
                }
            }
        })
    })

    return {
        blocks: data.map((row) => {
            const tool = row.tool
            delete row.tool
            return {
                type: tool,
                data: row
            }
        })
    }
}

function trimString(s) {
    return (s || '').trim().replace(/\r\n/g, '\r').replace(/\r/g, '\n').replace(/\n/g, ' ').replace(/\s+/g, ' ')
}

function parseWordElement(element) {
    if (element.tagName === 'P') {
        let content = trimString(element.textContent)
        let purified = trimString(sanitizeHtml(element.innerHTML, {
            allowedTags: ['b', 'i', 'em', 'strong', 'sub'],
        }))

        if (content === '') {
            return [{
                type: 'empty'
            }]
        }

        if (/^[_­-]+$/.test(content)) {
            return [{
                type: 'separator'
            }]
        }

        let sumMatch = content.match(/^Ajánlati ár.+?össz[^:]*:\s*([0-9\s]+)/)
        if (sumMatch) {
            return [{
                type: 'sum',
                text: sumMatch[1]
            }]
        }

        purified = purified.replace(/<\/b><b>/g, '')

        if (element.classList.contains('MsoNormal') && (element.getAttribute('align') === 'center' || element.style.textAlign === 'center')) {
            return [{
                type: 'title_or_sub_title',
                text: content
            }]
        }

        if (/^\d+\./.test(content)) {
            return [{
                type: 'product',
                text: purified.replace(/^(<b>)?\s*\d+\.\s*/, '$1')
            }]
        }

        if (/^\W+\s+/.test(content)) {
            return [{
                type: 'custom_list_item',
                text: purified.replace(/^\W+\s+/, '')
            }]
        }

        if (element.classList.contains('MsoNormalCxSpFirst')) {
            return [{
                type: 'title',
                text: content
            }]
        }

        if (element.classList.contains('MsoNormalCxSpMiddle')) {
            if (element.getAttribute('align') === 'center' || element.style.textAlign === 'center') {
                return [{
                    type: 'sub_title',
                    text: content
                }]
            } else {
                return [{
                    type: 'paragraph',
                    text: purified
                }]
            }
        }

        return [{
            type: 'paragraph',
            text: purified
        }]
    } else if (element.tagName === 'TABLE') {
        let results = []
        for (let i = 0; i < element.rows.length; i++) {
            let row = element.rows[i]
            let content = trimString(row.textContent)
            if (content !== '') {
                let purified = trimString(sanitizeHtml(row.innerHTML, {
                    allowedTags: ['b', 'i', 'em', 'strong', 'sub'],
                }))
                results.push({
                    type: 'paragraph',
                    text: purified
                })
            }
        }
        return results
    } else {
        return []
    }
}
