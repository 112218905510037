<template>
    <div class="order-status-page page-details" v-if="userAuthenticated">
        <job-navigation :loading="loading" :fixedToTop="true" @closePage="closePage" :title="orderStatusData.title" :sub-title="orderStatusData.customerOrderId"></job-navigation>

        <div class="wrapper" v-show="!loading">
            <div class="main-col" v-scroll-spy="{offset: 80, time: 500}">
                <div class="box main-data">
                    <div class="row row-progress">
                        <progress-bar :elements="progressBarData"></progress-bar>
                    </div>
<!--                    <div class="row row-notify" v-if="orderStatusData.permissions && orderStatusData.permissions.canManageObject && orderStatusData.objectRecommended && !orderStatusData.objectNumber">-->
<!--                        {{ $t('order.page.status.text.object_recommended') }}-->
<!--                    </div>-->
                    <div class="row row-buttons">
                        <div v-if="isProject">
                            <h2><b>{{ $t('order.page.pending.project.title')}}</b></h2>
                            <br>
                            <button
                                class="btn btn-primary"
                                style="margin-right:5px"
                                type="button"
                                @click="setIsProject(true)">
                                {{ $t('order.page.pending.project.yes')}}
                            </button>
                            <button
                                class="btn"
                                type="button"
                                @click="setIsProject(false)">
                                {{ $t('order.page.pending.project.no')}}
                            </button>
                        </div>
                        <button
                            v-if="orderStatusData.permissions.canApprove && !isProject"
                            class="btn btn-primary"
                            type="button"
                            @click="approveOrder">
                            {{ $t('order.page.status.button.approve') }}
                        </button>
                        <button class="btn btn-plain btn-plain-wicon button-details" type="button" @click="openHistory = !openHistory">
                            <span>{{ $t('order.page.status.button.details') }}</span>
                            <md-icon v-show="openHistory">expand_less</md-icon>
                            <md-icon v-show="!openHistory">expand_more</md-icon>
                        </button>
                        <div class="col-edit">
                            <button v-if="orderStatusData.printUrl" class="btn btn-plain btn-plain-wicon" type="button" @click="printOrder">
                                <md-icon>print</md-icon>
                                <span>{{ $t('order.page.status.button.print') }}</span>
                            </button>
                            <button v-if="orderStatusData.permissions && orderStatusData.permissions.canEdit" class="btn btn-plain btn-plain-wicon" type="button" @click="editOrder">
                                <md-icon>edit</md-icon>
                                <span>{{ $t('order.page.status.button.edit') }}</span>
                            </button>
                            <button v-if="orderStatusData.permissions && orderStatusData.permissions.canDelete" class="btn btn-plain btn-plain-wicon" type="button" @click="deleteOrder">
                                <md-icon>delete</md-icon>
                                <span>{{ $t('order.page.status.button.delete') }}</span>
                            </button>
                            <button v-if="orderStatusData.permissions && orderStatusData.permissions.canReject" class="btn btn-plain btn-plain-wicon" type="button" @click="rejectOrder">
                                <md-icon>pan_tool</md-icon>
                                <span>{{ $t('order.page.status.button.reject') }}</span>
                            </button>
                            <button
                                v-if="orderStatusData.permissions && orderStatusData.permissions.canManageObject && !orderStatusData.objectId"
                                class="btn btn-plain btn-plain-wicon"
                                type="button"
                                @click="createObject">
                                <md-icon>add_circle</md-icon>
                                <span>{{ $t('order.page.status.button.create_object') }}</span>
                            </button>
                        </div>
                    </div>
<!--                    <div v-if="orderStatusData.needObjectPage" class="row">-->
<!--                        <p style="text-align:center; display:flex; justify-content: center; align-items: center; gap:5px">-->
<!--                            A jóváhagyáshoz készítsen egy valós objekt lapot-->
<!--                            <md-icon-->
<!--                                class="icon-info"-->
<!--                                v-tooltip="'Ha az idomcserép kedvezmény mértéke meghaladja a 20 %-ot vagy a Fém- és műanyag tartozékok  kedvezmény mértéke meghaladja a 25 %-ot, kötelező az érvényes objekt lap megadása.'">-->
<!--                                info_outline-->
<!--                            </md-icon>-->
<!--                        </p>-->
<!--                    </div>-->
                    <div class="row row-history" v-show="openHistory">
                        <div v-if="orderStatusData.approved" class="row-history-element">
                            <div class="col-status">{{ $t('order.page.status.text.history.approved') }}</div>
                            <div class="col-person">{{orderStatusData.approved.name}}</div>
                            <div class="col-date">{{orderStatusData.approved.time}}</div>
                        </div>
                        <div v-if="orderStatusData.waitingForApprove" class="row-history-element">
                            <div class="col-status">{{ $t('order.page.status.text.history.waiting_for_approve') }}</div>
                            <div class="col-person">
                                <span v-for="(name, index) in orderStatusData.waitingForApprove.names" :key="index">{{ name }}</span>
                            </div>
                            <div class="col-date"></div>
                        </div>
                        <div v-if="orderStatusData.ordered" class="row-history-element">
                            <div class="col-status">{{ $t('order.page.status.text.history.ordered') }}</div>
                            <div class="col-person">{{orderStatusData.ordered.name}}</div>
                            <div class="col-date">{{orderStatusData.ordered.time}}</div>
                        </div>
                        <div v-if="orderStatusData.created" class="row-history-element">
                            <div class="col-status">{{ $t('order.page.status.text.history.created') }}</div>
                            <div class="col-person">{{orderStatusData.created.name}}</div>
                            <div class="col-date">{{orderStatusData.created.time}}</div>
                        </div>
                    </div>
                </div>

                <div> <!-- helper divs for scrollspy -->
                    <h2 class="box-label">{{ $t('order.page.status.details.title') }}</h2>
                    <div class="box order-data">
                        <div class="row row-identification">
                            <div class="col-id">
                                <span class="label">{{ $t('order.page.status.details.field.customer_order_id') }}</span>
                                {{orderStatusData.customerOrderId}}
                            </div>
                            <div class="col-object">
                                <span class="label">{{ $t('order.page.status.details.field.object') }}</span>
                                <button
                                    v-if="orderStatusData.objectId && userHasObjects"
                                    @click="goToObject(orderStatusData.objectId)"
                                    type="button"
                                    class="btn btn-plain btn-plain-wicon">
                                    <span>{{orderStatusData.objectNumber}}</span>
                                    <md-icon>launch</md-icon>
                                </button>
                                <template v-else>{{orderStatusData.objectNumber}}</template>
                            </div>
                            <div class="col-date">
                                <span class="label">{{ $t('order.page.status.details.field.order_date') }}</span>
                                {{orderStatusData.orderDate}}
                            </div>
                        </div>
                        <div class="row row-customer">
                            <div class="col-name">
                                <span class="label">{{ $t('order.page.status.details.field.customer') }}</span>
                                <span v-if="orderStatusData.customerDeleted" :title="this.$t('order.page.status.details.text.customer_deleted')" style="cursor:help; text-decoration: line-through">
                                    {{orderStatusData.customerName}}
                                    <span class="customer-number" style="text-decoration: line-through">{{orderStatusData.customerSap}}</span>
                                </span>
                                <template v-else>
                                    {{orderStatusData.customerName}}
                                    <span class="customer-number">{{orderStatusData.customerSap}}</span>
                                </template>
                            </div>
                            <div class="col-phone">{{orderStatusData.customerPhone}}</div>
                            <div class="col-email">
                                <a class="btn btn-plain" :href="`mailto:${orderStatusData.customerEmail}`">{{orderStatusData.customerEmail}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 class="box-label">{{ $t('order.page.status.transport.title') }}</h2>
                    <div class="box shipping-data">
                        <div class="row">
                            <div class="col-mode">
                                <span class="label">{{ $t('order.page.status.transport.field.mode') }}</span>
                                {{orderStatusData.transportName}}
                            </div>
                            <div class="col-time">
                                <span class="label">{{ $t('order.page.status.transport.field.shipping_time') }}</span>
                                {{orderStatusData.shippingTime}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-address">
                                <span class="label">{{ $t('order.page.status.transport.field.address') }}</span>
                                <span class="shipping-address">
                                    <span v-if="!orderStatusData.shippingAddress || orderStatusData.shippingAddress.length === 0">-</span>
                                    <span v-else v-for="(row, index) in orderStatusData.shippingAddress" :key="index">
                                        {{row}}<br v-if="index < (orderStatusData.shippingAddress.length - 1)">
                                    </span>
                                </span>
                            </div>
                            <div class="col-recipient">
                                <span class="label">{{ $t('order.page.status.transport.field.recipient') }}</span>
                                {{orderStatusData.shippingName}}
                                <span class="recipient-phone">{{orderStatusData.shippingPhone}}</span>
                            </div>
                        </div>
                        <div class="row" v-if="orderStatusData.shippingPathInfo">
                            <div class="col-comment">
                                <span class="label">{{ $t('order.page.status.transport.field.path_info') }}</span>
                                {{orderStatusData.shippingPathInfo}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-comment">
                                <span class="label">{{ $t('order.page.status.transport.field.comment') }}</span>
                                {{orderStatusData.shippingComment}}
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 class="box-label">{{ $t('order.page.status.discounts.title') }}</h2>
                    <div class="text-info-discounts" v-if="orderStatusData.discountText">{{ orderStatusData.discountText }}</div>
                    <table class="table discounts-data" v-if="orderStatusData.discounts && orderStatusData.discounts.length > 0">
                        <tr>
                            <th>{{ $t('order.page.status.discounts.column.name') }}</th>
                            <th>{{ $t('order.page.status.discounts.column.amount') }}</th>
                        </tr>
                        <template v-for="discount in orderStatusData.discounts">
                            <tr :key="discount.id">
                                <td>{{discount.name}}</td>
                                <td>{{discount.percent}}</td>
                            </tr>
                        </template>
                    </table>
                </div>

                <div>
                    <h2 class="box-label">
                        <span>{{ $t('order.page.status.products.title') }}</span>
                    </h2>
                    <button type="button" class="btn btn-plain btn-collapse-products" @click="openProductsTable = !openProductsTable">
                        <span>{{ $t('order.page.status.products.button.details') }}</span>
                        <md-icon v-show="!openProductsTable">keyboard_arrow_down</md-icon>
                        <md-icon v-show="openProductsTable">keyboard_arrow_up</md-icon>
                    </button>
                    <table v-show="openProductsTable" class="table products-data">
                        <tr>
                            <th>{{ $t('order.page.status.products.column.name') }}</th>
                            <th>{{ $t('order.page.status.products.column.amount') }}</th>
                            <th v-if="orderStatusData.hasProductRowDiscount">{{ $t('order.page.status.products.column.discount') }}</th>
                        </tr>

                        <template v-for="product in orderStatusData.products">
                            <tr :key="product.id">
                                <td>{{product.name}}</td>
                                <td>{{product.amount}}</td>
                                <td class="sub-discounts" v-if="orderStatusData.hasProductRowDiscount && typeof product.discount == 'object'">
                                    <div v-for="discount in product.discount" :key="discount.id">{{ discount.name }}: <strong>{{ discount.percent }}%</strong></div>
                                </td>
                                <td v-else-if="orderStatusData.hasProductRowDiscount"><strong>{{ product.discount }}%</strong></td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <nav class="navbar navbar-bottom">
            <ul v-scroll-spy-active="{class: 'active'}" v-scroll-spy-link>
                <li :key="item" v-for="item in menuItems" class="navbar-item" :class="{'hidden' : item.length < 2}">
                    <a>{{item}}</a>
                </li>
            </ul>
        </nav>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            :isAcceptButtonDisabled="isAcceptButtonDisabled"
            @accepted="orderAction()"
            @close="openConfirmationModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openDeleteConfirmationModal"
            :data="deleteConfirmationModalData"
            @accepted="deleteObject()"
            @close="openDeleteConfirmationModal = false"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

// import { stickyColHandler } from '../plugins/helpers.js'
import { MdIcon } from 'vue-material/dist/components'
import VTooltip from 'v-tooltip'
import Scrollspy from 'vue2-scrollspy'

import JobNavigation from '../components/navigations/JobNavigation.vue'
import Notification from '../components/Notification.vue'
import ProgressBar from '../components/ProgressBar.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'

Vue.use(MdIcon)
Vue.use(VTooltip)
Vue.use(Scrollspy)

export default {
    name: 'OrderStatus',

    data() {
        return {
            loading: true,
            details: this.$store.state.orderDetails,
            openProductsTable: true,
            openHistory: false,
            openDeleteConfirmationModal: false,
            menuItems: [
                '1',
                this.$t('order.page.status.details.title'),
                this.$t('order.page.status.transport.title'),
                '4',
                this.$t('order.page.status.products.title')
            ],
            orderStatusData: {
                permissions: {}
            },
            deleteConfirmationModalData: {
                acceptText: this.$t('order.page.status.confirmation.delete_object.ok'),
                cancelText: this.$t('order.page.status.confirmation.delete_object.cancel'),
                message: this.$t('order.page.status.confirmation.delete_object.question')
            },
            openConfirmationModal: false,
            isDeleteRequest: true,
            actionStatus: 'approve',
            isAcceptButtonDisabled: false,
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            window.scrollTo(0,0);
            this.fetchOrderStatus()
        }).catch((redirect) => {
            redirect && this.$router.push({name: 'OrderList'})
        })
    },

    watch: {
        '$route'() {
            this.fetchOrderStatus()
        },
    },

    methods: {
        fetchOrderStatus() {
            let message = {
                id: this.$route.params.orderId,
                type: 'order'
            }

            this.loading = true

            this.$store.dispatch('FETCH_ORDER_DETAILS', message); // Setting order details

            this.$store.dispatch('FETCH_ORDER_STATUS', message).then(() => {
                this.orderStatusData = this.orderStatus
                this.openHistory = !this.orderStatusData.ordered
                this.loading = false
            }).catch(() => {
                this.$router.push({name: 'OrderList'})
            })
        },

        setIsProject(val){
            let id = this.$route.params.orderId;
            if (val) {
                this.$store.dispatch('UPDATE_COMMENT', {id: id, isProject: true}).then((res) => {
                    this.orderStatusData.is_project_answered = res.is_project_answered;
                    this.orderStatusData.shippingComment = 'PROJEKT ' + this.orderStatusData.shippingComment;
                })
            }else{
                this.$store.dispatch('UPDATE_COMMENT', {id: id, isProject: false}).then((res) => {
                    this.orderStatusData.is_project_answered = res.is_project_answered;
                })
            }
        },

        approveOrder() {
            let message = {
                id: this.$route.params.orderId,
                type: 'order'
            }

            this.$store.dispatch('FETCH_ORDER_DETAILS', message).then(() => {
                this.details = this.$store.state.orderDetails

                if (!this.details.shippingDate) {
                    this.$store.commit('setNotification', ['A kért szállítási hét nincs megadva']);
                    return;
                }

                this.actionStatus = 'approve'
                this.openConfirmationModal = true
            });
        },

        editOrder() {
            this.$router.push({name: 'Order', params: {orderId: this.$route.params.orderId}})
        },

        printOrder() {
            document.getElementById('print_iframe').contentWindow.location.replace(this.orderStatusData.printUrl)
        },

        deleteOrder() {
            this.actionStatus = 'delete'
            this.openConfirmationModal = true
        },

        deleteObject() {
            this.$store.dispatch('DELETE_OBJECT', this.orderStatusData.objectId).then(() => {
                this.orderStatusData.objectId = null
                this.orderStatusData.objectNumber = null
                this.openDeleteConfirmationModal = false
            })
        },

        rejectOrder() {
            this.actionStatus = 'reject'
            this.openConfirmationModal = true
        },

        createObject() {
            this.$router.push({name: 'NewOrderObject', params: {orderId: this.$route.params.orderId}})
        },

        orderAction() {
            let message = {
                id: this.$route.params.orderId,
                inList: false
            }

            if (this.actionStatus === 'approve') {
                this.isAcceptButtonDisabled = true
                this.$store.dispatch('APPROVE_ORDER', message).then(() => {
                    this.isAcceptButtonDisabled = false
                    this.$router.push({name: 'OrderList'})
                })
            } else if (this.actionStatus === 'delete') {
                this.$store.dispatch('DELETE_ORDER', message).then(() => {
                    this.$router.push({name: 'OrderList'})
                })
            } else if (this.actionStatus === 'reject') {
                this.$store.dispatch('REJECT_ORDER', message).then(() => {
                    this.$router.push({name: 'OrderList'})
                })
            }
        },

        goToObject(objectId) {
            this.$router.push({name: 'Object', params: {objectId}})
        },

        closePage() {
            this.$router.push({name: 'OrderList'})
        }
    },

    computed: {
        isProject() {
            return this.orderStatusData.permissions.canApprove &&
                this.orderStatus.isVillas &&
                this.orderStatus.discount_type == 4 &&
                this.orderStatus.is_project_answered == false;
        },
        progressBarData() {
            return [
                {
                    label: this.$t('order.page.status.text.progress.ordered'),
                    done: (this.orderStatus.progress && this.orderStatus.progress.ordered) || false
                },
                {
                    label: this.$t('order.page.status.text.progress.waiting'),
                    done: (this.orderStatus.progress && this.orderStatus.progress.waitingForApproval) || false
                },
                {
                    label: this.$t('order.page.status.text.progress.finished'),
                    done: (this.orderStatus.progress && this.orderStatus.progress.finished) || false
                }
            ]
        },

        confirmationModalData() {
            let text = this.$t('order.page.status.confirmation.approve_question')

            if (this.actionStatus === 'delete') {
                text = this.$t('order.page.status.confirmation.delete_question')
            } else if (this.actionStatus === 'reject') {
                text = this.$t('order.page.status.confirmation.reject_question')
            }

            return {
                acceptText:  this.$t('order.page.status.text.confirmation.ok'),
                cancelText:  this.$t('order.page.status.text.confirmation.cancel'),
                message: text
            }
        },

        ...mapGetters(['orderStatus', 'orderDetails', 'userHasObjects', 'userAuthenticated'])
    },

    components: { JobNavigation, Notification, ProgressBar, ModalConfirmation }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/layout/page-details.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/box.scss';
@import '../assets/sass/ui/table.scss';
@import '../assets/sass/ui/navbar.scss';
@import '../assets/sass/ui/loading.scss';

.order-status-page {
    background: $color-gray-bg-1;
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 100px;

    .box {
        padding: 0;
    }

    .table {
        width: 100%;
    }

    .row {
        padding: 30px 40px 30px 40px;
    }

    .label {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-gray-4;
        display: block;
        margin-bottom: 10px;
    }

    .main-data {
        margin-top: 32px;

        .row-progress {
            background: $color-gray-bg-1;
            border-bottom: 1px solid $color-gray-border;
        }

        .row-notify {
            background: rgba(255, 255, 0, 0.2);
            border-bottom: 1px solid $color-gray-border;
            height: 22px;
            line-height: 22px;
            font-size: 13px;
            color: $color-gray-2;
            text-align: center;
            font-weight: bold;
            padding: 0;
            margin: 0;
            text-transform: uppercase;
        }

        .row-buttons {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid $color-gray-border;
            padding-top: 20px;
            padding-bottom: 20px;

            @media only screen and (max-width: 600px) {
                flex-direction: column;
                align-items: flex-start;
            }

            button {
                height: 40px;
            }

            .button-details {
                font-size: 14px;
                margin-left: 22px;

                i {
                    position: relative;
                    top: 2px;
                    font-size: 17px !important;
                }
            }

            .col-edit {
                display: flex;
                flex-direction: column;
                margin-left: auto;
                margin-right: 0;

                @media only screen and (max-width: 600px) {
                    margin-left: 0px;
                    align-items: baseline;
                }

                button {
                    height: auto;
                    margin: 5px 0;
                    text-align: right;
                }
            }
        }

        .row-history {
            background: $color-gray-bg-1;
            padding-top: 25px;
            padding-bottom: 20px;

            .row-history-element {
                display: flex;
                font-size: 13px;
                border-bottom: 1px dashed $color-gray-border;
                padding-bottom: 15px;
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 5px;
                    border: 0;
                    padding: 0;
                }

                .col-status {
                    width: 30%;
                    font-weight: bold;
                }

                .col-person {
                    width: 45%;

                    span {
                        display: block;
                    }
                }

                .col-date {
                    width: 25%;
                    text-align: right;
                }
            }
        }
    }

    .order-data {
        [class^="col-"] {
            width: 33.33%;
            font-weight: 500;
        }

        .row-identification {
            display: flex;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-gray-border;

            .col-id,
            .col-object {
                border-right: 1px dashed $color-gray-border;
            }

            .col-object,
            .col-date {
                text-align: center;
            }

            .col-object {
                .btn {
                    display: inline-block;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 1;
                    margin: 3px 0;

                    i {
                        font-size: 14px !important;
                        margin-left: -3px;
                    }
                }
            }
        }

        .row-customer {
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;

            .col-name {
                font-size: 14px;

                .customer-number {
                    display: inline-block;
                    font-family: $font-primary;
                    margin-top: 5px;
                }
            }

            .col-phone,
            .col-email {
                font-size: 15px;
                font-family: $font-primary;
                text-align: center;

                .btn {
                    font-size: 15px;
                }
            }
        }
    }

    .shipping-data {
        [class^="col-"] {
            width: 50%;
            font-weight: 500;
        }

        .row {
            display: flex;
            border-bottom: 1px solid $color-gray-border;

            .col-time {
                padding-left: 15%;
            }

            .col-address,
            .col-recipient {
                font-size: 15px;

                span:last-child {
                    display: block;
                    font-family: $font-primary;
                    margin-top: 5px;
                    line-height: 1.2;
                }
            }

            .col-address {
                border-right: 1px dashed $color-gray-border;
                padding-right: 15px;
            }

            .col-recipient {
                padding-left: 15%;
            }

            .col-amount {
                padding-left: 15%;
                font-family: $font-primary;

                span {
                    font-weight: bold;
                }
            }

            .col-comment {
                width: 100%;
                font-family: $font-primary;
                font-size: 15px;
                line-height: 1.4;
            }
        }

    }

    .text-info-discounts {
        margin-bottom: 30px;
        font-size: 15px;
    }

    .discounts-data {
        margin-bottom: 50px;

        th,
        td {
            &:nth-child(1) {
                width: 75%;
            }

            &:nth-child(2) {
                width: 25%;
            }
        }

        td {
            &:nth-child(2) {
                font-weight: bold;
            }
        }
    }

    .products-data {
        margin-bottom: 20px;

        th,
        td {
            line-height: 20px;

            strong {
                font-weight: bold;
            }

            &:nth-child(1) {
                width: 50%;
            }

            &:nth-child(2) {
                width: 25%;
            }

            &.sub-discounts {
                font-size: 13px;
            }
        }
    }

    .btn-collapse-products {
        text-decoration: none;
        margin-left: 18px;

        span {
            text-decoration: underline;
            font-size: 14px;
        }

        i {
            font-size: 20px !important;
        }
    }
}
</style>
