<template>
    <div class="dealer-products-page" v-if="userAuthenticated" >
        <job-navigation :loading="loading" :isDealerProducts="true" :title="this.$t('dealer.page.products.job_navigation.title')" :sub-title="subTitle" @saveDealerProducts="saveDealerProducts"  @resetRestrictions="confirmResetRestrictions" :fixedToTop="true" @closePage="closePage()"></job-navigation>

        <div class="container">
            <h2>{{title}}</h2>
            <div class="box">
                <div class="is-loading is-loading--inline" v-if="loading"></div>
                <template v-else>
                    <div v-for="row in rows" :key="row.id" class="row" :class="rowClass(row)" v-if="rowVisible(row)">
                        <input type="checkbox" :checked="rowChecked(row)" :value="row.id" :id="row.key" @input="toggleRow">
                        <template v-if="row.hasChild">
                            <button class="name" @click="showHideRow(row)">{{ row.name }}</button>
                            <button class="btn btn-plain btn-plain-wicon button-collapse" @click="showHideRow(row)">
                                <md-icon>keyboard_arrow_down</md-icon>
                            </button>
                        </template>
                        <label :for="row.key" class="name" v-else>{{ row.name }}</label>
                        <img src="@/assets/svg/has_value.svg" v-if="hasValue(row)">
                    </div>
                </template>
            </div>
        </div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="saveDealerProducts(true)"
            @close="openConfirmationModal = false"
            @secondaryAction="exitDealerProducts"
        ></modal-confirmation>

        <modal-confirmation
            :open="openResetModal"
            :data="resetModalData"
            @accepted="resetRestrictions(true)"
            @close="openResetModal = false"
            @secondaryAction="openResetModal = false"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '../plugins/config.js'
import axios from 'axios'

import { scrollToError } from '@/plugins/helpers'

import CustomSelect from '../components/dropdowns/CustomSelect.vue'
import JobNavigation from '../components/navigations/JobNavigation.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'

export default {
    name: 'DealerProducts',

    data() {
        return {
            loading: true,
            title: '',
            subTitle: '',
            rows: [],
            idByPath: {},
            pathById: {},
            visible: [],
            restrictions: {},
            loadedRestrictions: null,
            allRestrictions: {},
            parentRestrictions: {},
            allParentRestrictions: {},
            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('dealer.page.products.confirmation.exit.ok'),
                cancelText: this.$t('dealer.page.products.confirmation.exit.cancel'),
                secondaryActionText: this.$t('dealer.page.products.confirmation.exit.secondary'),
                message: this.$t('dealer.page.products.confirmation.exit.question')
            },
            openResetModal: false,
            resetModalData: {
                acceptText: this.$t('dealer.page.products.confirmation.reset.ok'),
                cancelText: this.$t('dealer.page.products.confirmation.reset.cancel'),
                message: this.$t('dealer.page.products.confirmation.reset.question')
            },
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if(this.userHasDealers) {
                this.loadDealerProducts()
            } else {
                this.$router.push('/');
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route' () {
            this.loadDealerProducts()
        },

        validation: {
            handler: function() {
                scrollToError()
            }
        }
    },

    methods: {
        loadDealerProducts() {
            let url

            if (this.$route.params.userId) {
                url = 'dealer-products/' + this.$route.params.userId
            } else {
                url = 'dealer-products'
            }

            this.restrictions = {}
            this.allRestrictions = {}
            this.title = ''
            this.subTitle = ''
            this.loading = true

            return axios.get(url, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.setDealerProducts(data)
                }).catch((err) => {
                    console.error(err)
                    this.$store.commit('setNotification', [this.$t('dealer.page.products.text.notification.load_failed')])
                    this.exitDealerProducts()
                })
        },

        saveDealerProducts(exit) {
            let url

            if (this.$route.params.userId) {
                url = 'dealer-products/' + this.$route.params.userId
            } else {
                url = 'dealer-products'
            }

            let message = {
                restrictions: Object.values(this.restrictions).map(v => {
                    return {
                        id: v.id,
                        enabled: v.enabled
                    }
                })
            }

            return axios.put(url, message, config.AXIOS_DEFAULTS)
                .then(() => {
                    if (exit) {
                        this.exitDealerProducts()
                    } else {
                        this.loadedRestrictions = JSON.stringify(this.restrictions)
                    }
                    this.$store.commit('setNotification', [this.$t('dealer.page.products.text.notification.save.success')])
                }).catch(() => {
                    this.$store.commit('setNotification', [this.$t('dealer.page.products.text.notification.save.failed')])
                })
        },
        closePage() {
            if(JSON.stringify(this.restrictions) !== this.loadedRestrictions) {
                this.openConfirmationModal = true
            } else {
                this.exitDealerProducts()
            }
        },

        setDealerProducts(data) {
            this.title = data.title
            this.subTitle = data.subTitle
            this.idByPath = {}
            this.pathById = {}

            this.rows = data.rows.map((row, index) => {
                let childRegExp = new RegExp('^' + row.path + '(_|$)')
                this.idByPath[row.path] = row.id
                this.pathById[row.id] = row.path
                return {
                    ...row,
                    depth: row.path.split('_').length,
                    isProduct: row.id.split('_').length === 6,
                    hasChild: data.rows.slice(index + 1).filter(otherRow => {
                        return childRegExp.test(otherRow.path)
                    }).length > 0,
                    key: 'row_' + row.id
                }
            })

            let restrictions = {}
            for(let restriction of data.restrictions) {
                if(this.pathById.hasOwnProperty(restriction.id)) {
                    restrictions[restriction.id] = {
                        id: restriction.id,
                        enabled: restriction.enabled,
                        path: this.pathById[restriction.id]
                    }
                }
            }

            let parentRestrictions = {}
            for(let restriction of data.parentRestrictions) {
                if(this.pathById.hasOwnProperty(restriction.id)) {
                    parentRestrictions[restriction.id] = {
                        id: restriction.id,
                        enabled: restriction.enabled,
                        path: this.pathById[restriction.id]
                    }
                }
            }

            setTimeout(() => {
                this.parentRestrictions = this.simplifyRestrictions(parentRestrictions)

                this.allParentRestrictions = this.createAllRestrictions(this.parentRestrictions)

                this.restrictions = this.simplifyRestrictions(restrictions, this.allParentRestrictions)
                this.allRestrictions = this.createAllRestrictions(this.restrictions, this.allParentRestrictions)

                this.loadedRestrictions = JSON.stringify(this.restrictions)

                this.loading = false
            }, 60)
        },

        confirmResetRestrictions() {
            this.openResetModal = true
        },

        resetRestrictions() {
            this.restrictions = this.simplifyRestrictions({}, this.allParentRestrictions)
            this.allRestrictions = this.createAllRestrictions(this.restrictions, this.allParentRestrictions)
            this.openResetModal = false
        },

        toggleRow(e) {
            let restrictions = {...this.restrictions}
            let rowChecked = e.target.checked
            let rowId = e.target.value

            if(restrictions.hasOwnProperty(rowId)) {
                restrictions[rowId].enabled = rowChecked
            } else {
                restrictions[rowId] = {
                    id: rowId,
                    enabled: rowChecked,
                    path: this.pathById[rowId]
                }
            }

            let regExp = new RegExp('^' + this.pathById[rowId] + '_')

            Object.keys(restrictions).forEach(id => {
                if(regExp.test(restrictions[id].path)) {
                    delete restrictions[id]
                }
            })

            this.restrictions = this.simplifyRestrictions(restrictions, this.allParentRestrictions)
            this.allRestrictions = this.createAllRestrictions(this.restrictions, this.allParentRestrictions)
        },

        createAllRestrictions(restrictions, allParentRestrictions = {}) {
            let allRestrictions = {}
            let children = this.rows.filter(v => /^\d+$/.test(v.path))

            for(let row of children) {
                let rowChecked = null
                let hasChildrenChecked = null

                if(restrictions.hasOwnProperty(row.id)) {
                    rowChecked = restrictions[row.id].enabled
                } else if(allParentRestrictions.hasOwnProperty(row.id)) {
                    rowChecked = allParentRestrictions[row.id]
                } else {
                    rowChecked = true
                }

                hasChildrenChecked = this.createSubRestrictions(restrictions, allParentRestrictions, allRestrictions, row, rowChecked, !restrictions.hasOwnProperty(row.id), this.rows)

                if(hasChildrenChecked !== null) {
                    rowChecked = hasChildrenChecked
                }

                allRestrictions[row.id] = rowChecked
            }

            return allRestrictions
        },

        createSubRestrictions(restrictions, allParentRestrictions, allRestrictions, parentRow, parentRowChecked, useParentDefault, filteredRows) {
            let childrenRegExp = new RegExp('^' + parentRow.path + '_\\d+$')
            let children = filteredRows.filter(v => childrenRegExp.test(v.path))

            if(children.length === 0) {
                return null
            }

            let allChildrenRegExp = new RegExp('^' + parentRow.path + '_\\d+')
            let allChildren = filteredRows.filter(v => allChildrenRegExp.test(v.path))
            let hasChecked = false

            for(let row of children) {
                let rowChecked = null
                let hasChildrenChecked = null
                let useParentDefaultForRow = useParentDefault

                if(allChildren.length > 0) {
                    if(restrictions.hasOwnProperty(row.id)) {
                        rowChecked = restrictions[row.id].enabled
                        useParentDefaultForRow = false
                    } else if(useParentDefault && allParentRestrictions.hasOwnProperty(row.id)) {
                        rowChecked = allParentRestrictions[row.id]
                    } else {
                        rowChecked = parentRowChecked
                    }

                    hasChildrenChecked = this.createSubRestrictions(restrictions, allParentRestrictions, allRestrictions, row, rowChecked, useParentDefaultForRow, allChildren)
                }

                if(hasChildrenChecked !== null) {
                    rowChecked = hasChildrenChecked
                }

                hasChecked = hasChecked || rowChecked

                allRestrictions[row.id] = rowChecked
            }

            return hasChecked
        },

        simplifyRestrictions(originalRestrictions, allParentRestrictions = null) {
            let restrictions = {...originalRestrictions}
            let again

            do {
                again = false

                for(let id in restrictions) {
                    if(restrictions.hasOwnProperty(id)) {
                        let parts = restrictions[id].path.split('_')
                        let enabled

                        if(allParentRestrictions === null) {
                            enabled = restrictions[id].enabled
                        } else {
                            enabled = restrictions[id].enabled === allParentRestrictions[id];
                        }

                        if(parts.length === 1) {
                            if(enabled) {
                                delete restrictions[id]
                                again = true
                                break
                            }
                        } else {
                            let sameParent = false
                            let hasParent = false

                            for(let i = parts.length - 2; i >= 0; i--) {
                                let newPath = parts.slice(0, i + 1).join('_')
                                let newId = this.idByPath[newPath]
                                if(restrictions.hasOwnProperty(newId)) {
                                    hasParent = true
                                    sameParent = restrictions[id].enabled === restrictions[newId].enabled
                                    break
                                }
                            }

                            if(sameParent || (!hasParent && enabled)) {
                                delete restrictions[id]
                                again = true
                                break
                            }
                        }
                    }
                }
            } while (again)

            return restrictions
        },

        rowClass(row) {
            let classes = []

            if(row.isProduct) {
                classes.push('product')
            }

            classes.push(`depth-${row.depth}`)

            if(this.visible.includes(row.path)) {
                classes.push('opened')
            }

            return classes.join(' ')
        },

        rowVisible(row) {
            if(row.depth === 1) {
                return true
            }

            for(let path of this.visible) {
                if(new RegExp('^' + path + '_\\d+$').test(row.path)) {
                    return true
                }
            }

            return false
        },

        showHideRow(row) {
            if(this.visible.includes(row.path)) {
                this.visible = this.visible.filter(path => {
                    return row.path !== path && !new RegExp('^' + row.path + '(_|$)').test(path)
                })
            } else {
                this.visible.push(row.path)
            }
        },

        hasValue(row) {
            if(this.restrictions.hasOwnProperty(row.id)) {
                return true
            }

            let regExp = new RegExp('^' + row.path + '_')
            for(let id in this.restrictions) {
                if(this.restrictions.hasOwnProperty(id)) {
                    if(regExp.test(this.restrictions[id].path)) {
                        return true
                    }
                }
            }

            return false
        },

        rowChecked(row) {
            return this.allRestrictions[row.id]
        },

        exitDealerProducts() {
            this.$router.push({name: 'DealerList'})
        }
    },

    computed: {
        ...mapGetters(['validation', 'userAuthenticated', 'userHasDealers'])
    },

    components: {
        JobNavigation,
        Notification,
        CustomSelect,
        ModalConfirmation
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/table.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/partials/contacts.scss';

.dealer-products-page {
    background: $color-gray-bg-1;
    padding-top: 50px;

    .container {
        max-width: 980px;
        width: 100%;
        margin: 60px auto 0;

        h2 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .box {
            background: $color-white;
            box-shadow: 0px 4px 8px #d9d9d9;
            border: 1px solid $color-gray-border;
            padding: 40px;
            margin-bottom: 50px;

            .label {
                font-size: 15px;
                line-height: 2;
            }

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 34px;
                margin: 6px 0;
                padding: 17px 6px;
                border-left: 2px solid #1657B9;
                border-bottom: 2px solid #1657B9;
                background: #f1f1f1;

                .name {
                    padding: 0;
                    margin-left: 10px;
                    color: #333333;
                    font-size: 16px;
                }

                &.depth-1 {
                    border: 2px solid #1657B9;
                    border-radius: 7px;
                    background: none;

                    .name {
                        color: #1657B9;
                        font-weight: bold;
                        font-size: 17px;
                    }
                }

                &.product {
                    background: #009fe3 none repeat scroll 0 0;
                    border: 1px solid #1657B9;
                    border-radius: 4px;

                    .name {
                        color: white;
                        font-size: 15px;
                        font-weight: normal;
                    }

                    button, i {
                        color: white;
                    }
                }

                .button-collapse {
                    i {
                        transition: transform .5s;
                        vertical-align: middle;
                    }
                }

                img {
                    margin-left: 10px;
                }

                &.opened {
                    .button-collapse {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }

                @mixin depth-x-list {
                    @for $i from 2 through 10 {
                        &.depth-#{$i} {margin-left: #{($i - 1) * 30}px; }
                    }
                }

                @include depth-x-list;
            }
        }
    }
}
</style>
