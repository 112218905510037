<template>
    <div class="order-list-page" v-if="userAuthenticated" :class="{ 'is-loading' : loading }">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div
                class="dashboard-header__new"
                v-click-outside="() => {this.openNewOrderDropdown = false}">
                <template v-if="userCanCreateOrder">
                    <button
                        class="btn btn-primary"
                        type="button"
                        @click="selectedProductType()">
                        <md-icon>add_circle</md-icon> {{ $t('order.page.list.button.create') }}
                    </button>
                    <product-type-dropdown
                        :open="openNewOrderDropdown"
                        :productTypes="orderProductTypes"
                        @selectedType="selectedProductType">
                    </product-type-dropdown>
                </template>
            </div>

            <div class="dashboard-header__filters">
                <div class="order-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="orderListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                        <button v-if="isMobile" @click="toggleSearchItems = !toggleSearchItems" class="btn filters--button">{{ toggleSearchItems ? 'Szűrők elrejtése' : "Szűrők megtekintése" }}</button>
                    </div>

                    <div class="custom--filters-container" v-show="toggleSearchItems || !isMobile">
                        <custom-filter
                            v-if="orderListGroups.length > 0"
                            :options="orderListGroups"
                            :value="orderListActiveFilters.group"
                            :label="''"
                            @selected="filterCategory"
                        ></custom-filter>

                        <div style="display:contents;" v-if="userCanSeeFilters">
                            <custom-select
                                class="filter-component"
                                v-if="orderListDealers.length > 1"
                                :selectedItem="selectedDealer"
                                :filterable="true"
                                :large="true"
                                :fixedWidth="true"
                                :width="200"
                                :options="orderListDealers"
                                @changed="filterDealer">
                            </custom-select>

                            <custom-filter
                                v-if="orderListAdvisories.length > 1"
                                :options="orderListAdvisories"
                                :value="orderListActiveFilters.advisory"
                                :label="''"
                                @selected="filterAdvisory"
                            ></custom-filter>

                            <div class="filter-component input-wrapper">
                                <custom-datepicker
                                    :initDate="null"
                                    @changed="(el) => {fromDate = el}"
                                />
                            </div>

                            <div class="filter-component input-wrapper">
                                <custom-datepicker
                                    :initDate="null"
                                    @changed="(el) => {toDate = el}"
                                />
                            </div>
                        </div>

                        <div class="offer-list-page__header-controls">
                            <!--
                                <div class="archive-container" v-click-outside="() => {this.openArchiving = false}">
                                    <archive-dropdown :open="openArchiving" @archive="archiveOldOffers()" @close="openArchiving = false"></archive-dropdown>

                                    <button type="button" @click="openArchiving = !openArchiving">
                                        <md-icon>archive</md-icon>
                                    </button>
                                </div>
                            -->

                                <div v-if="userCanSeeFilters">
                                    <button @click="exportSelected()" type="button">
                                        <md-icon>download</md-icon>
                                    </button>
                                    <a href="" download ref="pdfButton" class="pdf-download"></a>
                                </div>

                                <button type="button" @click="refresh()">
                                    <md-icon>refresh</md-icon>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="filter-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <sidebar-filter
                v-if="orderListFilters"
                :elements="orderListFilters"
                :selected="orderListActiveFilters.filter"
                @changed="sidebarChanged">
            </sidebar-filter>

            <basic-links position="bottom"></basic-links>

            <div class="button-container">
                <button class="btn btn-plain btn-plain-wicon button-close" type="button" @click="collapsedColumn = true">
                    <md-icon>arrow_back_ios</md-icon>
                </button>
            </div>

            <button class="btn btn-plain btn-plain-wicon button-open" type="button" @click="collapsedColumn = false">
                <md-icon>arrow_forward_ios</md-icon>
            </button>
        </div>

        <div class="list-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <custom-table
                ref="table"
                :columns="tableColumns"
                :pages="orderListPages"
                :meta="orderListMeta"
                :row-style-class="rowClass"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-cell-click="onCellClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <span class="col-status" v-if="props.column.field === 'status'">
                        <img v-if="props.row.status === 'toapprove'" src="@/assets/svg/toapprove.svg">
                        <img v-if="props.row.status === 'inprogress'" src="@/assets/svg/inprogress.svg">
                        <img v-if="props.row.status === 'waiting'" src="@/assets/svg/waiting.svg">
                        <img v-if="props.row.status === 'approved'" src="@/assets/svg/approved.svg">
                        <img v-if="props.row.status === 'deleted'" src="@/assets/svg/deleted.svg">

                        <div class="text-block">
                            <span class="large-line">{{props.row.statusText}}</span>
                            <span class="small-line" v-if="props.row.code">{{ props.row.code }}</span>
                        </div>
                    </span>

                    <span v-else-if="props.column.field === 'name'">
                        <span class="large-line" v-html="props.row.name.join('<br>')"></span>
                    </span>

                    <span class="col-ordertime" v-else-if="props.column.field === 'orderTime'">
                        <span class="large-line">{{ props.row.orderTime }}</span>
                    </span>

                    <span class="col-shipping" v-else-if="props.column.field === 'shippingTime'">
                        <span class="large-line">{{ props.row.shippingTime }}</span>
                        <span class="small-line show-onhover" v-if="props.row.shippingTime2">{{ props.row.shippingTime2 }}</span>
                    </span>

                    <span class="col-representatives" v-else-if="props.column.field === 'representatives'">
                        <span v-for="(name, index) in props.row.representatives" :key="index">{{ name }}</span>
                    </span>

                    <div class="col-options" v-else-if="props.column.field === 'options'" @click="props.row.showDropdown = !props.row.showDropdown">
                        <button v-if="props.row.actions.length > 0" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            v-if="props.row.actions.length > 0"
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            :elementPayload="props.row.tags"
                        ></dropdown>
                    </div>

                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="empty_state">{{ $t('order.page.list.text.empty_state') }}</div>
            </custom-table>
        </div>

        <div class="mobile--add-menu">
            <button class="btn btn-primary floating--button" type="button" @click="openNewOrderDropdownMobile = !openNewOfferopenNewOrderDropdownMobileDropdownMobile">
                <md-icon>add_circle</md-icon>
            </button>

            <product-type-dropdown
                v-click-outside="() => {this.openNewOrderDropdownMobile = false}"
                :open="openNewOrderDropdownMobile"
                :productTypes="orderProductTypes"
                @selectedType="selectedProductType">
            </product-type-dropdown>
        </div>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="orderDelete"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import vClickOutside from 'v-click-outside'
import { MdIcon } from 'vue-material/dist/components'

import CustomTable from '../components/CustomTable'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Search from '../components/Search.vue'
import SidebarFilter from '../components/SidebarFilter.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import BasicLinks from '../components/BasicLinks.vue'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'
import Notification from '../components/Notification.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import CustomDatepicker from '../components/dropdowns/CustomDatepicker.vue'
import axios from "axios";
import config from "@/plugins/config";
import CustomSelect from '@/components/dropdowns/CustomSelect.vue'

Vue.use(MdIcon)

export default {
    name: 'OrderList',

    data() {
        return {
            loading: true,
            toggleSearchItems: false,
            openNewOrderDropdownMobile: false,
            tableColumns: [
                {
                    label: this.$t('order.page.list.column.status'),
                    field: 'status',
                    width: '20%',
                    thClass: 'th-status'
                },
                {
                    label: this.$t('order.page.list.column.name'),
                    field: 'name'
                },
                {
                    label: this.$t('order.page.list.column.order_time'),
                    field: 'orderTime',
                    width: '14%',
                },
                {
                    label: this.$t('order.page.list.column.shipping_time'),
                    field: 'shippingTime',
                    width: '12%',
                },
                {
                    label: this.$t('order.page.list.column.buyer'),
                    field: 'buyer'
                },
                {
                    label: this.$t('order.page.list.column.representatives'),
                    field: 'representatives'
                },
                {
                    label: '',
                    field: 'options',
                    width: '80px'
                }
            ],
            openNewOrderDropdown: false,
            collapsedColumn: false,

            selectedDealer: {
                id: 0,
                name: "Összes kereskedő"
            },
            fromDate: '',
            toDate: '',
            initFromDate: '',
            initToDate: '',

            viewportWidth: window.innerWidth || document.documentElement.clientWidth,

            deleteOrderData: null,
            openConfirmationDeleteModal: false,
            confirmationDeleteModalData: {
                acceptText: this.$t('order.page.list.confirmation.delete.ok'),
                cancelText: this.$t('order.page.list.confirmation.delete.cancel'),
                message: this.$t('order.page.list.confirmation.delete.question')
            }
        }
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);

        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if(!this.userHasOrders) {
                this.$store.dispatch('GO_TO_FIRST_AVAILABLE_LIST_PAGE');
            } else {
                const hasData = Object.keys(this.orderListPages).length > 0

                if (hasData) {
                    this.$refs.table.scrollTo(this.orderListScrollPosition)
                }

                this.$store.dispatch('FETCH_ORDER_PRODUCT_GROUPS').then(() => {
                    if(hasData) {
                        this.$store.dispatch('FETCH_ORDER_PAGES').then(() => this.loading = false)
                    } else {
                        this.$store.dispatch('FETCH_ORDERS', {page: 1, keepOtherPages: false}).then(() => this.loading = false)
                    }
                })

                this.initFromDate = '';
                this.initToDate = '';
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!from || (from.name !== 'Order' && from.name !== 'OrderDetails' && from.name !== 'OrderStatus')) {
                vm.$store.commit('resetOrders')
            }
        })
    },

    methods: {
        exportSelected() {
            axios.post('orders/export', this.orderListActiveFilters, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
        },

        handleResize() {
            this.viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        },

        sidebarChanged(el) {
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                filter: el
            })
            this.refresh()
        },

        filterCategory(e) {
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                group: e.id
            })
            this.refresh()
        },

        filterDealer(e) {
            this.selectedDealer = e;
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                dealer: e.id
            })
            this.refresh()
        },

        filterFromDate(){
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                from_date: this.fromDate
            })
            this.refresh()
        },

        filterToDate(){
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                to_date: this.toDate
            })
            this.refresh()
        },

        filterAdvisory(e) {
            this.$store.commit('setOrderListActiveFilters', {
                ...this.orderListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        onSearchPhraseChanged(phrase) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.orderListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setOrderListActiveFilters', {
                    ...this.orderListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_ORDERS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeOrderListPage', page)
        },

        rowClass(row) {
            let classList = ''
            classList += (row.view == false && row.edit == false) ? 'is-disabled' : ''
            return classList
        },

        onDropdownClick(val, page) {
            let newData = {}
            let action = typeof val.action === 'string' ? val.action : val.action.action

            if (action === 'order-delete') {
                newData = {
                    id: val.id,
                    inList: true,
                    page
                }
                this.openConfirmationDeleteModal = true
                this.deleteOrderData = newData
            } else if (action === 'order-print') {
                document.getElementById('print_iframe').contentWindow.location.replace(val.action.printUrl)
            }
        },

        onCellClick(params) {
            if (!params.row.showDropdown && params.column.field !== 'options' && !params.event.target.className.includes('element-options')) {
                if (params.row.view === true) {
                    this.$store.commit('setOrderListScrollPosition', this.$refs.table.getScrollPosition())
                    this.$router.push({name: 'OrderStatus', params: {orderId: params.row.id}})
                } else if (params.row.view === false && params.row.edit === true) {
                    this.$store.commit('setOrderListScrollPosition', this.$refs.table.getScrollPosition())
                    this.$router.push({name: 'Order', params: {orderId: params.row.id}})
                }
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProductType(payload = null) {
            if(this.orderProductTypes.length === 1) {
                this.$router.push({name: 'NewOrder', params: {groupId: this.orderProductTypes[0].id}})
                return
            }

            if(!payload) {
                this.openNewOrderDropdown = !this.openNewOrderDropdown
                return
            }

            this.openNewOrderDropdown = false
            this.$router.push({name: 'NewOrder', params: {groupId: payload.id}})
        },

        orderDelete() {
            this.openConfirmationDeleteModal = false
            this.$store.dispatch('DELETE_ORDER', this.deleteOrderData)
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_ORDERS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        isMobile() {
            return this.viewportWidth < 600;
        },
        searchData() {
            return {
                placeholder: this.$t('order.page.list.search.placeholder'),
                tooltipText: this.$t('order.page.list.search.tooltip'),
                countText: this.$tc('order.page.list.search.name', this.orderListMeta.total)
            }
        },

        ...mapGetters(['orderListPages', 'userCanSeeFilters', 'orderListFilters', 'orderListGroups', 'orderListDealers', 'orderListAdvisories', 'orderListMeta', 'orderListActiveFilters', 'orderListScrollPosition', 'orderProductTypes', 'userAuthenticated', 'userHasOrders', 'userCanCreateOrder'])
    },

    watch: {
        'fromDate': {
            handler: function () {
                this.filterFromDate();
            }
        },

        'toDate': {
            handler: function () {
                this.filterToDate();
            }
        },
    },

    components: {
        MainNavigation,
        Search,
        SidebarFilter,
        CustomFilter,
        BasicLinks,
        ProductTypeDropdown,
        CustomTable,
        Notification,
        Dropdown,
        ModalConfirmation,
        CustomDatepicker,
        CustomSelect
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.order-list-page {
    .dashboard-header {
        .checkbox {
            margin-left: auto;
            margin-right: 32px;
        }

        .filter-component {
            margin: 5px 5px 5px 0px;
        }

        .search-container {
            width: 100%;
            max-width: 500px;
            min-width: 150px;
            margin: 0px 5px 5px 0px;

            @media only screen and (max-width: 600px) {
                padding: 10px;
                width: 95%;
                margin: auto;
            }

            .filters--button{
                display: none;
                width: 100%;
                margin-top: 5px;

                @media only screen and (max-width: 600px) {
                    display: block;
                }
            }
        }


        .order-list-page__header-search-flex {
            width: 100%;
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            align-content: space-between;
        }

        .button-refresh {
            margin-right: 30px;
        }
    }

    .col-status {
        display: flex;

        img {
            float: left;
            width: 32px;
            margin-right: 30px;
        }

        .text-block {
            float: left;
            flex-wrap: wrap;
            display: flex;
            align-items: center;

            span {
                flex-basis: 100%;
            }
        }
    }

    .col-shipping {
        span {
            font-family: $font-primary;
        }

        .small-line {
            text-transform: uppercase;
            font-size: 10px;
            line-height: 12px;
        }
    }

    .custom--filters-container{
        display: flex;
        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 95%;
            margin: auto;
            padding: 10px;
        }
    }

    .col-name {
        @media only screen and (max-width: 600px) {
            height: 70px;
            width: max-content;
        }
    }


    .col-representatives {
        span {
            display: block;
        }
    }

    .col-options {
        position: relative;
        width: 100px;

        @media only screen and (max-width: 600px) {
            width: 30px;
        }
    }
}
</style>
