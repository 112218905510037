<template>
    <div class="vgt-wrap">
        <div class="vgt-inner-wrap">
            <div class="vgt-fixed-header"></div>
            <div class="vgt-responsive" ref="tableContainer">
                <table class="vgt-table bordered">
                    <thead>
                    <tr>
                        <th v-for="column in columns" :key="`header-col-${column.field}`"
                            class="vgt-left-align sortable" :class="column.thClass" :style="headerStyle(column)">
                            <span>{{ column.label }}</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody v-if="noRows">
                    <tr>
                        <td :colspan="columns.length">
                            <slot name="empty_state">
                                <div class="vgt-center-align vgt-text-disabled">
                                    No data for table
                                </div>
                            </slot>
                        </td>
                    </tr>
                    </tbody>
                    <template v-else>
                        <tbody v-observe-visibility="visibilityChanged(page)"
                               :class="{'no-data': !page.rows, 'has-data': !!page.rows}" :data-key="`page-${pageIndex}`"
                               v-for="(page, pageIndex) in allPages" :key="`page-${pageIndex}`">
                        <template v-if="page.rows">
                            <tr
                                :data-key="`row-${pageIndex}-${index}`"
                                v-for="(row, index) in page.rows"
                                :class="rowStyleClass ? rowStyleClass(row) : null"
                                :key="`row-${pageIndex}-${index}`"
                                @mouseleave="onMouseleave(row, index)"
                                @click="onRowClicked(row, index, $event)"
                            >
                                <td class="vgt-left-align" v-for="column in columns"
                                    @click="onCellClicked(row, column, index, $event)"
                                    :key="`row-${index}-col-${column.field}`">
                                    <slot 
                                        name="table-row" 
                                        :formattedRow="row"
                                        :row="row" 
                                        :page="page.page"
                                        :column="column">
                                    </slot>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td :colspan="columns.length" :style="emptyPageStyle(page)"></td>
                        </tr>
                        </tbody>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

export default {
    name: 'CustomTable',
    props: ['columns', 'pages', 'meta', 'rowStyleClass', 'rowHeight'],

    data() {
        return {
            loadTimer: null,
            loadPages: [],
            removePages: []
        }
    },

    methods: {
        getScrollPosition() {
            return this.$refs.tableContainer.scrollTop
        },

        scrollTo(position) {
            this.$refs.tableContainer.scrollTop = position
        },

        scrollToTop() {
            this.$refs.tableContainer.scrollTop = 0
        },

        headerStyle(column) {
            return {
                minWidth: column.width ? column.width : 'auto',
                maxWidth: column.width ? column.width : 'auto',
                width: column.width ? column.width : 'auto'
            }
        },

        onMouseleave(row, index) {
            this.$emit('on-row-mouseleave', {row, pageIndex: index})
        },

        onRowClicked(row, index, event) {
            this.$emit('on-row-click', {row, event, pageIndex: index})
        },

        onCellClicked(row, column, rowIndex, event) {
            this.$emit('on-cell-click', {row, column, rowIndex, event})
        },

        emptyPageStyle(page) {
            if (!page.rows) {
                return {
                    height: `${page.length * (this.rowHeight || 72)}px`
                }
            }
            return null
        },
        visibilityChanged(page) {
            let that = this

            return (isVisible) => {
                if (isVisible && !page.rows && !that.loadPages.includes(page.page)) {
                    clearTimeout(that.loadTimer)
                    that.loadTimer = setTimeout(() => {
                        that.loadTimer = null
                        that.loadPages.push(page.page)
                        that.$emit('load-page', page.page)
                    }, 300)
                }

                if (!isVisible && page.rows && !that.removePages.includes(page.page)) {
                    that.removePages.push(page.page)
                    that.$emit('remove-page', page.page)
                }
            }
        }
    },

    watch: {
        pages(val) {
            this.loadPages = this.loadPages.filter(p => {
                return !val.hasOwnProperty(p)
            })

            this.removePages = this.removePages.filter(p => {
                return val.hasOwnProperty(p)
            })
        }
    },

    computed: {
        hasMeta() {
            return Object.keys(this.meta).length > 0
        },
        noRows() {
            return !this.hasMeta || this.meta.total === 0
        },
        allPages() {
            let pages = []

            if (!this.hasMeta) {
                return pages
            }

            for (let page = 1; page <= this.meta.last_page; page++) {
                if (this.pages.hasOwnProperty(page)) {
                    pages.push({
                        page,
                        rows: this.pages[page]
                    })
                } else if (page < this.meta.last_page) {
                    pages.push({
                        page,
                        length: this.meta.per_page
                    })
                } else {
                    pages.push({
                        page,
                        length: Math.min(this.meta.total - ((this.meta.last_page - 1) * this.meta.per_page), this.meta.per_page)
                    })
                }
            }

            return pages
        }
    }
}
</script>

<style scoped>
.vgt-responsive {
    position: relative;
}

.vgt-table {
    position: absolute;
    top: 0;
}
</style>

