<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">Garancia</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="custom-modal modal-box-body">
                <p v-html="modalText"></p>
            </div>

            <div v-if="isOrder" class="custom-modal-footer">
                <button @click="$emit('close')" class="btn btn-secondary">
                    <md-icon>edit</md-icon>
                    Vissza a rendelés szerkesztéséhez
                </button>

                <button @click="$emit('goToOrder')" class="btn btn-primary">
                    <md-icon>shopping_cart</md-icon>
                    Megrendelés
                </button>
            </div>
        </div>

        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'MOdalGuarantee',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        modalText: {
            type: String,
            default: ''
        },
        isOrder: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {

        }
    },

    mounted(){
    },

    methods: {

    },

    computed: {

    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

</style>


<style>
.custom-modal span{
    margin-bottom: 15px;
}

.custom-modal h4{
    display: block;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bolder;
}

.custom-modal-footer{
    margin: 0px 15px 15px 15px!important;
    display: flex;
    gap: 10px;
    align-items: end;
    justify-content: end;
}
</style>