<template>
    <draggable v-model="sortableRows" :group="path">
        <div v-for="row in sortableRows" :key="row.id" :class="{'group': true, 'main-group': path === ''}">
            <div class="row" :class="rowClass(row)" v-show="parentVisible">
                <template v-if="row.children && row.children.length > 0">
                    <button class="name" @click="showHideRow(row)">{{ row.name }}</button>
                    <button class="btn btn-plain btn-plain-wicon button-collapse" @click="showHideRow(row)">
                        <md-icon>keyboard_arrow_down</md-icon>
                    </button>
                </template>
                <label class="name" v-else>{{ row.name }}</label>
            </div>
            <nested-category-sort v-if="row.children && row.children.length > 0 && parentVisible" :parent-visible="visible.includes(row.id)" :path="row.path" :rows="row.children"></nested-category-sort>
        </div>
    </draggable>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'NestedCategorySort',

    props: {
        parentVisible: {
            required: true,
            type: Boolean
        },
        path: {
            required: true,
            type: String
        },
        rows: {
            required: true,
            type: Array
        }
    },

    data(p) {
        return {
            visible: [],
            sortableRows: p.rows
        }
    },

    watch: {
        sortableRows(val) {
            this.$store.commit('setGroupCategorySortPart', {
                path: this.path,
                value: val
            })
        }
    },

    methods: {
        rowClass(row) {
            let classes = []

            if(this.path === '') {
                classes.push('main-group')
            }

            if(row.isProduct) {
                classes.push('product')
            }

            if(this.parentVisible && this.visible.includes(row.id)) {
                classes.push('opened')
            }

            return classes.join(' ')
        },

        showHideRow(row) {
            if(this.visible.includes(row.id)) {
                this.visible = this.visible.filter(id => {
                    return row.id !== id
                })
            } else {
                this.visible.push(row.id)
            }
        }
    },

    components: {
        draggable
    }
};
</script>
<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.dragArea {
    min-height: 50px;
    outline: 1px dashed;
}

.order-page {
    .container {
        max-width: 980px;
        width: 100%;
        margin: 60px auto 0;

        h2 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .box {
            background: $color-white;
            box-shadow: 0px 4px 8px #d9d9d9;
            border: 1px solid $color-gray-border;
            padding: 40px;
            margin-bottom: 50px;

            .label {
                font-size: 15px;
                line-height: 2;
            }

            .group {
                margin-left: 30px;

                &.main-group {
                    margin-left: 0;
                }
            }

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 34px;
                margin: 6px 0;
                padding: 17px 6px;
                border-left: 2px solid #1657B9;
                border-bottom: 2px solid #1657B9;
                background: #f1f1f1;

                .name {
                    padding: 0;
                    margin-left: 10px;
                    color: #333333;
                    font-size: 16px;
                }

                &.main-group {
                    border: 2px solid #1657B9;
                    border-radius: 7px;
                    background: none;

                    .name {
                        color: #1657B9;
                        font-weight: bold;
                        font-size: 17px;
                    }
                }

                &.product {
                    background: #009fe3 none repeat scroll 0 0;
                    border: 1px solid #1657B9;
                    border-radius: 4px;

                    .name {
                        color: white;
                        font-size: 15px;
                        font-weight: normal;
                    }

                    button, i {
                        color: white;
                    }
                }

                .button-collapse {
                    i {
                        transition: transform .5s;
                        vertical-align: middle;
                    }
                }

                &.opened {
                    .button-collapse {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}
</style>
