<template>
    <div class="product-type-dropdown-component" :class="[{'is-open' : open}]">
        <ul>
            <li v-for="type in productTypes" :key="type.id">
                <button type="button" @click="selectProductType(type)">{{type.name}}</button>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import { isInViewport } from '@/plugins/helpers'

Vue.use(MdIcon)

export default {
    name: 'ProductTypeDropdown',
    props: ['open', 'productTypes'],

    data() {
        return {
            fromBottom: false
        }
    },

    watch: {
        open: {
            handler: function() {
                let thisElement = document.querySelector('.product-type-dropdown-component')

                // Not using fromBottom anymore - it has bugs if the viewport doesn't have enough height.
                // this.fromBottom = !isInViewport(thisElement);
            }
        }
    },

    methods: {
        selectProductType(payload) {
            this.$emit('selectedType', payload)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';

.product-type-dropdown-component {
    position: absolute;
    top: 15px;
    left: 45px;
    width: 250px;
    padding: 0;
    border: 1px solid $color-gray-border;
    background: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;

    &.is-open {
        visibility: visible;
        opacity: 1;
    }

    &.from-bottom {
        top: auto;
        bottom: 0;
    }

    ul {
        list-style-type: none;

        li {
            padding: 5px 25px;

            &:hover {
                background: $color-gray-bg-2;
            }
        }

        button {
            font-size: 14px;
            width: 100%;
            padding: 7px 0;
            margin: 3px 0;
            text-align: left;
        }
    }

    @media only screen and (max-width: 600px) {
        top: unset;
        left: unset;
        right: 15px;
        bottom: 75px;
    }
}
</style>
