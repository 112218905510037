<template>
    <div class="discount-navigation-component">
        <button type="button" @click="openDiscounts" v-if="canGiveDiscounts">
            <img src="@/assets/svg/discount.svg">
        </button>

        <button type="button" @click="openServices" v-if="isOffer">
            <img src="@/assets/svg/services-inactive.svg">
        </button>

        <button type="button" @click="openComments" v-if="isOffer">
            <img src="@/assets/svg/comment-inactive.svg">
        </button>

        <button type="button" @click="openTags" v-if="canOpenTags">
            <img src="@/assets/svg/tags-inactive.svg">
            <span class="number" v-if="selectedTagsCount">{{selectedTagsCount}}</span>
        </button>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import { mapGetters } from 'vuex'

Vue.use(MdIcon)

export default {
    name: 'DiscountNavigation',

    data() {
        return {}
    },

    props:{
        canGiveDiscounts: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        openDiscounts() {
            this.$store.commit('openSettingsSidebar', 'discounts')
        },

        openServices() {
            this.$store.commit('openSettingsSidebar', 'services')
        },

        openComments() {
            this.$store.commit('openSettingsSidebar', 'comments')
        },

        openTags() {
            this.$store.commit('openSettingsSidebar', 'tags')
        }
    },

    computed: {
        ...mapGetters(['offerInformation', 'selectedTagsCount', 'canTag']),

        routeName() {
            return this.$route.name
        },

        canOpenTags() {
            return this.canTag && this.$route.name === 'OfferCategory'
        },

        isOffer() {
            return this.$route.name === 'OfferCategory' || this.$route.name === 'NewOfferCategory' || this.$route.name === 'NewRelatedOffer'
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';

.discount-navigation-component {
    position: fixed;
    top: 60px;
    right: 0;
    height: 100%;
    width: 55px;
    border-left: 1px solid $color-gray-border;

    button {
        position: relative;
        width: 100%;
        padding: 10px 5px;
        margin: 5px 0;

        &:hover {
            background: $color-gray-bg-2;
        }

        .number {
            position: absolute;
            height: 15px;
            width: 15px;
            bottom: 10px;
            right: 8px;
            font-size: 11px;
            line-height: 15px;
            border-radius: 50%;
            background: $color-red-1;
            color: $color-white;
        }
    }
}
</style>
