<template>
    <div class="auth-support">
        <p id="auth_support_text" v-html="this.$t('common.support.text', {email_hu_article: emailArticle, email, link} )"></p>
        <ul>
            <li><a href="javascript:void(0)" @click="$emit('openModal','privacy')">{{ $t('common.support.button.privacy')}}</a></li>
            <li><a href="javascript:void(0)" @click="$emit('openModal','terms')">{{ $t('common.support.button.terms')}}</a></li>
        </ul>
    </div>
</template>

<script>
import config from '../../plugins/config.js'

export default {
    name: 'SupportText',

    computed: {
        emailArticle() {
            if (this.email && /^[aeiou]/i.test(this.email)) {
                return 'az'
            } else {
                return 'a'
            }
        },

        email() {
            return config.SUPPORT_EMAIL
        },

        link() {
            return `<a href="mailto:${this.email}?subject=${encodeURIComponent(this.$t('common.name'))}">${this.email}</a>`
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/base/variables.scss';

.auth-support {
    width: 420px;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $color-gray-border;
    text-align: center;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }

    p {
        color: $color-gray-4;
        font-size: 14px;
        line-height: 1.5;
        padding-right: 10%;
    }

    ul {
        margin-top: 35px;
        list-style: none;

        li {
            display: inline-block;
            margin-right: 30px;

            a {
                color: $color-gray-2;
                font-size: 14px;
                text-decoration: underline;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>

<style lang="scss">
@import '../../assets/sass/base/variables.scss';
#auth_support_text {
    a {
        color: $color-gray-2;
        text-decoration: underline;
    }
}
</style>
