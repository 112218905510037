<template>
    <div class="main-navigation-component">

        <router-link v-if="userHasOffers" :to="{name: 'OfferList'}"><img alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo"></router-link>
        <router-link v-else-if="userHasOrders" :to="{name: 'OrderList'}"><img alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo"></router-link>
        <router-link v-else to="/"><img alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo"></router-link>

        <div class="tabs-container">
            <tabs
                :elements="tabElements"
                :selected="tabValue"
                :large="true"
                :navigation="true"
                begin-with="true"
                @changed="goToPage"
            ></tabs>
        </div>

        <div class="profile-container" v-if="userAuthenticated">
            <profile></profile>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import Tabs from '../../components/Tabs.vue'
import Profile from '../../components/Profile.vue'

Vue.use(MdIcon)

export default {
    name: 'MainNavigation',

    data() {
        return {
            tabValue: this.$route.name
        }
    },

    methods: {
        goToPage(name) {
            this.$router.push({name})
        }
    },

    computed: {
        tabElements() {
            let tabElements = [];

            if (this.userHasOffers) {
                tabElements.push({
                    handle: 'OfferList',
                    handleName: this.$t('offer.page.list.navigation')
                });
            }

            if (this.userHasOrders) {
                tabElements.push({
                    handle: 'OrderList',
                    handleName: this.userAccessoryOnly ? this.$t('order.page.list.navigation.accessory_only') : this.$t('order.page.list.navigation.common')
                });
            }

            if (this.userHasObjects) {
                tabElements.push({
                    handle: 'ProjectList',
                    handleName: this.$t('projects.page.list.navigation')
                });
            }

            if (this.userHasObjects) {
                tabElements.push({
                    handle: 'ObjectList',
                    handleName: this.$t('object.page.list.navigation')
                })
            }

            if (this.userHasWorkers) {
                tabElements.push({
                    handle: 'WorkerList',
                    handleName: this.$t('worker.page.list.navigation')
                })
            }

            if (this.userHasDealers) {
                tabElements.push({
                    handle: 'DealerList',
                    handleName: this.$t('dealer.page.list.navigation')
                })
            }

            if (this.userHasUsers) {
                tabElements.push({
                    handle: 'UserList',
                    handleName: this.$t('user.page.list.navigation')
                })
            }

            if (this.userHasProductAdmin) {
                tabElements.push({
                    handle: 'ProductAdmin',
                    handleName: this.$t('product_admin.page.list.navigation')
                })
            }

            if (this.userHasRegionAdmin) {
                tabElements.push({
                    handle: 'RegionList',
                    handleName: this.$t('region.page.list.navigation')
                })
            }

            return tabElements
        },

        ...mapGetters(['userAuthenticated', 'userHasObjects', 'userHasDealers', 'userHasWorkers', 'userHasRegionAdmin', 'userHasUsers', 'userDealerOnly', 'userHasOffers', 'userHasOrders', 'userAccessoryOnly', 'userHasProductAdmin'])
    },

    components: { Tabs, Profile }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';

.main-navigation-component {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid $color-gray-border;

    .logo {
        max-width: 38px;
        height: auto;
        margin-left: 30px;

        @media only screen and (max-width: 600px) {
            margin-left: 10px;
        }
    }

    .tabs-container {
        margin-left: 210px;

        @media only screen and (max-width: 600px) {
            margin-left: 10px;
        }
    }

    .profile-container {
        margin-left: auto;
        margin-right: 25px;
    }
}
</style>
