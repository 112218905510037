<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">
                    <template v-if="!isEdit">{{ $t('worker.manage.title.create') }}</template>
                    <template v-else-if="worker.canEdit">{{ $t('worker.manage.title.edit') }}</template>
                    <template v-else>{{ $t('worker.manage.title.show') }}</template>
                </span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper" :class="{'error' : errors.name}">
                    <label class="text-input-label" for="worker-worker-add">{{ $t('worker.manage.field.name.label') }}</label>
                    <input type="text" :disabled="!worker.canEdit" v-model="worker.name" id="worker-worker-add">
                    <span class="input-error">{{errors.name}}</span>
                </div>
                <div class="input-wrapper" :class="{'error' : errors.email}">
                    <label class="text-input-label" for="email-worker-add">{{ $t('worker.manage.field.email.label') }}</label>
                    <input type="email" :disabled="!worker.canEdit" v-model="worker.email" id="email-worker-add">
                    <span class="input-error">{{errors.email}}</span>
                </div>
                <div class="input-wrapper" :class="{'error' : errors.phone}">
                    <label class="text-input-label" for="phone-worker-add">{{ $t('worker.manage.field.phone.label') }}</label>
                    <input type="text" :disabled="!worker.canEdit" v-model="worker.phone" id="phone-worker-add">
                    <span class="input-error">{{errors.phone}}</span>
                </div>
                <!--<div class="input-wrapper" :class="{'error' : errors.dealerId}" v-if="worker.canEditDealer">
                    <label class="text-input-label">{{ $t('worker.manage.field.dealer.label') }}</label>
                    <custom-select
                        :modal="true"
                        :selectedItem="worker.dealer"
                        :options="worker.dealers"
                        @changed="selectedDealer"
                        :cursorNotAllowed="false"
                        :bold="false"
                        :large="true"
                        placeholder="-"
                    ></custom-select>
                    <span class="input-error">{{errors.dealerId}}</span>
                </div>
                -->
                <div class="button-container" v-if="worker.canEdit">
                    <button v-if="isEdit" class="btn btn-primary button-save" type="button" @click="saveWorker" :disabled="loading">
                        {{ $t('worker.manage.button.save') }}
                    </button>
                    <button v-else class="btn btn-primary button-save" type="button" @click="saveWorker" :disabled="loading">
                        {{ $t('worker.manage.button.create') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('worker.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalWorkerAdd',
    props: ['open', 'workerId'],

    data() {
        return {
            loading: false,
            confirmedEmailSending: false,
            errors: {},
            worker: {
                name: '',
                email: '',
                phone: '',

                dealer: null,
                dealers: [],

                canEdit: false,
                canEditDealer: false
            }
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.confirmedEmailSending = false
                this.$store.dispatch('FETCH_WORKER', this.workerId).then(worker => {
                    this.worker = worker
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveWorker() {
            this.errors = {}

            let payload = {
                id: this.workerId,
                data: {
                    name: this.worker.name,
                    email: this.worker.email,
                    phone: this.worker.phone,
                    //dealerId: this.worker.dealer ? this.worker.dealer.id : null
                }
            }

            this.$store.dispatch('SEND_WORKER', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
        selectedDealer(val) {
            this.worker.dealer = val
        },
    },

    computed: {
        isEdit() {
            return this.workerId !== null;
        }
    },

    components: { CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        &-body {

            input {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                .dropdown-list {
                    top: 76px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
