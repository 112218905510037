<template>
    <div class="auth-form">
        <div class="auth-form__subtitle">{{ $t('re_authenticate.title') }}</div>

        <div class="alert alert--danger" v-if="validation.message">{{ validation.message }}</div>
        <div class="auth-form__row" v-if="!submitted">
            <label for="l_login_password">{{ $t('login.page.field.password.label') }}</label>
            <input
                type="password"
                ref="passwordField"
                name="password"
                v-model="password"
                autocomplete="current-password"
                tabindex="3"
                id="l_login_password"
                :class="{ 'has-error' : hasError('password') }"
                @keyup.enter="validate()">

            <span class="password-reveal" v-if="!passwordRevealed" @click="togglePassword()"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
            <span class="password-reveal" v-else @click="togglePassword()"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

            <span class="auth-form__error" v-if="hasError('password')">{{ validation.fields.password }}</span>
        </div>

        <div class="auth-form__submit" v-if="!submitted">
            <button type="button" class="btn btn-red" @click="validate()">{{ $t('login.page.button.send') }}</button>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Notification from '../Notification.vue'

export default {
    name: 'ReAuthenticate',

    data() {
        return {
            loading: false,
            submitted: false,
            errors: [],
            passwordRevealed: false,
            generalError: '',
            password: ''
        }
    },

    methods: {
        hasError(field) {
            return !!(this.validation.fields && this.validation.fields.hasOwnProperty(field));
        },

        togglePassword() {
            this.passwordRevealed = !this.passwordRevealed
            this.$refs.passwordField.type = this.passwordRevealed ? 'text' : 'password'
        },

        validate() {
            this.loading = true

            this.$store.dispatch('VALIDATE_RELOGIN', {
                payload: {
                    password: this.password
                },
            }).then(() => {
                setTimeout(() => {
                    this.loading = false
                    this.$emit('validPassword')
                }, 500)
            }).catch(response => {
                this.$store.commit('addValidation', [response.error, response.errors])
            })
        }
    },

    computed: {
        ...mapGetters(['validation'])
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/loading.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/partials/auth-form.scss';

.auth-form {
    margin: 50px auto 0;
}
</style>
