<template>
    <div class="auth-page">
        <teaser @on-brand-logo-click="changeView('login')" />

        <div class="auth-page__form">
            <login v-if="authView === 'login'" @switchToForgot="changeView('forgot')" @switchToVerification="changeView('verification')" />
            <verification v-if="authView === 'verification'" @switchToLogin="changeView('login')" />
            <forgot-password v-if="authView === 'forgot'" @switchToLogin="changeView('login')"/>
            <support-text @openModal="openModal($event)" />
        </div>

        <modal-privacy :open="openedModal === 'privacy'" @close="closeModal()"></modal-privacy>
        <modal-terms :open="openedModal === 'terms'" @close="closeModal()"></modal-terms>
    </div>
</template>

<script>
import SupportText from '../components/auth/SupportText.vue'
import Teaser from '../components/auth/Teaser.vue'

import Login from '../components/auth/Login.vue'
import ForgotPassword from '../components/auth/ForgotPassword.vue'
import Verification from '../components/auth/Verification.vue'

import ModalPrivacy from '../components/modals/ModalPrivacy.vue'
import ModalTerms from '../components/modals/ModalTerms.vue'

export default {
    name: 'Auth',

    data() {
        return {
            authView: 'login',
            openedModal: ''
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.$store.dispatch('GO_TO_FIRST_AVAILABLE_LIST_PAGE')
        }).catch(() => {
        })
    },

    methods: {
        changeView(layout) {
            this.authView = layout
        },

        openModal(modal) {
            this.openedModal = modal
        },

        closeModal() {
            this.openedModal = ''
        }
    },

    components: {
        Login, ForgotPassword, Verification, ModalTerms, ModalPrivacy, SupportText, Teaser
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/layout/auth-page.scss';

.logo {
    cursor: pointer;
}
</style>
