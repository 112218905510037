<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('product.select.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain">{{ $t('product.select.button.close') }}</button>
            </div>

            <div class="modal-box-body">
                <div class="autocomplete-container">
                    <label class="text-input-label" for="product-name-select">{{ $t('product.select.field.product.label') }}</label>

                    <input
                        @input="searchProducts"
                        v-model="productName"
                        :disabled="fetching"
                        ref="searchInput"
                        id="product-name-select"
                        type="text"
                        autocomplete="off"
                        :placeholder="this.$t('product.select.text.placeholder')"
                        v-autofocus
                    >

                    <ul v-show="productName" class="dropdown-list">
                        <li
                            @click="selectProduct(result)"
                            v-for="result in this.results"
                            :key="result.productNumber"
                        >
                            <span class="result-name">
                                <span v-html="result.styledName"></span>
                                <span class="address" v-html="result.styledProductNumber"></span>
                            </span>
                            <button class="btn btn-plain" type="button">{{ $t('product.select.button.select') }}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'

import { AutoFocus } from '@/plugins/directives'
import axios from 'axios'
import config from '../../plugins/config.js'

Vue.use(MdIcon)

export default {
    name: 'ModalProductAddSelect',
    props: ['open', 'productData'],

    data() {
        return {
            fetching: true,
            productName: '',
            cancelToken: null,
            timer: null,
            results: []
        }
    },

    watch: {
        open: function(val) {
            this.productName = ''
            if(val) {
                this.fetching = true
                axios.get('products/init-create', config.AXIOS_DEFAULTS).then(() => {
                    this.fetching = false
                })
            }
        }
    },

    methods: {
        searchProducts() {
            if(this.cancelToken) {
                this.cancelToken.cancel()
            }

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }

            this.results = []

            this.timer = setTimeout(() => {
                this.timer = null
                this.cancelToken = axios.CancelToken.source()
                axios.get(`products/for-create?search=${encodeURIComponent(this.productName)}`, {...config.AXIOS_DEFAULTS, cancelToken: this.cancelToken.token}).then((data) => {
                    if(data !== null) {
                        this.results = data
                    }
                }).finally(() => {
                    this.cancelToken = null
                })
            }, 100)
        },

        selectProduct(data) {
            this.$emit('selected', Object.assign({}, data))
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow-y: visible;

            input,
            .input-label {
                width: 100%;
            }

            .autocomplete-container {
                position: relative;
            }

            .dropdown-list {
                top: 76px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 24px;

                    button {
                        text-decoration: none;
                    }

                    .address {
                        display: block;
                        color: $color-gray-2;
                        font-size: 10px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}
</style>
