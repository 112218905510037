<template>
    <div class="order-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon>
                    {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>
        </div>

        <div class="filter-column">
            <group-admin-pages selected-page="GroupCategorySort" :group="group"></group-admin-pages>
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <div class="container">
                <h2>{{ $t('group_category_sort.page_title') }}</h2>
                <div class="box">
                    <div class="is-loading is-loading--inline" v-if="loading"></div>
                    <div v-else>
                        <nested-category-sort :parent-visible="true" path="" :rows="groupCategorySortRows"></nested-category-sort>
                        <button class="btn btn-primary" type="button" @click="saveSort">
                            {{ $t('group_category_sort.button.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {MdIcon} from 'vue-material/dist/components'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'
import NestedCategorySort from "@/components/NestedCategorySort.vue";
import vClickOutside from 'v-click-outside'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Notification from '../components/Notification.vue'
import GroupAdminPages from "@/components/sidebars/GroupAdminPages";
import BasicLinks from '../components/BasicLinks.vue'

Vue.use(MdIcon)

export default {
    name: 'GroupCategorySort',

    data() {
        return {
            loading: true,
            openGroupListDropdown: false,
            productGroups: []
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.fetchData()
                })
            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route'() {
            this.refresh()
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    methods: {
        fetchData() {
            this.$store.dispatch('FETCH_GROUP_CATEGORY_SORT', this.$route.params.groupId).then(() => {
                this.loading = false;
            })
        },

        selectedProductType(payload) {
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        saveSort() {
            const reducer = (carry, r) => {
                if (r.children) {
                    carry.push({
                        id: r.id,
                        children: r.children.reduce(reducer, [])
                    })
                } else {
                    carry.push({
                        id: r.id,
                    })
                }
                return carry
            }

            let data = this.groupCategorySortRows.reduce(reducer, [])

            this.$store.dispatch('SEND_GROUP_CATEGORY_SORT', {
                groupId: this.groupId,
                data
            })
        },

        refresh() {
            this.loading = true
            this.fetchData()
        },
    },

    computed: {
        groupId() {
            return this.$route.params.groupId
        },

        group() {
            return this.productGroups.find(group => group.id === Number(this.groupId))
        },

        ...mapGetters(['groupCategorySortRows', 'groupTypeCategories', 'userHasProductAdmin'])
    },

    components: {
        MainNavigation,
        ProductTypeDropdown,
        Notification,
        BasicLinks,
        GroupAdminPages,
        NestedCategorySort
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.order-page {
    .container {
        max-width: 980px;
        width: 100%;
        margin: 60px auto 0;

        h2 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .box {
            background: $color-white;
            box-shadow: 0px 4px 8px #d9d9d9;
            border: 1px solid $color-gray-border;
            padding: 40px;
            margin-bottom: 50px;

            .label {
                font-size: 15px;
                line-height: 2;
            }

            .btn-primary {
                margin-top: 20px;
            }

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 34px;
                margin: 6px 0;
                padding: 17px 6px;
                border-left: 2px solid #1657B9;
                border-bottom: 2px solid #1657B9;
                background: #f1f1f1;

                .name {
                    padding: 0;
                    margin-left: 10px;
                    color: #333333;
                    font-size: 16px;
                }

                &.depth-1 {
                    border: 2px solid #1657B9;
                    border-radius: 7px;
                    background: none;

                    .name {
                        color: #1657B9;
                        font-weight: bold;
                        font-size: 17px;
                    }
                }

                &.product {
                    background: #009fe3 none repeat scroll 0 0;
                    border: 1px solid #1657B9;
                    border-radius: 4px;

                    .name {
                        color: white;
                        font-size: 15px;
                        font-weight: normal;
                    }

                    button, i {
                        color: white;
                    }
                }

                .button-collapse {
                    i {
                        transition: transform .5s;
                        vertical-align: middle;
                    }
                }

                img {
                    margin-left: 10px;
                }

                &.opened {
                    .button-collapse {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }

                @mixin depth-x-list {
                    @for $i from 2 through 10 {
                        &.depth-#{$i} {margin-left: #{($i - 1) * 30}px; }
                    }
                }

                @include depth-x-list;
            }
        }
    }
}
</style>
