import Vue from 'vue'
import Router from 'vue-router'
import Auth from '../pages/Auth'
import Registration from '../pages/Registration'
import ResetPassword from '../pages/ResetPassword'
import OfferCategory from '../pages/OfferCategory'
import OfferText from '../pages/OfferText'
import OfferList from '../pages/OfferList'
import ObjectList from '../pages/ObjectList'
import ObjectAdd from '../pages/ObjectAdd'
import PendingOrder from '../pages/PendingOrder'
import DealerList from '../pages/DealerList'
import WorkerList from '../pages/WorkerList'
import RegionList from '../pages/RegionList'
import ProductAdmin from '../pages/ProductAdmin'
import ProductList from '../pages/ProductList'
import ProductTypeList from '../pages/ProductTypeList'
import ColorList from '../pages/ColorList'
import UnitList from '../pages/UnitList'
import CarrierList from '../pages/CarrierList'
import SurfaceList from '../pages/SurfaceList'
import GroupAdmin from '../pages/GroupAdmin'
import GroupTypeProductList from '../pages/GroupTypeProductList'
import GroupProductList from '../pages/GroupProductList'
import GroupCategoryProductList from '../pages/GroupCategoryProductList'
import GroupCategoryProductTypeList from '../pages/GroupCategoryProductTypeList'
import GroupCategorySort from '../pages/GroupCategorySort'
import GroupTypeSort from '../pages/GroupTypeSort'
import GroupTypeVariantList from '../pages/GroupTypeVariantList'
import UserList from '../pages/UserList'
import Privacy from '../pages/Privacy'
import Terms from '../pages/Terms'
import NotFound from '../pages/NotFound'
import Maintenance from '../pages/Maintenance'
import Order from '../pages/Order'
import OrderDetails from '../pages/OrderDetails'
import DealerProducts from '../pages/DealerProducts'
import OrderStatus from '../pages/OrderStatus'
import OrderList from '../pages/OrderList'
import ProjectList from '../pages/ProjectList'
import ProductVisibilityPage from '../pages/ProductVisibilityPage'

import i18n from '../i18n'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '*',
            name: 'NotFound',
            component: NotFound
        },
        {
            path: i18n.t('maintenance.page.path'),
            name: 'Maintenance',
            component: Maintenance,
            meta: {
                title: i18n.t('maintenance.page.title')
            }
        },
        {
            path: '/',
            name: 'Auth',
            component: Auth,
            meta: {
                title: i18n.t('login.page.title')
            }
        },
        {
            path: i18n.t('registration.page.path'),
            name: 'Registration',
            component: Registration,
            meta: {
                title: i18n.t('registration.page.title')
            }
        },
        {
            path: i18n.t('reset_password.page.path'),
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                title: i18n.t('reset_password.page.title')
            }
        },
        {
            path: i18n.t('offer.page.edit.category.path'),
            name: 'OfferCategory',
            component: OfferCategory,
            meta: {
                title: i18n.t('offer.page.edit.category.title')
            }
        },
        {
            path: i18n.t('offer.page.create.category.path'),
            name: 'NewOfferCategory',
            component: OfferCategory,
            meta: {
                title: i18n.t('offer.page.create.category.title')
            }
        },
        {
            path: i18n.t('offer.page.create_related.path'),
            name: 'NewRelatedOffer',
            component: OfferCategory,
            meta: {
                title: i18n.t('offer.page.create_related.title')
            }
        },
        {
            path: i18n.t('offer.page.edit.text.path'),
            name: 'OfferText',
            component: OfferText,
            meta: {
                title: i18n.t('offer.page.edit.text.title')
            }
        },
        {
            path: i18n.t('offer.page.create.text.path'),
            name: 'NewOfferText',
            component: OfferText,
            meta: {
                title: i18n.t('offer.page.create.text.title')
            }
        },
        {
            path: i18n.t('offer.page.list.path'),
            name: 'OfferList',
            component: OfferList,
            meta: {
                title: i18n.t('offer.page.list.title')
            }
        },
        {
            path: i18n.t('object.page.list.path'),
            name: 'ObjectList',
            component: ObjectList,
            meta: {
                title: i18n.t('object.page.list.title'),
            }
        },
        {
            path: i18n.t('object.page.create.path'),
            name: 'NewSimpleObject',
            component: ObjectAdd,
            meta: {
                title: i18n.t('object.page.create.title')
            }
        },
        {
            path: i18n.t('object.page.create_for_order.path'),
            name: 'NewOrderObject',
            component: ObjectAdd,
            meta: {
                title: i18n.t('object.page.create_for_order.title')
            }
        },
        {
            path: i18n.t('object.page.edit.path'),
            name: 'Object',
            component: ObjectAdd,
            meta: {
                title: i18n.t('object.page.edit.title')
            }
        },
        {
            path: i18n.t('order.page.pending.edit.path'),
            name: 'EditDiscount',
            component: PendingOrder,
            meta: {
                title: i18n.t('order.page.pending.edit.title')
            }
        },
        {
            path: i18n.t('order.page.pending.approve.path'),
            name: 'ApproveOrder',
            component: PendingOrder,
            meta: {
                title: i18n.t('order.page.pending.approve.title')
            }
        },
        {
            path: i18n.t('order.page.pending.reject.path'),
            name: 'RejectOrder',
            component: PendingOrder,
            meta: {
                title: i18n.t('order.page.pending.reject.title')
            }
        },
        {
            path: i18n.t('dealer.page.list.path'),
            name: 'DealerList',
            component: DealerList,
            meta: {
                title: i18n.t('dealer.page.list.title')
            }
        },
        {
            path: i18n.t('worker.page.list.path'),
            name: 'WorkerList',
            component: WorkerList,
            meta: {
                title: i18n.t('worker.page.list.title')
            }
        },
        {
            path: i18n.t('region.page.list.path'),
            name: 'RegionList',
            component: RegionList,
            meta: {
                title: i18n.t('region.page.list.title')
            }
        },
        {
            path: i18n.t('user.page.list.path'),
            name: 'UserList',
            component: UserList,
            meta: {
                title: i18n.t('user.page.list.title')
            }
        },
        {
            path: i18n.t('product_admin.page.list.path'),
            name: 'ProductAdmin',
            component: ProductAdmin,
            meta: {
                title: i18n.t('product_admin.page.list.title')
            }
        },
        {
            path: i18n.t('group_admin.page.list.path'),
            name: 'GroupAdmin',
            component: GroupAdmin,
            meta: {
                title: i18n.t('group_admin.page.list.title')
            }
        },
        {
            path: i18n.t('product.page.list.path'),
            name: 'ProductList',
            component: ProductList,
            meta: {
                title: i18n.t('product.page.list.title')
            }
        },
        {
            path: i18n.t('product_type.page.list.path'),
            name: 'ProductTypeList',
            component: ProductTypeList,
            meta: {
                title: i18n.t('product_type.page.list.title')
            }
        },
        {
            path: i18n.t('color.page.list.path'),
            name: 'ColorList',
            component: ColorList,
            meta: {
                title: i18n.t('color.page.list.title')
            }
        },
        {
            path: i18n.t('unit.page.list.path'),
            name: 'UnitList',
            component: UnitList,
            meta: {
                title: i18n.t('unit.page.list.title')
            }
        },
        {
            path: i18n.t('surface.page.list.path'),
            name: 'SurfaceList',
            component: SurfaceList,
            meta: {
                title: i18n.t('surface.page.list.title')
            }
        },
        {
            path: i18n.t('carrier.page.list.path'),
            name: 'CarrierList',
            component: CarrierList,
            meta: {
                title: i18n.t('carrier.page.list.title')
            }
        },
        {
            path: i18n.t('group_type_product.page.list.path'),
            name: 'GroupTypeProductList',
            component: GroupTypeProductList,
            meta: {
                title: i18n.t('group_type_product.page.list.title')
            }
        },
        {
            path: i18n.t('group_product.page.list.path'),
            name: 'GroupProductList',
            component: GroupProductList,
            meta: {
                title: i18n.t('group_product.page.list.title')
            }
        },
        {
            path: i18n.t('group_category_product.page.list.path'),
            name: 'GroupCategoryProductList',
            component: GroupCategoryProductList,
            meta: {
                title: i18n.t('group_category_product.page.list.title')
            }
        },
        {
            path: i18n.t('group_category_sort.path'),
            name: 'GroupCategorySort',
            component: GroupCategorySort,
            meta: {
                title: i18n.t('group_category_sort.title')
            }
        },
        {
            path: i18n.t('group_type_sort.path'),
            name: 'GroupTypeSort',
            component: GroupTypeSort,
            meta: {
                title: i18n.t('group_type_sort.title')
            }
        },
        {
            path: i18n.t('group_category_product_type.page.list.path'),
            name: 'GroupCategoryProductTypeList',
            component: GroupCategoryProductTypeList,
            meta: {
                title: i18n.t('group_category_product_type.page.list.title')
            }
        },
        {
            path: i18n.t('group_type_variant.page.list.path'),
            name: 'GroupTypeVariantList',
            component: GroupTypeVariantList,
            meta: {
                title: i18n.t('group_type_variant.page.list.title')
            }
        },
        {
            path: i18n.t('privacy.page.path'),
            name: 'Privacy',
            component: Privacy,
            meta: {
                title: i18n.t('privacy.page.title')
            }
        },
        {
            path: i18n.t('terms.page.path'),
            name: 'Terms',
            component: Terms,
            meta: {
                title: i18n.t('terms.page.title')
            }
        },
        {
            path: i18n.t('order.page.edit.path'),
            name: 'Order',
            component: Order,
            meta: {
                title: i18n.t('order.page.edit.title')
            }
        },
        {
            path: i18n.t('order.page.create.path'),
            name: 'NewOrder',
            component: Order,
            meta: {
                title: i18n.t('order.page.create.title')
            }
        },
        {
            path: i18n.t('order.page.details.common.path'),
            name: 'OrderDetails',
            component: OrderDetails,
            meta: {
                title: i18n.t('order.page.details.common.title')
            }
        },
        {
            path: i18n.t('order.page.details.from_offer.path'),
            name: 'OrderDetailsFromOffer',
            component: OrderDetails,
            meta: {
                title: i18n.t('order.page.details.from_offer.title')
            }
        },
        {
            path: i18n.t('order.page.status.path'),
            name: 'OrderStatus',
            component: OrderStatus,
            meta: {
                title: i18n.t('order.page.status.title')
            }
        },
        {
            path: i18n.t('order.page.list.path'),
            name: 'OrderList',
            component: OrderList,
            meta: {
                title: i18n.t('order.page.list.title')
            }
        },
        {
            path: i18n.t('projects.page.list.path'),
            name: 'ProjectList',
            component: ProjectList,
            meta: {
                title: i18n.t('projects.page.list.title')
            }
        },
        {
            path: i18n.t('dealer.page.products.path'),
            name: 'DealerProducts',
            component: DealerProducts,
            meta: {
                title: i18n.t('dealer.page.products.title')
            }
        },
        {
            path: i18n.t('product.page.list.visibility_path'),
            name: 'ProductVisibilityPage',
            component: ProductVisibilityPage,
            meta: {
                title: i18n.t('product.visibility.title')
            }
        }
    ]
})
