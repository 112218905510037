<template>
    <div class="modal is-open">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>keyboard_arrow_left</md-icon></button>
                <span class="title">{{ $t('project.add_person.title', {type: selectedPersonType}) }}</span>
            </div>

            <div v-if="personType === 'defaultAdvisoryUser'" class="modal-box-body">
                <div class="input-wrapper">
                    <div class="autocomplete-container" v-click-outside="onClickOutside">
                        <label class="text-input-label" for="search-text-advisory">{{ selectedPersonType }}</label>

                        <input
                            v-model="searchText"
                            @focus="searchFocus = true; openResultsList = true"
                            @blur="searchFocus = false"
                            class="search-text-person"
                            :class="{'selected-person' : selectedPerson}"
                            id="search-text-advisory"
                            type="text"
                        >

                        <div class="selected-person-box" v-if="selectedPerson">
                            <span class="name">{{selectedPerson.name}}</span>
                        </div>

                        <button type="button" class="btn btn-plain btn-plain-wicon button-clear" @click="clearInput">
                            <md-icon>close</md-icon>
                        </button>

                        <ul v-show="openResultsList" class="dropdown-list">
                            <li
                                @click="selectPerson(result)"
                                v-for="result in resultsList"
                                :key="result.id"
                            >
                                <span class="result-name">
                                    <span v-html="result.styledName"></span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" :disabled="selectedPerson === null" @click="addAdvisory">{{ $t('project.add_person.button.add') }}</button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('project.add_person.button.back') }}</button>
                </div>
            </div>
            <div v-else-if="personType === 'dealer'" class="modal-box-body">
                <div class="input-wrapper">
                    <div class="autocomplete-container" v-click-outside="onClickOutside">
                        <label class="text-input-label" for="search-text-person">{{ selectedPersonType }}</label>

                        <input
                            v-model="searchText"
                            @focus="searchFocus = true; openResultsList = true"
                            @blur="searchFocus = false"
                            class="search-text-person"
                            :class="{'selected-person' : selectedPerson}"
                            id="search-text-person"
                            type="text"
                        >

                        <div class="selected-person-box" v-if="selectedPerson">
                            <span class="name">{{selectedPerson.name}}</span>
                            <span class="company">{{selectedPerson.company}}</span>
                        </div>

                        <button type="button" class="btn btn-plain btn-plain-wicon button-clear" @click="clearInput">
                            <md-icon>close</md-icon>
                        </button>

                        <ul v-show="openResultsList" class="dropdown-list">
                            <li
                                @click="selectPerson(result)"
                                v-for="result in resultsList"
                                :key="result.id"
                            >
                                <span class="result-name">
                                    <span v-html="result.styledName"></span>
                                    <span class="company">{{result.company}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" :disabled="selectedPerson === null" @click="addDealer">{{ $t('project.add_person.button.add') }}</button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('project.add_person.button.back') }}</button>
                </div>
            </div>
            <div v-else-if="personType === 'worker'" class="modal-box-body">
                <template v-if="showWorkerSelect">
                    <div class="autocomplete-container">
                        <label class="text-input-label" for="worker-name">{{ selectedPersonType }}</label>

                        <input
                            v-if="profile.canCreateWorkers"
                            @keyup.enter="createNewWorker"
                            @keyup="searchWorkers"
                            id="worker-name"
                            v-model="workerName"
                            type="text"
                            :placeholder="this.$t('project.add_person.text.worker_placeholder')"
                            v-autofocus
                        >

                        <input
                            v-else
                            @keyup="searchWorkers"
                            id="worker-name"
                            v-model="workerName"
                            type="text"
                            :placeholder="this.$t('project.add_person.text.worker_placeholder')"
                            v-autofocus
                        >

                        <ul v-show="workerName" class="dropdown-list">
                            <li v-if="profile.canCreateWorkers" @click="createNewWorker()">
                                <strong>{{workerName}}</strong>
                                <button class="btn btn-plain" type="button">{{ $t('project.add_person.button.create') }}</button>
                            </li>

                            <li
                                @click="selectWorker(result)"
                                v-for="result in resultsListWorker"
                                :key="result.id"
                            >
                                <span class="result-name">
                                    <span v-html="result.styledName"></span>
                                </span>
                                <button class="btn btn-plain" type="button">{{ $t('project.add_person.button.select') }}</button>
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-else>
                    <div class="input-wrapper">
                        <label class="text-input-label" for="name-add-worker">{{ $t('project.add_person.field.worker_name.label') }}</label>
                        <input type="text" v-model="worker.name" id="name-add-worker">
                    </div>
                    <div class="input-wrapper">
                        <label class="text-input-label" for="email-add-worker">{{ $t('project.add_person.field.worker_email.label') }}</label>
                        <input type="email" v-model="worker.email" id="email-add-worker">
                    </div>
                    <div class="input-wrapper input-wrapper-last">
                        <label class="text-input-label" for="phone-add-worker">{{ $t('project.add_person.field.worker_phone.label') }}</label>
                        <input type="text" v-model="worker.phone" id="phone-add-worker">
                    </div>
                    <span v-if="workerError || personType === 'worker'">{{workerError}}</span>
                    <hr>
                    <button
                        class="btn btn-primary button-save"
                        type="button"
                        @click="addNewWorker()"
                        :disabled="worker.name.length < 1">{{ $t('project.add_person.button.add') }}</button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('project.add_person.button.back') }}</button>
                </template>
            </div>
        </div>
        <div class="modal-layer"></div>

    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectAddPerson',
    props: ['personType', 'advisoryUsers'],

    data() {
        return {
            person: {
                name: '',
                phone: '',
                email: ''
            },
            worker: {
                name: '',
                phone: '',
                email: ''
            },
            workerError: '',
            searchText: '',
            searchFocus: false,
            openResultsList: false,
            selectedPerson: null,
            results: [],
            allResults: [],
            resultsWorker: [],
            showNewWorker: false,
            showWorkerSelect: true,
            workerName: ''
        }
    },

    mounted() {
        switch (this.personType) {
        case 'defaultAdvisoryUser':
            this.allResults = this.advisoryUsers
            this.results = this.allResults
            break
        case 'worker':
            this.$store.dispatch('FETCH_WORKERS_ALL').then(() => {
                this.resultsWorker = this.workersAll
            })
            break
        default:
            this.$store.dispatch('FETCH_PROJECT_DEALERS').then(() => {
                this.allResults = this.projectDealers
                this.results = this.allResults
            })
        }
    },

    watch: {
        'searchText': function(value) {
            this.results = this.allResults.filter(user => {
                return ( user.name.toLowerCase().indexOf(value.toLowerCase()) > -1 )
            })

            if (value.length < 1) {
                this.results = this.allResults
            }
        }
    },

    computed: {
        ...mapGetters(['offerInformation', 'projectDealers', 'workersAll', 'profile']),

        resultsList() {
            return this.results.map(r => {
                let pos = r.name.toLowerCase().indexOf(this.searchText.toLowerCase())
                let searchTextLength = this.searchText.length
                r.styledName = ''

                if (pos < 1) {
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(0, searchTextLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        searchTextLength,
                        searchTextLength + r.name.length
                    )
                } else {
                    r.styledName += r.name.substring(0, pos)
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(pos, pos + searchTextLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        pos + searchTextLength,
                        pos + searchTextLength + r.name.length
                    )
                }

                return r
            })
        },

        resultsListWorker() {
            return this.resultsWorker.map(r => {
                let pos = r.name.toLowerCase().indexOf(this.workerName.toLowerCase())
                let workerNameLength = this.workerName.length
                r.styledName = ''

                if (pos < 1) {
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(0, workerNameLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        workerNameLength,
                        workerNameLength + r.name.length
                    )
                } else {
                    r.styledName += r.name.substring(0, pos)
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(pos, pos + workerNameLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        pos + workerNameLength,
                        pos + workerNameLength + r.name.length
                    )
                }

                return r
            })
        },

        selectedPersonType () {
            switch (this.personType) {
            case 'defaultAdvisoryUser':
                return this.$t('project.add_person.types.default_advisory_user')
            case 'worker':
                return this.$t('project.add_person.types.worker')
            default:
                return this.$t('project.add_person.types.dealer')
            }
        }
    },

    methods: {
        selectPerson(person) {
            this.selectedPerson = person
            this.openResultsList = false
        },

        addDealer() {
            if (this.selectedPerson) {
                this.$emit('addPerson', this.selectedPerson)
            }
        },

        addAdvisory() {
            if (this.selectedPerson) {
                this.$emit('addPerson', this.selectedPerson)
            }
        },

        addNewWorker() {
            this.workerError = null

            let payload = {
                id: null,
                data: {
                    name: this.worker.name,
                    email: this.worker.email,
                    phone: this.worker.phone
                }
            }

            this.$store.dispatch('SEND_WORKER', payload).then((data) => {
                this.$emit('addPerson', data)
            }).catch(result => {
                this.workerError = result.errors;
            })

            //this.workerError = 'Nem lehet már létrehozni kivitelezőt'
        },

        selectWorker(data) {
            this.$emit('addPerson', data)
        },

        createNewWorker() {
            let newWorker = {
                name: this.workerName,
                phone: '',
                email: ''
            }

            this.worker = {...newWorker}

            this.showWorkerSelect = false
        },

        searchWorkers() {
            let lowerName = this.workerName.toLowerCase()

            this.resultsWorker = this.workersAll.filter((list) => {
                return list.name.toLowerCase().includes(lowerName)
            })
        },

        clearInput() {
            this.openResultsList = false
            this.searchText = ''
            this.selectedPerson = null
        },

        onClickOutside() {
            if (this.openResultsList) {
                this.openResultsList = false
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            button {
                float: left;
            }
        }

        &-body {
            overflow: visible;

            input {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    // padding-bottom: 50px;
                    // border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 60px;
                }
            }

            .button-save {
                margin-right: 25px;
            }

            .autocomplete-container {
                position: relative;

                .button-clear {
                    position: absolute;
                    right: 8px;
                    top: 42px;
                    background: $color-white;
                }

                .search-text-person {
                    &.selected-person {
                        color: transparent;
                        pointer-events: none;
                    }
                }

                .selected-person-box {
                    position: absolute;
                    top: 44px;
                    left: 15px;

                    .name {
                        font-size: 13px;
                    }

                    .company {
                        display: block;
                        color: $color-gray-2;
                        font-size: 10px;
                        line-height: 14px;
                    }
                }

                .dropdown-list {
                    top: 76px;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 24px;

                        button {
                            text-decoration: none;
                        }

                        .company {
                            display: block;
                            color: $color-gray-2;
                            font-size: 10px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }
}
</style>
