<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box" :style="{width: data.width ? `${data.width}px` : null}">
            <div class="modal-box-body">
                <div class="message-text" v-html="data.message"></div>
                <p>
                    <template>
                        <div class="input-wrapper">
                            <label class="text-input-label">Felhasználóra átruházás</label>
                            <custom-select
                                @changed="selectedNewUser"
                                :filterable="true"
                                :options="users"
                                :selectedItem="newUser"
                                :modal="true"
                                :disabled="false"
                                :large="true"
                                :bold="false"
                            ></custom-select>
                            <span class="input-error"></span>
                        </div>
                    </template>
                </p>

                <div class="button-container">
                    <button type="button" class="btn btn-primary" :disabled="loading" @click='deleteUser'>
                        {{ data.acceptText }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ data.cancelText }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {MdIcon} from 'vue-material/dist/components'
import CustomSelect from '../dropdowns/CustomSelect'

Vue.use(MdIcon)

export default {
    name: 'ModalUserDeleteConfirmation',
    props: ['open', 'data', 'userId', 'isAcceptButtonDisabled'],
    components: {
        CustomSelect
    },

    data() {
        return {
            user: null,
            newUser: null,
            users: null,
            loading: true,
        }
    },

    methods: {
        selectedNewUser(val) {
            this.newUser = val
        },

        deleteUser() {
            let payload = {
                id: this.user.id,
                newUserId: this.newUser.id,
            }
            this.$store.dispatch('DELETE_USER', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.delete.notification.success')])
                this.confirmationUserId = null
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('user.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.$emit('close')
            })
        },

        refreshPages() {
            this.loading = true
            this.$store.dispatch('FETCH_USER_PAGES').then(() => {
                this.loading = false
            })
        }
    },

    watch: {
        'open': function (opened) {
            this.$store.dispatch('FETCH_ALL_USERS_WITHOUT_FILTERS').then(() => {
                let payload
                if (opened) {
                    this.users = this.$store.state.allUsersWithoutFilters.rows.map(p => {
                        return {id: p.id, name: p.username + ' - ' + p.name + ' - ' + p.email + ' (' + p.status + ')'}
                    }).filter(x => x.id != this.userId);
                    this.users.unshift({id: null, name: "-"});
                    this.newUser = this.users[0];
                    this.errors = {}
                    this.loading = true

                    payload = {
                        id: this.userId,
                    }

                    this.$store.dispatch('FETCH_USER', payload).then(user => {
                        this.user = user
                        this.loading = false
                    }).catch(e => {
                        console.error(e)
                        this.$emit('close')
                    })
                }
            });
        },
    },

}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        top: 25vh;
        width: 620px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            padding-top: 32px;

            .message-text {
                line-height: 1.2;
            }

            .button-container {
                margin-top: 25px;

                button {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }
}
</style>
