<template>
    <div class="offer-page" v-if="userAuthenticated">
        <job-navigation :loading="loading"
                        :originalOfferId="offerInformation.originalOfferId"
                        :offerName="offerName"
                        :isOffer="true" @goToOriginalOffer="goToOriginalOffer"
                        @saveOffer="saveOffer"
                        @closePage="closePage()"
                        :viewOnly="viewOnly" :can-edit-sales="canEditSales"></job-navigation>

        <div class="product-list" :class="{'multilevel-products': orderData.multiLevel}" v-show="!loading">
            <div class="product-list-settings"
                v-if="orderData.groupTypes || orderData.variantType || orderData.hasCalculation || orderData.basicInformation.length > 0"
            >
                <div class="col-category" v-if="orderData.groupTypes">
                    <span class="label">{{ $t('offer.page.edit.category.field.groups') }}</span>
                    <custom-group-select
                        v-if="orderData.groupTypes.grouped"
                        :options="orderData.groupTypes.groups"
                        :selectedItem="orderData.selectedGroupType"
                        @changed="updateGroupTypeSelect"
                        :disabled="viewOnly">
                    </custom-group-select>
                    <custom-select
                        v-if="!orderData.groupTypes.grouped"
                        :large="true"
                        :options="orderData.groupTypes.options"
                        :selectedItem="orderData.selectedGroupType"
                        @changed="updateGroupTypeSelect"
                        :disabled="viewOnly">
                        >
                    </custom-select>
                </div>

                <div class="col-color" v-if="orderData.variantType">
                    <template v-if="orderData.variants">
                        <span class="label">{{ orderData.variantType }}</span>
                        <custom-select
                            :large="true"
                            :options="orderData.variants"
                            :selectedItem="orderData.selectedVariant"
                            @changed="updateVariant"
                            :disabled="viewOnly"
                        ></custom-select>
                    </template>
                    <div class="checkbox" v-if="orderData.differentColors !== null && !orderData.restricted">
                        <input
                            type="checkbox"
                            :checked="orderData.differentColors"
                            @input="updateDifferentColors"
                            id="color"
                            :disabled="viewOnly"
                        >
                        <label for="color">{{ $t('offer.page.edit.category.field.different_colors') }}</label>
                    </div>
                </div>

                <div class="col-calculation" v-if="orderData.hasCalculation && !viewOnly">
                    <button type="button" class="btn btn-secondary" @click="openCalculation">
                        <md-icon>straighten</md-icon>
                        {{ $t('offer.page.edit.category.button.plan_calculation') }}
                    </button>
                    <button type="button" class="btn btn-secondary last" @click="openConsignment"
                            v-if="orderData.calculation && orderData.calculation.selectableProducts">
                        <md-icon>straighten</md-icon>
                        {{ $t('offer.page.edit.category.button.consignment_details') }}
                    </button>
                </div>
                <div class="col-calculation"
                     v-else-if="orderData.calculation && orderData.calculation.selectableProducts">
                    <button type="button" class="btn btn-secondary" @click="openCalculation"
                            v-if="orderData.hasCalculation && viewOnly">
                        <md-icon>straighten</md-icon>
                        {{ $t('offer.page.edit.category.button.plan_calculation_details') }}
                    </button>
                    <button type="button" class="btn btn-secondary"
                            :class="{last: orderData.hasCalculation && viewOnly}" @click="openConsignment">
                        <md-icon>straighten</md-icon>
                        {{ $t('offer.page.edit.category.button.consignment_details') }}
                    </button>
                </div>
                <div class="col-calculation"
                     v-else-if="orderData.calculation && orderData.hasCalculation && !orderData.calculation.selectableProducts && viewOnly">
                    <button type="button" class="btn btn-secondary" @click="openCalculation">
                        <md-icon>straighten</md-icon>
                        {{ $t('offer.page.edit.category.button.plan_calculation_details') }}
                    </button>
                </div>

                <div class="col-plan-info is-offer"
                     :class="{'no-calculation': (!(orderData.hasCalculation && !viewOnly) && !(orderData.calculation && orderData.calculation.selectableProducts) && !(orderData.calculation && orderData.hasCalculation && !orderData.calculation.selectableProducts && viewOnly))}"
                     v-if="orderData.basicInformation.length > 0">
                    <div class="plan-info-row" v-for="(information, index) in orderData.basicInformation" :key="index">
                        <div class="plan-info-item" v-for="info in information" :key="info.id">
                            <span class="label">{{ info.label }}</span>
                            <span class="value">{{ info.value }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product-list-body">
                <div class="product-list-body-filters">

                    <input
                        v-model="search"
                        @input="searchInProducts"
                        placeholder="Keresés"
                        style="margin-right:15px"
                        class="small"
                    >

                    <div class="checkbox" style="margin-right:15px">
                        <input type="checkbox" v-model="showProductNumbers" id="show-product-numbers">
                        <label for="show-product-numbers">{{
                                $t('offer.page.edit.category.field.show_product_numbers')
                            }}</label>
                    </div>
                    <div class="checkbox">
                        <input type="checkbox" v-model="showOnlyAdded" id="show-added">
                        <label for="show-added">{{ $t('offer.page.edit.category.field.show_only_added') }}</label>
                    </div>
                    <span class="label">{{ $t('offer.page.edit.category.field.prices') }}</span>
                    <button
                        type="button"
                        class="btn"
                        :class="{'btn-secondary' : showNetPrice, 'btn-simple' : !showNetPrice}"
                        @click="showNetPrice = true"
                    >{{ $t('offer.page.edit.category.option.prices.net') }}
                    </button>
                    <button
                        type="button"
                        class="btn"
                        :class="{'btn-secondary' : !showNetPrice, 'btn-simple' : showNetPrice}"
                        @click="showNetPrice = false"
                    >{{ $t('offer.page.edit.category.option.prices.gross') }}
                    </button>
                    <div class="input-switchers" v-if="orderData.multiLevel">
                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : showInputs, 'btn-simple' : !showInputs}"
                            @click="showInputs = true"
                        >{{ $t('offer.page.edit.category.option.field.input') }}
                        </button>
                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : !showInputs, 'btn-simple' : showInputs}"
                            @click="showInputs = false"
                        >{{ $t('offer.page.edit.category.option.field.additional') }}
                        </button>
                    </div>
                </div>

                <div v-if="!search">
                    <div
                        class="category-container"
                        v-for="(category, index) in categories"
                        :key="category.id"
                        :data-id="category.id"
                        :class="getCategoryClasses(category)"
                        >
                        <button
                            @click="toggleChildren(category)"
                            v-show="(category.children.length > 0 || (category.depth < 3 && category.products.length > 0) ) && (!showOnlyAdded || hasAddedProducts(category)) && orderData.multiLevel"
                            class="btn btn-plain btn-plain-wicon button-collapse">
                            <md-icon>keyboard_arrow_down</md-icon>
                        </button>
                        <span
                            @click="toggleChildren(category)"
                            v-show="!showOnlyAdded || hasAddedProducts(category)"
                            class="category-name"
                        >{{ category.name }}</span>
                        <div class="line-recommended"
                            v-show="category.recommended && ( !showOnlyAdded || hasAddedProducts(category) )">
                            {{ $t('offer.page.edit.category.text.recommended.category') }}
                        </div>

                        <table class="table-products"
                        v-show="(!showOnlyAdded || hasAddedProducts(category)) && category.products.length > 0">
                            <thead>
                                <tr>
                                    <th class="th-name">{{ $t('offer.page.edit.category.column.product') }}</th>
                                    <th class="th-color">&nbsp;</th>
                                    <th class="th-property" v-if="showColumn('packing', category.properties, showInputs, true)">
                                        {{ $t('offer.page.edit.category.column.packing') }}
                                    </th>
                                    <th class="th-property"
                                        v-if="showColumn('carrier', category.properties, showInputs, false)">
                                        {{ $t('offer.page.edit.category.column.carrier') }}
                                    </th>
                                    <th class="th-property"
                                        v-if="showColumn('surface', category.properties, showInputs, false)">
                                        {{ $t('offer.page.edit.category.column.surface') }}
                                    </th>
                                    <th class="th-property"
                                        v-if="showColumn('thickness', category.properties, showInputs, false)">
                                        {{ $t('offer.page.edit.category.column.thickness') }}<span class="subtext">mm</span>
                                    </th>
                                    <th class="th-property" v-if="showColumn('weight', category.properties, showInputs, false)">
                                        {{ $t('offer.page.edit.category.column.weight') }}<span class="subtext">kg/m²</span>
                                    </th>
                                    <th class="th-amount">{{ $t('offer.page.edit.category.column.amount') }}</th>
                                    <th class="th-list-price">{{  (groupId != 10 && groupId != 11) ?  $t('offer.page.edit.category.column.list_price') : 'Egységár'}}</th>
                                    <th class="th-sales-discount" v-show="(discountType === 3 || discountType === 4) && isWorkerOffer"
                                        v-html="salesDiscountColumn">
                                    </th>
                                    <th class="th-buyer-discount" v-show="(discountType === 3 || discountType === 4) && (groupId != 10 && groupId != 11)"
                                        v-html="buyerDiscountColumn"></th>
                                    <th class="th-dealer-discount" v-show="(discountType === 3 || discountType === 4) && !isWorkerOffer && (groupId != 10 && groupId != 11)"
                                        v-html="dealerDiscountColumn"></th>
                                    <th class="th-icons" v-if="category.hasWarnings">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="product in category.products"
                                    :key="product.id"
                                    :class="getRowClasses(product)">
                                    <td :colspan="!orderData.differentColors || orderData.restricted || !product.mainColor ? 2 : null">
                                        <div class="col-name col-name--with-select" v-if="product.selectable">
                                            <custom-select
                                                v-if="product.selectable"
                                                :large="true"
                                                :width="300"
                                                :options="product.options"
                                                :selectedItem="{...product, name: showProductNumbers ? product.nameWithProductNumber : product.name}"
                                                @changed="(el) => changeSelectableProduct(product.type, el)"
                                                :disabled="viewOnly || product.isDeleted">
                                                >
                                            </custom-select>
                                            <md-icon
                                                v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                                v-if="product.info">info
                                            </md-icon>
                                        </div>
                                        <div class="col-name" v-else>
                                            <span class="text">
                                                {{ showProductNumbers ? product.nameWithProductNumber : product.name }}
                                                <span class="deleted-product" v-if="product.isDeleted">FIGYELEM TÖRÖLT TERMÉK!</span>
                                                <template
                                                v-if="recommendedProductId === product.id">{{
                                                    $t('offer.page.edit.category.text.recommended.product')
                                                }}
                                                </template>
                                                <md-icon
                                                    v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                                    v-if="product.info">info</md-icon>
                                            </span>
                                            <span class="text-secondary">{{ product.subText }}</span>
                                        </div>
                                    </td>
                                    <td v-if="orderData.differentColors && product.mainColor && !orderData.restricted">
                                        <div class="col-color">
                                            <button
                                                v-if="!isSecondaryColors(product.type) && !viewOnly"
                                                type="button"
                                                class="btn btn-plain"
                                                @click="addSecondaryColor(product.type)"
                                            >
                                                <md-icon>arrow_drop_down</md-icon>
                                                <span>{{ $t('offer.page.edit.category.button.in_other_colors') }}</span>
                                            </button>

                                            <button
                                                v-if="isSecondaryColors(product.type) && !viewOnly"
                                                type="button"
                                                class="btn btn-plain"
                                                @click="removeSecondaryColor(product.type)"
                                            >
                                                <md-icon>arrow_drop_up</md-icon>
                                                <span>{{ $t('offer.page.edit.category.button.only_in_one_color') }}</span>
                                            </button>
                                        </div>
                                    </td>
                                    <td v-if="showColumn('packing', category.properties, showInputs, true)">
                                        <div class="col-packing col-info">
                                                <span class="text">
                                                    {{ product.properties.packing }}
                                                </span>
                                        </div>
                                    </td>
                                    <td v-if="showColumn('carrier', category.properties, showInputs, false)">
                                        <div class="col-carrier col-info">
                                                <span class="text">
                                                    {{ product.properties.carrier }}
                                                </span>
                                        </div>
                                    </td>
                                    <td v-if="showColumn('surface', category.properties, showInputs, false)">
                                        <div class="col-surface col-info">
                                                <span class="text">
                                                    {{ product.properties.surface }}
                                                </span>
                                        </div>
                                    </td>
                                    <td v-if="showColumn('thickness', category.properties, showInputs, false)">
                                        <div class="col-thickness col-info">
                                                <span class="text">
                                                    {{ product.properties.thickness }}
                                                </span>
                                        </div>
                                    </td>
                                    <td v-if="showColumn('weight', category.properties, showInputs, false)">
                                        <div class="col-weight col-info">
                                                <span class="text">
                                                    {{ product.properties.weight }}
                                                </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-amount">
                                            <div>
                                                <input
                                                    :value="product.amount"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    :step="getStep(product)"
                                                    @input="updateAmount($event.target.value, product, false)"
                                                    @blur="updateAmount($event.target.value, product, true)"
                                                    type="number"
                                                    class="small"
                                                    min="0"
                                                    v-zerohandling
                                                    :data-id="product.id"
                                                    :disabled="viewOnly || !product.editable || product.isDeleted"
                                                >
                                                <span v-if="product.units.length === 1">{{ product.amountUnit.name }}</span>
                                                <custom-select
                                                    v-else
                                                    :options="product.units"
                                                    :selectedItem="product.amountUnit"
                                                    @changed="changeUnit($event, product)"
                                                    :disabled="viewOnly || !product.editable || product.isDeleted"
                                                    class="unit-selector">
                                                </custom-select>
                                                <span v-if="product.squareMeter && !product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                        round(product.squareMeter * product.amount * product.amountUnit.multiplier, 3)
                                                    }} m²</span>
                                                <span v-if="product.squareMeter && product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                        Math.round(product.amount / product.squareMeter)
                                                    }} {{ product.listPrice.unitName }}
                                                </span>
                                            </div>
                                            <div class="amount-info" v-if="product.amountUnit.info && groupId != 10">{{
                                                    product.amountUnit.info
                                                }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-amount">
                                            <div>
                                                <input
                                                    v-if="categoriesWithEditablePrice.includes(product.type)"
                                                    v-model="product.listPrice.net"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    :step="getStep(product)"
                                                    type="number"
                                                    class="small"
                                                    min="0"
                                                    v-zerohandling
                                                    :data-id="product.id"
                                                    :disabled="viewOnly || !product.editable || product.isDeleted"
                                                >
                                                <div class="col-price">
                                                    <span class="text">
                                                        {{
                                                            product.amount * (product.amountUnit.multiplier ? product.amountUnit.multiplier : 1) * productPrice(product, 'list', !showNetPrice) | formatPrice(false)
                                                        }}
                                                        <span>Ft</span>
                                                    </span>
                                                    <span class="text-secondary">
                                                        {{ productUnitPrice(product, 'list', !showNetPrice) | formatPrice(false) }}
                                                        Ft/{{ product.amountUnit.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-show="(discountType === 3 || discountType === 4) && isWorkerOffer">
                                        <div class="col-sales">
                                            <div class="text-part">
                                                <span class="text">
                                                    {{
                                                        product.amount * product.amountUnit.multiplier * productPrice(product, 'sales', !showNetPrice) | formatPrice(false)
                                                    }}
                                                    <span>Ft</span>
                                                </span>
                                                <span class="text-secondary">
                                                    {{ productUnitPrice(product, 'sales', !showNetPrice) | formatPrice(false) }}
                                                    Ft/{{ product.amountUnit.name }}
                                                </span>
                                            </div>

                                            <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                                <div class="sub-discounts-part"
                                                    v-for="subDiscount in product.listPrice.subDiscounts"
                                                    :key="subDiscount.id">
                                                    <label :for="`product_sales_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                            subDiscount.name
                                                        }}</label>
                                                    <div class="input-part">
                                                        <input
                                                            :id="`product_sales_sub_discount_${product.id}_${subDiscount.id}`"
                                                            v-if="product.salesDiscount"
                                                            type="number"
                                                            class="small"
                                                            step='0.01'
                                                            min="0"
                                                            max="100"
                                                            :value="product.salesDiscount[subDiscount.id]"
                                                            @input="updateSalesDiscount($event, product, subDiscount.id)"
                                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                            v-zerohandling
                                                            :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                        >
                                                        <span>%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="input-part">
                                                <input
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.salesDiscount"
                                                    @input="updateSalesDiscount($event, product)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-if="(discountType === 3 || discountType === 4)  && (groupId != 10 && groupId != 11)">
                                        <div class="col-buyer">
                                            <div class="text-part">
                                                <span class="text">
                                                    {{
                                                        product.amount * product.amountUnit.multiplier * productPrice(product, 'buyer', !showNetPrice) | formatPrice(false)
                                                    }}
                                                    <span>Ft</span>
                                                </span>
                                                <span class="text-secondary">
                                                    {{ productUnitPrice(product, 'buyer', !showNetPrice) | formatPrice(false) }}
                                                    Ft/{{ product.amountUnit.name }}
                                                </span>
                                            </div>

                                            <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                                <div class="sub-discounts-part"
                                                    v-for="subDiscount in product.listPrice.subDiscounts"
                                                    :key="subDiscount.id">
                                                    <label :for="`product_buyer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                            subDiscount.name
                                                        }}</label>
                                                    <div class="input-part">
                                                        <input
                                                            :id="`product_buyer_sub_discount_${product.id}_${subDiscount.id}`"
                                                            type="number"
                                                            class="small"
                                                            step='0.01'
                                                            min="0"
                                                            max="100"
                                                            :value="product.buyerDiscount[subDiscount.id]"
                                                            @input="updateBuyerDiscount($event, product, subDiscount.id)"
                                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                            v-zerohandling
                                                            :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                        >
                                                        <span>%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="input-part">
                                                <input
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.buyerDiscount"
                                                    @input="updateBuyerDiscount($event, product)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else></td>
                                    <td v-if="(discountType === 3 || discountType === 4) && !isWorkerOffer  && (groupId != 10 && groupId != 11)">
                                        <div class="col-dealer">
                                            <div class="text-part">
                                                <span class="text">
                                                    {{
                                                        product.amount * product.amountUnit.multiplier * productPrice(product, 'dealer', !showNetPrice) | formatPrice(false)
                                                    }}
                                                    <span>Ft</span>
                                                </span>
                                                <span class="text-secondary">
                                                    {{ productUnitPrice(product, 'dealer', !showNetPrice) | formatPrice(false) }}
                                                    Ft/{{ product.amountUnit.name }}
                                                </span>
                                            </div>
                                            <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                                <div class="sub-discounts-part"
                                                    v-for="subDiscount in product.listPrice.subDiscounts"
                                                    :key="subDiscount.id">
                                                    <label :for="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                            subDiscount.name
                                                        }}</label>
                                                    <div class="input-part">
                                                        <input
                                                            :id="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`"
                                                            v-if="product.dealerDiscount"
                                                            type="number"
                                                            class="small"
                                                            step='0.01'
                                                            min="0"
                                                            max="100"
                                                            :value="product.dealerDiscount[subDiscount.id]"
                                                            @input="updateDealerDiscount($event, product, subDiscount.id)"
                                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                            v-zerohandling
                                                            :disabled="discountType === 3 || viewOnly || isWorkerOffer || product.isDeleted"
                                                        >
                                                        <span>%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else class="input-part">
                                                <input
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.dealerDiscount"
                                                    @input="updateDealerDiscount($event, product)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || viewOnly || isWorkerOffer || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-else></td>
                                    <td v-if="product.warnings">
                                        <button>
                                            <md-icon>history</md-icon>
                                        </button>

                                        <button>
                                            <md-icon>highlight_off</md-icon>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="editableGroups.includes(parseInt(groupId))">

                                    <td v-if="groupId == 10">
                                        <input v-model="newProducts[index].name" class="small" type="text" placeholder="Új termék neve">
                                    </td>
                                    <td v-else>
                                        <input v-model="newProducts[index].name" class="small" type="text" placeholder="Új munkanem">
                                    </td>


                                    <td></td>

                                    <td>
                                        <div class="col-amount">
                                            <div>
                                                <input v-model="newProducts[index].amount" class="small" type="number" placeholder="">
                                                <custom-select
                                                    :options="newProducts[index].units"
                                                    :selectedItem="newProducts[index].amountUnit"
                                                    @changed="changeUnit($event, newProducts[index])"
                                                    :disabled="viewOnly || !newProducts[index].editable || newProducts[index].isDeleted"
                                                    class="unit-selector">
                                                </custom-select>
                                            </div>
                                            <div class="amount-info" v-if="newProducts[index].amountUnit.info">
                                                {{ newProducts[index].amountUnit.info }}
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="col-amount">
                                            <div>
                                                <input v-model="newProducts[index].listPrice.net" class="small" type="number">
                                                <div class="col-price" style="margin-left:auto">
                                                    <span class="text">
                                                        {{
                                                            newProducts[index].amount * newProducts[index].amountUnit.multiplier * newProducts[index].listPrice.net | formatPrice(false)
                                                        }}
                                                        <span>Ft</span>
                                                    </span>
                                                    <span class="text-secondary">
                                                        {{ productUnitPrice(newProducts[index], 'list') | formatPrice(false) }}
                                                        Ft/{{ newProducts[index].amountUnit.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr v-if="editableGroups.includes(parseInt(groupId))">
                                    <td colspan="6">
                                        <button
                                            :disabled="(newProducts[index].amount <= 0 || newProducts[index].name == null || newProducts[index].listPrice.net == null)"
                                            @click="addNewProduct(index, category.id)"
                                            style="background:#009fe3;color:white"
                                            class="btn button-save">
                                            + hozzáadás
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-else>
                    <table class="table-products">
                        <thead>
                        <tr>
                            <th class="th-name">{{ $t('offer.page.edit.category.column.product') }}</th>
                            <th class="th-color">&nbsp;</th>
                            <th class="th-property">
                                {{ $t('offer.page.edit.category.column.packing') }}
                            </th>
                            <th class="th-property">
                                {{ $t('offer.page.edit.category.column.carrier') }}
                            </th>
                            <th class="th-property">
                                {{ $t('offer.page.edit.category.column.surface') }}
                            </th>
                            <th class="th-property">
                                {{ $t('offer.page.edit.category.column.thickness') }}<span class="subtext">mm</span>
                            </th>
                            <th class="th-property">
                                {{ $t('offer.page.edit.category.column.weight') }}<span class="subtext">kg/m²</span>
                            </th>
                            <th class="th-amount">{{ $t('offer.page.edit.category.column.amount') }}</th>
                            <th class="th-list-price">{{  (groupId != 10 && groupId != 11) ?  $t('offer.page.edit.category.column.list_price') : 'Egységár'}}</th>
                            <th class="th-sales-discount" v-if="(discountType === 3 || discountType === 4) && isWorkerOffer" v-html="salesDiscountColumn">
                            </th>
                            <th v-else>

                            </th>
                            <th class="th-buyer-discount" v-if="(discountType === 3 || discountType === 4) && (groupId != 10 && groupId != 11)"
                                v-html="buyerDiscountColumn"></th>
                                <th v-else>

                                </th>
                            <th class="th-dealer-discount" v-if="(discountType === 3 || discountType === 4) && !isWorkerOffer && (groupId != 10 && groupId != 11)"
                                v-html="dealerDiscountColumn"></th>
                                <th v-else>

                                </th>
                            <th class="th-icons">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr
                            v-for="product in results"
                            :key="product.id"
                            :class="getRowClasses(product)">
                            <td :colspan="!orderData.differentColors || orderData.restricted || !product.mainColor ? 2 : null">
                                <div class="col-name col-name--with-select" v-if="product.selectable">
                                    <custom-select
                                        v-if="product.selectable"
                                        :large="true"
                                        :width="300"
                                        :options="product.options"
                                        :selectedItem="{...product, name: showProductNumbers ? product.nameWithProductNumber : product.name}"
                                        @changed="(el) => changeSelectableProduct(product.type, el)"
                                        :disabled="viewOnly || product.isDeleted">
                                        >
                                    </custom-select>
                                    <md-icon
                                        v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                        v-if="product.info">info
                                    </md-icon>
                                </div>
                                <div class="col-name" v-else>
                                    <span class="text">
                                        {{ showProductNumbers ? product.nameWithProductNumber : product.name }}
                                        <span class="deleted-product" v-if="product.isDeleted">FIGYELEM TÖRÖLT TERMÉK!</span>
                                        <template
                                        v-if="recommendedProductId === product.id">{{
                                            $t('offer.page.edit.category.text.recommended.product')
                                        }}
                                        </template>
                                        <md-icon
                                            v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                            v-if="product.info">info</md-icon>
                                    </span>
                                    <span class="text-secondary">{{ product.subText }}</span>
                                </div>
                            </td>
                            <td v-if="orderData.differentColors && product.mainColor && !orderData.restricted">
                                <div class="col-color">
                                    <button
                                        v-if="!isSecondaryColors(product.type) && !viewOnly"
                                        type="button"
                                        class="btn btn-plain"
                                        @click="addSecondaryColor(product.type)"
                                    >
                                        <md-icon>arrow_drop_down</md-icon>
                                        <span>{{ $t('offer.page.edit.category.button.in_other_colors') }}</span>
                                    </button>

                                    <button
                                        v-if="isSecondaryColors(product.type) && !viewOnly"
                                        type="button"
                                        class="btn btn-plain"
                                        @click="removeSecondaryColor(product.type)"
                                    >
                                        <md-icon>arrow_drop_up</md-icon>
                                        <span>{{ $t('offer.page.edit.category.button.only_in_one_color') }}</span>
                                    </button>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="showColumn('packing', product.category.properties, showInputs, true)">
                                <div class="col-packing col-info">
                                        <span class="text">
                                            {{ product.properties.packing }}
                                        </span>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="showColumn('carrier', product.category.properties, showInputs, false)">
                                <div class="col-carrier col-info">
                                    <span class="text">
                                        {{ product.properties.carrier }}
                                    </span>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="showColumn('surface', product.category.properties, showInputs, false)">
                                <div class="col-surface col-info">
                                        <span class="text">
                                            {{ product.properties.surface }}
                                        </span>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="showColumn('thickness', product.category.properties, showInputs, false)">
                                <div class="col-thickness col-info">
                                        <span class="text">
                                            {{ product.properties.thickness }}
                                        </span>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="showColumn('weight', product.category.properties, showInputs, false)">
                                <div class="col-weight col-info">
                                        <span class="text">
                                            {{ product.properties.weight }}
                                        </span>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td>
                                <div class="col-amount">
                                    <div>
                                        <input
                                            :value="product.amount"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            :step="getStep(product)"
                                            @input="updateAmount($event.target.value, product, false)"
                                            @blur="updateAmount($event.target.value, product, true)"
                                            type="number"
                                            class="small"
                                            min="0"
                                            v-zerohandling
                                            :data-id="product.id"
                                            :disabled="viewOnly || !product.editable || product.isDeleted"
                                        >
                                        <span v-if="product.units.length === 1">{{ product.amountUnit.name }}</span>
                                        <custom-select
                                            v-else
                                            :options="product.units"
                                            :selectedItem="product.amountUnit"
                                            @changed="changeUnit($event, product)"
                                            :disabled="viewOnly || !product.editable || product.isDeleted"
                                            class="unit-selector">
                                        </custom-select>
                                        <span v-if="product.squareMeter && !product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                round(product.squareMeter * product.amount * product.amountUnit.multiplier, 3)
                                            }} m²</span>
                                        <span v-if="product.squareMeter && product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                Math.round(product.amount / product.squareMeter)
                                            }} {{ product.listPrice.unitName }}
                                        </span>
                                    </div>
                                    <div class="amount-info" v-if="product.amountUnit.info && groupId != 10">{{
                                            product.amountUnit.info
                                        }}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="col-amount">
                                    <div>
                                        <input
                                            v-if="categoriesWithEditablePrice.includes(product.type)"
                                            v-model="product.listPrice.net"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            :step="getStep(product)"
                                            type="number"
                                            class="small"
                                            min="0"
                                            v-zerohandling
                                            :data-id="product.id"
                                            :disabled="viewOnly || !product.editable || product.isDeleted"
                                        >
                                        <div class="col-price">
                                            <span class="text">
                                                {{
                                                    product.amount * (product.amountUnit.multiplier ? product.amountUnit.multiplier : 1) * productPrice(product, 'list', !showNetPrice) | formatPrice(false)
                                                }}
                                                <span>Ft</span>
                                            </span>
                                            <span class="text-secondary">
                                                {{ productUnitPrice(product, 'list', !showNetPrice) | formatPrice(false) }}
                                                Ft/{{ product.amountUnit.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td v-show="(discountType === 3 || discountType === 4) && isWorkerOffer">
                                <div class="col-sales">
                                    <div class="text-part">
                                        <span class="text">
                                            {{
                                                product.amount * product.amountUnit.multiplier * productPrice(product, 'sales', !showNetPrice) | formatPrice(false)
                                            }}
                                            <span>Ft</span>
                                        </span>
                                        <span class="text-secondary">
                                            {{ productUnitPrice(product, 'sales', !showNetPrice) | formatPrice(false) }}
                                            Ft/{{ product.amountUnit.name }}
                                        </span>
                                    </div>

                                    <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                        <div class="sub-discounts-part"
                                             v-for="subDiscount in product.listPrice.subDiscounts"
                                             :key="subDiscount.id">
                                            <label :for="`product_sales_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                    subDiscount.name
                                                }}</label>
                                            <div class="input-part">
                                                <input
                                                    :id="`product_sales_sub_discount_${product.id}_${subDiscount.id}`"
                                                    v-if="product.salesDiscount"
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.salesDiscount[subDiscount.id]"
                                                    @input="updateSalesDiscount($event, product, subDiscount.id)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="input-part">
                                        <input
                                            type="number"
                                            class="small"
                                            step='0.01'
                                            min="0"
                                            max="100"
                                            :value="product.salesDiscount"
                                            @input="updateSalesDiscount($event, product)"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            v-zerohandling
                                            :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                        >
                                        <span>%</span>
                                    </div>
                                </div>
                            </td>
                            <td v-if="(discountType === 3 || discountType === 4)  && (groupId != 10 && groupId != 11)">
                                <div class="col-buyer">
                                    <div class="text-part">
                                        <span class="text">
                                            {{
                                                product.amount * product.amountUnit.multiplier * productPrice(product, 'buyer', !showNetPrice) | formatPrice(false)
                                            }}
                                            <span>Ft</span>
                                        </span>
                                        <span class="text-secondary">
                                            {{ productUnitPrice(product, 'buyer', !showNetPrice) | formatPrice(false) }}
                                            Ft/{{ product.amountUnit.name }}
                                        </span>
                                    </div>

                                    <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                        <div class="sub-discounts-part"
                                             v-for="subDiscount in product.listPrice.subDiscounts"
                                             :key="subDiscount.id">
                                            <label :for="`product_buyer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                    subDiscount.name
                                                }}</label>
                                            <div class="input-part">
                                                <input
                                                    :id="`product_buyer_sub_discount_${product.id}_${subDiscount.id}`"
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.buyerDiscount[subDiscount.id]"
                                                    @input="updateBuyerDiscount($event, product, subDiscount.id)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="input-part">
                                        <input
                                            type="number"
                                            class="small"
                                            step='0.01'
                                            min="0"
                                            max="100"
                                            :value="product.buyerDiscount"
                                            @input="updateBuyerDiscount($event, product)"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            v-zerohandling
                                            :disabled="discountType === 3 || (viewOnly && !canEditSales) || product.isDeleted"
                                        >
                                        <span>%</span>
                                    </div>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="(discountType === 3 || discountType === 4) && !isWorkerOffer  && (groupId != 10 && groupId != 11)">
                                <div class="col-dealer">
                                    <div class="text-part">
                                        <span class="text">
                                            {{
                                                product.amount * product.amountUnit.multiplier * productPrice(product, 'dealer', !showNetPrice) | formatPrice(false)
                                            }}
                                            <span>Ft</span>
                                        </span>
                                        <span class="text-secondary">
                                            {{ productUnitPrice(product, 'dealer', !showNetPrice) | formatPrice(false) }}
                                            Ft/{{ product.amountUnit.name }}
                                        </span>
                                    </div>
                                    <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                        <div class="sub-discounts-part"
                                             v-for="subDiscount in product.listPrice.subDiscounts"
                                             :key="subDiscount.id">
                                            <label :for="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                    subDiscount.name
                                                }}</label>
                                            <div class="input-part">
                                                <input
                                                    :id="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`"
                                                    v-if="product.dealerDiscount"
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.dealerDiscount[subDiscount.id]"
                                                    @input="updateDealerDiscount($event, product, subDiscount.id)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || viewOnly || isWorkerOffer || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="input-part">
                                        <input
                                            type="number"
                                            class="small"
                                            step='0.01'
                                            min="0"
                                            max="100"
                                            :value="product.dealerDiscount"
                                            @input="updateDealerDiscount($event, product)"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            v-zerohandling
                                            :disabled="discountType === 3 || viewOnly || isWorkerOffer || product.isDeleted"
                                        >
                                        <span>%</span>
                                    </div>
                                </div>
                            </td>
                            <td v-else>
                                -
                            </td>
                            <td v-if="product.warnings">
                                <button>
                                    <md-icon>history</md-icon>
                                </button>

                                <button>
                                    <md-icon>highlight_off</md-icon>
                                </button>
                            </td>
                            <td v-else>
                                -
                            </td>
                        </tr>
                        <tr v-if="editableGroups.includes(parseInt(groupId))">

                            <td v-if="groupId == 10">
                                <input v-model="newProducts[index].name" class="small" type="text" placeholder="Új termék neve">
                            </td>
                            <td v-else>
                                <input v-model="newProducts[index].name" class="small" type="text" placeholder="Új munkanem">
                            </td>


                            <td></td>

                            <td>
                                <div class="col-amount">
                                    <div>
                                        <input v-model="newProducts[index].amount" class="small" type="number" placeholder="">
                                        <custom-select
                                            :options="newProducts[index].units"
                                            :selectedItem="newProducts[index].amountUnit"
                                            @changed="changeUnit($event, newProducts[index])"
                                            :disabled="viewOnly || !newProducts[index].editable || newProducts[index].isDeleted"
                                            class="unit-selector">
                                        </custom-select>
                                    </div>
                                    <div class="amount-info" v-if="newProducts[index].amountUnit.info">
                                        {{ newProducts[index].amountUnit.info }}
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div class="col-amount">
                                    <div>
                                        <input v-model="newProducts[index].listPrice.net" class="small" type="number">
                                        <div class="col-price" style="margin-left:auto">
                                            <span class="text">
                                                {{
                                                    newProducts[index].amount * newProducts[index].amountUnit.multiplier * newProducts[index].listPrice.net | formatPrice(false)
                                                }}
                                                <span>Ft</span>
                                            </span>
                                            <span class="text-secondary">
                                                {{ productUnitPrice(newProducts[index], 'list') | formatPrice(false) }}
                                                Ft/{{ newProducts[index].amountUnit.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-if="editableGroups.includes(parseInt(groupId))">
                            <td colspan="6">
                                <button
                                    :disabled="(newProducts[index].amount <= 0 || newProducts[index].name == null || newProducts[index].listPrice.net == null)"
                                    @click="addNewProduct(index, category.id)"
                                    style="background:#009fe3;color:white"
                                    class="btn button-save">
                                    + hozzáadás
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <scroll-to-top></scroll-to-top>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <panel-total
            :loading="loading"
            :data="totalData"
            :discountType="discountType"
            :isOffer="true"
            :isWorkerOffer="isWorkerOffer"
            @exportData="exportToPdf"
            @nextStep="checkDiscountType"
        ></panel-total>
        <discount-navigation :canGiveDiscounts="groupId != 10 && groupId != 11"></discount-navigation>
        <discount-bar :loading="loading" :isWorkerOffer="isWorkerOffer"></discount-bar>
        <services-bar :loading="loading" :isWorkerOffer="isWorkerOffer" :showNetPrice="showNetPrice"></services-bar>
        <comments-bar :loading="loading" :isWorkerOffer="isWorkerOffer"></comments-bar>
        <tags-bar :loading="loading"></tags-bar>
        <modal-calculation :viewOnly="viewOnly"></modal-calculation>
        <modal-consignment :details="orderData.calculation"></modal-consignment>
        <modal-export
            :offer-id="ids.offerId"
            :open="openExportModal"
            :discountType="discountType"
            :exportNetPrice="showNetPrice"
            :isWorkerOffer="isWorkerOffer"
            @close="openExportModal = false"
        ></modal-export>
        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="saveOffer(true)"
            @close="openConfirmationModal = false"
            @secondaryAction="exitOffer"
        ></modal-confirmation>
        <modal-confirmation
            v-if="validToConfirmationModalData !== null"
            :open="openValidToConfirmationModal"
            :data="validToConfirmationModalData"
            @accepted="validToConfirmationModalCallback(true)"
            @close="openValidToConfirmationModal = false"
            @secondaryAction="validToConfirmationModalCallback(false)"
        ></modal-confirmation>
        <modal-confirmation
            v-if="saveNewConfirmationModalData !== null"
            :open="openSaveNewConfirmationModal"
            :data="saveNewConfirmationModalData"
            @accepted="saveNewConfirmationModalCallback(true)"
            @close="openSaveNewConfirmationModal = false"
            @secondaryAction="saveNewConfirmationModalCallback(false)"
        ></modal-confirmation>
        <modal-confirm-discount
            :open="openConfirmDiscount"
            @accepted="acceptedOffer"
            @close="openConfirmDiscount = false"
        ></modal-confirm-discount>
        <modal-guarantee
            :open="showGuaranteeAlert"
            :modal-text="guaranteeModalText"
            @close="showGuaranteeAlert = false" />
        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

import JobNavigation from '../components/navigations/JobNavigation.vue'
import DiscountNavigation from '../components/navigations/DiscountNavigation.vue'
import CustomSelect from '../components/dropdowns/CustomSelect.vue'
import CustomGroupSelect from '../components/dropdowns/CustomGroupSelect.vue'
import PanelTotal from '../components/PanelTotal.vue'
import DiscountBar from '../components/sidebars/DiscountBar.vue'
import ServicesBar from '../components/sidebars/ServicesBar.vue'
import CommentsBar from '../components/sidebars/CommentsBar.vue'
import TagsBar from '../components/sidebars/TagsBar.vue'
import ModalGuarantee from '../components/modals/ModalGuarantee.vue'
import Notification from '../components/Notification.vue'
import ModalCalculation from '../components/modals/ModalCalculation.vue'
import ModalConsignment from '../components/modals/ModalConsignment.vue'
import ModalExport from '../components/modals/ModalExport.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ModalConfirmDiscount from '../components/modals/ModalConfirmDiscount.vue'
import ScrollToTop from '../components/ScrollToTop.vue'
import VTooltip from 'v-tooltip'

import {getTotalPrices, getProductPrice, hShowColumn, round} from '@/plugins/helpers'
import {ZeroHandling} from '@/plugins/directives'
import moment from '../plugins/moment'

import Decimal from 'decimal.js'

Vue.use(VTooltip);

const newProductUnits = [
    {
        id: 1,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "db",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 2,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "fm",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 3,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "kg",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 4,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "m²",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 5,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "tábla",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 6,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "tekercs",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 7,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "tubus",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 8,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "raklap",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 9,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "csomag",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 10,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "kanna",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 11,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "m",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 12,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "zsák",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 13,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "m³",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 14,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "liter",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 15,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "doboz",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 16,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "köteg",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 17,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "bála",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 18,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "tétel",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 19,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "nap",
        roundAbove: 0,
        step: 0.01
    },
    {
        id: 20,
        info: null,
        isSquareMeter: false,
        multiplier: 1,
        name: "hét",
        roundAbove: 0,
        step: 0.01
    }
];

export default {
    name: 'OfferCategory',

    data() {
        return {
            offerName: null,
            newProductsCounter: 1,
            search: '',
            results: [],
            showGuaranteeAlert: false,
            guaranteeModalText: null,

            newProducts: [
                {
                    id: 'custom_1',
                    productId: 9999 + 18,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 624,
                    units: newProductUnits
                },
                {
                    id: 'custom_2',
                    productId: 9999 + 19,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 625,
                    units: newProductUnits
                },
                {
                    id: 'custom_3',
                    productId: 9999 + 20,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 625,
                    units: newProductUnits
                },
                {
                    id: 'custom_4',
                    productId: 9999 + 21,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 625,
                    units: newProductUnits
                },
                {
                    id: 'custom_5',
                    productId: 9999 + 22,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 625,
                    units: newProductUnits
                }
                ,
                {
                    id: 'custom_6',
                    productId: 9999 + 23,
                    category_id: null,
                    amount: null,
                    buyerDiscount: 0,
                    dealerDiscount: 0,
                    salesDiscount: 0,
                    editable: true,
                    fixOpen: false,
                    forceShow: false,
                    amountUnit: {
                        id: 1,
                        info: null,
                        isSquareMeter: false,
                        multiplier: 1,
                        name: 'db',
                        roundAbove: 0,
                        step: 0.01,
                    },
                    info: null,
                    isDeleted: false,
                    isSelectable: null,
                    isSelected: null,
                    linked: false,
                    linkedProducts: null,
                    listPrice: {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    },
                    pallet: null,
                    mainColor: null,
                    nameWithProductNumber: 'test',
                    subText: 'Egyedileg hozzáadott termék / szolgáltatás',
                    type: 625,
                    units: newProductUnits
                }
            ],
            categoriesWithEditablePrice: [null, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634, 635, 636, 637, 638, 639, 640, 641, 642, 643, 644, 645, 646, 647, 648, 649, 650, 651, 652, 653, 654, 655, 656, 657],
            editableGroups: [10, 11],
            loading: true,
            offerProducts: {},
            isNew: false,
            showProductNumbers: false,
            showOnlyAdded: false,
            forceShowWhenOnlyAdded: [],
            showNetPrice: true,
            showInputs: true,
            openExportModal: false,
            openConfirmationModal: false,
            openSaveNewConfirmationModal: false,
            openValidToConfirmationModal: false,
            validToConfirmationModalCallback: null,
            saveNewConfirmationModalCallback: null,
            categoryMap: {},
            openedCategories: [],
            confirmationModalData: {
                acceptText: this.$t('offer.page.edit.confirmation.save.ok'),
                cancelText: this.$t('offer.page.edit.confirmation.save.cancel'),
                secondaryActionText: this.$t('offer.page.edit.confirmation.save.secondary'),
                message: this.$t('offer.page.edit.confirmation.save.question')
            },
            openConfirmDiscount: false,
            categoryDepthPosition: null,
        }
    },

    directives: {ZeroHandling},

    watch: {
        '$route'() {
            this.fetchOfferData()
            if(this.groupId == 10 || this.groupId == 11){
                this.newProducts.forEach(prod => {
                    prod.name = '';
                    prod.category_id = null;
                    prod.listPrice = {
                        discounts: [12],
                        gross: null,
                        net: null,
                        unitName: 'db',
                        subDiscounts: []
                    };
                    prod.amount = null;
                });
            }
        },

        displayNetPrice: {
            handler: function (val) {
                this.showNetPrice = val
            }
        },

        showOnlyAdded: {
            handler: function (val) {
                if (val) {
                    this.openedCategories = this.calculateOpenedCategories()
                    this.forceShowWhenOnlyAdded = []
                }
            }
        },
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.showOnlyAdded = false
            this.fetchOfferData()
            this.$store.dispatch('FETCH_OFFER_PRODUCT_GROUPS')
        }).catch((redirect) => {
            redirect && this.$router.push('/')
        })
    },

    methods: {
        searchInProducts(){
            if(!this.search && this.showOnlyAdded){
                this.showOnlyAdded = false;
                setTimeout(() => {
                    this.showOnlyAdded = true;
                }, 50);
            }
            this.results = [];

            this.categories.forEach((category) => {
                const filteredProducts = category.products.filter((product) => {
                    product.category = category;
                    if(product.amount > 0){
                        return product;
                    }
                    return product.nameWithProductNumber.toLowerCase().includes(this.search.toLowerCase());
                });

                this.results = [...this.results, ...filteredProducts];
            });
        },

        addNewProduct(index, category_id) {
            this.newProducts[index].category_id = category_id;

            let tempProduct = Object.assign({}, this.newProducts[index]);

            this.orderData.categories[index].products.push(tempProduct);

            this.newProducts[index].id = this.newProducts[index].id + this.newProductsCounter++;
            this.newProducts[index].productId = this.newProducts[index].productId + this.newProductsCounter++;
            this.newProducts[index].name = '';
            this.newProducts[index].category_id = null;
            this.newProducts[index].listPrice = {
                discounts: [12],
                gross: null,
                net: null,
                unitName: 'db',
                subDiscounts: []
            };
            this.newProducts[index].amount = null;
        },

        closePage() {
            if ((this.viewOnly && !this.canEditSales) || this.loading) {
                this.$router.push({name: 'OfferList'})
            }

            this.openConfirmationModal = true
        },

        updateGroupTypeSelect(el) {
            let newData = {
                element: el,
                handle: 'groupType',
                jobType: 'offer',
                groupId: this.offerInformation.groupId,
                offerId: this.ids.offerId
            }
            this.$store.dispatch('UPDATE_CATEGORY_LIST', newData)
        },

        changeSelectableProduct(type, el) {
            const payload = {
                type,
                id: el.id
            }

            this.$store.commit('updateSelectableProductSelected', payload)
        },

        updateVariant(el) {
            let newData = {
                element: el,
                handle: 'variant',
                jobType: 'offer',
                groupId: this.offerInformation.groupId,
                offerId: this.ids.offerId
            }
            this.$store.dispatch('UPDATE_CATEGORY_LIST', newData)
        },

        updateDifferentColors(e) {
            this.$store.commit('updateDifferentColors', e.target.checked)
        },

        isSecondaryColors(type) {
            return this.orderData.activeProductTypes.includes(type)
        },

        hasAddedProducts(category) {
            let hasAdded

            hasAdded = category.products.some(element => {
                return element.amount > 0 || this.forceShowWhenOnlyAdded.includes(element.id)
            })

            if (!hasAdded) {
                hasAdded = category.allChildren.some(id => {
                    if (this.categoryMap[id] && this.categoryMap[id].products.length > 0) {
                        return this.categoryMap[id].products.some(element => {
                            return element.amount > 0 || this.forceShowWhenOnlyAdded.includes(element.id)
                        })
                    }
                    return false
                })
            }

            return hasAdded
        },

        toggleChildren(category) {
            if (!this.orderData.multiLevel) {
                return true
            }

            if (this.openedCategories.includes(category.id)) {
                this.openedCategories = this.openedCategories.filter(id => id !== category.id && !category.allChildren.includes(id))
            } else {
                this.openedCategories.push(category.id)
            }
        },

        goToOriginalOffer() {
            this.$router.push({name: 'OfferCategory', params: {offerId: this.offerInformation.originalOfferId}})
        },

        saveOffer(exit, updateValidTo = null, saveNew = null) {
            console.log('saving offer...');
            this.loading = true;
            let forceCopy = null

            this.openValidToConfirmationModal = false
            this.openSaveNewConfirmationModal = false
            this.openConfirmationModal = false

            if (this.validToConfirmationModalData !== null) {
                if (updateValidTo === null) {
                    this.validToConfirmationModalCallback = (choice) => {
                        this.saveOffer(exit, choice, saveNew)
                    }
                    this.openValidToConfirmationModal = true
                    return
                }

                if (updateValidTo) {
                    this.$store.commit('setOfferInformationValidTo', moment().add(this.offerInformation.validToDays, 'days').format('YYYY-MM-DD'))
                    forceCopy = true
                }
            }

            if (this.saveNewConfirmationModalData !== null) {
                if (saveNew === null) {
                    this.saveNewConfirmationModalCallback = (choice) => {
                        this.saveOffer(exit, updateValidTo, choice)
                    }
                    this.openSaveNewConfirmationModal = true
                    return
                }

                forceCopy = saveNew
            }

            this.$store.dispatch('SEND_OFFER', {...this.ids, forceCopy}).then(() => {
                this.offerName = this.offerInformation.name
                this.loading = false;
                if (exit) {
                    this.$router.push({name: 'OfferList'})
                }
            }).then(() => {
                console.log('hello');
                this.checkGuarantee();
            })
        },

        saveOfferAndGoToOrder(discountType) {
            if (this.viewOnly) {
                this.$router.push({
                    name: 'OrderDetailsFromOffer',
                    params: {offerId: this.ids.offerId, discountType: discountType > 0 ? discountType : void 0}
                })
            } else {
                this.$store.dispatch('SEND_OFFER', this.ids).then(() => {
                    this.offerName = this.offerInformation.name
                    this.$router.push({
                        name: 'OrderDetailsFromOffer',
                        params: {offerId: this.ids.offerId, discountType: discountType > 0 ? discountType : void 0}
                    })
                })
            }
        },

        checkGuarantee(){
            this.$store.dispatch('CHECK_OFFER_FOR_GUARANTEES', {offerId : this.ids.offerId}).then((data) => {
                if(data){
                    if(data.hasSpecificItems && data.showGuaranteeAlert){
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }

                    if(data.hasSpecificItems == false && data.showGuaranteeAlert){
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }
                }
            })
        },

        exitOffer() {
            this.$router.push({name: 'OfferList'})
        },

        fetchOfferData() {
            this.loading = true

            this.$store.dispatch('FETCH_OFFER_DATA', this.ids).then(() => {
                this.isNew = this.offerInformation.isNew;
                this.offerName = this.offerInformation.name;
                this.offerProducts = this.offerInformation.products;
                if (this.orderData.multiLevel === true) {
                    this.categoryMap = this.categories.reduce((map, c) => {
                        map[c.id] = c
                        return map
                    }, {})
                    this.openedCategories = this.calculateOpenedCategories()
                }
                this.loading = false
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('offer.page.edit.notification.load_failed')])
                this.$router.push({name: 'OfferList'})
            })
        },

        exportToPdf(updateValidTo = null, saveNew = null) {
            this.$store.commit('closeAllSettingsSidebars')

            if (this.viewOnly && !this.canEditSales) {
                this.openExportModal = true
                return true
            }

            let forceCopy = null

            this.openValidToConfirmationModal = false
            this.openSaveNewConfirmationModal = false
            this.openConfirmationModal = false

            if (this.validToConfirmationModalData !== null) {
                if (updateValidTo === null) {
                    this.validToConfirmationModalCallback = (choice) => {
                        this.exportToPdf(choice, saveNew)

                    }
                    this.openValidToConfirmationModal = true
                    return
                }

                if (updateValidTo) {
                    this.$store.commit('setOfferInformationValidTo', moment().add(this.offerInformation.validToDays, 'days').format('YYYY-MM-DD'))
                    forceCopy = true
                }
            }

            if (this.saveNewConfirmationModalData !== null) {
                if (saveNew === null) {
                    this.saveNewConfirmationModalCallback = (choice) => {
                        this.exportToPdf(updateValidTo, choice)
                    }
                    this.openSaveNewConfirmationModal = true
                    return
                }

                forceCopy = saveNew
            }

            this.$store.dispatch('SEND_OFFER', {...this.ids, forceCopy}).then(() => {
                this.offerName = this.offerInformation.name
                this.openExportModal = true
            }).then(() => {
                this.checkGuarantee();
            })
        },

        checkDiscountType() {
            if (this.discountType === 3 || this.discountType === 4) {
                this.openConfirmDiscount = true
            } else {
                this.saveOfferAndGoToOrder(0)
            }
        },

        acceptedOffer(discountType) {
            this.saveOfferAndGoToOrder(discountType)
        },

        goToOrder() {
            this.$store.commit('closeAllSettingsSidebars')
        },

        openCalculation() {
            if (!this.planCalculation) {
                this.$store.dispatch('FETCH_CALCULATION').then(() => {
                    this.$store.commit('openModal', 'planCalculation')
                })
            } else {
                this.$store.commit('openModal', 'planCalculation')
            }
        },

        openConsignment() {
            this.$store.commit('openModal', 'consignment')
        },

        // Table methods
        showColumn(property, propertyList, hideProperties, isSwitchable) {
            return hShowColumn(property, propertyList, hideProperties, isSwitchable)
        },

        addSecondaryColor(type) {
            let newData = {
                type: Number(type),
                add: true
            }
            this.$store.commit('updateActiveProductTypes', newData)
        },

        removeSecondaryColor(type) {
            let newData = {
                type: Number(type),
                add: false
            }
            this.$store.commit('updateActiveProductTypes', newData)
            this.$store.commit('clearSecondaryProductValueByType', newData.type)
        },

        getRowClasses(row) {
            let rowClassList = ''
            rowClassList += this.forceShowWhenOnlyAdded.includes(row.id) ? 'force-show ' : ''
            rowClassList += row.amount < 1 ? 'inactive ' : ''
            rowClassList += (row.mainColor === true && (this.isSecondaryColors(row.type) || row.fixOpen)) ? 'is-main-color-open ' : ''
            rowClassList += (row.mainColor === false) ? 'is-secondary-color ' : ''
            rowClassList += (row.recommended || this.recommendedProductId === row.id) ? 'is-recommended ' : ''
            rowClassList += (row.mainColor === false && !this.isSecondaryColors(row.type) && !row.forceShow) || (this.showOnlyAdded && row.amount === 0) ? 'is-hidden ' : ''

            return rowClassList
        },

        getCategoryClasses(category) {
            let categoryClassList = `depth-${category.depth} `
            let hasProducts = this.orderData.multiLevel && category.products.length > 0
            let hasCollapseTable = this.orderData.multiLevel && category.depth < 3 && category.products.length > 0
            let opened = this.orderData.multiLevel && this.openedCategories.includes(category.id)
            let parentOpened = this.orderData.multiLevel && this.openedCategories.includes(category.parent)

            categoryClassList += this.orderData.multiLevel && category.products.length < 1 ? 'no-products ' : ''
            categoryClassList += hasCollapseTable ? 'has-collapse-table ' : ''
            categoryClassList += hasProducts > 0 ? 'has-products ' : ''
            categoryClassList += category.products.filter(p => p.listPrice.subDiscounts.length > 0).length ? 'has-sub-discounts ' : ''

            categoryClassList += (opened || parentOpened) && category.depth > 0 ? 'open ' : ''
            categoryClassList += opened && !hasProducts ? 'children-open ' : ''
            categoryClassList += opened && hasCollapseTable ? 'open-table ' : ''

            categoryClassList += this.showOnlyAdded && !this.hasAddedProducts(category) ? 'is-hidden ' : ''

            return categoryClassList
        },

        calculateOpenedCategories() {
            let productInputs = document.querySelectorAll('.product-list .has-products .col-amount input[data-id]')

            let openedCategories = {}

            productInputs.forEach(e => {
                let productId
                if (e.value > 0) {
                    productId = Number(e.dataset.id)
                    this.categories.forEach(category => {
                        if (category.products.some(p => p.id === productId)) {
                            openedCategories[category.id] = 1
                            category.parents.forEach(id => {
                                openedCategories[id] = 1
                            })
                        }
                    })
                }
            })

            return Object.keys(openedCategories).map(id => Number(id))
        },

        getStep(product) {
            if (product.amount > product.amountUnit.roundAbove) {
                return product.amountUnit.step
            }

            return null
        },

        updateAmount(value, row, onBlur) {
            let newValue = Number(value)

            if (onBlur && newValue > row.amountUnit.roundAbove) {
                // newValue = Math.ceil(newValue / row.amountUnit.step) * row.amountUnit.step
                newValue = parseFloat((new Decimal(newValue)).dividedBy(row.amountUnit.step).ceil().mul(row.amountUnit.step).toFixed())
            }

            let newData = {
                id: row.id,
                unit: row.amountUnit,
                value: newValue
            }

            if (newValue === 0 && this.showOnlyAdded) {
                this.forceShowWhenOnlyAdded.push(row.id)
            }

            this.$store.commit('updateProductAmount', newData)

            if (row.linkedProducts) {
                for (let productId in row.linkedProducts) {
                    if (row.linkedProducts.hasOwnProperty(productId)) {
                        let linkedProduct = null

                        this.categories.every(category => {
                            category.products.every(product => {
                                if (product.id === Number(productId)) {
                                    linkedProduct = product
                                    return false
                                }
                                return true
                            })
                            return !linkedProduct
                        })

                        if (linkedProduct) {
                            this.updateAmount(newValue * row.amountUnit.multiplier * row.linkedProducts[productId], linkedProduct, onBlur)
                        }
                    }
                }
            }
        },

        changeUnit(e, prod) {
            prod.amountUnit = e

            this.updateAmount(prod.amount, prod, true)
        },

        updateCustomListPrice(e, row) {
            let newData = {
                id: row.id,
                value: e.target.value
            }

            this.$store.commit('updateCustomListPrice', newData)
        },

        updateSalesDiscount(e, row, subDiscountId = null) {
            let newValue = e.target.value

            if (newValue < 0) {
                newValue = 0
            }

            if (newValue > 100) {
                newValue = 100
            }

            let newData = {
                id: row.id,
                subDiscountId,
                value: newValue
            }

            this.$store.commit('updateProductSalesDiscount', newData)
        },

        updateBuyerDiscount(e, row, subDiscountId = null) {
            let newValue = e.target.value

            if (newValue < 0) {
                newValue = 0
            }

            if (newValue > 100) {
                newValue = 100
            }

            let newData = {
                id: row.id,
                subDiscountId,
                value: newValue
            }

            this.$store.commit('updateProductBuyerDiscount', newData)
        },

        updateDealerDiscount(e, row, subDiscountId = null) {
            let newValue = e.target.value

            if (newValue < 0) {
                newValue = 0
            }

            if (newValue > 100) {
                newValue = 100
            }

            let newData = {
                id: row.id,
                subDiscountId,
                value: newValue
            }

            this.$store.commit('updateProductDealerDiscount', newData)
        },

        setLineBreak(str) {
            if (str === null) {
                return null
            }

            return String(str).replace(/(\S+\s*){1,7}/g, '$&\n')
        },

        productPrice(product, type, gross) {
            return getProductPrice(product, type, gross)
        },

        productUnitPrice(product, type, gross) {
            return getProductPrice(product, type, gross) * product.amountUnit.multiplier
        },

        round(value, decimals) {
            return round(value, decimals)
        }
    },

    computed: {
        ...mapGetters(['orderData', 'mainStates', 'planCalculation', 'offerInformation', 'originalOfferInformation', 'originalOfferVariantId', 'originalOfferVariantFullName', 'discountType', 'transportPrices', 'selectedTransport', 'transports', 'totalServicePrice', 'pallets', 'displayNetPrice', 'customTransport', 'dealerTransport', 'customDealerTransport', 'viewOnly', 'canEditSales', 'userAuthenticated', 'recommendedProductId']),

        groupId(){
            return this.ids.groupId ?? this.offerInformation.groupId;
        },

        validToConfirmationModalData() {
            if (!this.offerInformation.validTo || !this.offerInformation.id) {
                return null
            }

            if (this.originalOfferInformation.validTo === this.offerInformation.validTo && moment().isAfter(this.offerInformation.validTo, 'day')) {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.valid_to.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.valid_to.cancel'),
                    secondaryActionText: this.$t('offer.page.edit.confirmation.valid_to.secondary'),
                    message: this.$t('offer.page.edit.confirmation.valid_to.question', {
                        date: moment(this.offerInformation.validTo).format(String(this.$t('offer.page.edit.confirmation.valid_to.date_format')))
                    })
                }
            }

            return null
        },

        saveNewConfirmationModalData() {
            if (!this.offerInformation.saveNewAvailable || !this.orderData.selectedVariant || !this.orderData.selectedVariant.id || !this.ids.offerId) {
                return null
            }

            if (this.orderData.selectedVariant.id !== this.originalOfferVariantId) {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.save_new.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.save_new.cancel'),
                    secondaryActionText: this.$t('offer.page.edit.confirmation.save_new.secondary'),
                    message: this.$t('offer.page.edit.confirmation.save_new.question', {
                        original: this.originalOfferVariantFullName,
                        new: this.orderData.selectedVariant.fullName,
                    })
                }
            }

            return null
        },

        categories() {
            let addSelectable = (categoryProducts, options, products) => {
                let selected = null

                options.some(({id}) => {
                    return categoryProducts.some((product) => {
                        if (product.id === id && product.isSelected) {
                            selected = product
                            return true
                        }
                    })
                })

                if(selected) {
                    products.push({
                        selectable: true,
                        subName: selected.subText,
                        ...selected,
                        options,
                    })
                }
            }

            return this.orderData.categories.map(category => {
                let products = []
                let options = []
                let productTypeId
                let selected

                category.products = category.products.filter(product => {
                    if(product.isDeleted && this.isNew){
                        return false;
                    }

                    return product;
                })

                category.products.map(product => {
                    if (product.isSelectable) {
                        if (productTypeId !== product.type) {
                            if (options.length > 0) {
                                addSelectable(category.products, options, products)
                            }
                            options = []
                            productTypeId = product.type
                            selected = null
                        }
                        options.push({
                            id: product.id,
                            name: this.showProductNumbers ? product.nameWithProductNumber : product.name,
                            subName: product.subText
                        })
                    } else {
                        if (options.length > 0) {
                            addSelectable(category.products, options, products)
                        }
                        options = []
                        productTypeId = product.type
                        selected = null
                        products.push(product)
                    }
                })

                if (options.length > 0) {
                    addSelectable(category.products, options, products)
                }

                return {...category, products}
            })
        },

        ids() {
            return {
                projectId: this.$route.params.projectId,
                groupId: this.$route.params.groupId,
                relatedOfferId: this.$route.params.relatedOfferId,
                offerId: this.$route.params.offerId
            }
        },

        totalData() {
            let productPrices = getTotalPrices(this.orderData.categories, this.discountType)
            let servicePrice = this.totalServicePrice
            let dealerTransport = 0
            let salesTransport = 0

            if (this.selectedTransport !== null && this.transports) {
                this.transports.forEach(el => {
                    if (el.id === this.selectedTransport && el.withServices === false) { // Clear service price when spec transport
                        servicePrice = 0
                    }
                })
            }

            if (this.selectedTransport !== null && this.transportPrices.list.length > 0) {
                let transportIndex = null

                this.transportPrices.list.forEach((el, i) => {
                    if (el.id === this.selectedTransport) {
                        transportIndex = i
                    }
                })

                if (transportIndex !== null && transportIndex > -1) {
                    if (this.customDealerTransport) {
                        dealerTransport = this.dealerTransport
                        salesTransport = this.dealerTransport
                    } else {
                        dealerTransport = this.transportPrices.buyer[transportIndex].amount + servicePrice
                        salesTransport = this.transportPrices.sales[transportIndex].amount + servicePrice
                    }

                    return {
                        listPriceNet: productPrices.listPriceNet + this.transportPrices.list[transportIndex].amount + servicePrice + this.customTransport,
                        salesPriceNet: productPrices.salesPriceNet + salesTransport,
                        buyerPriceNet: productPrices.buyerPriceNet + dealerTransport,
                        dealerPriceNet: productPrices.dealerPriceNet + this.transportPrices.dealer[transportIndex].amount + servicePrice + this.customTransport,
                        listPriceGross: productPrices.listPriceGross + (this.transportPrices.list[transportIndex].amount + servicePrice + this.customTransport) * 1.27,
                        salesPriceGross: productPrices.salesPriceGross + salesTransport * 1.27,
                        buyerPriceGross: productPrices.buyerPriceGross + dealerTransport * 1.27,
                        dealerPriceGross: productPrices.dealerPriceGross + (this.transportPrices.dealer[transportIndex].amount + servicePrice + this.customTransport) * 1.27
                    }
                }
            }

            if (this.customDealerTransport) {
                dealerTransport = this.dealerTransport
                salesTransport = this.dealerTransport
            } else {
                dealerTransport = servicePrice
                salesTransport = servicePrice
            }

            return {
                listPriceNet: productPrices.listPriceNet + servicePrice + this.customTransport,
                salesPriceNet: productPrices.salesPriceNet + salesTransport,
                buyerPriceNet: productPrices.buyerPriceNet + dealerTransport,
                dealerPriceNet: productPrices.dealerPriceNet + servicePrice + this.customTransport,
                listPriceGross: productPrices.listPriceGross + (servicePrice + this.customTransport) * 1.27,
                salesPriceGross: productPrices.salesPriceGross + salesTransport * 1.27,
                buyerPriceGross: productPrices.buyerPriceGross + dealerTransport * 1.27,
                dealerPriceGross: productPrices.dealerPriceGross + (servicePrice + this.customTransport) * 1.27
            }
        },

        isWorkerOffer() {
            return this.offerInformation.workerOffer
        },

        salesDiscountColumn() {
            return this.$t('offer.page.edit.category.column.sales_price', {
                discount: `<span style="white-space: nowrap">${this.$t('offer.page.edit.category.column.sales_price_discount')}</span>`
            })
        },

        buyerDiscountColumn() {
            if (this.isWorkerOffer) {
                return this.$t('offer.page.edit.category.column.purchase_price', {
                    discount: `<span style="white-space: nowrap">${this.$t('offer.page.edit.category.column.purchase_price_discount')}</span>`
                })
            } else {
                return this.$t('offer.page.edit.category.column.buyer_price', {
                    discount: `<span style="white-space: nowrap">${this.$t('offer.page.edit.category.column.buyer_price_discount')}</span>`
                })
            }
        },

        dealerDiscountColumn() {
            return this.$t('offer.page.edit.category.column.dealer_price', {
                discount: `<span style="white-space: nowrap">${this.$t('offer.page.edit.category.column.dealer_price_discount')}</span>`
            })
        }
    },

    components: {
        JobNavigation,
        DiscountNavigation,
        CustomSelect,
        CustomGroupSelect,
        PanelTotal,
        DiscountBar,
        ServicesBar,
        CommentsBar,
        TagsBar,
        Notification,
        ModalCalculation,
        ModalConsignment,
        ModalExport,
        ModalConfirmation,
        ModalConfirmDiscount,
        ScrollToTop,
        ModalGuarantee
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
.custom-col{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}
.table-products {
    .col-amount {
        .unit-selector {
            li {
                padding: 7px 10px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/ui/table-products.scss';
@import '../assets/sass/layout/product-list.scss';

.product-list-settings {
    min-height: 164px;
}

.deleted-product{
    background:red;
    color:white;
    padding: 2px 4px;
    border-radius:3px
}

</style>
