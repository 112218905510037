import i18n from '../i18n'
import './custom_list.css'

export default class CustomList {
    static get enableLineBreaks() {
        return true;
    }

    static get sanitize() {
        return {
            br: true,
            b: {},
            i: {},
            u: {},
            sub: {},
            sup: {}
        };
    }

    static get toolbox() {
        return {
            icon: '<svg width="17" height="13" viewBox="0 0 17 13" xmlns="http://www.w3.org/2000/svg"> <path d="M5.625 4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0-4.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm0 9.85h9.25a1.125 1.125 0 0 1 0 2.25h-9.25a1.125 1.125 0 0 1 0-2.25zm-4.5-5a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0-4.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25zm0 9.85a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z"/></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.custom_list')
        };
    }

    constructor({ data, api }) {
        this._elements = {
            wrapper: null,
            div: null,
            li: null,
            ul: null,
        };

        this.api = api;

        this._data = {
            focus: false,
            text: '',
            items: [],
        };

        this.data = data;
    }

    get CSS() {
        return {
            baseBlock: this.api.styles.block,
            wrapper: 'ejs-custom_list',
            item: 'ejs-custom_list__item',
        };
    }

    get DEFAULT() {
        return {
            text: i18n.t('offer.page.edit.text.editor_sample.tool.custom_list.text', {
                title: i18n.t('offer.page.edit.text.editor_sample.tool.custom_list.title'),
                title_bold: `<b>${i18n.t('offer.page.edit.text.editor_sample.tool.custom_list.title')}</b>`
            }),
            items: [i18n.t('offer.page.edit.text.editor_sample.tool.custom_list.property')]
        };
    }

    set data(listData) {
        if (!listData) {
            listData = {};
        }

        this._data.focus = listData.focus || undefined;
        this._data.text = listData.text || this.DEFAULT.text;
        this._data.items = listData.items || this.DEFAULT.items;

        const oldView = this._elements.wrapper;

        if (oldView) {
            oldView.parentNode.replaceChild(this.render(), oldView);
        }
    }

    get data() {
        let data = {}

        data.text = this._elements.div.innerHTML;
        data.items = [];

        const items = this._elements.ul.children;

        for (let i = 0; i < items.length; i++) {
            data.items.push(items[i].innerHTML.replace(/(\s*<br>\s*)+$/g, ''));
        }

        return data;
    }

    save() {
        return this.data;
    }

    rendered() {
        if(this._data.focus) {
            this._elements.div.focus()
            this._data.focus = false
        }
    }

    render() {
        this._elements.wrapper = this._make('div', [this.CSS.baseBlock, this.CSS.wrapper]);

        this._elements.div = this._make('div', null, {
            contentEditable: true,
            innerHTML: this._data.text
        })
        this._elements.ul = this._make('ul', [this.CSS.baseBlock], {
            contentEditable: true,
        });

        this._elements.wrapper.appendChild(this._elements.div);
        this._elements.wrapper.appendChild(this._elements.ul);

        this.init()

        return this._elements.wrapper;
    }

    init() {
        // fill with data
        if (this._data.items.length) {
            this._data.items.forEach((item) => {
                this._elements.ul.appendChild(this._make('li', this.CSS.item, {
                    innerHTML: item,
                }));
            });
        } else {
            this.disableUlIfEmpty()
        }

        // detect keydown
        this._elements.div.addEventListener('keydown', (event) => {
            const [ENTER] = [13]; // key codes

            switch (event.keyCode) {
            case ENTER:
                this.handleDivEnter(event);
                break;
            }
        }, false);

        this._elements.ul.addEventListener('keydown', (event) => {
            const [ENTER, BACKSPACE] = [13, 8]; // key codes

            switch (event.keyCode) {
            case ENTER:
                this.handleListElementEnter(event);
                break;

            case BACKSPACE:
                this.handleListElementBackspace(event);
                break;
            }
        }, false);
    }

    handleDivEnter(event) {
        if(this.getCaretCharacterOffsetWithin(event.target) === 0) {
            event.preventDefault();
            event.stopPropagation();
            this.api.blocks.insert(undefined, undefined, undefined, this.api.blocks.getCurrentBlockIndex());
        } else if(this.getCaretCharacterOffsetWithin(event.target) === event.target.textContent.length) {
            let newItem = this._make('li', this.CSS.item, {
                innerHTML: '',
            })

            if(this._elements.ul.contentEditable === 'false') {
                this._elements.ul.contentEditable = true
                this._elements.ul.style.display = ''
            }

            this._elements.ul.insertBefore(newItem, this._elements.ul.firstChild);

            event.preventDefault();
            event.stopPropagation();

            this.focusAndSetCaretToEnd(newItem)
        }
    }

    handleListElementEnter(event) {
        let element = window.getSelection().anchorNode

        while(element && element.tagName !== 'LI') {
            element = element.parentNode
        }

        if(element && this.getCaretCharacterOffsetWithin(element) === 0 && element.textContent === '') {
            let index = -1

            this._elements.ul.childNodes.forEach((e, i) => {
                if(e === element) {
                    index = i
                }
            })

            if(index === (this._elements.ul.childNodes.length - 1)) {
                event.preventDefault();
                event.stopPropagation();

                this._elements.ul.removeChild(element)

                this.disableUlIfEmpty()

                this.api.blocks.insert(this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex()).name, {focus: true});
            }
        }
    }

    handleListElementBackspace(event) {
        if(this.disableUlIfEmpty()) {
            event.preventDefault();
            event.stopPropagation();

            this.focusAndSetCaretToEnd(this._elements.div)
        }
    }

    disableUlIfEmpty() {
        if(this._elements.ul.textContent === '') {
            while(this._elements.ul.firstChild) {
                this._elements.ul.removeChild(this._elements.ul.firstChild)
            }

            this._elements.ul.contentEditable = false
            this._elements.ul.style.display = 'none'

            return true
        }

        return false
    }

    getCaretCharacterOffsetWithin(element) {
        let caretOffset = 0;
        let doc = element.ownerDocument || element.document;
        let win = doc.defaultView || doc.parentWindow;
        let sel;

        if (typeof win.getSelection !== "undefined") {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                let range = win.getSelection().getRangeAt(0);
                let preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        } else if ((sel = doc.selection) && sel.type !== "Control") {
            let textRange = sel.createRange();
            let preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }

        return caretOffset;
    }

    focusAndSetCaretToEnd(el) {
        el.focus()
        let range = document.createRange();
        let sel = window.getSelection();
        range.setStart(el, el.childNodes.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    }

    selectItem(event) {
        event.preventDefault();

        const selection = window.getSelection(),
            currentNode = selection.anchorNode.parentNode,
            currentItem = currentNode.closest('.' + this.CSS.item),
            range = new Range();

        range.selectNodeContents(currentItem);

        selection.removeAllRanges();
        selection.addRange(range);
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName);

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames);
        } else if (classNames) {
            el.classList.add(classNames);
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}
