<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper" :class="{'error' : errors.productTypeId}">
                    <label class="text-input-label">{{ $t('group_category_product_type.modal.field.product_type') }}</label>
                    <custom-select
                        :filterable="true"
                        :modal="true"
                        :selectedItem="productType"
                        :options="productTypes"
                        @changed="(el) => {productType = el}"
                        :large="true"
                    ></custom-select>
                    <span class="input-error">{{errors.productTypeId}}</span>
                </div>
                <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.selectable}">
                    <div class="toggle">
                        <input type="checkbox" v-model="selectable" id="is-select-product-type-edit">
                        <label for="is-select-product-type-edit">{{ $t('group_category_product_type.modal.field.selectable') }}</label>
                    </div>
                    <span class="input-error">{{errors.selectable}}</span>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="save" :disabled="loading">
                        {{ $t('group_category_product_type.modal.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_category_product_type.modal.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupCategoryProductTypeEdit',
    props: ['open', 'groupId', 'categoryId', 'productTypeId', 'createTarget'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            productType: null,
            productTypes: [],
            selectable: false
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_CATEGORY_PRODUCT_TYPE', {
                    groupId: this.groupId,
                    categoryId: this.categoryId,
                    productTypeId: this.productTypeId,
                    createTarget: this.createTarget,
                }).then(data => {
                    this.title = data.title
                    this.productType = data.productType
                    this.productTypes = data.productTypes
                    this.selectable = data.selectable
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        save() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                categoryId: this.categoryId,
                productTypeId: this.productTypeId,
                createTarget: this.createTarget,
                data: {
                    productTypeId: this.productType ? this.productType.id : null,
                    selectable: this.selectable
                }
            }

            this.$store.dispatch('SEND_GROUP_CATEGORY_PRODUCT_TYPE', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    components: { Notification, CustomSelect }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }


        &-body {

            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
