<template>
    <div class="auth-page">
        <teaser :use-link="true" />

        <div class="auth-page__form">
            <div class="auth-form">
                <div class="auth-form__title">{{ $t('reset_password.page.text.title') }}</div>

                <p class="auth-form__subtitle">{{ $t('reset_password.page.text.description') }}</p>

                <template v-if="!loadingError && !loading">
                    <div class="auth-form__row">
                        <label for="l_reset_email">{{ $t('reset_password.page.field.email.label') }}</label>
                        <input disabled="disabled" type="text" name="email" v-model="email" tabindex="1" id="l_reset_email">
                    </div>

                    <div class="auth-form__row" :class="{ 'error' : errors['id'] }" v-if="users.length > 1">
                        <label for="l_reset_email">{{ $t('reset_password.page.field.user.label') }}</label>
                        <custom-select
                            :large="true"
                            :options="users"
                            :selectedItem="selectedUser"
                            @changed="(e) => this.selectedUser = e"
                            :disabled="disabled">
                            >
                        </custom-select>
                        <span class="auth-form__error" v-if="errors['id']">{{errors['id']}}</span>
                    </div>

                    <div class="auth-form__row" :class="{ 'error' : errors['newPassword'] }">
                        <label for="l_reset_password">{{ $t('reset_password.page.field.new_password.label') }}</label>
                        <input :disabled="disabled" ref="passwordField" type="password" name="password" v-model="newPassword" tabindex="2" id="l_reset_password" @keyup="changedPwdInput">

                        <span class="password-reveal" v-if="!passwordRevealed" @click="togglePassword()"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
                        <span class="password-reveal" v-else @click="togglePassword()"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

                        <span class="auth-form__error" v-if="errors['newPassword']">{{errors['newPassword']}}</span>
                    </div>

                    <div class="auth-form__row" :class="{ 'error' : errors['newPasswordAgain'] }">
                        <label for="l_reset_password_again">{{ $t('reset_password.page.field.new_password_again.label') }}</label>
                        <input :disabled="disabled" ref="passwordAgainField" type="password" name="password_again" v-model="newPasswordAgain" tabindex="3" id="l_reset_password_again" @keyup="changedPwdInput">

                        <span class="password-reveal" v-if="!passwordRevealedAgain" @click="togglePassword(true)"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
                        <span class="password-reveal" v-else @click="togglePassword(true)"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

                        <span class="auth-form__error" v-if="errors['newPasswordAgain']">{{errors['newPasswordAgain']}}</span>
                    </div>

                    <div class="auth-form__submit">
                        <button
                            @click="sendPassword()"
                            :disabled="disabled"
                            type="button"
                            class="btn btn-blue">
                            {{ $t('reset_password.page.button.save') }}
                        </button>

                        <span class="auth-form__error" v-if="generalError.length > 0">
                            {{generalError}}
                        </span>
                    </div>
                </template>
                <div class="auth-form__submit" v-else>
                    <span class="auth-form__error">
                        {{loadingError}}
                    </span>
                </div>
            </div>
            <support-text @openModal="openModal($event)" />
        </div>

        <modal-privacy :open="openedModal === 'privacy'" @close="closeModal()"></modal-privacy>
        <modal-terms :open="openedModal === 'terms'" @close="closeModal()"></modal-terms>
    </div>
</template>

<script>
import config from '../plugins/config.js'
import axios from 'axios'

import SupportText from '../components/auth/SupportText.vue'
import Teaser from '../components/auth/Teaser.vue'

import { scrollToError } from '@/plugins/helpers'

import ModalPrivacy from '../components/modals/ModalPrivacy.vue'
import ModalTerms from '../components/modals/ModalTerms.vue'

import Notification from '../components/Notification.vue'
import CustomSelect from '../components/dropdowns/CustomSelect.vue'

export default {
    name: 'ResetPassword',

    data() {
        return {
            loading: true,
            disabled: true,
            passwordRevealed: false,
            passwordRevealedAgain: false,
            email: '',
            newPassword: '',
            newPasswordAgain: '',
            users: [],
            selectedUser: null,
            errors: {},
            loadingError: '',
            generalError: '',
            openedModal: ''
        }
    },

    mounted() {
        if (this.$route.params.token) {
            this.fetchData()
        }

        if (this.$route.params.email) {
            this.email = this.$route.params.email
        }
    },

    methods: {
        fetchData() {
            const data = {
                token: this.$route.params.token,
                email: this.$route.params.email,
            }

            axios.post(`auth/reset/details`, data, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.users = data
                    this.selectedUser = this.users[0]
                    this.disabled = false
                })
                .catch(response => {
                    this.loadingError = response.error
                })
                .finally(() => {
                    this.loading = false
                })
        },

        changedPwdInput() {
            if (Object.keys(this.errors).length > 0) {
                this.errors = {}
            }

            if (this.generalError.length > 0) {
                this.generalError = ''
            }
        },

        togglePassword(again) {
            if (again){
                this.passwordRevealedAgain = !this.passwordRevealedAgain
                this.$refs.passwordAgainField.type = this.passwordRevealedAgain ? 'text' : 'password'
            } else {
                this.passwordRevealed = !this.passwordRevealed
                this.$refs.passwordField.type = this.passwordRevealed ? 'text' : 'password'
            }
        },

        openLogin() {
            this.$router.push('/')
        },

        sendPassword() {
            let data = {
                id: this.selectedUser ? this.selectedUser.id : null,
                token: this.$route.params.token,
                email: this.email,
                newPassword: this.newPassword,
                newPasswordAgain: this.newPasswordAgain
            }

            this.disabled = true

            axios.post('auth/reset/save', data, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.$router.push('/')

                    setTimeout(() => {
                        this.$store.commit('setNotification', [this.$t('reset_password.page.text.notification')])
                    }, 100)
                })
                .catch(response => {
                    this.errors = response.errors || {}
                    this.generalError = response.error || ''
                    this.disabled = false
                    scrollToError()
                })
        },

        openModal(modal) {
            this.openedModal = modal
        },

        closeModal() {
            this.openedModal = ''
        }
    },

    components: { ModalPrivacy, ModalTerms, Notification, SupportText, Teaser, CustomSelect }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/layout/auth-page.scss';
@import '../assets/sass/partials/auth-form.scss';
</style>
