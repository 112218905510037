<template>
    <div class="auth-page__teaser">
        <div class="auth-page__teaser__wrapper">
            <div>
                <router-link to="/" v-if="useLink">
                    <img alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo">
                </router-link>
                <img v-else alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo" @click="$emit('onBrandLogoClick')">
                <div class="auth-page__teaser__logos">
                    <img alt="BMI Bramac logo" src="@/assets/images/logo-bmi-bramac.png">
                    <span> | </span>
                    <img alt="BMI Villas logo" src="@/assets/images/logo-bmi-villas.png">
                </div>
                <h1>{{ $t('common.short_name') }}</h1>
            </div>

            <ul v-if="showOfferTeaser || showOrderTeaser">
                <li v-if="showOfferTeaser"><md-icon>check</md-icon> {{ $t('offer.teaser') }}</li>
                <li v-if="showOrderTeaser"><md-icon>check</md-icon> {{ $t('order.teaser') }}</li>
            </ul>
        </div>
    </div>
</template>

<script>

import config from '../../plugins/config'

export default {
    props: ['useLink'],
    name: 'Teaser',
    computed: {
        showOfferTeaser() {
            return config.SHOW_OFFER_TEASER
        },
        showOrderTeaser() {
            return config.SHOW_ORDER_TEASER
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';

.auth-page {
    &__teaser {
        flex-basis: 50%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url('/src/assets/images/login-background.png');
        background-repeat: no-repeat;
        background-size: 100%;

        @media only screen and (max-width: 600px) {
            display: none;
        }

        &__wrapper {
            width: 420px;
            text-align: left;

            @media only screen and (max-width: 600px) {
                width: 100%;
            }

            img {
                width: 179px;
                height: 179px;
                display: block;
                margin: 0 auto;
            }

            h1 {
                display: block;
                font-size: 30px;
                font-weight: bold;
                color: $color-gray-2;
                text-align: center;
                margin: 50px 0 140px;
            }

            ul {
                list-style: none;

                padding: 0 100px;

                li {
                    font-size: 26px;
                    line-height: 1;
                    margin-bottom: 40px;
                    padding: 5px 0 5px 30px;
                    position: relative;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    i {
                        background-color: $color-green-1;
                        border-radius: 50%;
                        color: white;
                        font-size: 16px !important;
                        position: absolute;
                        height: 20px;
                        left: 0;
                        top: 8px;
                        margin-right: 10px;
                        min-width: 0;
                        width: 20px;
                    }
                }
            }
        }

        &__logos {
            align-items: center;
            display: flex;
            margin-top: 50px;

            img {
                display: inline-block;
                height: 50px !important;
                width: auto;
            }

            span {
                display: inline-block;
                padding: 0 20px;
            }
        }
    }
}

</style>
