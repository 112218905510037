import { render, staticRenderFns } from "./TagFilter.vue?vue&type=template&id=ff921346&scoped=true"
import script from "./TagFilter.vue?vue&type=script&lang=js"
export * from "./TagFilter.vue?vue&type=script&lang=js"
import style0 from "./TagFilter.vue?vue&type=style&index=0&id=ff921346&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff921346",
  null
  
)

export default component.exports