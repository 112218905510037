import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'
import plugins from "./plugins"
import config from '../plugins/config.js'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    key: 'bramacPersist',
    storage: window.localStorage,
    reducer: state => ({
        authSession: state.authSession,
        authToken: state.authToken,
        profile: state.profile
    })
})

export const defaultOfferInformation = {
    id: null,
    name: null,
    groupId: null,
    validTo: null,
    ownerUser: null,
    editorUser: null,
    relatedOffers: [],
    advisoryUser: null,
    advisoryUsers: [],
    observerUsers: [],
    dealerUsers: [],
    workerUsers: [],
    created: null,
    modified: null,
    saveNewAvailable: false,
    originalOfferId: null,
    workerOffer: false,
    type: null,
    tags: []
}

export const defaultOrderData = {
    title: null,
    differentColors: false,
    restricted: false,
    dealerDiscounts: true,
    activeProductTypes: [],
    categories: [],
    totalData: {
        listPrice: {
            gross: 0,
            net: 0
        },
        sales: {
            gross: 0,
            net: 0
        },
        buyer: {
            gross: 0,
            net: 0
        },
        total: {
            gross: 0,
            net: 0
        }
    },
    groupTypes: {
        grouped: false
    },
    selectedGroupType: {},
    variants: [],
    variantType: '',
    selectedVariant: {},
    hasCalculation: false,
    hasColor: false,
    multiLevel: false,
    recommendedProducts: [],
    basicInformation: [],
    calculation: []
}

export const defaultDiscounts = {
    discountType: null,
    groupDiscounts: []
}

export const defaultAdditionalDetails = {
    comments: [{text: ''}],
    systemWarrantee: true
}

export default new Vuex.Store({
    state: {
        priceList: null,
        userFetched: false,
        authSession: false,
        authToken: '',
        profile: {},
        validation: {
            message: null,
            fields: {}
        },
        permissions: {},
        viewOnly: false,
        canTag: false,
        canEditSales: false,

        projects: {},
        projectListActiveFilters: {},

        orders: {},
        orderListActiveFilters: {},
        orderListScrollPosition: 0,

        offers: {},
        advisories: {},
        allDealers: {},
        offerListActiveFilters: {},
        offerListScrollPosition: 0,
        tags: [],
        selectedTagsCount: null,

        objects: {},
        objectListActiveFilters: {},
        objectListScrollPosition: 0,
        objectStandaloneTypes: [],

        dealers: {},
        dealerListActiveFilters: {},
        dealerListScrollPosition: 0,

        workers: {},
        workerListActiveFilters: {},

        regions: {},
        workerProjectList: [],
        workersAll: [],

        products: {},
        productListActiveFilters: {},

        groupTypeProducts: {},
        groupCategoryProducts: {},
        groupCategoryProductTypes: {},
        groupCategorySort: [],
        groupTypeSort: [],
        groupTypeVariants: {},
        groupProducts: {},
        productTypes: {},
        colors: {},
        units: {},
        carriers: {},
        surfaces: {},

        reAuthenticated: !config.NEED_LOGIN_FOR_PRODUCT_ADMIN,

        allUsers : {},
        allUsersWithoutFilters : {},
        users: {},
        userListActiveFilters: {},
        userRoles: [],
        allUserRoles: [],

        loginData: null,
        notification: { open: false, message: [] },
        modals: {
            comments: {
                open: false
            },
            editComment: {
                open: false
            },
            planCalculation: {
                open: false
            },
            consignment: {
                open: false
            }
        },
        orderSettings: {
            discounts: {
                open: false
            },
            services: {
                open: false
            },
            comments: {
                open: false
            },
            tags: {
                open: false
            }
        },
        zipCodesList: [],
        citiesList: [],
        targetComment: 0,
        savedComments: {
            personal: [],
            global: []
        },
        offerProductTypes: [],
        orderProductTypes: [],
        projectData: {},
        projectOffers: [],
        newOfferGroup: {},
        orderData: defaultOrderData,
        orderDataTabs: [],
        activeOrderTab: null,
        transportsInTabs: [],
        allTransportsInTabs: [],
        selectedOrderDataTab: null,
        additionalDetails: defaultAdditionalDetails,
        orderDetails: {
            customerOrderId: '',
            transportId: 1, // alapértelmezett
            shippingLocationId: 1, // raktárra
            shippingTruckTypeId: 1, // fix vagy darus
            shippingName: '',
            shippingPhone: '',
            shippingCompany: '',
            shippingZip: '',
            shippingCity: '',
            shippingAddress: '',
            shippingSoloTruck: false,
            shippingComment: '',
            shippingPathInfo: '',
            shippingDate: null,
            tabs: []
        },
        orderStatus: {},
        originalOffer: {},
        offerInformation: defaultOfferInformation,
        orderInformation: {
            id: null,
            offerId: null
        },
        offerActions: [],
        offerList: [],
        dealerList: [],
        projectDealers: [],
        discounts: defaultDiscounts,
        object: {},
        pallets: [],
        customPallets: false,
        customDemolitionPallets: null,
        customTransport: 0,
        customDealerTransport: false,
        dealerTransport: 0,
        services: [],
        totalServicePrice: 0,
        groupTransports: [],
        transports: [],
        selectedTransport: null,
        transportPrices: {
            list: [],
            sales: [],
            buyer: [],
            dealer: []
        },
        groupTransportPrices: {
            list: [],
            sales: [],
            buyer: [],
            dealer: []
        },
        warehouse: false,
        userSettings: {
            emailSettings: [],
            showNetPrice: false
        },
        displayNetPrice: true,
        productText: {},
        productTextDebug: null,
        planCalculation: null,
        recommendedProductId: null,
        stalledOffers: []
    },

    actions: actions,
    mutations: mutations,
    getters: getters,
    plugins: [plugins, vuexLocal.plugin],

    strict: false
})
