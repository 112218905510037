<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box ">
            <div class="modal-box-body">
                <div class="message-text">{{ $t('offer.page.edit.confirmation.discount.title') }}</div>
                <div class="radio-container">
                    <div class="radio">
                        <input type="radio" name="r-adc" id="adc0" value="0" v-model="additionalDiscountType">
                        <label for="adc0">
                            <span class="text">{{ $t('offer.page.edit.confirmation.discount.option.custom_discount') }}</span>
                            <span class="bg-layer"></span>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="r-adc" id="adc1" value="2" v-model="additionalDiscountType">
                        <label for="adc1">
                            <span class="text">{{ $t('offer.page.edit.confirmation.discount.option.sale_discount') }}</span>
                            <span class="bg-layer"></span>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="r-adc" id="adc2" value="5" v-model="additionalDiscountType">
                        <label for="adc2">
                            <span class="text">{{ $t('offer.page.edit.confirmation.discount.option.base_discount') }}</span>
                            <span class="bg-layer"></span>
                        </label>
                    </div>
                </div>
                <div class="button-container">
                    <button type="button" class="btn btn-primary" @click="save">{{ $t('offer.page.edit.confirmation.discount.next') }}</button>
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{ $t('offer.page.edit.confirmation.discount.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalConfirmDiscount',
    props: ['open'],

    data() {
        return {
            additionalDiscountType: 0
        }
    },

    methods: {
        save() {
            this.$emit('accepted', this.additionalDiscountType)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        top: 25vh;
        width: 620px;

        &-body {
            padding-top: 32px;

            .message-text {
                line-height: 1.2;
            }

            .radio-container {
                display: flex;
                flex-direction: column;
                margin-top: 15px;

                .radio {
                    margin-bottom: 5px;

                    label:after {
                        top: 5px;
                    }

                    .text {
                        font-size: 15px;
                        margin-left: 4px;
                    }
                }
            }

            .button-container {
                margin-top: 15px;

                button {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }
}
</style>
