<template>
    <div class="offer-list-page" v-if="userAuthenticated">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div
                class="dashboard-header__new"
                v-click-outside="() => {this.openNewOfferDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openNewOfferDropdown = !openNewOfferDropdown">
                    <md-icon>add_circle</md-icon> {{ $t('offer.page.list.button.create') }}
                </button>
                <product-type-dropdown
                    :open="openNewOfferDropdown"
                    :productTypes="offerProductTypes"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="offer-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="offerListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                        <button v-if="isMobile"  @click="toggleSearchItems = !toggleSearchItems" class="btn filters--button">{{ toggleSearchItems ? 'Szűrők elrejtése' : "Szűrők megtekintése" }}</button>
                    </div>

                    <div class="custom--filters-container" v-show="toggleSearchItems || !isMobile">
                        <custom-filter
                            v-if="offerListGroups.length > 0"
                            :options="offerListGroups"
                            :value="offerListActiveFilters.group"
                            :label="''"
                            @selected="filterCategory" />

                        <div style="display:contents;" v-if="userCanSeeFilters">
                            <custom-filter
                                class="filter-component"
                                v-if="offerListAdvisories.length > 1"
                                :options="offerListAdvisories"
                                :value="offerListActiveFilters.advisory"
                                :label="''"
                                :dropdown-size="340"
                                @selected="filterAdvisories" />

                            <custom-select
                                class="filter-component"
                                v-if="offerListDealers.length > 1"
                                :selectedItem="selectedDealer"
                                :filterable="true"
                                :large="true"
                                :fixedWidth="true"
                                :width="200"
                                :options="offerListDealers"
                                @changed="filterDealers">
                            </custom-select>

                            <div class="filter-component input-wrapper">
                                <custom-datepicker
                                    :initDate="null"
                                    @changed="(el) => {fromDate = el}"
                                />
                            </div>

                            <div class="filter-component input-wrapper">
                                <custom-datepicker
                                    :initDate="null"
                                    @changed="(el) => {toDate = el}"
                                />
                            </div>

                            <button
                                v-tooltip="{content: offerListActiveFilters.is_archived ? `Archivált ajánlatok elrejtése` : `Archivált ajánlatok mutatása`, placement: 'top', classes: 'tooltip-privacy'}"
                                @click="toggleArchives"
                                :class="[offerListActiveFilters.is_archived ? 'active' : '', 'archive--button']" type="button">
                                <md-icon>archive</md-icon>
                            </button>
                        </div>

                        <div class="offer-list-page__header-controls">
                            <!--
                                <div class="archive-container" v-click-outside="() => {this.openArchiving = false}">
                                    <archive-dropdown :open="openArchiving" @archive="archiveOldOffers()" @close="openArchiving = false"></archive-dropdown>

                                    <button type="button" @click="openArchiving = !openArchiving">
                                        <md-icon>archive</md-icon>
                                    </button>
                                </div>
                            -->

                                <div v-if="userCanSeeFilters">
                                    <button @click="exportSelected()" type="button">
                                        <md-icon>download</md-icon>
                                    </button>
                                    <a href="" download ref="pdfButton" class="pdf-download"></a>
                                </div>

                                <button type="button" @click="refresh()">
                                    <md-icon>refresh</md-icon>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="filter-column" :class="{'collapsed-filter-column' : collapsedColumn}">

            <div class="button-container">
                <button class="btn btn-plain btn-plain-wicon button-close" type="button" @click="collapsedColumn = true">
                    <md-icon>arrow_back_ios</md-icon>
                </button>
            </div>

            <button class="btn btn-plain btn-plain-wicon button-open" type="button" @click="collapsedColumn = false">
                <md-icon>arrow_forward_ios</md-icon>
            </button>

            <sidebar-filter
                v-if="offerListFilters"
                :elements="offerListFilters"
                :selected="offerListActiveFilters.filter"
                @changed="sidebarChanged"
            ></sidebar-filter>

            <tag-filter
                v-if="tags && profile.isInternal"
                :options="tags"
                :canCreateTags="profile.canCreateTags"
                :canDeleteTags="profile.canDeleteTags"
                :selectedTag="offerListActiveFilters.tag"
                @changed="filterTag"
                @reset="resetTags"
                @openNewTag="openNewTag"
                @delete="deleteTagType"
            ></tag-filter>

            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <custom-table
                ref="table"
                :row-height="92"
                :columns="tableColumns"
                :pages="offerListPages"
                :meta="offerListMeta"
                :row-style-class="rowClass"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-cell-click="onCellClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-favourite" v-if="props.column.field === 'favourite'">
                        <md-icon
                            v-if="profile.canAnswerQuestions && props.row.has_question"
                            style="color:red"
                            v-tooltip="{content: `Kereskedő / tetőfedőnek segítségre van szüksége`, placement: 'left', classes: 'tooltip-privacy'}">
                            question_mark
                        </md-icon>

                        <button @click="updateFavorite(props.row.id, props.page, false)" v-show="props.row.favorite && !props.row.archived" class="remove element-todo" type="button">
                            <md-icon class="element-todo">star</md-icon>
                        </button>

                        <button @click="updateFavorite(props.row.id, props.page, true)" v-show="!props.row.favorite && !props.row.archived" class="add element-todo" type="button">
                            <md-icon class="element-todo">star_border</md-icon>
                        </button>

                        <md-icon v-show="props.row.ordered && !props.row.orderDraft" class="i-done" title="Megrendelve">done</md-icon>
                        <img v-show="props.row.ordered && props.row.orderDraft" title="Megrendelés folyamatban" src="@/assets/svg/inprogress.svg">
                    </div>

                    <div class="col-name" v-else-if="props.column.field === 'name'">
                        <span class="small-line">{{ props.row.address }}</span>
                        <span class="large-line">{{ props.row.name }}</span>
                        <span class="normal-line" v-if="props.row.offerName">{{ props.row.offerName }}</span>

                        <span class="tag-line" v-if="props.row.tags.length && profile.isInternal">
                            <div :class="`tag tag--color-${tag.color}`" v-for="(tag, tagIndex) in props.row.tags" :key="tagIndex">
                                {{ tag.title }}
                                <!--<span
                                    v-if="tag.creator_id == profile.id || tag.is_template == true"
                                    @click="deleteTag([props.row.id, tag.id, props.page])"
                                    class="tag--delete"
                                    >&times;</span>
                                -->
                            </div>
                        </span>
                    </div>

                    <div class="col-contact" v-else-if="props.column.field === 'contact'">
                        <span class="small-line show-onhover" v-if="props.row.dealerCompany">{{ props.row.dealerCompany }}</span>
                        <span class="large-line">{{ props.row.contact }}</span>
                    </div>

                    <div class="col-type" v-else-if="props.column.field === 'type'">
                        <span class="small-line show-onhover">{{ props.row.number }}</span>
                        <span class="large-line">{{ props.row.type }}</span>
                    </div>

                    <div class="col-created" v-else-if="props.column.field === 'createdAt'">
                        <span class="small-line">{{ props.row.createdAtFormatted }}</span>
                        <span class="large-line show-onhover">{{ props.row.responsibleUser }}</span>
                    </div>

                    <div class="col-sign" v-else-if="props.column.field === 'sign'">
                        <md-icon v-tooltip="setLineBreak(props.row.warningText)" v-if="props.row.warningText">warning</md-icon>
                    </div>

                    <div class="col-options" v-else-if="props.column.field === 'options'" @click="props.row.showDropdown = !props.row.showDropdown">
                        <button v-if="props.row.actions.length > 0" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            v-if="props.row.actions.length > 0"
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            :elementPayload="props.row.tags"
                        ></dropdown>

                        <button v-if="props.row.viewType !== 'edit'" class="button-visibility" >
                            <md-icon v-if="props.row.viewType === 'eye'">remove_red_eye</md-icon>
                            <md-icon v-if="props.row.viewType === 'lock'">lock</md-icon>
                        </button>
                    </div>

                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="empty_state">
                    {{ $t('offer.page.list.text.empty_state') }}
                </div>
            </custom-table>
        </div>

        <div class="mobile--add-menu">
            <button class="btn btn-primary floating--button" type="button" @click="openNewOfferDropdownMobile = !openNewOfferDropdownMobile">
                <md-icon>add_circle</md-icon>
            </button>

            <product-type-dropdown
                v-click-outside="() => {this.openNewOfferDropdownMobile = false}"
                :open="openNewOfferDropdownMobile"
                :productTypes="offerProductTypes"
                @selectedType="selectedProductType">
            </product-type-dropdown>
        </div>

        <modal-project-select
            :open="openProjectSelectModal"
            :projectData="selectedProjectData"
            @close="closedProjectSelects"
            @selected="selectedProject">
        </modal-project-select>
        <modal-project-edit
            :open="openProjectEditModal"
            :data="selectedProjectData"
            :isAvailableProject="availableProject"
            :isCopyProject="copyProject"
            @close="closeProjectEditModal"
            @selectProject="openProjectEditModal = false; openProjectSelectModal = true">
        </modal-project-edit>
        <modal-tag-add
            :canCreateTemplateTags="profile.canCreateTemplateTags"
            :open="openTagAddModal"
            @close="closeTagAddModal">
        </modal-tag-add>
        <modal-tag-select
            v-if="openTagSelectModal"
            :offerData="openTagSelectModalData"
            @close="closeTagSelectModal">
        </modal-tag-select>
        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="offerCopyNew"
            @close="openConfirmationModal = false"
            @secondaryAction="offerCopyExisting"
        ></modal-confirmation>
        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @secondaryPrimaryAction="offerDelete(true)"
            @accepted="offerDelete(false)"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>
        <modal-confirmation
            :open="openConfirmationDeleteTagModal"
            :data="confirmationDeleteTagModalData"
            @accepted="deleteTagType(deleteTagData, true)"
            @close="openConfirmationDeleteTagModal = false"
        ></modal-confirmation>

        <notification></notification>

        <modal-ask-for-help v-if="profile.showStalledOffersModal" :open="openAskForHelpModal" @close="openAskForHelpModal = false">

        </modal-ask-for-help>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import vClickOutside from 'v-click-outside'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Search from '../components/Search.vue'
import SidebarFilter from '../components/SidebarFilter.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import TagFilter from '../components/dropdowns/TagFilter.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'
import ModalProjectSelect from '../components/modals/ModalProjectSelect.vue'
import ModalProjectEdit from '../components/modals/ModalProjectEdit.vue'
import ModalTagAdd from '../components/modals/ModalTagAdd.vue'
import ModalTagSelect from '../components/modals/ModalTagSelect.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ModalAskForHelp from "../components/modals/ModalAskForHelp.vue"
import Notification from '../components/Notification.vue'
import CustomTable from '../components/CustomTable'
import CustomSelect from '@/components/dropdowns/CustomSelect.vue'
import CustomDatepicker from '../components/dropdowns/CustomDatepicker.vue'
import axios from "axios";
import config from "@/plugins/config";

export default {
    name: 'OfferList',

    data() {
        return {
            loading: true,
            showArchives: false,

            openProjectSelectModal: false,
            openProjectEditModal: false,
            openTagAddModal: false,
            openTagSelectModal: false,
            openTagSelectModalData: {},
            openProjectCopyModal: false,
            openAskForHelpModal: false,
            selectedProjectData: null,
            availableProject: false,
            searchTimer: null,

            openNewOfferDropdownMobile: false,

            copyProject: null,
            deleteOfferData: null,
            deleteTagData: null,

            toggleSearchItems: false,
            viewportWidth: window.innerWidth || document.documentElement.clientWidth,

            tableColumns: [
                {
                    label: '',
                    field: 'favourite',
                    width: '50px'
                },
                {
                    label: this.$t('offer.page.list.column.name'),
                    field: 'name',
                    width: '25%'
                },
                {
                    label: this.$t('offer.page.list.column.contact'),
                    field: 'contact'
                },
                {
                    label: this.$t('offer.page.list.column.type'),
                    field: 'type',
                    width: '22%'
                },
                {
                    label: this.$t('offer.page.list.column.created_at'),
                    field: 'createdAt',
                    width: '15%'
                },
                {
                    label: '',
                    field: 'sign',
                    width: '30px'
                },
                {
                    label: '',
                    field: 'options',
                    width: '80px'
                }
            ],


            showFilters: false,
            selectedDealer: {},
            fromDate: '',
            toDate: '',
            initFromDate: '',
            initToDate: '',

            openArchiving: false,
            openNewOfferDropdown: false,
            collapsedColumn: false,

            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('offer.page.list.confirmation.copy.ok'),
                cancelText: this.$t('offer.page.list.confirmation.copy.cancel'),
                secondaryActionText: this.$t('offer.page.list.confirmation.copy.secondary'),
                message: this.$t('offer.page.list.confirmation.copy.question')
            },
            openConfirmationDeleteModal: false,
            openConfirmationDeleteTagModal: false
        }
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);

        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (!this.userHasOffers) {
                this.$store.dispatch('GO_TO_FIRST_AVAILABLE_LIST_PAGE')
            } else {
                const hasData = Object.keys(this.offerListPages).length > 0

                if (hasData) {
                    this.$refs.table.scrollTo(this.offerListScrollPosition)
                }

                this.$store.dispatch('FETCH_OFFER_PRODUCT_GROUPS').then(() => {
                    this.$store.dispatch('FETCH_TAGS').then(() => {
                        if(hasData) {
                            this.$store.dispatch('FETCH_OFFER_PAGES').then(() => {
                                this.loading = false
                            })
                        } else {
                            this.$store.dispatch('FETCH_OFFERS', {page: 1, keepOtherPages: false}).then(() => {
                                console.error(this.offerListPages);
                                this.loading = false
                            })
                        }

                        this.showArchives = this.offerListActiveFilters.is_archived ?? false
                    })
                })

                this.initFromDate = '';
                this.initToDate = '';

                if (this.userCanSeeFilters) {
                    this.$store.dispatch('FETCH_OFFER_ADVISORIES');
                    this.$store.dispatch('FETCH_OFFER_DEALERS').then(() => {
                        this.selectedDealer = this.offerListDealers[0];
                    });
                }
            }
            this.openAskForHelpModal = true;
            this.selectedProjectData = null
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (!from || (from.name !== 'OfferCategory' && from.name !== 'OfferText')) {
                vm.$store.commit('resetOffers')
            }
        })
    },

    methods: {
        handleResize() {
            this.viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        },

        async exportSelected() {
            this.loading = true
            await axios.post('offers/export', this.offerListActiveFilters, config.AXIOS_DEFAULTS)
                .then(data => {
                    // const downloadButton = this.$refs.pdfButton
                    // downloadButton.href = data.downloadUrl
                    // downloadButton.click()
                    this.$store.commit('setNotification', [data.message])
                    this.loading = false
                })
                .catch(error => {
                    this.$store.commit('setNotification', ['Ennyi ajánlatot nem sikerült exportálni'])
                    this.loading = false
                    console.error(error)
                })
        },

        sidebarChanged(el) {
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                filter: el
            })
            this.refresh()
        },

        filterCategory(e) {
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                group: e.id
            })
            this.refresh()
        },

        filterAdvisories(e) {
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        filterDealers(e) {
            this.selectedDealer = e;
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                dealer: e.id
            })
            this.refresh()
        },

        filterFromDate(){
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                from_date: this.fromDate
            })
            this.refresh()
        },

        filterToDate(){
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                to_date: this.toDate
            })
            this.refresh()
        },

        toggleArchives(){
            this.showArchives = !this.showArchives
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                is_archived: this.showArchives
            })
            this.refresh()
        },

        filterTag(e) {
            if (this.offerListActiveFilters.tag === e.id) {
                this.resetTags()
            } else {
                this.$store.commit('setOfferListActiveFilters', {
                    ...this.offerListActiveFilters,
                    tag: e.id
                })
                this.refresh()
            }
        },

        resetTags() {
            this.$store.commit('setOfferListActiveFilters', {
                ...this.offerListActiveFilters,
                tag: void 0
            })
            this.refresh()
        },

        openNewTag() {
            this.openTagAddModal = true
        },

        addTag([id, tags, page]) {
            this.openTagSelectModal = true
            this.openTagSelectModalData = {id, tags, page}
        },

        deleteTag([id, tag, page]) {
            this.$store.dispatch('REMOVE_OFFER_TAG', {id, tag}).then(() => this.loadPage(page))
        },

        deleteTagType(tag, confirmed = false) {
            if(!confirmed) {
                this.deleteTagData = tag
                this.openConfirmationDeleteTagModal = true
                return
            }

            this.deleteTagData = null
            this.openConfirmationDeleteTagModal = false

            this.$store.dispatch('DELETE_OFFER_TAG_TYPE', {
                id: tag.id
            }).then(() => {
                this.resetTags()
            })
        },

        countUnique(iterable) {
            return new Set(iterable).size;
        },

        onSearchPhraseChanged(phrase) {

            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.offerListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setOfferListActiveFilters', {
                    ...this.offerListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_OFFERS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeOfferListPage', page)
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_OFFERS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },

        onCellClick(params) {
            if (!params.row.showDropdown && params.column.field !== 'favourite' && params.column.field !== 'options' &&  !params.event.target.className.includes('element-todo') && !params.event.target.className.includes('element-options') && !params.event.target.className.includes('tag--')) {
                this.$store.commit('setOfferListScrollPosition', this.$refs.table.getScrollPosition())
                if (params.row.groupType === 'text') {
                    this.$router.push({name: 'OfferText', params: {offerId: params.row.id}})
                } else {
                    this.$router.push({name: 'OfferCategory', params: {offerId: params.row.id}})
                }
            }
        },

        updateFavorite(id, page, addToFavorites) {
            let newData = {
                id: id,
                page: page,
                add: addToFavorites
            }

            this.$store.dispatch('UPDATE_OFFER_FAVORITE', newData)
        },

        onDropdownClick(val, page) {
            let newData = {}
            let action = typeof val.action === 'string' ? val.action : val.action.action

            switch (action) {
            case 'offer-copy':
                newData = {
                    id: val.id,
                    inList: true
                }
                this.openConfirmationModal = true
                this.copyProject = newData
                break
            case 'offer-delete':
                newData = {
                    id: val.id,
                    inList: true,
                    page: page,
                    hasRelatedOffers: val.action.hasRelatedOffers
                }

                this.openConfirmationDeleteModal = true
                this.deleteOfferData = newData
                break
            /*case 'offer-archive':
                newData = {
                    id: val.id,
                    archive: true,
                    page: page,
                    inList: true
                }
                this.$store.dispatch('UPDATE_OFFER_ARCHIVE', newData)
                break
            case 'offer-unarchive':
                newData = {
                    id: val.id,
                    archive: false,
                    page: page,
                    inList: true
                }
                this.$store.dispatch('UPDATE_OFFER_ARCHIVE', newData)
                break
            */
            case 'offer-order':
                this.$router.push({
                    name: 'OrderDetailsFromOffer',
                    params: {offerId: val.id}
                })
                break
            case 'offer-edit-order':
                this.$router.push({
                    name: 'OrderDetails',
                    params: {orderId: val.action.orderId}
                })
                break
            case 'offer-view-order':
                this.$router.push({
                    name: 'OrderStatus',
                    params: {orderId: val.action.orderId}
                })
                break
            case 'offer-tags':
                this.addTag([val.id, val.payload, page])
                break

            case 'offer-answer':
                this.answerQuestion(val.id, page)
                break
            }
        },

        async answerQuestion(id, page) {
            try {
                await this.$store.dispatch('ANSWER_QUESTION', id);
                await this.$store.dispatch('FETCH_OFFERS', { page, keepOtherPages: true });
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },

        offerCopyNew() {
            this.openConfirmationModal = false
            this.$store.dispatch('COPY_OFFER', this.copyProject)
        },

        offerCopyExisting() {
            this.openConfirmationModal = false
            this.openProjectSelectModal = true
        },

        offerDelete(withRelated) {
            this.openConfirmationDeleteModal = false
            this.$store.dispatch('DELETE_OFFER', {withRelated, ...this.deleteOfferData})
        },

        /*archiveOldOffers() {
            this.$store.dispatch('ARCHIVE_OFFERS').then(() => {
                this.openArchiving = false
                this.refresh()

                this.$store.commit('setNotification', [this.$t('offer.page.list.archive.text.notification')])
            })
        },
        */

        rowClass(row) {
            let classList = ''
            classList += row.newCopy ? 'is-newcopy' : ''
            classList += (row.has_question && this.profile.canAnswerQuestions) ? 'has-question' : ''
            return classList
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProductType(payload) {
            this.copyProject = null
            this.openNewOfferDropdown = false
            this.$store.commit('setNewOfferGroup', payload)
            this.openProjectSelectModal = true
        },

        selectedProject(data) {
            this.selectedProjectData = data.projectData
            this.availableProject = data.type === 'available'
            this.openProjectSelectModal = false
            this.openProjectEditModal = true
        },

        closedProjectSelects() {
            this.openProjectSelectModal = false
            this.$router.push({name: 'OfferList'})
        },

        closeProjectEditModal() {
            this.openProjectEditModal = false
        },

        closeTagAddModal() {
            this.openTagAddModal = false
        },

        closeAskForHelpModal(){
            this.openAskForHelpModal = false
        },

        closeTagSelectModal() {
            this.openTagSelectModal = false
            this.openTagSelectModalData = {}
        },

        setLineBreak(str) {
            if (str === null) {
                return null
            }

            return String(str).replace(/(\S+\s*){1,7}/g, "$&\n")
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        isMobile() {
            return this.viewportWidth < 600;
        },
        confirmationDeleteModalData() {
            if (this.deleteOfferData && this.deleteOfferData.hasRelatedOffers) {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.delete.with_related.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.delete.with_related.cancel'),
                    secondaryPrimaryActionText: this.$t('offer.page.edit.confirmation.delete.with_related.secondary'),
                    message: this.$t('offer.page.edit.confirmation.delete.with_related.question'),
                    width: 655
                }
            } else {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.delete.without_related.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.delete.without_related.cancel'),
                    message: this.$t('offer.page.edit.confirmation.delete.without_related.question')
                }
            }
        },

        confirmationDeleteTagModalData() {
            return {
                acceptText: this.$t('offer.page.list.confirmation.delete_tag.ok'),
                cancelText: this.$t('offer.page.list.confirmation.delete_tag.cancel'),
                message: this.deleteTagData ? this.$t('offer.page.list.confirmation.delete_tag.question', {
                    title_hu_article: this.deleteTagArticle,
                    title_bold: `<strong>${this.deleteTagData.title}</strong>`,
                    title: this.deleteTagData.title
                }) : ''
            }
        },

        deleteTagArticle() {
            if (this.deleteTagData && /^[aeiou]/i.test(this.deleteTagData.title)) {
                return 'az'
            } else {
                return 'a'
            }
        },

        searchData() {
            return {
                placeholder: this.$t('offer.page.list.search.placeholder'),
                tooltipText: this.$t('offer.page.list.search.tooltip'),
                countText: this.$tc('offer.page.list.search.name', this.offerListMeta.total) + ' ' + this.offerListProjectCount + ' projekt'
            }
        },

        ...mapGetters(
            [
                'offerProductTypes',
                'profile',
                'offerListProjectCount',
                'offerListPages',
                'offerListDealers',
                'offerListAdvisories',
                'offerListFilters',
                'offerListGroups',
                'offerListMeta',
                'offerListActiveFilters',
                'offerListScrollPosition',
                'tags',
                'userAuthenticated',
                'userHasOffers',
                'userCanSeeFilters',
            ])
    },

    components: {
        MainNavigation,
        Search,
        SidebarFilter,
        CustomFilter,
        TagFilter,
        BasicLinks,
        Dropdown,
        ProductTypeDropdown,
        CustomTable,
        ModalProjectSelect,
        ModalProjectEdit,
        ModalTagAdd,
        ModalTagSelect,
        ModalConfirmation,
        ModalAskForHelp,
        Notification,
        CustomSelect,
        CustomDatepicker,
    },

    watch: {
        'fromDate': {
            handler: function () {
                this.filterFromDate();
            }
        },

        'toDate': {
            handler: function () {
                this.filterToDate();
            }
        },
    },
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/tag.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.offer-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 500px;
            min-width: 150px;
            margin: 0px 5px 5px 0px;

            @media only screen and (max-width: 600px) {
                padding: 10px;
                width: 95%;
                margin: auto;
            }

            .filters--button{
                display: none;
                width: 100%;
                margin-top: 5px;

                @media only screen and (max-width: 600px) {
                    display: block;
                }
            }
        }

        .filter-component {
            margin: 5px 5px 5px 0px;
        }
    }

    &__header-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__header-search-flex {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        align-content: space-between;
        padding: 10px;
    }

    &__header-controls {
        padding-right: 30px;

        @media only screen and (max-width: 600px) {
            display: flex;
            margin-top: 15px;
            padding-right: 15px;
        }
    }

    .custom--filters-container{
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            width: 95%;
            margin: auto;
            padding: 10px;
        }
    }

    .archive-container {
        display: inline-block;
        position: relative;
    }

    .col-name {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-width: 600px) {
            height: 70px;
            width: max-content;
        }
    }

    .col-favourite {
        margin-right: 35px;
        width: 80px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 600px) {
            width: 40px;
            margin-right: 0px;
        }

        .i-done {
            // position: relative;
            // top: 4px;
            // left: 7px;
            font-weight: 600;
            color:  $color-green-1;
        }

        button {
            line-height: 18px;

            i {
                margin-top: 5px;
            }

            &.remove {
                i {
                    color: $color-yellow-star;
                }
            }
        }
    }

    .col-options {
        position: relative;
        width: 100px;

        @media only screen and (max-width: 600px) {
            width: 30px;
        }
    }
}
</style>
