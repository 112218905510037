<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('project.edit.title') }}</span>
                <button type="button" @click="closeModal" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>
            <div class="modal-box-body">
                <project-data-form
                    :projData="data"
                    :fromProjects="fromProjects"
                    :isNewOffer="true"
                    :availableProject="isAvailableProject"
                    :isCopyProject="isCopyProject"
                    @close="closeModal"
                    @selectProject="backToSelectProject"></project-data-form>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import ProjectDataForm from '../../components/ProjectDataForm.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectEdit',
    props: ['open', 'data', 'isAvailableProject', 'isCopyProject', 'fromProjects'],


    methods: {
        closeModal(refresh = false) {
            this.$emit('close', refresh)
        },

        backToSelectProject() {
            this.$emit('selectProject')
        }
    },

    computed: {
        isAvailable: () => {
            return this.data && this.data.type === 'available';
        }
    },

    components: { ProjectDataForm }
}
</script>

<style lang="scss" scoped>
    @import '../../assets/sass/toolkit.scss';
    @import '../../assets/sass/ui/modal.scss';
</style>
