import Vue from 'vue';

const triggerEvent = (el, type) => {
    if ('createEvent' in document) {
        // modern browsers, IE9+
        let e = document.createEvent('HTMLEvents');
        e.initEvent(type, false, true);
        el.dispatchEvent(e);
    } else {
        // IE 8
        let e = document.createEventObject();
        e.eventType = type;
        el.fireEvent('on'+e.eventType, e);
    }
}

const disableScroll = (e) => {
    if(document.activeElement === e.target) {
        e.preventDefault()
    }
}

export const ZeroHandling = {
    inserted: function (el) {
        el.onfocus = () => {
            let value = String(el.value)

            if (value === '0') {
                el.value = ''
            }
        }

        el.onmousewheel = disableScroll
        el.removeEventListener('DOMMouseScroll', disableScroll)
        el.addEventListener('DOMMouseScroll', disableScroll)

        el.onblur = () => {
            let value = String(el.value)
            let changed = false

            if (value === '') {
                value = 0
                changed = true
            }

            value = Number(value)

            let min = el.getAttribute('min')
            let max = el.getAttribute('max')
            let step = el.getAttribute('step')

            if(min !== null && value < Number(min)) {
                value = Number(min)
                changed = true
            }

            if(max !== null && value > Number(max)) {
                value = Number(max)
                changed = true
            }

            if(step !== null && step.indexOf('.') > -1) {
                step = Math.pow(10, `${step}`.split('.').pop().length)
                value = parseInt(value * step) / step
                changed = changed || value !== Number(el.value)
            }

            if(changed) {
                el.value = value
                triggerEvent(el, 'input')
            }
        }
    }
}

Vue.directive('zerohandling', ZeroHandling)

export const DisableMouseWheel = {
    inserted: function (el) {
        el.onmousewheel = disableScroll
        el.removeEventListener('DOMMouseScroll', disableScroll)
        el.addEventListener('DOMMouseScroll', disableScroll)
    }
}

Vue.directive('disable-mouse-wheel', DisableMouseWheel)

export const AutoFocus = {
    update: function (el) {
        if (!el.offsetParent) { // element not visible
            el.dataset.focused = false
        } else if (el.dataset.focused !== 'true') { // focus hasn't been forced yet
            el.dataset.focused = true
            el.focus()
        }
    }
}

Vue.directive('autofocus', AutoFocus)

export const AutocompleteOff = {
    counter: 1,
    update: function (el) {
        el.autocomplete = 'off'
        el.name = `input-${AutocompleteOff.counter++}-${new Date().getTime()}`
    }
}
Vue.directive('autocomplete-off', AutocompleteOff)
