<template>
    <sidebar-filter
        :elements="pages"
        :selected="selectedPage"
        @changed="sidebarChanged"
    ></sidebar-filter>
</template>

<script>
import Vue from 'vue'
import SidebarFilter from '../SidebarFilter.vue'
import {mapGetters} from "vuex";

export default {
    name: 'GroupAdminPages',

    props: ['title', 'group', 'selectedPage'],

    computed: {
        pages() {
            return !this.group ? [] : [
                {
                    name: '< Vissza',
                    handle: 'back',
                },
                {
                    name: this.group.name,
                    handle: null,
                    children: [
                        {
                            name: this.$t('group_type_product.page.list.sidebar'),
                            handle: this.group.subType === 'fix' ? 'GroupCategoryProductList' : 'GroupTypeProductList',
                        },
                        {
                            name: this.$t('group_category_product.page.list.sidebar'),
                            handle: 'GroupProductList',
                        },
                        ...this.group.subType === 'fix' ? [
                            {
                                name: this.$t('group_category_sort.sidebar'),
                                handle: 'GroupCategorySort',
                            }
                        ] : [
                            {
                                name: this.$t('group_category_product_type.page.list.sidebar'),
                                handle: 'GroupCategoryProductTypeList',
                            },
                            {
                                name: this.$t('group_category_sort.sidebar'),
                                handle: 'GroupCategorySort',
                            },
                            {
                                name: this.$t('group_type_variant.page.list.sidebar'),
                                handle: 'GroupTypeVariantList',
                            },
                            {
                                name: this.$t('group_type_sort.sidebar'),
                                handle: 'GroupTypeSort',
                            }
                        ]
                    ]
                }
            ]
        }
    },

    methods: {
        sidebarChanged(name) {
            if(name === 'back') {
                this.$router.push({name: 'ProductAdmin'})
            } else {
                this.$router.push({name, params: {groupId: this.group.id}})
            }
        }
    },

    components: {
        SidebarFilter,
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/layout/list-page.scss';
</style>
