<template>
    <div class="scroll-to-top-component">
        <button type="button" class="btn btn-plain btn-plain-wicon" @click="scrollTo">
            <md-icon>keyboard_arrow_up</md-icon>
        </button>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ScrollToTop',

    data() {
        return {}
    },

    methods: {
        scrollTo() {
            document.querySelector('.product-list').scrollTo({ top: 0, behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/toolkit.scss';
    @import '../assets/sass/ui/button.scss';

    .scroll-to-top-component {
        position: fixed;
        bottom: 110px;
        right:  70px;
        z-index: 45;
        opacity: .85;

        @media only screen and (max-width: 600px) {
            bottom: 185px;
            right: 10px
        }

        button {
            background: $color-white;
            width: 32px;
            height: 32px;
            border: 1px solid $color-gray-border;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

            i {
                margin: 0;
                transition: transform .3s;
            }

            &:hover {
                i {
                    transform: translateY(-3px);
                    transition: transform .3s;
                }
            }
        }
    }
</style>
