<template>
    <div class="region-list-page" v-if="userHasRegionAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new"></div>
            <div class="dashboard-header__filters">
                <div class="region-list-page__header-search-flex">
                    <div class="search-container">
                        <search :searchData="searchData" @searchPhrase="filterTable"></search>
                    </div>

                    <custom-filter
                        v-if="regionAdvisories.length > 0"
                        :options="regionAdvisories"
                        :value="filteredAdvisory"
                        :label="''"
                        @selected="filterAdvisory"
                    ></custom-filter>
                </div>

                <div class="region-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column" v-if="regionRows.length > 0">
            <vue-good-table
                :columns="tableColumns"
                :rows="getRegionRows()"
                @on-row-click="onRowClick">
                <template slot="table-row" slot-scope="props">
                    <span class="col-advisories" v-if="props.column.field === 'bramacAdvisory' && props.row.temporaryBramacAdvisory !== null">
                        <div>{{ props.row.bramacAdvisory }}</div>
                        <span class="small-line">(helyettesíti: {{ props.row.temporaryBramacAdvisory }})</span>
                    </span>
                    <span class="col-advisories" v-else-if="props.column.field === 'villasAdvisory' && props.row.temporaryVillasAdvisory !== null">
                        <div>{{ props.row.villasAdvisory }}</div>
                        <span class="small-line">(helyettesíti: {{ props.row.temporaryVillasAdvisory }})</span>
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] || '-' }}
                    </span>
                </template>
                <div slot="emptystate">{{ $t('region.page.list.text.empty_state') }}</div>
            </vue-good-table>
        </div>

        <modal-region-add
            :open="openRegionModal"
            :regionId="selectedRegion"
            @close="regionEditEnd">
        </modal-region-add>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import { MdIcon } from 'vue-material/dist/components'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalRegionAdd from '../components/modals/ModalRegionAdd.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'

Vue.use(MdIcon)
Vue.use(VueGoodTable)

export default {
    name: 'RegionList',

    data() {
        return {
            loading: true,
            openRegionModal: false,
            selectedRegion: null,
            filteredAdvisory: 0,

            searchData: {
                placeholder: this.$t('region.page.list.search.placeholder'),
                tooltipText: this.$t('region.page.list.search.tooltip'),
                countText: ''
            },

            searchPhrase: '',

            tableColumns: [
                {
                    label: this.$t('region.page.list.column.name'),
                    field: 'name',
                    width: '40%'
                },
                {
                    label: this.$t('region.page.list.column.bramac_advisory'),
                    field: 'bramacAdvisory',
                    width: '30%'
                },
                {
                    label: this.$t('region.page.list.column.villas_advisory'),
                    field: 'villasAdvisory',
                    width: '30%'
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasRegionAdmin) {
                this.$store.dispatch('FETCH_REGIONS').then(() => this.loading = false)
            } else {
                this.$router.push('/');
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    methods: {
        filterAdvisory(e) {
            this.filteredAdvisory = e.id
        },

        filterTable(phrase) {
            this.searchPhrase = phrase
        },

        getRegionRows() {
            let tableData = this.regionRows, searchPhrase

            if (this.searchPhrase !== '') {
                searchPhrase = this.searchPhrase.toLowerCase().split(/\s+/)
                tableData = tableData.filter(row => {
                    let valid = true
                    for (let value of searchPhrase) {
                        valid = valid && row.search.toLowerCase().indexOf(value) > -1
                    }
                    return valid
                })
            }

            if (this.filteredAdvisory !== 0) {
                tableData = tableData.filter(row => { return row.advisoryUserIds.includes(this.filteredAdvisory) })
            }

            this.searchData.countText = this.$tc('region.page.list.search.name', tableData.length)

            return tableData
        },

        onRowClick(params) {
            this.selectedRegion = params.row.id
            this.openRegionModal = true
        },

        regionEditEnd(refresh) {
            this.openRegionModal = false
            if (refresh) {
                this.refresh()
            }
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_REGIONS').then(() => this.loading = false)
        }
    },

    computed: {
        ...mapGetters(['regionRows', 'regionAdvisories', 'userHasRegionAdmin'])
    },

    components: {
        MainNavigation,
        BasicLinks,
        VueGoodTable,
        Search,
        Dropdown,
        ModalRegionAdd,
        ModalConfirmation,
        Notification,
        CustomFilter
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.region-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: 60%;
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .col-options {
        position: relative;
        width: 100px;
    }
}
</style>
