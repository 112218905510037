import Vue from 'vue'

Vue.filter('formatPrice', (value, wholeNumber) => {
    value = Math.round(value * 100) / 100
    value = value.toString()
    let [beforeComma, afterComma] = value.split('.', 2)
    if (afterComma === undefined || wholeNumber) {
        return beforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }

    return beforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ',' + afterComma
})
