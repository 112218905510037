<template>
    <div
        class="custom-datepicker-component"
        v-click-outside="() => {this.open = false}">
        <input
            type="text"
            v-model="dateString"
            readonly="readonly"
            @click="open = !open"
            class="input-date"
            :class="{ 'open': open }"
            :disabled="disabled">

        <button class="custom-datepicker-cancel" @click="date=null"><md-icon>close</md-icon></button>

        <md-icon class="icon-calendar" v-show="!disabled">calendar_today</md-icon>
        <span class="week-text" v-show="weekText && !disabled">{{ weekText }}</span>
        <date-pick
            v-model="date"
            v-show="open"
            :hasInputElement="false"
            :nextMonthCaption="lang.nextMonthCaption"
            :prevMonthCaption="lang.prevMonthCaption"
            :setTimeCaption="lang.setTimeCaption"
            :isDateDisabled="isPastDate"
            :weekdays="lang.weekdays"
            :months="lang.months"
        ></date-pick>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import moment from '../../plugins/moment'

// https://dbrekalo.github.io/vue-date-pick/
import DatePick from 'vue-date-pick'

Vue.use(MdIcon)

export default {
    name: 'Datepicker',
    props: {
        initDate: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        enablePassedDates: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    data() {
        return {
            date: '',
            open: false,
            lang: {
                nextMonthCaption: this.$t('common.datepicker.next_month_caption'),
                prevMonthCaption: this.$t('common.datepicker.prev_month_caption'),
                setTimeCaption: this.$t('common.datepicker.set_time_caption'),
                weekdays: [
                    this.$t('common.datepicker.weekdays.mo'),
                    this.$t('common.datepicker.weekdays.tu'),
                    this.$t('common.datepicker.weekdays.we'),
                    this.$t('common.datepicker.weekdays.th'),
                    this.$t('common.datepicker.weekdays.fr'),
                    this.$t('common.datepicker.weekdays.sa'),
                    this.$t('common.datepicker.weekdays.su'),
                ],
                months: [
                    this.$t('common.datepicker.months.jan'),
                    this.$t('common.datepicker.months.feb'),
                    this.$t('common.datepicker.months.mar'),
                    this.$t('common.datepicker.months.apr'),
                    this.$t('common.datepicker.months.may'),
                    this.$t('common.datepicker.months.jun'),
                    this.$t('common.datepicker.months.jul'),
                    this.$t('common.datepicker.months.aug'),
                    this.$t('common.datepicker.months.sep'),
                    this.$t('common.datepicker.months.oct'),
                    this.$t('common.datepicker.months.nov'),
                    this.$t('common.datepicker.months.dec')
                ]
            }
        }
    },

    watch: {
        date: function(val, old) {
            if(val == null){
                this.$emit('changed', '')
            }
            if (this.open === true) {
                this.$emit('changed', val)
                this.open = false
            }
        },

        'initDate': function(val) {
            if (val !== null) {
                this.date = val
            }
        },
    },

    mounted() {
        this.date = this.initDate;
    },

    methods: {
        isPastDate(date) {
            if(this.enablePassedDates == true){
                let disabledToDate = new Date(moment());
                return false;
            }else{
                let disabledToDate = new Date(moment());
                return date < disabledToDate
            }
        }
    },

    computed: {
        dateString() {
            if (this.date === null) {
                return ''
            }

            return moment(this.date, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range_without_year')))
        },

        weekText() {
            if (this.date === 'Invalid date') {
                return ''
            }

            return null
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: { DatePick }
}
</script>

<style lang="scss">
@import '../../../node_modules/vue-date-pick/dist/vueDatePick.css';
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/datepick.scss';

.custom-datepicker-component {
    position: relative;
    border: 1px solid #CBCBCB!important;
    border-radius: 3px !important;

    & input{
        border: none!important;
        margin-right: 35px;
        padding-right: 0;
        width: 110px;
    }
}

.custom-datepicker-cancel{
    position: absolute;
    top: 15px;
    right: 2px;
}
</style>
