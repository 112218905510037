<template>
    <div class="order-page" v-if="userAuthenticated">
        <job-navigation :loading="loading" :isOrder="true" @saveOffer="saveOrder" @closePage="closePage()"
                        :viewOnly="viewOnly"></job-navigation>
        <div class="product-list" :class="{'multilevel-products': orderData.multiLevel}" v-show="!loading">
            <div class="product-list-category-tabs" v-if="orderData.variants">
                <ul>
                    <li
                        v-for="(tab, index) in orderDataTabs"
                        :key="index"
                        :class="{'active' : index === selectedOrderDataTab}">
                        <button @click="selectCategory(index)">{{ tab.title }}</button>
                    </li>
                    <li v-click-outside="() => {this.openNewOrderDropdown = false}">
                        <button class="add" @click="addNewCategory()">
                            <md-icon>add</md-icon>
                            <span>{{ $t('order.page.edit.button.add_new_category') }}</span>
                        </button>
                        <product-type-dropdown
                            :open="openNewOrderDropdown"
                            :productTypes="orderInformation.otherGroups"
                            @selectedType="addNewCategory">
                        </product-type-dropdown>
                    </li>
                </ul>
            </div>
            <div
                class="product-list-settings"
                v-if="orderData.groupTypes || orderData.variantType || orderData.hasCalculation || orderData.basicInformation.length > 0"
            >
                <div class="col-category" v-if="orderData.groupTypes">
                    <span class="label">{{ $t('order.page.edit.field.groups') }}</span>
                    <custom-group-select
                        v-if="orderData.groupTypes.grouped"
                        :options="orderData.groupTypes.groups"
                        :selectedItem="orderData.selectedGroupType"
                        @changed="updateGroupTypeSelect"
                        :disabled="viewOnly">
                    </custom-group-select>
                    <custom-select
                        v-if="!orderData.groupTypes.grouped"
                        :large="true"
                        :options="orderData.groupTypes.options"
                        :selectedItem="orderData.selectedGroupType"
                        @changed="updateGroupTypeSelect"
                        :disabled="viewOnly">
                        >
                    </custom-select>
                </div>

                <div class="col-color" v-if="orderData.variantType">
                    <template v-if="orderData.variants">
                        <span class="label">{{ orderData.variantType }}</span>
                        <custom-select
                            :large="true"
                            :options="orderData.variants"
                            :selectedItem="orderData.selectedVariant"
                            @changed="updateVariant"
                            :disabled="viewOnly"
                        ></custom-select>
                    </template>
                    <div class="checkbox" v-if="orderData.differentColors !== null && !orderData.restricted">
                        <input
                            type="checkbox"
                            :checked="orderData.differentColors"
                            @input="updateDifferentColors"
                            id="color"
                            :disabled="viewOnly"
                        >
                        <label for="color">{{ $t('order.page.edit.field.different_colors') }}</label>
                    </div>
                </div>

                <div class="col-shipment-types" v-if="allTransportsInTabs.length">
                    <span class="label">Elérhető szállítási módok</span>
                    <div class="shipment-types">
                        <div :key="transport.id" v-for="(transport) in allTransportsInTabs">
                            <span>
                                {{ transport.name }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-handle-category">
                    <button v-if="orderDataTabs.length > 1" @click="openDeleteConfirmationModal = true">
                        <md-icon>delete</md-icon>
                        <span>{{ $t('order.page.edit.button.delete_category') }}</span></button>
                </div>

            </div>

            <div v-else-if="allTransportsInTabs.length" class="col-shipment-types custom">
                <span class="label">Elérhető szállítási módok</span>
                <div class="shipment-types">
                    <div :key="transport.id" v-for="(transport) in allTransportsInTabs">
                            <span>
                                {{ transport.name }}
                            </span>
                    </div>
                </div>
            </div>

            <div class="product-list-body">
                <div class="product-list-body-filters">
                    <input
                        v-model="search"
                        @input="searchInProducts"
                        placeholder="Keresés"
                        style="margin-right:15px"
                        class="small"
                    >

                    <div class="checkbox" style="margin-right:15px">
                        <input type="checkbox" v-model="showProductNumbers" id="show-product-numbers">
                        <label for="show-product-numbers">{{ $t('order.page.edit.field.show_product_numbers') }}</label>
                    </div>
                    <div class="checkbox">
                        <input type="checkbox" v-model="showOnlyAdded" id="show-added">
                        <label for="show-added">{{ $t('order.page.edit.field.show_only_added') }}</label>
                    </div>
                    <div class="input-switchers" v-if="orderData.multiLevel">
                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : showInputs, 'btn-simple' : !showInputs}"
                            @click="showInputs = true"
                        >{{ $t('order.page.edit.option.field.input') }}
                        </button>
                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : !showInputs, 'btn-simple' : showInputs}"
                            @click="showInputs = false"
                        >{{ $t('order.page.edit.option.field.additional') }}
                        </button>
                    </div>
                </div>

                <div v-if="!search">
                    <div
                        class="category-container"
                        v-for="category in categories"
                        :key="category.id"
                        :data-id="category.id"
                        :class="getCategoryClasses(category)"
                    >
                        <button
                            @click="toggleChildren(category)"
                            v-show="(category.children.length > 0 || (category.depth < 3 && category.products.length > 0) ) && (!showOnlyAdded || hasAddedProducts(category)) && orderData.multiLevel"
                            class="btn btn-plain btn-plain-wicon button-collapse">
                            <md-icon>keyboard_arrow_down</md-icon>
                        </button>
                        <span
                            @click="toggleChildren(category)"
                            v-show="!showOnlyAdded || hasAddedProducts(category)"
                            class="category-name"
                        >{{ category.name }}</span>
                        <div class="line-recommended"
                             v-show="category.recommended && ( !showOnlyAdded || hasAddedProducts(category) )">
                            {{ $t('order.page.edit.text.recommended.category') }}
                        </div>


                        <table class="table-products"
                               v-show="(!showOnlyAdded || hasAddedProducts(category)) && category.products.length > 0">
                            <thead>
                            <tr>
                                <th class="th-name">{{ $t('order.page.edit.column.product') }}</th>
                                <th class="th-color">&nbsp;</th>
                                <th class="th-property"
                                    v-if="showColumn('packing', category.properties, showInputs, true)">
                                    {{ $t('order.page.edit.column.packing') }}
                                </th>
                                <th class="th-property"
                                    v-if="showColumn('carrier', category.properties, showInputs, false)">
                                    {{ $t('order.page.edit.column.carrier') }}
                                </th>
                                <th class="th-property"
                                    v-if="showColumn('surface', category.properties, showInputs, false)">
                                    {{ $t('order.page.edit.column.surface') }}
                                </th>
                                <th class="th-property"
                                    v-if="showColumn('thickness', category.properties, showInputs, false)">
                                    {{ $t('order.page.edit.column.thickness') }}<span class="subtext">mm</span></th>
                                <th class="th-property"
                                    v-if="showColumn('weight', category.properties, showInputs, false)">
                                    {{ $t('order.page.edit.column.weight') }}<span class="subtext">kg/m²</span></th>
                                <th class="th-amount">{{ $t('order.page.edit.column.amount') }}</th>
                                <th class="th-dealer-discount" v-show="(discountType === 3) || (discountType === 4)"
                                    v-html="discountColumn"></th>
                                <th class="th-icons" v-if="category.hasWarnings">&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="product in category.products"
                                :key="product.id"
                                :class="getRowClasses(product)">
                                <td :colspan="!orderData.differentColors || orderData.restricted  || !product.mainColor ? 2 : null">
                                    <div class="col-name col-name--with-select" v-if="product.selectable">
                                        <custom-select
                                            v-if="product.selectable"
                                            :large="true"
                                            :width="300"
                                            :options="product.options"
                                            :selectedItem="{...product, name: showProductNumbers ? product.nameWithProductNumber : product.name}"
                                            @changed="(el) => changeSelectableProduct(product.type, el)"
                                            :disabled="viewOnly">
                                            >
                                        </custom-select>
                                        <md-icon
                                            v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                            v-if="product.info">info
                                        </md-icon>
                                    </div>
                                    <div class="col-name" v-else>
                                        <span class="text">
                                            <span class="adr-product" v-if="product.is_adr">ADR</span>
                                            {{ showProductNumbers ? product.nameWithProductNumber : product.name }}
                                            <span class="deleted-product" v-if="product.isDeleted">FIGYELEM TÖRÖLT TERMÉK!</span>
                                            <template
                                                v-if="recommendedProductId === product.id">{{
                                                    $t('order.page.edit.text.recommended.product')
                                                }}
                                            </template>
                                            <md-icon
                                                v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                                v-if="product.info">info</md-icon>
                                        </span>
                                        <span class="text-secondary">{{ product.subText }}</span>
                                    </div>
                                </td>
                                <td v-if="orderData.differentColors && product.mainColor && !orderData.restricted">
                                    <div class="col-color">
                                        <button
                                            v-if="!isSecondaryColors(product.type) && !viewOnly"
                                            type="button"
                                            class="btn btn-plain"
                                            @click="addSecondaryColor(product.type)"
                                        >
                                            <md-icon>arrow_drop_down</md-icon>
                                            <span>{{ $t('order.page.edit.button.in_other_colors') }}</span>
                                        </button>

                                        <button
                                            v-if="isSecondaryColors(product.type) && !viewOnly"
                                            type="button"
                                            class="btn btn-plain"
                                            @click="removeSecondaryColor(product.type)"
                                        >
                                            <md-icon>arrow_drop_up</md-icon>
                                            <span>{{ $t('order.page.edit.button.only_in_one_color') }}</span>
                                        </button>
                                    </div>
                                </td>
                                <td v-if="showColumn('packing', category.properties, showInputs, true)">
                                    <div class="col-packing col-info">
                                        <span class="text">
                                            {{ product.properties.packing }}
                                        </span>
                                    </div>
                                </td>
                                <td v-if="showColumn('carrier', category.properties, showInputs, false)">
                                    <div class="col-carrier col-info">
                                        <span class="text">
                                            {{ product.properties.carrier }}
                                        </span>
                                    </div>
                                </td>
                                <td v-if="showColumn('surface', category.properties, showInputs, false)">
                                    <div class="col-surface col-info">
                                        <span class="text">
                                            {{ product.properties.surface }}
                                        </span>
                                    </div>
                                </td>
                                <td v-if="showColumn('thickness', category.properties, showInputs, false)">
                                    <div class="col-thickness col-info">
                                        <span class="text">
                                            {{ product.properties.thickness }}
                                        </span>
                                    </div>
                                </td>
                                <td v-if="showColumn('weight', category.properties, showInputs, false)">
                                    <div class="col-weight col-info">
                                        <span class="text">
                                            {{ product.properties.weight }}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-amount">
                                        <div>
                                            <input
                                                :value="product.amount"
                                                :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                :step="getStep(product)"
                                                @input="updateAmount($event.target.value, product, false)"
                                                @blur="updateAmount($event.target.value, product, true)"
                                                type="number"
                                                class="small"
                                                min="0"
                                                v-zerohandling
                                                :data-id="product.id"
                                                :disabled="viewOnly || !product.editable || product.isDeleted"
                                            >
                                            <span v-if="product.units.length === 1">{{ product.amountUnit.name }}</span>
                                            <custom-select
                                                v-else
                                                :options="product.units"
                                                :selectedItem="product.amountUnit"
                                                @changed="changeUnit($event, product)"
                                                :disabled="viewOnly || !product.editable || product.isDeleted"
                                                class="unit-selector">
                                            </custom-select>
                                            <span v-if="product.squareMeter && !product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                    round(product.squareMeter * product.amount * product.amountUnit.multiplier, 3)
                                                }} m²</span>
                                            <span v-if="product.squareMeter && product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                    Math.round(product.amount / product.squareMeter)
                                                }} {{ product.listPrice.unitName }}
                                        </span>
                                        </div>
                                        <div class="amount-info" v-if="product.amountUnit.info">{{
                                                product.amountUnit.info
                                            }}
                                        </div>
                                    </div>
                                </td>
                                <td v-show="(discountType === 3) || (discountType === 4)">
                                    <div class="col-dealer">
                                        <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                            <div class="sub-discounts-part"
                                                 v-for="subDiscount in product.listPrice.subDiscounts"
                                                 :key="subDiscount.id">
                                                <label
                                                    :for="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                        subDiscount.name
                                                    }}</label>
                                                <div class="input-part">
                                                    <input
                                                        :id="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`"
                                                        type="number"
                                                        class="small"
                                                        step='0.01'
                                                        min="0"
                                                        max="100"
                                                        :value="product.dealerDiscount[subDiscount.id]"
                                                        @input="updateDealerDiscount($event, product, subDiscount.id)"
                                                        :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                        v-zerohandling
                                                        :disabled="discountType === 3 || viewOnly || product.isDeleted"
                                                    >
                                                    <span>%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="input-part">
                                            <input
                                                type="number"
                                                class="small"
                                                step='0.01'
                                                min="0"
                                                max="100"
                                                :value="product.dealerDiscount"
                                                @input="updateDealerDiscount($event, product)"
                                                :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                v-zerohandling
                                                :disabled="discountType === 3 || viewOnly"
                                            >
                                            <span>%</span>
                                        </div>
                                    </div>
                                </td>
                                <td v-if="product.warnings">
                                    <button>
                                        <md-icon>history</md-icon>
                                    </button>
                                    <button>
                                        <md-icon>highlight_off</md-icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-else>
                    <table class="table-products">
                        <thead>
                        <tr>
                            <th class="th-name">{{ $t('order.page.edit.column.product') }}</th>
                            <th class="th-color">&nbsp;</th>
                            <th class="th-property">
                                {{ $t('order.page.edit.column.packing') }}
                            </th>
                            <th class="th-property">
                                {{ $t('order.page.edit.column.carrier') }}
                            </th>
                            <th class="th-property">
                                {{ $t('order.page.edit.column.surface') }}
                            </th>
                            <th class="th-property">
                                {{ $t('order.page.edit.column.thickness') }}<span class="subtext">mm</span></th>
                            <th class="th-property">
                                {{ $t('order.page.edit.column.weight') }}
                                <span class="subtext">kg/m²</span>
                            </th>
                            <th class="th-amount">{{ $t('order.page.edit.column.amount') }}</th>
                            <th class="th-dealer-discount" v-if="(discountType === 3) || (discountType === 4)"
                                v-html="discountColumn"></th>
                            <th v-else></th>
                            <th class="th-icons">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="product in results"
                            :key="product.id"
                            :class="getRowClasses(product)">
                            <td :colspan="!orderData.differentColors || orderData.restricted  || !product.mainColor ? 2 : null">
                                <div class="col-name col-name--with-select" v-if="product.selectable">
                                    <custom-select
                                        v-if="product.selectable"
                                        :large="true"
                                        :width="300"
                                        :options="product.options"
                                        :selectedItem="{...product, name: showProductNumbers ? product.nameWithProductNumber : product.name}"
                                        @changed="(el) => changeSelectableProduct(product.type, el)"
                                        :disabled="viewOnly">
                                        >
                                    </custom-select>
                                    <md-icon
                                        v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                        v-if="product.info">info
                                    </md-icon>
                                </div>
                                <div class="col-name" v-else>
                                        <span class="text">
                                            {{ showProductNumbers ? product.nameWithProductNumber : product.name }}
                                            <span class="deleted-product" v-if="product.isDeleted">FIGYELEM TÖRÖLT TERMÉK!</span>
                                            <template
                                                v-if="recommendedProductId === product.id">{{
                                                    $t('order.page.edit.text.recommended.product')
                                                }}
                                            </template>
                                            <md-icon
                                                v-tooltip="{content: setLineBreak(product.info), placement: 'right-start', classes: 'tooltip-col-name'}"
                                                v-if="product.info">info</md-icon>
                                        </span>
                                    <span class="text-secondary">{{ product.subText }}</span>
                                </div>
                            </td>
                            <td v-if="orderData.differentColors && product.mainColor && !orderData.restricted">
                                <div class="col-color">
                                    <button
                                        v-if="!isSecondaryColors(product.type) && !viewOnly"
                                        type="button"
                                        class="btn btn-plain"
                                        @click="addSecondaryColor(product.type)"
                                    >
                                        <md-icon>arrow_drop_down</md-icon>
                                        <span>{{ $t('order.page.edit.button.in_other_colors') }}</span>
                                    </button>

                                    <button
                                        v-if="isSecondaryColors(product.type) && !viewOnly"
                                        type="button"
                                        class="btn btn-plain"
                                        @click="removeSecondaryColor(product.type)"
                                    >
                                        <md-icon>arrow_drop_up</md-icon>
                                        <span>{{ $t('order.page.edit.button.only_in_one_color') }}</span>
                                    </button>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="showColumn('packing', product.category.properties, showInputs, true)">
                                <div class="col-packing col-info">
                                        <span class="text">
                                            {{ product.properties.packing }}
                                        </span>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="showColumn('carrier', product.category.properties, showInputs, false)">
                                <div class="col-carrier col-info">
                                        <span class="text">
                                            {{ product.properties.carrier }}
                                        </span>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="showColumn('surface', product.category.properties, showInputs, false)">
                                <div class="col-surface col-info">
                                        <span class="text">
                                            {{ product.properties.surface }}
                                        </span>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="showColumn('thickness', product.category.properties, showInputs, false)">
                                <div class="col-thickness col-info">
                                        <span class="text">
                                            {{ product.properties.thickness }}
                                        </span>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="showColumn('weight', product.category.properties, showInputs, false)">
                                <div class="col-weight col-info">
                                        <span class="text">
                                            {{ product.properties.weight }}
                                        </span>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td>
                                <div class="col-amount">
                                    <div>
                                        <input
                                            :value="product.amount"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            :step="getStep(product)"
                                            @input="updateAmount($event.target.value, product, false)"
                                            @blur="updateAmount($event.target.value, product, true)"
                                            type="number"
                                            class="small"
                                            min="0"
                                            v-zerohandling
                                            :data-id="product.id"
                                            :disabled="viewOnly || !product.editable || product.isDeleted"
                                        >
                                        <span v-if="product.units.length === 1">{{ product.amountUnit.name }}</span>
                                        <custom-select
                                            v-else
                                            :options="product.units"
                                            :selectedItem="product.amountUnit"
                                            @changed="changeUnit($event, product)"
                                            :disabled="viewOnly || !product.editable || product.isDeleted"
                                            class="unit-selector">
                                        </custom-select>
                                        <span v-if="product.squareMeter && !product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                round(product.squareMeter * product.amount * product.amountUnit.multiplier, 3)
                                            }} m²</span>
                                        <span v-if="product.squareMeter && product.amountUnit.isSquareMeter"> | &nbsp;{{
                                                Math.round(product.amount / product.squareMeter)
                                            }} {{ product.listPrice.unitName }}
                                        </span>
                                    </div>
                                    <div class="amount-info" v-if="product.amountUnit.info">{{
                                            product.amountUnit.info
                                        }}
                                    </div>
                                </div>
                            </td>
                            <td v-if="(discountType === 3) || (discountType === 4)">
                                <div class="col-dealer">
                                    <div class="sub-discounts" v-if="product.listPrice.subDiscounts.length > 0">
                                        <div class="sub-discounts-part"
                                             v-for="subDiscount in product.listPrice.subDiscounts"
                                             :key="subDiscount.id">
                                            <label :for="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`">{{
                                                    subDiscount.name
                                                }}</label>
                                            <div class="input-part">
                                                <input
                                                    :id="`product_dealer_sub_discount_${product.id}_${subDiscount.id}`"
                                                    type="number"
                                                    class="small"
                                                    step='0.01'
                                                    min="0"
                                                    max="100"
                                                    :value="product.dealerDiscount[subDiscount.id]"
                                                    @input="updateDealerDiscount($event, product, subDiscount.id)"
                                                    :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                                    v-zerohandling
                                                    :disabled="discountType === 3 || viewOnly || product.isDeleted"
                                                >
                                                <span>%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="input-part">
                                        <input
                                            type="number"
                                            class="small"
                                            step='0.01'
                                            min="0"
                                            max="100"
                                            :value="product.dealerDiscount"
                                            @input="updateDealerDiscount($event, product)"
                                            :tabindex="{ '-1' : !showOnlyAdded && product.amount < 1}"
                                            v-zerohandling
                                            :disabled="discountType === 3 || viewOnly"
                                        >
                                        <span>%</span>
                                    </div>
                                </div>
                            </td>
                            <td v-else> -</td>
                            <td v-if="product.warnings">
                                <button>
                                    <md-icon>history</md-icon>
                                </button>
                                <button>
                                    <md-icon>highlight_off</md-icon>
                                </button>
                            </td>
                            <td v-else> -</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <scroll-to-top></scroll-to-top>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <panel-total
            :loading="loading"
            :data="{orderProductsAmount: orderProductsAmount }"
            :discountType="discountType"
            @exportData="()=>{return null}"
            @nextStep="goToDetails(true)"
        ></panel-total>

        <discount-navigation></discount-navigation>
        <discount-bar :loading="loading"></discount-bar>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="saveOrder(true)"
            @close="openConfirmationModal = false"
            @secondaryAction="exitOrder"
        ></modal-confirmation>

        <modal-confirmation
            :open="openDeleteConfirmationModal"
            :data="deleteConfirmationModalData"
            @accepted="removeCategory()"
            @close="openDeleteConfirmationModal = false"
        ></modal-confirmation>

        <modal-guarantee
            :isOrder="true"
            :open="showGuaranteeAlert"
            :from-order="true"
            :modal-text="guaranteeModalText"
            @goToOrder="goToDetails"
            @close="showGuaranteeAlert = false"/>
        <notification></notification>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import JobNavigation from '../components/navigations/JobNavigation.vue'
import DiscountNavigation from '../components/navigations/DiscountNavigation.vue'
import CustomSelect from '../components/dropdowns/CustomSelect.vue'
import CustomGroupSelect from '../components/dropdowns/CustomGroupSelect.vue'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'
import PanelTotal from '../components/PanelTotal.vue'
import DiscountBar from '../components/sidebars/DiscountBar.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import ScrollToTop from '../components/ScrollToTop.vue'
import ModalGuarantee from '../components/modals/ModalGuarantee.vue'
import VTooltip from 'v-tooltip'

import {getProductsWithValue, hShowColumn, loadGroupTransports, round} from '@/plugins/helpers'
import {ZeroHandling} from '@/plugins/directives'
import vClickOutside from 'v-click-outside'
import Vue from 'vue'
import Decimal from 'decimal.js'
import {check} from "@desislavsd/vue-select";

Vue.use(VTooltip)

export default {
    name: 'Order',

    data() {
        return {
            showGuaranteeAlert: false,
            guaranteeModalText: null,
            search: '',
            results: [],
            loading: true,
            openNewOrderDropdown: false,
            showProductNumbers: false,
            showOnlyAdded: false,
            forceShowWhenOnlyAdded: [],
            isNew: false,
            showInputs: true,
            openConfirmationModal: false,
            openDeleteConfirmationModal: false,
            openedCategories: [],
            categoryMap: {},
            confirmationModalData: {
                acceptText: this.$t('order.page.edit.confirmation.save.ok'),
                cancelText: this.$t('order.page.edit.confirmation.save.cancel'),
                secondaryActionText: this.$t('order.page.edit.confirmation.save.secondary'),
                message: this.$t('order.page.edit.confirmation.save.question')
            },
            deleteConfirmationModalData: {
                acceptText: this.$t('order.page.edit.confirmation.delete_category.ok'),
                cancelText: this.$t('order.page.edit.confirmation.delete_category.cancel'),
                message: this.$t('order.page.edit.confirmation.delete_category.question')
            },
            categoryDepthPosition: null
        }
    },

    directives: {
        clickOutside: vClickOutside.directive,
        ZeroHandling
    },

    watch: {
        '$route'() {
            this.fetchOrderData()
        },

        showOnlyAdded: {
            handler: function (val) {
                if (val) {
                    this.openedCategories = this.calculateOpenedCategories()
                    this.forceShowWhenOnlyAdded = []
                }
            }
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.showOnlyAdded = false
            this.fetchOrderData()
            this.$store.dispatch('FETCH_ORDER_PRODUCT_GROUPS')
        }).catch((redirect) => {
            redirect && this.$router.push({name: 'OrderList'})
        })
    },

    methods: {
        searchInProducts() {
            this.results = [];

            this.categories.forEach((category) => {
                const filteredProducts = category.products.filter((product) => {
                    product.category = category;
                    return product.nameWithProductNumber.toLowerCase().includes(this.search.toLowerCase());
                });

                this.results = [...this.results, ...filteredProducts];
            });
        },

        closePage() {
            if (this.viewOnly || this.loading) {
                this.$router.push({name: 'OrderList'})
            }

            this.openConfirmationModal = true
        },

        updateGroupTypeSelect(el) {
            let newData = {
                element: el,
                handle: 'groupType',
                jobType: 'order',
                groupId: this.orderDataTabs[this.selectedOrderDataTab].groupId,
                orderTabId: this.orderDataTabs[this.selectedOrderDataTab].id,
            }
            this.$store.dispatch('UPDATE_CATEGORY_LIST', newData)
        },

        changeSelectableProduct(type, el) {
            const payload = {
                type,
                id: el.id
            }

            this.$store.commit('updateSelectableProductSelected', payload)
        },

        updateVariant(el) {
            let newData = {
                element: el,
                handle: 'variant',
                jobType: 'order',
                groupId: this.orderDataTabs[this.selectedOrderDataTab].groupId,
                orderTabId: this.orderDataTabs[this.selectedOrderDataTab].id
            }
            this.$store.dispatch('UPDATE_CATEGORY_LIST', newData)
        },

        updateDifferentColors(e) {
            this.$store.commit('updateDifferentColors', e.target.checked)
        },

        isSecondaryColors(type) {
            return this.orderData.activeProductTypes.includes(type)
        },

        hasAddedProducts(category) {
            let hasAdded

            hasAdded = category.products.some(element => {
                return element.amount > 0 || this.forceShowWhenOnlyAdded.includes(element.id)
            })

            if (!hasAdded) {
                hasAdded = category.allChildren.some(id => {
                    if (this.categoryMap[id] && this.categoryMap[id].products.length > 0) {
                        return this.categoryMap[id].products.some(element => {
                            return element.amount > 0 || this.forceShowWhenOnlyAdded.includes(element.id)
                        })
                    }
                    return false
                })
            }

            return hasAdded
        },

        toggleChildren(category) {
            if (!this.orderData.multiLevel) {
                return true
            }

            if (this.openedCategories.includes(category.id)) {
                this.openedCategories = this.openedCategories.filter(id => id !== category.id && !category.allChildren.includes(id))
            } else {
                this.openedCategories.push(category.id)
            }
        },

        checkGuarantee() {
            this.showGuaranteeAlert = false;
            this.$store.dispatch('CHECK_ORDER_FOR_GUARANTEES', {orderId: this.orderInformation.id}).then((data) => {
                if (data) {
                    if (data.hasSpecificItems && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }

                    if (data.hasSpecificItems == false && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }
                }
            })
        },

        saveOrder(exit = false) {
            this.$store.commit('closeAllSettingsSidebars')

            let message = {
                orderId: this.orderInformation.id,
                selectedOrderDataTab: this.selectedOrderDataTab,
                nextStep: false,
                checkGuarantee: !exit && this.orderInformation.id, // not exiting and has order id (so not a brand new order)
            }

            this.$store.dispatch('SEND_ORDER', message).then((data) => {
                if (data && message.checkGuarantee) {
                    if (data.hasSpecificItems && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }

                    if (data.hasSpecificItems == false && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }
                }

                if (exit) {
                    this.$router.push({name: 'OrderList'})
                }
            })
        },

        exitOrder() {
            this.$router.push({name: 'OrderList'})
        },

        async fetchOrderData() {
            this.loading = true
            let ids = {
                groupId: this.$route.params.groupId || null,
                orderId: this.$route.params.orderId || null,
            }

            await this.$store.dispatch('FETCH_ORDER_DATA', ids)
                .then(async (res) => {
                    if (res === true) {
                        this.isNew = this.orderInformation.isNew
                        if (this.orderData.multiLevel === true) {
                            this.categoryMap = this.categories.reduce((map, c) => {
                                map[c.id] = c
                                return map
                            }, {})
                            this.openedCategories = this.calculateOpenedCategories()
                        }
                    }
                })
                .then(async () => {
                    await this.$store.dispatch('FETCH_GROUP_TRANSPORTS')
                    this.$store.state.activeOrderTab = this.orderDataTabs[this.selectedOrderDataTab].groupId
                })
                .then(async () => await this.$store.commit('calculateTransportsDataOrder'))
                .finally(() => this.loading = false)
                .catch(() => {
                    this.$store.commit('setNotification', [this.$t('order.page.edit.notification.load_failed')])
                    this.$router.push({name: 'OrderList'})
                })

            // check guarantee on load
            // comes from actions.js SEND_ORDER -> router.replace
            if (this.$route.params.checkGuarantee) {
                this.checkGuarantee()
            }
        },

        goToDetails(checkGuarantee = false) {
            this.$store.commit('closeAllSettingsSidebars')
            this.$store.commit('resetOrderDetails')

            let message = {
                orderId: this.orderInformation.id,
                selectedOrderDataTab: this.selectedOrderDataTab,
                nextStep: true,
                checkGuarantee: checkGuarantee,
            }

            this.showGuaranteeAlert = false
            this.$store.dispatch('SEND_ORDER', message).then(data => {
                if (data && message.checkGuarantee) {
                    if (data.hasSpecificItems && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }

                    if (data.hasSpecificItems == false && data.showGuaranteeAlert) {
                        this.showGuaranteeAlert = true;
                        this.guaranteeModalText = data.modalText;
                    }
                }
            })
        },

        // Table methods
        showColumn(property, propertyList, hideProperties, isSwitchable) {
            return hShowColumn(property, propertyList, hideProperties, isSwitchable)
        },

        addSecondaryColor(type) {
            let newData = {
                type: Number(type),
                add: true
            }
            this.$store.commit('updateActiveProductTypes', newData)
        },

        removeSecondaryColor(type) {
            let newData = {
                type: Number(type),
                add: false
            }
            this.$store.commit('updateActiveProductTypes', newData)
            this.$store.commit('clearSecondaryProductValueByType', newData.type)
        },

        getRowClasses(row) {
            let rowClassList = ''
            rowClassList += this.forceShowWhenOnlyAdded.includes(row.id) ? 'force-show ' : ''
            rowClassList += row.amount < 1 ? 'inactive ' : ''
            rowClassList += (row.mainColor === true && (this.isSecondaryColors(row.type) || row.fixOpen)) ? 'is-main-color-open ' : ''
            rowClassList += (row.mainColor === false) ? 'is-secondary-color ' : ''
            rowClassList += (row.recommended || this.recommendedProductId === row.id) ? 'is-recommended ' : ''
            rowClassList += (row.mainColor === false && !this.isSecondaryColors(row.type) && !row.forceShow) || (this.showOnlyAdded && row.amount === 0) ? 'is-hidden ' : ''

            return rowClassList
        },

        getCategoryClasses(category) {
            let categoryClassList = `depth-${category.depth} `
            let hasProducts = this.orderData.multiLevel && category.products.length > 0
            let hasCollapseTable = this.orderData.multiLevel && category.depth < 3 && category.products.length > 0
            let opened = this.orderData.multiLevel && this.openedCategories.includes(category.id)
            let parentOpened = this.orderData.multiLevel && this.openedCategories.includes(category.parent)

            categoryClassList += this.orderData.multiLevel && category.products.length < 1 ? 'no-products ' : ''
            categoryClassList += hasCollapseTable ? 'has-collapse-table ' : ''
            categoryClassList += hasProducts > 0 ? 'has-products ' : ''
            categoryClassList += category.products.filter(p => p.listPrice.subDiscounts.length > 0).length ? 'has-sub-discounts ' : ''

            categoryClassList += (opened || parentOpened) && category.depth > 0 ? 'open ' : ''
            categoryClassList += opened && !hasProducts ? 'children-open ' : ''
            categoryClassList += opened && hasCollapseTable ? 'open-table ' : ''

            categoryClassList += this.showOnlyAdded && !this.hasAddedProducts(category) ? 'is-hidden ' : ''

            return categoryClassList
        },

        calculateOpenedCategories() {
            let productInputs = document.querySelectorAll('.product-list .has-products .col-amount input[data-id]')

            let openedCategories = {}

            productInputs.forEach(e => {
                let productId
                if (e.value > 0) {
                    productId = Number(e.dataset.id)
                    this.categories.forEach(category => {
                        if (category.products.some(p => p.id === productId)) {
                            openedCategories[category.id] = 1
                            category.parents.forEach(id => {
                                openedCategories[id] = 1
                            })
                        }
                    })
                }
            })

            let res = Object.keys(openedCategories).map(id => Number(id))
            return res
        },

        getStep(product) {
            if (product.amount > product.amountUnit.roundAbove) {
                return product.amountUnit.step
            }

            return null
        },

        updateAmount(value, row, onBlur) {
            let newValue = Number(value)

            if (onBlur && newValue > row.amountUnit.roundAbove) {
                // newValue = Math.ceil(newValue / row.amountUnit.step) * row.amountUnit.step
                newValue = parseFloat((new Decimal(newValue)).dividedBy(row.amountUnit.step).ceil().mul(row.amountUnit.step).toFixed())
            }

            let newData = {
                id: row.id,
                unit: row.amountUnit,
                value: newValue
            }

            if (newValue === 0 && this.showOnlyAdded) {
                this.forceShowWhenOnlyAdded.push(row.id)
            }

            this.$store.commit('updateProductAmount', newData)
            this.$store.commit('calculateTransportsDataOrder')

            if (row.linkedProducts) {
                for (let productId in row.linkedProducts) {
                    if (row.linkedProducts.hasOwnProperty(productId)) {
                        let linkedProduct = null

                        this.categories.every(category => {
                            category.products.every(product => {
                                if (product.id === Number(productId)) {
                                    linkedProduct = product
                                    return false
                                }
                                return true
                            })
                            return !linkedProduct
                        })

                        if (linkedProduct) {
                            this.updateAmount(newValue * row.amountUnit.multiplier * row.linkedProducts[productId], linkedProduct, onBlur)
                        }
                    }
                }
            }
        },

        changeUnit(e, prod) {
            prod.amountUnit = e

            this.updateAmount(prod.amount, prod, true)
        },

        updateDealerDiscount(e, row, subDiscountId = null) {
            let newValue = e.target.value

            if (newValue < 0) {
                newValue = 0
            }

            if (newValue > 100) {
                newValue = 100
            }

            let newData = {
                id: row.id,
                subDiscountId,
                value: newValue
            }

            this.$store.commit('updateProductDealerDiscount', newData)
        },

        setLineBreak(str) {
            if (str === null) {
                return null
            }

            return String(str).replace(/(\S+\s*){1,7}/g, '$&\n')
        },

        // Category tab methods
        async selectCategory(index) {
            this.search = ''
            await this.$store.commit('changeActiveOrderDataTab', index)
            this.$store.state.activeOrderTab = this.orderDataTabs[this.selectedOrderDataTab].groupId
            this.$store.state.transports = this.$store.state.groupTransports[this.$store.state.activeOrderTab]
            await this.$store.commit('calculateTransportsDataOrder')
        },

        async addNewCategory(payload = null) {
            if (!payload) {
                if (this.orderInformation.otherGroups.length === 1) {
                    this.addNewCategory(this.orderInformation.otherGroups[0])
                } else {
                    this.openNewOrderDropdown = !this.openNewOrderDropdown
                }
                return
            }

            this.openNewOrderDropdown = false
            await this.$store.dispatch('ADD_CATEGORY', payload.id).then(() => {
                this.loading = false
            })

            this.$store.state.activeOrderTab = payload.id
            this.$store.state.transports = this.$store.state.groupTransports[payload.id]

            await this.$store.commit('calculateTransportsDataOrder')
        },

        async removeCategory() {
            this.openDeleteConfirmationModal = false
            await this.$store.commit('removeFromOrderDataTabs')
            this.$store.state.activeOrderTab = this.orderDataTabs[this.selectedOrderDataTab].groupId
            this.$store.state.transports = this.$store.state.groupTransports[this.$store.state.activeOrderTab]
            await this.$store.commit('calculateTransportsDataOrder')
        },

        round(value, decimals) {
            return round(value, decimals)
        }
    },

    computed: {
        ...mapGetters(['orderData', 'allTransportsInTabs', 'transports', 'transportPrices', 'orderInformation', 'discountType', 'orderDataTabs', 'selectedOrderDataTab', 'viewOnly', 'userAuthenticated', 'recommendedProductId']),

        categories() {
            let addSelectable = (categoryProducts, options, products) => {
                let selected = null

                options.some(({id}) => {
                    return categoryProducts.some((product) => {
                        if (product.id === id && product.isSelected) {
                            selected = product
                            return true
                        }
                    })
                })

                products.push({
                    selectable: true,
                    subName: selected.subText,
                    ...selected,
                    options,
                })
            }

            return this.orderData.categories.map(category => {
                let products = []
                let options = []
                let productTypeId
                let selected

                category.products = category.products.filter(product => {
                    if (product.isDeleted && this.isNew) {
                        return false;
                    }

                    return product;
                })

                category.products.map(product => {
                    if (product.isSelectable) {
                        if (productTypeId !== product.type) {
                            if (options.length > 0) {
                                addSelectable(category.products, options, products)
                            }
                            options = []
                            productTypeId = product.type
                            selected = null
                        }
                        options.push({
                            id: product.id,
                            name: this.showProductNumbers ? product.nameWithProductNumber : product.name,
                            subName: product.subText
                        })
                    } else {
                        if (options.length > 0) {
                            addSelectable(category.products, options, products)
                        }
                        options = []
                        productTypeId = product.type
                        selected = null
                        products.push(product)
                    }
                })

                if (options.length > 0) {
                    addSelectable(category.products, options, products)
                }

                return {...category, products}
            })
        },

        orderProductsAmount() {
            return getProductsWithValue(this.orderDataTabs, true).length
        },

        discountColumn() {
            return this.$t('order.page.edit.column.dealer_price', {
                discount: `<span style="white-space: nowrap">${this.$t('order.page.edit.column.dealer_price_discount')}</span>`
            })
        },
    },

    components: {
        JobNavigation,
        DiscountNavigation,
        CustomSelect,
        CustomGroupSelect,
        PanelTotal,
        DiscountBar,
        Notification,
        ModalConfirmation,
        ScrollToTop,
        ProductTypeDropdown,
        ModalGuarantee
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';

.table-products {
    .col-amount {
        .unit-selector {
            li {
                padding: 7px 10px;
            }
        }
    }
}
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/ui/table-products.scss';
@import '../assets/sass/layout/product-list.scss';

.product-list-settings {
    min-height: 164px;
}

.deleted-product {
    background: red;
    color: white;
    padding: 2px 4px;
    border-radius: 3px
}

.adr-product {
    background: #e63b11;
    color: white;
    padding: 1px 4px;
    border-radius: 0px;
}
</style>
