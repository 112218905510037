<template>
    <div class="dropdown-component" v-show="open" ref="dropdownComponent">
        <ul class="dropdown-list">
            <li
                v-for="option in options"
                :key="option.actionName"
                :class="{'new-group': option.isNew}"
                @click.stop="sendAction(option.handle)"
                v-show="option.handle">
                <md-icon v-if="option.isNew">add_circle</md-icon>
                <span>{{ option.actionName }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'RelatedOfferDropdown',
    props: ['open', 'options'],

    data() {
        return {}
    },

    watch: {
        open: {
            handler: function(val) {
                if (val) {
                    this.checkPosition()
                }
            }
        }
    },

    methods: {
        sendAction(action) {
            this.$emit('clicked', action)
            return false
        },

        checkPosition() {
            let el = this.$refs.dropdownComponent

            setTimeout(function() {
                let rect = el.getBoundingClientRect()
                if (window.innerHeight < rect.bottom + 5) {
                    el.classList.add('bottom')
                }
            }, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';

.dropdown-component {
    position: absolute;
    width: 210px;
    top: 40px;
    left: 0;

    &.bottom {
        top: auto;
        bottom: 5px;
    }

    .dropdown-list {
        top: 0;
        position: relative;
        border-radius: 2px;
        background: $color-white;
        z-index: 100;

        li {
            display: flex;
            font-size: 11px;
            line-height: 19px;
            padding: 12px 12px;
            vertical-align: middle;

            i {
                position: relative;
                line-height: 15px;
                height: 14px;
                width: 14px;
                top: 3px;
                margin-right: 7px;
            }

            span {
                font-size: 12px;
            }

            &.new-group {
                span {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
