<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon>
                    {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="product-list-page__header-search-flex">
                    <div class="search-container">
                        <search :searchData="searchData" @searchPhrase="(value) => {this.searchPhrase = value}"></search>
                    </div>
                    <custom-filter
                        v-if="groupTypeVariantCategories.length > 0"
                        :options="groupTypeVariantCategories"
                        :label="''"
                        :value="filteredCategory"
                        @selected="(e) => {this.filteredCategory = e.id}"
                    ></custom-filter>

                    <custom-filter
                        v-if="groupTypeVariantTypes.length > 0"
                        :options="groupTypeVariantTypes"
                        :label="''"
                        :value="filteredType"
                        @selected="(e) => {this.filteredType = e.id}"
                        :dropdown-size="250"
                    ></custom-filter>
                </div>

                <div class="product-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <group-admin-pages selected-page="GroupTypeVariantList" :group="group"></group-admin-pages>
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <vue-good-table
                :columns="tableColumns"
                :rows="getProductRows()"
                :sort-options="{enabled: false}"
                styleClass="vgt-table non-interactive"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-name" v-if="props.column.field === 'name'">
                        <div :style="`margin-left:${props.row.depth * 20}px`">{{ props.row.name }}</div>
                    </div>
                    <span v-else-if="props.column.field === 'type'">{{ getTypeName(props.formattedRow[props.column.field]) }}</span>
                    <div v-else-if="Array.isArray(props.formattedRow[props.column.field])">
                        <div v-for="(value, index) in props.formattedRow[props.column.field]" :key="index">{{ value }}</div>
                    </div>
                    <div class="col-options" v-else-if="props.column.field === 'options' && props.row.actions.length > 0">
                        <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            width="280"
                        ></dropdown>
                    </div>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="emptystate">{{ $t('group_category_product_type.page.list.text.empty_state') }}</div>
            </vue-good-table>
        </div>

        <modal-group-type-category-edit
            :open="openTypeCategoryEditModal"
            :group-id="groupId"
            :category-id="selectedTypeCategoryId"
            :product-type-id="selectedTypeCategoryId"
            :create-target="selectedCreateTarget"
            @close="typeCategoryEditEnd">
        </modal-group-type-category-edit>

        <modal-group-type-edit
            :open="openTypeEditModal"
            :group-id="groupId"
            :category-id="selectedTypeCategoryId"
            :type-id="selectedTypeId"
            :create-target="selectedCreateTarget"
            @close="typeEditEnd">
        </modal-group-type-edit>

        <modal-group-type-variant-edit
            :open="openTypeVariantEditModal"
            :group-id="groupId"
            :category-id="selectedTypeCategoryId"
            :type-id="selectedTypeId"
            :variant-id="selectedTypeVariantId"
            :create-target="selectedCreateTarget"
            @close="typeVariantEditEnd">
        </modal-group-type-variant-edit>

        <modal-confirmation
            :open="openConfirmationDeleteTypeCategoryModal"
            :data="confirmationDeleteTypeCategoryModalData"
            @accepted="typeCategoryDelete"
            @close="openConfirmationDeleteTypeCategoryModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationDeleteTypeModal"
            :data="confirmationDeleteTypeModalData"
            @accepted="typeDelete"
            @close="openConfirmationDeleteTypeModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationDeleteTypeVariantModal"
            :data="confirmationDeleteTypeVariantModalData"
            @accepted="typeVariantDelete"
            @close="openConfirmationDeleteTypeVariantModal = false"
        ></modal-confirmation>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {VueGoodTable} from 'vue-good-table'
import {MdIcon} from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalGroupTypeCategoryEdit from '../components/modals/ModalGroupTypeCategoryEdit.vue'
import ModalGroupTypeEdit from '../components/modals/ModalGroupTypeEdit.vue'
import ModalGroupTypeVariantEdit from '../components/modals/ModalGroupTypeVariantEdit.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation'
import CustomFilter from "@/components/dropdowns/CustomFilter";
import BasicLinks from "@/components/BasicLinks";
import GroupAdminPages from "@/components/sidebars/GroupAdminPages";

Vue.use(MdIcon)
Vue.use(VueGoodTable)

export default {
    name: 'GroupTypeList',

    data() {
        return {
            loading: true,

            selectedTypeId: null,
            selectedTypeCategoryId: null,
            selectedTypeVariantId: null,
            selectedCreateTarget: null,

            openTypeEditModal: false,
            openTypeVariantEditModal: false,
            openTypeCategoryEditModal: false,

            openGroupListDropdown: false,
            productGroups: [],

            filteredCategory: 0,
            filteredType: 0,

            deleteTypeId: null,
            openConfirmationDeleteTypeModal: false,
            confirmationDeleteTypeModalData: {
                acceptText: this.$t('group_type.confirmation.delete.ok'),
                cancelText: this.$t('group_type.confirmation.delete.cancel'),
                message: this.$t('group_type.confirmation.delete.question')
            },

            deleteTypeCategoryId: null,
            openConfirmationDeleteTypeCategoryModal: false,
            confirmationDeleteTypeCategoryModalData: {
                acceptText: this.$t('group_type_category.confirmation.delete.ok'),
                cancelText: this.$t('group_type_category.confirmation.delete.cancel'),
                message: this.$t('group_type_category.confirmation.delete.question')
            },

            deleteTypeVariantId: null,
            openConfirmationDeleteTypeVariantModal: false,
            confirmationDeleteTypeVariantModalData: {
                acceptText: this.$t('group_type_variant.page.list.confirmation.delete.ok'),
                cancelText: this.$t('group_type_variant.page.list.confirmation.delete.cancel'),
                message: this.$t('group_type_variant.page.list.confirmation.delete.question')
            },

            searchData: {
                placeholder: this.$t('group_type_variant.page.list.search.placeholder'),
                tooltipText: this.$t('group_type_variant.page.list.search.tooltip'),
                countText: ''
            },

            searchPhrase: '',

            tableColumns: [
                {
                    label: this.$t('group_type_variant.page.list.column.name'),
                    field: 'name',
                    width: '50%'
                },
                {
                    label: this.$t('group_type_variant.page.list.column.type'),
                    field: 'type',
                    width: '44%'
                },
                {
                    label: '',
                    field: 'options',
                    width: '6%'
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.fetchData()
                })
            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route'() {
            this.$store.commit('resetGroupTypeVariantList')
            this.resetFilter()
            this.refresh()
        }
    },

    methods: {
        getProductRows() {
            let tableData = this.groupTypeVariantRows, searchPhrase

            if (this.filteredCategory !== 0) {
                tableData = tableData.filter(row => {
                    return row.typeCategoryId === this.filteredCategory
                })
            }

            if (this.filteredType !== 0) {
                tableData = tableData.filter(row => {
                    return row.typeId === this.filteredType
                })
            }

            if (this.searchPhrase !== '') {
                searchPhrase = this.searchPhrase.toLowerCase().split(/\s+/)
                tableData = tableData.filter(row => {
                    let valid = true
                    for (let value of searchPhrase) {
                        valid = valid && row.search.toLowerCase().indexOf(value) > -1
                    }
                    return valid
                })
            }

            this.searchData.countText = this.$tc('group_type_variant.page.list.search.name', tableData.length)

            return tableData
        },

        onDropdownClick(val) {
            switch (val.action.action) {
            case 'type-category-edit':
                this.selectedTypeCategoryId = val.id
                this.selectedCreateTarget = null
                this.openTypeCategoryEditModal = true
                break
            case 'type-category-create':
                this.selectedTypeCategoryId = val.id
                this.selectedCreateTarget = val.action.target
                this.openTypeCategoryEditModal = true
                break
            case 'type-category-delete':
                this.deleteTypeCategoryId = val.id
                this.openConfirmationDeleteTypeCategoryModal = true
                break
            case 'type-category-type-create':
                this.selectedTypeCategoryId = val.id
                this.selectedTypeId = null
                this.selectedCreateTarget = val.action.target
                this.openTypeEditModal = true
                break
            case 'type-edit':
                this.selectedTypeCategoryId = val.action.categoryId
                this.selectedTypeId = val.id
                this.selectedCreateTarget = null
                this.openTypeEditModal = true
                break
            case 'type-create':
                this.selectedTypeCategoryId = val.action.categoryId
                this.selectedTypeId = val.id
                this.selectedCreateTarget = val.action.target
                this.openTypeEditModal = true
                break
            case 'type-delete':
                this.deleteTypeCategoryId = val.action.categoryId
                this.deleteTypeId = val.id
                this.openConfirmationDeleteTypeModal = true
                break
            case 'type-variant-create':
                this.selectedTypeCategoryId = val.action.categoryId
                this.selectedTypeId = val.id
                this.selectedTypeVariantId = null
                this.selectedCreateTarget = val.action.target
                this.openTypeVariantEditModal = true
                break
            case 'variant-edit':
                this.selectedTypeCategoryId = val.action.categoryId
                this.selectedTypeId = val.action.typeId
                this.selectedTypeVariantId = val.id
                this.selectedCreateTarget = null
                this.openTypeVariantEditModal = true
                break
            case 'variant-create':
                this.selectedTypeCategoryId = val.action.categoryId
                this.selectedTypeId = val.action.typeId
                this.selectedTypeVariantId = val.id
                this.selectedCreateTarget = val.action.target
                this.openTypeVariantEditModal = true
                break
            case 'variant-delete':
                this.deleteTypeCategoryId = val.action.categoryId
                this.deleteTypeId = val.action.typeId
                this.deleteTypeVariantId = val.id
                this.openConfirmationDeleteTypeVariantModal = true
                break
            }
        },

        resetFilter() {
            this.filteredCategory = 0
            this.filteredType = 0
        },

        getTypeName(type) {
            switch (type) {
            case 'type_category': return 'Típus kategória';
            case 'type': return 'Típus';
            case 'variant': return 'Variáns';
            default: return type;
            }
        },

        fetchData() {
            this.$store.dispatch('FETCH_GROUP_TYPE_VARIANTS', this.$route.params.groupId).then(data => {
                this.loading = false;
            })
        },

        typeCategoryEditEnd(refresh) {
            this.openTypeCategoryEditModal = false
            if (refresh) {
                this.refresh()
            }
        },

        typeCategoryDelete() {
            let payload = {
                groupId: this.$route.params.groupId,
                categoryId: this.deleteTypeCategoryId
            }

            this.$store.dispatch('DELETE_GROUP_TYPE_CATEGORY', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('group_type_category.confirmation.delete.notification.success')])
                this.deleteTypeCategoryId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('group_type_category.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteTypeCategoryModal = false
            })
        },

        typeEditEnd(refresh) {
            this.openTypeEditModal = false
            if (refresh) {
                this.refresh()
            }
        },

        typeDelete() {
            let payload = {
                groupId: this.$route.params.groupId,
                categoryId: this.deleteTypeCategoryId,
                typeId: this.deleteTypeId
            }

            this.$store.dispatch('DELETE_GROUP_TYPE', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('group_type.confirmation.delete.notification.success')])
                this.deleteTypeCategoryId = null
                this.deleteTypeId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('group_type.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteTypeModal = false
            })
        },

        typeVariantEditEnd(refresh) {
            this.openTypeVariantEditModal = false
            if (refresh) {
                this.refresh()
            }
        },

        typeVariantDelete() {
            let payload = {
                groupId: this.$route.params.groupId,
                categoryId: this.deleteTypeCategoryId,
                typeId: this.deleteTypeId,
                variantId: this.deleteTypeVariantId
            }

            this.$store.dispatch('DELETE_GROUP_TYPE_VARIANT', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('group_type_variant.page.list.confirmation.delete.notification.success')])
                this.deleteTypeCategoryId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('group_type_variant.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteTypeVariantModal = false
            })
        },

        onRowClick(params) {
            if (!params.event.target.className.includes('element-options')) {
                params.row.showDropdown = false
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProductType(payload) {
            this.openTypeCategoryEditModal = false
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        refresh() {
            this.loading = true
            this.fetchData()
        },
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        groupId() {
            return this.$route.params.groupId
        },

        group() {
            return this.productGroups.find(group => group.id === Number(this.groupId))
        },

        ...mapGetters(['groupTypeVariantRows', 'groupTypeVariantCategories', 'groupTypeVariantTypes', 'userHasProductAdmin'])
    },

    components: {
        MainNavigation,
        VueGoodTable,
        ProductTypeDropdown,
        Search,
        Dropdown,
        ModalGroupTypeCategoryEdit,
        ModalGroupTypeEdit,
        ModalGroupTypeVariantEdit,
        CustomFilter,
        Notification,
        BasicLinks,
        GroupAdminPages,
        ModalConfirmation
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .checkbox {
            margin-left: auto;
            margin-right: 32px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: calc(100% - 70px);
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .filter-column {
        max-width: calc(100vh - 140px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
