import Paragraph from '@editorjs/paragraph';

export default class Text extends Paragraph {
    static get sanitize() {
        return {
            br: true,
            b: true,
            i: true,
            u: true,
            sub: true,
            sup: true
        };
    }
}
