<template>
    <div class="project-data-form-component">
        <div class="input-wrapper input-wrapper-project-name">
            <label class="text-input-label" for="name-data-form">{{ $t('project.edit.field.name.label') }}</label>
            <input v-model="projectData.name" :disabled="disabledName && !duplicateProject" id="name-data-form" type="text">
            <button
                v-show="disabledName && canEditDetails && !duplicateProject"
                type="button"
                class="btn btn-plain btn-plain-wicon button-rename"
                @click="openModalRename = true"
            >
                <md-icon>edit</md-icon>
                <span>{{ $t('project.edit.button.rename') }}</span>
            </button>
            <button type="button" v-show="isNewOffer" class="button-close" @click="backToSelectProject">
                <md-icon>close</md-icon>
            </button>
        </div>
        <div class="input-wrapper input-wrapper-project-name" :class="{'error' : validation.customerName}">
            <label class="text-input-label" for="customer-data-form">{{ $t('project.edit.field.customer_name.label') }}<template v-if="canEditDetails">*</template></label>
            <input
                v-model="projectData.customerName"
                id="customer-data-form"
                type="text"
                :disabled="!canEditDetails">
            <span class="input-error">{{validation.customerName}}</span>
        </div>
        <div class="address-container">
            <div class="input-col col-25">
                <div class="input-wrapper input-wrapper-project-name" :class="{'error' : validation.customerPhone}">
                    <label class="text-input-label" for="customer-phone-data-form">{{ $t('project.edit.field.customer_phone.label') }}</label>
                    <input
                        v-model="projectData.customerPhone"
                        v-mask="{mask: '+36 99/999 9999', greedy: true, autoUnmask: true}"
                        id="customer-phone-data-form"
                        type="text"
                        :disabled="!canEditDetails">
                    <span class="input-error">{{validation.customerPhone}}</span>
                </div>
            </div>
            <div class="input-col col-75">
                <div class="input-wrapper input-wrapper-project-name" :class="{'error' : validation.customerEmail}">
                    <label class="text-input-label" for="customer-email-data-form">{{ $t('project.edit.field.customer_email.label') }}</label>
                    <input
                        v-model="projectData.customerEmail"
                        id="customer-email-data-form"
                        type="email"
                        :disabled="!canEditDetails">
                    <span class="input-error">{{validation.customerEmail}}</span>
                </div>
            </div>
        </div>
        <span class="text-input-label">{{ $t('project.edit.text.address') }}</span>
        <div class="address-container">
            <div class="input-col col-15">
                <div
                    :class="{'error' : validation.addressZip}"
                    class="input-wrapper input-wrapper-zip">
                    <label class="text-input-label" for="addressZip-data-form">{{ $t('project.edit.field.address_zip.label') }}<template v-if="canEditDetails">*</template></label>
                    <input
                        ref="addressZip"
                        v-model="projectData.addressZip"
                        @input="inputZip"
                        @focus="checkZip"
                        @blur="emptyCityList"
                        type="number"
                        min="1000"
                        max="9999"
                        id="addressZip-data-form"
                        v-disable-mouse-wheel
                        v-autocomplete-off
                        :disabled="!canEditDetails">
                    <span class="input-error">{{validation.addressZip}}</span>
                    <ul class="dropdown-list" v-show="cityList.length > 0">
                        <li
                            v-for="(city, index) in cityList"
                            :key="index"
                            @click="writeCityName(city)">
                            {{city}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="input-col col-85">
                <div class="input-wrapper" :class="{'error' : validation.addressCity}">
                    <label class="text-input-label" for="addressCity-data-form">{{ $t('project.edit.field.address_city.label') }}<template v-if="canEditDetails">*</template></label>
                    <input
                        ref="addressCity"
                        v-model="projectData.addressCity"
                        @input="inputCity"
                        @focus="checkCity"
                        @blur="blurCity"
                        id="addressCity-data-form"
                        type="text"
                        v-autocomplete-off
                        :disabled="!canEditDetails">
                    <span class="input-error" v-if="!(validation.addressZip)">{{validation.addressCity}}</span>
                    <ul class="dropdown-list" v-show="zipList.length > 0">
                        <li
                            v-for="(zip, index) in zipList"
                            :key="index"
                            @click="writeZip(zip)">
                            {{zip}}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="input-wrapper" :class="{'error' : validation.address}">
                <label class="text-input-label" for="address-data-form">{{ $t('project.edit.field.address.label') }}<template v-if="canEditDetails">*</template></label>
                <input type="text" v-model="projectData.address" id="address-data-form" v-autocomplete-off :disabled="!canEditDetails">
                <span class="input-error">{{validation.address}}</span>
            </div>

            <div v-if="userCanSeeExtraFields">
                <div style="width: 45%; margin-right:5%" class="input-col">
                    <div class="input-wrapper" :class="{'error' : validation.percentageToWin}">
                        <label class="text-input-label" for="address-data-form">Esély hogy megnyerjük %</label>
                        <custom-filter
                            style="width: 100%!important;"
                            class="filter-component input-wrapper"
                            :fullWidth="true"
                            :value="projectData.percentageToWin"
                            @selected="(el) => {projectData.percentageToWin = el.id}"
                            :filterable="false"
                            :label="''"
                            :options="percentageOptions">
                        </custom-filter>
                        <span class="input-error">{{validation.percentageToWin}}</span>
                    </div>
                </div>

                <div style="width: 50%;" class="input-col custom">
                    <div class="input-wrapper" :class="{'error' : validation.expectedBuildingTime}">
                        <label class="text-input-label" for="address-data-form">Várható beépítési idő</label>
                        <custom-datepicker
                            style="width:100%"
                            :enablePassedDates="false"
                            :initDate="projectData.expectedBuildingTime ? projectData.expectedBuildingTime : null"
                            @changed="(el) => {projectData.expectedBuildingTime = el}"
                        />
                        <span class="input-error">{{validation.expectedBuildingTime}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="contacts-container contacts-container--default-advisory" v-if="projectData.defaultAdvisoryUsers">
            <div class="contacts-row">
                <span class="label">{{ $t('project.edit.field.default_advisory.label') }}</span>
                <div class="element-wrapper">
                    <span class="element" v-if="projectData.defaultAdvisoryUser">
                        {{ projectData.defaultAdvisoryUser.name }}
                        <button type="button" @click="removePerson('defaultAdvisoryUser')">
                            <md-icon>close</md-icon>
                        </button>
                    </span>
                </div>

                <button
                    v-if="!projectData.defaultAdvisoryUser"
                    type="button"
                    class="btn btn-plain btn-plain-wicon button-add"
                    @click="openModalAddPerson('defaultAdvisoryUser')"
                >
                    <md-icon>add_circle</md-icon>
                    <span>{{ $t('project.edit.button.add') }}</span>
                </button>
            </div>
        </div>
        <div class="contacts-container" v-if="(canEditDealer || projectData.dealer) || (canEditWorker || projectData.worker)">
            <label class="text-input-label" v-if="canEdit">{{ $t('project.edit.text.add_contacts') }}</label>
            <label class="text-input-label" v-else>{{ $t('project.edit.text.contacts') }}</label>

            <div class="contacts-row" v-if="canEditDealer">
                <span class="label">{{ $t('project.edit.field.dealer.label') }}</span>
                <div class="element-wrapper">
                    <span class="element" v-if="projectData.dealer">
                        <span v-if="projectData.dealer.deleted" :title="this.$t('project.edit.text.customer_deleted')" style="cursor:help; text-decoration: line-through">{{ projectData.dealer.name }}</span>
                        <template v-else>{{ projectData.dealer.name }}</template>
                        <button type="button" @click="removePerson('dealer')">
                            <md-icon>close</md-icon>
                        </button>
                    </span>
                </div>

                <button
                    v-if="!projectData.dealer"
                    type="button"
                    class="btn btn-plain btn-plain-wicon button-add"
                    @click="openModalAddPerson('dealer')"
                >
                    <md-icon>add_circle</md-icon>
                    <span>{{ $t('project.edit.button.add') }}</span>
                </button>
            </div>
            <div class="contacts-row" v-else-if="projectData.dealer">
                <span class="label">{{ $t('project.edit.field.dealer.label') }}</span>
                <div class="element-wrapper">
                    <span class="element">
                        <span v-if="projectData.dealer.deleted" :title="this.$t('project.edit.text.customer_deleted')" style="cursor:help; text-decoration: line-through">{{ projectData.dealer.name }}</span>
                        <template v-else>{{ projectData.dealer.name }}</template>
                    </span>
                </div>
            </div>

            <div class="contacts-row" v-if="canEditWorker">
                <span class="label">{{ $t('project.edit.field.worker.label') }}</span>
                <div class="element-wrapper">
                    <span class="element" v-if="projectData.worker">
                        {{projectData.worker.name}}
                        <button type="button" @click="removePerson('worker')">
                            <md-icon>close</md-icon>
                        </button>
                    </span>
                </div>
                <button
                    v-if="!projectData.worker"
                    type="button"
                    class="btn btn-plain btn-plain-wicon button-add"
                    @click="openModalAddPerson('worker')"
                >
                    <md-icon>add_circle</md-icon>
                    <span>{{ $t('project.edit.button.add') }}</span>
                </button>
            </div>
            <div class="contacts-row" v-else-if="projectData.worker">
                <span class="label">{{ $t('project.edit.field.worker.label') }}</span>
                <div class="element-wrapper">
                    <span class="element">{{ projectData.worker.name }}</span>
                </div>
            </div>
        </div>

        <button
            v-if="canEdit && isNewOffer && !isCopyProject && fromProjects == null"
            :disabled="isSaveOfferButtonDisabled"
            @click="saveNewData"
            class="btn btn-primary button-save"
            type="button">
            {{ $t('project.edit.button.save_and_go_to_offer') }}
        </button>

        <button
            v-if="isCopyProject"
            @click="saveNewData"
            class="btn btn-primary button-save"
            type="button">
            {{ $t('project.edit.button.copy_to_another_project') }}
        </button>

        <button
            v-if="!canEdit && isNewOffer && !isCopyProject"
            @click="goToOffer"
            class="btn btn-primary button-save"
            type="button">
            {{ $t('project.edit.button.go_to_offer') }}
        </button>

        <button
            v-if="canEdit && !isNewOffer && isCopyProject === null"
            @click="saveEditedData"
            class="btn btn-primary button-save"
            type="button">
            {{ $t('project.edit.button.save') }}
        </button>


        <button
            v-if="canEdit && isNewOffer && fromProjects == true"
            @click="saveEditedData"
            class="btn btn-primary button-save"
            type="button">
            Mentés
        </button>

        <button
            v-if="canEdit && duplicateProject"
            @click="duplicateTheProject"
            class="btn btn-primary button-save"
            type="button">
            {{ $t('project.edit.button.copy') }}
        </button>

        <button
            v-if="canEdit && !isNewOffer"
            @click="$emit('close', false)"
            class="btn btn-plain"
            type="button">
            {{ $t('project.edit.button.back') }}
        </button>

        <modal-project-rename
            :open="openModalRename"
            :name="projectData.name"
            @saveName="renameProject"
            @close="() => {this.openModalRename = false}"
        ></modal-project-rename>

        <modal-project-add-person
            v-if="openModalPerson"
            :personType="personType"
            :advisoryUsers="projectData.defaultAdvisoryUsers"
            @addPerson="addPerson"
            @close="() => {this.openModalPerson = false}"
        ></modal-project-add-person>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import ModalProjectRename from '../components/modals/ModalProjectRename.vue'
import ModalProjectAddPerson from '../components/modals/ModalProjectAddPerson.vue'

import CustomFilter from '@/components/dropdowns/CustomFilter.vue'
import CustomDatepicker from '../components/dropdowns/CustomDatepicker.vue'
import CustomSelect from '../components/dropdowns/CustomSelect.vue'

import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'ProjectDataForm',
    props: ['projData', 'isNewOffer', 'fromProjects', 'availableProject', 'isCopyProject', 'offerInformation', 'duplicateProject'],

    data() {
        return {
            disabledName: true,
            openModalRename: false,
            openModalPerson: false,
            projectData: {},
            personType: '',
            cityList: [],
            zipList: [],
            errors: [],
            dealerChanged: false,
            workerChanged: false,
            isSaveOfferButtonDisabled: false,
            fromData: '',
            testValue: {},
            percentageOptions: [
                {
                    id: null,
                    name: "Kérjük válasszon",
                },
                {
                    id: 25,
                    name: "25",
                },
                {
                    id: 50,
                    name: "50",
                },
                {
                    id: 75,
                    name: "75",
                },
                {
                    id: 100,
                    name: "100",
                }
            ],
        }
    },

    mounted() {
        this.$store.commit('emptyValidation')
        this.$store.dispatch('FETCH_ZIPCODE_LIST')
        this.$store.dispatch('FETCH_CITY_LIST')
    },

    watch: {
        projData: function() {
            this.projectData = this.projData;
            // Check if this.projectData.customerPhone is defined
            if (this.projectData.customerPhone && this.projectData.customerPhone.length === 11) {
                this.projectData.customerPhone = this.projectData.customerPhone.slice(2);
            }
        }
    },

    methods: {
        setPercentageToWin(e){
            this.projectData.percentageToWin = e.value;
        },

        openModalAddPerson(type) {
            this.personType = type
            this.openModalPerson = true
        },

        addPerson(data) {
            this.openModalPerson = false
            this.projectData[this.personType] = data

            if (this.personType === 'dealer') {
                this.dealerChanged = true
            }

            if (this.personType === 'worker') {
                this.workerChanged = true
            }
        },

        removePerson(type) {
            this.projectData[type] = null

            if (type === 'dealer') {
                this.dealerChanged = true
            }

            if (type === 'worker') {
                this.workerChanged = true
            }
        },

        inputZip() {
            this.projectData.addressCity = ''
            this.cityList = []
            this.checkZip()
        },

        checkZip() {
            this.$refs.addressZip.removeAttribute('tabindex')

            let zipLength = String(this.projectData.addressZip).length
            if (zipLength > 4) {
                this.projectData.addressZip = Number((String(this.projectData.addressZip)).substring(0, 4))
            }

            if (this.projectData.addressZip !== null && String(this.projectData.addressZip).length === 4) {
                this.setCity()
            } else {
                this.cityList = []
            }
        },

        inputCity() {
            this.projectData.addressZip = ''
            this.zipList = []
            this.checkCity()
        },

        checkCity() {
            this.$refs.addressCity.removeAttribute('tabindex')

            if (this.projectData.addressCity !== null && this.projectData.addressCity.length > 2) {
                this.setZip()
            } else {
                this.zipList = []
            }
        },

        blurCity() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.projectData.addressCity.toLowerCase() && el.name !== this.projectData.addressCity) {
                    this.projectData.addressCity = el.name
                }
            })
            this.emptyZipList()
        },

        setCity() {
            this.zipCodesList.forEach(el => {
                const sameZip = Number(el.zip) === Number(this.projectData.addressZip)
                if (sameZip && el.cities.length === 1) {
                    this.$refs.addressCity.setAttribute('tabindex', '-1')
                    this.projectData.addressCity = el.cities[0]
                } else if (sameZip && el.cities.length > 1) {
                    this.cityList = el.cities
                }
            })
        },

        setZip() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.projectData.addressCity.toLowerCase()) {
                    if (el.zips.length === 1) {
                        this.$refs.addressZip.setAttribute('tabindex', '-1')
                        this.projectData.addressZip = el.zips[0]
                    } else {
                        this.zipList = el.zips
                    }
                }
            })
        },

        writeCityName(name) {
            this.$refs.addressCity.setAttribute('tabindex', '-1')
            this.projectData.addressCity = name
            this.emptyZipList()
        },

        writeZip(zip) {
            this.$refs.addressZip.setAttribute('tabindex', '-1')
            this.projectData.addressZip = zip
            this.emptyCityList()
        },

        emptyCityList() {
            let that = this
            setTimeout(function(){
                if (that.cityList.length > 0) {
                    that.cityList = []
                }
            }, 250)
        },

        emptyZipList() {
            let that = this
            setTimeout(function(){
                if (that.zipList.length > 0) {
                    that.zipList = []
                }
            }, 250)
        },

        backToSelectProject() {
            this.$emit('selectProject')
        },

        renameProject(newName) {
            this.$store.commit('emptyValidation')

            this.projectData.name = newName
            if (this.projectData.id) {
                this.$store.dispatch('RENAME_PROJECT', { id: this.projectData.id, name: this.projectData.name }).catch(response => {
                    this.$store.commit('setValidation', response.errors)
                })
            }
            this.openModalRename = false
        },

        goToOffer() {
            if (this.newOfferGroup.type === 'text') {
                this.$router.push({name: 'NewOfferText', params: {projectId: this.projectData.id, groupId: this.newOfferGroup.id}})
            } else {
                this.$router.push({name: 'NewOfferCategory', params: {projectId: this.projectData.id, groupId: this.newOfferGroup.id}})
            }
        },

        copyOfferToProject(projectId = null) {
            this.$store.dispatch('COPY_OFFER', {
                projectId: projectId || this.projectData.id,
                id: this.isCopyProject.id,
                inList: true
            }).then(() => {
                this.$emit('close', true)
            })
        },

        saveNewData() {
            this.isSaveOfferButtonDisabled = true
            this.$store.commit('emptyValidation')

            let data = {
                name: this.projectData.name,
                addressZip: this.projectData.addressZip,
                addressCity: this.projectData.addressCity,
                address: this.projectData.address,
                dealerId: this.projectData.dealer === null ? null : this.projectData.dealer.id,
                workerId: this.projectData.worker === null ? null : this.projectData.worker.id,
                defaultAdvisoryUserId: !this.projectData.defaultAdvisoryUser ? null : this.projectData.defaultAdvisoryUser.id,
                customerName: this.projectData.customerName,
                customerPhone: this.projectData.customerPhone ? '36'+this.projectData.customerPhone : null,
                customerEmail: this.projectData.customerEmail,
                percentageToWin: this.projectData.percentageToWin ?? null,
                expectedBuildingTime: this.projectData.expectedBuildingTime ?? null
            }

            let projectId = null

            if (this.availableProject === true) {
                projectId = this.projectData.id;
            }

            if (this.isCopyProject) {
                this.$store.dispatch('SAVE_PROJECT',  { data: data, status: 'copyProject' }).then(data => {
                    this.copyOfferToProject(data.id)
                }).catch(response => {
                    this.isSaveOfferButtonDisabled = false
                    this.$store.commit('setValidation', response.errors)
                })
            } else if (this.routeName === 'OrderList') {
                this.$store.dispatch('SAVE_PROJECT', { id: projectId, data: data, status: 'newOrder' }).then(() => {
                    this.isSaveOfferButtonDisabled = false
                    this.$emit('close', true)
                })
            } else {
                this.$store.dispatch('SAVE_PROJECT', { id: projectId, data: data, status: 'new' }).then(() => {
                    this.isSaveOfferButtonDisabled = false
                    this.$emit('close', true)
                }).catch(response => {
                    this.isSaveOfferButtonDisabled = false
                    this.$store.commit('setValidation', response.errors)
                })
            }
        },

        saveEditedData() {
            this.$store.commit('emptyValidation')

            let data = {
                name: this.projectData.name,
                addressZip: this.projectData.addressZip,
                addressCity: this.projectData.addressCity,
                address: this.projectData.address,
                dealerId: this.projectData.dealer === null ? null : this.projectData.dealer.id,
                workerId: this.projectData.worker === null ? null : this.projectData.worker.id,
                defaultAdvisoryUserId: !this.projectData.defaultAdvisoryUser ? null : this.projectData.defaultAdvisoryUser.id,
                customerName: this.projectData.customerName,
                customerPhone: this.projectData.customerPhone ? '36'+this.projectData.customerPhone : null,
                customerEmail: this.projectData.customerEmail,
                percentageToWin: this.projectData.percentageToWin ?? null,
                expectedBuildingTime: this.projectData.expectedBuildingTime ?? null
            }

            this.$store.dispatch('SAVE_PROJECT', { id: this.projectData.id, data: data, status: 'edit' }).then(() => {
                this.$emit('close', true)

                if ((this.dealerChanged || this.workerChanged) && this.offerInformation) {
                    this.dealerChanged = false
                    this.workerChanged = false
                    this.$store.dispatch('FETCH_OFFER_INFORMATION_DATA', {projectId: this.projectData.id, offerInformation: {...this.offerInformation}})
                }
            }).catch(response => {
                this.$store.commit('setValidation', response.errors)
            })
        },

        duplicateTheProject() {
            this.$store.commit('emptyValidation')

            let data = {
                name: this.projectData.name,
                addressZip: this.projectData.addressZip,
                addressCity: this.projectData.addressCity,
                address: this.projectData.address,
                dealerId: this.projectData.dealer === null ? null : this.projectData.dealer.id,
                workerId: this.projectData.worker === null ? null : this.projectData.worker.id,
                defaultAdvisoryUserId: !this.projectData.defaultAdvisoryUser ? null : this.projectData.defaultAdvisoryUser.id,
                customerName: this.projectData.customerName,
                customerPhone: this.projectData.customerPhone ? '36'+this.projectData.customerPhone : null,
                customerEmail: this.projectData.customerEmail,
                percentageToWin: this.projectData.percentageToWin ?? null,
                expectedBuildingTime: this.projectData.expectedBuildingTime ?? null
            }

            this.$store.dispatch('COPY_PROJECT', { id: this.projectData.id, data: data }).then(() => {
                this.$emit('close', true)
            }).catch(response => {
                this.$store.commit('setValidation', response.errors)
            })
        }
    },

    computed: {
        ...mapGetters(['newOfferGroup', 'zipCodesList', 'citiesList', 'validation', 'userHasDealers', 'userHasWorkers', 'userCanSeeExtraFields']),

        routeName() {
            return this.$route.name
        },

        canEditDealer() {
            if(this.duplicateProject) {
                return this.userHasDealers;
            }

            return this.projectData && this.projectData.permissions && this.projectData.permissions.canEditDealer;
        },

        canEditWorker() {
            if(this.duplicateProject) {
                return this.userHasWorkers;
            }

            return this.projectData && this.projectData.permissions && this.projectData.permissions.canEditWorker;
        },

        canEditDetails() {
            if(this.duplicateProject) {
                return true;
            }

            return this.projectData && this.projectData.permissions && this.projectData.permissions.canEditDetails;
        },

        canEdit() {
            return this.canEditDealer || this.canEditDetails;
        }
    },

    components: { ModalProjectRename, ModalProjectAddPerson, CustomFilter, CustomDatepicker },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/contacts.scss';

.mb-5{
    margin-bottom: 15px;
}


.project-data-form-component {
    input {
        width: 100%;
    }

    .text-input-label {
        font-size: 13px;
    }

    .input-col {
        display: inline-block;

        &.col-50 {
            width: calc(49.5% + 1px);

            &:first-child {
                margin-right: 0.4%;
            }
        }

        &.col-15 {
            width: 15%;
            margin-right: 0.4%;
        }

        &.col-25 {
            width: 25%;
            margin-right: 0.4%;
        }

        &.col-85 {
            width: 84%;
        }

        &.col-75 {
            width: 74%;
        }
    }

    .input-wrapper-project-name {
        position: relative;
        margin-bottom: 15px;

        input {
            width: 100%;

            &:disabled {
                background: $color-gray-bg-2;
            }
        }

        .button-rename {
            position: absolute;
            top: 41px;
            right: 70px;

            i {
                font-size: 18px !important;
            }

            span {
                display: inline-block;
            }
        }

        .button-close {
            position: absolute;
            top: 41px;
            right: 10px;

            i {
                font-size: 24px !important;
            }
        }
    }

    .address-container {
        margin-top: 5px;

        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;

            .input-col{
                width: 100%!important;
            }
        }
        .text-input-label {
            font-size: 13px;
        }

        .input-wrapper {
            margin-bottom: 15px;

            &.input-wrapper-zip.error {
                .input-error {
                    min-width: 380px;
                }
            }
        }

        .dropdown-list {
            top: 76px;
            width: 270px;
        }
    }

    .button-save {
        margin-right: 25px;
    }

    .contacts-container {
        margin-top: 15px;

        .contacts-row {
            &:last-child {
                border-bottom: none;
                padding-bottom: 10px;
            }
        }

        &--default-advisory {
            .contacts-row {
                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
