<template>
    <div class="auth-form">
        <div class="auth-form__title">{{ $t('verification.page.title') }}</div>
        <div class="auth-form__subtitle">{{ $t('verification.page.description') }}</div>
        <div class="alert alert--danger" v-if="validation.message">{{ validation.message }}</div>
        <div class="auth-form__row">
            <div class="auth-form__row">
                <label for="l_verification_code">{{ $t('verification.page.field.code.label') }}</label>
                <input
                    type="text"
                    name="code"
                    v-model="code"
                    autocomplete="chrome-off"
                    tabindex="2"
                    id="l_verification_code"
                    :class="{ 'has-error' : hasError('code') }"
                    @keyup.enter="validate()">

                <span class="auth-form__error" v-if="hasError('code')">{{ validation.fields.code }}</span>
            </div>
        </div>

        <div class="auth-form__submit">
            <button type="button" class="btn btn-red" @click="validate()">{{ $t('verification.page.button.verification') }}</button>
            <p>
                <a @click="resendCode">{{ $t('verification.page.button.resend')}}</a>
                <a @click="$emit('switchToLogin')" class="button-tologin">{{ $t('verification.page.button.login')}}</a>
            </p>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Notification from '../Notification.vue'

export default {
    name: 'Verification',

    data() {
        return {
            loading: false,
            errors: [],
            generalError: '',
            code: '',
            email: this.sto
        }
    },

    methods: {
        hasError(field) {
            return !!(this.validation.fields && this.validation.fields.hasOwnProperty(field));
        },

        validate() {
            this.loading = true

            this.$store.dispatch('VALIDATE_VERIFICATION_CODE', {
                payload: {
                    code: this.code,
                    email: this.userAuthEmail
                },
                remember: this.userAuthRemember
            }).then(() => {
                setTimeout(() => {
		        this.$emit('validPassword')
		        this.$router.push({name: 'OfferList'})
                    this.loading = false
                }, 500)
            }).catch(response => {
                this.$store.commit('addValidation', [response.error, response.errors])
            })
        },

        resendCode() {
            this.loading = true

            this.$store.dispatch('VALIDATE_VERIFICATION_CODE', {
                payload: {},
            }).then(() => {
                setTimeout(() => {
                    this.loading = false
                }, 500)
            }).catch(response => {
                this.$store.commit('addValidation', [response.error, response.errors])
            })
        }
    },

    computed: {
        ...mapGetters(['validation', 'userAuthEmail', 'userAuthRemember'])
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/loading.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/partials/auth-form.scss';

.button-tologin {
    display: block;
    margin-top: 30px;
}
</style>
