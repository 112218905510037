<template>
    <div class="select-component" :class="{'large' : large, 'fixed-width': fixedWidth }" :style="{width: width ? `${width}px` : null}"
        v-click-outside="onClickOutside"
        >
        <input
            ref="input"
            type="text"
            :value="selectedItemName"
            readonly="readonly"
            autocomplete="off"
            @click="open=!open"
            :placeholder="placeholder || this.$t('common.select.choice')"
            :disabled="disabled"
            :class="{
                'bold': typeof bold === 'undefined' || bold,
                'cursor-not-allowed': typeof cursorNotAllowed === 'undefined' || cursorNotAllowed,
                'has-sub-name': Boolean(selectedItemSubName)
            }"
        >
        <span class="sub-name" v-if="selectedItemSubName">{{ selectedItemSubName }}</span>
        <md-icon v-if="!iconArrow">list</md-icon>
        <md-icon v-if="iconArrow">keyboard_arrow_down</md-icon>
        <ul v-show="open" class="dropdown-list" :class="{'large-input' : large}" ref="dropdownList" :style="modalStyle">
            <li v-if="filterable" class="filter" :style="filterStyle">
                <input
                    class="input-filter"
                    type="text"
                    :placeholder="$t('common.select.filter_placeholder')"
                    ref="filterInput"
                    v-model="filterInputValue"
                >
                <md-icon class="icon-filter">search</md-icon>
            </li>
            <li
                v-for="option in filteredOptions"
                :key="option.id"
                :class="{
                    'selected': selectedItem !== null && selectedItem.id == option.id,
                    'has-sub-name': Boolean(option.subName)
                }"
                @click="selectOption(option)"
            ><span v-html="option.styledName"></span><span v-if="option.subName">{{ option.subName }}</span></li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import {MdIcon} from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'CustomSelect',
    props: ['selectedItem', 'options', 'large', 'iconArrow', 'disabled', 'placeholder', 'bold', 'cursorNotAllowed', 'modal', 'width', 'filterable', 'fixedWidth'],

    data() {
        return {
            open: false,
            boundingClientRectInput: null,
            boundingClientRectList: null,
            maxHeight: null,
            modalElement: null,
            filterInputValue: ''
        }
    },

    mounted() {
        this.modalElement = null
        if (this.modal) {
            this.modalElement = this.$el.closest('.modal-box-body')
            if (this.modalElement) {
                window.addEventListener('resize', this.setBoundingClientRect, false)
                this.modalElement.addEventListener('scroll', this.setBoundingClientRect, false)
            }
        }
    },

    destroyed() {
        if (this.modalElement) {
            window.removeEventListener('resize', this.setBoundingClientRect)
            this.modalElement.removeEventListener('scroll', this.setBoundingClientRect)
        }
    },

    watch: {
        open(opened) {
            if (opened) {
                this.setBoundingClientRect()
                if (this.filterable) {
                    requestAnimationFrame(() => {
                        this.$refs.filterInput.focus()
                    })
                }
            } else if (this.filterable) {
                this.filterInputValue = ''
            }
        },
        filterInputValue() {
            this.setBoundingClientListRect(() => {
                this.$refs.dropdownList.scrollTop = 0;
            })
        }
    },

    methods: {
        setBoundingClientRect() {
            if (this.open && this.modalElement) {
                this.boundingClientRectInput = this.$refs.input.getBoundingClientRect()
                this.maxHeight = Math.min(window.innerHeight * 0.55, window.innerHeight - this.boundingClientRectInput.top - this.boundingClientRectInput.height - 5)
            }
            this.setBoundingClientListRect()
        },

        setBoundingClientListRect(callback) {
            if (this.open && this.filterable) {
                requestAnimationFrame(() => {
                    if (this.$refs.dropdownList.children.length > 1) {
                        this.boundingClientRectList = this.$refs.dropdownList.children[1].getBoundingClientRect()
                    } else {
                        this.boundingClientRectList = null
                    }
                    if (typeof callback === 'function') {
                        callback()
                    }
                })
            }
        },

        selectOption(selected) {
            this.open = false

            if (this.selectedItem === null || selected.id !== this.selectedItem.id) {
                this.$emit('changed', selected)
            }
        },

        onClickOutside() {
            if (this.open) {
                this.open = false
            }
        },

        escapeHtml(unsafe) {
            return unsafe
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#039;");
        }
    },

    computed: {
        selectedItemName() {
            return this.selectedItem ? this.selectedItem.name : ''
        },

        selectedItemSubName() {
            return this.selectedItem ? this.selectedItem.subName : ''
        },

        modalStyle() {
            if (!this.boundingClientRectInput) {
                if (this.width) {
                    return {
                        width: `${this.width}px`
                    }
                }
                return {}
            }

            return {
                position: 'fixed',
                left: `${this.boundingClientRectInput.left}px`,
                top: `${this.boundingClientRectInput.top + this.boundingClientRectInput.height}px`,
                maxHeight: `${this.maxHeight}px`,
                minWidth: `${this.boundingClientRectInput.right - this.boundingClientRectInput.left}px`,
                width: 'auto'
            }
        },

        filterStyle() {
            if (!this.boundingClientRectList) {
                if (this.boundingClientRectInput) {
                    return {
                        position: 'relative',
                        width: 'auto',
                    }
                } else {
                    return {}
                }
            }
            return {
                width: `${this.boundingClientRectList.right - this.boundingClientRectList.left}px`,
            }
        },

        filterParts() {
            if (!this.filterable || !this.filterInputValue || this.filterInputValue.trim() === '') {
                return null
            }
            return this.filterInputValue.trim().toLowerCase().split(/\s+/)
        },

        filteredOptions() {
            if(!this.options) {
                return []
            }

            if (!this.filterParts) {
                return this.options.map(option => {
                    return {
                        ...option,
                        styledName: this.escapeHtml(option.name)
                    }
                })
            }

            return this.options.filter(option => {
                if (option.name) {
                    const lowerName = String(option.name).toLowerCase()
                    return this.filterParts.every(part => {
                        return lowerName.includes(part)
                    })
                }
                return true
            }).map(option => {
                return {
                    ...option,
                    styledName: this.escapeHtml(this.filterParts.reduce((replacedName, part) => {
                        if (replacedName) {
                            const safePartForRegex = part.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
                            return replacedName.replace(new RegExp(safePartForRegex, 'ig'), match => {
                                return `<strong>${match}</strong>`
                            })
                        }
                        return replacedName
                    }, option.name)).replaceAll(this.escapeHtml('<strong>'), '<strong>').replaceAll(this.escapeHtml('</strong>'), '</strong>')
                }
            })
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';

.select-component {
    position: relative;
    width: 100%;
    background: $color-white;

    &.fixed-width{
        input{
            width: 200px!important;
        }
        @media only screen and (max-width: 600px) {
            input{
                width: 100%!important;
            }
        }
    }

    input {
        position: relative;
        color: $color-gray-1;
        width: 100%;
        padding: 0 35px 0 12px;
        font-size: 13px;
        line-height: 22px;
        height: 35px;
        border-radius: 2px;
        border: 1px solid $color-gray-border;
        cursor: pointer;
        z-index: 2;
        background: transparent;

        &.has-sub-name {
            padding-bottom: 16px;
        }

        &:disabled {
            background: #DDD;
            cursor: default;
            font-weight: normal;

            &.cursor-not-allowed {
                cursor: not-allowed;
            }
        }

        &.bold {
            font-weight: bold;
        }
    }

    .sub-name {
        position: absolute;
        left: 13px;
        bottom: 10px;
        font-size: 11px;
        line-height: 13px;
        color: dimgray;
    }

    .dropdown-list {
        li.has-sub-name {
            font-weight: bold;

            span {
                display: block;
                font-size: 11px;
                line-height: 13px;
                color: dimgray;
                font-weight: normal;
            }
        }
    }

    i {
        position: absolute;
        background: $color-white;
        top: 8px;
        right: 10px;
    }

    .filter {
        cursor: default;
        padding: 0;
        border-bottom-width: 2px;
        position: fixed;

        &:hover {
            background: #FFFFFF;
        }

        + li {
            margin-top: 44px;
        }

        .input-filter {
            width: 100%;
            cursor: default;
            height: auto;
            border: none;
            padding: 10px 0 10px 44px;
        }

        .icon-filter {
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }

    &.large {
        input {
            height: 52px;
        }

        i {
            top: 16px;
        }
    }
}
</style>
