<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <h2>{{ subTitle }}</h2>
                <div class="input-wrapper-row" v-for="(product, index) in selectedProducts" :key="index">
                    <div class="input-wrapper" :class="{'error' : errors.productId}">
                        <label class="text-input-label">{{ $t('group_type_product.manage.field.product') }}</label>
                        <custom-select
                            :filterable="true"
                            :modal="true"
                            :selectedItem="product"
                            :options="products"
                            @changed="productSelected(index, $event)"
                            :large="true"
                        ></custom-select>
                        <span class="input-error">{{errors.productId}}</span>
                    </div>

                    <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.recommended}" v-if="product.id && hasRecommended">
                        <div class="toggle">
                            <input type="checkbox" v-model="selectedProducts[index].recommended" :id="`recommended-product-add-${index}`">
                            <label :for="`recommended-product-add-${index}`">{{ $t('group_type_product.manage.field.recommended') }}</label>
                        </div>
                        <span class="input-error">{{errors.recommended}}</span>
                    </div>
                </div>

                <button v-if="hasMultipleProducts" class="btn btn-plain" type="button" @click="selectedProducts = [...selectedProducts, ...products[0]]">
                    {{ $t('group_type_product.manage.button.add_new_product') }}
                </button>

                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveProduct" :disabled="loading">
                        {{ $t('group_type_product.manage.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_type_product.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupTypeProductEdit',
    props: ['open', 'groupId', 'groupTypeVariantId', 'groupTypeId', 'productId'],

    data() {
        return {
            loading: false,
            errors: {},
            hasMultipleProducts: false,
            hasRecommended: false,
            title: null,
            subTitle: null,
            selectedProducts: [],
            products: []
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_TYPE_PRODUCT', {
                    groupId: this.groupId,
                    groupTypeId: this.groupTypeId,
                    groupTypeVariantId: this.groupTypeVariantId,
                    productId: this.productId
                }).then(data => {
                    data.products = data.products.map(v => {
                        if(data.hasRecommended && v.recommended === null) {
                            v.recommended = false
                        }
                        return v
                    })

                    this.title = data.title
                    this.hasRecommended = data.hasRecommended
                    this.hasMultipleProducts = data.hasMultipleProducts
                    this.subTitle = data.subTitle
                    this.selectedProducts = data.selectedProducts.length === 0 ? [{...data.products[0]}] : data.selectedProducts
                    this.products = data.products
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        productSelected(index, el) {
            this.selectedProducts = this.selectedProducts.map((p, i) => {
                if(i === index) {
                    return {...el, recommended: el.id !== null && p.recommended}
                } else {
                    return p
                }
            })
        },

        saveProduct() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                groupTypeId: this.groupTypeId,
                groupTypeVariantId: this.groupTypeVariantId,
                productId: this.productId,
                data: {
                    selectedProducts: this.selectedProducts.filter(p => p.id !== null)
                }
            }

            this.$store.dispatch('SEND_GROUP_TYPE_PRODUCT', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    components: { CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            .input-wrapper-row {
                margin-bottom: 20px;
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 40px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
