<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <tabs :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el}"></tabs>
                <div class="product-type-data-container" v-show="tabValue === 'data'">
                    <div class="col-left">
                        <div class="input-wrapper" :class="{'error' : errors.name}">
                            <label class="text-input-label" for="product-type-name">{{ $t('product_type.manage.field.name') }}</label>
                            <input type="text" v-model="name" id="product-type-name">
                            <span class="input-error">{{errors.name}}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.needRoundPallet}">
                            <div class="toggle">
                                <input type="checkbox" v-model="needRoundPallet" id="exportable-type-category-edit">
                                <label for="exportable-type-category-edit">{{ $t('product_type.manage.field.need_round_pallet') }}</label>
                            </div>
                            <span class="input-error">{{errors.needRoundPallet}}</span>
                        </div>
                    </div>
                    <div class="col-right">
                    </div>
                </div>
                <div class="product-types-container" v-show="tabValue === 'linkedProductTypes'">
                    <table>
                        <thead>
                        <tr>
                            <td style="width: 130px">Terméktípus</td>
                            <td style="width: 130px">Mennyiség</td>
                            <td style="width: 30px;"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(linkedProductType, index) in linkedProductTypes" :key="index">
                            <td>
                                <custom-select
                                    :filterable="true"
                                    :modal="true"
                                    :selectedItem="linkedProductType.productType"
                                    :options="productTypes"
                                    @changed="(el) => {linkedProductTypes[index].productType = el}"
                                    :large="true"
                                ></custom-select>
                            </td>
                            <td>
                                <input type="text" v-model="linkedProductType.quantity">
                            </td>
                            <td>
                                <input type="button" value="Törlés" @click="removeLinkedProductType(index)">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <input type="button" value="Új terméktípus hozzáadása" @click="addLinkedProductType">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="product-types-container" v-show="tabValue === 'subProductTypes'">
                    <table>
                        <thead>
                        <tr>
                            <td style="width: 130px">Terméktípus</td>
                            <td style="width: 130px">Mennyiség</td>
                            <td style="width: 30px;"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(linkedProductType, index) in subProductTypes" :key="index">
                            <td>
                                <custom-select
                                    :filterable="true"
                                    :modal="true"
                                    :selectedItem="linkedProductType.productType"
                                    :options="productTypes"
                                    @changed="(el) => {subProductTypes[index].productType = el}"
                                    :large="true"
                                ></custom-select>
                            </td>
                            <td>
                                <input type="text" v-model="linkedProductType.quantity">
                            </td>
                            <td>
                                <input type="button" value="Törlés" @click="removeSubProductType(index)">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <input type="button" value="Új terméktípus hozzáadása" @click="addSubProductType">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveProductType" :disabled="loading">
                        {{ $t('product_type.manage.button.save') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('product_type.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import Notification from '../../components/Notification.vue'
import Tabs from "@/components/Tabs.vue";
import CustomSelect from "@/components/dropdowns/CustomSelect.vue";

Vue.use(MdIcon)

export default {
    name: 'ModalProductTypeEdit',
    props: ['open', 'productTypeId'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            name: null,
            needRoundPallet: false,
            linkedProductTypes: [],
            subProductTypes: [],
            productTypes: [],
            tabElements: [
                {
                    handle: 'data',
                    handleName: this.$t('product_type.manage.data.title')
                },
                {
                    handle: 'linkedProductTypes',
                    handleName: this.$t('product_type.manage.linked_product_types.title')
                },
                {
                    handle: 'subProductTypes',
                    handleName: this.$t('product_type.manage.sub_product_types.title')
                }
            ],
            tabValue: 'data',
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_PRODUCT_TYPE', {productTypeId: this.productTypeId}).then(data => {
                    this.title = data.title
                    this.name = data.name
                    this.needRoundPallet = data.needRoundPallet
                    this.productTypes = data.productTypes
                    this.linkedProductTypes = data.linkedProductTypes
                    this.subProductTypes = data.subProductTypes
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveProductType() {
            this.errors = {}

            let payload = {
                productTypeId: this.productTypeId,
                data: {
                    name: this.name,
                    needRoundPallet: this.needRoundPallet,
                    linkedProductTypes: this.linkedProductTypes.map(linkedProductType => {
                        return {
                            id: linkedProductType.productType ? linkedProductType.productType.id : null,
                            quantity: linkedProductType.quantity
                        }
                    }),
                    subProductTypes: this.subProductTypes.map(subProductType => {
                        return {
                            id: subProductType.productType ? subProductType.productType.id : null,
                            quantity: subProductType.quantity
                        }
                    })
                }
            }

            this.$store.dispatch('SEND_PRODUCT_TYPE', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
        removeLinkedProductType(index) {
            this.linkedProductTypes.splice(index, 1)
        },
        addLinkedProductType() {
            this.linkedProductTypes.push({
                productType: null,
                quantity: 1
            })
        },
        removeSubProductType(index) {
            this.subProductTypes.splice(index, 1)
        },
        addSubProductType() {
            this.subProductTypes.push({
                productType: null,
                quantity: 1
            })
        }
    },

    components: { Tabs, CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        width: 800px;

        &-body {

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--checkbox {
                    margin-bottom: 0;
                    white-space: nowrap;
                    margin-top: -30px;
                }

                .checkbox {
                    display: block;
                }

                .dropdown-list {
                    top: 76px;
                }

                .toggle {
                    margin-left: 22px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .product-types-container {
                float: left;
                width: 100%;
                margin-top: 15px;

                table {
                    width: 100%;
                    border-spacing: 5px;
                    border-collapse: separate;

                    .toggle {
                        position: relative;

                        label {
                            padding-left: 0;
                            position: absolute;
                            top: 0;
                            left: 27px;
                        }
                    }
                }
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
