<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="col-left">
                    <div class="input-wrapper" :class="{'error' : errors.name}">
                        <label class="text-input-label" for="surface-name">Név</label>
                        <input type="text" v-model="name" id="surface-name">
                        <span class="input-error">{{errors.name}}}</span>
                    </div>
                </div>
                <div class="col-right">
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveSurface" :disabled="loading">
                        {{ $t('surface.manage.button.save') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('surface.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalSurfaceEdit',
    props: ['open', 'surfaceId'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            name: null,
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_SURFACE', {surfaceId: this.surfaceId}).then(data => {
                    this.title = data.title
                    this.name = data.name
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveSurface() {
            this.errors = {}

            let payload = {
                surfaceId: this.surfaceId,
                data: {
                    name: this.name
                }
            }

            this.$store.dispatch('SEND_SURFACE', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--checkbox {
                    margin-bottom: 0;
                }

                .checkbox {
                    display: block;
                }

                .dropdown-list {
                    top: 76px;
                }

                .toggle {
                    margin-left: 22px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
