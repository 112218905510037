// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import i18n from './i18n'
import store from './store'
import './plugins/polyfills'
import './plugins/filters'
import interceptorsSetup from './plugins/interceptors'
import InputMask from 'inputmask'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    let pageTitle = i18n.t('common.name')
    if (typeof to.meta.title !== 'undefined') {
        pageTitle += ' - ' + to.meta.title
    }
    document.title = pageTitle

    next()
})

// Closest polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// Google Analytics methods
ga('set', 'page', router.currentRoute.path)
ga('send', 'pageview')

router.afterEach(( to ) => {
    ga('set', 'page', to.path)
    ga('send', 'pageview')
})

interceptorsSetup()

const VueInputMask = {
    install: (Vue) => {
        Vue.directive('mask', {
            bind: (el, binding) => {
                InputMask(binding.value).mask(el)
            }
        })
    }
}

Vue.use(VueInputMask)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: { App },
    template: '<App/>'
})
