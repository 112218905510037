<template>
    <div class="panel-total-component">
        <div class="col-left">
            <button class="btn btn-secondary" :disabled="loading" type="button" @click="nextStep" v-if="isOffer && permissions.canOrder">
                <md-icon>shopping_cart</md-icon>
                <span>
                    {{ $t('offer.page.edit.category.total.button.order') }}
                </span>
            </button>
            <button class="btn btn-secondary" :disabled="loading" type="button" @click="nextStep" v-if="isTextOffer">
                <span>
                    {{ $t('offer.page.edit.text.total.button.load_default') }}
                </span>
            </button>
        </div>

        <div class="col-right">
            <template v-if="isWorkerOffer">
                <div class="col-listprice" v-if="isOffer && !isTextOffer && data.listPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.list_price') }}</span>
                    <span class="gross">
                    {{data.listPriceGross | formatPrice}}
                    <span>Ft</span>
                </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.listPriceNet | formatPrice}} Ft</span>
                </div>

                <div class="col-sales" v-if="isOffer && !isTextOffer && data.salesPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.sales_price') }}</span>
                    <span class="gross">
                        {{data.salesPriceGross | formatPrice}}
                        <span>Ft</span>
                    </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.salesPriceNet | formatPrice}} Ft</span>
                </div>

                <div class="col-total" v-if="isOffer && !isTextOffer && data.buyerPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.purchase_price') }}</span>
                    <span class="gross">
                        {{data.buyerPriceGross | formatPrice}}
                        <span>Ft</span>
                    </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.buyerPriceNet | formatPrice}} Ft</span>
                </div>
            </template>

            <template v-else>
                <div class="col-listprice" v-if="isOffer && !isTextOffer && data.listPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.list_price') }}</span>
                    <span class="gross">
                    {{data.listPriceGross | formatPrice}}
                    <span>Ft</span>
                </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.listPriceNet | formatPrice}} Ft</span>
                </div>

                <div class="col-buyer" v-if="isOffer && !isTextOffer && data.buyerPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.buyer_price') }}</span>
                    <span class="gross">
                        {{data.buyerPriceGross | formatPrice}}
                        <span>Ft</span>
                    </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.buyerPriceNet | formatPrice}} Ft</span>
                </div>

                <div class="col-total" v-if="isOffer && !isTextOffer && data.dealerPrice !== null && !loading">
                    <span class="label">{{ $t('offer.page.edit.category.total.text.dealer_price') }}</span>
                    <span class="gross">
                        {{data.dealerPriceGross | formatPrice}}
                        <span>Ft</span>
                    </span>
                    <span class="net">{{ $t('offer.page.edit.category.total.text.net') }} {{data.dealerPriceNet | formatPrice}} Ft</span>
                </div>
            </template>

            <div class="col-button">
                <button
                    class="btn btn-primary"
                    type="button"
                    @click="exportData"
                    v-if="isOffer || isTextOffer"
                    :disabled="loading"
                >
                    <md-icon>save_alt</md-icon>
                    <template v-if="!viewOnly || canEditSales"><span>{{ $t('offer.page.edit.total.button.save_and_export') }}</span></template>
                    <template v-else><span>{{ $t('offer.page.edit.total.button.export') }}</span></template>
                </button>

                <button class="btn btn-primary" type="button" @click="nextStep" v-if="!isOffer && !isTextOffer && !viewOnly" :disabled="loading || data.orderProductsAmount < 1">
                    <md-icon>shopping_cart</md-icon>
                    <span>{{ $t('order.page.edit.total.button.order') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PanelTotal',
    props: ['loading', 'data', 'showExportButton', 'isOffer', 'isTextOffer', 'discountType', 'isWorkerOffer'],

    data() {
        return {}
    },

    methods: {
        exportData() {
            this.$emit('exportData')
        },

        nextStep() {
            this.$emit('nextStep')
        }
    },

    computed: {
        ...mapGetters(['mainStates', 'viewOnly', 'canEditSales', 'permissions', 'offerInformation'])
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

    @media only screen and (max-width: 600px) {
    .col-button{
            position: absolute;
            right: 0;
            bottom: 0;

            span{
                display: none;
            }
        }
}

.panel-total-component {
    position: fixed;
    display: flex;
    background: $color-white;
    width: 100%;
    left: 0;
    bottom: 0;
    border: 1px solid $color-gray-border;
    box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.1);
    z-index: 500;
    justify-content: space-between;
    align-items: center;

    .panel--mobile-buttons{
        display: none;

        @media only screen and (max-width: 600px) {
            display: block;
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 5px;
        padding: 10px;
        z-index: 5;
    min-height: 150px;
    }

    .col-left {
        padding: 30px 50px 30px 24px;

        @media only screen and (max-width: 600px) {
            padding: 0px;
            position: absolute;
            right: 11px;
            bottom: 30px;
            z-index: 9;

            button{
                span{
                    display: none;
                }
            }
        }

        .info {
            position: absolute;
            bottom: 10px;
            font-size: 12px;
            color: $color-gray-5;
        }
    }

    .col-right {
        position: relative;
        display: flex;
        font-weight: bold;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .label {
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;

            @media only screen and (max-width: 600px) {
                font-size: 14px;
            }
        }

        .info {
            position: absolute;
            right: 43px;
            bottom: 4px;
            font-size: 11px;
            color: $color-gray-5;
            max-width: 200px;
        }

        .col-listprice,
        .col-sales,
        .col-buyer,
        .col-total {
            padding-top: 23px;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 600px) {
                padding: 0;
            }

            .gross {
                font-size: 22px;
                padding: 5px 0 1px;

                @media only screen and (max-width: 600px) {
                    font-size: 16px;
                }

                span {
                    font-size: 18px;
                    font-family: $font-primary;

                    @media only screen and (max-width: 600px) {
                        font-size: 14px;
                    }
                }
            }

            .net {
                font-size: 12px;
                color: $color-gray-5;
            }
        }

        .col-listprice,
        .col-sales,
        .col-buyer {
            color: $color-gray-1;

            .gross {
                color: $color-black-1;
                font-size: 17px;
                padding-top: 8px;
                padding-bottom: 2px;

                @media only screen and (max-width: 600px) {
                    padding: 0px;
                    font-size: 14px;
                }
            }
        }

        .col-listprice {
            padding: 23px 23px 23px 30px;

            @media only screen and (max-width: 600px) {
                padding: 3px;
                border-bottom: 1px solid #CBCBCB;
            }

            &.large {
                .gross {
                    font-size: 22px;
                    padding: 5px 0 1px;
                }
            }
        }

        .col-sales,
        .col-buyer {
            position: relative;
            padding: 23px 72px 23px 40px;
            margin-left: 12px;

            @media only screen and (max-width: 600px) {
                padding: 3px;
                margin-left: 0px;
                border-bottom: 1px solid #CBCBCB;

            }


            &:before {
                content: '';
                position: absolute;
                top: 20px;
                left: 0;
                height: calc(100% - 40px);
                width: 1px;
                border-right: 0.5px dashed $color-gray-border;
            }
        }

        .col-total {
            border-left: 1px solid $color-gray-border;
            padding-left: 20px;
            text-align: right;
            color: $color-black-1;

            @media only screen and (max-width: 600px) {
                padding-left: 0;
                text-align: left;
                border-left: 0;
                padding: 3px;
            }

            &-order {
                margin-left: 25px;
            }
        }

        .col-button {
            padding: 30px 50px 30px 40px;

            @media only screen and (max-width: 600px) {
                padding: 0px;
                position: absolute;
                right: 0;
                bottom: 75px;

                span{
                    display: none;
                }
            }
        }
    }
}
</style>
