import Vue from "vue";

export default {
    // Offers
    offerListProjectCount(state) {
        return state.offers.project_count || {}
    },

    offerListPages(state) {
        return state.offers.pages || {}
    },

    stalledOffers(state) {
        return state.stalledOffers || {}
    },

    offerListFilters(state) {
        return state.offers.filters || []
    },

    offerListGroups(state) {
        return state.offers.groups || []
    },

    offerListAdvisories(state) {
        return state.advisories || []
    },

    projectListAdvisories(state) {
        return state.advisories || []
    },

    offerListDealers(state) {
        return state.allDealers || []
    },

    offerListMeta(state) {
        return state.offers.meta || {}
    },

    offerListActiveFilters(state) {
        return state.offerListActiveFilters || {}
    },

    offerListScrollPosition(state) {
        return state.offerListScrollPosition || 0
    },

    tags(state) {
        return state.tags
    },

    selectedTagsCount(state) {
        return state.selectedTagsCount
    },

    // Orders
    orderListPages(state) {
        return state.orders.pages || {}
    },

    orderListFilters(state) {
        return state.orders.filters || []
    },

    orderListGroups(state) {
        return state.orders.groups || []
    },

    orderListDealers(state) {
        return state.orders.dealers || []
    },

    orderListAdvisories(state) {
        return state.orders.advisories || []
    },

    orderListMeta(state) {
        return state.orders.meta || {}
    },

    orderListActiveFilters(state) {
        return state.orderListActiveFilters || {}
    },

    orderListScrollPosition(state) {
        return state.orderListScrollPosition || 0
    },

    // Order data
    orderData(state) {
        return state.orderData
    },

    orderDataTabs(state) {
        return state.orderDataTabs
    },

    transportsInTabs(state) {
        return state.transportsInTabs
    },

    activeOrderTab(state) {
        return state.activeOrderTab
    },

    allTransportsInTabs(state){
        return state.allTransportsInTabs
    },

    selectedOrderDataTab(state) {
        return state.selectedOrderDataTab
    },

    projectData(state) {
        return state.projectData
    },

    orderDetails(state) {
        return state.orderDetails
    },

    orderStatus(state) {
        return state.orderStatus
    },

    // projects
    projectListPages(state) {
        return state.projects.pages || {}
    },

    projectListMeta(state){
        return state.projects.meta  || {}
    },

    projectListActiveFilters(state) {
        return state.projectListActiveFilters || {}
    },

    projectListFilters(state) {
        return state.projects.filters || []
    },

    // Object
    objectListPages(state) {
        return state.objects.pages || {}
    },

    objectListFilters(state) {
        return state.objects.filters || []
    },

    objectListMeta(state) {
        return state.objects.meta || {}
    },

    objectListActiveFilters(state) {
        return state.objectListActiveFilters || {}
    },

    objectListScrollPosition(state) {
        return state.objectListScrollPosition || 0
    },

    // Dealers
    dealerListPages(state) {
        return state.dealers.pages || {}
    },

    dealerListFilters(state) {
        return state.dealers.filters || []
    },

    dealerListAdvisories(state) {
        return state.dealers.advisoryUsers || []
    },

    dealerListTypes(state) {
        return state.dealers.types || []
    },

    dealerListMeta(state) {
        return state.dealers.meta || {}
    },

    dealerListActiveFilters(state) {
        return state.dealerListActiveFilters || {}
    },

    dealerListScrollPosition(state) {
        return state.dealerListScrollPosition || 0
    },

    // Workers
    workerListPages(state) {
        return state.workers.pages || {}
    },

    workerListMeta(state) {
        return state.workers.meta || {}
    },

    workerListActiveFilters(state) {
        return state.workerListActiveFilters || {}
    },

    workerProjectList(state) {
        return state.workerProjectList || []
    },

    workersAll(state) {
        return state.workersAll || []
    },

    // Regions
    regionRows(state) {
        return state.regions.rows || []
    },

    regionAdvisories(state) {
        return state.regions.advisoryUsers || []
    },

    // Product Admin
    productListPages(state) {
        return state.products.pages || {}
    },

    productListTypes(state) {
        return state.products.types || []
    },

    productListMeta(state) {
        return state.products.meta || {}
    },

    productListActiveFilters(state) {
        return state.productListActiveFilters || {}
    },

    // Type Product Admin
    groupTypeProductRows(state) {
        return state.groupTypeProducts.rows || []
    },

    groupTypeCategories(state) {
        return state.groupTypeProducts.categories || []
    },

    groupTypeProductTypes(state) {
        return state.groupTypeProducts.productTypes || []
    },

    // Category Product Admin
    groupCategoryProductRows(state) {
        return state.groupCategoryProducts.rows || []
    },

    groupCategoryProductCategories(state) {
        return state.groupCategoryProducts.categories || []
    },

    // Category Product Admin
    groupCategoryProductTypeRows(state) {
        return state.groupCategoryProductTypes.rows || []
    },

    // Category Product Admin
    groupTypeVariantRows(state) {
        return state.groupTypeVariants.rows || []
    },

    groupTypeVariantCategories(state) {
        return state.groupTypeVariants.typeCategories || []
    },

    groupTypeVariantTypes(state) {
        return state.groupTypeVariants.types || []
    },

    // Category Product Admin
    groupProductRows(state) {
        return state.groupProducts.rows || []
    },

    // Category Product Admin
    productTypeRows(state) {
        return state.productTypes.rows || []
    },

    // Category Product Admin
    colorRows(state) {
        return state.colors.rows || []
    },

    // Category Product Admin
    unitRows(state) {
        return state.units.rows || []
    },

    // Category Product Admin
    carrierRows(state) {
        return state.carriers.rows || []
    },

    // Category Product Admin
    surfaceRows(state) {
        return state.surfaces.rows || []
    },

    // Category Product Admin
    groupCategorySortRows(state) {
        return state.groupCategorySort || []
    },
    groupTypeSortRows(state) {
        return state.groupTypeSort || []
    },

    reAuthenticated(state) {
        return state.reAuthenticated || false
    },

    // Users
    userListPages(state) {
        return state.users.pages || {}
    },

    userListFilters(state) {
        return state.users.filters || []
    },

    userListDealers(state) {
        return state.users.dealers || []
    },

    userListStatuses(state) {
        return state.users.statuses || []
    },

    userListMeta(state) {
        return state.users.meta || {}
    },

    userListActiveFilters(state) {
        return state.userListActiveFilters || {}
    },

    userRoles(state) {
        return state.userRoles
    },

    allUserRoles(state) {
        return state.allUserRoles || []
    },

    // Global input validation
    validation(state) {
        return state.validation
    },

    notification(state) {
        return state.notification
    },

    orderSettings(state) {
        return state.orderSettings
    },

    modals(state) {
        return state.modals
    },

    getComments(state) {
        return state.additionalDetails.comments
    },

    systemWarrantee(state) {
        return state.additionalDetails.systemWarrantee
    },

    savedComments(state) {
        return state.savedComments
    },

    profile(state) {
        return state.profile
    },

    userAuthEmail(state) {
        return state.authEmail
    },

    userAuthRemember(state) {
        return state.authRemember
    },

    userAuthenticated(state) {
        return state.userFetched && (
            (typeof state.authToken === "string" && state.authToken !== '') ||
            (state.authSession)
        )
    },

    userCanSeeFilters(state) {
        return state.userFetched && state.profile && (state.profile.isAdmin || state.profile.isInternal)
    },

    userCanSeeExtraFields(state) {
        return state.profile.isAdmin || state.profile.isInternal
    },

    userIsAdmin(state) {
        return state.userFetched && state.profile && state.profile.isAdmin
    },

    userHasObjects(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasObjects
    },

    userHasOffers(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasOffers
    },

    userHasOrders(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasOrders
    },

    userAccessoryOnly(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.accessoryOnly
    },

    userCanCreateOrder(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.canCreateOrder
    },

    userCanCreateDealer(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.canCreateDealer
    },

    userCanCreateObject(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.canCreateObject
    },

    userCanChangeDealerProducts(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.canChangeDealerProducts
    },

    userHasDealers(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasDealers
    },

    userHasWorkers(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasWorkers
    },

    userHasRegionAdmin(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasRegionAdmin
    },

    userHasProductAdmin(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasProductAdmin
    },

    userHasUsers(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasUsers
    },

    userDealerOnly(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.dealerOnly
    },

    userOrderCreate(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasOrderCreate
    },

    userOfferStatistics(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasOfferStatistics
    },

    userAccessoryOrderStatistics(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.hasAccessoryOrderStatistics
    },

    userCanExportPlanCalculations(state) {
        return state.userFetched && state.profile && state.profile.permissions && state.profile.permissions.canExportPlanCalculations
    },

    offerProductTypes(state) {
        return state.offerProductTypes
    },

    orderProductTypes(state) {
        return state.orderProductTypes
    },

    objectProductTypes(state) {
        return state.objectProductTypes
    },

    objectStandaloneTypes(state) {
        return state.objectStandaloneTypes
    },

    projectOffers(state) {
        return state.projectOffers
    },

    projectDealers(state) {
        return state.projectDealers
    },

    newOfferGroup(state) {
        return state.newOfferGroup
    },

    offerInformation(state) {
        return state.offerInformation
    },

    originalOfferInformation(state) {
        return state.originalOffer.offer || {}
    },

    originalOfferVariantId(state) {
        if (!state.originalOffer.tab) {
            return null
        }

        if (!state.originalOffer.tab.variant) {
            return null
        }

        return state.originalOffer.tab.variant.id || null
    },

    originalOfferVariantFullName(state) {
        if (!state.originalOffer.tab) {
            return null
        }

        if (!state.originalOffer.tab.variant) {
            return null
        }

        return state.originalOffer.tab.variant.fullName
    },

    orderInformation(state) {
        return state.orderInformation
    },

    offerActions(state) {
        return state.offerActions
    },

    mainStates(state) {
        return state.mainStates
    },

    discounts(state) {
        return state.discounts
    },

    discountType(state) {
        return state.discounts.discountType
    },

    objects(state) {
        return state.objects
    },

    pallets(state) {
        return state.pallets
    },

    customPallets(state) {
        return state.customPallets
    },

    customDemolitionPallets(state) {
        return state.customDemolitionPallets
    },

    customTransport(state) {
        return state.customTransport
    },

    customDealerTransport(state) {
        return state.customDealerTransport
    },

    dealerTransport(state) {
        return state.dealerTransport || 0
    },

    services(state) {
        return state.services
    },

    totalServicePrice(state) {
        return state.totalServicePrice
    },

    transports(state) {
        return state.transports
    },

    selectedTransport(state) {
        return state.selectedTransport
    },

    transportPrices(state) {
        return state.transportPrices
    },

    warehouse(state) {
        return state.warehouse
    },

    zipCodesList(state) {
        return state.zipCodesList
    },

    citiesList(state) {
        return state.citiesList
    },

    userSettings(state) {
        return state.userSettings
    },

    displayNetPrice(state) {
        return state.displayNetPrice
    },

    permissions(state) {
        return state.permissions
    },

    viewOnly(state) {
        return state.viewOnly
    },

    canTag(state) {
        return state.canTag
    },

    canEditSales(state) {
        return state.canEditSales
    },

    planCalculation(state) {
        return state.planCalculation
    },

    productText(state) {
        return state.productText || {}
    },

    productTextDebug(state) {
        return state.productTextDebug
    },

    recommendedProductId(state) {
        return state.recommendedProductId
    },

    loginData(state) {
        return state.loginData
    }
}
