<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper" :class="{'error' : errors.colorId}" v-if="hasColor">
                    <label class="text-input-label">{{ $t('group_type_variant.manage.field.color') }}</label>
                    <custom-select
                        :filterable="true"
                        :modal="true"
                        :selectedItem="color"
                        :options="colors"
                        @changed="(el) => {this.color = el}"
                        :large="true"
                    ></custom-select>
                    <span class="input-error">{{errors.colorId}}</span>
                </div>
                <div class="input-wrapper" :class="{'error' : errors.sidingId}" v-if="hasSiding">
                    <label class="text-input-label">{{ $t('group_type_variant.manage.field.siding') }}</label>
                    <custom-select
                        :filterable="true"
                        :modal="true"
                        :selectedItem="siding"
                        :options="sidings"
                        @changed="(el) => {this.siding = el}"
                        :large="true"
                    ></custom-select>
                    <span class="input-error">{{errors.sidingId}}</span>
                </div>
                <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.deleted}">
                    <div class="toggle">
                        <input type="checkbox" v-model="deleted" id="deleted-group-type-variant-edit">
                        <label for="deleted-group-type-variant-edit">{{ $t('group_type_variant.manage.field.deleted') }}</label>
                    </div>
                    <span class="input-error">{{errors.deleted}}</span>
                </div>
                <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.discontinued}">
                    <div class="toggle">
                        <input type="checkbox" v-model="discontinued" id="discontinued-group-type-variant-edit">
                        <label for="discontinued-group-type-variant-edit">{{ $t('group_type_variant.manage.field.discontinued') }}</label>
                    </div>
                    <span class="input-error">{{errors.discontinued}}</span>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="save" :disabled="loading">
                        {{ $t('group_type_variant.manage.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_type_variant.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupTypeVariantEdit',
    props: ['open', 'groupId', 'categoryId', 'typeId', 'variantId', 'createTarget'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            deleted: false,
            discontinued: false,
            color: null,
            colors: [],
            siding: null,
            sidings: [],
        }
    },

    computed: {
        hasColor() {
            return typeof this.colors !== 'undefined' && this.colors.length > 0
        },
        hasSiding() {
            return typeof this.sidings !== 'undefined' && this.sidings.length > 0
        },
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_TYPE_VARIANT', {
                    groupId: this.groupId,
                    categoryId: this.categoryId,
                    typeId: this.typeId,
                    variantId: this.variantId,
                    createTarget: this.createTarget,
                }).then(data => {
                    this.title = data.title
                    this.deleted = data.deleted
                    this.discontinued = data.discontinued
                    this.color = data.color
                    this.colors = data.colors
                    this.siding = data.siding
                    this.sidings = data.sidings
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        save() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                categoryId: this.categoryId,
                typeId: this.typeId,
                variantId: this.variantId,
                createTarget: this.createTarget,
                data: {
                    deleted: this.deleted,
                    discontinued: this.discontinued,
                    colorId: this.color ? this.color.id : null,
                    sidingId: this.siding ? this.siding.id : null,
                }
            }

            this.$store.dispatch('SEND_GROUP_TYPE_VARIANT', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    components: { Notification, CustomSelect }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 600px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
