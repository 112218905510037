<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ product.title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="product-group-container">
                    <div class="col-left">
                        <div class="input-wrapper" :class="{'error' : errors.productId}">
                            <label class="text-input-label">Termék</label>
                            <custom-select
                                :filterable="true"
                                :modal="true"
                                :selectedItem="product.product"
                                :options="product.products"
                                @changed="(el) => {product.product = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.productId}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.pallet}">
                            <label class="text-input-label">Raklap</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="product.pallet"
                                :options="product.pallets"
                                @changed="(el) => {product.pallet = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.pallet}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.subText}">
                            <label class="text-input-label" for="group-product-sub-text">Egyedi rövid leírás</label>
                            <input type="text" v-model="product.subText" :placeholder="product.originalSubText" id="group-product-sub-text">
                            <span class="input-error">{{errors.subText}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.transportIds}">
                            <label class="text-input-label">Szállítási módok</label>
                            <div class="input-wrapper">
                                <div class="checkbox" v-for="transport in product.transports" :key="transport.id">
                                    <input type="checkbox" :value="transport.id" :id="`group-product-transport-${transport.id}`" v-model="product.transportIds">
                                    <label :for="`group-product-transport-${transport.id}`">{{ transport.name }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.discountIds}" v-if="product.discounts.length > 0">
                            <label class="text-input-label">Kedvezmények</label>
                            <div class="input-wrapper">
                                <vue-tagger
                                    :tags="product.discounts"
                                    :selected-tags="product.discountIds"
                                    @changed="(value) => {product.discountIds = value}"
                                    :placeholder="'Enter a tag...'"
                                    :delimiter="','"
                                ></vue-tagger>
                            </div>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="input-wrapper" :class="{'error' : errors.productType}" v-if="product.hasProductType">
                            <label class="text-input-label">Egyedi termék típus</label>
                            <custom-select
                                :filterable="true"
                                :modal="true"
                                :selectedItem="product.productType"
                                :options="product.productTypes"
                                @changed="(el) => {product.productType = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.productType}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.calculatePalletAbove}">
                            <label class="text-input-label" for="group-product-calculate-pallet-above">Számoljon raklapot, ha a mennyiség több mint</label>
                            <input type="text" v-model="product.calculatePalletAbove" id="group-product-calculate-pallet-above">
                            <span class="input-error">{{errors.calculatePalletAbove}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.needObjectAbove}">
                            <label class="text-input-label" for="group-product-need-object-above">Rendelésnél objekt szükséges, ha a mennyiség több mint</label>
                            <input type="text" v-model="product.needObjectAbove" id="group-product-need-object-above">
                            <span class="input-error">{{errors.needObjectAbove}}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.rank}" v-if="product.hasProductType">
                            <label class="text-input-label" for="group-product-rank">Sorrend legördülő esetén</label>
                            <input type="text" v-model="product.rank" id="group-product-rank">
                            <span class="input-error">{{errors.rank}}}</span>
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveProduct" :disabled="loading">
                        {{ $t('group_product.manage.button.save') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_product.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'
import VueTagger from '../../components/VueTagger.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupProductEdit',
    props: ['open', 'groupId', 'groupProductId', 'productNumber'],

    data() {
        return {
            loading: false,
            errors: {},
            product: {
                subText: null,
                originalSubText: null,
                pallet: null,
                pallets: [],
                product: null,
                products: [],
                productType: null,
                productTypes: [],
                transports: [],
                transportIds: [],
                needObjectAbove: null,
                calculatePalletAbove: null,
                rank: null,
                discounts: [],
                discountIds: [],
                hasProductType: false
            }
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_PRODUCT', {groupId: this.groupId, groupProductId: this.groupProductId}).then(product => {
                    this.product = product
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveProduct() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                groupProductId: this.groupProductId,
                data: {
                    productId: this.product.product ? this.product.product.id : null,
                    productTypeId: this.product.productType ? this.product.productType.id : null,
                    palletId: this.product.pallet ? this.product.pallet.id : null,
                    discountIds: this.product.discountIds,
                    transportIds: this.product.transportIds,
                    needObjectAbove: this.product.needObjectAbove,
                    calculatePalletAbove: this.product.calculatePalletAbove,
                    subText: this.product.subText,
                    rank: this.product.rank,
                }
            }

            this.$store.dispatch('SEND_GROUP_PRODUCT', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
    },

    components: { VueTagger, CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--checkbox {
                    margin-bottom: 0;
                }

                .checkbox {
                    display: block;
                }

                .dropdown-list {
                    top: 76px;
                }

                .toggle {
                    margin-left: 22px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
