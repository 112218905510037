<template>
    <div class="job-navigation-component" :class="{'fixed-to-top' : fixedToTop}" v-click-outside="() => {this.openRelatedOffers = false}">
        <button type="button" class="button-logo" @click="closePage">
            <img alt="BMI logo" src="@/assets/images/logo-bmi.png" class="logo">
        </button>

        <div class="multi-menu-container" v-if="!loading">
            <button
                v-if="title"
                type="button"
                class="btn btn-simple button-multi-menu is-order"
            >
                <span class="text-part">
                    <span class="project-name project-name--without-menu">{{title}}</span>
                    <span class="offer-name offer-name--without-menu" v-if="subTitle">{{subTitle}}</span>
                </span>
            </button>

            <button
                v-if="routeName === 'Order' || routeName === 'NewOrder'"
                type="button"
                class="btn btn-simple button-multi-menu is-order"
            >
                <span class="text-part">
                    <span class="project-name project-name--without-menu" v-if="orderData.title">{{orderData.title}}</span>
                    <span class="offer-name offer-name--without-menu" v-if="orderInformation.customerOrderId">{{orderInformation.customerOrderId}}</span>
                </span>
            </button>

            <button
                v-if="routeName === 'OrderDetails' || routeName === 'OrderDetailsFromOffer'"
                type="button"
                class="btn btn-simple button-multi-menu is-order"
            >
                <span class="text-part">
                    <span class="project-name project-name--without-menu" v-if="orderDetails.title">{{orderData.title}}</span>
                    <span class="offer-name offer-name--without-menu" v-if="subTitle">{{subTitle}}</span>
                </span>
            </button>

            <button
                v-if="isOffer || isTextOffer"
                @click="openProjectOptions = !openProjectOptions"
                type="button"
                class="btn btn-simple button-multi-menu"
            >
                <span class="text-part">
                    <span class="project-name" v-if="orderData.title">{{orderData.title}}</span>
                    <span class="offer-name" v-if="projectData">{{projectData.name}}</span>
                </span>
                <md-icon>more_vert</md-icon>
            </button>

            <multi-menu
                v-if="isOffer || isTextOffer "
                v-show="openProjectOptions"
                @close="openProjectOptions = false"
                :elements="multiMenuElements"
                @selected="multiMenuAction"
            ></multi-menu>

        </div>

        <div class="basic-data-container" v-if="offerInformation && !loading">
            <div class="basic-data-item" v-if="offerInformation.validTo && (isOffer || isTextOffer)">
                <span class="label">{{ $t('offer.page.edit.job_navigation.text.valid_to') }}</span>
                <button type="button" class="btn btn-plain" @click="openDataEditModal">{{getNiceDate(offerInformation.validTo, this.$t('offer.page.edit.job_navigation.date_format.valid_to'))}}</button>
            </div>
            <div class="basic-data-item" v-if="offerInformation.advisoryUser && ( isOffer || isTextOffer )">
                <span class="label">{{ $t('offer.page.edit.job_navigation.text.advisory') }}</span>
                <button
                    @click="openDataEditModal"
                    type="button"
                    class="btn btn-plain"
                    >{{offerInformation.advisoryUser.name}}</button>
            </div>
            <div class="basic-data-item" v-if="offerInformation.editorUser && ( isOffer || isTextOffer )">
                <span class="label">{{ $t('offer.page.edit.job_navigation.text.editor') }}</span>
                <button
                    @click="openDataEditModal"
                    type="button"
                    class="btn btn-plain"
                >{{offerInformation.editorUser.name}}</button>
            </div>
            <div class="basic-data-item" v-if="offerInformation.relatedOffers.length">
                <span class="label">{{ $t('offer.page.edit.job_navigation.text.related_offers') }}</span>
                <button
                    @click="openRelatedOffers = !openRelatedOffers"
                    type="button"
                    class="btn btn-plain"
                >{{ relatedOfferName }}</button>

                <related-offer-dropdown @clicked="multiMenuAction" :open="openRelatedOffers" :options="relatedOffersElements"></related-offer-dropdown>
            </div>
        </div>

        <div class="right-container">
            <div class="offer-data" v-if="(offerInformation.number || currentOfferName) && ( isOffer || isTextOffer )">
                <span class="label label--name" v-if="currentOfferName" :title="currentOfferName">{{ currentOfferName }}</span>
                <span class="label" v-if="offerInformation.number">{{ $t('offer.page.edit.job_navigation.text.number') }} <strong>{{ offerInformation.number }}</strong></span>
            </div>

            <button
                :disabled="loading"
                v-if="(isOffer || isTextOffer || isOrder || isOrderDetails || isObjectAdd || isDealerProducts || isProductVisibility) && (!viewOnly || canEditSales)"
                type="button"
                class="btn button-save"
                @click="saveWork">
                    {{ $t('common.job_navigation.button.save') }}
            </button>

            <button
                v-if="isObjectAdd"
                @click="exportWork"
                :class="{'is-loading' : loadingExport}"
                :disabled="loadingExport || loading"
                type="button"
                class="btn button-export btn-secondary"><md-icon>save_alt</md-icon> {{ $t('object.page.edit.job_navigation.button.export') }}</button>
            <button v-if="(isObjectAdd | isOrderDetails) && offerId" type="button" class="btn button-export btn-secondary" @click="goToOffer"> {{ $t('common.job_navigation.button.back_to_offer') }}</button>
            <button v-if="isObjectAdd && orderId" type="button" class="btn button-export btn-secondary" @click="closePage">{{ $t('common.job_navigation.button.back_to_order') }}</button>
            <button v-if="isOrderDetails && orderId" type="button" class="btn button-export btn-secondary" @click="goToOrderProducts">{{ $t('order.page.edit.job_navigation.button.back_to_products') }}</button>
            <button v-if="isOffer && originalOfferId" type="button" class="btn button-export btn-secondary" @click="goToOriginalOffer">{{ $t('offer.page.edit.job_navigation.button.go_to_original_offer') }}</button>
            <button :disabled="loading" v-if="isDealerProducts" type="button" class="btn button-export btn-secondary" @click="resetRestrictions">{{ $t('dealer.page.products.job_navigation.button.default') }}</button>

            <div v-if="offerInformation && (isOffer || isTextOffer)" class="status-icon">
                <md-icon v-if="offerInformation.viewType === 'edit'">edit</md-icon>
                <md-icon v-if="offerInformation.viewType === 'eye'">remove_red_eye</md-icon>
                <md-icon v-if="offerInformation.viewType === 'lock'">lock</md-icon>
            </div>
        </div>

        <button type="button" class="button-close" :class="{ 'ml-0' : (isOffer || isTextOffer || isOrder || isOrderDetails || isObjectAdd || isDealerProducts || isProductVisibility)}" @click="closePage">
            <md-icon>close</md-icon>
        </button>

        <modal-data-edit v-if="isOffer || isTextOffer" :open="openModalDataEdit" :tabState="modalDataEditState" @close="closeModalDataEdit"></modal-data-edit>

        <modal-project-offers v-if="isOffer || isTextOffer" :open="openProjectOffersModal" @close="openProjectOffersModal = false"></modal-project-offers>

        <modal-project-duplicate :open="openProjectDuplicateModal" @close="openProjectDuplicateModal = false"></modal-project-duplicate>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @secondaryPrimaryAction="offerDelete(true)"
            @accepted="offerDelete(false)"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'

import { MdIcon } from 'vue-material/dist/components'
import MultiMenu from '../../components/dropdowns/MultiMenu.vue'
import ModalDataEdit from '../../components/modals/ModalDataEdit.vue'
import ModalProjectOffers from '../../components/modals/ModalProjectOffers.vue'
import ModalProjectDuplicate from '../../components/modals/ModalProjectDuplicate.vue'
import RelatedOfferDropdown from '../../components/dropdowns/RelatedOfferDropdown.vue'
import ModalConfirmation from '../../components/modals/ModalConfirmation.vue'

import moment from '../../plugins/moment'

Vue.use(MdIcon)

export default {
    name: 'JobNavigation',
    props: ['loading', 'isOffer', 'offerName', 'isTextOffer', 'isOrder', 'isOrderDetails', 'isObjectAdd', 'isDealerProducts', 'isProductVisibility', 'fixedToTop', 'originalOfferId', 'offerId', 'orderId', 'viewOnly', 'canEditSales', 'loadingExport', 'title', 'subTitle'],

    data() {
        return {
            currentOfferName: this.offerName,

            openRelatedOffers: false,
            openProjectOptions: false,
            openModalDataEdit: false,
            modalDataEditState: 'offerData',
            openProjectOffersModal: false,
            openProjectDuplicateModal: false,
            setOfferActions: true,
            orderMultiMenuElements: [],
            deleteOfferData: null,
            openConfirmationDeleteModal: false
        }
    },

    watch: {
        offerName(val) {
            this.currentOfferName = val
        }
    },

    methods: {
        openDataEditModal() {
            this.openModalDataEdit = true
            this.modalDataEditState = 'offerData'
        },

        closePage() {
            this.$emit('closePage')
        },

        closeModalDataEdit(newOfferName = false) {
            if(newOfferName !== false) {
                this.currentOfferName = newOfferName
            }
            this.openModalDataEdit = false
        },

        multiMenuAction(value) {
            if(typeof value === 'string') {
                value = {
                    action: value
                }
            }

            let newData = {}

            switch (value.action) {
            case 'new-offer':
                if (value.groupType === 'text') {
                    this.$router.push({name: 'NewOfferText', params: {projectId: this.projectData.id, groupId: value.groupId}})
                } else {
                    this.$router.push({name: 'NewOfferCategory', params: {projectId: this.projectData.id, groupId: value.groupId}})
                }
                break
            case 'new-related-offer':
                this.$router.push({name: 'NewRelatedOffer', params: {relatedOfferId: value.offerId, groupId: value.groupId}})
                this.openRelatedOffers = false
                break
            case 'view-related-offer':
                this.$router.push({name: 'OfferCategory', params: {offerId: value.offerId}})
                this.openRelatedOffers = false
                break
            case 'project-data':
                this.openModalDataEdit = true
                this.modalDataEditState = 'projectData'
                break
            case 'offer-data':
                this.openModalDataEdit = true
                this.modalDataEditState = 'offerData'
                break
            case 'project-offers':
                this.openProjectOffersModal = true
                break
            case 'project-copy':
                this.openProjectDuplicateModal = true
                break
            case 'offer-copy':
                newData = {
                    id: this.offerInformation.id
                }
                this.$store.dispatch('COPY_OFFER', newData)
                break
            case 'offer-delete':
                let targetOfferId = null
                let hasRelatedOffers = false

                if(this.offerInformation.relatedOffers.length) {
                    let mainOffer = this.offerInformation.relatedOffers.filter(data => data.isMain)[0]
                    if (mainOffer) {
                        targetOfferId = mainOffer.offerId
                    }

                    hasRelatedOffers = this.offerInformation.relatedOffers.filter(data => !data.isMain && data.offerId !== null).length > 0
                }

                newData = {
                    id: this.offerInformation.id,
                    targetOfferId: targetOfferId,
                    hasRelatedOffers: hasRelatedOffers && !targetOfferId
                }

                this.deleteOfferData = newData
                this.openConfirmationDeleteModal = true
                break
            /*
            case 'offer-archive':
                newData = {
                    id: this.offerInformation.id,
                    archive: true,
                    inList: false
                }
                this.$store.dispatch('UPDATE_OFFER_ARCHIVE', newData)
                break
            */
            case 'offer-unarchive':
                newData = {
                    id: this.offerInformation.id,
                    archive: false,
                    inList: false
                }
                this.$store.dispatch('UPDATE_OFFER_ARCHIVE', newData)
                break
            case 'offer-edit-order':
                this.$router.push({
                    name: 'OrderDetails',
                    params: {orderId: value.orderId}
                })
                break
            case 'offer-view-order':
                this.$router.push({
                    name: 'OrderStatus',
                    params: {orderId: value.orderId}
                })
                break
            }
        },

        offerDelete(withRelated) {
            this.openConfirmationDeleteModal = false
            this.$store.dispatch('DELETE_OFFER', {withRelated, ...this.deleteOfferData})
        },

        saveWork() {
            if (this.isOffer || this.isTextOffer || this.isOrder || this.isOrderDetails) {
                this.$emit('saveOffer')
            }

            if (this.isObjectAdd) {
                this.$emit('saveObjectAdd');
            }

            if (this.isDealerProducts) {
                this.$emit('saveDealerProducts')
            }

            if (this.isProductVisibility) {
                this.$emit('saveProductsVisibilty')
            }
        },

        exportWork() {
            if (this.isObjectAdd) {
                this.$emit('exportObject')
            }
        },

        goToOffer() {
            this.$emit('goToOffer')
        },

        goToOriginalOffer() {
            this.$emit('goToOriginalOffer')
        },

        goToOrderProducts() {
            this.$emit('goToOrderProducts')
        },

        resetRestrictions() {
            this.$emit('resetRestrictions')
        },

        getNiceDate(date, format) {
            return moment(date, 'YYYY-MM-DD').format(String(format))
        }
    },

    computed: {
        ...mapGetters(['orderData', 'orderDetails', 'orderInformation', 'projectData', 'offerInformation', 'offerProductTypes', 'offerActions']),

        confirmationDeleteModalData() {
            if (this.deleteOfferData && this.deleteOfferData.hasRelatedOffers) {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.delete.with_related.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.delete.with_related.cancel'),
                    secondaryPrimaryActionText: this.$t('offer.page.edit.confirmation.delete.with_related.secondary'),
                    message: this.$t('offer.page.edit.confirmation.delete.with_related.question'),
                    width: 655
                }
            } else {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.delete.without_related.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.delete.without_related.cancel'),
                    message: this.$t('offer.page.edit.confirmation.delete.without_related.question')
                }
            }
        },

        routeName() {
            return this.$route.name
        },

        multiMenuElements() {
            return [
                [
                    {
                        handle: 'project-data',
                        handleName: this.$t('offer.page.edit.job_navigation.option.menu.project_data')
                    },
                    {
                        handle: 'project-offers',
                        handleName: this.$t('offer.page.edit.job_navigation.option.menu.project_offers')
                    },
                    {
                        handle: 'project-copy',
                        handleName: this.$t('offer.page.edit.job_navigation.option.menu.project_copy')
                    },
                    this.projectData && this.projectData.permissions && this.projectData.permissions.canCreateOffer ? {
                        handle: '',
                        handleName: this.$t('offer.page.edit.job_navigation.option.menu.create_offer'),
                        subElements: this.offerProductTypes.map(el => {
                            return {
                                handle:  {
                                    action: 'new-offer',
                                    groupId: el.id,
                                    groupType: el.type
                                },
                                handleName: el.name
                            }
                        })
                    } : null
                ].filter(m => m !== null),
                [
                    {
                        handle: 'offer-data',
                        handleName: this.$t('offer.page.edit.job_navigation.option.menu.offer_data')
                    }
                ],
                this.offerActions.map(action => {
                    const {handle, handleName, ...params} = action
                    return {
                        handle: {
                            ...params,
                            action: handle,
                        },
                        handleName
                    }
                })
            ]
        },

        relatedOffersElements() {
            return this.offerInformation.relatedOffers.map(data => {
                if(data.offerId) {
                    return {
                        handle: {
                            action: 'view-related-offer',
                            offerId: data.offerId
                        },
                        actionName: `${data.groupName} (${data.offerNumber})`,
                        offerId: data.offerId
                    }
                } else {
                    return {
                        handle: {
                            action: 'new-related-offer',
                            offerId: this.offerInformation.id,
                            groupId: data.groupId
                        },
                        actionName: data.groupName,
                        isNew: true
                    }
                }
            })
        },

        relatedOfferName() {
            let offers = this.offerInformation.relatedOffers.filter(data => data.offerId !== null)

            if(offers.length === 0) {
                return this.$t('offer.page.edit.job_navigation.button.create_related_offer')
            }

            if(offers.length === 1) {
                return offers[0].groupName
            }

            return this.$t('offer.page.edit.job_navigation.button.related_offers', {number_of_offers: offers.length})
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    components: { MultiMenu, ModalDataEdit, ModalProjectOffers, ModalProjectDuplicate, RelatedOfferDropdown, ModalConfirmation }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/loading.scss';

.job-navigation-component {
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 10px #d9d9d9;
    background: $color-white;

    &.fixed-to-top {
        top: 0;
        position: fixed;
        width: 100%;
        z-index: 1000;
    }

    .logo {
        position: relative;
        top: 1px;
        max-width: 38px;
        height: auto;
        margin: 0 13px;
    }

    .button-logo {
        padding: 0;
    }

    .button-multi-menu {
        position: relative;
        display: flex;
        width: 230px;
        border-radius: 0;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px;
        z-index: 60;
        border-top: 0;
        border-bottom: 0;

        &.is-order {
            cursor: default;
        }

        .text-part {
            display: flex;
            flex-direction: column;

            span {
                display: block;
                width: 100%;
                text-align: left;
            }

            .project-name,
            .offer-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 186px;

                &--without-menu {
                    max-width: 205px;
                }
            }

            .project-name {
                font-size: 10px;
                font-family: $font-primary;
            }

            .offer-name {
                font-size: 13px;
            }
        }
    }

    .basic-data-container {
        display: flex;
        padding: 0 25px;

        @media only screen and (max-width: 600px) {
            display: none;
        }

        .basic-data-item {
            position: relative;
            margin-right: 40px;

            .label {
                font-size: 13px;
                display: block;
            }

            button {
                color: $color-blue-1;
            }
        }
    }

    .right-container {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 5px;

        @media only screen and (max-width: 600px) {
            display: none;
        }

        .offer-data {
            font-size: 13px;
            margin-right: 25px;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .label {
                &--name {
                    font-weight: bold;
                    margin-bottom: 5px;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .button-save {
            height: 40px;
            margin-left: auto;
            margin-right: 20px;
            background: #009fe3;
            color: $color-white;
            border: 0;

            &:hover {
                opacity: 0.85;
            }

            &:disabled {
                opacity: 0.5;
            }
        }

        .button-export {
            position: relative;
            height: 40px;
            line-height: 16px;
            margin-left: 0;
            margin-right: 20px;

            i {
                position: relative;
                top: -2px;
            }

            &.is-loading {
                cursor: not-allowed;

                &:before {
                    position: absolute;
                    top: 1px;
                    background-size: 36px;
                    left: 5px;
                    width: 36px;
                    height: 36px;
                }

                i {
                    visibility: hidden;
                }
            }
        }

        .status-icon {
            display: flex;
            margin-left: 0;
            margin-right: 15px;
        }
    }

    .button-close {
        margin-left: auto;
        height: 60px;
        padding: 8px 15px 0;
        border-left: 1px solid $color-gray-border;

        &.ml-0 {
            margin-left: 0;

            @media only screen and (max-width: 600px) {
                margin-left: auto!important;
            }
        }

        i {
            font-size: 30px !important;
        }
    }
}
</style>
