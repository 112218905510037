<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper" :class="{'error' : errors.name}">
                    <label class="text-input-label" for="name-type-category-edit">{{ $t('group_type_category.manage.field.name') }}</label>
                    <input type="text" v-model="name" id="name-type-category-edit">
                    <span class="input-error">{{errors.name}}</span>
                </div>
                <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.exportable}">
                    <div class="toggle">
                        <input type="checkbox" v-model="exportable" id="exportable-type-category-edit">
                        <label for="exportable-type-category-edit">{{ $t('group_type_category.manage.field.exportable') }}</label>
                    </div>
                    <span class="input-error">{{errors.exportable}}</span>
                </div>
                <div class="input-wrapper" :class="{'error' : errors.exportColor}">
                    <label class="text-input-label" for="export-color-type-category-edit">{{ $t('group_type_category.manage.field.export_color') }}</label>
                    <input type="text" v-model="exportColor" id="export-color-type-category-edit">
                    <span class="input-error">{{errors.exportColor}}</span>
                </div>

                <div class="input-wrapper" :class="{'error' : errors.exportColorCeramics}">
                    <label class="text-input-label" for="export-color-ceramics-type-category-edit">{{ $t('group_type_category.manage.field.export_color_ceramics') }}</label>
                    <input type="text" v-model="exportColorCeramics" id="export-color-ceramics-type-category-edit">
                    <span class="input-error">{{errors.exportColorCeramics}}</span>
                </div>

                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="save" :disabled="loading">
                        {{ $t('group_type_category.manage.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_type_category.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupTypeCategoryEdit',
    props: ['open', 'groupId', 'categoryId', 'createTarget'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            name: null,
            exportable: false,
            exportColor: null,
            exportColorCeramics: null
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_TYPE_CATEGORY', {
                    groupId: this.groupId,
                    categoryId: this.categoryId,
                    createTarget: this.createTarget,
                }).then(data => {
                    this.title = data.title
                    this.name = data.name
                    this.exportable = data.exportable
                    this.exportColor = data.exportColor
                    this.exportColorCeramics = data.exportColorCeramics
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        save() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                categoryId: this.categoryId,
                createTarget: this.createTarget,
                data: {
                    name: this.name,
                    exportable: this.exportable,
                    exportColor: this.exportColor,
                    exportColorCeramics: this.exportColorCeramics
                }
            }

            this.$store.dispatch('SEND_GROUP_TYPE_CATEGORY', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
