<template>
    <div class="tabs-component" :class="{'large' : large, 'navigation': navigation}">
        <nav>
            <button
                v-for="element in elements"
                :key="element.handle"
                :class="{ 'active' : beginWith ? selected.indexOf(element.handle) === 0 : element.handle === selected }"
                @click="changeActive(element.handle)"
            >{{ element.handleName }}</button>
        </nav>

        <div
            class="mobile--navigation"
            :class="{ 'open': open }"
            @click="open = !open"
            v-click-outside="onClickOutside"
        >
            <div>
                <button class="btn btn-primary pages--button">Oldalak</button>
            </div>

            <nav-dropdown @clicked="changeActive" :open="open" :elements="elements"></nav-dropdown>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import NavDropdown from '../components/NavDropdown.vue'

export default {
    name: 'Tabs',
    props: ['elements', 'selected', 'beginWith', 'large', 'navigation'],

    components: {
        NavDropdown
    },

    data() {
        return {
            open: false,
        }
    },


    directives: {
        clickOutside: vClickOutside.directive
    },

    methods: {
        changeActive(element) {
            if (this.selected !== element) {
                this.$emit('changed', element)
            }
        },

        onClickOutside() {
            this.open = false
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.tabs-component {
    border-bottom: 1px solid $color-gray-border;
    font-size: 13px;
    line-height: 20px;

    .pages--button{
        height: 40px!important;
        display: flex!important;
        justify-content: center!important;;
        align-items: center!important;;
        padding: 15px!important;
        color: white!important;
    }

    button {
        position: relative;
        font-weight: bold;
        padding: 12px 0;
        margin: 0 14px;
        color: $color-gray-5;
        text-decoration: underline;

        &:first-child {
            margin-left: 0 !important;
        }

        &:last-child {
            margin-right: 0 !important;
        }

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 4px;
            left: 0;
            bottom: 0;
            border-radius: 2px 2px 0px 0px;
            background: transparent;
        }

        &.active {
            text-decoration: none;
            color: $color-gray-1;

            &:after {
                background: $color-red-1;
            }
        }
    }

    &.large {
        button {
            padding: 17px 0;
            margin: 0 20px;

            @media #{$bp-laptop} {
                margin: 0 10px;
            }
        }

        border: 0;
    }

    .mobile--navigation{
        display: none;
    }

    @media only screen and (max-width: 600px) {
        .mobile--navigation{
            display: flex;
        }

        nav{
            display: none!important;
        }
    }



    &.navigation {
        button {
            height: 60px;
            text-transform: uppercase;
            text-decoration: none;
            padding: 18px 0 17px;
            color: $color-gray-4;
            font-size: 12px;

            margin: 0px 10px;

            @media #{$bp-laptop} {
                font-size: 10px;
            }

            &.active {
                color: $color-black-1;
            }
        }
    }
}
</style>
