export default class SubTool {
    static get isInline() {
        return true;
    }

    get state() {
        return this._state;
    }

    set state(state) {
        this._state = state;

        this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }
    get TAG() {
        return 'SUB';
    }

    get SVG() {
        return '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16" height="16" viewBox="0 0 491.781 491.781" style="enable-background:new 0 0 491.781 491.781;" xml:space="preserve"><g><path d="M343.273,340.824h-81.179l-92.379-108.377L79.429,340.824H0l130.864-148.187L6.295,52.792H86.43l86.797,101.388 l87.461-101.388h76.639L211.352,192.637L343.273,340.824z M393.154,401.06l52.86-40.034c18.542-12.731,30.724-24.559,36.563-35.464 c5.84-10.9,8.748-22.621,8.748-35.176c0-20.504-6.856-37.055-20.558-49.653c-13.701-12.602-31.723-18.896-54.048-18.896 c-21.521,0-38.751,6.372-51.636,19.112c-12.922,12.75-19.37,31.96-19.37,57.648h41.523c0-15.327,2.713-25.925,8.133-31.801 c5.426-5.875,12.862-8.818,22.331-8.818c9.463,0,16.94,2.99,22.484,8.961c5.509,5.97,8.27,13.394,8.27,22.26 c0,8.854-2.554,16.869-7.69,24.039c-5.13,7.17-19.381,19.263-42.776,36.286c-20.02,14.635-47.091,28.431-55.218,41.363 l0.407,48.103h148.603v-37.936h-98.627V401.06z"/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>';
    }

    constructor({api}) {
        this.api = api;
        this.button = null;
        this._state = false;
    }

    render() {
        this.button = document.createElement('button');
        this.button.type = 'button';
        this.button.innerHTML = this.SVG;
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }

    surround(range) {
        if (this.state) {
            this.unwrap(range);
            return;
        }

        this.wrap(range);
    }

    wrap(range) {
        const selectedText = range.extractContents();
        const mark = document.createElement(this.TAG);

        mark.appendChild(selectedText);
        range.insertNode(mark);

        this.api.selection.expandToTag(mark);
    }

    unwrap(range) {
        const mark = this.api.selection.findParentTag(this.TAG);
        const text = range.extractContents();

        mark.remove();

        range.insertNode(text);
    }


    checkState() {
        const mark = this.api.selection.findParentTag(this.TAG);

        this.state = !!mark;
    }
}
