<template>
    <div class="edit-discount-page" v-if="!loading">
        <template v-if="order && order.id !== null">
            <h1 v-if="isEditPage">{{ order.modifyTitle }}</h1>
            <h1 v-else-if="isApprovePage">{{ $t('order.page.pending.title.approve') }}</h1>
            <h1 v-else-if="isRejectPage">{{ $t('order.page.pending.title.reject') }}</h1>

            <div class="order-data-container">
                <table class="section__table section__order-table">
                    <tr v-if="order.data.orderedAt">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.ordered_at') }}</span></td>
                        <td>{{ order.data.orderedAt }}</td>
                    </tr>
                    <tr v-if="order.data.orderNumber">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.order_number') }}</span></td>
                        <td>{{ order.data.orderNumber }}</td>
                    </tr>
                    <tr v-if="order.data.customerName">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.customer_name') }}</span></td>
                        <td>{{ order.data.customerName }}</td>
                    </tr>
                    <tr v-if="order.data.shippingDate">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.shipping_date') }}</span></td>
                        <td>{{ order.data.shippingDate }}</td>
                    </tr>
                    <tr v-if="order.data.transportName">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.transport_name') }}</span></td>
                        <td>{{ order.data.transportName }}</td>
                    </tr>
                    <tr v-if="order.data.shippingType">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.shipping_type') }}</span></td>
                        <td>{{ order.data.shippingType }}</td>
                    </tr>
                    <tr v-if="order.data.objectNumber">
                        <td class="very-small strong uppercase head"><span>{{ $t('order.page.pending.details.object_number') }}</span></td>
                        <td>{{ order.data.objectNumber }}</td>
                    </tr>
                </table>

                <h2>{{ $t('order.page.pending.products.title') }}</h2>

                <table class="product-list" v-if="order.products.length">
                    <tr class="label-row">
                        <th>{{ $t('order.page.pending.products.column.name') }}</th>
                        <th>{{ $t('order.page.pending.products.column.amount') }}</th>
                        <th :colspan="numberOfSubDiscounts">{{ $t('order.page.pending.products.column.discount') }}</th>
                    </tr>
                    <tr class="input-row" v-for="product in order.products" :key="product.id">
                        <td>{{ product.name }}</td>
                        <td>{{ product.amount }} {{ product.unit }}</td>
                        <template v-if="isProductDiscountEditPage">
                            <template v-if="product.subDiscounts.length > 0">
                                <td class="sub-discount" v-for="subDiscount in product.subDiscounts" :key="subDiscount.id">
                                    <div><input v-model="product.discount[subDiscount.id]" v-zerohandling type="number" class="small" min="0" max="100" step="0.01">%</div>
                                    <label>{{ subDiscount.name }}</label>
                                </td>
                            </template>
                            <td :colspan="numberOfSubDiscounts" v-else>
                                <input v-model="product.discount" v-zerohandling type="number" class="small"  min="0" max="100" step="0.01">%
                            </td>
                        </template>
                        <td class="sub-discount" v-else-if="product.subDiscounts.length > 0">
                            <div v-for="subDiscount in product.subDiscounts" :key="subDiscount.id">{{ subDiscount.name }}: {{ getDiscount(product)[subDiscount.id]}} %</div>
                        </td>
                        <td v-else>{{ getDiscount(product) }} %</td>
                    </tr>
                </table>

                <template v-if="order.discounts.length && (!isEditPage || isGroupDiscountEditPage || (successMessage || errorMessage))">
                    <h2>{{ $t('order.page.pending.discounts.title') }}</h2>

                    <table class="product-list">
                        <tr class="label-row">
                            <th>{{ $t('order.page.pending.discounts.column.name') }}</th>
                            <th>{{ $t('order.page.pending.discounts.column.amount') }}</th>
                        </tr>
                        <tr class="input-row" v-for="discount in order.discounts" :key="discount.id">
                            <td>{{ discount.name }}</td>
                            <td v-if="isGroupDiscountEditPage">
                                <input v-model="discount.percent" v-zerohandling type="number" class="small"  min="0" max="100" step="0.01">%
                            </td>
                            <td v-else>{{ discount.percent }} %</td>
                        </tr>
                    </table>
                </template>
            </div>

            <template v-if="!successMessage && !errorMessage">
                <template v-if="isEditPage">

                    <div v-if="isProject">
                        <h2><b>{{ $t('order.page.pending.project.title')}}</b></h2>
                        <button
                            class="btn btn-primary"
                            style="margin-bottom: 0.5rem;"
                            type="button"
                            @click="setIsProject(true)">
                            {{ $t('order.page.pending.project.yes')}}
                        </button>
                        <button
                            class="btn"
                            type="button"
                            @click="setIsProject(false)">
                            {{ $t('order.page.pending.project.no')}}
                        </button>
                    </div>
                    <button v-else @click="modifyOrder()" :disabled="buttonLoading"  class="btn btn-primary" type="button">{{ $t('order.page.pending.button.modify_and_approve') }}</button>

                </template>

                <template v-else-if="isApprovePage">

                    <div v-if="isProject">
                        <h2><b>{{ $t('order.page.pending.project.title')}}</b></h2>
                        <button
                            class="btn btn-primary"
                            style="margin-bottom: 0.5rem;"
                            type="button"
                            @click="setIsProject(true)">
                            {{ $t('order.page.pending.project.yes')}}
                        </button>
                        <button
                            class="btn"
                            type="button"
                            @click="setIsProject(false)">
                            {{ $t('order.page.pending.project.no')}}
                        </button>
                    </div>
                    <button v-else @click="approveOrder()" :disabled="buttonLoading" class="btn btn-primary" type="button">{{ $t('order.page.pending.button.approve') }}</button>

                </template>

                <template v-else-if="isRejectPage">
                    <button @click="rejectOrder()" :disabled="buttonLoading" class="btn btn-primary" type="button">{{ $t('order.page.pending.button.reject') }}</button>
                </template>
            </template>
        </template>

        <div class="message-success" v-if="successMessage">
            {{ successMessage }}
        </div>
        <div class="message-error" v-if="errorMessage">
            {{ errorMessage }}
        </div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="modifyOrder(true)"
            @close="openConfirmationModal = false"
        ></modal-confirmation>
    </div>
</template>

<script>
import Vue from 'vue'

import config from '../plugins/config.js'
import axios from 'axios'

import {ZeroHandling} from '@/plugins/directives'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'

export default {
    name: 'PendingOrder',

    data() {
        const defaultOrder = {
            id: null,
            message: null,
            data: {
                orderedAt: null,
                orderNumber: null,
                customerName: null,
                shippingDate: null,
                transportName: null,
                shippingType: null,
                objectNumber: null,
            },
            permissions: {
                canEditDiscounts: false,
                canApprove: false,
                canReject: false
            },
            modifyTitle: null,
            discounts: [],
            products: [],
            isVillas: null,
            discountType: null,
            isProjectAnswered: null,
        }

        return {
            defaultOrder,

            loading: true,
            buttonLoading: false,

            token: null,
            order: defaultOrder,
            successMessage: '',
            errorMessage: '',

            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('order.page.pending.confirmation.modify_and_approve.ok'),
                cancelText: this.$t('order.page.pending.confirmation.modify_and_approve.cancel'),
                message: this.$t('order.page.pending.confirmation.modify_and_approve.question')
            }
        }
    },

    watch: {
        '$route'() {
            this.fetchOrderData()
        },
    },

    mounted() {
        this.fetchOrderData()
    },

    methods: {
        fetchOrderData() {
            this.loading = true
            this.token = this.$route.params.token
            this.order =  this.defaultOrder
            this.successMessage = ''
            this.errorMessage = ''
            this.openConfirmationModal = false

            if (this.token) {
                this.getData()
            } else {
                this.errorMessage = this.$t('order.page.pending.text.order_not_found')
                this.loading = false
            }
        },

        getData() {
            return axios.get(`pending-orders/${this.token}`, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.order = data
                    if(
                        (this.isEditPage && !this.order.permissions.canEditDiscounts) ||
                        (this.isApprovePage && !this.order.permissions.canApprove) ||
                        (this.isRejectPage && !this.order.permissions.canReject)
                    ) {
                        this.successMessage = data.message
                        this.errorMessage = data.error
                    }
                })
                .catch(err => {
                    this.errorMessage = err.error
                })
                .finally(() => {
                    this.loading = false
                })
        },

        getDiscount(product) {
            if (!this.isEditPage || this.order.discounts.length === 0) {
                return product.discount
            }

            let discount

            if(product.subDiscounts.length === 0) {
                discount = 0

                this.order.discounts.forEach((d) => {
                    if(d.percent > 0 && product.discounts.includes(d.id)) {
                        discount = d.percent
                    }
                })
            } else {
                discount = {}
                product.subDiscounts.forEach(subDiscount => {
                    let groupDiscount = this.order.discounts.filter(d => d.id === subDiscount.id)[0];
                    discount[subDiscount.id] = groupDiscount ? groupDiscount.percent : 0
                })
            }

            return discount
        },

        modifyOrder(send = false) {
            let message = {}

            if (!send) {
                this.openConfirmationModal = true
                return
            }

            if (this.order.discounts.length > 0) {
                Vue.set(message, 'discounts', this.order.discounts)
            } else if (this.order.products.length > 0) {
                Vue.set(message, 'products', this.order.products)
            }

            this.buttonLoading = true;
            axios.post(`pending-orders/${this.token}/modify-discounts`, message, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.successMessage = this.$t('order.page.pending.text.modify_and_approve_success')
                })
                .catch(err => {
                    this.errorMessage = err.error
                })
                .finally(() => {
                    this.buttonLoading = false;
                    this.openConfirmationModal = false
                })
        },

        approveOrder() {
            this.buttonLoading = true;
            axios.post(`pending-orders/${this.token}/approve`, {}, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.buttonLoading = false;
                    this.successMessage = this.$t('order.page.pending.text.approve_success')
                })
                .catch(err => {
                    this.errorMessage = err.error
                })
        },

        rejectOrder() {
            this.buttonLoading = true;
            axios.post(`pending-orders/${this.token}/reject`, {}, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.buttonLoading = false;
                    this.successMessage = this.$t('order.page.pending.text.reject_success')
                })
                .catch(err => {
                    this.errorMessage = err.error
                })
        },

        setIsProject(val){
            let id = this.order.id;
            if(val){
                axios.post(`pending-orders/${this.token}/updateComment`, { id: id, isProject: true }, config.AXIOS_DEFAULTS)
                    .then((res) => {
                        this.order.isProjectAnswered = res.is_project_answered;
                    })
            }else{
                axios.post(`pending-orders/${this.token}/updateComment`, { id: id, isProject: false }, config.AXIOS_DEFAULTS)
                    .then((res) => {
                        this.order.isProjectAnswered = res.is_project_answered;
                    })
            }
        },
    },

    computed: {
        isProject() {
            return this.order.isVillas &&
                this.order.discountType == 4 &&
                this.order.isProjectAnswered == false;
        },
        routeName() {
            return this.$route.name
        },
        isEditPage() {
            return this.routeName === 'EditDiscount'
        },
        hasMessage() {
            return this.successMessage || this.errorMessage
        },
        isGroupDiscountEditPage() {
            return !this.hasMessage && this.isEditPage && this.order.discounts.length > 0
        },
        isProductDiscountEditPage() {
            return !this.hasMessage && this.isEditPage && this.order.discounts.length === 0
        },
        isApprovePage() {
            return this.routeName === 'ApproveOrder'
        },
        isRejectPage() {
            return this.routeName === 'RejectOrder'
        },
        numberOfSubDiscounts() {
            let max = 1

            this.order.products.forEach(p => {
                if(p.subDiscounts.length > max) {
                    max = p.subDiscounts.length
                }
            })

            return max
        },
    },

    directives: {
        ZeroHandling
    },

    components: {
        ModalConfirmation,
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.edit-discount-page {
    padding: 30px 20px;
    max-width: 550px;
    margin: 0 auto;

    h1 {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 10px;
    }

    button {
        width: 100%;
    }

    .product-list {
        margin-bottom: 20px;
        width: 100%;

        th {
            color: $color-gray-5;
            font-size: 13px;
            font-weight: bold;
            border-bottom: 1px solid $color-gray-border;
            text-align: left;
            padding: 5px;
        }

        td {
            font-size: 14px;
            line-height: 18px;
            text-align: left;
            padding: 5px;
            vertical-align: middle;

            &.sub-discount {
                > div {
                    white-space: nowrap;
                }

                label {
                    font-size: 12px;
                }
            }

            input {
                width: 64px;
                margin-right: 7px;
            }

        }
    }

    h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .order-data-container {
        .section {
            &__paragraph__table-caption {
                color: $color-gray-5;
                font-size: 13px;
                font-weight: bold;
                padding-bottom: 10px;
                margin-bottom: 15px;
            }

            &__order-table {
                font-size: 14px;
                width: 100%;
                margin-bottom: 20px;

                td {
                    padding: 10px 5px 10px 0;
                    color: $color-gray-1;
                    border-bottom: 1px dashed $color-gray-border;

                    a {
                        text-decoration: none;
                        color: $color-gray-1;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &.head {
                        width: 50%;

                        span {
                            display: inline-block;
                            background-color: $color-white;
                            color: $color-gray-4;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 480px) {
            .section {
                &__order-table {
                    padding: 0 15px 20px;

                    td {
                        font-size: 11px;

                        &.head {
                            line-height: 1px;
                        }
                    }
                }
            }
        }
    }

    .message-success,
    .message-error {
        color: $color-green-1;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    .message-error {
        color: $color-red-2;
    }
}
</style>
