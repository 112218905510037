import i18n from '../i18n'
import './sum.css'

export default class Sum {
    static get toolbox() {
        return {
            icon: '<svg width="12" height="12" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 341.333 341.333" style="enable-background:new 0 0 341.333 341.333;" xml:space="preserve"><g><g><polygon points="298.667,0 42.667,0 42.667,42.667 181.333,170.667 42.667,298.667 42.667,341.333 298.667,341.333 298.667,277.333 149.333,277.333 256,170.667 149.333,64 298.667,64 \t\t"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.sum')
        };
    }

    constructor({ data, api }) {
        this._elements = {
            wrapper: null,
            input: null
        };

        this.api = api;

        this._data = {
            price: 0
        };

        this.data = data;
    }

    get CSS() {
        return {
            baseBlock: this.api.styles.block,
            wrapper: 'ejs-sum',
            input: 'ejs-sum__input',
        };
    }

    set data(listData) {
        if (!listData) {
            listData = {};
        }

        this._data.price = listData.price || 0;

        const oldView = this._elements.wrapper;

        if (oldView) {
            oldView.parentNode.replaceChild(this.render(), oldView);
        }
    }

    get data() {
        let data = {}

        data.price = this._elements.input.value;

        return data;
    }

    save() {
        return this.data;
    }

    render() {
        this._elements.wrapper = this._make('div', [this.CSS.baseBlock, this.CSS.wrapper]);

        this._elements.input = this._make('input', null, {
            type: 'text',
            value: this._data.price
        })

        this._elements.wrapper.appendChild(this._make('span', [], {
            innerHTML: i18n.t('offer.page.edit.text.editor.sum.title')
        }))

        this._elements.wrapper.appendChild(this._make('sub', [], {
            innerHTML: i18n.t('offer.page.edit.text.editor.sum.sub')
        }))

        this._elements.wrapper.appendChild(this._make('span', [], {
            innerHTML: ': '
        }))

        this._elements.wrapper.appendChild(this._elements.input);

        this._elements.wrapper.appendChild(this._make('span', [], {
            innerHTML: i18n.t('offer.page.edit.text.editor.sum.prefix')
        }))

        return this._elements.wrapper;
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName);

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames);
        } else if (classNames) {
            el.classList.add(classNames);
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}
