import i18n from '../i18n'
import './separator.css'

export default class Separator {
    static get toolbox() {
        return {
            icon: '<svg width="18" height="18" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 298.667 298.667" style="enable-background:new 0 0 298.667 298.667;" xml:space="preserve"><g><g><rect y="128" width="298.667" height="42.667"/>\t</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.separator')
        };
    }

    get CSS() {
        return {
            baseBlock: this.api.styles.block,
            wrapper: 'ejs-separator'
        };
    }

    constructor({ api }) {
        this.api = api;
    }

    save() {
        return {};
    }

    render() {
        return this._make('div', [this.CSS.baseBlock, this.CSS.wrapper]);
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName);

        if (Array.isArray(classNames)) {
            el.classList.add(...classNames);
        } else if (classNames) {
            el.classList.add(classNames);
        }

        for (const attrName in attributes) {
            el[attrName] = attributes[attrName];
        }

        return el;
    }
}
