<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div v-if="reAuthenticated" class="dashboard-header__new" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon> {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>
        </div>

        <div class="filter-column">
            <product-admin-pages v-if="reAuthenticated"></product-admin-pages>
            <basic-links v-if="reAuthenticated" position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <re-authenticate v-if="!reAuthenticated" @validPassword="setReAuthenticated(true)"></re-authenticate>
        </div>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Notification from '../components/Notification.vue'
import ReAuthenticate from '../components/auth/ReAuthenticate.vue'
import ProductAdminPages from "@/components/sidebars/ProductAdminPages";

Vue.use(MdIcon)

export default {
    name: 'ProductAdmin',

    watch: {
        'reAuthenticated'(val) {
            if (val == true) {
                this.fetchGroups()
            }
        }
    },

    data() {
        return {
            loading: true,
            openGroupListDropdown: false,
            productGroups: [],
            productPages: [
                {
                    name: this.$t('product.page.list.sidebar'),
                    handle: 'ProductList',
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                if(this.reAuthenticated) {
                    this.fetchGroups()
                } else {
                    this.loading = false
                }
            } else {
                this.$router.push('/');
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    methods: {
        fetchGroups() {
            if (this.userHasProductAdmin) {
                this.loading = true
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.loading = false
                })
            }
        },

        sidebarChanged(name) {
            this.$router.push({name})
        },

        selectedProductType(payload) {
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        setReAuthenticated(val) {
            this.$store.commit('setReAuthenticated', val)
        },
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        ...mapGetters(['userHasProductAdmin', 'reAuthenticated'])
    },

    components: {
        ProductAdminPages,
        MainNavigation,
        BasicLinks,
        ProductTypeDropdown,
        Notification,
        ReAuthenticate,
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    .list-column {
        font-weight: 500;
    }
}
</style>
