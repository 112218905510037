<template>
    <div class="auth-page">
        <teaser :use-link="true" />

        <div class="auth-page__form">
            <div class="auth-form">
                <div class="auth-form__title">{{ $t('registration.page.text.title') }}</div>

                <span v-if="tokenError" class="error">{{tokenError}}</span>

                <template v-else>
                    <p class="auth-form__subtitle" v-html="invited"></p>
                    <p class="auth-form__subtitle" v-html="finalization"></p>

                    <div class="auth-form__row" :class="{ 'error' : errors['password'] }">
                        <label for="l_reset_password">{{ $t('registration.page.field.password.label') }}</label>
                        <input ref="passwordField" type="password" name="password" v-model="newPassword" tabindex="2" id="l_reset_password" @keyup="changedInput">

                        <span class="password-reveal" v-if="!passwordRevealed" @click="togglePassword()"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
                        <span class="password-reveal" v-else @click="togglePassword()"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

                        <span class="auth-form__error" v-if="errors['password']">{{errors['password']}}</span>
                    </div>

                    <div class="auth-form__row" :class="{ 'error' : errors['passwordAgain'] }">
                        <label for="l_reset_password_again">{{ $t('registration.page.field.password_again.label') }}</label>
                        <input ref="passwordFieldAgain" type="password" name="password_again" v-model="newPasswordAgain" tabindex="3" id="l_reset_password_again" @keyup="changedInput">

                        <span class="password-reveal" v-if="!passwordRevealedAgain" @click="togglePassword(true)"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
                        <span class="password-reveal" v-else @click="togglePassword(true)"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

                        <span class="auth-form__error" v-if="errors['passwordAgain']">{{errors['passwordAgain']}}</span>
                    </div>

                    <div class="auth-form__row" :class="{ 'error' : errors['privacy'] }">
                        <div class="checkbox">
                            <input type="checkbox" id="privacy-1" v-model="privacy" @change="changedInput">
                            <label for="privacy-1">{{ $t('registration.page.field.privacy.label') }}</label>
                            <span class="auth-form__error" v-if="errors['privacy']">{{errors['privacy']}}</span>
                        </div>
                    </div>

                    <div class="auth-form__submit">
                        <button
                            @click="sendPassword()"
                            :disabled="loading"
                            type="button"
                            class="btn btn-blue">
                            {{ $t('registration.page.button.save') }}
                        </button>

                        <span class="auth-form__error" v-if="generalError.length > 0">
                            {{generalError}}
                        </span>
                    </div>
                </template>
            </div>
            <support-text @openModal="openModal($event)" />

            <notification></notification>
        </div>

        <modal-privacy :open="openedModal === 'privacy'" @close="closeModal()"></modal-privacy>
        <modal-terms :open="openedModal === 'terms'" @close="closeModal()"></modal-terms>
    </div>
</template>

<script>
import config from '../plugins/config.js'
import axios from 'axios'

import SupportText from '../components/auth/SupportText.vue'
import Teaser from '../components/auth/Teaser.vue'

import { scrollToError } from '@/plugins/helpers'

import ModalPrivacy from '../components/modals/ModalPrivacy.vue'
import ModalTerms from '../components/modals/ModalTerms.vue'

import Notification from '../components/Notification.vue'

export default {
    name: 'Registration',

    data() {
        return {
            loading: false,
            privacy: false,
            invitorName: null,
            invitorEmail: null,
            tokenError: null,
            errors: {},
            passwordRevealed: false,
            passwordRevealedAgain: false,
            email: '',
            newPassword: '',
            newPasswordAgain: '',
            generalError: '',
            openedModal: ''
        }
    },

    mounted() {
        this.loading = true

        if (this.$route.params.token) {
            this.fetchData()
        }
    },

    computed: {
        sap() {
            return this.$route.params.sap || ''
        },

        invited() {
            return this.$t('registration.page.text.invited', {
                name: `<strong>${this.invitorName}</strong>`,
                email: this.invitorEmail
            })
        },

        finalization() {
            if(this.sap) {
                return this.$t('registration.page.text.finalization_with_sap', {
                    email_hu_article: this.emailArticle,
                    email_bold: `<strong>${this.email}</strong>`,
                    email: this.email,
                    sap_bold: `<strong>${this.sap}</strong>`,
                    sap: this.sap
                })
            } else {
                return this.$t('registration.page.text.finalization', {
                    email_hu_article: this.emailArticle,
                    email_bold: `<strong>${this.email}</strong>`,
                    email: this.email
                })
            }
        },

        emailArticle() {
            if (this.email && /^[aeiou]/i.test(this.email)) {
                return 'az'
            } else {
                return 'a'
            }
        }
    },

    methods: {
        changedInput() {
            if (Object.keys(this.errors).length > 0) {
                this.errors = {}
            }

            if (this.generalError.length > 0) {
                this.generalError = ''
            }
        },

        togglePassword(again) {
            if (again){
                this.passwordRevealedAgain = !this.passwordRevealedAgain
                this.$refs.passwordFieldAgain.type = this.passwordRevealedAgain ? 'text' : 'password'
            } else {
                this.passwordRevealed = !this.passwordRevealed
                this.$refs.passwordField.type = this.passwordRevealed ? 'text' : 'password'
            }
        },

        openLogin() {
            this.$router.push('/')
        },

        fetchData() {
            axios.get(`invite/${this.$route.params.token}`, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.email = data.email
                    this.invitorName = data.invitorName
                    this.invitorEmail = data.invitorEmail
                })
                .catch(response => {
                    this.tokenError = response.error
                })
                .finally(() => {
                    this.loading = false
                })
        },

        sendPassword() {
            let data = {
                token: this.$route.params.token,
                password: this.newPassword,
                passwordAgain: this.newPasswordAgain,
                privacy: this.privacy ? 1 : 0,
            }

            this.loading = true

            axios.post('invite/save', data, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.$store.commit('setLoginData', {
                        email: this.email,
                        sap: this.sap
                    })

                    this.$router.push('/')

                    setTimeout(() => {
                        this.$store.commit('setNotification', [this.$t('registration.page.text.notification')])
                    }, 100)
                })
                .catch(response => {
                    this.errors = response.errors || {}
                    this.generalError = response.error || ''
                    this.loading = false
                    scrollToError()
                })
        },

        openModal(modal) {
            this.openedModal = modal
        },

        closeModal() {
            this.openedModal = ''
        }
    },

    components: { ModalPrivacy, ModalTerms, Notification, SupportText, Teaser }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/layout/auth-page.scss';
@import '../assets/sass/partials/auth-form.scss';

.checkbox {
    margin: 10px 0;

    input {
        position: absolute;
    }

    label {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
        padding-left: 30px;

        &:before {
            left: 0;
        }

        &:after {
            top: 6px;
            left: 4px;
        }
    }

    span {
        font-weight: 400;
    }
}
</style>
