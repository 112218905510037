<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ product.name }}<template v-if="product.type && product.type !== product.name"> ({{ product.type }})</template></span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <tabs :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el}"></tabs>
                <div class="product-data-container" v-show="tabValue === 'data'">
                    <div class="col-left">
                        <div class="input-wrapper" :class="{'error' : errors.name}">
                            <label class="text-input-label" for="name-product-edit">{{ $t('product.manage.field.name') }}</label>
                            <input type="text" v-model="product.name" id="name-product-edit" disabled>
                            <span class="input-error">{{errors.name}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.listPrice}">
                            <label class="text-input-label" for="listPrice-product-edit" v-if="product.listPriceUnit">
                                {{ $t('product.manage.field.list_price', {unit: product.listPriceUnit.name}) }}
                            </label>
                            <label class="text-input-label" for="listPrice-product-edit" v-else>
                                {{ $t('product.manage.field.list_price_without_unit') }}
                            </label>
                            <input type="text" v-model="product.listPrice" id="listPrice-product-edit" :disabled="product.isSap">
                            <span class="input-error">{{errors.listPrice}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.productTypeId}">
                            <label class="text-input-label">{{ $t('product.manage.field.product_type') }}</label>
                            <custom-select
                                :filterable="true"
                                :modal="true"
                                :selectedItem="product.productType"
                                :options="product.productTypes"
                                @changed="(el) => {product.productType = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.productTypeId}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--w50" :class="{'error' : errors.sapUnit}">
                            <label class="text-input-label" for="sapUnit-product-edit">{{ $t('product.manage.field.sap_unit') }}</label>
                            <input type="text" v-model="product.sapUnit" id="sapUnit-product-edit" disabled>
                            <span class="input-error">{{errors.sapUnit}}</span>
                        </div><div class="input-wrapper input-wrapper--w50" :class="{'error' : errors.sapUnitMultiplier}">
                            <label class="text-input-label" for="sapUnitMultiplier-product-edit">{{ $t('product.manage.field.sap_unit_multiplier') }}</label>
                            <input type="text" v-model="product.sapUnitMultiplier" id="sapUnitMultiplier-product-edit">
                            <span class="input-error">{{errors.sapUnitMultiplier}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.subText}">
                            <label class="text-input-label" for="subText-product-edit">{{ $t('product.manage.field.sub_text') }}</label>
                            <input type="text" v-model="product.subText" id="subText-product-edit">
                            <span class="input-error">{{errors.subText}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.carrierId}">
                            <label class="text-input-label">{{ $t('product.manage.field.carrier') }}</label>
                            <custom-select
                                :filterable="true"
                                :modal="true"
                                :selectedItem="product.carrier"
                                :options="product.carriers"
                                @changed="(el) => {product.carrier = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.carrierId}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.nominalWeight}">
                            <label class="text-input-label" for="nominalWeight-product-edit">{{ $t('product.manage.field.nominal_weight') }}</label>
                            <input type="text" v-model="product.nominalWeight" id="nominalWeight-product-edit">
                            <span class="input-error">{{errors.nominalWeight}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.thickness}">
                            <label class="text-input-label" for="thickness-product-edit">{{ $t('product.manage.field.thickness') }}</label>
                            <input type="text" v-model="product.thickness" id="thickness-product-edit">
                            <span class="input-error">{{errors.thickness}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.hasExtraCharge}">
                            <div class="toggle">
                                <input type="checkbox" v-model="product.hasExtraCharge" id="hasExtraCharge-product-edit">
                                <label for="hasExtraCharge-product-edit">{{ $t('product.manage.field.extra_charge') }}</label>
                            </div>
                            <span class="input-error">{{errors.hasExtraCharge}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.restricted}">
                            <div class="toggle">
                                <input type="checkbox" v-model="product.restricted" id="restricted-product-edit">
                                <label for="restricted-product-edit">{{ $t('product.manage.field.restricted') }}</label>
                            </div>
                            <span class="input-error">{{errors.restricted}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.is_adr}">
                            <div class="toggle">
                                <input type="checkbox" v-model="product.is_adr" id="is_adr">
                                <label for="is_adr">ADR (veszélyes áru)</label>
                            </div>
                            <span class="input-error">{{errors.is_adr}}</span>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="input-wrapper" :class="{'error' : errors.productNumber}">
                            <label class="text-input-label" for="productNumber-product-edit">{{ $t('product.manage.field.product_number') }}</label>
                            <input type="text" v-model="product.productNumber" id="productNumber-product-edit" disabled>
                            <span class="input-error">{{errors.productNumber}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.weight}">
                            <label class="text-input-label" for="weight-product-edit">{{ $t('product.manage.field.weight') }}</label>
                            <input type="text" v-model="product.weight" id="weight-product-edit" disabled>
                            <span class="input-error">{{errors.weight}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--w33" :class="{'error' : errors.listPriceUnitId}">
                            <label class="text-input-label">{{ $t('product.manage.field.list_price_unit') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="product.listPriceUnit"
                                :options="product.units"
                                @changed="(el) => {product.listPriceUnit = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.listPriceUnitId}}</span>
                        </div><div class="input-wrapper input-wrapper--w33" :class="{'error' : errors.listPriceVat}">
                            <label class="text-input-label" for="listPriceVat-product-edit">{{ $t('product.manage.field.vat') }}</label>
                            <input type="text" placeholder="27" v-model="product.listPriceVat" id="listPriceVat-product-edit">
                            <span class="input-error">{{errors.listPriceVat}}</span>
                        </div><div class="input-wrapper input-wrapper--w33" :class="{'error' : errors.listPriceUnitStep}">
                            <label class="text-input-label" for="listPriceUnitStep-product-edit">{{ $t('product.manage.field.list_price_unit_step') }}</label>
                            <input type="text" v-model="product.listPriceUnitStep" id="listPriceUnitStep-product-edit">
                            <span class="input-error">{{errors.listPriceUnitStep}}</span>
                        </div>
                        <div class="input-wrapper " :class="{'error' : errors.listPriceUnitStepRoundAbove}">
                            <label class="text-input-label" for="listPriceUnitStepRoundAbove-product-edit">{{ $t('product.manage.field.list_price_unit_step_round_above') }}</label>
                            <input type="text" v-model="product.listPriceUnitStepRoundAbove" id="listPriceUnitStepRoundAbove-product-edit">
                            <span class="input-error">{{errors.listPriceUnitStepRoundAbove}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.info}">
                            <label class="text-input-label" for="info-product-edit">{{ $t('product.manage.field.info') }}</label>
                            <input type="text" v-model="product.info" id="info-product-edit">
                            <span class="input-error">{{errors.info}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.surfaceId}">
                            <label class="text-input-label">{{ $t('product.manage.field.surface') }}</label>
                            <custom-select
                                :filterable="true"
                                :modal="true"
                                :selectedItem="product.surface"
                                :options="product.surfaces"
                                @changed="(el) => {product.surface = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.surfaceId}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.nominalThickness}">
                            <label class="text-input-label" for="nominalThickness-product-edit">{{ $t('product.manage.field.nominal_thickness') }}</label>
                            <input type="text" v-model="product.nominalThickness" id="nominalThickness-product-edit">
                            <span class="input-error">{{errors.nominalThickness}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.length}">
                            <label class="text-input-label" for="length-product-edit">{{ $t('product.manage.field.length') }}</label>
                            <input type="text" v-model="product.length" id="length-product-edit">
                            <span class="input-error">{{errors.length}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.packing}">
                            <label class="text-input-label" for="packing-product-edit">{{ $t('product.manage.field.packing') }}</label>
                            <input type="text" v-model="product.packing" id="packing-product-edit">
                            <span class="input-error">{{errors.packing}}</span>
                        </div>
                    </div>
                </div>
                <div class="product-units-container" v-show="tabValue === 'units'">
                    <table>
                        <thead>
                            <tr>
                                <td style="width: 130px">Mértékegység</td>
                                <td style="width: 130px">Mennyiség</td>
                                <td style="width: 130px">Lépésköz</td>
                                <td style="width: 50px;">Publikus</td>
                                <td style="width: 50px;">Inverz</td>
                                <td style="width: 30px;"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(productUnit, index) in product.productUnits" :key="index">
                                <td>
                                    <custom-select
                                        :modal="true"
                                        :selectedItem="productUnit.unit"
                                        :options="product.units"
                                        @changed="(el) => {product.productUnits[index].unit = el}"
                                        :large="true"
                                    ></custom-select>
                                </td>
                                <td>
                                    <input type="text" v-model="productUnit.amount">
                                </td>
                                <td>
                                    <input type="text" v-model="productUnit.step">
                                </td>
                                <td>
                                    <div class="toggle">
                                        <input type="checkbox" v-model="product.productUnits[index].public" :id="`product-unit-${index}-public`">
                                        <label :for="`product-unit-${index}-public`"></label>
                                    </div>
                                </td>
                                <td>
                                    <div class="toggle">
                                        <input type="checkbox" v-model="product.productUnits[index].inverse" :id="`product-unit-${index}-inverse`">
                                        <label :for="`product-unit-${index}-inverse`"></label>
                                    </div>
                                </td>
                                <td>
                                    <input type="button" value="Törlés" @click="removeProductUnit(index)">
                                </td>
                            </tr>
                            <tr>
                                <td colspan="6">
                                    <input type="button" value="Új mértékegység" @click="addProductUnit">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveProduct" :disabled="loading">
                        {{ $t('product.manage.button.save') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('product.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'
import Tabs from '../../components/Tabs.vue'
import VueTagger from '../../components/VueTagger.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalProductEdit',
    props: ['open', 'productId', 'productNumber'],

    data() {
        return {
            loading: false,
            errors: {},
            tabElements: [
                {
                    handle: 'data',
                    handleName: this.$t('product.manage.data.title')
                },
                {
                    handle: 'units',
                    handleName: this.$t('product.manage.units.title')
                }
            ],
            tabValue: 'data',
            product: {
                productNumber: null,
                name: null,
                type: null,
                subText: null,
                packing: null,
                carrier: null,
                surface: null,
                productType: null,
                nominalThickness: null,
                nominalWeight: null,
                thickness: null,
                length: null,
                weight: null,
                listPrice: null,
                listPriceVat: null,
                listPriceUnit: null,
                listPriceUnitStep: null,
                listPriceUnitStepRoundAbove: null,
                info: null,
                sapUnit: null,
                sapUnitMultiplier: null,
                hasExtraCharge: false,
                restricted: false,
                productTypes: [],
                surfaces: [],
                carriers: [],
                units: [],
                productUnits: [],
                is_adr: false
            }
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.tabValue = 'data'
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_PRODUCT', {productId: this.productId, productNumber: this.productNumber}).then(product => {
                    this.product = product
                    this.loading = false
                    this.$emit('opened')
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveProduct() {
            this.errors = {}

            let payload = {
                productId: this.productId,
                productNumber: this.productNumber,
                data: {
                    subText: this.product.subText,
                    packing: this.product.packing,
                    productTypeId: this.product.productType ? this.product.productType.id : null,
                    carrierId: this.product.carrier ? this.product.carrier.id : null,
                    surfaceId: this.product.surface ? this.product.surface.id : null,
                    nominalThickness: this.product.nominalThickness,
                    nominalWeight: this.product.nominalWeight,
                    thickness: this.product.thickness,
                    length: this.product.length,
                    listPrice: this.product.listPrice,
                    listPriceVat: this.product.listPriceVat,
                    listPriceUnitId: this.product.listPriceUnit ? this.product.listPriceUnit.id : null,
                    listPriceUnitStep: this.product.listPriceUnitStep,
                    listPriceUnitStepRoundAbove: this.product.listPriceUnitStepRoundAbove,
                    isSap: this.product.isSap,
                    sapUnitMultiplier: this.product.sapUnitMultiplier,
                    info: this.product.info,
                    hasExtraCharge: this.product.hasExtraCharge,
                    restricted: this.product.restricted,
                    is_adr: this.product.is_adr,
                    units: this.product.productUnits.map(productUnit => {
                        return {
                            id: productUnit.unit ? productUnit.unit.id : null,
                            amount: productUnit.amount,
                            step: productUnit.step,
                            public: productUnit.public,
                            inverse: productUnit.inverse
                        }
                    })
                }
            }

            this.$store.dispatch('SEND_PRODUCT', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },

        removeProductUnit(index) {
            this.product.productUnits.splice(index, 1)
        },

        addProductUnit() {
            this.product.productUnits.push({
                unit: null,
                amount: null,
                step: 1,
                public: false,
                inverse: false
            })
        }
    },

    components: { Tabs, VueTagger, CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        width: 800px;

        &-body {

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--checkbox {
                    margin-bottom: 0;
                }

                .dropdown-list {
                    top: 76px;
                }

                .toggle {
                    margin-left: 22px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .product-units-container {
                float: left;
                width: 100%;
                margin-top: 15px;

                table {
                    width: 100%;
                    border-spacing: 5px;
                    border-collapse: separate;

                    .toggle {
                        position: relative;

                        label {
                            padding-left: 0;
                            position: absolute;
                            top: 0;
                            left: 27px;
                        }
                    }
                }
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
