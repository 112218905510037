<template>
    <div class="modal" :class="{ 'is-open': modals.comments.open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.saved_comments.title') }}</span>
                <button type="button" @click="close" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <tabs
                    :elements="tabElements"
                    :selected="tabValue"
                    @changed="(el) => {this.tabValue = el}"
                ></tabs>

                <div
                    class="comment-element"
                    v-for="(comment, commentIndex) in savedComments[tabValue]"
                    :key="commentIndex"
                >
                    <span class="text">{{comment}}</span>
                    <button
                        class="btn btn-plain btn-plain-wicon"
                        v-show="tabValue === 'personal'"
                        @click="deleteComment(commentIndex)"
                    >
                        <md-icon>delete</md-icon>
                    </button>

                    <button
                        class="btn btn-plain btn-plain-wicon"
                        v-show="tabValue === 'personal'"
                        @click="editComment(commentIndex, comment)"
                    >
                        <md-icon>edit</md-icon>
                    </button>

                    <button
                        class="btn btn-plain btn-select"
                        @click="selectComment(comment)"
                    >{{ $t('offer.saved_comments.button.select') }}</button>
                </div>
            </div>
        </div>

        <div class="modal-layer"></div>

        <modal-edit-comment :initIndex="editIndex" :initText="editText"></modal-edit-comment>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import Tabs from '../../components/Tabs.vue'
import ModalEditComment from '../../components/modals/ModalEditComment.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalSavedComments',

    data() {
        return {
            tabElements: [
                {
                    handle: 'personal',
                    handleName: this.$t('offer.saved_comments.tabs.personal')
                },
                {
                    handle: 'global',
                    handleName: this.$t('offer.saved_comments.tabs.global')
                }
            ],
            tabValue: 'personal',
            editIndex: 0,
            editText: ''
        }
    },

    watch: {
        'modals.editComment.open': function(val) {
            if (!val) {
                this.$forceUpdate()
            }
        }
    },

    mounted() {},

    methods: {
        selectComment(text) {
            this.$store.commit('selectSavedComment', text)
            this.close()
        },

        editComment(index, text) {
            this.editIndex = index
            this.editText = text
            this.$store.commit('openModal', 'editComment')
        },

        deleteComment(index) {
            let message = {
                type: this.tabValue,
                index: index
            }
            this.$store.commit('deleteSavedComment', message)
        },

        close() {
            this.$store.commit('closeModal', 'comments')
        }
    },

    computed: {
        ...mapGetters(['modals', 'savedComments'])
    },

    components: { Tabs, ModalEditComment }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            .comment-element {
                padding: 12px 0;
                border-bottom: 1px solid $color-gray-border;

                .text {
                    display: inline-block;
                    width: 60%;
                    font-size: 13px;
                    line-height: 18px;
                }

                .btn {
                    float: right;
                    margin: 0 5px;
                }

                .btn-select {
                    margin-right: 15px;
                }
            }
        }
    }
}
</style>
