<template>
    <div class="worker-list-page" v-if="userHasWorkers">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new">
                <button
                    @click="newWorker"
                    class="btn btn-primary"
                    type="button">
                    <md-icon>add_circle</md-icon> Új tetőfedő
                </button>
            </div>

            <div class="dashboard-header__filters">
                <div class="worker-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="workerListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                    </div>

                    <div style="display:contents;" v-if="userCanSeeFilters">
                        <custom-select
                            class="filter-component"
                            v-if="offerListAdvisories.length > 1"
                            :selectedItem="selectedAdvisory"
                            :filterable="true"
                            :large="true"
                            :options="offerListAdvisories"
                            @changed="filterAdvisories">
                        </custom-select>
                    </div>
                </div>

                <div class="worker-list-page__header-controls">
                    <div style="display:contents" v-if="userCanSeeFilters">
                        <button @click="exportSelected()" type="button">
                            <md-icon>download</md-icon>
                        </button>
                        <a href="" download ref="pdfButton" class="pdf-download"></a>
                    </div>

                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <custom-table
                ref="table"
                :columns="tableColumns"
                :pages="workerListPages"
                :meta="workerListMeta"
                @load-page="loadPage"
                @remove-page="removePage"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-options" v-if="props.column.field === 'options' && props.row.actions.length > 0">
                        <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            @clicked="onDropdownClick"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                        ></dropdown>
                    </div>

                    <div class="col-created" v-else-if="props.column.field === 'createdPerson'">
                        <div class="small-line">{{ props.row.createdPerson }}</div>
                        <div class="large-line show-onhover">{{ props.row.createdAtFormatted }}</div>
                    </div>

                    <div class="col-created" v-else-if="props.column.field === 'updaterPerson'">
                        <div class="small-line">{{ props.row.updaterPerson }}</div>
                        <div class="large-line show-onhover">{{ props.row.updatedAtFormatted }}</div>
                    </div>

                    <div v-else-if="props.column.field !== 'options'">{{ props.formattedRow[props.column.field] }}</div>
                </template>
                <div slot="empty_state">{{ $t('worker.page.list.text.empty_state') }}</div>
            </custom-table>
        </div>

        <modal-worker-add
            :open="openWorkerModal"
            :workerId="selectedWorker"
            @close="workerEditEnd">
        </modal-worker-add>

        <modal-project-select-basic
            :open="openSelectModal"
            :workerId="selectedWorker"
            @close="projectSelectEnd">
        </modal-project-select-basic>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="deleteWorker()"
            @close="openConfirmationModal = false"
        ></modal-confirmation>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalWorkerAdd from '../components/modals/ModalWorkerAdd.vue'
import ModalProjectSelectBasic from '../components/modals/ModalProjectSelectBasic.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import CustomTable from '../components/CustomTable'
import CustomSelect from '@/components/dropdowns/CustomSelect.vue'
import axios from "axios";
import config from "@/plugins/config";

Vue.use(MdIcon)

export default {
    name: 'WorkerList',

    data() {
        return {
            loading: true,
            openWorkerModal: false,
            openSelectModal: false,
            openConfirmationModal: false,
            selectedWorker: null,
            filteredAdvisory: 0,

            searchTimer: null,
            selectedAdvisory: {},

            tableColumns: [
                {
                    label: this.$t('worker.page.list.column.name'),
                    field: 'name',
                    width: '40%'
                },
                {
                    label: this.$t('dealer.page.list.column.sap_number'),
                    field: 'sapNumber',
                    width: '12%'
                },
                {
                    label: this.$t('worker.page.list.column.email'),
                    field: 'email',
                    width: '20%'
                },
                {
                    label: this.$t('worker.page.list.column.phone'),
                    field: 'phone',
                    width: '20%'
                },
                {
                    label: this.$t('worker.page.list.column.created_person'),
                    field: 'createdPerson',
                    width: '10%'
                },
                {
                    label: this.$t('worker.page.list.column.updater_person'),
                    field: 'updaterPerson',
                    width: '10%'
                },
                {
                    label: '',
                    field: 'options',
                    width: '6%'
                }
            ],
            confirmationModalData: {
                acceptText: this.$t('worker.page.list.confirmation.delete.ok'),
                cancelText: this.$t('worker.page.list.confirmation.delete.cancel'),
                message: this.$t('worker.page.list.confirmation.delete.question')
            }
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasWorkers) {
                this.$store.commit('resetWorkers');
                this.$store.dispatch('FETCH_WORKERS', {page: 1, keepOtherPages: false}).then(() => {
                    this.loading = false
                })

                this.$store.dispatch('FETCH_OFFER_ADVISORIES').then(() => {
                    this.selectedAdvisory = this.offerListAdvisories[0];
                });

            } else {
                this.$router.push('/')  ;
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    methods: {
        exportSelected() {
            axios.post('workers/export', this.workerListActiveFilters, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
        },
        onSearchPhraseChanged(phrase) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.workerListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setWorkerListActiveFilters', {
                    ...this.workerListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        filterAdvisories(e) {
            this.selectedAdvisory = e;
            this.$store.commit('setWorkerListActiveFilters', {
                ...this.objectListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_WORKERS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        removePage(page) {
            this.$store.commit('removeWorkerListPage', page)
        },

        onRowClick(params) {
            if (!params.row.showDropdown && !params.event.target.className.includes('element-options')) {
                this.selectedWorker = params.row.id
                this.openWorkerModal = true
            }
        },

        onDropdownClick(val) {
            this.selectedWorker = val.id

            switch (val.action) {
            case 'worker-add-to-project':
                this.openSelectModal = true
                break
            case 'worker-edit':
                this.openWorkerModal = true
                break
            case 'worker-delete':
                this.openConfirmationModal = true
                break
            }
        },

        workerEditEnd(refresh) {
            this.openWorkerModal = false
            if (refresh) {
                this.refreshPages()
            }
        },

        projectSelectEnd(refresh) {
            this.openSelectModal = false
            if (refresh) {
                this.refreshPages()
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        deleteWorker() {
            this.openConfirmationModal = false

            let newData = {
                id: this.selectedWorker
            }
            this.$store.dispatch('DELETE_WORKER', newData).then(() => {
                this.$store.commit('setNotification', [this.$t('worker.page.list.confirmation.delete.notification.success')])
                this.refreshPages()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('worker.page.list.confirmation.delete.notification.failed')])
            })
        },

        newWorker() {
            this.selectedWorker = null
            this.openWorkerModal = true
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_WORKERS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },

        refreshPages() {
            this.loading = true
            this.$store.dispatch('FETCH_WORKER_PAGES').then(() => {
                this.loading = false
            })
        }
    },

    computed: {
        searchData() {
            return {
                placeholder: this.$t('worker.page.list.search.placeholder'),
                tooltipText: this.$t('worker.page.list.search.tooltip'),
                countText: this.$tc('worker.page.list.search.name', this.workerListMeta.total)
            }
        },

        ...mapGetters([
            'workerListPages',
            'offerListAdvisories',
            'workerListMeta',
            'workerListActiveFilters',
            'userHasWorkers',
            'userCanSeeFilters'
        ])
    },

    components: {
        MainNavigation,
        BasicLinks,
        CustomTable,
        Search,
        Dropdown,
        ModalWorkerAdd,
        ModalProjectSelectBasic,
        ModalConfirmation,
        Notification,
        CustomSelect
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.worker-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: 60%;
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .col-options {
        position: relative;
        width: 100px;
    }
}
</style>
