<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>keyboard_arrow_left</md-icon></button>
                <span class="title">{{ $t('project.duplicate.title') }}</span>
            </div>

            <div class="modal-box-body">
                <project-data-form :projData="{...projectDataForDuplicate}" :duplicateProject="true" @close="$emit('close')"></project-data-form>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import ProjectDataForm from '../../components/ProjectDataForm.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectDuplicate',
    props: ['open'],

    computed: {
        ...mapGetters(['projectData']),

        projectDataForDuplicate() {
            let data = {...this.projectData}

            data.name = this.$t('project.duplicate.text.duplicated_name', {name: data.name})

            if(data.dealer && data.dealer.deleted) {
                data.dealer = null
            }

            return data
        }
    },

    components: { ProjectDataForm }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            button {
                float: left;
            }
        }
    }
}
</style>
