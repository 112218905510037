import axios from 'axios'
import store from '../store'
import {zoomLevel} from 'zoom-level'
import {detect} from 'detect-browser'
import i18n from '../i18n'

let browser

try {
    const browserDetect = detect()
    if(browserDetect) {
        browser = {n: browserDetect.name, v: browserDetect.version, os: browserDetect.os, t: browserDetect.type}
    }
} catch (e) {
    console.error(e)
}

export default function setup() {
    axios.interceptors.request.use(function (config) {
        config.headers['X-Client-Version'] = process.env.VERSION

        try {
            config.headers['X-Client-Debug'] = JSON.stringify({
                w: window.innerWidth,
                h: window.innerHeight,
                z: zoomLevel(),
                b: browser
            })
        } catch (e) {
            console.error(e)
            config.headers['X-Client-Debug'] = JSON.stringify({e: e.message})
        }

        if (store.state.authToken) {
            config.headers.Authorization = store.state.authToken
        }

        if (store.state.authSession) {
            if (config.url.indexOf('session=') === -1) {
                if (config.url.indexOf('?') > -1) {
                    config.url = `${config.url}&session=1`
                } else {
                    config.url = `${config.url}?session=1`
                }
            }

            config.withCredentials = true
        } else if (config.url.indexOf('session=1')) {
            config.withCredentials = true
        }

        return config
    }, function (err) {
        return Promise.reject(err)
    })

    axios.interceptors.response.use((response) => {
        return response.data
    }, function (error) {
        if (axios.isCancel(error)) {
            return null
        } else if (error && error.response && error.response.data) {
            return Promise.reject({...error.response.data, response: {status: error.response.status, statusText: error.response.statusText}})
        } else {
            console.error(error)
            return Promise.reject({
                error: i18n.t('common.error.occurred')
            })
        }
    })
}
