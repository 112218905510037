import i18n from '../i18n'
import Paragraph from '@editorjs/paragraph';
import './title.css'

export default class Title extends Paragraph {
    static get toolbox() {
        return {
            // icon: '<svg width="12" height="12" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 341.333 341.333" style="enable-background:new 0 0 341.333 341.333;" xml:space="preserve"><g><g><polygon points="298.667,0 42.667,0 42.667,42.667 181.333,170.667 42.667,298.667 42.667,341.333 298.667,341.333 298.667,277.333 149.333,277.333 256,170.667 149.333,64 298.667,64 \t\t"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            icon: '<svg width="18" height="18" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g style="opacity:0.36;"><rect y="394.667" width="512" height="85.333"/></g></g><g><g><path d="M277.333,32h-42.667L117.333,330.667h48l24-64h133.333l24,64h48L277.333,32z M205.333,224L256,88.853L306.667,224H205.333 z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.title')
        };
    }

    drawView() {
        let div = super.drawView();

        div.classList.add('ejs-title');

        return div;
    }
}
