<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">

            <div class="dashboard-header__new" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon>
                    {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="product-list-page__header-search-flex">
                    <div class="search-container">
                        <search :searchData="searchData" @searchPhrase="(value) => {this.searchPhrase = value}"></search>
                    </div>

                    <custom-filter
                        v-if="groupCategoryProductCategories.length > 0"
                        :options="groupCategoryProductCategories"
                        :label="''"
                        :value="filteredCategory"
                        :dropdown-size="600"
                        @selected="(e) => {this.filteredCategory = e.id}"
                    ></custom-filter>
                </div>

                <div class="product-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <group-admin-pages selected-page="GroupCategoryProductList" :group="group"></group-admin-pages>
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column">
            <vue-good-table
                :columns="tableColumns"
                :rows="getProductRows()"
                :sort-options="{enabled: false}"
                styleClass="vgt-table non-interactive"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-name" v-if="props.column.field === 'name'">
                        <div :style="`margin-left:${props.row.depth * 20}px`">{{ props.row.name }}</div>
                    </div>
                    <span v-else-if="props.column.field === 'type'">{{ getTypeName(props.formattedRow[props.column.field]) }}</span>
                    <div v-else-if="Array.isArray(props.formattedRow[props.column.field])">
                        <div v-for="(value, index) in props.formattedRow[props.column.field]" :key="index">{{ value }}</div>
                    </div>
                    <div class="col-options" v-else-if="props.column.field === 'options' && props.row.actions.length > 0">
                        <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            width="250"
                        ></dropdown>
                    </div>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="emptystate">{{ $t('group_category_product.page.list.text.empty_state') }}</div>
            </vue-good-table>
        </div>

        <modal-group-category-edit
            :open="openCategoryEditModal"
            :group-id="groupId"
            :category-id="selectedCategoryId"
            :create-target="selectedCreateTarget"
            @close="categoryEditEnd">
        </modal-group-category-edit>

        <modal-group-category-product-edit
            :open="openCategoryProductEditModal"
            :group-id="groupId"
            :category-id="selectedCategoryId"
            :product-id="selectedCategoryProductId"
            :create-target="selectedCreateTarget"
            @close="categoryProductEditEnd">
        </modal-group-category-product-edit>

        <modal-confirmation
            :open="openConfirmationDeleteCategoryModal"
            :data="confirmationDeleteCategoryModalData"
            @accepted="categoryDelete"
            @close="openConfirmationDeleteCategoryModal = false"
        ></modal-confirmation>

        <modal-confirmation
            :open="openConfirmationDeleteCategoryProductModal"
            :data="confirmationDeleteCategoryProductModalData"
            @accepted="categoryProductDelete"
            @close="openConfirmationDeleteCategoryProductModal = false"
        ></modal-confirmation>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {VueGoodTable} from 'vue-good-table'
import {MdIcon} from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import Search from '../components/Search.vue'
import ModalGroupCategoryEdit from '../components/modals/ModalGroupCategoryEdit.vue'
import ModalGroupCategoryProductEdit from '../components/modals/ModalGroupCategoryProductEdit.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation'
import GroupAdminPages from "@/components/sidebars/GroupAdminPages";
import BasicLinks from '../components/BasicLinks.vue'

Vue.use(MdIcon)
Vue.use(VueGoodTable)

export default {
    name: 'GroupCategoryProductList',

    data() {
        return {
            loading: true,
            selectedCategoryId: null,
            selectedCategoryProductId: null,
            selectedCreateTarget: null,
            openCategoryEditModal: false,
            openCategoryProductEditModal: false,
            openGroupListDropdown: false,
            productGroups: [],
            filteredCategory: 0,

            deleteCategoryId: null,
            openConfirmationDeleteCategoryModal: false,
            confirmationDeleteCategoryModalData: {
                acceptText: this.$t('group_category.confirmation.delete.ok'),
                cancelText: this.$t('group_category.confirmation.delete.cancel'),
                message: this.$t('group_category.confirmation.delete.question')
            },

            deleteCategoryProductId: null,
            openConfirmationDeleteCategoryProductModal: false,
            confirmationDeleteCategoryProductModalData: {
                acceptText: this.$t('group_category_product.page.list.confirmation.delete.ok'),
                cancelText: this.$t('group_category_product.page.list.confirmation.delete.cancel'),
                message: this.$t('group_category_product.page.list.confirmation.delete.question')
            },

            searchData: {
                placeholder: this.$t('group_category_product.page.list.search.placeholder'),
                tooltipText: this.$t('group_category_product.page.list.search.tooltip'),
                countText: ''
            },

            searchPhrase: '',

            tableColumns: [
                {
                    label: this.$t('group_category_product.page.list.column.name'),
                    field: 'name',
                    width: '50%'
                },
                {
                    label: this.$t('group_category_product.page.list.column.type'),
                    field: 'type',
                    width: '44%'
                },
                {
                    label: '',
                    field: 'options',
                    width: '6%'
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.fetchData()
                })
            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route'() {
            this.$store.commit('resetGroupCategoryProductList')
            this.resetFilter()
            this.refresh()
        }
    },

    methods: {
        getProductRows() {
            let tableData = this.groupCategoryProductRows, searchPhrase

            if (this.filteredCategory !== 0) {
                tableData = tableData.filter(row => {
                    return row.categoryIds.includes(this.filteredCategory)
                })
            }

            if (this.searchPhrase !== '') {
                searchPhrase = this.searchPhrase.toLowerCase().split(/\s+/)
                tableData = tableData.filter(row => {
                    let valid = true
                    for (let value of searchPhrase) {
                        valid = valid && row.search.toLowerCase().indexOf(value) > -1
                    }
                    return valid
                })
            }

            this.searchData.countText = this.$tc('group_category_product.page.list.search.name', tableData.length)

            return tableData
        },

        onDropdownClick(val) {
            switch (val.action.action) {
            case 'category-edit':
                this.selectedCategoryId = val.id
                this.selectedCreateTarget = null
                this.openCategoryEditModal = true
                break
            case 'category-create':
                this.selectedCategoryId = val.id
                this.selectedCreateTarget = val.action.target
                this.openCategoryEditModal = true
                break
            case 'category-delete':
                this.deleteCategoryId = val.id
                this.openConfirmationDeleteCategoryModal = true
                break
            case 'category-product-create':
                this.selectedCategoryId = val.id
                this.selectedCategoryProductId = null
                this.selectedCreateTarget = val.action.target
                this.openCategoryProductEditModal = true
                break
            case 'product-edit':
                this.selectedCategoryId = val.action.categoryId
                this.selectedCategoryProductId = val.id
                this.selectedCreateTarget = null
                this.openCategoryProductEditModal = true
                break
            case 'product-create':
                this.selectedCategoryId = val.action.categoryId
                this.selectedCategoryProductId = val.id
                this.selectedCreateTarget = val.action.target
                this.openCategoryProductEditModal = true
                break
            case 'product-delete':
                this.deleteCategoryId = val.action.categoryId
                this.deleteCategoryProductId = val.id
                this.openConfirmationDeleteCategoryProductModal = true
                break
            }
        },

        getTypeName(type) {
            switch (type) {
            case 'category': return 'Kategória';
            case 'product': return 'Termék';
            default: return type;
            }
        },

        resetFilter() {
            this.filteredCategory = 0
        },

        fetchData() {
            this.$store.dispatch('FETCH_GROUP_CATEGORY_PRODUCTS', this.$route.params.groupId).then(data => {
                this.loading = false;
            })
        },

        categoryEditEnd(refresh) {
            this.openCategoryEditModal = false
            if (refresh) {
                this.refresh()
            }
        },

        categoryDelete() {
            let payload = {
                groupId: this.$route.params.groupId,
                categoryId: this.deleteCategoryId
            }

            this.$store.dispatch('DELETE_GROUP_CATEGORY', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('group_category.confirmation.delete.notification.success')])
                this.deleteCategoryId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('group_category.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteCategoryModal = false
            })
        },

        categoryProductEditEnd(refresh) {
            this.openCategoryProductEditModal = false
            if (refresh) {
                this.refresh()
            }
        },

        categoryProductDelete() {
            let payload = {
                groupId: this.$route.params.groupId,
                categoryId: this.deleteCategoryId,
                productId: this.deleteCategoryProductId
            }

            this.$store.dispatch('DELETE_GROUP_CATEGORY_PRODUCT', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('group_category_product.page.list.confirmation.delete.notification.success')])
                this.deleteCategoryId = null
                this.deleteCategoryProductId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('group_category_product.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteCategoryProductModal = false
            })
        },

        onRowClick(params) {
            if (!params.event.target.className.includes('element-options')) {
                params.row.showDropdown = false
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProductType(payload) {
            this.openCategoryEditModal = false
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        refresh() {
            this.loading = true
            this.fetchData()
        },
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        groupId() {
            return this.$route.params.groupId
        },

        group() {
            return this.productGroups.find(group => group.id === Number(this.groupId))
        },

        ...mapGetters(['groupCategoryProductRows', 'groupCategoryProductCategories', 'groupTypeCategories', 'userHasProductAdmin'])
    },

    components: {
        MainNavigation,
        VueGoodTable,
        ProductTypeDropdown,
        Search,
        Dropdown,
        ModalGroupCategoryEdit,
        ModalGroupCategoryProductEdit,
        Notification,
        CustomFilter,
        BasicLinks,
        GroupAdminPages,
        ModalConfirmation
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .checkbox {
            margin-left: auto;
            margin-right: 32px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: calc(100% - 70px);
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .filter-column {
        max-width: calc(100vh - 140px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
