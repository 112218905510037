<template>
    <div class="auth-form">
        <div class="auth-form__toplink">
            <a @click="$emit('switchToLogin')">{{ $t('forgot_password.button.back_to_login') }}</a>
        </div>

        <div class="auth-form__title">{{ $t('forgot_password.title') }}</div>

        <p class="auth-form__subtitle" v-if="submitted" v-html="submittedText"></p>
        <p class="auth-form__subtitle" v-else>{{ $t('forgot_password.description') }}</p>

        <div class="auth-form__row" v-if="!submitted" :class="{ 'error' : errors['email'] }">
            <label for="l_forgot_email">{{ $t('forgot_password.field.email.label') }}</label>
            <input type="text" name="email" v-model="email" tabindex="3" id="l_forgot_email" @keyup="changedEmailInput">
            <span class="auth-form__error" v-if="errors['email']">{{errors['email']}}</span>
        </div>

        <div class="auth-form__submit" v-if="!submitted">
            <button type="button" class="btn btn-blue" @click="sendRequest()" :disabled="loading">{{ $t('forgot_password.button.send') }}</button>

            <span class="auth-form__error" v-if="generalError.length > 0">
                {{generalError}}
            </span>
        </div>

        <div class="auth-form__again" v-if="submitted">
            <h6>{{ $t('forgot_password.not_received.title') }}</h6>
            <p>{{ $t('forgot_password.not_received.description') }}</p>

            <div class="auth-form__submit">
                <button type="button" class="btn btn-gray" @click="() => this.submitted = false" :disabled="loading">{{ $t('forgot_password.button.resend') }}</button>
            </div>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import config from '../../plugins/config.js'
import axios from 'axios'

import { scrollToError } from '@/plugins/helpers'

import Notification from '../Notification.vue'

export default {
    name: 'ForgotPassword',

    data() {
        return {
            loading: false,
            submitted: false,
            email: '',
            errors: {},
            generalError: ''
        }
    },

    computed: {
        submittedText() {
            return this.$t('forgot_password.submitted', {
                email_hu_article: this.emailArticle,
                email_bold: `<strong>${this.email}</strong>`,
                email: this.email
            })
        },

        emailArticle() {
            if (this.email && /^[aeiou]/i.test(this.email)) {
                return 'az'
            } else {
                return 'a'
            }
        }
    },

    methods: {
        changedEmailInput() {
            if (Object.keys(this.errors).length > 0) {
                this.errors = {}
            }

            if (this.generalError.length > 0) {
                this.generalError = ''
            }
        },

        sendRequest() {
            this.loading = true

            let data = {
                email: this.email
            }

            axios.post('auth/reset/request', data, config.AXIOS_DEFAULTS)
                .then(() => {
                    this.$store.commit('setNotification', [this.$t('forgot_password.notification')])
                    this.submitted = true
                })
                .catch(response => {
                    this.errors = response.errors || {}
                    this.generalError = response.error || ''
                    scrollToError()
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/loading.scss';
@import '../../assets/sass/partials/auth-form.scss';
</style>
