<template>
    <div class="search-component">
        <input
            class="input-search"
            type="text"
            :placeholder="searchData.placeholder"
            v-model="searchInputValue"
            @keyup="submitValue"
        >
        <md-icon class="icon-search">search</md-icon>
        <md-icon class="icon-info" v-tooltip="searchData.tooltipText">info_outline</md-icon>

        <div class="option-box" :class="{ 'show': searchInputValue.length > 0}">
            <span class="count">{{searchData.countText}}</span>

            <button type="button" @click="emptyInput">
                <md-icon>close</md-icon>
            </button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import VTooltip from 'v-tooltip'

Vue.use(MdIcon)
Vue.use(VTooltip)

export default {
    name: 'Search',
    props: ['searchData', 'input'],

    data() {
        return {
            searchInputValue: this.input || ''
        }
    },

    watch: {
        input(val) {
            if(typeof val === 'undefined' || val === '') {
                this.searchInputValue = '';
            }
        }
    },

    methods: {
        emptyInput() {
            this.searchInputValue = ''
            this.$emit('searchPhrase', this.searchInputValue)
        },

        submitValue() {
            this.$emit('searchPhrase', this.searchInputValue)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.search-component {
    position: relative;
    width: 100%;

    .input-search {
        width: 100%;
        padding-top: 13px;
        padding-left: 44px;
        padding-right: 50px;
    }

    .icon-search {
        position: absolute;
        top: 17px;
        left: 12px;
    }

    .icon-info {
        font-size: 22px !important;
        position: absolute;
        top: 17px;
        right: 12px;
    }

    .option-box {
        background: $color-white;
        display: none;
        position: absolute;
        top: 8px;
        right: 5px;
        height: calc(100% - 16px);
        padding: 5px 0 5px 10px;
        align-items: center;

        &.show {
            display: flex;
        }

        .btn {
            font-size: 10px;
            line-height: 15px;
            padding: 4px 10px;
            border-radius: 40px;
            margin-right: 10px;
        }

        .count {
            font-size: 12px;
            line-height: 24px;
            padding-right: 12px;
            border-right: 1px solid $color-gray-border;
            margin-left: 4px;
            margin-right: 6px;
        }

        button {
            padding-top: 7px;
        }

        .filter {
            display: none;

            &.show {
                display: block;
            }
        }
    }
}
</style>
