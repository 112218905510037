<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title" v-show="!successfulBugReport">{{ $t('bug_report.title') }}</span>
                <span class="title" v-show="successfulBugReport">{{ $t('bug_report.text.success_title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>
            <div class="modal-box-body">
                <div class="input-wrapper" v-show="!successfulBugReport">
                    <label class="label" for="textarea-bug-message">{{ $t('bug_report.text.message') }}</label>
                    <textarea v-model="bugMessage" id="textarea-bug-message"></textarea>
                </div>
                <button
                    @click="submitBugReport"
                    :disabled="bugMessage.length < 9"
                    v-show="!successfulBugReport"
                    class="btn btn-primary"
                    type="button"
                >{{ $t('bug_report.button.send') }}
                </button>
                <button class="btn btn-plain" type="button" @click="$emit('close')" v-show="!successfulBugReport">
                    {{ $t('bug_report.button.cancel') }}
                </button>

                <div class="success-message" v-show="successfulBugReport">{{
                        $t('bug_report.text.success_message')
                    }}
                </div>
                <button class="btn btn-primary" type="button" @click="$emit('close')" v-show="successfulBugReport">
                    {{ $t('bug_report.button.close') }}
                </button>
            </div>
        </div>

        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {MdIcon} from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalBugReport',
    props: ['open'],

    data() {
        return {
            bugMessage: '',
            successfulBugReport: false
        }
    },

    watch: {
        'open': function (newValue, oldValue) {
            if (this.successfulBugReport && newValue && !oldValue) {
                this.bugMessage = ''
                this.successfulBugReport = false
            }
        }
    },

    methods: {
        submitBugReport() {
            if (this.bugMessage.length > 8) {
                this.$store.dispatch('SEND_BUG_REPORT', {message: this.bugMessage}).then(() => {
                    this.successfulBugReport = true
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        &-body {
            .input-wrapper {
                margin-top: 15px;
                margin-bottom: 25px;

                .label {
                    font-size: 14px;
                }

                textarea {
                    margin-top: 7px;
                }
            }

            .btn-primary {
                margin-right: 20px;
            }

            .success-message {
                font-size: 15px;
                margin-top: 15px;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
