<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('region.manage.title', {name: region.name}) }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="col-left">
                    <div class="input-wrapper" :class="{'error' : errors.bramacAdvisoryId}">
                        <label class="text-input-label">{{ $t('region.manage.field.bramac_advisory') }}</label>
                        <custom-select
                            :modal="true"
                            :selectedItem="region.bramacAdvisory"
                            :options="region.advisoryUsers"
                            @changed="selectedBramacAdvisory"
                            :cursorNotAllowed="false"
                            :bold="false"
                            :large="true"
                            placeholder="-"
                        ></custom-select>
                        <span class="input-error">{{errors.bramacAdvisoryId}}</span>
                    </div>
                </div>
                <div class="col-right">
                    <div class="input-wrapper" :class="{'error' : errors.villasAdvisoryId}">
                        <label class="text-input-label">{{ $t('region.manage.field.villas_advisory') }}</label>
                        <custom-select
                            :modal="true"
                            :selectedItem="region.villasAdvisory"
                            :options="region.advisoryUsers"
                            @changed="selectedVillasAdvisory"
                            :cursorNotAllowed="false"
                            :bold="false"
                            :large="true"
                            placeholder="-"
                        ></custom-select>
                        <span class="input-error">{{errors.villasAdvisoryId}}</span>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveRegion" :disabled="loading">
                        {{ $t('region.manage.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('region.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalRegionAdd',
    props: ['open', 'regionId'],

    data() {
        return {
            loading: false,
            errors: {},
            region: {
                name: '',
                bramacAdvisory: null,
                villasAdvisory: null,
                advisoryUsers: []
            }
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_REGION', this.regionId).then(region => {
                    this.region = region
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        selectedBramacAdvisory(val) {
            this.region.bramacAdvisory = val
        },

        selectedVillasAdvisory(val) {
            this.region.villasAdvisory = val
        },

        saveRegion() {
            this.errors = {}

            let payload = {
                id: this.regionId,
                data: {
                    bramacAdvisoryId: this.region.bramacAdvisory ? this.region.bramacAdvisory.id : null,
                    villasAdvisoryId: this.region.villasAdvisory ? this.region.villasAdvisory.id : null
                }
            }

            this.$store.dispatch('SEND_REGION', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
    },

    components: { CustomSelect, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            input {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                .dropdown-list {
                    top: 76px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
