<template>
    <div class="text-page">
        <h1>{{ $t('maintenance.page.text.title') }}</h1>
        <p>{{ $t('maintenance.page.text.message') }}</p>
        <a href="javascript:location.reload()" class="btn btn-primary">{{ $t('maintenance.page.button.retry') }}</a>
    </div>
</template>

<script>
import axios from 'axios'
import config from '../plugins/config'
import {decode} from 'url-safe-base64'

export default {
    name: 'Maintenance',

    mounted() {
        return axios.get('check', config.AXIOS_DEFAULTS)
            .then(() => {
                let back = this.$route.params.back;

                if (back) {
                    try {
                        back = JSON.parse(atob(decode(back)))
                    } catch (e) {
                        back = '/'
                    }
                } else {
                    back = '/'
                }

                this.$router.replace(back);
            }).catch(() => {
                // noop
            })
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/toolkit.scss';
    @import '../assets/sass/ui/button.scss';

    .text-page {
        text-align: center;
        h1 {
            font-size: 42px;
            margin: 15vh 0 36px;
        }
        p {
            margin-bottom: 40px;
        }
    }
</style>
