<template>
    <div class="dropdown-component" :style="width ? `width:${width}px` : null" v-show="open" ref="dropdownComponent">
        <ul class="dropdown-list">
            <li
                v-for="option in options"
                :key="option.actionName"
                @click.stop="sendAction(option.handle)"
                v-show="option.handle">
                <md-icon v-show="option.icon">{{ option.icon }}</md-icon>
                <span>{{ option.actionName }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'Dropdown',
    props: ['open', 'options', 'elementId', 'elementPayload', 'width'],

    data() {
        return {}
    },

    watch: {
        open: {
            handler: function(val) {
                if (val) {
                    this.checkPosition()
                }
            }
        }
    },

    methods: {
        sendAction(action) {
            if (this.elementId) {
                if (this.elementPayload) {
                    this.$emit('clicked', {action: action, id: this.elementId, payload: this.elementPayload})
                } else {
                    this.$emit('clicked', {action: action, id: this.elementId})
                }
            } else {
                this.$emit('clicked', action)
            }

            return false
        },

        checkPosition() {
            let el = this.$refs.dropdownComponent

            setTimeout(function() {
                let rect = el.getBoundingClientRect()
                if (window.innerHeight < rect.bottom + 5) {
                    el.classList.add('bottom')
                }
            }, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';

.dropdown-component {
    position: absolute;
    width: 190px;
    top: 32px;
    right: 10px;
    text-transform: uppercase;
    font-weight: bold;

    &.bottom {
        top: auto;
        bottom: 5px;
    }

    .dropdown-list {
        top: 0;
        position: relative;
        border-radius: 2px;
        background: $color-white;
        z-index: 100;

        li {
            display: flex;
            font-size: 11px;
            line-height: 19px;
            padding: 12px 22px;
            vertical-align: middle;

            i {
                position: relative;
                line-height: 15px;
                height: 14px;
                width: 14px;
                top: 3px;
                margin-right: 7px;
            }
        }
    }
}
</style>
