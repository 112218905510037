import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '../plugins/config'
import hu from './locales/hu.json'
import en from './locales/en.json'

Vue.use(VueI18n)

export default new VueI18n({
    locale: config.I18N_LOCALE,
    messages: {hu, en}
})
