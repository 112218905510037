<template>
    <div class="text-page">
        <h1>{{ $t('not_found.page.text.title') }}</h1>
        <router-link to="/" class="btn btn-primary">{{ $t('not_found.page.button.back') }}</router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style lang="scss" scoped>
    @import '../assets/sass/toolkit.scss';
    @import '../assets/sass/ui/button.scss';

    .text-page {
        text-align: center;
        h1 {
            font-size: 42px;
            margin: 15vh 0 36px;
        }
    }
</style>
