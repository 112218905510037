<template>
    <div class="tag-select" :class="{'tag-select--has-selected-tag': selectedTag}">
        <div class="tag-select__title">{{ $t('offer.tags.title') }}</div>

        <ul class="tag-select__list">
            <li v-for="(tag, index) in options" :key="index" :class="{ 'is-selected': selectedTag === tag.id }">
                <div class="tag-select__item">
                    <a :class="`color-${tag.color}`" @click="selectTag(tag)">{{ tag.title }}</a>
                    <a v-if="tag.is_template == false || profile.canDeleteTags" class="button-tag-delete" @click="deleteTag(tag)">
                        <md-icon>close</md-icon>
                    </a>
                </div>

                <ul v-if="tag.children && tag.children.length > 0 && profile.canSelectChildTags" class="tag-select__children">
                    <li v-for="(childTag, childIndex) in tag.children" :key="childIndex" :class="{ 'is-selected': selectedTag === childTag.id }">
                        <div class="tag-select__item child" style="margin-left:20px">
                            <a :class="`color-${childTag.color}`" @click="selectTag(childTag)">{{ childTag.title }}</a>
                            <a v-if="childTag.is_template == false || profile.canDeleteTags" class="button-tag-delete child" @click="deleteTag(childTag)">
                                <md-icon>close</md-icon>
                            </a>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>

        <span class="no-tag-message" v-show="options && options.length < 1">{{ $t('offer.tags.text.create_first') }}</span>

        <div class="tag-select__reset" v-if="selectedTag">
            <a @click="resetSelection()">{{ $t('offer.tags.button.default') }}</a>
        </div>

        <div class="tag-select__new" v-if="canCreateTags">
            <a class="btn btn-secondary" @click="addNewTag()">{{ $t('offer.tags.button.create')}}</a>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import { mapGetters } from 'vuex'

Vue.use(MdIcon)

export default {
    name: 'TagFilter',
    props: ['options', 'selectedTag', 'canCreateTags', 'canDeleteTags'],

    methods: {
        selectTag(selected) {
            this.$emit('changed', selected)
        },

        deleteTag(selected) {
            this.$emit('delete', selected)
        },

        resetSelection() {
            this.$emit('reset')
        },

        addNewTag() {
            this.$emit('openNewTag')
        }
    },
    computed: {
        ...mapGetters(['profile'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/_button.scss';

.tag-select {
    display: block;
    overflow: hidden;
    position: relative;
    padding: 15px;
    margin: 10px 15px;
    background: $color-gray-bg-2;
    border-radius: 4px;

    &:after {
        content: '';
        width: 100%;
        height: 15px;
        display: block;
        background: linear-gradient(rgba(0, 0, 0, 0), $color-gray-bg-2);
        background: linear-gradient(to bottom, rgba($color-gray-bg-2, 0), $color-gray-bg-2);
        position: absolute;
        left: 0;
        bottom: 65px;
        z-index: 1;
    }

    &--has-selected-tag:after {
        bottom: 82px;
    }

    &__title {
        font-weight: bold;
        position: relative;
        margin-bottom: .5em;
    }

    .no-tag-message {
        display: inline-block;
        font-size: 12px;
        font-style: italic;
        margin-bottom: 5px;
    }

    &__item{
        &:hover{
            .button-tag-delete {
                visibility: visible;
            }

        }
    }

    &__list {
        //max-height: 100px;
        overflow-y: scroll;
        position: relative;
        list-style: none;
        padding-bottom: 10px;

        li {
            display: block;
            width: 100%;
            margin: 5px 0;

            a:not(.button-tag-delete) {
                position: relative;
                max-width: calc(100% - 24px);
                font-size: 13px;

                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    background: #333;
                    display: inline-block;
                    margin-right: 10px;
                }
            }

            .button-tag-delete {
                float: right;
                height: 18px;
                width: 20px;
                text-decoration: none;
                visibility: hidden;

                i {
                    font-size: 16px !important;
                    min-width: 10px;
                    width: 20px;
                }
            }

            &.is-selected {
                a {
                    color: black;
                    font-weight: bold;
                }
            }

        }
    }

    @for $i from 1 through 8 {
        .tag-select__list li a.color-#{$i} {
            &:before {
                background: map-get($tag-colors, 'color-tag-#{$i}');
            }
        }
    }

    &__reset {
        display: block;
        margin: 5px 0 -5px;

        a {
            font-size: 11px;
            font-weight: bold;
        }
    }

    &__new {
        margin-top: 20px;

        .btn {
            display: inline-block;
            line-height: 8px;
            background: $color-white;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
