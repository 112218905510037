import { render, staticRenderFns } from "./ModalColorEdit.vue?vue&type=template&id=1bcf1b06&scoped=true"
import script from "./ModalColorEdit.vue?vue&type=script&lang=js"
export * from "./ModalColorEdit.vue?vue&type=script&lang=js"
import style0 from "./ModalColorEdit.vue?vue&type=style&index=0&id=1bcf1b06&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bcf1b06",
  null
  
)

export default component.exports