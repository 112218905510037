<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>keyboard_arrow_left</md-icon></button>
                <span class="title">{{ $t('project.rename.title') }}</span>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper">
                    <label class="text-input-label" for="project-name-project-rename">{{ $t('project.rename.field.name.label') }}</label>
                    <input
                        @keyup.enter="saveName"
                        v-model="projectName"
                        id="project-name-project-rename"
                        type="text">
                </div>
                <span class="text-info">
                    <md-icon>info</md-icon>
                    {{ $t('project.rename.text.info') }}
                </span>
                <button class="btn btn-primary button-save" type="button" @click="saveName" :disabled="this.projectName.length < 1">{{ $t('project.rename.button.save') }}</button>
                <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('project.rename.button.back') }}</button>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectRename',
    props: ['open', 'name'],

    data() {
        return {
            projectName: ''
        }
    },

    watch: {
        'open': function() {
            this.projectName = this.name
        }
    },

    methods: {
        saveName() {
            if (this.projectName.length > 5) {
                this.$emit('saveName', this.projectName)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            button {
                float: left;
            }
        }

        &-body {
            input {
                width: 100%;
            }

            .text-info {
                display: block;
                margin-top: 5px;
                padding-bottom: 50px;
                border-bottom: 1px solid $color-gray-border;
                margin-bottom: 15px;

                i {
                    position: relative;
                    top: 5px;
                    font-size: 18px !important;
                }
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
