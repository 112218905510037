<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">
                    <template v-if="!isEdit">{{ $t('dealer.manage.title.create', {type: dealer.type}) }}</template>
                    <template v-else-if="dealer.canEdit">{{ $t('dealer.manage.title.edit', {type: dealer.type}) }}</template>
                    <template v-else>{{ $t('dealer.manage.title.show', {type: dealer.type}) }}</template>
                </span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="col-left">
                    <div class="input-wrapper" :class="{'error' : errors.name}">
                        <label class="text-input-label" for="dealer-dealer-add">{{ $t('dealer.manage.field.name.label') }}</label>
                        <input type="text" v-model="dealer.name" :disabled="!dealer.canEdit" id="dealer-dealer-add">
                        <span class="input-error">{{errors.name}}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.email}">
                        <label class="text-input-label" for="email-dealer-add">{{ $t('dealer.manage.field.email.label') }}</label>
                        <input type="email" v-model="dealer.email" :disabled="!dealer.canEdit" id="email-dealer-add">
                        <span class="input-error">{{errors.email}}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.phone}">
                        <label class="text-input-label" for="phone-dealer-add">{{ $t('dealer.manage.field.phone.label') }}</label>
                        <input type="text" v-model="dealer.phone" :disabled="!dealer.canEdit" id="phone-dealer-add">
                        <span class="input-error">{{errors.phone}}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.sapNumber}">
                        <label class="text-input-label" for="sap-dealer-add">{{ $t('dealer.manage.field.sap_number.label') }}</label>
                        <input type="text" v-model="dealer.sapNumber" :disabled="!dealer.canEdit" id="sap-dealer-add">
                        <span class="input-error">{{errors.sapNumber}}</span>
                    </div>
                </div>
                <div class="col-right">
                    <div class="input-wrapper" :class="{'error' : errors.bramacAdvisory}">
                        <label class="text-input-label">{{ $t('dealer.manage.field.bramac_advisory.label') }}</label>
                        <custom-select
                            :modal="true"
                            :selectedItem="dealer.bramacAdvisory"
                            :options="dealer.advisoryUsers"
                            @changed="selectedBramacAdvisory"
                            :disabled="!dealer.canEdit || !dealer.canChangeAdvisory"
                            :cursorNotAllowed="false"
                            :bold="false"
                            :large="true"
                            placeholder="-"
                        ></custom-select>
                        <span class="input-error">{{errors.bramacAdvisory}}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.villasAdvisory}">
                        <label class="text-input-label">{{ $t('dealer.manage.field.villas_advisory.label') }}</label>
                        <custom-select
                            :modal="true"
                            :selectedItem="dealer.villasAdvisory"
                            :options="dealer.advisoryUsers"
                            @changed="selectedVillasAdvisory"
                            :disabled="!dealer.canEdit || !dealer.canChangeAdvisory"
                            :cursorNotAllowed="false"
                            :bold="false"
                            :large="true"
                            placeholder="-"
                        ></custom-select>
                        <span class="input-error">{{errors.villasAdvisory}}</span>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.addressZip}">
                        <label class="text-input-label" for="zipCode-dealer-add">{{ $t('dealer.manage.field.address_zip.label') }}</label>
                        <input
                            ref="addressZip"
                            v-model="dealer.addressZip"
                            @input="inputZip"
                            @focus="checkZip"
                            @blur="emptyCityList"
                            :disabled="!dealer.canEdit"
                            min="1000"
                            max="9999"
                            id="zipCode-dealer-add"
                            v-autocomplete-off
                            v-disable-mouse-wheel
                            type="number">
                        <span class="input-error">{{errors.addressZip}}</span>
                        <ul class="dropdown-list" v-show="cityList.length > 0">
                            <li
                                v-for="(city, index) in cityList"
                                :key="index"
                                @click="writeCityName(city)">
                                {{city}}
                            </li>
                        </ul>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.addressCity}">
                        <label class="text-input-label" for="city-dealer-add">{{ $t('dealer.manage.field.address_city.label') }}</label>
                        <input
                            ref="addressCity"
                            v-model="dealer.addressCity"
                            @input="inputCity"
                            @focus="checkCity"
                            @blur="blurCity"
                            :disabled="!dealer.canEdit"
                            id="city-dealer-add"
                            type="text"
                            v-autocomplete-off>
                        <span class="input-error">{{errors.addressCity}}</span>
                        <ul class="dropdown-list" v-show="zipList.length > 0">
                            <li
                                v-for="(zip, index) in zipList"
                                :key="index"
                                @click="writeZip(zip)">
                                {{zip}}
                            </li>
                        </ul>
                    </div>
                    <div class="input-wrapper" :class="{'error' : errors.address}">
                        <label class="text-input-label" for="street-dealer-add">{{ $t('dealer.manage.field.address.label') }}</label>
                        <input type="text" v-model="dealer.address" :disabled="!dealer.canEdit" id="street-dealer-add" >
                        <span class="input-error">{{errors.address}}</span>
                    </div>

                </div>
                <div class="button-container" v-if="dealer.canEdit">
                    <button v-if="isEdit" class="btn btn-primary button-save" type="button" @click="saveDealer" :disabled="loading">
                        {{ $t('dealer.manage.button.save') }}
                    </button>
                    <button v-else class="btn btn-primary button-save" type="button" @click="addDealer" :disabled="loading">
                        {{ $t('dealer.manage.button.add') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('dealer.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="() => {this.saveDealer(); this.confirmedEmailSending = true; this.openConfirmationModal = false}"
            @close="openConfirmationModal = false"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import ModalConfirmation from '../../components/modals/ModalConfirmation.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalDealerAdd',
    props: ['open', 'dealerId'],

    data() {
        return {
            loading: false,
            confirmedEmailSending: false,
            errors: {},
            dealer: {
                type: '',
                canEdit: false,
                canChangeAdvisory: false,

                name: '',
                email: '',
                phone: '',
                sapNumber: '',

                addressZip: '',
                addressCity: '',
                address: '',

                bramacAdvisory: null,
                villasAdvisory: null,
                advisoryUsers: []
            },
            cityList: [],
            zipList: [],
            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('dealer.manage.confirmation.ok'),
                cancelText: this.$t('dealer.manage.confirmation.cancel'),
                message: this.$t('dealer.manage.confirmation.question')
            }
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.$store.dispatch('FETCH_ZIPCODE_LIST')
                this.$store.dispatch('FETCH_CITY_LIST')

                this.errors = {}
                this.loading = true
                this.confirmedEmailSending = false
                this.$store.dispatch('FETCH_DEALER', this.dealerId).then(dealer => {
                    this.dealer = dealer
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        selectedBramacAdvisory(val) {
            this.dealer.bramacAdvisory = val
        },

        selectedVillasAdvisory(val) {
            this.dealer.villasAdvisory = val
        },

        addDealer() {
            if (this.confirmedEmailSending) {
                this.saveDealer()
            } else {
                this.openConfirmationModal = true
            }
        },

        saveDealer() {
            this.errors = {}

            let payload = {
                id: this.dealerId,
                data: {
                    name: this.dealer.name,
                    email: this.dealer.email,
                    phone: this.dealer.phone,
                    sapNumber: this.dealer.sapNumber,
                    addressZip: this.dealer.addressZip,
                    addressCity: this.dealer.addressCity,
                    address: this.dealer.address,
                    bramacAdvisory: this.dealer.bramacAdvisory ? this.dealer.bramacAdvisory.id : null,
                    villasAdvisory: this.dealer.villasAdvisory ? this.dealer.villasAdvisory.id : null,
                }
            }

            this.$store.dispatch('SEND_DEALER', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },

        inputZip() {
            this.dealer.addressCity = ''
            this.cityList = []
            this.checkZip()
        },

        checkZip() {
            this.$refs.addressZip.removeAttribute('tabindex')

            let zipLength = String(this.dealer.addressZip).length
            if (zipLength > 4) {
                this.dealer.addressZip = Number((String(this.dealer.addressZip)).substring(0, 4))
            }

            if (this.dealer.addressZip !== null && String(this.dealer.addressZip).length === 4) {
                this.setCity()
            } else {
                this.cityList = []
            }
        },

        inputCity() {
            this.dealer.addressZip = ''
            this.zipList = []
            this.checkCity()
        },

        checkCity() {
            this.$refs.addressCity.removeAttribute('tabindex')

            if (this.dealer.addressCity !== null && this.dealer.addressCity.length > 2) {
                this.setZip()
            } else {
                this.zipList = []
            }
        },

        blurCity() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.dealer.addressCity.toLowerCase() && el.name !== this.dealer.addressCity) {
                    this.dealer.addressCity = el.name
                }
            })
            this.emptyZipList()
        },

        setCity() {
            this.zipCodesList.forEach(el => {
                const sameZip = Number(el.zip) === Number(this.dealer.addressZip)
                if (sameZip && el.cities.length === 1) {
                    this.$refs.addressCity.setAttribute('tabindex', '-1')
                    this.dealer.addressCity = el.cities[0]
                } else if (sameZip && el.cities.length > 1) {
                    this.cityList = el.cities
                }
            })
        },

        setZip() {
            this.citiesList.forEach(el => {
                if (el.name.toLowerCase() === this.dealer.addressCity.toLowerCase()) {
                    if (el.zips.length === 1) {
                        this.$refs.addressZip.setAttribute('tabindex', '-1')
                        this.dealer.addressZip = el.zips[0]
                    } else {
                        this.zipList = el.zips
                    }
                }
            })
        },

        writeCityName(name) {
            this.$refs.addressCity.setAttribute('tabindex', '-1')
            this.dealer.addressCity = name
            this.emptyZipList()
        },

        writeZip(zip) {
            this.$refs.addressZip.setAttribute('tabindex', '-1')
            this.dealer.addressZip = zip
            this.emptyCityList()
        },

        emptyCityList() {
            let that = this
            setTimeout(function(){
                if (that.cityList.length > 0) {
                    that.cityList = []
                }
            }, 250)
        },

        emptyZipList() {
            let that = this
            setTimeout(function(){
                if (that.zipList.length > 0) {
                    that.zipList = []
                }
            }, 250)
        },
    },

    computed: {
        ...mapGetters(['zipCodesList', 'citiesList']),

        isEdit() {
            return this.dealerId !== null;
        }
    },

    components: { CustomSelect, ModalConfirmation, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }


        &-body {

            input {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                .dropdown-list {
                    top: 76px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
