<template>
    <div class="multi-menu-component">
        <ul class="multi-menu-list">
            <li v-for="(element, elementIndex) in elements" :key="elementIndex" v-if="element.length">
                <ul>
                    <li
                        v-for="(elem, elemIndex) in element"
                        :key="elemIndex"
                        @click="handleAction(elem.handle)"
                    >
                        {{ elem.handleName }}
                        <md-icon v-if="elem.subElements" class="icon-next">chevron_right</md-icon>
                        <ul v-if="elem.subElements">
                            <li
                                v-for="(sel, selIndex) in elem.subElements"
                                :key="selIndex"
                                @click="handleAction(sel.handle)"
                            >{{ sel.handleName }}</li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>

        <div class="multi-menu-layer" @click="close"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'MultiMenu',
    props: ['elements'],

    data() {
        return {}
    },

    methods: {
        close() {
            this.$emit('close')
        },

        handleAction(action) {
            if (action) {
                this.$emit('selected', action)
                this.$emit('close')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';

.multi-menu-component {
    .multi-menu-list {
        position: absolute;
        top: 61px;
        z-index: 55;
        width: 230px;
        border: 1px solid $color-gray-border;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        ul,
        li {
            list-style-type: none;
            cursor: pointer;
            background: $color-white;
        }

        li {
            font-size: 13px;
            line-height: 21px;
        }

        & > li {
            // Level 0 - for grouping
            border-bottom: 1px solid $color-gray-border;

            &:last-child {
                & > ul,
                & > ul > li {
                    background: $color-gray-bg-1;
                }
            }

            & > ul {
                // Level 1
                padding: 12px 0;

                & > li {
                    position: relative;
                    padding: 6px 16px;

                    &:hover {
                        text-decoration: underline;

                        & > ul {
                            display: block;
                        }
                    }

                    .icon-next {
                        position: absolute;
                        top: 7px;
                        right: 5px;
                        text-decoration: none;
                    }

                    & > ul {
                        // Level 2
                        display: none;
                        position: absolute;
                        top: 0;
                        left: calc(100% - 2px);
                        border: 1px solid $color-gray-border;
                        padding: 4px 0;
                        z-index: 56;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                        & > li {
                            line-height: 19px;
                            padding: 6px 16px;
                            white-space: nowrap;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .multi-menu-layer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        z-index: 50;
    }
}
</style>
