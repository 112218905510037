<template>
    <div class="modal is-scrollable" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain">{{ $t('order.shipping_info.button.close') }}</button>
            </div>

            <div class="modal-box-body">
                <div>
                    <table v-if="rows.length > 0">
                        <tr>
                            <th class="name">{{ $t('order.shipping_info.column.name') }}</th>
                            <th class="deadline">{{ $t('order.shipping_info.column.deadline') }}</th>
                            <th class="price">Egységár, ÁFA nélkül</th>
                            <th class="price">Egységár, ÁFÁ-val</th>
                            <th class="comment">{{ $t('order.shipping_info.column.comment') }}</th>
                        </tr>
                        <tr v-for="(row, index) in rows" :key="index">
                            <td v-if="row.name" class="name" :rowspan="row.name.merge">{{ row.name.text }}</td>
                            <td v-if="row.deadline" class="deadline" :rowspan="row.deadline.merge">{{ row.deadline.text }}</td>
                            <td v-if="row.price" class="price" :rowspan="row.price.merge">{{ row.price.text }}</td>
                            <td v-if="row.price_gross" class="price" :rowspan="row.price_gross.merge">{{ row.price_gross.text }}</td>
                            <td v-if="row.comment" class="comment" :rowspan="row.comment.merge">{{ row.comment.text }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {MdIcon} from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalTransportInfo',
    props: ['open', 'info', 'title'],
    methods: {
        normalizeInfoData(data) {
            return data.map(n => {
                if (typeof n === 'string') {
                    return {merge: 1, text: n}
                }

                return n
            })
        }
    },
    computed: {
        rows() {
            let rows = []

            if (this.info) {
                let names = this.normalizeInfoData(this.info.names)
                let deadlines = this.normalizeInfoData(this.info.deadlines)
                let prices = this.normalizeInfoData(this.info.prices)
                let prices_gross = this.normalizeInfoData(this.info.prices_gross)
                let comments = this.normalizeInfoData(this.info.comments)
                let numberOfRows = names.reduce((n, d) => n + d.merge, 0)

                for (let i = 0; i < numberOfRows; i++) {
                    rows.push({
                        name: names[i],
                        deadline: deadlines[i],
                        price: prices[i],
                        price_gross: prices_gross[i],
                        comment: comments[i],
                    })
                }
            }

            return rows
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';
@import '../../assets/sass/partials/contacts.scss';

.modal {
    &-box {
        width: 1200px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow-y: visible;

            table {
                margin: 16px 0;

                tr {
                    td, th {
                        border: 1px solid #535353;
                        padding: 5px;
                    }

                    td {
                        vertical-align: middle;
                    }

                    th.name {
                        width: 20%;
                    }

                    th.deadline {
                        width: 20%;
                    }

                    th.price {
                        width: 18%;
                    }

                    th.comment {
                        width: 22%;
                    }

                    td.price {
                        font-weight: bold;
                        white-space: nowrap;
                        text-align: center;
                    }
                }
            }
        }
    }

    &.is-scrollable {
        .modal-box-body {
            overflow-y: scroll;
            //min-height: 420px;
        }
    }
}

</style>
