import Vue from 'vue'

import {
    getProductsWithValue,
    getTotalPrices,
    palletsSum,
    loadTab,
    simpleLoadTab,
    getClipboardData
} from '@/plugins/helpers'
import {defaultAdditionalDetails, defaultDiscounts, defaultOfferInformation, defaultOrderData} from './index'

import i18n from '../i18n'

export default {
    storeUserEmail(state, email) {
        Vue.set(state, 'authEmail', email)
    },

    storeUserRemember(state, remember) {
        Vue.set(state, 'authRemember', remember)
    },

    storeUserToken(state, token) {
        Vue.set(state, 'authToken', token)
    },

    storeUserSession(state, session) {
        Vue.set(state, 'authSession', session)
    },

    storePriceList(state, priceList) {
        Vue.set(state, 'priceList', priceList)
    },

    storeUserProfile(state, profile) {
        Vue.set(state, 'userFetched', true)
        Vue.set(state, 'profile', profile)
    },

    clearUser(state) {
        Vue.set(state, 'userFetched', true)
        Vue.set(state, 'authToken', null)
        Vue.set(state, 'authSession', false)
        Vue.set(state, 'profile', {})
    },

    addValidation(state, payload) {
        Vue.set(state.validation, 'message', payload[0])
        Vue.set(state.validation, 'fields', payload[1])
    },

    hideValidation(state) {
        Vue.set(state, 'validation', {
            message: null,
            fields: {}
        })
    },

    resetOffer(state) {
        state.orderDataTabs = []
        state.selectedOrderDataTab = null
        state.projectData = {}
        state.originalOffer = {}
        state.orderData = JSON.parse(JSON.stringify(defaultOrderData))
        state.offerInformation = JSON.parse(JSON.stringify(defaultOfferInformation))
        state.offerActions = []
        state.displayNetPrice = true
        state.permissions = {}
        state.viewOnly = false
        state.canTag = false
        state.canEditSales = false
        state.selectedTagsCount = null
        state.productText = {}
        state.productTextDebug = null
        state.planCalculation = null
        state.discounts = JSON.parse(JSON.stringify(defaultDiscounts))
        state.pallets = []
        state.customPallets = false
        state.customDemolitionPallets = 0
        state.customTransport = 0
        state.dealerTransport = 0
        state.customDealerTransport = false
        state.services = []
        state.transports = []
        state.selectedTransport = null
        state.warehouse = false
        state.additionalDetails = JSON.parse(JSON.stringify(defaultAdditionalDetails))
    },

    resetOrder(state) {
        state.orderDataTabs = []
        state.selectedOrderDataTab = null
        state.projectData = {}
        state.orderData = JSON.parse(JSON.stringify(defaultOrderData))
        state.offerInformation = JSON.parse(JSON.stringify(defaultOfferInformation))
        state.permissions = {}
        state.viewOnly = false
        state.discounts = JSON.parse(JSON.stringify(defaultDiscounts))
    },

    setOrders(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.orders.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'orders', {
            groups: response.data.groups.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('order.page.list.default_filter.groups')
            }, ...response.data.groups],
            dealers: response.data.dealers.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('order.page.list.default_filter.dealers')
            }, ...response.data.dealers],
            advisories: response.data.advisories.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('order.page.list.default_filter.advisories')
            }, ...response.data.advisories],
            filters: response.data.filters,
            meta: response.meta,
            pages
        })
    },

    removeOrderListPage(state, removePage) {
        let pages = {}

        Object.keys(state.orders.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.orders.pages[page]}
            }
        })

        Vue.set(state, 'orders', {
            ...state.orders,
            pages
        })
    },

    setOrderListActiveFilters(state, payload) {
        Vue.set(state, 'orderListActiveFilters', payload)
    },

    setOrderListScrollPosition(state, payload) {
        Vue.set(state, 'orderListScrollPosition', payload)
    },

    resetOrders(state) {
        Vue.set(state, 'orders', {})
        Vue.set(state, 'orderListActiveFilters', {})
        Vue.set(state, 'orderListScrollPosition', 0)
    },

    setOrderDataTabs(state, payload) {
        Vue.set(state, 'orderDataTabs', payload)
    },

    addToOrderDataTabs(state, payload) {
        state.orderDataTabs.push(payload)
        this.commit('setSelectedOrderDataTab', state.orderDataTabs.length - 1)
    },

    removeFromOrderDataTabs(state) {
        if (state.orderDataTabs.length > 1) {
            state.orderDataTabs.splice(state.selectedOrderDataTab, 1)
            this.commit('setSelectedOrderDataTab', 0)
            loadTab(this.commit, state.orderDataTabs[0], false)
        }
    },

    changeActiveOrderDataTab(state, payload) {
        this.commit('updateOrderDataTabs')
        this.commit('setSelectedOrderDataTab', payload)
        simpleLoadTab(this.commit, state.orderDataTabs[payload])
    },

    updateOrderDataTabs(state) {
        if (state.selectedOrderDataTab !== null) {
            let updatedDataTab = state.orderDataTabs[state.selectedOrderDataTab]

            updatedDataTab.activeProductTypes = state.orderData.activeProductTypes
            updatedDataTab.categories = state.orderData.categories
            updatedDataTab.differentColors = state.orderData.differentColors
            updatedDataTab.restricted = state.orderData.restricted
            updatedDataTab.groupType = state.orderData.selectedGroupType
            updatedDataTab.groupTypes = state.orderData.groupTypes
            updatedDataTab.hasColor = state.orderData.hasColor
            updatedDataTab.information = state.orderData.basicInformation
            updatedDataTab.multiLevel = state.orderData.multiLevel
            updatedDataTab.variant = state.orderData.selectedVariant
            updatedDataTab.variantType = state.orderData.variantType
            updatedDataTab.variants = state.orderData.variants
            updatedDataTab.title = state.orderData.categoryTitle

            Vue.set(state.orderDataTabs, state.selectedOrderDataTab, updatedDataTab)
        }
    },

    setSelectedOrderDataTab(state, payload) {
        state.selectedOrderDataTab = payload
    },

    setOffers(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.offers.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'offers', {
            groups: response.data.groups.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('offer.page.list.default_filter.groups')
            }, ...response.data.groups],
            filters: response.data.filters,
            project_count: response.data.project_count,
            meta: response.meta,
            pages
        })
    },

    setProjects(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })


        if (keepOtherPages) {
            pages = {
                ...state.projects.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'projects', {
            filters: response.data.filters,
            meta: response.meta,
            pages
        })
    },

    setProjectListActiveFilters(state, payload) {
        Vue.set(state, 'projectListActiveFilters', payload)
    },

    removeOfferListPage(state, removePage) {
        let pages = {}

        Object.keys(state.offers.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.offers.pages[page]}
            }
        })

        Vue.set(state, 'offers', {
            ...state.offers,
            pages
        })
    },

    setOfferListActiveFilters(state, payload) {
        Vue.set(state, 'offerListActiveFilters', payload)
    },

    setOfferListScrollPosition(state, payload) {
        Vue.set(state, 'offerListScrollPosition', payload)
    },

    resetOffers(state) {
        Vue.set(state, 'offers', {})
        Vue.set(state, 'offerListActiveFilters', {})
        Vue.set(state, 'offerListScrollPosition', 0)
    },

    refreshOffer(state, payload) {
        state.offers.rows.map((offer, idx) => {
            if (offer.id === payload[0]) {
                state.offers.rows[idx] = payload[1]
            }
        })
    },

    setTags(state, payload) {
        Vue.set(state, 'tags', payload)
    },

    setStalledOffers(state, payload) {
        Vue.set(state, 'stalledOffers', payload)
    },


    setSelectedTagsCount(state, payload) {
        state.selectedTagsCount = payload
    },

    refreshTags(state, payload) {
        Vue.set(state, 'tags', payload)
    },

    setObjects(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.objects.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'objects', {
            filters: response.data.filters,
            meta: response.meta,
            pages
        })
    },

    removeObjectListPage(state, removePage) {
        let pages = {}

        Object.keys(state.objects.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.objects.pages[page]}
            }
        })

        Vue.set(state, 'objects', {
            ...state.objects,
            pages
        })
    },

    setObjectListActiveFilters(state, payload) {
        Vue.set(state, 'objectListActiveFilters', payload)
    },

    setObjectListScrollPosition(state, payload) {
        Vue.set(state, 'objectListScrollPosition', payload)
    },

    resetObjects(state) {
        Vue.set(state, 'objects', {})
        Vue.set(state, 'objectListActiveFilters', {})
        Vue.set(state, 'objectListScrollPosition', 0)
    },

    setOfferList(state, payload) {
        state.offerList = payload
    },

    setObjectData(state, payload) {
        if (payload.classification === null) {
            payload.classification = {
                id: null,
                name: ''
            }
        }

        Vue.set(state, 'objectData', payload)
    },

    setTitle(state, payload) {
        state.orderData.title = payload
    },

    setGroupTypes(state, payload) {
        state.orderData.groupTypes = payload
    },

    setSelectedGroupType(state, payload) {
        state.orderData.selectedGroupType = payload
    },

    setVariants(state, payload) {
        state.orderData.variants = payload
    },

    setVariantType(state, payload) {
        state.orderData.variantType = payload
    },

    setSelectedVariant(state, payload) {
        state.orderData.selectedVariant = payload
    },

    setCalculation(state, payload) {
        state.orderData.calculation = payload
    },

    setPlanCalculation(state, payload) {
        state.planCalculation = payload
    },

    setProjectData(state, payload) {
        state.projectData = payload
    },

    setUserSettings(state, payload) {
        state.userSettings = payload
    },

    updateDifferentColors(state, payload) {
        if (!payload) {
            let newCategories = state.orderData.categories

            newCategories.forEach((element, index) => {
                element.products.forEach((el, ind) => {
                    if (el.mainColor === false && !el.forceShow) {
                        let product = newCategories[index].products[ind]

                        product.amount = 0

                        if (product.linkedProducts) {
                            for (let productId in product.linkedProducts) {
                                if (product.linkedProducts.hasOwnProperty(productId)) {
                                    let linkedProduct = null

                                    newCategories.every(category => {
                                        category.products.every(product => {
                                            if (product.id === Number(productId)) {
                                                linkedProduct = product
                                                return false
                                            }
                                            return true
                                        })
                                        return !linkedProduct
                                    })

                                    if (linkedProduct) {
                                        linkedProduct.amount = 0
                                    }
                                }
                            }
                        }
                    }
                })
            })

            state.orderData.categories = newCategories
            state.orderData.activeProductTypes = []

            if (state.orderDataTabs.length < 1) {
                this.commit('calculatePalletsData')
                this.commit('calculateTransportsData')
            }
        }

        state.orderData.differentColors = payload
    },

    updateRestricted(state, payload) {
        state.orderData.restricted = payload
    },

    setMultiLevel(state, payload) {
        state.orderData.multiLevel = payload
    },

    setRecommendedProducts(state, payload) {
        state.orderData.recommendedProducts = payload
    },

    setCategories(state, payload) {
        state.orderData.categories = payload
    },

    updateProductAmount(state, payload) {
        let newCategories = state.orderData.categories

        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.id === payload.id) {
                    newCategories[index].products[ind].amount = payload.value
                    newCategories[index].products[ind].amountUnit = payload.unit
                }
            })
        })

        state.orderData.categories = newCategories

        if (state.orderData.recommendedProducts.length > 0) {
            this.commit('specifyRecommendedProduct')
        }

        if (state.orderDataTabs.length < 1) {
            this.commit('calculatePalletsData')
            this.commit('calculateTransportsData')
        }
    },

    updateSelectableProductSelected(state, {type, id}) {
        let newCategories = [...state.orderData.categories]
        let oldProduct = null
        let selected = null
        let otherSelectableProducts = {}
        let notSelected = []

        let updateProduct = (newCategories, index, ind, oldProduct) => {
            newCategories[index].products[ind].isSelected = true
            newCategories[index].products[ind].amount = oldProduct.amount
            newCategories[index].products[ind].amountUnit = newCategories[index].products[ind].units.filter(u => u.id === oldProduct.amountUnit.id)[0]
            newCategories[index].products[ind].dealerDiscount = oldProduct.dealerDiscount
            newCategories[index].products[ind].buyerDiscount = oldProduct.buyerDiscount
            newCategories[index].products[ind].salesDiscount = oldProduct.salesDiscount
        }

        newCategories.forEach((category, index) => {
            category.products.forEach((product, ind) => {
                if (product.isSelectable) {
                    if (product.type === type) {
                        if (product.isSelected) {
                            oldProduct = {...product}
                            newCategories[index].products[ind].amount = 0
                        }

                        if (product.id === id) {
                            selected = [index, ind]
                        }

                        notSelected.push([index, ind])
                    } else {
                        if (!otherSelectableProducts.hasOwnProperty(product.type)) {
                            otherSelectableProducts[product.type] = {}
                        }
                        otherSelectableProducts[product.type][product.productId] = {index, ind, product}
                    }
                }
            })
        })

        if (!selected) {
            return
        }

        let [index, ind] = selected
        let swap = false

        const newProduct = newCategories[index].products[ind]

        for (let otherType in otherSelectableProducts) {
            if (
                otherSelectableProducts.hasOwnProperty(otherType) &&
                otherSelectableProducts[otherType].hasOwnProperty(newProduct.productId) &&
                otherSelectableProducts[otherType][newProduct.productId].product.isSelected
            ) {
                swap = [
                    otherType,
                    otherSelectableProducts[otherType][oldProduct.productId].index,
                    otherSelectableProducts[otherType][oldProduct.productId].ind,
                    {...oldProduct},
                ]
                oldProduct = otherSelectableProducts[otherType][newProduct.productId].product
                break
            }
        }

        notSelected.forEach(([index, ind]) => {
            newCategories[index].products[ind].isSelected = false
            newCategories[index].products[ind].amount = 0
        })

        updateProduct(newCategories, index, ind, oldProduct)

        if (swap) {
            let [swapType, swapIndex, swapInd, swapProduct] = swap

            Object.values(otherSelectableProducts[swapType]).forEach(({index, ind}) => {
                newCategories[index].products[ind].isSelected = false
                newCategories[index].products[ind].amount = 0
            })

            updateProduct(newCategories, swapIndex, swapInd, swapProduct)
        }

        state.orderData.categories = newCategories

        if (state.orderData.recommendedProducts.length > 0) {
            this.commit('specifyRecommendedProduct')
        }

        if (state.orderDataTabs.length < 1) {
            this.commit('calculatePalletsData')
            this.commit('calculateTransportsData')
        }
    },

    updateCustomListPrice(state, payload) {
        let newCategories = state.orderData.categories

        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.id === payload.id) {
                    newCategories[index].products[ind].listPrice.net = payload.value
                }
            })
        })

        state.orderData.categories = newCategories
    },

    updateProductBuyerDiscount(state, payload) {
        let newCategories = state.orderData.categories

        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.id === payload.id) {
                    if (payload.subDiscountId) {
                        newCategories[index].products[ind].buyerDiscount[payload.subDiscountId] = payload.value
                    } else {
                        newCategories[index].products[ind].buyerDiscount = payload.value
                    }
                }
            })
        })

        state.orderData.categories = newCategories
    },

    updateProductDealerDiscount(state, payload) {
        let newCategories = state.orderData.categories

        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.id === payload.id) {
                    if (payload.subDiscountId) {
                        newCategories[index].products[ind].dealerDiscount[payload.subDiscountId] = payload.value
                    } else {
                        newCategories[index].products[ind].dealerDiscount = payload.value
                    }
                }
            })
        })

        state.orderData.categories = newCategories
    },

    updateProductSalesDiscount(state, payload) {
        let newCategories = state.orderData.categories

        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.id === payload.id) {
                    if(payload.subDiscountId) {
                        newCategories[index].products[ind].salesDiscount[payload.subDiscountId] = payload.value
                    } else {
                        newCategories[index].products[ind].salesDiscount = payload.value
                    }
                }
            })
        })

        state.orderData.categories = newCategories
    },

    calculatePalletsData(state) {
        if (!state.customPallets) {
            let palletsAmounts = {}
            state.pallets.forEach((element) => {
                Vue.set(palletsAmounts, element.id, 0)
            })

            if (state.orderDataTabs.length < 1) {
                state.orderData.categories.forEach((element) => {
                    element.products.forEach((el) => {
                        let amount
                        if (el.amount > 0 && el.pallet !== null) {
                            amount = el.amount * el.amountUnit.multiplier
                            if (el.pallet.calculateAbove === null || amount >= el.pallet.calculateAbove) {
                                if (el.pallet.needRound) {
                                    palletsAmounts[el.pallet.type] += Math.ceil(amount / el.pallet.divider)
                                } else {
                                    palletsAmounts[el.pallet.type] += amount / el.pallet.divider
                                }
                            }
                        }
                    })
                })

                state.pallets.forEach((element, index) => {
                    Vue.set(state.pallets[index], 'amount', Math.ceil(palletsAmounts[element.id]))
                    Vue.set(state.pallets[index], 'amountNoRounded', palletsAmounts[element.id])
                })
            }
        }

        if (state.orderDataTabs.length < 1) {
            this.commit('calculateDemolitionFee')
            this.commit('calculateServicePrice')
        }
    },

    calculateDemolitionFee(state) {
        if (!state.customDemolitionPallets) {
            let sum = 0
            let serviceIndex = null
            let targetPallets = []
            let bramacTetocserep = false

            state.services.forEach((element, index) => {
                // Raklap bontási díj for bramac tetocserep
                if (element.id === 7) {
                    bramacTetocserep = true
                }
                if (element.calcDemolition) {
                    serviceIndex = index
                    targetPallets = element.pallets
                }
            })

            if (serviceIndex !== null && state.orderDataTabs.length < 1) {
                state.orderData.categories.forEach((element) => {
                    element.products.forEach((el) => {
                        let amount

                        // only for bramac tetocserep group -
                        if (bramacTetocserep === true) {

                            // calcDemolition only for 1/1 Alapcserep
                            // Only discounts: 1 -> Alapcserep
                            if (el.listPrice && el.listPrice.discounts.length && el.listPrice.discounts.includes(1)) {
                                if (el.amount > 0 && el.pallet !== null && targetPallets.includes(el.pallet.type)) {
                                    amount = el.amount * el.amountUnit.multiplier
                                    if ((el.pallet.calculateAbove === null || amount >= el.pallet.calculateAbove) && (amount / el.pallet.divider) % 1 !== 0) {
                                        sum++
                                    }
                                }
                            }

                            // Other discount: return
                            return;
                        }

                        if (el.amount > 0 && el.pallet !== null && !el.pallet.needRound && targetPallets.includes(el.pallet.type)) {
                            amount = el.amount * el.amountUnit.multiplier
                            if ((el.pallet.calculateAbove === null || amount >= el.pallet.calculateAbove) && (amount / el.pallet.divider) % 1 !== 0) {
                                sum++
                            }
                        }
                    })
                })

                Vue.set(state.services[serviceIndex], 'amount', sum)
            }
        }
    },

    // Offer
    calculateTransportsData(state) {
        if (state.transports.length > 0) {
            let listTransportPrices = []
            let dealerTransportPrices = []
            let buyerTransportPrices = []
            let salesTransportPrices = []
            let selectedProducts = getProductsWithValue(state.orderData.categories)
            let totalPrices = getTotalPrices(state.orderData.categories, state.discounts.discountType)

            state.transports.forEach((transport) => {
                let listSum = 0
                let dealerSum = 0
                let buyerSum = 0
                let salesSum = 0
                let isNull = false
                let disabledTransport = true
                let weightSum
                let allProductValid
                let hasValidProduct

                if (totalPrices.listPriceNet > 0) {
                    if (transport.price !== null) {
                        disabledTransport = false
                        listSum += transport.price
                        dealerSum += transport.price
                        buyerSum += transport.price
                        salesSum += transport.price
                    } else {
                        allProductValid = true
                        hasValidProduct = false

                        selectedProducts.forEach((product) => {
                            let containsAnyPrice = false

                            transport.prices.forEach((price) => {
                                if (!containsAnyPrice && price.products.indexOf(product.id) > -1) {
                                    containsAnyPrice = true
                                }
                            })

                            allProductValid = allProductValid && containsAnyPrice
                            hasValidProduct = hasValidProduct || containsAnyPrice
                        })

                        transport.prices.forEach((price) => {
                            switch (price.calculateBy) {
                            case 'amount':
                                if (allProductValid) {
                                    let amountSum = 0

                                    selectedProducts.forEach((product) => {
                                        if (price.products.indexOf(product.id) > -1) {
                                            let productIndex = price.products.indexOf(product.id)
                                            amountSum += Math.ceil(product.amount * product.unitMultiplier / price.dividers[productIndex])
                                        }
                                    })

                                    if (amountSum > 0) {
                                        disabledTransport = false

                                        listSum += amountSum * price.net
                                        dealerSum += amountSum * price.net
                                        buyerSum += amountSum * price.net
                                        salesSum += amountSum * price.net
                                    }
                                }

                                break

                            case 'weight':
                                if (allProductValid) {
                                    weightSum = null

                                    let rangePrice = null
                                    selectedProducts.forEach((product) => {
                                        if (price.products.indexOf(product.id) > -1) {
                                            if (weightSum === null) {
                                                weightSum = 0
                                            }
                                            weightSum += product.amount * product.unitMultiplier * product.weight
                                        }
                                    })

                                    if (weightSum !== null) {
                                        disabledTransport = false

                                        price.ranges.forEach((range) => {
                                            if ((range.from === null || range.from <= weightSum) && (range.to === null || range.to > weightSum)) {
                                                rangePrice = range.price
                                            }
                                        })

                                        if (rangePrice === null) {
                                            isNull = true
                                        } else {
                                            listSum += rangePrice
                                            dealerSum += rangePrice
                                            buyerSum += rangePrice
                                            salesSum += rangePrice
                                        }
                                    }
                                }

                                break

                            case 'pallet':
                                if (palletsSum(state.pallets, 'amount') < price.rangeTo) {
                                    listSum += price.net
                                    dealerSum += price.net
                                    buyerSum += price.net
                                    salesSum += price.net
                                } else {
                                    listSum += 0
                                    dealerSum += 0
                                    buyerSum += 0
                                    salesSum += 0
                                }
                                disabledTransport = false
                                break

                            case 'pallet_without_round':
                                if (palletsSum(state.pallets, 'amountNoRounded') < price.rangeTo) {
                                    listSum += price.net
                                    dealerSum += price.net
                                    buyerSum += price.net
                                    salesSum += price.net
                                } else {
                                    listSum += 0
                                    dealerSum += 0
                                    buyerSum += 0
                                    salesSum += 0
                                }
                                disabledTransport = false
                                break

                            case 'pallet_sum':
                                if (hasValidProduct) {
                                    disabledTransport = false

                                    listSum += palletsSum(state.pallets, 'amount') * price.net
                                    dealerSum += palletsSum(state.pallets, 'amount') * price.net
                                    buyerSum += palletsSum(state.pallets, 'amount') * price.net
                                    salesSum += palletsSum(state.pallets, 'amount') * price.net
                                }
                                break

                            case 'mini_pallet':
                                if (allProductValid) {
                                    weightSum = 0

                                    selectedProducts.forEach((product) => {
                                        if (price.products.indexOf(product.id) > -1) {
                                            weightSum += product.amount * product.unitMultiplier * product.weight
                                        }
                                    })

                                    if (weightSum > 0 && weightSum <= price.maxWeight) {
                                        disabledTransport = false

                                        listSum += price.net
                                        dealerSum += price.net
                                        buyerSum += price.net
                                        salesSum += price.net
                                    }
                                }

                                break

                            case 'product_net_discounted_sum':
                                if (totalPrices.listPriceNet < price.rangeTo) {
                                    listSum += price.net
                                } else {
                                    listSum += 0
                                }

                                if (totalPrices.dealerPriceNet < price.rangeTo) {
                                    dealerSum += price.net
                                } else {
                                    dealerSum += 0
                                }

                                if (totalPrices.buyerPriceNet < price.rangeTo) {
                                    buyerSum += price.net
                                } else {
                                    buyerSum += 0
                                }

                                if (totalPrices.salesPriceNet < price.rangeTo) {
                                    salesSum += price.net
                                } else {
                                    salesSum += 0
                                }

                                disabledTransport = false
                                break

                            case 'pallet_or_product_net_discounted_sum':
                                if (
                                    palletsSum(state.pallets, 'amount') >= price.rangeTo ||
                                    totalPrices.listPriceNet >= price.rangeOrTo ||
                                    totalPrices.dealerPriceNet >= price.rangeOrTo ||
                                    totalPrices.buyerPriceNet >= price.rangeOrTo ||
                                    totalPrices.salesPriceNet >= price.rangeOrTo
                                ) {
                                    listSum += 0
                                    dealerSum += 0
                                    buyerSum += 0
                                    salesSum += 0
                                } else {
                                    listSum += price.net
                                    dealerSum += price.net
                                    buyerSum += price.net
                                    salesSum += price.net
                                }

                                disabledTransport = false
                                break
                            }
                        })
                    }
                }

                if (isNull) {
                    listTransportPrices.push({id: transport.id, amount: null})
                    dealerTransportPrices.push({id: transport.id, amount: null})
                    buyerTransportPrices.push({id: transport.id, amount: null})
                    salesTransportPrices.push({id: transport.id, amount: null})
                } else if (disabledTransport) {
                    listTransportPrices.push({id: transport.id, amount: false})
                    dealerTransportPrices.push({id: transport.id, amount: false})
                    buyerTransportPrices.push({id: transport.id, amount: false})
                    salesTransportPrices.push({id: transport.id, amount: false})
                } else {
                    listTransportPrices.push({id: transport.id, amount: listSum})
                    dealerTransportPrices.push({id: transport.id, amount: dealerSum})
                    buyerTransportPrices.push({id: transport.id, amount: buyerSum})
                    salesTransportPrices.push({id: transport.id, amount: salesSum})
                }
            })

            let newTransportPrices = {
                list: listTransportPrices,
                dealer: dealerTransportPrices,
                buyer: buyerTransportPrices,
                sales: salesTransportPrices
            }

            state.transportPrices = newTransportPrices

            if (newTransportPrices.list.length) {
                let selectedTransport = newTransportPrices.list.filter(p => p.id === state.selectedTransport)[0]

                if (!selectedTransport || selectedTransport.amount === false) {
                    let firstAvailableTransport = newTransportPrices.list.filter(p => p.amount !== false)[0]

                    state.selectedTransport = firstAvailableTransport ? firstAvailableTransport.id : null
                }
            }
        }
    },

    // Order
    calculateTransportsDataOrder(state) {
        // state.activeOrderTab = groupID
        if (state.groupTransports[state.activeOrderTab].length > 0) {

            state.orderDataTabs.forEach((tab, i) => {

                let listTransportPrices = []
                let dealerTransportPrices = []
                let buyerTransportPrices = []
                let salesTransportPrices = []
                let selectedProducts = getProductsWithValue(tab.categories)
                let totalPrices = getTotalPrices(tab.categories, state.discounts.discountType)

                state.groupTransports[tab.groupId].forEach((transport) => {
                    let listSum = 0
                    let dealerSum = 0
                    let buyerSum = 0
                    let salesSum = 0
                    let isNull = false
                    let disabledTransport = true
                    let weightSum
                    let allProductValid
                    let hasValidProduct

                    if (totalPrices.listPriceNet > 0) {
                        if (transport.price !== null) {
                            disabledTransport = false
                            listSum += transport.price
                            dealerSum += transport.price
                            buyerSum += transport.price
                            salesSum += transport.price
                        } else {
                            allProductValid = true
                            hasValidProduct = false

                            selectedProducts.forEach((product) => {
                                let containsAnyPrice = false

                                transport.prices.forEach((price) => {
                                    if (!containsAnyPrice && price.products.indexOf(product.id) > -1) {
                                        containsAnyPrice = true
                                    }
                                })

                                allProductValid = allProductValid && containsAnyPrice
                                hasValidProduct = hasValidProduct || containsAnyPrice
                            })

                            transport.prices.forEach((price) => {
                                switch (price.calculateBy) {
                                case 'amount':
                                    if (allProductValid) {
                                        let amountSum = 0

                                        selectedProducts.forEach((product) => {
                                            if (price.products.indexOf(product.id) > -1) {
                                                let productIndex = price.products.indexOf(product.id)
                                                amountSum += Math.ceil(product.amount * product.unitMultiplier / price.dividers[productIndex])
                                            }
                                        })

                                        if (amountSum > 0) {
                                            disabledTransport = false

                                            listSum += amountSum * price.net
                                            dealerSum += amountSum * price.net
                                            buyerSum += amountSum * price.net
                                            salesSum += amountSum * price.net
                                        }
                                    }

                                    break

                                case 'weight':
                                    if (allProductValid) {
                                        weightSum = null

                                        let rangePrice = null
                                        selectedProducts.forEach((product) => {
                                            if (price.products.indexOf(product.id) > -1) {
                                                if (weightSum === null) {
                                                    weightSum = 0
                                                }
                                                weightSum += product.amount * product.unitMultiplier * product.weight
                                            }
                                        })

                                        if (weightSum !== null) {
                                            disabledTransport = false

                                            price.ranges.forEach((range) => {
                                                if ((range.from === null || range.from <= weightSum) && (range.to === null || range.to > weightSum)) {
                                                    rangePrice = range.price
                                                }
                                            })

                                            if (rangePrice === null) {
                                                isNull = true
                                            } else {
                                                listSum += rangePrice
                                                dealerSum += rangePrice
                                                buyerSum += rangePrice
                                                salesSum += rangePrice
                                            }
                                        }
                                    }

                                    break

                                case 'pallet':
                                    if (palletsSum(state.pallets, 'amount') < price.rangeTo) {
                                        listSum += price.net
                                        dealerSum += price.net
                                        buyerSum += price.net
                                        salesSum += price.net
                                    } else {
                                        listSum += 0
                                        dealerSum += 0
                                        buyerSum += 0
                                        salesSum += 0
                                    }
                                    disabledTransport = false
                                    break

                                case 'pallet_without_round':
                                    if (palletsSum(state.pallets, 'amountNoRounded') < price.rangeTo) {
                                        listSum += price.net
                                        dealerSum += price.net
                                        buyerSum += price.net
                                        salesSum += price.net
                                    } else {
                                        listSum += 0
                                        dealerSum += 0
                                        buyerSum += 0
                                        salesSum += 0
                                    }
                                    disabledTransport = false
                                    break

                                case 'pallet_sum':
                                    if (hasValidProduct) {
                                        disabledTransport = false

                                        listSum += palletsSum(state.pallets, 'amount') * price.net
                                        dealerSum += palletsSum(state.pallets, 'amount') * price.net
                                        buyerSum += palletsSum(state.pallets, 'amount') * price.net
                                        salesSum += palletsSum(state.pallets, 'amount') * price.net
                                    }
                                    break

                                case 'mini_pallet':
                                    if (allProductValid) {
                                        weightSum = 0

                                        selectedProducts.forEach((product) => {
                                            if (price.products.indexOf(product.id) > -1) {
                                                weightSum += product.amount * product.unitMultiplier * product.weight
                                            }
                                        })

                                        if (weightSum > 0 && weightSum <= price.maxWeight) {
                                            disabledTransport = false

                                            listSum += price.net
                                            dealerSum += price.net
                                            buyerSum += price.net
                                            salesSum += price.net
                                        }
                                    }

                                    break

                                case 'product_net_discounted_sum':
                                    if (totalPrices.listPriceNet < price.rangeTo) {
                                        listSum += price.net
                                    } else {
                                        listSum += 0
                                    }

                                    if (totalPrices.dealerPriceNet < price.rangeTo) {
                                        dealerSum += price.net
                                    } else {
                                        dealerSum += 0
                                    }

                                    if (totalPrices.buyerPriceNet < price.rangeTo) {
                                        buyerSum += price.net
                                    } else {
                                        buyerSum += 0
                                    }

                                    if (totalPrices.salesPriceNet < price.rangeTo) {
                                        salesSum += price.net
                                    } else {
                                        salesSum += 0
                                    }

                                    disabledTransport = false
                                    break

                                case 'pallet_or_product_net_discounted_sum':
                                    if (
                                        palletsSum(state.pallets, 'amount') >= price.rangeTo ||
                                        totalPrices.listPriceNet >= price.rangeOrTo ||
                                        totalPrices.dealerPriceNet >= price.rangeOrTo ||
                                        totalPrices.buyerPriceNet >= price.rangeOrTo ||
                                        totalPrices.salesPriceNet >= price.rangeOrTo
                                    ) {
                                        listSum += 0
                                        dealerSum += 0
                                        buyerSum += 0
                                        salesSum += 0
                                    } else {
                                        listSum += price.net
                                        dealerSum += price.net
                                        buyerSum += price.net
                                        salesSum += price.net
                                    }

                                    disabledTransport = false
                                    break
                                }
                            })
                        }
                    }

                    if (isNull) {
                        listTransportPrices.push({id: transport.id, amount: null})
                        dealerTransportPrices.push({id: transport.id, amount: null})
                        buyerTransportPrices.push({id: transport.id, amount: null})
                        salesTransportPrices.push({id: transport.id, amount: null})
                    } else if (disabledTransport) {
                        listTransportPrices.push({id: transport.id, amount: false})
                        dealerTransportPrices.push({id: transport.id, amount: false})
                        buyerTransportPrices.push({id: transport.id, amount: false})
                        salesTransportPrices.push({id: transport.id, amount: false})
                    } else {
                        listTransportPrices.push({id: transport.id, amount: listSum})
                        dealerTransportPrices.push({id: transport.id, amount: dealerSum})
                        buyerTransportPrices.push({id: transport.id, amount: buyerSum})
                        salesTransportPrices.push({id: transport.id, amount: salesSum})
                    }
                })

                // state.transportPrices
                state.groupTransportPrices[tab.groupId] = {
                    list: listTransportPrices,
                    dealer: dealerTransportPrices,
                    buyer: buyerTransportPrices,
                    sales: salesTransportPrices
                }
            })

            let tabTransports = []

            state.orderDataTabs.forEach((tab, i) => {

                let transports = [];

                // state.transports == state.groupTransports[tab.groupId]
                state.groupTransports[tab.groupId].forEach((element, index) => {

                    let prices = state.groupTransportPrices[tab.groupId];

                    if (prices.buyer[index] && prices.buyer[index].amount !== false) {
                        transports[index] = {
                            tab: state.activeOrderTab,
                            id: element.id,
                            name: element.name
                        }
                    } else {
                        transports[index] = undefined;
                    }
                });

                tabTransports[i] = transports.filter(function (item) {
                    return item !== undefined;
                });
            })

            tabTransports.sort((a, b) => a.length - b.length);

            if (tabTransports.length && tabTransports[0].length) {
                state.allTransportsInTabs = tabTransports[0];
            } else {
                state.allTransportsInTabs = state.transports
            }
        }
    },

    specifyRecommendedProduct(state) {
        let definedId = null

        state.orderData.categories.forEach((element) => {
            element.products.forEach((el) => {
                state.orderData.recommendedProducts.forEach((recProduct => {
                    if (el.amount > 0 && el.id === recProduct.productId) {
                        definedId = recProduct.recommendedProductId
                    }
                }))
            })
        })

        state.recommendedProductId = definedId
    },

    setActiveProductTypes(state, payload) {
        state.orderData.activeProductTypes = payload
    },

    setHasCalculation(state, payload) {
        state.orderData.hasCalculation = payload
    },

    setHasColor(state, payload) {
        state.orderData.hasColor = payload
    },

    setBasicInformation(state, payload) {
        state.orderData.basicInformation = payload
    },

    setCategoryTitle(state, payload) {
        state.orderData.categoryTitle = payload
    },

    updateActiveProductTypes(state, payload) {
        if (payload.add) {
            state.orderData.activeProductTypes.push(payload.type)
        } else {
            let index = state.orderData.activeProductTypes.indexOf(payload.type)
            if (index > -1) {
                state.orderData.activeProductTypes.splice(index, 1)
            }
        }
    },

    clearSecondaryProductValueByType(state, payload) {
        let newCategories = state.orderData.categories
        newCategories.forEach((element, index) => {
            element.products.forEach((el, ind) => {
                if (el.type === payload && el.mainColor === false && !el.forceShow && !newCategories[index].products[ind].linked) {
                    let product = newCategories[index].products[ind]

                    product.amount = 0

                    if (product.linkedProducts) {
                        for (let productId in product.linkedProducts) {
                            if (product.linkedProducts.hasOwnProperty(productId)) {
                                let linkedProduct = null

                                newCategories.every(category => {
                                    category.products.every(product => {
                                        if (product.id === Number(productId)) {
                                            linkedProduct = product
                                            return false
                                        }
                                        return true
                                    })
                                    return !linkedProduct
                                })

                                if (linkedProduct) {
                                    linkedProduct.amount = 0
                                }
                            }
                        }
                    }
                }
            })
        })

        state.orderData.categories = newCategories

        if (state.orderDataTabs.length < 1) {
            this.commit('calculatePalletsData')
            this.commit('calculateTransportsData')
        }
    },

    setOrderDetails(state, payload) {
        state.orderDetails = {
            ...payload,
            customerId: payload.customer ? payload.customer.id : null
        }
    },

    resetOrderDetails(state) {
        state.orderDetails = {
            customerOrderId: '',
            transportId: null,
            transports: [],
            groupDiscounts: [],
            products: [],
            shippingLocationId: 1, // raktárra
            shippingTruckTypeId: 1, // fix vagy darus
            shippingName: '',
            shippingPhone: '',
            shippingCompany: '',
            shippingZip: '',
            shippingCity: '',
            shippingAddress: '',
            shippingSoloTruck: false,
            shippingComment: '',
            shippingPathInfo: '',
            shippingDate: null,
            tabs: []
        }
    },

    setOrderStatus(state, payload) {
        Vue.set(state, 'orderStatus', payload)
    },

    setValidation(state, payload) {
        state.validation = payload
    },

    emptyValidation(state) {
        state.validation = {}
    },

    setNotification(state, payload) {
        state.notification = {
            open: true,
            message: payload
        }
    },

    emptyNotification(state) {
        state.notification.open = false
    },

    openSettingsSidebar(state, payload) {
        state.orderSettings[payload].open = true
    },

    closeSettingsSidebar(state, payload) {
        state.orderSettings[payload].open = false
    },

    closeAllSettingsSidebars(state) {
        let sidebarTypes = Object.keys(state.orderSettings)
        sidebarTypes.forEach((el) => {
            state.orderSettings[el].open = false
        })
    },

    openModal(state, payload) {
        state.modals[payload].open = true
    },

    closeModal(state, payload) {
        state.modals[payload].open = false
    },

    setSavedComments(state, payload) {
        state.savedComments = payload
    },

    saveComments(state, payload) {
        state.additionalDetails.comments = payload
    },

    saveSystemWarrantee(state, payload) {
        state.additionalDetails.systemWarrantee = payload
    },

    setTargetComment(state, payload) {
        state.targetComment = payload
    },

    selectSavedComment(state, payload) {
        let index = state.targetComment
        Vue.set(state.additionalDetails.comments[index], 'text', payload)
    },

    addToSavedComments(state, payload) {
        state.savedComments[payload.type].push(payload.text)
    },

    updateSavedComment(state, payload) {
        state.savedComments[payload.type][payload.index] = payload.text
    },

    deleteSavedComment(state, payload) {
        state.savedComments[payload.type].splice([payload.index], 1)
    },

    setOrderProductTypes(state, payload) {
        state.orderProductTypes = payload
    },

    setObjectStandaloneTypes(state, payload) {
        state.objectStandaloneTypes = payload
    },

    setOfferProductTypes(state, payload) {
        state.offerProductTypes = payload
    },

    setAdvisories(state, payload) {
        state.advisories = payload
    },

    setAllDealers(state, payload) {
        state.allDealers = payload
    },

    setProjectOffers(state, payload) {
        state.projectOffers = payload
    },

    setProjectDealers(state, payload) {
        state.projectDealers = payload
    },

    setDealers(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.dealers.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'dealers', {
            advisoryUsers: response.data.advisoryUsers.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('dealer.page.list.default_filter.advisories')
            }, ...response.data.advisoryUsers],
            types: response.data.types.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('dealer.page.list.default_filter.types')
            }, ...response.data.types],
            filters: response.data.filters,
            meta: response.meta,
            pages
        })
    },

    removeDealerListPage(state, removePage) {
        let pages = {}

        Object.keys(state.dealers.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.dealers.pages[page]}
            }
        })

        Vue.set(state, 'dealers', {
            ...state.dealers,
            pages
        })
    },

    setDealerListActiveFilters(state, payload) {
        Vue.set(state, 'dealerListActiveFilters', payload)
    },

    setDealerListScrollPosition(state, payload) {
        Vue.set(state, 'dealerListScrollPosition', payload)
    },

    resetDealers(state) {
        Vue.set(state, 'dealers', {})
        Vue.set(state, 'dealerListActiveFilters', {})
        Vue.set(state, 'dealerListScrollPosition', 0)
    },

    setWorkers(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.workers.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'workers', {
            meta: response.meta,
            pages
        })
    },

    removeWorkerListPage(state, removePage) {
        let pages = {}

        Object.keys(state.workers.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.workers.pages[page]}
            }
        })

        Vue.set(state, 'workers', {
            ...state.workers,
            pages
        })
    },

    setWorkerListActiveFilters(state, payload) {
        Vue.set(state, 'workerListActiveFilters', payload)
    },

    resetWorkers(state) {
        Vue.set(state, 'workers', {})
        Vue.set(state, 'workerListActiveFilters', {})
    },

    setRegionList(state, payload) {
        if (payload.advisoryUsers.length > 0) {
            payload.advisoryUsers = [{
                id: 0,
                name: i18n.t('region.page.list.default_filter.advisories')
            }, ...payload.advisoryUsers]
        }

        Vue.set(state, 'regions', payload)
    },

    setWorkerProjectList(state, payload) {
        Vue.set(state, 'workerProjectList', payload)
    },

    setWorkersAll(state, payload) {
        Vue.set(state, 'workersAll', payload)
    },

    setProducts(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.products.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'products', {
            types: response.data.types.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('product.page.list.default_filter.types')
            }, ...response.data.types],
            meta: response.meta,
            pages
        })
    },

    removeProductListPage(state, removePage) {
        let pages = {}

        Object.keys(state.products.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.products.pages[page]}
            }
        })

        Vue.set(state, 'products', {
            ...state.products,
            pages
        })
    },

    setProductListActiveFilters(state, payload) {
        Vue.set(state, 'productListActiveFilters', payload)
    },

    resetProducts(state) {
        Vue.set(state, 'products', {})
        Vue.set(state, 'productListActiveFilters', {})
    },

    setGroupTypeProductList(state, payload) {
        if (payload.categories.length > 0) {
            payload.categories = [{
                id: 0,
                name: i18n.t('group_type_product.page.list.default_filter.categories')
            }, ...payload.categories]
        }

        if (payload.productTypes.length > 0) {
            payload.productTypes = [{
                id: 0,
                name: i18n.t('group_type_product.page.list.default_filter.product_types')
            }, ...payload.productTypes]
        }

        Vue.set(state, 'groupTypeProducts', payload)
    },

    resetGroupTypeProductList(state) {
        Vue.set(state, 'groupTypeProducts', {})
    },

    setGroupCategoryProductList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (payload.categories.length > 0) {
            payload.categories = [{
                id: 0,
                name: i18n.t('group_category_product.page.list.default_filter.categories')
            }, ...payload.categories]
        }

        Vue.set(state, 'groupCategoryProducts', payload)
    },

    resetGroupCategoryProductList(state) {
        Vue.set(state, 'groupCategoryProducts', {})
    },

    setGroupCategoryProductTypeList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'groupCategoryProductTypes', payload)
    },

    resetGroupCategoryProductTypeList(state) {
        Vue.set(state, 'groupCategoryProductTypes', {})
    },

    setGroupCategorySort(state, payload) {
        Vue.set(state, 'groupCategorySort', payload)
    },

    setGroupCategorySortPart(state, {path, value}) {
        const findSubState = (s) => {
            for (let r of s) {
                if(r.path === path) {
                    return r
                } else if(r.children) {
                    let found = findSubState(r.children)
                    if(found) {
                        return found
                    }
                }
            }
        }

        if(path === '') {
            Vue.set(state, 'groupCategorySort', value)
        } else {
            let subState = findSubState(state.groupCategorySort)
            Vue.set(subState, 'children', value)
        }
    },

    setGroupTypeSort(state, payload) {
        Vue.set(state, 'groupTypeSort', payload)
    },

    setGroupTypeSortPart(state, {path, value}) {
        const findSubState = (s) => {
            for (let r of s) {
                if(r.path === path) {
                    return r
                } else if(r.children) {
                    let found = findSubState(r.children)
                    if(found) {
                        return found
                    }
                }
            }
        }

        if(path === '') {
            Vue.set(state, 'groupTypeSort', value)
        } else {
            let subState = findSubState(state.groupTypeSort)
            Vue.set(subState, 'children', value)
        }
    },

    setGroupTypeVariantList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (payload.typeCategories.length > 0) {
            payload.typeCategories = [{
                id: 0,
                name: i18n.t('group_type_variant.page.list.default_filter.type_categories')
            }, ...payload.typeCategories]
        }

        if (payload.types.length > 0) {
            payload.types = [{
                id: 0,
                name: i18n.t('group_type_variant.page.list.default_filter.types')
            }, ...payload.types]
        }

        Vue.set(state, 'groupTypeVariants', payload)
    },

    resetGroupTypeVariantList(state) {
        Vue.set(state, 'groupTypeVariants', {})
    },

    setGroupProductList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'groupProducts', payload)
    },

    setProductTypeList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'productTypes', payload)
    },

    setColorList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'colors', payload)
    },

    setUnitList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'units', payload)
    },

    setCarrierList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'carriers', payload)
    },

    setSurfaceList(state, payload) {
        payload.rows = payload.rows.map(r => {
            return {...r, showDropdown: false}
        })

        Vue.set(state, 'surfaces', payload)
    },

    setReAuthenticated(state, payload) {
        Vue.set(state, 'reAuthenticated', payload)
    },

    setUsers(state, {response, keepOtherPages}) {
        let pages

        const rows = response.data.rows.map(r => {
            return {...r, showDropdown: false}
        })

        if (keepOtherPages) {
            pages = {
                ...state.users.pages,
                [response.meta.current_page]: rows
            }
        } else {
            pages = {
                [response.meta.current_page]: rows
            }
        }

        Vue.set(state, 'users', {
            dealers: response.data.dealers.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('user.page.list.default_filter.dealers')
            }, ...response.data.dealers],
            statuses: response.data.statuses.length === 0 ? [] : [{
                id: 0,
                name: i18n.t('user.page.list.default_filter.statuses')
            }, ...response.data.statuses],
            filters: response.data.filters,
            meta: response.meta,
            pages
        })
    },

    setAllUsers(state, {response}) {
        Vue.set(state, 'allUsers', {
            rows: response.rows
        })
    },

    setAllUsersWithoutFilters(state, {response}) {
        Vue.set(state, 'allUsersWithoutFilters', {
            rows: response.rows
        })
    },

    removeUserListPage(state, removePage) {
        let pages = {}

        Object.keys(state.users.pages).forEach(page => {
            if (Number(page) !== Number(removePage)) {
                pages[page] = {...state.users.pages[page]}
            }
        })

        Vue.set(state, 'users', {
            ...state.users,
            pages
        })
    },

    setUserListActiveFilters(state, payload) {
        Vue.set(state, 'userListActiveFilters', payload)
    },

    resetUsers(state) {
        Vue.set(state, 'users', {})
        Vue.set(state, 'userListActiveFilters', {})
    },

    setUserRoles(state, payload) {
        state.userRoles = payload
    },

    setAllUserRoles(state, payload) {
        state.allUserRoles = payload
    },

    setNewOfferGroup(state, payload) {
        state.newOfferGroup = payload
    },

    setOrderInformation(state, payload) {
        state.orderInformation = payload
    },

    setOfferInformation(state, payload) {
        state.offerInformation = payload
    },

    setOriginalOffer(state, payload) {
        state.originalOffer = {...payload}
    },

    setOfferInformationValidTo(state, payload) {
        Vue.set(state.offerInformation, 'validTo', payload)
    },

    setOfferInformationName(state, payload) {
        Vue.set(state.offerInformation, 'name', payload)
    },

    setOfferInformationEditorUser(state, payload) {
        Vue.set(state.offerInformation, 'editorUser', payload)
    },

    setOfferInformationAdvisoryUser(state, payload) {
        Vue.set(state.offerInformation, 'advisoryUser', payload)
    },

    setOfferInformationDealers(state, payload) {
        Vue.set(state.offerInformation, 'dealerUsers', payload)
    },

    setOfferInformationWorkers(state, payload) {
        Vue.set(state.offerInformation, 'workerUsers', payload)
    },

    setOfferInformationObserverUsers(state, payload) {
        Vue.set(state.offerInformation, 'observerUsers', payload)
    },

    setOfferInformationViewType(state, payload) {
        Vue.set(state.offerInformation, 'viewType', payload)
    },

    setOfferActions(state, payload) {
        state.offerActions = payload
    },

    updateOfferDiscounts(state, payload) {
        state.discounts = payload
    },

    setDiscounts(state, payload) {
        state.discounts = payload
    },

    setPallets(state, payload) {
        state.pallets = payload
        this.commit('calculateServicePrice')
        this.commit('calculateTransportsData')
    },

    setCustomPallets(state, payload) {
        state.customPallets = payload
    },

    setCustomDemolitionPallets(state, payload) {
        state.customDemolitionPallets = payload
    },

    setCustomTransport(state, payload) {
        state.customTransport = payload
    },

    setCustomDealerTransport(state, payload) {
        state.customDealerTransport = payload
    },

    setDealerTransport(state, payload) {
        state.dealerTransport = payload
    },

    setServices(state, payload) {
        state.services = payload
        this.commit('calculateServicePrice')
    },

    calculateServicePrice(state) {
        let totalPrice = 0

        state.services.forEach(element => {
            if (element.active && !element.calcDemolition) {
                state.pallets.forEach(pallet => {
                    if (element.pallets.indexOf(pallet.id) > -1) {
                        totalPrice += Number(pallet.amount) * element.price
                    }
                })
            } else if (element.active && element.calcDemolition) {
                totalPrice += Number(element.amount) * element.price
            }
        })

        state.totalServicePrice = totalPrice
    },

    setGroupTransports(state, payload) {
        state.groupTransports = payload
    },

    setTransports(state, payload) {
        state.transports = payload
    },

    setSelectedTransport(state, payload) {
        state.selectedTransport = payload
    },

    setWarehouse(state, payload) {
        state.warehouse = payload
    },

    updateProductDiscounts(state, payload) {
        let discounts = {
            sales: {},
            buyer: {},
            dealer: {}
        }

        payload.groupDiscounts.forEach((offerDiscount) => {
            discounts.sales[offerDiscount.id] = Number(offerDiscount.sales)
            discounts.buyer[offerDiscount.id] = Number(offerDiscount.buyer)
            discounts.dealer[offerDiscount.id] = Number(offerDiscount.dealer)
        })

        if (state.orderDataTabs.length < 1) {
            let updatedCategories = state.orderData.categories
            updatedCategories.forEach((category, index) => {
                category.products.forEach((product, ind) => {
                    let sales = 0
                    let buyer = 0
                    let dealer = 0

                    if (product.listPrice.subDiscounts.length > 0) {
                        sales = {}
                        buyer = {}
                        dealer = {}
                        product.listPrice.subDiscounts.forEach((subDiscount) => {
                            sales[subDiscount.id] = discounts.sales[subDiscount.id] || 0
                            buyer[subDiscount.id] = discounts.buyer[subDiscount.id] || 0
                            dealer[subDiscount.id] = discounts.dealer[subDiscount.id] || 0
                        })
                    } else {
                        product.listPrice.discounts.forEach((productDiscount) => {
                            if (discounts.sales[productDiscount] > 0) {
                                sales = discounts.sales[productDiscount]
                            }
                            if (discounts.buyer[productDiscount] > 0) {
                                buyer = discounts.buyer[productDiscount]
                            }
                            if (discounts.dealer[productDiscount] > 0) {
                                dealer = discounts.dealer[productDiscount]
                            }
                        })
                    }

                    updatedCategories[index]['products'][ind].salesDiscount = sales
                    updatedCategories[index]['products'][ind].buyerDiscount = buyer
                    updatedCategories[index]['products'][ind].dealerDiscount = dealer
                })
            })

            state.orderData.categories = updatedCategories
        } else {
            this.commit('updateOrderDataTabs')

            let updatedTabs = state.orderDataTabs
            updatedTabs.forEach((tab, tabIndex) => {
                tab.categories.forEach((category, index) => {
                    category.products.forEach((product, ind) => {
                        let dealer = 0

                        if (product.listPrice.subDiscounts.length > 0) {
                            dealer = {}
                            product.listPrice.subDiscounts.forEach((subDiscount) => {
                                dealer[subDiscount.id] = discounts.dealer[subDiscount.id] || 0
                            })
                        } else {
                            product.listPrice.discounts.forEach((productDiscount) => {
                                if (discounts.dealer[productDiscount] > 0) {
                                    dealer = discounts.dealer[productDiscount]
                                }
                            })
                        }

                        updatedTabs[tabIndex]['categories'][index]['products'][ind].dealerDiscount = dealer
                    })
                })
            })

            state.orderDataTabs = updatedTabs

            loadTab(this.commit, state.orderDataTabs[state.selectedOrderDataTab], false)
        }
    },

    setZipCodesList(state, payload) {
        state.zipCodesList = payload
    },

    setCitiesList(state, payload) {
        state.citiesList = payload
    },

    setDisplayNetPrice(state, payload) {
        state.displayNetPrice = payload
    },

    setProductText(state, payload) {
        if (typeof payload === 'string') {
            if (payload === '') {
                payload = {}
            } else {
                try {
                    payload = JSON.parse(payload) || {}
                } catch (e) {
                    console.error('setProductText exception', e)
                    payload = getClipboardData(payload, true) || {}
                }
            }
        }
        state.productText = payload
    },

    setProductTextDebug(state, payload) {
        state.productTextDebug = payload
    },

    setPermissions(state, payload) {
        state.permissions = payload
    },

    setViewOnly(state, payload) {
        state.viewOnly = payload
    },

    setCanTag(state, payload) {
        state.canTag = payload
    },

    setCanEditSales(state, payload) {
        state.canEditSales = payload
    },

    setLoginData(state, payload) {
        state.loginData = payload
    },

    clearLoginData(state) {
        state.loginData = null
    }
}
