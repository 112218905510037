<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="col-left">
                    <div class="input-wrapper" :class="{'error' : errors.name}">
                        <label class="text-input-label" for="color-name">{{ $t('color.manage.field.name') }}</label>
                        <input type="text" v-model="name" id="color-name">
                        <span class="input-error">{{errors.name}}}</span>
                    </div>
                    <div class="input-wrapper input-wrapper--checkbox" :class="{'error' : errors.public}">
                        <div class="toggle">
                            <input type="checkbox" v-model="public" id="color-public">
                            <label for="color-public">{{ $t('color.manage.field.public') }}</label>
                        </div>
                        <span class="input-error">{{errors.public}}</span>
                    </div>
                </div>
                <div class="col-right">
                </div>
                <div class="col-full">
                    <div class="input-wrapper" :class="{'error' : errors.similar}">
                        <label class="text-input-label">Hasonló színek</label>
                        <div class="input-wrapper input-wrapper--checkbox input-wrapper--multi-columns">
                            <div class="checkbox" v-for="color in colors" :key="color.id">
                                <input type="checkbox" :value="color.id" :id="`color-similar-${color.id}`" v-model="similar">
                                <label :for="`color-similar-${color.id}`">{{ color.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="saveColor" :disabled="loading">
                        {{ $t('color.manage.button.save') }}
                    </button>
                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('color.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalColorEdit',
    props: ['open', 'colorId'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            name: null,
            public: true,
            similar: [],
            colors: []
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_COLOR', {colorId: this.colorId}).then(data => {
                    this.title = data.title
                    this.name = data.name
                    this.public = data.public
                    this.similar = data.similar
                    this.colors = data.colors
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        },
    },

    methods: {
        saveColor() {
            this.errors = {}

            let payload = {
                colorId: this.colorId,
                data: {
                    name: this.name,
                    public: this.public,
                    similar: this.similar
                }
            }

            this.$store.dispatch('SEND_COLOR', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },
    },

    components: { Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        &-body {

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--checkbox {
                    margin-bottom: 0;
                    margin-top: -30px;
                }

                &--multi-columns {
                    margin-top: 5px;
                    column-count: 3;
                }

                .checkbox {
                    display: block;
                }

                .dropdown-list {
                    top: 76px;
                }

                .toggle {
                    margin-left: 22px;
                }
            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .col-full {
                clear: both;
                width: 100%;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 15px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
