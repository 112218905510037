<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('worker.project_select.title') }}</span>
                <button type="button" @click="close()" class="btn btn-plain">{{ $t('worker.project_select.button.close') }}</button>
            </div>

            <div class="modal-box-body">
                <label class="text-input-label" for="project-name-select">{{ $t('worker.project_select.field.project') }}</label>

                <div class="select-container">
                    <input
                        @keyup="searchProjects"
                        v-model="projectName"
                        ref="searchInput"
                        id="project-name-select"
                        type="text"
                        :placeholder="this.$t('worker.project_select.text.placeholder')"
                        v-autofocus
                        v-autocomplete-off
                    >

                    <ul v-show="resultsList.length > 0 && showList" class="dropdown-list">
                        <li
                            @click="selectProject(result)"
                            v-for="result in resultsList"
                            :key="result.id"
                        >
                            <span class="result-name">
                                <span v-html="result.styledName"></span>
                            </span>
                        </li>
                    </ul>
                </div>

                <button class="btn btn-primary" @click="saveSelectedProject()" :disabled="disableSaving">{{ $t('worker.project_select.button.add') }}</button>
                <button type="button" class="btn btn-secondary button-cancel" @click="close()">{{ $t('worker.project_select.button.cancel') }}</button>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectSelectBasic',
    props: ['open', 'workerId'],

    data() {
        return {
            projectName: '',
            results: [],
            selectedProject: null,
            disableSaving: true,
            showList: false
        }
    },

    watch: {
        open: function(val) {
            if (val) {
                this.$store.dispatch('FETCH_WORKER_PROJECT_LIST', this.workerId)
            }
        }
    },

    methods: {
        searchProjects() {
            this.disableSaving = true
            this.showList = true

            let lowerName = this.projectName.toLowerCase()
            this.results = this.workerProjectList.filter((list) => {
                return list.name.toLowerCase().includes(lowerName)
            })
        },

        selectProject(val) {
            this.selectedProject = val
            this.projectName = val.name
            this.disableSaving = false
            this.showList = false
        },

        close(success) {
            this.selectedProject = null
            this.projectName = ''
            this.results = []
            this.disableSaving = true
            this.showList = false
            this.$emit('close', success)
        },

        saveSelectedProject() {
            let data = {
                workerId: this.workerId,
                projectId: this.selectedProject.id
            }

            this.$store.dispatch('SAVE_WORKER_PROJECT', data).then(() => {
                this.close(true)
            })
        }
    },

    computed: {
        resultsList() {
            return this.results.map(r => {
                let pos = r.name.toLowerCase().indexOf(this.projectName.toLowerCase())
                let projectNameLength = this.projectName.length
                r.styledName = ''

                if (pos < 1) {
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(0, projectNameLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        projectNameLength,
                        projectNameLength + r.name.length
                    )
                } else {
                    r.styledName += r.name.substring(0, pos)
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(pos, pos + projectNameLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        pos + projectNameLength,
                        pos + projectNameLength + r.name.length
                    )
                }

                return r
            })
        },

        ...mapGetters(['workerProjectList'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {

    @media only screen and (max-width: 600px) {
        width: 95%!important;
    }

    &-box {
        &-body {
            overflow-y: visible;

            input,
            .input-label {
                width: 100%;
            }

            .select-container {
                position: relative;
                border-bottom: 1px solid $color-gray-border;
                padding-bottom: 30px;
                margin-bottom: 30px;
            }

            .btn-primary {
                margin-right: 10px;
            }
        }
    }
}
</style>
