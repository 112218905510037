<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style lang="scss">
@import "/node_modules/vue-material/dist/vue-material.min.css";
@import "assets/sass/global.scss";
</style>
