<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.plan_calculation.export.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="col-left">
                    <div class="row" v-if="users.length > 0">
                        <div class="input-wrapper">
                            <label class="label">{{ $t('offer.plan_calculation.export.field.app_engineer.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="user"
                                :options="users"
                                @changed="selectedUser"
                                :cursorNotAllowed="false"
                                :bold="false"
                                :large="true"
                            ></custom-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="input-wrapper">
                            <label class="label">{{ $t('offer.plan_calculation.export.field.context.label') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="exportOption"
                                :options="exportOptions"
                                @changed="selectedExportOption"
                                :cursorNotAllowed="false"
                                :bold="false"
                                :large="true"
                            ></custom-select>
                        </div>
                    </div>

                    <template v-if="exportOption.id === 'year_month'">
                        <div class="row">
                            <div class="input-wrapper">
                                <label class="label">{{ $t('offer.plan_calculation.export.field.month.label') }}</label>
                                <custom-select
                                    :modal="true"
                                    :selectedItem="yearMonthOption"
                                    :options="yearMonthOptions"
                                    :disabled="yearMonthOptions.length === 0"
                                    @changed="selectedYearMonthOption"
                                    :cursorNotAllowed="false"
                                    :bold="false"
                                    :large="true"
                                ></custom-select>
                            </div>
                        </div>
                    </template>

                    <template v-if="exportOption.id === 'year'">
                        <div class="row">
                            <div class="input-wrapper">
                                <label class="label">{{ $t('offer.plan_calculation.export.field.year.label') }}</label>
                                <custom-select
                                    :modal="true"
                                    :selectedItem="yearOption"
                                    :options="yearOptions"
                                    :disabled="yearOptions.length === 0"
                                    @changed="selectedYearOption"
                                    :cursorNotAllowed="false"
                                    :bold="false"
                                    :large="true"
                                ></custom-select>
                            </div>
                        </div>
                    </template>

                    <template v-if="exportOption.id === 'period'">
                        <span class="label label-date">{{ $t('offer.plan_calculation.export.field.period.label') }}</span>
                        <div class="row-input">
                            <div class="date-box">
                                <md-icon>calendar_today</md-icon>
                                <span class="date-period">{{ datePeriod }}</span>
                            </div>
                            <div>
                                <button class="btn btn-plain" type="button" @click="getThisMonth">{{ $t('offer.plan_calculation.export.option.period.this_month') }}</button>
                                <button class="btn btn-plain" type="button" @click="getThreeMonths">{{ $t('offer.plan_calculation.export.option.period.three_months') }}</button>
                            </div>
                        </div>
                        <div class="row-calendars">
                            <div class="col-calendar">
                                <span class="label">{{ $t('offer.plan_calculation.export.field.date_from.label') }}</span>
                                <date-pick
                                    v-model="dateFrom"
                                    :hasInputElement="false"
                                    :nextMonthCaption="lang.nextMonthCaption"
                                    :prevMonthCaption="lang.prevMonthCaption"
                                    :setTimeCaption="lang.setTimeCaption"
                                    :weekdays="lang.weekdays"
                                    :months="lang.months"
                                    :isDateDisabled="isFutureDate"
                                ></date-pick>
                            </div>
                            <div class="col-calendar">
                                <span class="label">{{ $t('offer.plan_calculation.export.field.date_to.label') }}</span>
                                <date-pick
                                    v-model="dateTo"
                                    :hasInputElement="false"
                                    :nextMonthCaption="lang.nextMonthCaption"
                                    :prevMonthCaption="lang.prevMonthCaption"
                                    :setTimeCaption="lang.setTimeCaption"
                                    :weekdays="lang.weekdays"
                                    :months="lang.months"
                                    :isDateDisabled="isFutureDate"
                                ></date-pick>
                            </div>
                        </div>
                    </template>
                    <button class="btn btn-secondary button-download" @click="downloadList" :disabled="counts.plans < 1 || download || loading">
                        <md-icon>save_alt</md-icon>
                        {{ $t('offer.plan_calculation.export.button.download') }}
                    </button>
                    <a href="" download ref="pdfButton" class="pdf-download"></a>
                </div>
                <div class="col-right">
                    <div class="row-number">
                        <span class="label">{{ $t('offer.plan_calculation.export.text.all_offers') }}</span>
                        <span class="number">{{counts.offers}}</span>
                    </div>
                    <div class="row-number">
                        <span class="label">{{ $t('offer.plan_calculation.export.text.all_plan_calculations') }}</span>
                        <span class="number">{{counts.plans}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import moment from '../../plugins/moment'
import config from '../../plugins/config.js'
import axios from 'axios'

import DatePick from 'vue-date-pick'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalCalculationExport',
    props: ['open'],

    data() {
        return {
            exportOption: {
                id: 'year_month',
                name: this.$t('offer.plan_calculation.export.option.context.month')
            },
            exportOptions: [
                {
                    id: 'year',
                    name: this.$t('offer.plan_calculation.export.option.context.year')
                },
                {
                    id: 'year_month',
                    name: this.$t('offer.plan_calculation.export.option.context.month')
                },
                {
                    id: 'period',
                    name: this.$t('offer.plan_calculation.export.option.context.period')
                }
            ],
            yearOption: null,
            yearOptions: [],
            yearMonthOption: null,
            yearMonthOptions: [],
            defaultDateFrom: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
            defaultDateTo: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
            dateFrom: 0,
            dateTo: 0,
            results: [],
            responsibleUsers: [],
            counts: {
                offers: 0,
                plans: 0
            },
            dataMap: null,
            users: [],
            user: null,
            offerList: [],
            lang: {
                nextMonthCaption: this.$t('common.datepicker.next_month_caption'),
                prevMonthCaption: this.$t('common.datepicker.prev_month_caption'),
                setTimeCaption: this.$t('common.datepicker.set_time_caption'),
                weekdays: [
                    this.$t('common.datepicker.weekdays.mo'),
                    this.$t('common.datepicker.weekdays.tu'),
                    this.$t('common.datepicker.weekdays.we'),
                    this.$t('common.datepicker.weekdays.th'),
                    this.$t('common.datepicker.weekdays.fr'),
                    this.$t('common.datepicker.weekdays.sa'),
                    this.$t('common.datepicker.weekdays.su'),
                ],
                months: [
                    this.$t('common.datepicker.months.jan'),
                    this.$t('common.datepicker.months.feb'),
                    this.$t('common.datepicker.months.mar'),
                    this.$t('common.datepicker.months.apr'),
                    this.$t('common.datepicker.months.may'),
                    this.$t('common.datepicker.months.jun'),
                    this.$t('common.datepicker.months.jul'),
                    this.$t('common.datepicker.months.aug'),
                    this.$t('common.datepicker.months.sep'),
                    this.$t('common.datepicker.months.oct'),
                    this.$t('common.datepicker.months.nov'),
                    this.$t('common.datepicker.months.dec')
                ]
            },
            loading: true,
            download: false
        }
    },

    watch: {
        open: {
            handler: function(val) {
                if (val) {
                    this.fetchData()
                }
            }
        },

        dateFrom: {
            handler: function() {
                this.countData()
            }
        },

        dateTo: {
            handler: function() {
                this.countData()
            }
        }
    },

    methods: {
        selectedYearOption(val) {
            this.yearOption = val
            this.updatePeriod()
        },

        selectedYearMonthOption(val) {
            this.yearMonthOption = val
            this.updatePeriod()
        },

        selectedExportOption(val) {
            this.exportOption = val
            this.updatePeriod()
        },

        updatePeriod() {
            if(this.exportOption.id === 'year' && this.yearOption) {
                this.dateFrom = moment().utc().year(this.yearOption.id).startOf('year').unix()
                this.dateTo = moment().utc().year(this.yearOption.id).endOf('year').unix()
            } else if(this.exportOption.id === 'year_month' && this.yearMonthOption) {
                let month = this.yearMonthOption.id % 100
                let year = (this.yearMonthOption.id - month) / 100;
                this.dateFrom = moment().utc().year(year).month(month - 1).startOf('month').unix()
                this.dateTo = moment().utc().year(year).month(month - 1).endOf('month').unix()
            } else if(this.exportOption.id === 'period') {
                this.dateFrom = this.defaultDateFrom
                this.dateTo = this.defaultDateTo
            }
        },

        getThisMonth() {
            this.dateFrom = moment().startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().endOf('month').format('YYYY-MM-DD')
        },

        getThreeMonths() {
            this.dateFrom = moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD')
            this.dateTo = moment().endOf('month').format('YYYY-MM-DD')
        },

        downloadList() {
            let newData = {
                type: this.exportOption.id,
                userId: this.user ? this.user.id : null
            }

            if(this.exportOption.id === 'year') {
                newData.year = this.yearOption.id
            } else if(this.exportOption.id === 'year_month') {
                newData.yearMonth = this.yearMonthOption.id
            } else {
                newData.dateFrom = this.dateFrom;
                newData.dateTo = this.dateTo
            }

            this.download = true

            axios.post('plan-calculations/export', newData, config.AXIOS_DEFAULTS)
                .then(data => {
                    const downloadButton = this.$refs.pdfButton
                    downloadButton.href = data.downloadUrl
                    downloadButton.click()
                })
                .finally(() => {
                    this.download = false
                })
        },

        isFutureDate(date) {
            const lastDate = new Date(moment().endOf('month'))
            return date > lastDate
        },

        fetchData() {
            this.loading = true
            return axios.get('plan-calculations/export', config.AXIOS_DEFAULTS)
                .then(response => {
                    let data

                    this.dataMap = {}
                    this.users = []
                    this.user = null

                    if (response.users) {
                        this.users = response.users.map(p => {
                            this.dataMap[p.id] = p.data
                            return {id: p.id, name: p.name}
                        })
                        this.user = this.users[0]
                        data = this.dataMap[this.user.id]
                    } else {
                        data = response
                    }

                    this.loadData(data)
                    this.updatePeriod()
                    this.countData()
                })
                .finally(() => {
                    this.loading = false
                })
        },

        loadData(data) {
            this.yearOptions = data.years
            this.yearOption = data.years[data.years.length - 1]
            this.yearMonthOptions = data.yearMonths
            this.yearMonthOption = data.yearMonths[data.yearMonths.length - 1]
            this.offerList = data.offers
        },

        countData() {
            let plansCount = 0
            let offersCount = 0
            let dateFromTs = this.exportOption.id === 'period' ? moment(this.dateFrom, 'YYYY-MM-DD').startOf('day').unix() : this.dateFrom
            let dateToTs = this.exportOption.id === 'period' ? moment(this.dateTo, 'YYYY-MM-DD').endOf('day').unix() : this.dateTo
            this.offerList.forEach(offer => {
                if (offer.createdAt >= dateFromTs && offer.createdAt <= dateToTs) {
                    plansCount++
                    offersCount += 1 + offer.numberOfChildren
                }
            })
            Vue.set(this.counts, 'plans', plansCount)
            Vue.set(this.counts, 'offers', offersCount)
        },

        selectedUser(user) {
            this.user = user
            this.loadData(this.dataMap[this.user.id])
            this.updatePeriod()
            this.countData()
        }
    },

    computed:  {
        datePeriod() {
            const begin = moment(this.dateFrom, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range')))
            let end

            if (this.dateFrom.substring(0, 4) === this.dateTo.substring(0, 4)) {
                end = moment(this.dateTo, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range_without_year')))
            } else {
                end = moment(this.dateTo, 'YYYY-MM-DD').format(String(this.$t('common.date.format.week_range')))
            }

            return `${begin} - ${end}`
        }
    },

    components: { DatePick, CustomSelect }
}
</script>

<style lang="scss">
@import '../../../node_modules/vue-date-pick/dist/vueDatePick.css';
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/datepick.scss';
.col-calendar {
    .vdpComponent {
        position: relative;
        top: 0;
        width: auto;
    }

    .vdpHeader {
        padding: 0 5px 30px;

        .vdpArrow {
            top: 10px;
            left: 0;

            &.vdpArrowPrev {
                &:after {
                    margin-top: -5px;
                    margin-left: -3px;
                }
            }

            &.vdpArrowNext {
                left: auto;
                right: 0;

                &:after {
                    margin-top: -5px;
                    margin-left: -11px;
                }
            }
        }
    }

    .vdpInnerWrap {
        min-width: 275px;
    }

    .vdpPeriodControl>button {
        font-size: 13px;
        font-weight: 600;
    }

    .vdpPeriodControls .vdpPeriodControl:last-child button {
        font-size: 11px;
        font-weight: 500;
    }

    .vdpHeadCellContent {
        font-size: 13px;
    }
}

</style>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 1050px;

        @media #{$bp-laptop} {
            top: 10px;
        }

        &-body {
            display: flex;
            padding: 0;

            @media #{$bp-laptop} {
                max-height: calc(100vh - 100px);
            }

            .col-left {
                width: 65%;
                padding: 30px;

                input {
                    width: 100%;
                }

                .row {
                    margin-bottom: 30px;
                }

                .input-wrapper {
                    .select-component {
                        max-width: 200px;
                    }
                }

                .label {
                    display: block;
                    font-size: 16px;
                    margin-bottom: 15px;
                }

                .autocomplete-container {
                    position: relative;
                }

                .row-input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .date-box {
                    display: inline-flex;
                    padding: 5px 10px 12px 0;
                    align-items: center;

                    .date-period {
                        font-size: 15px;
                        font-weight: 500;
                        margin: 7px 15px 3px 7px
                    }
                }

                .btn-plain {
                    position: relative;
                    margin-left: 25px;
                }

                .row-calendars {
                    display: flex;
                    margin-top: 25px;

                    .col-calendar {
                        width: 50%;
                        text-align: center;

                        &:first-child {
                            padding-right: 10px;
                        }

                        &:last-child {
                            padding-left: 10px;
                        }

                        .label {
                            font-size: 14px;
                            margin-bottom: 15px;
                        }
                    }
                }

                .button-download {
                    margin-top: 30px;
                    margin-bottom: 20px;
                }

                .pdf-download {
                    visibility: hidden;
                }
            }

            .col-right {
                width: 35%;
                background: $color-gray-bg-2;

                .row-number {
                    padding: 30px 30px 40px;

                    &:first-child {
                        padding-top: 100px;
                        border-bottom: 2px solid $color-gray-border;
                    }

                        .label {
                            display: block;
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 30px;
                        }

                        .number {
                            font-size: 58px;
                            font-weight: 600;
                        }
                }
            }
        }
    }
}
</style>
