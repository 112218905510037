<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box">
            <div class="modal-box-body">
                <div class="message-text">
                    <h2 class="modal--shipping-header">Kérjük, ellenőrizze a kért szállítási időt!</h2>
                    <span class="modal--shipping-date">{{ dateString }}</span>
                    <p class="modal--shipping-text">
                        A megjegyzés rovatban kérjük jelezze ha az időpont bizonytalan vagy további egyezetetés szükséges.
                    </p>
                </div>

                <div class="input-wrapper shipping-date custom--shipping-date">
                    <datepicker
                        id="shipping-date-modal"
                        :initDate="shippingDate"
                        :transport-id="transportId"
                        :isFullWeek="true"
                        @dateText="(t) => dateText = t"
                        @changed="(el) => { setShippingDate(el) }" />
                </div>

                <div class="input-wrapper comment" >
                    <span class="text-input-label">{{$t('order.page.details.transport.field.comment.label')}}</span>
                    <textarea :placeholder="this.$t('order.page.details.transport.field.comment.placeholder')" v-model="shippingComment"></textarea>
                </div>

                <div class="button-container">
                    <button type="button" class="btn btn-primary" @click="acceptChanges()">Rendben</button>
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">Mégsem</button>
                </div>

            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import Datepicker from '../dropdowns/Datepicker.vue'
import moment from '../../plugins/moment'

Vue.use(MdIcon)

export default {
    name: 'ModalShipping',
    emits: ['accepted', 'close'],
    props: ['open', 'date', 'comment', 'transportId', 'isAcceptButtonDisabled'],
    components: {
        Datepicker
    },

    data() {
        return {
            shippingDate: this.date,
            shippingComment: this.comment,
            dateText: null,
        }
    },

    watch: {
        date(newDate) {
            this.shippingDate = newDate;
        },
        comment(newComment) {
            this.shippingComment = newComment;
        }
    },

    methods: {
        setShippingDate(el) {
            if (el != null) {
                this.shippingDate = el;
            }
        },
        acceptChanges() {
            this.$emit('accepted', {
                shippingDate: this.shippingDate,
                shippingComment: this.shippingComment
            });
        }
    },

    computed: {
        dateString() {
            if (this.shippingDate === 'Invalid date') {
                return ''
            }

            const weekBegin = moment(this.shippingDate, 'YYYY-MM-DD').startOf('week')
            const weekEnd = moment(this.shippingDate, 'YYYY-MM-DD').endOf('week')

            return `${weekBegin.format(String(this.$t('common.date.format.week_range')))} - ${weekEnd.format(String(this.$t('common.date.format.week_range')))}`
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        top: 25vh;
        width: 620px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        @media only screen and (max-width: 600px) {
            width: 95%;
        }

        &-body {
            padding-top: 32px;

            .message-text {
                line-height: 1.2;
            }

            .button-container {
                margin-top: 25px;

                button {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }
}


.modal--shipping-header{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
    color: #e63b12;
}

.modal--shipping-date{
    font-size: 25px;
    font-weight: bold;
    display: block;
    margin-bottom: 15px;
}

.modal--shipping-text{
    margin-bottom: 15px;
    color: #e63b12;
    font-weight: bold;
}
</style>

<style lang="scss">

.custom--shipping-date{
    width: 100%;
    margin-bottom: 15px!important;
    .datepicker-component{
        width: 100%!important;
        .input-date{
            width: 100%!important;
        }
    }

}

</style>
