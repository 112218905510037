<template>
    <div class="sidebar-filter-component">
        <ul>
            <li
                v-for="(element, index) in elements"
                :key="index"
                :class="{'has-child' : ( element.handle === null ), 'active' : selected === element.handle, 'active-child open' : element.handle === null && isChildActive(element.children) }"
            >
                <button type="button" @click="applyFilter(element.handle)">{{element.name}}</button>

                <ul v-if="element.handle === null">
                    <li
                        v-for="(elem, ind) in element.children"
                        :key="ind"
                        :class="{'active' : selected === elem.handle}"
                    >
                        <button type="button" @click="applyFilter(elem.handle)">{{elem.name}}</button>
                        <span v-if="elem.count" class="number-badge">{{elem.count}}</span>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'SidebarFilter',
    props: ['elements', 'selected'],

    data() {
        return {}
    },

    mounted() {
        let primaryEls = document.querySelectorAll('.has-child > button')

        primaryEls.forEach(el => {
            el.addEventListener('click', () => {
                el.parentElement.classList.toggle('open')
            })
        })
    },

    methods: {
        applyFilter(el) {
            if (el !== this.selected && el !== null) {
                this.$emit('changed', el)
            }
        },

        isChildActive(list) {
            let isActive = false
            list.forEach(el => {
                if (el.handle === this.selected) {
                    isActive = true
                }
            })

            return isActive
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.sidebar-filter-component {
    //float: left;
    width: 100%;
    margin-top: 20px;

    button {
        position: relative;
        display: block;
    }

    ul,
    li {
        list-style-type: none;
        position: relative;
    }

    .number-badge {
        position: absolute;
        right: 0;
        top: -5px;
        right: 15px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $color-black-1;
        color: $color-white;
        padding-top: 6px;
        text-align: center;
        font-size: 13px;
    }

    ul,
    li {
        list-style-type: none;
        position: relative;
    }

    & > ul > li {
        position: relative;
        overflow: hidden;

        & > button {
            color: $color-gray-2;
            font-size: 14px;
            padding-left: 25px;
            margin: 7px 0;
            width: calc(100% - 25px);
            text-align: left;
            z-index: 2;
        }

        &.active {
            & > button {
                font-weight: bold;
                color: $color-black-1;

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    top: 0;
                    left: 0;
                    background: $color-red-1;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }

        &.active-child {
            & > button {
                font-weight: bold;
            }
        }
    }

    & > ul > li > ul {
        overflow: hidden;
        transition: max-height 0.3s ease-out;
    }

    & > ul > li > ul > li {
        position: relative;

        & > button {
            font-size: 13px;
            padding-left: 50px;
            margin: 8px 0;
            color: $color-gray-2;
            text-decoration: underline;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }

        &:first-child {
            button {
                margin-top: 5px;
            }
        }

        &:last-child {
            button {
                margin-bottom: 14px;
            }
        }

        &.active {
            & > button {
                font-weight: bold;
                text-decoration: none;

                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 3px;
                    top: 0;
                    left: 0;
                    background: $color-red-1;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }
}
</style>
