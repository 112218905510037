import { render, staticRenderFns } from "./ModalOfferSelectPerson.vue?vue&type=template&id=2f31f776&scoped=true"
import script from "./ModalOfferSelectPerson.vue?vue&type=script&lang=js"
export * from "./ModalOfferSelectPerson.vue?vue&type=script&lang=js"
import style0 from "./ModalOfferSelectPerson.vue?vue&type=style&index=0&id=2f31f776&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f31f776",
  null
  
)

export default component.exports