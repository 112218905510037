<template>
    <div class="comments-bar-component" :class="[orderSettings.comments.open ? 'active' : '', viewOnly ? 'disabled': '']">
        <div class="settings-sidebar-layer" @click="closeBar"></div>
        <div class="settings-sidebar">
            <div class="settings-sidebar-head">
                <img src="@/assets/svg/comment.svg">
                <span class="name">{{ $t('offer.page.edit.category.sidebars.comments.title') }}</span>
                <button type="button" @click="closeBar">
                    <md-icon>keyboard_arrow_right</md-icon>
                </button>
            </div>

            <div class="settings-sidebar-body" v-if="!loading">
                <div
                    class="comment-element"
                    v-for="(comment, commentIndex) in comments"
                    :key="commentIndex"
                >
                    <div class="label-row">
                        <span class="label">{{ $t('offer.page.edit.category.sidebars.comments.field.comment.label') }}</span>
                        <button
                            class="btn btn-plain"
                            @click="selectComment(commentIndex)"
                        >{{ $t('offer.page.edit.category.sidebars.comments.button.templates', {number_of_comments: savedComments.personal.length}) }}</button>
                    </div>
                    <textarea :placeholder="commentPlaceholder" v-model="comment.text"></textarea>
                    <div class="action-row">
                        <button @click="addToSavedComments(comment.text)" class="btn btn-secondary">
                            <md-icon>save</md-icon>{{ $t('offer.page.edit.category.sidebars.comments.button.save_as_template') }}
                        </button>
                        <button
                            @click="removeComment(commentIndex)"
                            v-show="comments.length > 1"
                            class="btn btn-plain btn-plain-wicon"
                        >
                            <md-icon>delete</md-icon>
                            <span>{{ $t('offer.page.edit.category.sidebars.comments.button.delete') }}</span>
                        </button>
                    </div>
                </div>

                <button class="btn btn-plain btn-plain-wicon btn-add-new" @click="addComment">
                    <md-icon>add_circle</md-icon>
                    <span>{{ $t('offer.page.edit.category.sidebars.comments.button.add_new') }}</span>
                </button>

                <div class="warrantee-container" v-if="localSystemWarrantee !== null">
                    <span class="text-info text-info-bold">{{ $t('offer.page.edit.category.sidebars.comments.text.system_warranty.title') }}</span>
                    <div class="checkbox">
                        <input
                            type="checkbox"
                            id="warrantee-1"
                            v-model="localSystemWarrantee"
                        >
                        <label for="warrantee-1">{{ $t('offer.page.edit.category.sidebars.comments.field.system_warranty.label') }}</label>
                    </div>
                    <span class="text-info">{{ $t('offer.page.edit.category.sidebars.comments.text.system_warranty.info') }}</span>
                </div>
            </div>
        </div>

        <modal-saved-comments></modal-saved-comments>
        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import ModalSavedComments from '../../components/modals/ModalSavedComments.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'CommentsBar',
    props: ['loading'],

    data() {
        return {
            comments: [
                {
                    text: ''
                }
            ],
            localSystemWarrantee: null
        }
    },

    watch: {
        'getComments': function(val) {
            this.comments = val
        },

        'systemWarrantee': function(val) {
            this.localSystemWarrantee = val
        },

        'loading': {
            handler: function(val) {
                if (!val) {
                    this.$store.commit('saveComments', this.comments)
                    this.$store.commit('saveSystemWarrantee', this.localSystemWarrantee)
                }
            }
        },

        'orderSettings.comments.open': {
            handler: function(val) {
                if (!val) {
                    this.$store.commit('saveComments', this.comments)
                    this.$store.commit('saveSystemWarrantee', this.localSystemWarrantee)
                }
            }
        }
    },

    mounted() {
        this.comments = this.getComments
        this.$store.dispatch('FETCH_SAVED_COMMENTS').then(() => {
            this.localSystemWarrantee = this.systemWarrantee
        })
    },

    methods: {
        closeBar() {
            this.$store.commit('closeSettingsSidebar', 'comments')
        },

        addComment() {
            this.comments.push({ text: '' })
        },

        removeComment(index) {
            this.comments.splice(index, 1)
        },

        selectComment(index) {
            this.$store.commit('saveComments', this.comments)
            this.$store.commit('setTargetComment', index)
            this.$store.commit('openModal', 'comments')
        },

        addToSavedComments(text) {
            if (text.length > 0) {
                let message = {
                    type: 'personal',
                    text: text
                }
                this.$store.commit('addToSavedComments', message)
            }
        },
    },

    computed: {
        commentPlaceholder() {
            return this.$t('offer.page.edit.category.sidebars.comments.text.placeholder')
        },

        ...mapGetters(['orderSettings', 'getComments', 'systemWarrantee', 'modals', 'orderData', 'savedComments', 'viewOnly'])
    },

    components: { ModalSavedComments, Notification }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/settings-sidebar.scss';

.comments-bar-component {
    .settings-sidebar-head {
        img {
            position: relative;
            top: 7px;
        }
    }

    .settings-sidebar-body {
        .comment-element {
            margin-top: 15px;
            margin-bottom: 15px;
            padding-bottom: 22px;
            border-bottom: 1px solid $color-gray-border;

            .label-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 3px;

                .label {
                    font-size: 14px;
                }

                .btn {
                    color: $color-blue-1;
                    text-decoration: none;
                }
            }

            .action-row {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;

                .btn {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .btn-secondary {
                    padding: 10px 18px;
                }
            }
        }

        .btn-add-new {
            i {
                font-size: 18px !important;
            }
        }

        .warrantee-container {
            border-top: 1px solid $color-gray-border;
            margin-top: 30px;
            padding-top: 24px;

            .checkbox {
                display: block;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            .text-info-bold {
                color: $color-gray-1;
            }
        }
    }
}
</style>
