<template>
    <div class="select-group-component" v-click-outside="onClickOutside">
        <input
            ref="input"
            type="text"
            v-model="selectedItem.name"
            readonly="readonly"
            autocomplete="off"
            @click="open=!open"
            :placeholder="this.$t('common.select.choice')"
            :class="{'selected' : selectedItem.category }"
            :disabled="disabled"
        >
        <span class="category-name">{{ selectedItem.category }}</span>
        <md-icon>list</md-icon>
        <ul v-show="open" class="dropdown-list" :style="modalStyle">
            <li v-for="(option, index) in options" :key="index">
                <ul>
                    <li class="group-name">{{ option.name }}</li>
                    <li
                        v-for="opt in option.options"
                        :key="opt.id"
                        :class="{ 'selected': selectedItem.id == opt.id }"
                        @click="selectOption(opt, option.name)"
                    >{{ opt.name }}</li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'CustomGroupSelect',
    props: ['options', 'selectedItem', 'disabled', 'modal'],

    data() {
        return {
            open: false,
            boundingClientRect: null,
            maxHeight: null,
            modalElement: null
        }
    },

    mounted() {
        this.modalElement = null;
        if(this.modal) {
            this.modalElement = this.$el.closest('.modal-box-body');
            if(this.modalElement) {
                window.addEventListener("resize", this.setBoundingClientRect, false);
                this.modalElement.addEventListener("scroll", this.setBoundingClientRect, false);
            }
        }
    },

    destroyed() {
        if(this.modalElement) {
            window.removeEventListener("resize", this.setBoundingClientRect);
            this.modalElement.removeEventListener("scroll", this.setBoundingClientRect);
        }
    },

    watch: {
        open() {
            this.setBoundingClientRect()
        }
    },

    methods: {
        setBoundingClientRect() {
            if(this.open && this.modalElement) {
                this.boundingClientRect = this.$refs.input.getBoundingClientRect()
                this.maxHeight = Math.min(window.innerHeight * 0.55, window.innerHeight - this.boundingClientRect.top - this.boundingClientRect.height - 5)
            }
        },

        selectOption(selected, groupName) {
            this.open = false

            if (selected.id !== this.selectedItem.id) {
                selected.category = groupName
                this.$emit('changed', selected)
            }
        },

        onClickOutside() {
            if (this.open) {
                this.open = false
            }
        }
    },

    computed: {
        modalStyle() {
            if(!this.boundingClientRect) {
                return {};
            }

            return {
                position: 'fixed',
                left: `${this.boundingClientRect.left}px`,
                top: `${this.boundingClientRect.top + this.boundingClientRect.height}px`,
                maxHeight: `${this.maxHeight}px`,
                width: `${this.boundingClientRect.right - this.boundingClientRect.left}px`,
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
.select-group-component {
    position: relative;
    width: 100%;
    background: $color-white;

    input {
        position: relative;
        color: $color-gray-1;
        font-weight: bold;
        width: 100%;
        padding: 0 35px 0 12px;
        font-size: 13px;
        line-height: 22px;
        height: 50px;
        border-radius: 2px;
        border: 1px solid $color-gray-border;
        cursor: pointer;
        z-index: 2;
        background: transparent;

        &.selected {
            padding-top: 14px;
        }

        &:disabled {
            background: #DDD;
            cursor: not-allowed;
        }
    }

    i {
        position: absolute;
        background: $color-white;
        top: 16px;
        right: 10px;
    }

    .category-name {
        position: absolute;
        font-weight: bold;
        top: 11px;
        left: 13px;
        font-size: 10px;
        color: $color-gray-5;
        text-transform: uppercase;
        z-index: 5;
    }

    .dropdown-list {
        top: 50px;

        ul {
            cursor: default;
        }

        & > li {
            padding: 0;

            .group-name {
                font-weight: bold;
                font-size: 10px;
                text-transform: uppercase;
                color: $color-gray-5;
                padding-top: 8px;
                padding-bottom: 4px;
                pointer-events: none;
                text-decoration: underline;
                background: $color-gray-bg-2;
            }

            li {
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    opacity: 0;
                    transition: opacity 0.5s;
                    background: $color-red-1;
                }

                &.selected {
                    background: $color-white;

                    &:before {
                        opacity: 1;
                        transition: opacity 0.5s;
                    }
                }
            }
        }
    }
}
</style>
