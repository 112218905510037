<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('project.select.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain">{{ $t('project.select.button.close') }}</button>
            </div>

            <div class="modal-box-body">
                <div class="autocomplete-container">
                    <label class="text-input-label" for="project-name-select">{{ $t('project.select.field.project.label') }}</label>

                    <input
                        @keyup.enter="createNewProject"
                        @input="searchProjects"
                        v-model="projectName"
                        ref="searchInput"
                        id="project-name-select"
                        type="text"
                        autocomplete="off"
                        :placeholder="this.$t('project.select.text.placeholder')"
                        v-autofocus
                    >

                    <ul v-show="projectName" class="dropdown-list">
                        <li @click="createNewProject()">
                            <strong>{{projectName}}</strong>
                            <button class="btn btn-plain" type="button">{{ $t('project.select.button.create') }}</button>
                        </li>

                        <li
                            @click="selectProject(result)"
                            v-for="result in this.results"
                            :key="result.id"
                        >
                            <span class="result-name">
                                <span v-html="result.styledName"></span>
                                <span class="address" v-html="result.styledAddress"></span>
                            </span>
                            <button class="btn btn-plain" type="button">{{ $t('project.select.button.select') }}</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'

import { AutoFocus } from '@/plugins/directives'
import axios from 'axios'
import config from '../../plugins/config.js'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectSelect',
    props: ['open', 'projectData'],

    data() {
        return {
            projectName: '',
            cancelToken: null,
            timer: null,
            results: []
        }
    },

    watch: {
        open: function() {
            this.projectName = ''
            // if (this.projectData != null) {
            //     this.projectName = this.projectData.name
            //     this.searchProjects()
            // }
        }
    },

    methods: {
        searchProjects() {
            if(this.cancelToken) {
                this.cancelToken.cancel()
            }

            if(this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }

            this.results = []

            this.timer = setTimeout(() => {
                this.timer = null
                this.cancelToken = axios.CancelToken.source()
                axios.get(`projects?search=${encodeURIComponent(this.projectName)}`, {...config.AXIOS_DEFAULTS, cancelToken: this.cancelToken.token}).then((data) => {
                    if(data !== null) {
                        this.results = data
                    }
                }).finally(() => {
                    this.cancelToken = null
                })
            }, 100)
        },

        createNewProject() {
            this.$store.dispatch('FETCH_NEW_PROJECT_DATA').then(data => {
                this.$emit('selected', {
                    type: 'new',
                    projectData: {...data, name: this.projectName}
                })
            })
        },

        selectProject(data) {
            let message = {
                type: 'available',
                projectData: Object.assign({}, data)
            }

            this.$emit('selected', message)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow-y: visible;

            input,
            .input-label {
                width: 100%;
            }

            .autocomplete-container {
                position: relative;
            }

            .dropdown-list {
                top: 76px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 24px;

                    button {
                        text-decoration: none;
                    }

                    .address {
                        display: block;
                        color: $color-gray-2;
                        font-size: 10px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}
</style>
