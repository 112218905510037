const KEEP_LOGGED_IN_DEFAULT = String(process.env.VUE_APP_KEEP_LOGGED_IN_DEFAULT) === 'true' || false
const NEED_LOGIN_FOR_PRODUCT_ADMIN = typeof process.env.VUE_APP_NEED_LOGIN_FOR_PRODUCT_ADMIN === 'undefined' ? true : String(process.env.VUE_APP_NEED_LOGIN_FOR_PRODUCT_ADMIN) === 'true'
const SHOW_OFFER_TEASER = String(process.env.VUE_APP_SHOW_OFFER_TEASER) === 'true' || false
const SHOW_ORDER_TEASER = String(process.env.VUE_APP_SHOW_ORDER_TEASER) === 'true' || false

let config = {
    LOCALE: process.env.VUE_APP_LOCALE,
    I18N_LOCALE: process.env.VUE_APP_LOCALE.substr(0, 2),
    MOMENT_LOCALE: process.env.VUE_APP_LOCALE,
    SUPPORT_EMAIL: process.env.VUE_APP_SUPPORT_EMAIL,
    KEEP_LOGGED_IN_DEFAULT,
    NEED_LOGIN_FOR_PRODUCT_ADMIN,
    SHOW_OFFER_TEASER,
    SHOW_ORDER_TEASER,
    GTC_URL: process.env.VUE_APP_GTC_URL,
    API_URL: process.env.VUE_APP_API_URL,
    API_HEADERS: {
        'Accept': 'application/json',
        'Accept-Language': process.env.VUE_APP_LOCALE,
        'Content-Type': 'application/json'
    }
}

config.AXIOS_DEFAULTS = {
    baseURL: config.API_URL,
    headers: config.API_HEADERS,
    // httpsAgent: new https.Agent({ rejectUnauthorized: false })
}

export default config
