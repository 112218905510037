<template>
    <div class="progressbar-component">
        <ul>
            <li
                v-for="(element, elementIndex) in elements"
                :key="elementIndex"
                :class="{'active' : element.done, 'active-last' : isLastActive(element, elementIndex) }"
                :style="{width: 100 / elements.length + '%'}"
            >
                <span class="circle">
                    <md-icon>panorama_fish_eye</md-icon>
                    <md-icon v-if="element.done" class="icon-done">check</md-icon>
                    <span v-if="element.done" class="icon-done-bg"></span>
                </span>
                <span class="text">{{ element.label }}</span>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ProgressBar',
    props: ['elements'],

    data() {
        return {}
    },

    methods: {
        isLastActive(el, index) {
            if (el.done && typeof this.elements[index + 1] === 'undefined') {
                return true
            }
            return (
                el.done &&
                typeof this.elements[index + 1] !== 'undefined' &&
                !this.elements[index + 1].done
            )
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';

.progressbar-component {
    width: 110%;
    margin-left: -5%;
    overflow: hidden;

    ul,
    li {
        list-style-type: none;
    }

    ul {
        width: 100%;
    }

    li {
        position: relative;
        float: left;
        display: block;

        span {
            position: relative;
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 auto;
            color: $color-gray-5;
            z-index: 2;

            &.circle {
                position: relative;
                background: $color-gray-border;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                padding-top: 7px;

                i {
                    color: $color-white;
                    font-size: 21px !important;
                }

                .icon-done {
                    position: absolute;
                    font-size: 16px !important;
                    left: 8px;
                    top: 5px;
                    z-index: 5;
                    font-weight: 600;
                }

                .icon-done-bg {
                    position: absolute;
                    content: '';
                    width: 8px;
                    height: 12px;
                    top: 7px;
                    right: 6px;
                    background: $color-green-1;
                    z-index: 1;
                }
            }

            &.text {
                font-size: 15px;
                margin-top: 7px;
            }
        }

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: 50%;
            height: 7px;
            top: 14px;
            left: 0;
            background: $color-gray-border;
        }

        &:after {
            left: 50%;
        }

        &.active {
            &:before,
            &:after {
                background: $color-green-1;
            }

            span {
                &.circle {
                    background: $color-green-1;
                }
            }
        }

        &.active-last {
            &:after {
                background: $color-gray-border;
            }

            span {
                font-weight: bold;
            }
        }

        &:first-child {
            &:before {
                display: none;
            }
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
</style>
