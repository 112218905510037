<template>
    <div class="modal is-open">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.tags.select.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <div class="alert alert--danger" v-if="validation.length">{{ validation }}</div>

                <div class="modal-row">
                    <label class="text-input-label">{{ $t('offer.tags.select.field.tag.label') }}</label>

                    <ul class="color-select">
                        <li v-for="(tag, tagIndex) in compTags" :key="tagIndex" :class="tagClass(tag)">
                            <span @click="selectTag(tag)">{{ tag.title }}</span>
                            <ul style="margin-left: 15px; margin-top: 5px;" class="color-select"
                                v-if="isSelected(tag) && profile.canSelectChildTags">
                                <li v-for="(childTag, childIndex) in tag.children" :key="childIndex"
                                    :class="tagClass(childTag)">
                                    <span @click="selectTag(childTag)">{{ childTag.title }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="modal-row">
                    <button type="button" class="btn btn-primary" @click="validate()">
                        {{ $t('offer.tags.select.button.save') }}
                    </button>
                    <button type="button" class="btn btn-secondary button-cancel" @click="$emit('close')">
                        {{ $t('offer.tags.select.button.cancel') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ModalTagSelect',
    props: ['open', 'offerData'],

    data() {
        return {
            selectedTags: [],
            competitorTagId: 138
        };
    },

    mounted() {
        this.selectedTags = this.offerData.tags.map(row => row.id);
    },

    methods: {
        selectTag(tag) {
            const { id, title } = tag;
            const isMultipleSelectionAllowed = this.selectedTags.includes(this.competitorTagId);
            const isAlreadySelected = this.selectedTags.includes(id);

            if (isMultipleSelectionAllowed) {
                if (isAlreadySelected) {
                    this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== id);
                } else if ((id === this.competitorTagId || this.isChildTagOf(tag, this.competitorTagId)) && this.selectedTags.length === 1) {
                    this.selectedTags.push(id);
                }
            } else {
                this.selectedTags = isAlreadySelected ? [] : [id];
            }

            if (!isAlreadySelected) {
                this.unselectChildTags(tag);
            }
        },

        isChildTagOf(tag, parentId) {
            if (tag.parent === parentId) {
                return true;
            }
            if (tag.parent) {
                const parentTag = this.tags.find(t => t.id === tag.parent);
                return this.isChildTagOf(parentTag, parentId);
            }
            return false;
        },

        unselectChildTags(tag) {
            if (tag.children && tag.children.length > 0) {
                tag.children.forEach(childTag => {
                    const childId = childTag.id;
                    if (this.selectedTags.includes(childId)) {
                        this.selectedTags = this.selectedTags.filter(selectedTag => selectedTag !== childId);
                    }
                });
            }
        },

        validate() {
            this.$store.dispatch('UPDATE_OFFER_TAGS', {
                id: this.offerData.id,
                tags: this.selectedTags,
                page: this.offerData.page,
                inList: true
            }).then(() => {
                this.selectedTags = [];
                this.error = '';
                this.$emit('close');
            });
        },

        isSelected(tag) {
            if (this.selectedTags.includes(tag.id)) {
                return true;
            }
            if (this.selectedTags.includes(tag.parent) && tag.children && tag.children.length > 0) {
                return tag.children.some(childTag => this.selectedTags.includes(childTag.id));
            }
            return false;
        },

        tagClass(tag) {
            return [
                `tag-${tag.color}`,
                { 'is-selected': this.isSelected(tag) },
                { 'is-inactive': this.offerData.tags && this.offerData.tags.indexOf(tag.id) !== -1 }
            ];
        }
    },

    computed: {
        ...mapGetters(['tags', 'profile', 'validation']),

        compTags() {
            return this.tags.map(tag => {
                if (tag.children && tag.children.length > 0) {
                    return {
                        ...tag,
                        children: tag.children.map(childTag => ({
                            ...childTag,
                            parent: tag.id
                        }))
                    };
                }
                return tag;
            });
        }
    }
};

</script>
<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';
@import '../../assets/sass/ui/color-select.scss';

.alert {
    margin-top: 1em;
    margin-bottom: 1em;
}

.modal-row {
    display: block;
    margin-bottom: 1em;

    &:last-child {
        margin-top: 35px;
        margin-bottom: 0;
    }

    label {
        display: block;
        margin-bottom: .5em;
    }

    input {
        width: 100%;
    }
}

.button-cancel {
    margin-left: 10px;
}
</style>
