<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>keyboard_arrow_left</md-icon></button>
                <span class="title">{{ $t('object.add_person.title', {type: selectedPersonType}) }}</span>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper">
                    <label class="text-input-label" for="name-add-person">{{ $t('object.add_person.field.name') }}</label>
                    <input type="text" v-model="person.name" id="name-add-person">
                </div>
                <div class="input-wrapper">
                    <label class="text-input-label" for="address-add-person">{{ $t('object.add_person.field.address') }}</label>
                    <input type="text" v-model="person.address" id="address-add-person">
                </div>
                <button class="btn btn-primary button-save" type="button" @click="addPerson">{{ $t('object.add_person.button.add') }}</button>
                <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('object.add_person.button.back') }}</button>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalObjectAddPerson',
    props: ['open', 'personType'],

    data() {
        return {
            person: {
                name: '',
                address: ''
            }
        }
    },
    watch: {
        'open': function() {
            this.person = {
                name: '',
                address: ''
            }
        },
    },

    computed: {
        selectedPersonType () {
            switch (this.personType) {
            case 'planner':
                return this.$t('object.add_person.types.planner')
            case 'implementer':
                return this.$t('object.add_person.types.implementer')
            case 'roofCover':
                return this.$t('object.add_person.types.roof_cover')
            default:
                return this.$t('object.add_person.types.investor')
            }
        }
    },

    methods: {
        addPerson() {
            this.$emit('addPerson', this.person)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            button {
                float: left;
            }
        }

        &-body {
            overflow: visible;

            input {
                width: 100%;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
