<template>
    <div class="auth-form">
        <div class="auth-form__title">{{ $t('login.page.text.title') }}</div>

        <div class="alert alert--danger" v-if="validation.message">{{ validation.message }}</div>

        <div class="auth-form__password_manager_fix">
            <div class="auth-form__row">
                <label for="l_login_sap">{{ $t('login.page.field.sap.label') }}</label>
                <input
                    v-model="sap"
                    type="text"
                    name="sap"
                    autocomplete="chrome-off"
                    tabindex="2"
                    id="l_login_sap"
                    :class="{ 'has-error' : hasError('sap') }"
                    @keyup.enter="validate()">

                <span class="auth-form__error" v-if="hasError('sap')">{{ validation.fields.sap }}</span>
            </div>
            <div class="auth-form__row">
                <label for="l_login_email">{{ $t('login.page.field.email.label') }}</label>
                <input
                    type="text"
                    name="email"
                    v-model="email"
                    autocomplete="email"
                    tabindex="1"
                    id="l_login_email"
                    :class="{ 'has-error' : hasError('email') }"
                    @keyup.enter="validate()">

                <span class="auth-form__error" v-if="hasError('email')">{{ validation.fields.email }}</span>
            </div>
        </div>

        <div class="auth-form__row">
            <label for="l_login_password">{{ $t('login.page.field.password.label') }}</label>
            <input
                type="password"
                ref="passwordField"
                name="password"
                v-model="password"
                autocomplete="current-password"
                tabindex="3"
                id="l_login_password"
                :class="{ 'has-error' : hasError('password') }"
                @keyup.enter="validate()">

            <span class="password-reveal" v-if="!passwordRevealed" @click="togglePassword()"><md-icon>visibility</md-icon> {{ $t('common.password.show') }}</span>
            <span class="password-reveal" v-else @click="togglePassword()"><md-icon>visibility_off</md-icon> {{ $t('common.password.hide') }}</span>

            <span class="auth-form__error" v-if="hasError('password')">{{ validation.fields.password }}</span>
        </div>

        <div class="auth-form__checkbox_row">
            <div class="checkbox">
                <input type="checkbox" :value="true" id="l_login_remember" v-model="remember">
                <label for="l_login_remember">{{ $t('login.page.field.keep_logged_in.label') }}</label>
            </div>
        </div>

        <div class="auth-form__submit">
            <button type="button" class="btn btn-red" @click="validate()">{{ $t('login.page.button.login') }}</button>
            <p>
                <a @click="$emit('switchToForgot')">{{ $t('login.page.button.forgot_your_password')}}</a>
            </p>
        </div>

        <notification></notification>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Notification from '../Notification.vue'
import config from '../../plugins/config.js'

export default {
    name: 'Login',

    data() {
        return {
            loading: true,
            errors: [],
            passwordRevealed: false,
            email: '',
            sap: '',
            password: '',
            remember: config.KEEP_LOGGED_IN_DEFAULT
        }
    },

    mounted() {
        if(this.loginData) {
            this.email = this.loginData.email
            this.sap = this.loginData.sap
        }

        setTimeout(() => {
            this.loading = false
        }, 500)
    },

    methods: {
        hasError(field) {
            return !!(this.validation.fields && this.validation.fields.hasOwnProperty(field));
        },

        togglePassword() {
            this.passwordRevealed = !this.passwordRevealed
            this.$refs.passwordField.type = this.passwordRevealed ? 'text' : 'password'
        },

        validate() {
            this.loading = true

            this.$store.dispatch('VALIDATE_LOGIN', {
                remember: this.remember,
                payload: {
                    email: this.email,
                    sap: this.sap,
                    password: this.password
                },
            }).then((mfa) => {
                if (mfa) {
                    this.$emit('switchToVerification')
                } else {
                    this.$store.commit('clearLoginData')
                }
                setTimeout(() => {
                    this.loading = false
                }, 500)
            }).catch(response => {
                this.$store.commit('addValidation', [response.error, response.errors])
            })
        }
    },

    computed: {
        ...mapGetters(['validation', 'loginData'])
    },

    components: {Notification}
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/loading.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/partials/auth-form.scss';
</style>
