<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title" v-if="offerInformation.number">{{ $t('offer.edit.title.with_number', {number: offerInformation.number}) }}</span>
                <span class="title" v-else>{{ $t('offer.edit.title.without_number') }}</span>
                <button type="button" @click="$emit('close', offerInformation.name)" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <tabs v-if="routeName !== 'Order' && routeName !== 'NewOrder'" :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el}"></tabs>
                <div class="offer-data-container" v-show="tabValue === 'offerData'" v-if="offerInformation">
                    <div class="input-wrapper input-wrapper-offer-name">
                        <label class="text-input-label" for="offer-name">{{ $t('offer.edit.data.field.name.label') }}</label>
                        <input v-model="offerInformation.name" :disabled="viewOnly" @keypress="offerNameKeyPress" id="offer-name" type="text">
                    </div>
                    <div class="input-wrapper input-wrapper-date">
                        <span class="text-input-label">{{ $t('offer.edit.data.field.valid_to.label') }}</span>
                        <datepicker :initDate="offerInformation.validTo" :disabled="viewOnly" @changed="saveValidToDate"></datepicker>
                    </div>
                    <div class="data-row" v-if="offerInformation.ownerUser">
                        <span class="label">{{ $t('offer.edit.data.field.owner.label') }}</span>
                        <div class="name">
                            <span class="text"> {{offerInformation.ownerUser.name}} </span>

                            <div class="button-container"></div>

                            <template v-if="permissions && permissions.canEditor.owner && offerInformation.ownerUser.viewTypes">
                                <template v-if="offerInformation.ownerUser.viewTypes.off === offerInformation.ownerUser.viewTypes.on">
                                    <label class="owner-switcher owner-switcher--standalone" :class="{'selected' : editorUserId === offerInformation.ownerUser.id}">
                                        <input type="radio" v-model="editorUserId" :value="offerInformation.ownerUser.id" @change="saveEditor(offerInformation.ownerUser)">
                                        <md-icon>{{getIcon(offerInformation.ownerUser.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>
                                <template v-else>
                                    <div class="owner-info-icons" :class="{'selected' : editorUserId !== offerInformation.ownerUser.id}" >
                                        <md-icon>{{getIcon(offerInformation.ownerUser.viewTypes.off)}}</md-icon>
                                    </div>
                                    <label class="owner-switcher" :class="{'selected' : editorUserId === offerInformation.ownerUser.id}">
                                        <input type="radio" v-model="editorUserId" :value="offerInformation.ownerUser.id" @change="saveEditor(offerInformation.ownerUser)">
                                        <md-icon>{{getIcon(offerInformation.ownerUser.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>
                            </template>

                            <template v-else>
                                <div class="owner-info-icons disabled">
                                    <md-icon>{{getIcon(offerInformation.ownerUser.viewType)}}</md-icon>
                                </div>
                            </template>

                        </div>
                    </div>
                    <div class="data-row">
                        <span class="label">{{ $t('offer.edit.data.field.advisory.label') }}</span>
                        <div class="name">
                            <template v-if="offerInformation.advisoryUser">

                                <span class="text"> {{offerInformation.advisoryUser.name}} </span>

                                <div class="button-container">
                                    <button v-if="permissions && permissions.canRemoveAdvisory" type="button" @click="removeAdvisory()">
                                        <md-icon>close</md-icon>
                                    </button>
                                </div>

                                <template v-if="permissions && permissions.canEditor.advisory && offerInformation.advisoryUser.viewTypes">
                                    <div class="owner-info-icons" :class="{'selected' : editorUserId !== offerInformation.advisoryUser.id}" >
                                        <md-icon>{{getIcon(offerInformation.advisoryUser.viewTypes.off)}}</md-icon>
                                    </div>
                                    <label class="owner-switcher" :class="{'selected' : editorUserId === offerInformation.advisoryUser.id}" v-if="offerInformation.advisoryUser">
                                        <input type="radio" v-model="editorUserId" :value="offerInformation.advisoryUser.id" @change="saveEditor(offerInformation.advisoryUser)">
                                        <md-icon>{{getIcon(offerInformation.advisoryUser.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>

                                <template v-else-if="offerInformation.advisoryUser.viewType">
                                    <div class="owner-info-icons disabled">
                                        <md-icon>{{getIcon(offerInformation.advisoryUser.viewType)}}</md-icon>
                                    </div>
                                </template>
                            </template>
                        </div>

                        <div class="other" v-if="permissions && permissions.canChangeAdvisory">
                            <button
                                @click="selectedPersonType = 'advisory'; openPersonSelect = true"
                                type="button"
                                class="btn btn-plain">{{ $t('offer.edit.button.modify') }}
                            </button>
                        </div>
                    </div>
                    <div class="data-row">
                        <span class="label">{{ $t('offer.edit.data.field.dealers.label') }}</span>
                        <div class="name">
                            <div v-if="offerInformation.dealerUsers && offerInformation.dealerUsers.length < 1" class="no-dealer">
                                <md-icon>account_circle</md-icon>
                                <div>{{ $t('offer.edit.data.text.no_dealer') }}</div>
                            </div>
                            <div
                                v-for="(person, index) in offerInformation.dealerUsers"
                                :key="index"
                                class="element">

                                <span class="text">{{person.name}}</span>

                                <div class="button-container"></div>

                                <template v-if="permissions && permissions.canEditor.dealer && person.viewTypes">
                                    <div class="owner-info-icons" :class="{'selected' : editorUserId !== person.id}" >
                                        <md-icon>{{getIcon(person.viewTypes.off)}}</md-icon>
                                    </div>
                                    <label class="owner-switcher" :class="{'selected' : editorUserId === person.id}" v-if="person">
                                        <input type="radio" v-model="editorUserId" :value="person.id" @change="saveEditor(person)">
                                        <md-icon>{{getIcon(person.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>

                                <template v-else-if="person.viewType">
                                    <div class="owner-info-icons disabled">
                                        <md-icon>{{getIcon(person.viewType)}}</md-icon>
                                    </div>
                                </template>

                            </div>
                        </div>
                    </div>
                    <div class="data-row" v-if="offerInformation.workerUsers && offerInformation.workerUsers.length > 0">
                        <span class="label">{{ $t('offer.edit.data.field.workers.label') }}</span>
                        <div class="name">
                            <div
                                v-for="(person, index) in offerInformation.workerUsers"
                                :key="index"
                                class="element">

                                <span class="text">{{person.name}}</span>

                                <div class="button-container"></div>

                                <template v-if="permissions && permissions.canEditor.dealer && person.viewTypes">
                                    <div class="owner-info-icons" :class="{'selected' : editorUserId !== person.id}" >
                                        <md-icon>{{getIcon(person.viewTypes.off)}}</md-icon>
                                    </div>
                                    <label class="owner-switcher" :class="{'selected' : editorUserId === person.id}" v-if="person">
                                        <input type="radio" v-model="editorUserId" :value="person.id" @change="saveEditor(person)">
                                        <md-icon>{{getIcon(person.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>

                                <template v-else-if="person.viewType">
                                    <div class="owner-info-icons disabled">
                                        <md-icon>{{getIcon(person.viewType)}}</md-icon>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="data-row">
                        <span class="label">{{ $t('offer.edit.data.field.observers.label') }}</span>
                        <div class="name">
                            <div
                                v-for="(person, index) in offerInformation.observerUsers"
                                :key="index"
                                v-show="person.selected || (permissions && !permissions.canChangeObservers)"
                                class="element">

                                <span class="text">{{person.name}}</span>

                                <div class="button-container">
                                    <button
                                        v-if="(permissions && permissions.canChangeObservers) || person.currentUser"
                                        @click="removeObserver(person)"
                                        type="button"
                                        class="btn btn-plain btn-plain-wicon">
                                        <md-icon>close</md-icon>
                                    </button>
                                </div>

                                <template v-if="permissions && permissions.canEditor.observer && person.viewTypes">
                                    <div class="owner-info-icons" :class="{'selected' : editorUserId !== person.id}" >
                                        <md-icon>{{getIcon(person.viewTypes.off)}}</md-icon>
                                    </div>
                                    <label class="owner-switcher" :class="{'selected' : editorUserId === person.id}" v-if="person">
                                        <input type="radio" v-model="editorUserId" :value="person.id" @change="saveEditor(person)">
                                        <md-icon>{{getIcon(person.viewTypes.on)}}</md-icon>
                                    </label>
                                </template>

                                <template v-else-if="person.viewType">
                                    <div class="owner-info-icons disabled">
                                        <md-icon>{{getIcon(person.viewType)}}</md-icon>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="other">
                            <button
                                v-if="permissions && permissions.canChangeObservers"
                                @click="selectedPersonType = 'observer'; openPersonSelect = true"
                                type="button"
                                class="btn btn-plain">{{ $t('offer.edit.button.add') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="history-data-container" v-show="tabValue === 'offerData'" v-if="offerInformation && offerInformation.created && offerInformation.modified">
                    <div class="data-row" v-if="offerInformation.created">
                        <span class="label">{{ $t('offer.edit.data.text.created') }}</span>
                        <div class="name">{{offerInformation.created.name}}</div>
                        <div class="other">{{offerInformation.created.time}}</div>
                    </div>
                    <div class="data-row" v-if="offerInformation.modified">
                        <span class="label">{{ $t('offer.edit.data.text.modified') }}</span>
                        <div class="name">{{offerInformation.modified.name}}</div>
                        <div class="other">{{offerInformation.modified.time}}</div>
                    </div>
                </div>
                <div class="project-data-container" v-show="tabValue === 'projectData'">
                    <project-data-form :projData="projectData" :offerInformation="offerInformation" :isCopyProject="null" @close="$emit('close', offerInformation.name)"></project-data-form>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
        <modal-offer-select-person
            @close="openPersonSelect = false"
            @newPerson="addPerson"
            :open="openPersonSelect"
            :personType="selectedPersonType">
        </modal-offer-select-person>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import Tabs from '../../components/Tabs.vue'
import Datepicker from '../../components/dropdowns/Datepicker.vue'
import ModalOfferSelectPerson from '../../components/modals/ModalOfferSelectPerson.vue'
import ProjectDataForm from '../../components/ProjectDataForm.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalDataEdit',
    props: ['open', 'tabState'],

    data() {
        return {
            tabElements: [
                {
                    handle: 'offerData',
                    handleName: this.$t('offer.edit.data.title')
                },
                {
                    handle: 'projectData',
                    handleName: this.$t('offer.edit.project.title')
                }
            ],
            tabValue: 'offerData',
            openPersonSelect: false,
            selectedPersonType: 'observer',
            editorUserId: null,
            projectName: ''
        }
    },

    watch: {
        'open': {
            handler: function() {
                this.tabValue = this.tabState
            }
        },
        'offerInformation.editorUser': {
            handler: function(editorUser) {
                if(editorUser) {
                    this.editorUserId = editorUser.id
                }
            }
        }
    },

    methods: {
        addPerson(person) {
            if (this.selectedPersonType === 'observer') {
                this.$store.commit('setOfferInformationObserverUsers', this.offerInformation.observerUsers.map(el => {
                    if (el.id === person.id) {
                        return {...el, selected: true}
                    } else {
                        return {...el}
                    }
                }))
            } else if (this.selectedPersonType === 'advisory') {
                if (!this.offerInformation.advisoryUser || this.offerInformation.advisoryUser.id === this.editorUserId) {
                    this.$store.commit('setOfferInformationEditorUser', person)
                }
                this.$store.commit('setOfferInformationAdvisoryUser', person)
            }

            this.saveOfferData()
            this.openPersonSelect = false
        },

        removeAdvisory() {
            if (this.offerInformation.advisoryUser.id === this.editorUserId) {
                this.$store.commit('setOfferInformationEditorUser', this.offerInformation.ownerUser)
            }

            this.$store.commit('setOfferInformationAdvisoryUser', null)

            this.saveOfferData()
        },

        removeObserver(person) {
            if(this.permissions && this.permissions.canChangeObservers) {
                if (person.id === this.editorUserId) {
                    if (this.offerInformation.advisoryUser) {
                        this.$store.commit('setOfferInformationEditorUser', this.offerInformation.advisoryUser)
                    } else {
                        this.$store.commit('setOfferInformationEditorUser', this.offerInformation.ownerUser)
                    }
                }

                this.$store.commit('setOfferInformationObserverUsers', this.offerInformation.observerUsers.map(el => {
                    if (el.id === person.id) {
                        return {...el, selected: false}
                    } else {
                        return {...el}
                    }
                }))

            } else {
                this.$store.commit('setOfferInformationObserverUsers', this.offerInformation.observerUsers.map(el => {
                    return {...el, selected: el.id !== person.id}
                }))
            }

            this.saveOfferData()
        },

        saveEditor(editor) {
            this.$store.commit('setOfferInformationEditorUser', editor)
            this.saveOfferData()
        },

        saveValidToDate(date) {
            this.$store.commit('setOfferInformationValidTo', date)
            this.saveOfferData()
        },

        offerNameKeyPress(e) {
            // Enter
            if(e.keyCode === 13) {
                this.$store.commit('setOfferInformationName', this.offerInformation.name)
                this.saveOfferData().then(() => {
                    this.$emit('close', this.offerInformation.name)
                })
            }
        },

        saveOfferData() {
            if (this.offerInformation.id) {
                const message = {
                    offerId: this.offerInformation.id
                }
                return this.$store.dispatch('SEND_OFFER', message)
            } else {
                return Promise.resolve()
            }
        },

        getIcon(type) {
            if (type === 'edit') {
                return 'edit'
            } else if (type === 'lock') {
                return 'lock'
            } else if (type === 'eye') {
                return 'remove_red_eye'
            } else {
                return ''
            }
        }
    },

    computed: {
        ...mapGetters(['projectData', 'offerInformation', 'permissions', 'viewOnly']),

        routeName () {
            return this.$route.name
        },
    },

    components: { Tabs, Datepicker, ModalOfferSelectPerson, ProjectDataForm }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow-y: auto;
            padding: 0;

            .input-wrapper-date {
                .text-input-label {
                    font-size: 13px;
                }

                margin-top: 20px;
                padding-bottom: 15px;
                margin-bottom: 5px;
                border-bottom: 1px dashed $color-gray-border;
            }

            .input-wrapper-offer-name {
                label {
                    font-size: 13px;
                }

                input {
                    width: 100%;
                }
            }

            .tabs-component {
                padding-top: 20px;
                margin: 0 36px;
            }

            .offer-data-container,
            .project-data-container {
                padding: 20px 36px;
            }

            .history-data-container {
                background: $color-gray-bg-1;
                border-top: 1px solid $color-gray-border;
                padding: 5px 36px 10px;

                .data-row {
                    .name {
                        width: 32%
                    }

                    .other {
                        width: 32%;
                    }
                }
            }

            .data-row {
                padding: 10px 0;
                border-bottom: 1px dashed $color-gray-border;

                &:last-child {
                    border-bottom: 0;
                }

                .label,
                .name,
                .other {
                    display: inline-block;
                    font-size: 13px;
                    width: 32%;
                }

                .label {
                    float: left;
                    font-weight: 500;
                    color: $color-gray-5;
                }

                .name {
                    font-weight: 600;
                    font-size: 13px;
                    width: 47%;

                    .strong {
                        font-weight: 700;
                    }

                    .element {
                        display: block;
                        overflow: hidden;
                        margin-bottom: 7px;
                    }

                    i {
                        position: relative;
                        top: 2px;
                        font-size: 14px !important;
                    }

                    .text {
                        float: left;
                        display: block;
                        width: calc(100% - 125px);
                        line-height: 1.3;
                    }

                    .no-dealer {
                        color: $color-gray-3;
                        text-align: center;

                        i {
                            font-size: 22px !important;
                            color: $color-gray-3;
                            margin-bottom: 5px;
                        }
                    }

                    .button-container {
                        position: relative;
                        top: -5px;
                        display: inline-block;
                        width: 36px;
                    }
                }

                .other {
                    text-align: right;
                    width: 17%;
                }

                .owner-info-icons,
                .owner-switcher {
                    border: 1px solid $color-gray-border;
                    width: 26px;
                    height: 24px;

                    &--standalone {
                        margin-left: 47px !important;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        border-left: 1px solid #CBCBCB !important;
                    }

                    &.selected {
                        background: $color-red-1;

                        i {
                            color: $color-white;
                        }
                    }


                    &.disabled {
                        cursor: default;
                        background: $color-gray-bg-2;
                        opacity: 0.65;
                        border: 1px solid $color-gray-border;
                        border-radius: 4px;
                    }

                    i {
                        font-size: 16px !important;
                    }
                }

                .owner-info-icons {
                    display: inline-block;
                    margin-left: 20px;
                    border-right: 0;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .owner-switcher {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;
                    margin-left: -2px;
                    border-left: 0;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;

                    input {
                        position: absolute;
                        visibility: hidden;
                        height: 0;
                        width: 0;
                    }
                }
            }
        }
    }
}
</style>
