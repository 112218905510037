<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('project.offers.title') }}</span>
                <span class="project-name" v-if="projectData">{{projectData.name}}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>
            <div class="modal-box-body">
                <div class="table-list">
                    <vue-good-table
                        :columns="tableColumns"
                        :rows="projectOffers"
                        :row-style-class="rowClass"
                        @on-cell-click="onRowClick"
                        @on-row-mouseleave="onRowMouseleave">
                        <template slot="table-row" slot-scope="props">
                            <div class="col-name" v-if="props.column.field === 'number'">
                                <span class="large-line">{{ props.row.number }}</span>
                            </div>

                            <div class="col-contact" v-else-if="props.column.field === 'type'">
                                <template v-if="props.row.name">
                                    <span class="small-line">{{ props.row.type }}</span>
                                    <span class="large-line">{{ props.row.name }}</span>
                                </template>
                                <span class="large-line" v-else>{{ props.row.type }}</span>
                            </div>

                            <div class="col-ordered-number" v-else-if="props.column.field === 'orderedNumbers'">
                                <md-icon v-if="props.row.ordered && !props.row.orderDraft" title="Megrendelve" class="i-done">done</md-icon>
                                <img v-else-if="props.row.ordered && props.row.orderDraft" title="Megrendelés folyamatban" src="@/assets/svg/inprogress.svg">
                                <template v-else>
                                    <span class="small-line" v-for="(number, index) in props.row.orderedNumbers" :key="`offer-number-${index}`">
                                        {{ number }}
                                    </span>
                                </template>
                            </div>

                            <div class="col-advisory" v-else-if="props.column.field === 'advisoryUser'">
                                <span class="large-line">{{ props.row.advisoryUser }}</span>
                            </div>

                            <div class="col-created" v-else-if="props.column.field === 'created'">
                                <span class="small-line">{{ props.row.createdAtFormatted }}</span>
                                <span class="large-line">{{ props.row.createdPerson }}</span>
                            </div>

                            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                        <div slot="emptystate">
                            {{ $t('project.offers.text.empty_state') }}
                        </div>
                    </vue-good-table>
                </div>
                <div class="button-container" v-click-outside="() => {this.openNewOfferDropdown = false}">
                    <button class="btn btn-primary button-new-offer" type="button" @click="openNewOfferDropdown = !openNewOfferDropdown">
                        <md-icon>add_circle</md-icon>
                        {{ $t('project.offers.button.create') }}
                    </button>
                    <product-type-dropdown
                        :open="openNewOfferDropdown"
                        :productTypes="offerProductTypes"
                        @selectedType="createNewOffer">
                    </product-type-dropdown>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import { VueGoodTable } from 'vue-good-table'
import Dropdown from '../../components/dropdowns/Dropdown.vue'
import ProductTypeDropdown from '../../components/dropdowns/ProductTypeDropdown.vue'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'ModalProjectOffers',
    props: ['open'],

    data() {
        return {
            openNewOfferDropdown: false,
            tableColumns: [
                {
                    label: this.$t('project.offers.column.number'),
                    field: 'number',
                    width: '20%'
                },
                {
                    label: this.$t('project.offers.column.type'),
                    field: 'type',
                    width: '30%'
                },
                {
                    label: this.$t('project.offers.column.ordered'),
                    field: 'orderedNumbers',
                    width: '10%'
                },
                {
                    label: this.$t('project.offers.column.advisory'),
                    field: 'advisoryUser'
                },
                {
                    label: this.$t('project.offers.column.created'),
                    field: 'created'
                }
            ]
        }
    },

    watch: {
        open () {
            if (typeof this.projectData.id !== 'undefined') {
                this.$store.dispatch('FETCH_PROJECT_OFFERS', this.projectData.id)
            }
        }
    },

    methods: {
        createNewOffer(groupType) {
            this.openNewOfferDropdown = false
            this.$emit('close')
            if (groupType.type === 'text') {
                this.$router.push({name: 'NewOfferText', params: {projectId: this.projectData.id, groupId: groupType.id}})
            } else {
                this.$router.push({name: 'NewOfferCategory', params: {projectId: this.projectData.id, groupId: groupType.id}})
            }
        },

        rowClass(row) {
            let classList = ''
            classList += row.newCopy ? 'is-newcopy' : ''
            return classList
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        onRowClick(params) {
            if (!params.row.showDropdown && !params.event.target.className.includes('element-todo') && !params.event.target.className.includes('element-options')) {
                this.$emit('close')

                if(params.row.groupType === 'text'){
                    this.$router.push({name: 'OfferText', params: {offerId: params.row.id}})
                } else {
                    this.$router.push({name: 'OfferCategory', params: {offerId: params.row.id}})
                }
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        ...mapGetters(['offerProductTypes', 'projectData', 'projectOffers'])
    },

    components: { VueGoodTable, Dropdown, ProductTypeDropdown }
}
</script>

<style lang="scss">
@import '../../assets/sass/base/variables';
@import '../../assets/sass/ui/vuetable.scss';

</style>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 970px;
        overflow: visible;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-header {
            padding: 20px 36px 12px;

            .title {
                display: block;
                font-size: 11px;
                line-height: 14px;
                text-transform: uppercase;
            }

            button {
                position: relative;
                top: -10px;
            }
        }

        &-body {
            padding: 20px 0 15px;
            max-height: initial;
            overflow: visible;

            .table-list {
                max-height: 45vh;
                overflow-y: auto;
            }

            .col-ordered-number {
                .i-done {
                    font-weight: 600;
                    color:  $color-green-1;
                }
            }

            .col-options {
                position: relative;
            }

            .button-container {
                position: relative;
                display: inline-block;
            }

            .button-new-offer {
                margin: 25px 0 10px 36px;
            }
        }
    }
}
</style>

<style lang="scss">
.modal-box-body .vgt-responsive {
    height: auto;
}
</style>
