<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">Kérem frissítse az ajánlat állapotát</span>
            </div>

            <div v-if="stalledOffers.length" class="modal-box-body">
                <div v-for="offer in stalledOffers" :key="offer.id" class="modal-row">
                    <div class="offer--row">
                        <div @click="goToOffer(offer)" class="project--name">
                            <b>Projekt neve:</b>
                            <p>{{ offer.project_name }} <small style="font-size:13px">({{ offer.created.time }})</small></p>
                        </div>

                        <div class="offer--name">
                            <b>Projekt címe:</b>
                            <p>{{ offer.project_address }}</p>
                        </div>

                        <div class="buyer--name">
                            <b>Vevő neve</b>
                            <p>{{ offer.project_contact }}</p>
                        </div>

                        <div class="offer--name">
                            <b>Ajánlat</b>
                            <p>{{ offer.group }}</p>
                        </div>

                        <div class="modal-row">
                            <b>Ajánlat állapota</b>
                            <div class="input-wrapper">
                                <div v-if="!profile.isAdvisory" class="radio-container">
                                    <div v-for="tag in tags" :key="tag.id" class="radio">
                                        <input type="radio" :id="`${tag.title}_${offer.id}`" :value="tag.id" v-model="offer.status">
                                        <label :for="`${tag.title}_${offer.id}`">
                                            <span class="text">{{ tag.title }}</span>
                                        </label>
                                    </div>
                                </div>

                                <div v-else class="radio-container advisory">
                                    <div v-for="tag in advisoryTags" :key="tag.id" class="radio">
                                        <input type="radio" :id="`${tag.title}_${offer.id}`" :value="tag.id" v-model="offer.status">
                                        <label :for="`${tag.title}_${offer.id}`">
                                            <span class="text">{{ tag.title }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="has--question" v-if="offer.status === 163">
                                <p class="has--question-text">Szüksége van tanácsadói segítségre?</p>
                                <div class="radio-container">
                                    <div class="radio">
                                        <input type="radio" :id="`no_question_${offer.id}`" value="0" v-model="offer.has_question">
                                        <label :for="`no_question_${offer.id}`">
                                            <span class="text">Nem</span>
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" :id="`has_question_${offer.id}`" value="1" v-model="offer.has_question">
                                        <label :for="`has_question_${offer.id}`">
                                            <span class="text">Igen</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="action-row modal-row">
                    <button :disabled="!allOffersHaveStatus"  @click="save" type="button" class="btn btn-primary">Ajánlatok frissítése</button>
                </div>
            </div>

            <div v-else class="modal-box-body">
                <div class="is-loading" v-show="loading"></div>
            </div>

        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'
import { mapGetters } from 'vuex'

Vue.use(MdIcon)

export default {
    name: 'ModalAskForHelp',
    props: ['open'],

    data() {
        return {
            loading: true,
            disabled: true,
            tags: [
                {
                    id: 163,
                    title: "Folyamatban"
                },
                {
                    id: 157,
                    title: "Meghiúsúlt",
                },
                {
                    id: 134,
                    title: "Megrendelve"
                }
            ],
            advisoryTags: [
                {
                    id: 131,
                    title: "Később kell keresni"
                },
                {
                    id: 132,
                    title: "Ajánlat kiküldve"
                },
                {
                    id: 134,
                    title: "Megrendelve"
                },
                {
                    id: 136,
                    title: "Találkozó"
                },
                {
                    id: 138,
                    title: "Versenytárs"
                },
                {
                    id: 143,
                    title: "Árajánlat több kereskedő / kivitelező"
                },
                {
                    id: 157,
                    title: "Meghiúsult"
                },
                {
                    id: 158,
                    title: "Más típust rendelt"
                },
            ]
        }
    },

    mounted(){
        this.getStalledOffers();
    },

    methods: {
        getStalledOffers(){
            this.$store.dispatch('FETCH_STALLED_OFFERS').then(() => {
                this.loading = false;
                if(this.stalledOffers.length == 0){
                    this.$emit('close')
                }
            }).catch((err) => {
                console.error(err);
                this.loading = false;
            })
        },

        goToOffer(offer){
            if (offer.groupType === 'text') {
                this.$router.push({name: 'OfferText', params: {offerId: offer.id}})
            } else {
                this.$router.push({name: 'OfferCategory', params: {offerId: offer.id}})
            }
        },

        save() {
            this.$store
                .dispatch('SAVE_STALLED_OFFERS', this.stalledOffers)
                .then(() => {
                    this.getStalledOffers();
                })
                .catch((err) => {
                    console.error('Error saving offer data:', err);
                    this.getStalledOffers();
                });
        }
    },

    computed: {
        ...mapGetters(
            [
                'stalledOffers', 'profile'
            ]),

        allOffersHaveStatus() {
            return true //this.stalledOffers.every(offer => offer.status !== undefined);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.alert {
    margin-top: 1em;
    margin-bottom: 1em;
}

.action-row{
    display: flex!important;
    justify-content: end;
}

.modal-row {
    display: block;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }

    label {
        display: block;
        margin-bottom: .5em;
    }
}
.radio-container {
    display: flex;
    margin-left: 25px;
    justify-content: start;
    align-items: center;
    gap: 30px;

    &.advisory{
        flex-wrap: wrap;
        gap: 10px;
    }
}

</style>
