<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.tags.add.title') }}</span>

                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <div class="alert alert--danger" v-if="validation.length">{{ validation }}</div>

                <div class="modal-row">
                    <label class="text-input-label">{{ $t('offer.tags.add.field.title.label') }}</label>

                    <input
                        v-model="title"
                        type="text"
                        :placeholder="this.$t('offer.tags.add.field.title.placeholder')"
                        v-autofocus>
                </div>

<!--                <div class="modal-row" v-if="canCreateTemplateTags">-->
<!--                    <div style="margin-left:20px" class="checkbox">-->
<!--                        <input type="checkbox" id="chb-1" disabled v-model="is_template">-->
<!--                        <label for="chb-1">Sablon?</label>-->
<!--                        <span class="text-info">A sablon cimkéket megadott szereplők láthatják, módosíthatják</span>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="modal-row">
                    <label class="text-input-label">{{ $t('offer.tags.add.field.color.label') }}</label>

                    <ul class="color-select">
                        <li v-for="(c, colorIndex) in colors"
                            :key="colorIndex"
                            :class="{ 'is-selected' : color === c }">
                            <span @click="selectColor(c)">{{ $t('offer.tags.add.text.color', {counter: c}) }}</span>
                        </li>
                    </ul>
                </div>

                <div class="modal-row">
                    <button type="button" class="btn btn-primary" @click="validate()">{{ $t('offer.tags.add.button.save') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalTagAdd',
    props: ['open', 'canCreateTemplateTags'],

    data() {
        return {
            title: '',
            color: 1,
            colors: [1, 2, 3, 4, 5, 6, 7, 8],
            is_template: this.canCreateTemplateTags ? true : false,
            error: ''
        }
    },

    methods: {
        selectColor(color) {
            this.color = color
        },

        validate() {
            this.$store.dispatch('ADD_OFFER_TAG', {
                title: this.title,
                color: this.color,
                is_template: this.is_template
            }).then(() => {
                this.title = ''
                this.color = 1
                this.$emit('close')
            }).catch(response => {
                if (response.errors) {
                    this.$store.commit('setValidation', response.errors.title)
                }
                if (response.error) {
                    this.$store.commit('setValidation', response.error)
                }
            })
        }
    },

    computed: {
        ...mapGetters(['validation'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/alert.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.alert {
    margin-top: 1em;
    margin-bottom: 1em;
}

.modal-row {
    display: block;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }

    label {
        display: block;
        margin-bottom: .5em;
    }

    input {
        width: 100%;
    }
}

.color-select {
    list-style: none;

    li {
        display: inline-block;
        cursor: pointer;
        user-select: none;
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }

        span {
            font-size: 13px;
            font-weight: bold;
            color: #222;
            padding: 8px;
            margin: 2px;
            border: 1px solid transparent;
            border-radius: 2px;
            position: relative;
            display: block;
            cursor: pointer;

            &:before {
                content: '';
                width: 10px;
                height: 10px;
                background: #ddd;
                display: inline-block;
                margin-right: 5px;
            }
        }

        &.is-selected {
            span {
                border-color: $color-gray-3;
                background: #f5f5f5;
            }
        }

        &:hover {
            span {
                background: #f5f5f5;
            }
        }
    }
}

@for $i from 1 through 8 {
    .color-select li:nth-child(#{$i}) {
        span {
            color: map-get($tag-colors, 'color-tag-#{$i}');

            &:before {
                background: map-get($tag-colors, 'color-tag-#{$i}');
            }
        }
    }
}
</style>
