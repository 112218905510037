var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"select-component",class:{'large' : _vm.large, 'fixed-width': _vm.fixedWidth },style:({width: _vm.width ? `${_vm.width}px` : null})},[_c('input',{ref:"input",class:{
            'bold': typeof _vm.bold === 'undefined' || _vm.bold,
            'cursor-not-allowed': typeof _vm.cursorNotAllowed === 'undefined' || _vm.cursorNotAllowed,
            'has-sub-name': Boolean(_vm.selectedItemSubName)
        },attrs:{"type":"text","readonly":"readonly","autocomplete":"off","placeholder":_vm.placeholder || this.$t('common.select.choice'),"disabled":_vm.disabled},domProps:{"value":_vm.selectedItemName},on:{"click":function($event){_vm.open=!_vm.open}}}),(_vm.selectedItemSubName)?_c('span',{staticClass:"sub-name"},[_vm._v(_vm._s(_vm.selectedItemSubName))]):_vm._e(),(!_vm.iconArrow)?_c('md-icon',[_vm._v("list")]):_vm._e(),(_vm.iconArrow)?_c('md-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],ref:"dropdownList",staticClass:"dropdown-list",class:{'large-input' : _vm.large},style:(_vm.modalStyle)},[(_vm.filterable)?_c('li',{staticClass:"filter",style:(_vm.filterStyle)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterInputValue),expression:"filterInputValue"}],ref:"filterInput",staticClass:"input-filter",attrs:{"type":"text","placeholder":_vm.$t('common.select.filter_placeholder')},domProps:{"value":(_vm.filterInputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.filterInputValue=$event.target.value}}}),_c('md-icon',{staticClass:"icon-filter"},[_vm._v("search")])],1):_vm._e(),_vm._l((_vm.filteredOptions),function(option){return _c('li',{key:option.id,class:{
                'selected': _vm.selectedItem !== null && _vm.selectedItem.id == option.id,
                'has-sub-name': Boolean(option.subName)
            },on:{"click":function($event){return _vm.selectOption(option)}}},[_c('span',{domProps:{"innerHTML":_vm._s(option.styledName)}}),(option.subName)?_c('span',[_vm._v(_vm._s(option.subName))]):_vm._e()])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }