<template>
    <div class="offer-simple-page" v-if="userAuthenticated">
        <job-navigation :loading="loading" :offerName="offerName" :isTextOffer="true" @saveOffer="saveOffer" @closePage="closePage()" :viewOnly="viewOnly"></job-navigation>
        <div class="container" v-show="!loading">
            <h1>{{ $t('offer.page.edit.text.text.title') }}</h1>
            <div class="box">
                <editor ref="editor" :config="config" :initialized="onInitialized" v-show="!viewOnly" />
                <div ref="preview" v-show="viewOnly"></div>
                <a href="" download ref="pdfButton" class="button-download"></a>
            </div>
        </div>

        <div class="is-loading" v-show="loading"></div>

        <panel-total
            :loading="loading"
            :isTextOffer="true"
            :showExportButton="true"
            @exportData="exportToPdf"
            @nextStep="generateDefault"
        ></panel-total>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="saveOffer(true)"
            @close="openConfirmationModal = false"
            @secondaryAction="exitOffer"
        ></modal-confirmation>

        <modal-confirmation
            :open="openDefaultModal"
            :data="confirmationDefaultData"
            @accepted="generateDefault(true)"
            @close="openDefaultModal = false"
        ></modal-confirmation>

        <modal-confirmation
            v-if="validToConfirmationModalData !== null"
            :open="openValidToConfirmationModal"
            :data="validToConfirmationModalData"
            @accepted="validToConfirmationModalCallback(true)"
            @close="openValidToConfirmationModal = false"
            @secondaryAction="validToConfirmationModalCallback(false)"
        ></modal-confirmation>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from '../plugins/moment'

import JobNavigation from '../components/navigations/JobNavigation.vue'
import PanelTotal from '../components/PanelTotal.vue'
import Notification from '../components/Notification.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'

import Editor from 'vue-editor-js/src/index'
import CustomList from '../editor/custom_list.js';
import Product from '../editor/product.js';
import Title from '../editor/title.js';
import SubTitle from '../editor/sub_title.js';
import Sum from '../editor/sum.js';
import Separator from '../editor/separator.js';
import SubTool from '../editor/sub_tool.js';
import SupTool from '../editor/sup_tool.js';
import Text from '../editor/text.js';
import {getClipboardData} from '@/plugins/helpers'

Vue.use(Editor)

export default {
    name: 'OfferText',

    data() {
        return {
            offerName: null,
            editor: null,
            defaultSignature: [],
            config: this.getEditorConfig(),
            loading: true,
            editorIsEmpty: true,

            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('offer.page.edit.confirmation.save.ok'),
                cancelText: this.$t('offer.page.edit.confirmation.save.cancel'),
                secondaryActionText: this.$t('offer.page.edit.confirmation.save.secondary'),
                message: this.$t('offer.page.edit.confirmation.save.question')
            },

            openValidToConfirmationModal: false,
            validToConfirmationModalCallback: null,

            openDefaultModal: false,
            confirmationDefaultData: {
                acceptText: this.$t('offer.page.edit.confirmation.overwrite.ok'),
                cancelText: this.$t('offer.page.edit.confirmation.overwrite.cancel'),
                message: this.$t('offer.page.edit.confirmation.overwrite.question')
            }
        }
    },

    watch: {
        '$route' () {
            this.fetchOfferData()
        },

        viewOnly() {
            this.setEditorViewOnly()
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.fetchOfferData()
            this.$store.dispatch('FETCH_OFFER_PRODUCT_GROUPS')
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    computed: {
        ...mapGetters(['productText', 'userAuthenticated', 'originalOfferInformation', 'offerInformation', 'projectData', 'viewOnly']),

        validToConfirmationModalData() {
            if (!this.offerInformation.validTo || !this.offerInformation.id) {
                return null
            }

            if (this.originalOfferInformation.validTo === this.offerInformation.validTo && moment().isAfter(this.offerInformation.validTo, 'day')) {
                return {
                    acceptText: this.$t('offer.page.edit.confirmation.valid_to.ok'),
                    cancelText: this.$t('offer.page.edit.confirmation.valid_to.cancel'),
                    secondaryActionText: this.$t('offer.page.edit.confirmation.valid_to.secondary'),
                    message: this.$t('offer.page.edit.confirmation.valid_to.question', {
                        date: moment(this.offerInformation.validTo).format(String(this.$t('offer.page.edit.confirmation.valid_to.date_format')))
                    })
                }
            }

            return null
        }
    },

    methods: {
        closePage() {
            if (this.viewOnly || this.loading) {
                this.$router.push({name: 'OfferList'})
            }

            this.openConfirmationModal = true
        },

        exportToPdf() {
            if (this.viewOnly) {
                this.generatePdf()
            } else {
                this.saveOffer().then(() => {
                    this.generatePdf()
                })
            }
        },

        generatePdf() {
            this.$store.dispatch('GENERATE_OFFER_PDF', {offerId: this.$route.params.offerId}).then(data => {
                const downloadButton = this.$refs.pdfButton
                downloadButton.href = data.downloadUrl
                downloadButton.click()
            })
        },

        saveOffer(exit) {
            let ids = {
                projectId: this.$route.params.projectId,
                groupId: this.$route.params.groupId,
                offerId: this.$route.params.offerId
            }

            return new Promise((resolve, reject) => {
                this.editor.save().then((outputData) => {
                    this.$store.commit('setProductText', outputData)

                    let save = (updateValidTo = null) => {
                        this.openValidToConfirmationModal = false
                        this.openConfirmationModal = false

                        if (this.validToConfirmationModalData !== null) {
                            if (updateValidTo === null) {
                                this.validToConfirmationModalCallback = save.bind(this)
                                this.openValidToConfirmationModal = true
                                return
                            }

                            if (updateValidTo) {
                                this.$store.commit('setOfferInformationValidTo', moment().add(this.offerInformation.validToDays, 'days').format('YYYY-MM-DD'))
                            }
                        }

                        this.$store.dispatch('SEND_OFFER', {...ids, forceCopy: updateValidTo}).then(() => {
                            this.offerName = this.offerInformation.name
                            if (exit) {
                                this.$router.push({name: 'OfferList'})
                            }
                            resolve()
                        }).catch((error) => {
                            reject(error)
                        })
                    }

                    save()
                }).catch((error) => {
                    console.error('Saving failed: ', error)
                    reject(error)
                });
            })
        },

        exitOffer() {
            this.$router.push({name: 'OfferList'})
        },

        fetchOfferData() {
            this.loading = true

            let ids = {
                projectId: this.$route.params.projectId,
                groupId: this.$route.params.groupId,
                offerId: this.$route.params.offerId
            }

            this.$store.dispatch('FETCH_OFFER_DATA', ids).then((data) => {
                this.loading = false
                this.offerName = this.offerInformation.name

                this.defaultSignature = data.defaultSignature || []

                if(this.editor) {
                    this.editor.isReady.then(() => {
                        this.setEditorData(this.productText)
                        this.setEditorViewOnly()
                    })
                }
            }).catch((err) => {
                console.error(err)
                this.$store.commit('setNotification', [this.$t('offer.page.edit.notification.load_failed')])
                this.$router.push({name: 'OfferList'})
            })
        },

        // Editor

        onInitialized (editor) {
            this.editor = editor
            this.editor.isReady.then(() => {
                this.setEditorData(this.productText)
                this.setEditorViewOnly()
                if(!this.viewOnly) {
                    this.$refs.editor && this.$refs.editor.$el.firstChild.addEventListener('paste', (e) => {
                        const editorJSData = e.clipboardData.getData('application/x-editor-js');

                        if(editorJSData) {
                            return
                        }

                        let htmlData = e.clipboardData.getData('text/html');

                        this.$store.commit('setProductTextDebug', htmlData)

                        let clipboardData = getClipboardData(htmlData)

                        if(clipboardData) {
                            e.preventDefault()
                            e.stopPropagation()

                            this.setEditorData(clipboardData, false)

                            return false
                        }
                    })
                }
            })
        },

        calculateEditorIsEmpty(api) {
            let empty = true
            for(let i = 0; empty && i < api.blocks.getBlocksCount(); i++) {
                empty = empty && api.blocks.getBlockByIndex(i).isEmpty
            }
            this.editorIsEmpty = empty
        },

        setEditorData(data, clear = true) {
            if(clear) {
                this.editor.clear();
            }

            let blocks = data.blocks || []

            blocks.forEach((block) => {
                this.editor.blocks.insert(block.type, block.data)
            })

            if(blocks.length > 0) {
                const block = this.editor.blocks.getBlockByIndex(0)

                if(block.isEmpty && block.name === 'paragraph') {
                    this.editor.blocks.delete(0);
                }

                for(let i = this.editor.blocks.getBlocksCount() - 1, stop = false; !stop && i > 0; i--) {
                    let block = this.editor.blocks.getBlockByIndex(i)
                    if(block.isEmpty && block.name === 'paragraph') {
                        this.editor.blocks.delete(i);
                    } else {
                        stop = true
                    }
                }
            }

            this.calculateEditorIsEmpty(this.editor)
        },

        setEditorViewOnly() {
            let inputs = this.$refs.editor.$el.querySelectorAll('input');
            inputs.forEach(el => {
                el.dataset.value = el.value
            })

            this.$refs.preview.innerHTML = this.$refs.editor.$el.innerHTML;
            let editable_elements = this.$refs.preview.querySelectorAll("[contenteditable=true]");
            editable_elements.forEach(el => el.removeAttribute("contenteditable"))

            let icon_settings = this.$refs.preview.querySelectorAll('.ce-toolbar, .ce-inline-toolbar, .codex-editor-overlay');
            icon_settings.forEach(el => el.remove())

            inputs = this.$refs.preview.querySelectorAll('input');
            inputs.forEach(el => {
                let span = document.createElement('span')
                span.innerText = el.dataset.value
                el.parentNode.replaceChild(span, el)
            })
        },

        generateDefault(confirmed = false) {
            if (this.viewOnly) {
                return
            }

            if (!this.editorIsEmpty && !confirmed) {
                this.openDefaultModal = true
                return
            }

            this.openDefaultModal = false

            const text = this.$t('offer.page.edit.text.editor_sample.default.product.text', {
                product_name: this.$t('offer.page.edit.text.editor_sample.default.product.name'),
                product_name_bold: `<b>${this.$t('offer.page.edit.text.editor_sample.default.product.name')}</b>`,
            })

            const otherText = this.$t('offer.page.edit.text.editor_sample.default.product.text', {
                product_name: this.$t('offer.page.edit.text.editor_sample.default.product.other_name'),
                product_name_bold: `<b>${this.$t('offer.page.edit.text.editor_sample.default.product.other_name')}</b>`,
            })

            const items = [
                this.$t('offer.page.edit.text.editor_sample.default.product.property_1'),
                this.$t('offer.page.edit.text.editor_sample.default.product.property_2')
            ]

            this.setEditorData({
                blocks: [
                    {
                        type: 'title',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.title')
                        }
                    },
                    {
                        type: 'sub_title',
                        data: {
                            text: this.projectData.name
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.description_start'),
                        }
                    },
                    {
                        type: 'product',
                        data: {text, items}
                    },
                    {
                        type: 'product',
                        data: {text: otherText, items}
                    },
                    {
                        type: 'sum',
                        data: {
                            price: '0'
                        }
                    },
                    {
                        type: 'custom_list',
                        data: {
                            text: `<b>${this.$t('offer.page.edit.text.editor_sample.default.included.text')}</b>`,
                            items: [
                                this.$t('offer.page.edit.text.editor_sample.default.included.property')
                            ]
                        }
                    },
                    {
                        type: 'custom_list',
                        data: {
                            text: `<b>${this.$t('offer.page.edit.text.editor_sample.default.not_included.text')}</b>`,
                            items: [
                                this.$t('offer.page.edit.text.editor_sample.default.not_included.property_1'),
                                this.$t('offer.page.edit.text.editor_sample.default.not_included.property_2')
                            ]
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.delivery_time.text', {
                                title: this.$t('offer.page.edit.text.editor_sample.default.delivery_time.title'),
                                title_bold: `<b>${this.$t('offer.page.edit.text.editor_sample.default.delivery_time.title')}</b>`,
                            })
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.terms_of_payment.text', {
                                title: this.$t('offer.page.edit.text.editor_sample.default.terms_of_payment.title'),
                                title_bold: `<b>${this.$t('offer.page.edit.text.editor_sample.default.terms_of_payment.title')}</b>`,
                            })
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.valid_to.text', {
                                title: this.$t('offer.page.edit.text.editor_sample.default.valid_to.title'),
                                title_bold: `<b>${this.$t('offer.page.edit.text.editor_sample.default.valid_to.title')}</b>`,
                            })
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.description_end')
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.$t('offer.page.edit.text.editor_sample.default.city_date', {
                                date: moment().format(String(this.$t('offer.page.edit.text.editor_sample.default.city_date_format')))
                            })
                        }
                    },
                    {
                        type: 'paragraph',
                        data: {
                            text: this.defaultSignature.map((value, index) => {
                                return index === 0 ? `<b>${value}</b>` : value
                            }).join('<br>')
                        }
                    }
                ]
            })
        },

        getEditorConfig() {
            return {
                placeholder: this.$t('offer.page.edit.text.text.description'),
                onChange: (api) => {
                    if (this.viewOnly) {
                        return
                    }

                    this.calculateEditorIsEmpty(api)
                    api.saver.save().then((outputData) => {
                        this.$store.commit('setProductText', outputData)
                    }).catch((error) => {
                        console.error('Saving failed: ', error)
                    })
                },
                tools: {
                    sub: SubTool,
                    sup: SupTool,
                    paragraph: {
                        class: Text,
                        inlineToolbar: ['bold', 'italic', 'sub', 'sup'],
                        config: {
                            preserveBlank: true
                        }
                    },
                    title: Title,
                    sub_title: {
                        class: SubTitle,
                        inlineToolbar: ['bold', 'italic', 'sub', 'sup']
                    },
                    custom_list: {
                        class: CustomList,
                        inlineToolbar: ['bold', 'italic', 'sub', 'sup']
                    },
                    product: {
                        class: Product,
                        inlineToolbar: ['bold', 'italic', 'sub', 'sup']
                    },
                    sum: Sum,
                    separator: Separator,
                },
                i18n: {
                    messages: {
                        ui: {
                            'blockTunes': {
                                'toggler': {
                                    'Click to tune': this.$t('offer.page.edit.text.editor.settings')
                                },
                            },
                            'inlineToolbar': {
                                'converter': {
                                    'Convert to': this.$t('offer.page.edit.text.editor.convert')
                                }
                            },
                            'toolbar': {
                                'toolbox': {
                                    'Add': this.$t('offer.page.edit.text.editor.add')
                                }
                            }
                        },

                        toolNames: {
                            'Text': this.$t('offer.page.edit.text.editor.tool.text'),
                            'Bold': this.$t('offer.page.edit.text.editor.tool.bold'),
                            'Italic': this.$t('offer.page.edit.text.editor.tool.italic'),
                            'Sub': this.$t('offer.page.edit.text.editor.tool.sub'),
                            'Sup': this.$t('offer.page.edit.text.editor.tool.sup')
                        },

                        blockTunes: {
                            'delete': {
                                'Delete': this.$t('offer.page.edit.text.editor.delete')
                            },
                            'moveUp': {
                                'Move up': this.$t('offer.page.edit.text.editor.move_up')
                            },
                            'moveDown': {
                                'Move down': this.$t('offer.page.edit.text.editor.move_down')
                            }
                        },
                    }
                }
            }
        },
    },

    components: {
        JobNavigation,
        PanelTotal,
        Notification,
        ModalConfirmation
    }
}
</script>
<style lang="scss">
@import '../assets/sass/ui/loading.scss';
.ce-block__content, .ce-toolbar__content {
    max-width: 980px;
}
</style>
<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';

.offer-simple-page {
    background: $color-gray-bg-1;

    .container {
        max-width: 980px;
        width: 100%;
        margin: 60px auto 0;

        h1 {
            font-size: 17px;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .box {
            background: $color-white;
            box-shadow: 0px 4px 8px #D9D9D9;
            border: 1px solid $color-gray-border;
            padding: 40px 20px 80px 70px;
            margin-bottom: 100px;
            font-family: "Arial", sans-serif;

            textarea {
                margin-top: 10px;
            }

            input {
                width: 100%;
            }

            .label {
                font-size: 15px;
            }

            .label-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 25px 0 20px;
            }

            .button-group {
                .btn {
                    &:nth-child(1) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        margin-right: -3px;
                    }

                    &:nth-child(2) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
}
</style>
