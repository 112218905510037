<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box" :style="{width: data.width ? `${data.width}px` : null}">
            <div class="modal-box-body">
                <div class="message-text" v-html="data.message"></div>
                <div class="button-container">
                    <button type="button" class="btn btn-primary" :disabled="isAcceptButtonDisabled" @click="$emit('accepted')">{{data.acceptText}}</button>
                    <button type="button" class="btn btn-primary" @click="$emit('secondaryPrimaryAction')" v-if="data.secondaryPrimaryActionText">{{data.secondaryPrimaryActionText}}</button>
                    <button type="button" class="btn btn-secondary" @click="$emit('secondaryAction')" v-if="data.secondaryActionText">{{data.secondaryActionText}}</button>
                    <button type="button" class="btn btn-secondary" @click="$emit('close')">{{data.cancelText}}</button>
                </div>

            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalConfirmation',
    props: ['open', 'data', 'isAcceptButtonDisabled'],

    data() {
        return {
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        top: 25vh;
        width: 620px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        @media only screen and (max-width: 600px) {
            width: 95%;
        }

        &-body {
            padding-top: 32px;

            .message-text {
                line-height: 1.2;
            }

            .button-container {
                margin-top: 25px;

                button {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }
}
</style>
