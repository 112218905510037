<template>
    <div class="modal wider" :class="{ 'is-open': modals.consignment.open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.plan_calculation.calculation.consignment_title') }}</span>
                <button type="button" @click="close" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body" v-if="details">
                <div class="basic-sizes-block" :class="{'basic-sizes-block--without-material': details.materialReq === null}">
                    <div class="input-col">
                        <label class="label" for="typical-lath-gaps">{{ $t('offer.plan_calculation.calculation.data.field.typical_lath_gaps') }}</label>
                        <input type="number" class="small" v-model="details.typicalLathGaps" id="typical-lath-gaps" min="0" step="0.01" disabled>
                        <span class="unit">cm</span>
                    </div>
                    <div class="input-col">
                        <label class="label" for="cutting-losses">{{ $t('offer.plan_calculation.calculation.data.field.cutting_losses') }}</label>
                        <input type="number" class="small" v-model="details.cuttingLosses" id="cutting-losses" min="0" step="0.01" disabled>
                        <span class="unit">%</span>
                    </div>
                    <div class="input-col">
                        <label class="label" for="roof-pitch">{{ $t('offer.plan_calculation.calculation.data.field.roof_pitch') }}</label>
                        <input type="number" class="small" v-model="details.roofPitch" id="roof-pitch" min="0" step="0.01" disabled>
                        <span class="unit">°</span>
                    </div>
                    <div class="input-col" v-if="details.materialReq !== null">
                        <label class="label">{{ $t('offer.plan_calculation.calculation.data.field.material_req') }}</label>
                        <input type="number" class="small" :value="details.materialReq" readonly disabled>
                        <span class="unit">db/m²</span>
                    </div>
                </div>
                <div class="consignment-data-block">
                    <span class="title">{{ $t('offer.plan_calculation.calculation.consignation.title') }}</span>
                    <div class="col col-first">
                        <div class="input-row">
                            <label class="label" for="roof-surface">{{ $t('offer.plan_calculation.calculation.consignation.field.roof_surface') }}</label>
                            <input type="number" class="small" v-model="details.roofSurface" id="roof-surface" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.square_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="comignolo">{{ $t('offer.plan_calculation.calculation.consignation.field.comignolo') }}</label>
                            <input type="number" class="small" v-model="details.comignolo" id="comignolo" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="arris">{{ $t('offer.plan_calculation.calculation.consignation.field.arris') }}</label>
                            <input type="number" class="small" v-model="details.arris" id="arris" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="valley">{{ $t('offer.plan_calculation.calculation.consignation.field.valley') }}</label>
                            <input type="number" class="small" v-model="details.valley" id="valley" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-left">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_left') }}</label>
                            <input type="number" class="small" v-model="details.capLeft" id="cap-left" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-right">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_right') }}</label>
                            <input type="number" class="small" v-model="details.capRight" id="cap-right" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="cap-oblique">{{ $t('offer.plan_calculation.calculation.consignation.field.cap_oblique') }}</label>
                            <input type="number" class="small" v-model="details.capOblique" id="cap-oblique" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-row">
                            <label class="label" for="half-gable">{{ $t('offer.plan_calculation.calculation.consignation.field.half_gable') }}</label>
                            <input type="number" class="small" v-model="details.halfGable" id="half-gable" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="gutter">{{ $t('offer.plan_calculation.calculation.consignation.field.gutter') }}</label>
                            <input type="number" class="small" v-model="details.gutter" id="gutter" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="perimeter-trim">{{ $t('offer.plan_calculation.calculation.consignation.field.perimeter_trim') }}</label>
                            <input type="number" class="small" v-model="details.perimeterTrim" id="perimeter-trim" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="chimney-edge">{{ $t('offer.plan_calculation.calculation.consignation.field.chimney_edge') }}</label>
                            <input type="number" class="small" v-model="details.chimneyEdge" id="chimney-edge" min="0" step="0.01" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.linear_meter') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="roof-pen-number">{{ $t('offer.plan_calculation.calculation.consignation.field.roof_pen_number') }}</label>
                            <input type="number" class="small" v-model="details.roofPenNumber" id="roof-pen-number" min="0" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="number-of-arris-tile">{{ $t('offer.plan_calculation.calculation.consignation.field.number_of_arris_tile') }}</label>
                            <input type="number" class="small" v-model="details.numberOfArrisTile" id="number-of-arris-tile" min="0" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                        <div class="input-row">
                            <label class="label" for="distributor-ridge-tile">{{ $t('offer.plan_calculation.calculation.consignation.field.distributor_ridge_tile') }}</label>
                            <input type="number" class="small" v-model="details.distributorRidgeTile" id="distributor-ridge-tile" min="0" disabled>
                            <span class="unit">{{ $t('offer.plan_calculation.calculation.units.item') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'ModalConsignment',
    props: ['open', 'details'],

    methods: {
        close () {
            this.$store.commit('closeModal', 'consignment')
        }
    },

    computed: {
        ...mapGetters(['modals'])
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            input[type='number'] {
                max-width: 75px;
            }

            hr {
                width: 100%;
                margin-top: 0;
                margin-bottom: 30px !important;
            }

            .label {
                display: block;
                color: $color-gray-5;
                font-size: 13px;
                margin-bottom: 7px;
            }

            .unit {
                font-size: 13px;
                font-weight: bold;
                color: $color-gray-5;
                margin-left: 5px;
            }

            .basic-sizes-block {
                .input-col {
                    width: 128px;
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--without-material {
                    .input-col {
                        width: 176px;
                    }
                }
            }

            .consignment-data-block {
                display: block;
                padding: 30px 0 0 0;
                overflow: hidden;

                .title {
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    font-weight: bold;
                    color: $color-black-1;
                    margin-bottom: 30px;
                }

                .col {
                    float: left;
                    display: block;
                    width: 45%;

                    &.col-first {
                        padding-right: 30px;
                    }

                    .input-row {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-bottom: 15px;

                        .label {
                            display: inline-block;
                            margin-right: 12px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
</style>
