<template>
    <div v-if="userAuthenticated">
        <main-navigation></main-navigation>

        <div class="filter-column filter-column-simple" :class="{'collapsed-filter-column' : collapsedColumn}">

            <basic-links position="bottom"></basic-links>

            <div class="button-container">
                <button class="btn btn-plain btn-plain-wicon button-close" type="button" @click="collapsedColumn = true">
                    <md-icon>arrow_back_ios</md-icon>
                </button>
            </div>

            <button class="btn btn-plain btn-plain-wicon button-open" type="button" @click="collapsedColumn = false">
                <md-icon>arrow_forward_ios</md-icon>
            </button>
        </div>

        <div class="list-column list-column-simple" :class="{'collapsed-filter-column' : collapsedColumn}">
            <terms-content-internal v-if="profile.isInternal"></terms-content-internal>
            <terms-content v-else></terms-content>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import TermsContent from '../contents/TermsContent.vue'
import TermsContentInternal from '../contents/TermsContentInternal.vue'

Vue.use(MdIcon)

export default {
    name: 'Terms',

    data() {
        return {
            collapsedColumn: false
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    components: {
        MainNavigation,
        BasicLinks,
        TermsContent,
        TermsContentInternal
    },

    computed: {
        ...mapGetters(['profile', 'userAuthenticated'])
    },
}
</script>

<style lang="scss">
    @import '../assets/sass/toolkit.scss';
    @import '../assets/sass/ui/button.scss';
    @import '../assets/sass/layout/list-page.scss';

    .list-column {
        overflow-y: auto;
        padding: 0 32px 50px 282px;

        &.collapsed-filter-column {
            padding-left: 80px;
        }
    }
</style>
