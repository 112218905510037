<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.category.export.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <tabs :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el; this.updateActiveFields()}"></tabs>
                <div v-if="tabValue != 'summary'" class="label-row">
                    <span class="label">{{ $t('offer.category.export.field.settings') }}</span>
                    <button type="button" class="btn btn-plain" @click="editSettings = !editSettings">
                        <span v-show="!editSettings">{{ $t('offer.category.export.button.change') }}</span>
                        <span v-show="editSettings">{{ $t('offer.category.export.button.save') }}</span>
                    </button>
                </div>
                <div v-else class="label-row">
                    <span class="label">Ajánlatok összesítve</span>
                </div>
                <div v-if="tabValue != 'summary'" class="show-settings-container" v-show="!editSettings">
                    <div class="col">
                        <ul>
                            <li v-show="isActiveField('productSubText')">{{ $t('offer.category.export.option.settings.product_sub_text') }}</li>
                            <li v-show="isActiveField('listPrices')">{{ $t('offer.category.export.option.settings.list_prices') }}</li>
                            <li v-show="isActiveField('showGrossPrice')">{{ $t('offer.category.export.option.settings.show_gross_price') }}</li>
                            <li v-show="!isActiveField('showGrossPrice')">{{ $t('offer.category.export.option.settings.show_net_price') }}</li>
                        </ul>
                    </div>
                    <div class="col" v-if="isWorkerOffer" v-show="isActiveField('salesPrice') || isActiveField('salesDiscount') || isActiveField('salesCustomDiscount')">
                        <ul>
                            <li v-show="isActiveField('salesPrice')">{{ $t('offer.category.export.option.settings.sales_price') }}</li>
                            <li v-show="isActiveField('salesDiscount')">{{ $t('offer.category.export.option.settings.sales_discount') }}</li>
                            <li v-show="isActiveField('salesCustomDiscount')">{{ $t('offer.category.export.option.settings.sales_custom_discount') }}</li>
                        </ul>
                    </div>
                    <div class="col" v-show="isActiveField('buyerPrice') || isActiveField('buyerDiscount') || isActiveField('buyerCustomDiscount')">
                        <ul v-if="isWorkerOffer">
                            <li v-show="isActiveField('buyerPrice')">{{ $t('offer.category.export.option.settings.purchase_price') }}</li>
                            <li v-show="isActiveField('buyerDiscount')">{{ $t('offer.category.export.option.settings.purchase_discount') }}</li>
                            <li v-show="isActiveField('buyerCustomDiscount')">{{ $t('offer.category.export.option.settings.purchase_custom_discount') }}</li>
                        </ul>
                        <ul v-else>
                            <li v-show="isActiveField('buyerPrice')">{{ $t('offer.category.export.option.settings.buyer_price') }}</li>
                            <li v-show="isActiveField('buyerDiscount')">{{ $t('offer.category.export.option.settings.buyer_discount') }}</li>
                            <li v-show="isActiveField('buyerCustomDiscount')">{{ $t('offer.category.export.option.settings.buyer_custom_discount') }}</li>
                        </ul>
                    </div>
                    <div class="col" v-if="!isWorkerOffer" v-show="isActiveField('dealerPrice') || isActiveField('dealerDiscount') || isActiveField('dealerCustomDiscount')">
                        <ul>
                            <li v-show="isActiveField('dealerPrice')">{{ $t('offer.category.export.option.settings.dealer_price') }}</li>
                            <li v-show="isActiveField('dealerDiscount')">{{ $t('offer.category.export.option.settings.dealer_discount') }}</li>
                            <li v-show="isActiveField('dealerCustomDiscount')">{{ $t('offer.category.export.option.settings.dealer_custom_discount') }}</li>
                        </ul>
                    </div>
                </div>
                <div class="edit-settings-container" v-show="editSettings">
                    <div class="input-wrapper">
                        <div class="checkbox">
                            <input type="checkbox" value="productSubText" id="productSubText" v-model="activeFields">
                            <label for="productSubText">{{ $t('offer.category.export.option.settings.product_sub_text') }}</label>
                        </div>
                    </div>
                    <div class="input-wrapper">
                        <div class="checkbox">
                            <input type="checkbox" value="listPrices" id="listPrices" v-model="activeFields">
                            <label for="listPrices">{{ $t('offer.category.export.option.settings.list_prices') }}</label>
                        </div>
                    </div>

                    <template v-show="tabValue === 'sales'" v-if="hasDiscount && isWorkerOffer">
                        <div class="input-wrapper input-wrapper-parent">
                            <div class="checkbox">
                                <input type="checkbox" value="sales" id="sales" v-model="activeFields" @change="changedParentField('sales')">
                                <label for="sales">{{ $t('offer.category.export.option.settings.all') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount && !hasProductDiscount}">
                            <div class="checkbox">
                                <input type="checkbox" value="salesPrice" id="salesPrice" v-model="activeFields">
                                <label for="salesPrice">{{ $t('offer.category.export.option.settings.sales_price') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount}" v-if="hasProductDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="salesDiscount" id="salesDiscount" v-model="activeFields">
                                <label for="salesDiscount">{{ $t('offer.category.export.option.settings.sales_discount') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasProductDiscount}" v-if="hasCustomDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="salesCustomDiscount" id="salesCustomDiscount" v-model="activeFields">
                                <label for="salesCustomDiscount">{{ $t('offer.category.export.option.settings.sales_custom_discount') }}</label>
                            </div>
                        </div>
                    </template>

                    <template v-show="tabValue === 'buyer'" v-if="hasDiscount">
                        <div class="input-wrapper input-wrapper-parent">
                            <div class="checkbox">
                                <input type="checkbox" value="buyer" id="buyer" v-model="activeFields" @change="changedParentField('buyer')">
                                <label for="buyer">{{ $t('offer.category.export.option.settings.all') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount && !hasProductDiscount}">
                            <div class="checkbox">
                                <input type="checkbox" value="buyerPrice" id="buyerPrice" v-model="activeFields">
                                <label for="buyerPrice">{{ isWorkerOffer ? $t('offer.category.export.option.settings.purchase_price') : $t('offer.category.export.option.settings.buyer_price') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount}" v-if="hasProductDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="buyerDiscount" id="buyerDiscount" v-model="activeFields">
                                <label for="buyerDiscount">{{ isWorkerOffer ? $t('offer.category.export.option.settings.purchase_discount') : $t('offer.category.export.option.settings.buyer_discount') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasProductDiscount}" v-if="hasCustomDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="buyerCustomDiscount" id="buyerCustomDiscount" v-model="activeFields">
                                <label for="buyerCustomDiscount">{{ isWorkerOffer ? $t('offer.category.export.option.settings.purchase_custom_discount') : $t('offer.category.export.option.settings.buyer_custom_discount') }}</label>
                            </div>
                        </div>
                    </template>

                    <template v-show="tabValue === 'dealer'" v-if="hasDiscount && !isWorkerOffer">
                        <div class="input-wrapper input-wrapper-parent">
                            <div class="checkbox">
                                <input type="checkbox" value="dealer" id="dealer" v-model="activeFields" @change="changedParentField('dealer')">
                                <label for="dealer">{{ $t('offer.category.export.option.settings.all') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount && !hasProductDiscount}">
                            <div class="checkbox">
                                <input type="checkbox" value="dealerPrice" id="dealerPrice" v-model="activeFields">
                                <label for="dealerPrice">{{ $t('offer.category.export.option.settings.dealer_price') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasCustomDiscount}" v-if="hasProductDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="dealerDiscount" id="dealerDiscount" v-model="activeFields">
                                <label for="dealerDiscount">{{ $t('offer.category.export.option.settings.dealer_discount') }}</label>
                            </div>
                        </div>
                        <div class="input-wrapper input-wrapper-child" :class="{'input-wrapper-child-last': !hasProductDiscount}" v-if="hasCustomDiscount">
                            <div class="checkbox">
                                <input type="checkbox" value="dealerCustomDiscount" id="dealerCustomDiscount" v-model="activeFields">
                                <label for="dealerCustomDiscount">{{ $t('offer.category.export.option.settings.dealer_custom_discount') }}</label>
                            </div>
                        </div>
                    </template>

                    <template v-show="tabValue === 'summary'">
                    </template>

                    <div class="input-wrapper">
                        <div class="toggle" >
                            <span class="toggle__text">{{ $t('offer.category.export.option.settings.net') }}</span>
                            <input type="checkbox" value="showGrossPrice" id="showGrossPrice" v-model="activeFields">
                            <label for="showGrossPrice">{{ $t('offer.category.export.option.settings.gross') }}</label>
                        </div>
                    </div>
                </div>
                <div class="last-container">
                    <button v-if="tabValue === 'summary'" @click="saveSummaryPdf" class="btn btn-primary" :disabled="!permissions.canExport">
                        <md-icon>save_alt</md-icon>
                        {{ $t('offer.category.export.button.download') }}
                    </button>
                    <button v-if="tabValue != 'summary'" @click="savePdf" class="btn btn-primary" :disabled="!permissions.canExport">
                        <md-icon>save_alt</md-icon>
                        {{ $t('offer.category.export.button.download') }}
                    </button>
                    <div v-if="permissions.canExportMessage" class="error-message">{{permissions.canExportMessage}}</div>

                    <a href="" download ref="pdfButton" class="button-download"></a>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import Tabs from '../../components/Tabs.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalDataEdit',
    props: ['open', 'exportNetPrice', 'discountType', 'offerId', 'isWorkerOffer', 'projectId'],

    data() {
        return {
            tabValue: null,
            editSettings: false,
            activeFields: []
        }
    },

    methods: {
        updateActiveFields() {
            this.activeFields = this.getActiveFields(this.tabValue, this.isActiveField('showGrossPrice'))
        },

        getActiveFields(tabValue, showGrossPrice) {
            let activeFields = ['productSubText', 'listPrices']

            switch (tabValue) {
            case 'dealer':
                if(this.hasDiscount) {
                    activeFields.push('dealer')
                    activeFields.push('dealerPrice')
                }
                if(this.hasCustomDiscount) {
                    activeFields.push('dealerCustomDiscount')
                } else if(this.hasProductDiscount) {
                    activeFields.push('dealerDiscount')
                }
                break
            case 'buyer':
                if(this.hasDiscount) {
                    activeFields.push('buyer')
                    activeFields.push('buyerPrice')
                }
                break

            case 'sales':
                if(this.hasDiscount) {
                    activeFields.push('sales')
                    activeFields.push('salesPrice')
                }
                break
            }

            if ( showGrossPrice) {
                activeFields.push('showGrossPrice')
            }

            return activeFields
        },

        isActiveField(field) {
            return this.activeFields.includes(field)
        },

        fields(type) {
            let fields = {
                parent: type,
                children: []
            }

            if (type === 'dealer') {
                if (this.hasDiscount) {
                    fields.children.push('dealerPrice')
                }

                if (this.hasCustomDiscount) {
                    fields.children.push('dealerCustomDiscount')
                }

                if (this.hasProductDiscount) {
                    fields.children.push('dealerDiscount')
                }
            } else if (type === 'buyer') {
                if (this.hasDiscount) {
                    fields.children.push('buyerPrice')
                }

                if (this.hasCustomDiscount) {
                    fields.children.push('buyerCustomDiscount')
                }

                if (this.hasProductDiscount) {
                    fields.children.push('buyerDiscount')
                }
            } else if (type === 'sales') {
                if (this.hasDiscount) {
                    fields.children.push('salesPrice')
                }

                if (this.hasCustomDiscount) {
                    fields.children.push('salesCustomDiscount')
                }

                if (this.hasProductDiscount) {
                    fields.children.push('salesDiscount')
                }
            }

            return fields
        },

        changedParentField(type) {
            let fields = this.fields(type)

            if (this.isActiveField(fields.parent)) {
                fields.children.forEach((el) => {
                    if (!this.activeFields.includes(el)) {
                        this.activeFields.push(el)
                    }
                })
            } else {
                this.activeFields = this.activeFields.filter((value) => {
                    return !fields.children.includes(value)
                })
            }
        },

        saveSummaryPdf(){
            this.$store.dispatch('GENERATE_OFFER_SUMMARY_PDF', {projectId: this.projectData.id}).then(data => {
                const downloadButton = this.$refs.pdfButton
                downloadButton.href = data.downloadUrl
                downloadButton.click()
                this.$emit('close')
            })
        },

        savePdf() {
            let message = {
                activeFields: this.activeFields
            }

            this.$store.dispatch('GENERATE_OFFER_PDF', {offerId: this.offerId, message: message}).then(data => {
                const downloadButton = this.$refs.pdfButton
                downloadButton.href = data.downloadUrl
                downloadButton.click()
                this.$emit('close')
            })
        }
    },

    watch: {
        open(value) {
            if (value) {
                this.editSettings = false
                this.tabValue = this.isWorkerOffer ? 'sales' : 'buyer'
                this.activeFields = this.getActiveFields(this.tabValue, !this.exportNetPrice)
            }
        },
        offerId() {
            this.activeFields = this.getActiveFields(this.tabValue, !this.exportNetPrice)
        },
        hasDiscount() {
            this.activeFields = this.getActiveFields(this.tabValue, !this.exportNetPrice)
        },
        activeFields(value) {
            let fieldsList = [
                this.fields('sales'),
                this.fields('buyer'),
                this.fields('dealer')
            ]

            fieldsList.forEach((el) => {
                if ( !value.includes(el.parent) && el.children.every((v) => value.includes(v)))  {
                    this.activeFields.push(el.parent)
                }

                if ( value.includes(el.parent) && !el.children.every((v) => value.includes(v)))  {
                    this.activeFields = this.activeFields.filter((val) => {
                        return (val !== el.parent)
                    })
                }
            })
        }
    },

    computed: {
        ...mapGetters(['permissions', 'projectData']),

        hasDiscount() {
            return this.discountType === 3 || this.discountType === 4
        },

        hasProductDiscount() {
            return this.discountType === 3 || this.discountType === 4
        },

        hasCustomDiscount() {
            return this.discountType === 3
        },

        tabElements() {
            return this.isWorkerOffer ? [
                {
                    handle: 'sales',
                    handleName: this.$t('common.discounts.export.sales')
                },
                {
                    handle: 'buyer',
                    handleName: this.$t('common.discounts.export.purchase')
                },
                {
                    handle: 'listprice',
                    handleName: this.$t('common.discounts.list_price')
                },
                {
                    handle: 'summary',
                    handleName: 'Összesítés'
                }
            ] : [
                {
                    handle: 'buyer',
                    handleName: this.$t('common.discounts.buyer')
                },
                {
                    handle: 'dealer',
                    handleName: this.$t('common.discounts.dealer')
                },
                {
                    handle: 'listprice',
                    handleName: this.$t('common.discounts.list_price')
                },
                {
                    handle: 'summary',
                    handleName: 'Összesítés'
                }
            ]
        }
    },

    components: { Tabs }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            .button-download {
                visibility: hidden;
            }

            .checkbox {
                width: 100%;
            }

            .label-row {
                margin-top: 25px;
                margin-bottom: 25px;

                .label {
                    font-size: 14px;
                    font-weight: 500;
                }

                button {
                    float: right;
                }
            }

            .show-settings-container {
                display: flex;
                font-size: 13px;
                line-height: 16px;
                font-weight: 400;
                text-transform: lowercase;

                .col {
                    min-width: 10%;
                    margin-right: 5px;

                    ul {
                        margin: 0;
                        margin-left: 30px;
                        padding-left: 5px;
                        padding-inline-start: 0;
                        list-style: none;

                        li:before {
                            content: "\2022";
                            color: $color-green-1;
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }
            }

            .edit-settings-container {
                .input-wrapper {
                    margin-bottom: 10px;

                    &.input-wrapper-parent {
                        margin-top: 25px;
                        margin-bottom: 15px;
                    }

                    &.input-wrapper-child {
                        padding-left: 40px;

                        label {
                            font-weight: 500;
                        }
                    }

                    &.input-wrapper-child-last {
                        margin-bottom: 20px;
                    }

                    .toggle {
                        margin-top: 20px;

                        &__text {
                            font-size: 13px;
                            line-height: 22px;
                            margin-right: 6px;
                        }

                        span, input {
                            display: inline-block;
                        }
                    }
                }
            }

            .last-container {
                border-top: 1px solid $color-gray-border;
                margin-top: 30px;
                padding-top: 30px;

                a {
                    text-decoration: none;
                }

                i {
                    font-size: 18px !important;
                }

                .error-message {
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.3;
                    color: $color-red-1;
                    margin-top: 10px;
                }
            }
        }
    }
}
</style>
