<template>
    <div class="vue-tagger">
        <span class="vue-tagger-tag" v-for="(tag) in visibleTags" :key="tag.id">
            <span class="vue-tagger-tag-name">{{ tag.name }}</span>
            <span class="vue-tagger-delete-tag" @click="deleteTag(tag)">&times;</span>
        </span>
        <div class="vue-tagger-input-container">
            <input class="vue-tagger-input" v-model="currentTag" type="text" @keydown.delete.stop="onDeletePressed" :placeholder="placeholder" ref="vue-tagger-input"/>
        </div>
    </div>
</template>
<script>
import fuzzysearch from 'fuzzysearch'
import Awesomplete from 'awesomplete'
import differenceBy from 'lodash.differenceby'

export default {
    name: 'VueTagger',
    props: {
        tags: {
            type: Array,
            required: true,
            default: []
        },
        selectedTags: {
            type: Array,
            required: true,
            default: []
        },
        delimiter: {
            type: String,
            default: ','
        },
        placeholder: {
            type: String,
            default: 'Enter a tag...'
        }
    },
    data() {
        return {
            awesomplete: null,
            currentTag: '',
            tagList: [].concat(this.tags),
            selectedTagList: [].concat(this.selectedTags)
        }
    },
    computed: {
        autocompleteList() {
            return differenceBy(this.tags, this.visibleTags, 'id').map(tag => [tag.name, tag.id])
        },
        visibleTags() {
            return this.tagList.filter(tag => this.selectedTagList.includes(tag.id)).sort((a, b) => {
                return this.selectedTagList.indexOf(a.id) - this.selectedTagList.indexOf(b.id)
            })
        }
    },
    mounted() {
        this.initAwesomplete()
    },
    destroyed() {
        window.removeEventListener('awesomplete-select', this.onAwesompleteSelect)
    },
    watch: {
        tags() {
            this.tagList = [].concat(this.tags)
            this.awesomplete.list = this.autocompleteList
        },
        tagList() {
            this.awesomplete.list = this.autocompleteList
        },
        selectedTags() {
            this.awesomplete.list = this.autocompleteList
        },
        selectedTagList() {
            this.awesomplete.list = this.autocompleteList
            this.$emit('changed', [...this.selectedTagList])
        }
    },
    methods: {
        initAwesomplete() {
            this.awesomplete = new Awesomplete(this.$refs['vue-tagger-input'], {
                autoFirst: true,
                minChars: 1,
                filter(text, input) {
                    return fuzzysearch(input.toLowerCase(), text.toLowerCase())
                },
                list: this.autocompleteList
            })
            window.addEventListener('awesomplete-select', this.onAwesompleteSelect, false)
        },
        onAwesompleteSelect(e) {
            if(e.target === this.$refs['vue-tagger-input']) {
                setTimeout(() => {
                    this.addTag(e.text.value)
                    setTimeout(() => {
                        this.currentTag = ''
                    }, 0)
                }, 0)
            }
        },
        onDeletePressed() {
            if (this.currentTag === '') {
                const lastVisibleTag = this.tagList.filter(tag => this.selectedTagList.includes(tag.id)).pop()
                if (lastVisibleTag) {
                    const tag = this.tagList.find(tag => tag.name === lastVisibleTag.name)
                    this.removeTagById(tag.id)
                }
            }
        },
        addTag(value) {
            !this.selectedTagList.includes(value) && this.selectedTagList.push(value)
        },
        removeTagById(id) {
            let index = this.selectedTagList.indexOf(id);
            if(index > -1) {
                this.selectedTagList.splice(index, 1)
            }
        },
        deleteTag(tag) {
            this.removeTagById(tag.id)
            this.$refs['vue-tagger-input'].focus()
        }
    }
}
</script>
<style lang="scss">
$primary: #708EA3;
$text: #fff;
.vue-tagger {
    background: #fcfcfc;
    background-clip: padding-box;
    border-radius: 3px;
    border: 1px solid rgba(#000, 0.15);
    box-shadow: inset 0 1px 2px rgba(#000, 0.1);
    display: flex;
    flex-wrap: wrap;
    padding: 6px 0 0 6px;
    max-width: 400px;
    min-height: 32px;

    &-tag {
        display: flex;
        align-items: center;
        background: $primary;
        border-radius: 2px;
        color: $text;
        font-family: 'Helvetica Neue';
        font-weight: bold;
        font-size: 11px;
        letter-spacing: 0.02em;
        padding: 0 6px;
        margin-bottom: 6px;
        margin-right: 3px;
        height: 26px;
    }

    &-delete-tag {
        cursor: pointer;
        line-height: 1;
        position: relative;
        top: -1px;
        margin-left: 0.75em;
        padding: 3px;
    }

    &-input-container {
        flex-grow: 1;
        flex-basis: 75px;
        margin-bottom: 6px;
        margin-right: 6px;
        height: 26px;
    }

    &-input {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        height: 100%;
        outline: none;
        width: 100%;
    }
}

// Awesomplete Styles
.awesomplete [hidden] {
    display: none;
}

.awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.awesomplete {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
}

.awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 200px;
    max-width: 200px;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
}

.awesomplete > ul:empty {
    display: none;
}

.awesomplete > ul {
    border-radius: .3em;
    margin: .2em 0 0;
    background: hsla(0, 0%, 100%, .9);
    background: linear-gradient(to bottom right, white, hsla(0, 0%, 100%, .8));
    border: 1px solid rgba(0, 0, 0, .3);
    box-shadow: .05em .2em .6em rgba(0, 0, 0, .2);
    text-shadow: none;
}

@supports (transform: scale(0)) {
    .awesomplete > ul[hidden],
    .awesomplete > ul:empty {
        opacity: 0;
        display: block;
    }
}

.awesomplete > ul > li {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans;
    font-size: 13px;
    position: relative;
    padding: .5em .5em;
    cursor: pointer;
}

.awesomplete > ul > li:hover {
    background: rgba($primary, 0.7);
    color: $text;
}

.awesomplete > ul > li[aria-selected="true"] {
    background: $primary;
    color: $text;
}

.awesomplete mark {
    background: transparent;
    font-weight: bold;
}

.awesomplete li:hover mark {
    background: transparent;
    font-weight: bold;
}

.awesomplete li[aria-selected="true"] mark {
    background: transparent;
    font-weight: bold;
    color: inherit;
}
</style>
