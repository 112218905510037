<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">

            <div class="dashboard-header__new" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon>
                    {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="product-list-page__header-search-flex">
                    <div class="search-container">
                        <search :searchData="searchData" @searchPhrase="(value) => {this.searchPhrase = value}"></search>
                    </div>

                    <custom-filter
                        v-if="groupTypeCategories.length > 0"
                        :options="groupTypeCategories"
                        :label="''"
                        :value="filteredCategory"
                        :dropdown-size="300"
                        @selected="(e) => {this.filteredCategory = e.id}"
                    ></custom-filter>

                    <custom-filter
                        v-if="groupTypeProductTypes.length > 0"
                        :options="groupTypeProductTypes"
                        :label="''"
                        :value="filteredProductType"
                        :dropdown-size="450"
                        @selected="(e) => {this.filteredProductType = e.id}"
                    ></custom-filter>

                    <custom-filter
                        v-if="groupTypeProductRows.length > 0"
                        :options="onlyFilterOptions"
                        :label="''"
                        :value="onlyFilter"
                        @selected="(e) => {this.onlyFilter = e.id}"
                    ></custom-filter>
                </div>

                <div class="product-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <sidebar-filter
                :elements="groupTypesForSidebar"
                :selected="sidebarValue"
                @changed="sidebarChanged">
            </sidebar-filter>
        </div>

        <div class="list-column" v-if="groupTypeProductRows.length > 0">
            <vue-good-table
                :columns="tableColumns"
                :rows="getProductRows()"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div v-if="Array.isArray(props.formattedRow[props.column.field])">
                        <div v-for="(value, index) in props.formattedRow[props.column.field]" :key="index">{{ value }}</div>
                    </div>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="emptystate">{{ $t('group_type_product.page.list.text.empty_state') }}</div>
            </vue-good-table>
        </div>

        <modal-group-type-product-edit
            :open="openProductModal"
            :group-id="groupId"
            :group-type-id="groupTypeId"
            :group-type-variant-id="groupTypeVariantId"
            :type-id="groupTypeId"
            :productId="selectedProduct"
            @close="productEditEnd">
        </modal-group-type-product-edit>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {VueGoodTable} from 'vue-good-table'
import {MdIcon} from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'
import SidebarFilter from '../components/SidebarFilter.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Search from '../components/Search.vue'
import ModalGroupTypeProductEdit from '../components/modals/ModalGroupTypeProductEdit.vue'
import Notification from '../components/Notification.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'

Vue.use(MdIcon)
Vue.use(VueGoodTable)

export default {
    name: 'GroupTypeProductList',

    data() {
        return {
            loading: true,
            openProductModal: false,
            groupTypes: [],
            selectedProduct: null,
            selectedGroupType: null,
            sidebarValue: null,
            filteredCategory: 0,
            filteredProductType: 0,
            onlyFilter: 0,
            openGroupListDropdown: false,
            productGroups: [],

            onlyFilterOptions: [
                {
                    id: 0,
                    name: this.$t('group_type_product.page.list.filter.only.all')
                },
                {
                    id: 1,
                    name: this.$t('group_type_product.page.list.filter.only.has_product')
                },
                {
                    id: 2,
                    name: this.$t('group_type_product.page.list.filter.only.no_product')
                }
            ],

            searchData: {
                placeholder: this.$t('group_type_product.page.list.search.placeholder'),
                tooltipText: this.$t('group_type_product.page.list.search.tooltip'),
                countText: ''
            },

            searchPhrase: '',

            tableColumns: [
                {
                    label: this.$t('group_type_product.page.list.column.category'),
                    field: 'category',
                    width: '20%',
                },
                {
                    label: this.$t('group_type_product.page.list.column.product_type'),
                    field: 'productType',
                    width: '20%'
                },
                {
                    label: this.$t('group_type_product.page.list.column.product_numbers'),
                    field: 'productNumbers',
                    width: '6%'
                },
                {
                    label: this.$t('group_type_product.page.list.column.product_names'),
                    field: 'productNames',
                    width: '25%'
                },
                {
                    label: this.$t('group_type_product.page.list.column.product_prices'),
                    field: 'productPrices',
                    width: '10%'
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.fetchData()
                })
            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route'() {
            this.groupTypes = []
            this.$store.commit('resetGroupTypeProductList')
            this.resetFilter()
            this.fetchData()
        },

        sidebarValue: {
            handler: function () {
                this.$store.commit('resetGroupTypeProductList')
                if (this.groupTypeVariantId || !this.hasGroupTypeVariant) {
                    this.refresh()
                }
            }
        },
    },

    methods: {
        getProductRows() {
            let tableData = this.groupTypeProductRows, searchPhrase

            if (this.searchPhrase !== '') {
                searchPhrase = this.searchPhrase.toLowerCase().split(/\s+/)
                tableData = tableData.filter(row => {
                    let valid = true
                    for (let value of searchPhrase) {
                        valid = valid && row.search.toLowerCase().indexOf(value) > -1
                    }
                    return valid
                })
            }

            if (this.filteredCategory !== 0) {
                tableData = tableData.filter(row => {
                    return row.categoryId === this.filteredCategory
                })
            }

            if (this.filteredProductType !== 0) {
                tableData = tableData.filter(row => {
                    return row.productTypeId === this.filteredProductType
                })
            }

            if (this.onlyFilter !== 0) {
                if (this.onlyFilter === 1) {
                    tableData = tableData.filter(row => {
                        return row.productIds.length > 0
                    })
                } else {
                    tableData = tableData.filter(row => {
                        return row.productIds.length === 0
                    })
                }
            }

            this.searchData.countText = this.$tc('group_type_product.page.list.search.name', tableData.length)

            return tableData
        },

        onRowClick(params) {
            this.selectedProduct = params.row.productTypeId
            this.openProductModal = true
        },

        resetFilter() {
            this.filteredCategory = 0
            this.filteredProductType = 0
        },

        fetchData() {
            this.$store.dispatch('FETCH_GROUP_TYPES', this.$route.params.groupId).then(data => {
                this.groupTypes = data;
                if (this.groupTypes.length === 1) {
                    if (this.groupTypes[0].variants.length === 1) {
                        this.sidebarValue = this.variantHandleFor(this.groupTypes[0].id, this.groupTypes[0].variants[0].id)
                    } else {
                        this.sidebarValue = this.typeHandleFor(this.groupTypes[0].id)
                    }
                }
                this.loading = false;
            })
        },

        productEditEnd(refresh) {
            this.openProductModal = false
            if (refresh) {
                this.refresh()
            }
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProductType(payload) {
            this.openProductModal = false
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        typeHandleFor(groupTypeId) {
            return `${groupTypeId}_`
        },

        variantHandleFor(groupTypeId, groupTypeVariantId) {
            return this.typeHandleFor(groupTypeId) + groupTypeVariantId
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_GROUP_TYPE_PRODUCTS', {
                groupId: this.groupId,
                groupTypeId: this.groupTypeId,
                groupTypeVariantId: this.groupTypeVariantId
            }).then(() => this.loading = false)
        },

        sidebarChanged(el) {
            if(el === 'back') {
                this.$router.push({name: 'GroupAdmin', params: {groupId: this.groupId}})
            } else {
                this.sidebarValue = el
            }
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {

        groupId() {
            return this.$route.params.groupId
        },

        groupTypeId() {
            let parts

            if (this.sidebarValue === null) {
                return null
            }

            parts = this.sidebarValue.split('_')

            if (parts[0] !== '') {
                return parseInt(parts[0])
            } else {
                return null
            }
        },

        hasGroupTypeVariant() {
            let hasGroupTypeVariant = false

            if (this.sidebarValue === null || this.groupTypeId === null) {
                return hasGroupTypeVariant
            }

            this.groupTypes.map(groupType => {
                if (hasGroupTypeVariant === false && groupType.id === this.groupTypeId) {
                    hasGroupTypeVariant = groupType.variants.length > 0;
                }
            })

            return hasGroupTypeVariant;
        },

        groupTypeVariantId() {
            let parts

            if (this.sidebarValue === null) {
                return null
            }

            parts = this.sidebarValue.split('_')

            if (parts[1] !== '') {
                return parseInt(parts[1])
            } else {
                return null
            }
        },

        groupTypesForSidebar() {
            return this.groupTypes.reduce((carry, groupType) => {
                let handle = this.typeHandleFor(groupType.id)
                let active = this.sidebarValue !== null && this.sidebarValue.indexOf(handle) === 0 && groupType.variants.length > 0
                carry.push({
                    name: groupType.name,
                    handle: active ? null : handle,
                    children: active ? groupType.variants.map(variant => {
                        return {
                            name: variant.name,
                            handle: this.variantHandleFor(groupType.id, variant.id)
                        }
                    }) : []
                })
                return carry
            }, [
                {
                    name: '< Vissza',
                    handle: 'back'
                }
            ])
        },

        ...mapGetters(['groupTypeProductRows', 'groupTypeCategories', 'groupTypeProductTypes', 'userHasProductAdmin'])
    },

    components: {
        MainNavigation,
        SidebarFilter,
        VueGoodTable,
        ProductTypeDropdown,
        Search,
        ModalGroupTypeProductEdit,
        Notification,
        CustomFilter
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .checkbox {
            margin-left: auto;
            margin-right: 32px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: calc(100% - 70px);
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .filter-column {
        max-width: calc(100vh - 140px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
