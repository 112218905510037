<template>
    <div class="offer-list-page" v-if="userAuthenticated">
        <main-navigation></main-navigation>
        <div class="dashboard-header">
            <div class="dashboard-header__new">
            </div>

            <div class="dashboard-header__filters">
                <div class="offer-list-page__header-search-flex">
                    <div class="search-container">
                        <search :input="projectListActiveFilters.search" :searchData="searchData" @searchPhrase="onSearchPhraseChanged"></search>
                    </div>

                    <div style="display:contents;" v-if="userCanSeeFilters">
                        <custom-filter
                            v-if="projectListAdvisories.length > 1"
                            :options="projectListAdvisories"
                            :value="projectListActiveFilters.advisory"
                            :label="''"
                            :dropdown-size="340"
                            @selected="filterAdvisories"
                        ></custom-filter>

                        <div class="filter-component input-wrapper">
                            <custom-datepicker
                                :initDate="null"
                                @changed="(el) => {fromDate = el}"
                            />
                        </div>

                        <div class="filter-component input-wrapper">
                            <custom-datepicker
                                :initDate="null"
                                @changed="(el) => {toDate = el}"
                            />
                        </div>
                    </div>
                </div>
                <div class="offer-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column" :class="{'collapsed-filter-column' : collapsedColumn}">

            <sidebar-filter
                v-if="projectListFilters"
                :elements="projectListFilters"
                :selected="projectListActiveFilters.filter"
                @changed="sidebarChanged" />

            <basic-links position="bottom"></basic-links>

            <div class="button-container">
                <button class="btn btn-plain btn-plain-wicon button-close" type="button" @click="collapsedColumn = true">
                    <md-icon>arrow_back_ios</md-icon>
                </button>
            </div>

            <button class="btn btn-plain btn-plain-wicon button-open" type="button" @click="collapsedColumn = false">
                <md-icon>arrow_forward_ios</md-icon>
            </button>
        </div>

        <div class="list-column" :class="{'collapsed-filter-column' : collapsedColumn}">
            <custom-table
                ref="table"
                :row-height="92"
                :columns="tableColumns"
                :pages="projectListPages"
                :meta="projectListMeta"
                @on-cell-click="onCellClick"
                @load-page="loadPage"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div class="col-name" v-if="props.column.field === 'id'">
                        <span class="large-line" v-if="props.row.id">#{{ props.row.id }}</span>
                    </div>
                    <div class="col-name" v-if="props.column.field === 'name'">
                        <span class="small-line">{{ props.row.styledAddress }}</span>
                        <span class="large-line" v-if="props.row.name">{{ props.row.name }}</span>
                    </div>

                    <div class="col" v-else-if="props.column.field === 'customer'">
                        <span class="large-line">{{ props.row.customerName }}</span>
                    </div>

                    <div class="col" v-else-if="props.column.field === 'offers_count'">
                        <span class="large-line">{{ props.row.offersCount }} ajánlat</span>
                    </div>

                    <div class="col" v-else-if="props.column.field === 'percentage_to_win'">
                        <span class="small-line" v-if="props.row.formattedExpectedBuildingTime">{{ props.row.formattedExpectedBuildingTime }}</span>
                        <span class="large-line" v-if="props.row.percentageToWin">{{ props.row.percentageToWin }} %</span>
                        <span class="large-line" v-else>-</span>
                    </div>

                    <div class="col" v-else-if="props.column.field === 'created_at'">
                        <span class="large-line">{{ props.row.formattedCreatedAt }}</span>
                    </div>

                    <div class="col" v-else-if="props.column.field === 'options'" @click="props.row.showDropdown = !props.row.showDropdown">
                        <button v-if="props.row.actions != null" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            v-if="props.row.actions != null"
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            :elementPayload="props.row.tags" />
                    </div>
                </template>

                <div slot="empty_state">
                    {{ $t('offer.page.list.text.empty_state') }}
                </div>
            </custom-table>
        </div>

        <modal-project-edit
            :open="openProjectEditModal"
            :data="selectedProjectData"
            :isAvailableProject="availableProject"
            :fromProjects="true"
            :isNewOffer="false"
            :isCopyProject="false"
            @close="closeProjectEditModal"
            @selectProject="openProjectEditModal = false; openProjectSelectModal = true" />

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @secondaryPrimaryAction="projectDelete(true)"
            @accepted="projectDelete(false)"
            @close="openConfirmationDeleteModal = false" />

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import vClickOutside from 'v-click-outside'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Search from '../components/Search.vue'
import SidebarFilter from '../components/SidebarFilter.vue'
import BasicLinks from '../components/BasicLinks.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import ModalProjectEdit from '../components/modals/ModalProjectEdit.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation.vue'
import Notification from '../components/Notification.vue'
import CustomTable from '../components/CustomTable'
import CustomDatepicker from '../components/dropdowns/CustomDatepicker.vue'
import CustomFilter from '../components/dropdowns/CustomFilter.vue'

export default {
    name: 'ProjectList',

    data() {
        return {
            loading: true,
            openProjectSelectModal: false,
            openProjectEditModal: false,
            openProjectCopyModal: false,
            selectedProjectData: null,
            availableProject: false,

            tableColumns: [
                {
                    label: this.$t('projects.page.list.column.id'),
                    field: 'id',
                },
                {
                    label: this.$t('projects.page.list.column.name'),
                    field: 'name',
                },
                {
                    label: this.$t('projects.page.list.column.customer'),
                    field: 'customer'
                },
                {
                    label: this.$t('projects.page.list.column.percentage_to_win'),
                    field: 'percentage_to_win'
                },
                {
                    label: this.$t('projects.page.list.column.offers_count'),
                    field: 'offers_count'
                },
                {
                    label: this.$t('projects.page.list.column.created_at'),
                    field: 'created_at'
                },
                {
                    label: '',
                    field: 'options',
                    width: '80px'
                },
            ],

            showFilters: false,
            collapsedColumn: false,

            deleteProjectData: null,

            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('offer.page.list.confirmation.copy.ok'),
                cancelText: this.$t('offer.page.list.confirmation.copy.cancel'),
                secondaryActionText: this.$t('offer.page.list.confirmation.copy.secondary'),
                message: this.$t('offer.page.list.confirmation.copy.question')
            },
            openConfirmationDeleteModal: false,
            openConfirmationDeleteTagModal: false,

            selectedDealer: {},
            fromDate: '',
            toDate: '',
            initFromDate: '',
            initToDate: '',
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            this.$store.dispatch('FETCH_PROJECTS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
            })
        })

        this.initFromDate = '';
        this.initToDate = '';

        this.$store.dispatch('FETCH_OFFER_ADVISORIES');
        this.$store.dispatch('FETCH_OFFER_DEALERS').then(() => {
            this.selectedDealer = this.offerListDealers[0];
        });
    },

    methods: {
        projectDelete() {
            this.openConfirmationDeleteModal = false
            this.$store.dispatch('DELETE_PROJECT', {...this.deleteProjectData}).then(
                this.$store.dispatch('FETCH_PROJECTS', {page: 1, keepOtherPages: false})
            )
            this.refresh();
        },

        filterFromDate(){
            this.$store.commit('setProjectListActiveFilters', {
                ...this.projectListActiveFilters,
                from_date: this.fromDate
            })
            this.refresh()
        },

        filterToDate(){
            this.$store.commit('setProjectListActiveFilters', {
                ...this.projectListActiveFilters,
                to_date: this.toDate
            })
            this.refresh()
        },

        filterAdvisories(e) {
            this.$store.commit('setProjectListActiveFilters', {
                ...this.projectListActiveFilters,
                advisory: e.id
            })
            this.refresh()
        },

        onSearchPhraseChanged(phrase) {

            if (this.searchTimer) {
                clearTimeout(this.searchTimer)
                this.searchTimer = null
            }

            if (this.projectListActiveFilters.search === phrase) {
                return
            }

            this.searchTimer = setTimeout(() => {
                this.searchTimer = null
                this.$store.commit('setProjectListActiveFilters', {
                    ...this.projectListActiveFilters,
                    search: phrase.length > 0 ? phrase : void 0
                })
                this.refresh()
            }, 300)
        },

        loadPage(page) {
            this.loading = true
            this.$store.dispatch('FETCH_PROJECTS', {page, keepOtherPages: true}).then(() => {
                this.loading = false
            })
        },

        refresh() {
            this.loading = true
            this.$store.dispatch('FETCH_PROJECTS', {page: 1, keepOtherPages: false}).then(() => {
                this.loading = false
                this.$refs.table.scrollToTop()
            })
        },

        onCellClick(params) {
            if (!params.row.showDropdown && params.column.field !== 'options') {
                this.selectedProjectData = params.row;
                this.openProjectEditModal = true;
            }
        },

        closeProjectEditModal(refresh = false) {
            this.openProjectEditModal = false;
            if (refresh == true) {
                this.refresh();
            }
        },

        onDropdownClick(val, page) {
            let newData = {}
            let action = typeof val.action === 'string' ? val.action : val.action.action

            switch (action) {
            case 'delete':
                newData = {
                    id: val.id,
                    inList: true,
                    page: page,
                }

                this.openConfirmationDeleteModal = true
                this.deleteProjectData = newData
                break;
            }
        },

        sidebarChanged(el) {
            this.$store.commit('setProjectListActiveFilters', {
                ...this.projectListActiveFilters,
                filter: el
            })
            this.refresh()
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        selectedProject(data) {
            this.selectedProjectData = data.projectData
            this.availableProject = data.type === 'available'
            this.openProjectSelectModal = false
            this.openProjectEditModal = true
        },

        closedProjectSelects() {
            this.openProjectSelectModal = false
            this.$router.push({name: 'ProjectList'})
        },

        closeTagSelectModal() {
            this.openTagSelectModal = false
            this.openTagSelectModalData = {}
        },

        setLineBreak(str) {
            if (str === null) {
                return null
            }

            return String(str).replace(/(\S+\s*){1,7}/g, "$&\n")
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        confirmationDeleteModalData() {
            return {
                acceptText: this.$t('projects.page.list.confirmation.delete.ok'),
                cancelText: this.$t('projects.page.list.confirmation.delete.cancel'),
                message: this.$t('projects.page.list.confirmation.delete.question')
            }
        },

        searchData() {
            return {
                placeholder: this.$t('projects.page.list.search.placeholder'),
                tooltipText: this.$t('projects.page.list.search.tooltip')
            }
        },

        ...mapGetters(
            [
                'profile',

                'projectListPages',
                'projectListMeta',

                'projectListFilters',
                'projectListActiveFilters',

                'projectListScrollPosition',

                'userAuthenticated',
                'userCanSeeFilters',

                'projectListAdvisories',

                'offerListDealers'
            ])
    },

    watch: {
        'fromDate': {
            handler: function () {
                this.filterFromDate();
            }
        },

        'toDate': {
            handler: function () {
                this.filterToDate();
            }
        },
    },

    components: {
        MainNavigation,
        Search,
        BasicLinks,
        SidebarFilter,
        Dropdown,
        CustomTable,
        ModalProjectEdit,
        ModalConfirmation,
        Notification,
        CustomDatepicker,
        CustomFilter
    },
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/ui/tag.scss';
@import '../assets/sass/ui/loading.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.offer-list-page {
    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 500px;
            min-width: 150px;
            margin: 0px 5px 5px 0px;
        }

        .filter-component {
            margin: 5px 5px 5px 0px;
        }
    }

    &__header-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__header-search-flex {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        align-content: space-between;
    }

    &__header-controls {
        padding-right: 30px;
    }

    .archive-container {
        display: inline-block;
        position: relative;
    }

    .col-name {
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .col-favourite {
        margin-right: 35px;
        width: 80px;
        display: flex;
        align-items: center;

        .i-done {
            // position: relative;
            // top: 4px;
            // left: 7px;
            font-weight: 600;
            color:  $color-green-1;
        }

        button {
            line-height: 18px;

            i {
                margin-top: 5px;
            }

            &.remove {
                i {
                    color: $color-yellow-star;
                }
            }
        }
    }

    .col-options {
        position: relative;
        width: 100px;
    }
}
</style>
