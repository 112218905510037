import i18n from '../i18n'
import CustomList from './custom_list'
import './product.css'

export default class Product extends CustomList {
    static get toolbox() {
        return {
            icon: '<svg width="18" height="18" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 341.333 341.333" style="enable-background:new 0 0 341.333 341.333;" xml:space="preserve"><g><g><g><rect x="0" y="192" width="213.333" height="42.667"/><rect x="0" y="106.667" width="341.333" height="42.667"/></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>',
            title: i18n.t('offer.page.edit.text.editor.tool.product')
        };
    }

    get CSS() {
        return {
            baseBlock: this.api.styles.block,
            wrapper: 'ejs-product',
            item: 'ejs-product__item',
        };
    }

    get DEFAULT() {
        return {
            text: i18n.t('offer.page.edit.text.editor_sample.tool.product.text', {
                title: i18n.t('offer.page.edit.text.editor_sample.tool.product.title'),
                title_bold: `<b>${i18n.t('offer.page.edit.text.editor_sample.tool.product.title')}</b>`
            }),
            items: [i18n.t('offer.page.edit.text.editor_sample.tool.product.property')]
        };
    }

    render() {
        this._elements.wrapper = this._make('ol', [this.CSS.baseBlock, this.CSS.wrapper]);

        this._elements.li = this._make('li')
        this._elements.div = this._make('div', null, {
            contentEditable: true,
            innerHTML: this._data.text
        })
        this._elements.ul = this._make('ul', [this.CSS.baseBlock], {
            contentEditable: true,
        });

        this._elements.li.appendChild(this._elements.div);
        this._elements.li.appendChild(this._elements.ul);
        this._elements.wrapper.appendChild(this._elements.li);

        super.init()

        return this._elements.wrapper;
    }
}
