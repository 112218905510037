import SubTool from './sub_tool'

export default class SupTool extends SubTool{
    get TAG() {
        return 'SUP';
    }

    get SVG() {
        return '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16" height="16" viewBox="0 0 519.955 519.955" style="enable-background:new 0 0 519.955 519.955;" xml:space="preserve"><g><g><path d="M255.551,304.953l124.132-193.82c1.206-1.885,1.285-4.278,0.215-6.236c-1.077-1.965-3.134-3.183-5.367-3.183h-94.989 c-2.129,0-4.106,1.108-5.22,2.925l-74.597,121.99l-76.176-122.039c-1.12-1.793-3.079-2.876-5.19-2.876H22.532 c-2.24,0-4.309,1.224-5.374,3.195c-1.071,1.971-0.979,4.37,0.239,6.249L140.96,301.948L4.398,510.481 c-1.23,1.879-1.334,4.284-0.263,6.261c1.071,1.977,3.14,3.213,5.386,3.213h98.312c2.081,0,4.021-1.059,5.147-2.809l85.129-132.395 l84.866,132.382c1.126,1.757,3.066,2.815,5.153,2.815h98.868c2.259,0,4.327-1.242,5.392-3.226c1.065-1.982,0.949-4.4-0.293-6.279 L255.551,304.953z"/><path d="M472.971,132.327c11.462-10.514,19.223-18.421,23.732-24.168c6.763-8.611,11.806-17.026,14.977-25.019 c3.237-8.17,4.877-16.879,4.877-25.881c0-16.224-5.875-29.988-17.46-40.924C487.609,5.496,471.813,0,452.156,0 c-17.84,0-33.005,4.682-45.074,13.917c-12.362,9.455-19.773,24.915-22.044,45.937c-0.178,1.622,0.306,3.244,1.334,4.511 c1.028,1.267,2.521,2.068,4.144,2.233l35.092,3.507c0.202,0.019,0.404,0.031,0.612,0.031c1.444,0,2.846-0.508,3.953-1.451 c1.268-1.071,2.044-2.613,2.154-4.271c0.558-8.599,2.552-14.884,5.777-18.17c3.177-3.237,7.485-4.81,13.177-4.81 c5.795,0,10.098,1.481,13.151,4.535c3.054,3.054,4.535,7.558,4.535,13.782c0,5.845-2.1,11.953-6.242,18.164\tc-2.246,3.287-9.009,11.041-28.28,28.997c-20.312,18.85-33.624,33.691-40.674,45.368c-7.118,11.787-11.45,24.462-12.877,37.663 c-0.189,1.726,0.374,3.452,1.53,4.743c1.163,1.291,2.815,2.032,4.554,2.032h123.446c3.378,0,6.12-2.742,6.12-6.12v-32.711 c0-3.378-2.742-6.12-6.12-6.12H452.64C455.675,148.465,462.505,141.929,472.971,132.327z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>';
    }
}
