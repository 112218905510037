<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('offer.select_person.title', {type: selectedPersonType}) }}</span>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper">
                    <div class="autocomplete-container" v-click-outside="onClickOutside">
                        <label class="text-input-label" for="search-text-person">{{selectedPersonType}}</label>

                        <input
                            v-model="searchText"
                            @focus="searchFocus = true; openResultsList = true"
                            @blur="searchFocus = false"
                            :class="{'no-person' : selectedPerson.name.length < 1}"
                            id="search-text-person"
                            type="text"
                        >

                        <div class="selected-person-box" v-show="!searchFocus">
                            <span class="name">{{selectedPerson.name}}</span>
                            <span class="position">{{selectedPerson.position}}</span>
                        </div>

                        <button type="button" class="btn btn-plain btn-plain-wicon button-clear" @click="clearInput">
                            <md-icon>close</md-icon>
                        </button>

                        <ul v-show="openResultsList" class="dropdown-list">
                            <li
                                @click="selectPerson(result)"
                                v-for="result in resultsList"
                                :key="result.id"
                            >
                                <span class="result-name">
                                    <span v-html="result.styledName"></span>
                                    <span class="position">{{result.position}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <button
                    @click="addPerson"
                    :disabled="selectedPerson.name.length < 1"
                    class="btn btn-primary button-save"
                    type="button">
                    <span v-if="personType === 'advisory'">{{ $t('offer.select_person.button.save') }}</span>
                    <span v-else>{{ $t('offer.select_person.button.add') }}</span>
                </button>
                <button class="btn btn-plain" type="button" @click="$emit('close')">{{ $t('offer.select_person.button.cancel') }}</button>
            </div>
        </div>
        <div class="modal-layer"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'

Vue.use(MdIcon)

export default {
    name: 'ModalOfferSelectPerson',
    props: ['open', 'personType'],

    data() {
        return {
            searchText: '',
            searchFocus: false,
            openResultsList: false,
            selectedPerson: {
                id: 0,
                name: '',
                position: ''
            },
            results: [],
            allResults: []
        }
    },

    watch: {
        'open': function() {
            this.clearInput()

            if (this.personType === 'advisory') {
                this.allResults = this.offerInformation.advisoryUsers
            } else if (this.personType === 'observer') {
                this.allResults = this.offerInformation.observerUsers.filter(user => {
                    return user.selected === false
                })
            }

            this.results = this.allResults
        },

        'searchText': function(value) {
            this.results = this.allResults.filter(user => {
                return ( user.name.toLowerCase().indexOf(value.toLowerCase()) > -1 )
            })

            if (value.length < 1) {
                this.results = this.allResults
            }
        }
    },

    computed: {
        ...mapGetters(['offerInformation']),

        selectedPersonType () {
            if (this.personType === 'observer') {
                return this.$t('offer.select_person.types.observer')
            } else {
                return this.$t('offer.select_person.types.advisory')
            }
        },

        resultsList() {
            return this.results.map(r => {
                let pos = r.name.toLowerCase().indexOf(this.searchText.toLowerCase())
                let searchTextLength = this.searchText.length
                r.styledName = ''

                if (pos < 1) {
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(0, searchTextLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        searchTextLength,
                        searchTextLength + r.name.length
                    )
                } else {
                    r.styledName += r.name.substring(0, pos)
                    r.styledName +=
                        '<strong>' +
                        r.name.substring(pos, pos + searchTextLength) +
                        '</strong>'
                    r.styledName += r.name.substring(
                        pos + searchTextLength,
                        pos + searchTextLength + r.name.length
                    )
                }

                return r
            })
        }
    },

    methods: {
        selectPerson(person) {
            this.selectedPerson = person
            this.openResultsList = false
        },

        addPerson() {
            if (this.selectedPerson.name.length > 0) {
                this.$emit('newPerson', this.selectedPerson)
            }
        },

        clearInput() {
            this.openResultsList = false
            this.searchText = ''
            this.selectedPerson = {
                id: 0,
                name: '',
                position: ''
            }
        },

        onClickOutside() {
            this.openResultsList = false
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            overflow: visible;

            input {
                width: 100%;
                color: transparent;

                &:focus,
                &.no-person {
                    color: $color-black-1;
                }
            }

            .input-wrapper {
                margin-bottom: 20px;
            }

            .autocomplete-container {
                position: relative;

                .button-clear {
                    position: absolute;
                    right: 8px;
                    top: 42px;
                    background: $color-white;
                }

                .selected-person-box {
                    position: absolute;
                    top: 40px;
                    left: 15px;

                    .name {
                        font-size: 13px;
                    }

                    .position {
                        display: block;
                        color: $color-gray-2;
                        font-size: 10px;
                        line-height: 14px;
                    }
                }

                .dropdown-list {
                    top: 76px;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-right: 24px;

                        button {
                            text-decoration: none;
                        }

                        .position {
                            display: block;
                            color: $color-gray-2;
                            font-size: 10px;
                            line-height: 14px;
                        }
                    }
                }
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
