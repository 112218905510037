<template>
    <div>
        <sidebar-filter
            :elements="pages"
            :selected="selectedPage"
            @changed="sidebarChanged"
        ></sidebar-filter>
        <modal-price-list-date-edit
            :open="openPriceListDateModal"
            @close="priceListDateEditEnd"
        ></modal-price-list-date-edit>
    </div>
</template>

<script>
import SidebarFilter from '../SidebarFilter.vue'
import ModalPriceListDateEdit from "@/components/modals/ModalPriceListDateEdit";

export default {
    name: 'ProductAdminPages',

    props: ['selectedPage'],

    data() {
        return {
            openPriceListDateModal: false,
            pages: [
                {
                    name: this.$t('product.page.list.sidebar'),
                    handle: 'ProductList',
                },
                {
                    name: this.$t('product_type.page.list.sidebar'),
                    handle: 'ProductTypeList',
                },
                {
                    name: this.$t('color.page.list.sidebar'),
                    handle: 'ColorList',
                },
                {
                    name: this.$t('unit.page.list.sidebar'),
                    handle: 'UnitList',
                },
                {
                    name: this.$t('carrier.page.list.sidebar'),
                    handle: 'CarrierList',
                },
                {
                    name: this.$t('surface.page.list.sidebar'),
                    handle: 'SurfaceList',
                },
                {
                    name: this.$t('price_list_date.sidebar'),
                    handle: 'EditPriceListDate',
                }
            ]
        }
    },

    methods: {
        sidebarChanged(name) {
            if(name === 'EditPriceListDate') {
                this.openPriceListDateModal = true
            } else {
                this.$router.push({name})
            }
        },
        priceListDateEditEnd(refresh) {
            this.openPriceListDateModal = false
        },
    },

    components: {
        ModalPriceListDateEdit,
        SidebarFilter,
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/layout/list-page.scss';
</style>
