<template>
    <div role="alert" class="notification-component" :class="{ 'active': notification.open }">
        <div>
            <span
                v-html="notification.message[0]"
                v-if="notification.message && notification.message[0]"
            ></span>
            <span
                v-html="notification.message[1]"
                v-if="notification.message && notification.message[1]"
                class="large-line"
            ></span>
        </div>

        <button
            v-show="notification.handleName"
            class="btn btn-plain-small action"
            type="button"
        >{{ notification.handleName }}</button>

        <button class="close" type="button" @click="hideNotification">
            <md-icon>close</md-icon>
        </button>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

import { MdIcon } from 'vue-material/dist/components'

Vue.use(MdIcon)

export default {
    name: 'Notification',

    data() {
        return {
            closeTimer: null,
            openTimer: null
        }
    },

    methods: {
        autoHide() {
            this.clearTimers()
            this.closeTimer = setTimeout(() => {
                this.closeTimer = null
                this.$store.commit('emptyNotification')
            }, 5000)
        },

        show(message) {
            this.clearTimers()
            this.openTimer = setTimeout(() => {
                this.openTimer = null
                this.$store.commit('setNotification', message)
            }, 300)
        },

        hideNotification() {
            this.$store.commit('emptyNotification')
        },

        clearTimers() {
            clearTimeout(this.openTimer)
            clearTimeout(this.closeTimer)
            this.openTimer = null
            this.closeTimer = null
        }
    },

    watch: {
        notification: function(now, before) {
            if (now.open && !before.open) {
                this.autoHide()
            } else if (now.open && before.open) {
                this.$store.commit('emptyNotification')
                this.show(now.message)
            }
        }
    },

    mounted() {
        if (this.notification.open) {
            this.autoHide()
        }
    },

    computed: {
        ...mapGetters(['notification'])
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/button.scss';

.notification-component {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 20px;
    bottom: 20px;
    width: 370px;
    background: $color-black-1;
    color: $color-white;
    padding: 20px 50px 20px 25px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: translateX(-1000px);
    transition: transform 0.5s;
    z-index: 1050;

    &.active {
        transform: translateX(0);
        transition: transform 0.5s;
    }

    button,
    i {
        color: $color-white;
    }

    span {
        font-size: 16px;
        line-height: 22px;

        &.large-line {
            display: block;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 13px;
            margin-top: 5px;
        }
    }

    .action {
        margin-left: 20px;
    }

    .close {
        position: absolute;
        top: 50%;
        right: 18px;
        width: 24px;
        height: 18px;
        padding: 0;
        transform: translateY(-11px);

        i {
            width: 100%;
        }
    }
}
</style>
