<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <tabs :elements="tabElements" :selected="tabValue" @changed="(el) => {this.tabValue = el}"></tabs>
                <div class="group-type-data-container" v-show="tabValue === 'data'">
                    <div class="col-left">
                        <div class="input-wrapper" :class="{'error' : errors.name}">
                            <label class="text-input-label" for="name-group-type-edit">{{ $t('group_type.manage.field.name') }}</label>
                            <input type="text" v-model="name" id="name-group-type-edit">
                            <span class="input-error">{{errors.name}}</span>
                        </div>

                        <div class="input-wrapper input-wrapper--mb-0" :class="{'error' : errors.planCalculationId}">
                            <label class="text-input-label">{{ $t('group_type.manage.field.plan_calculation') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="planCalculation"
                                :options="planCalculations"
                                @changed="(el) => {planCalculation = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.planCalculationId}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox input-wrapper--mb-0" :class="{'error' : errors.deleted}">
                            <div class="toggle">
                                <input type="checkbox" v-model="deleted" id="deleted-group-type-edit">
                                <label for="deleted-group-type-edit">{{ $t('group_type.manage.field.deleted') }}</label>
                            </div>
                            <span class="input-error">{{errors.deleted}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.deletedName}">
                            <label class="text-input-label" for="deleted-name-group-type-edit">{{ $t('group_type.manage.field.deleted_name') }}</label>
                            <input type="text" v-model="deletedName" id="deleted-name-group-type-edit" :placeholder="`pl. Római Star cserepünk`">
                            <span class="input-error">{{errors.deletedName}}</span>
                        </div>
                    </div>
                    <div class="col-right">
                        <div class="input-wrapper" :class="{'error' : errors.logo}">
                            <label class="text-input-label">{{ $t('group_type.manage.field.logo') }}</label>
                            <custom-select
                                :modal="true"
                                :selectedItem="logo"
                                :options="logos"
                                @changed="(el) => {logo = el}"
                                :large="true"
                            ></custom-select>
                            <span class="input-error">{{errors.logo}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox input-wrapper--mb-0 input-wrapper--ceramics" :class="{'error' : errors.isCeramics}">
                            <div class="toggle">
                                <input type="checkbox" v-model="isCeramics" id="is-ceramics-group-type-edit">
                                <label for="is-ceramics-group-type-edit">{{ $t('group_type.manage.field.is_ceramics') }}</label>
                            </div>
                            <span class="input-error">{{errors.isCeramics}}</span>
                        </div>
                        <div class="input-wrapper input-wrapper--checkbox input-wrapper--mb-0" :class="{'error' : errors.discontinued}">
                            <div class="toggle">
                                <input type="checkbox" v-model="discontinued" id="discontinued-group-type-edit">
                                <label for="discontinued-group-type-edit">{{ $t('group_type.manage.field.discontinued') }}</label>
                            </div>
                            <span class="input-error">{{errors.discontinued}}</span>
                        </div>
                        <div class="input-wrapper" :class="{'error' : errors.discontinuedName}">
                            <label class="text-input-label" for="discontinued-name-group-type-edit">{{ $t('group_type.manage.field.discontinued_name') }}</label>
                            <input type="text" v-model="discontinuedName" id="discontinued-name-group-type-edit" :placeholder="`pl. Római Star cserepünk`">
                            <span class="input-error">{{errors.discontinuedName}}</span>
                        </div>
                    </div>
                </div>
                <div class="group-type-information-container" v-show="tabValue === 'information'">
                    <table>
                        <thead>
                        <tr>
                            <td style="width: 130px">Felirat</td>
                            <td style="width: 130px">Szöveg</td>
                            <td style="width: 30px;"></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(informationRow, index) in information" :key="index">
                            <td>
                                <input type="text" v-model="informationRow.label">
                            </td>
                            <td>
                                <input type="text" v-model="informationRow.value">
                            </td>
                            <td>
                                <input type="button" value="Törlés" @click="removeInformation(index)">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <input type="button" value="Új információ" @click="addInformation">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="save" :disabled="loading">
                        {{ $t('group_type.manage.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_type.manage.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'
import Tabs from '../../components/Tabs.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupTypeEdit',
    props: ['open', 'groupId', 'categoryId',  'typeId', 'createTarget'],

    data() {
        return {
            loading: false,
            errors: {},
            tabElements: [
                {
                    handle: 'data',
                    handleName: this.$t('group_type.manage.data.title')
                },
                {
                    handle: 'information',
                    handleName: this.$t('group_type.manage.information.title')
                }
            ],
            tabValue: 'data',
            title: null,
            name: null,
            logo: null,
            logos: [],
            planCalculation: null,
            planCalculations: [],
            deleted: false,
            deletedName: null,
            discontinued: false,
            discontinuedName: null,
            information: [],
            isCeramics: false
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.tabValue = 'data'
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_TYPE', {
                    groupId: this.groupId,
                    categoryId: this.categoryId,
                    typeId: this.typeId,
                    createTarget: this.createTarget,
                }).then(data => {
                    this.title = data.title
                    this.name = data.name
                    this.logo = data.logo
                    this.logos = data.logos
                    this.planCalculation = data.planCalculation
                    this.planCalculations = data.planCalculations
                    this.deleted = data.deleted
                    this.deletedName = data.deletedName
                    this.discontinued = data.discontinued
                    this.discontinuedName = data.discontinuedName
                    this.information = data.information
                    this.isCeramics = data.isCeramics
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        save() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                categoryId: this.categoryId,
                typeId: this.typeId,
                createTarget: this.createTarget,
                data: {
                    name: this.name,
                    logo: this.logo ? this.logo.id : null,
                    planCalculationId: this.planCalculation ? this.planCalculation.id : null,
                    deleted: this.deleted,
                    deletedName: this.deletedName,
                    discontinued: this.discontinued,
                    discontinuedName: this.discontinuedName,
                    information: this.information,
                    isCeramics: this.isCeramics
                }
            }

            this.$store.dispatch('SEND_GROUP_TYPE', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        },

        removeInformation(index) {
            this.information.splice(index, 1)
        },

        addInformation() {
            this.information.push({
                label: null,
                value: null,
            })
        }
    },

    components: { Notification, Tabs, CustomSelect }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            input[type="checkbox"],
            input[type="radio"] {
                width: auto;
            }

            .input-wrapper {
                margin-bottom: 20px;

                &--w33 {
                    width: calc(33.33333% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &--w50 {
                    width: calc(50% - 4px);
                    display: inline-block;
                    margin-right: 4px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                &.input-wrapper-last {
                    padding-bottom: 50px;
                    border-bottom: 1px solid $color-gray-border;
                    margin-bottom: 15px;
                }

                &--mb-0 {
                    margin-bottom: 0;
                }

                &--ceramics {
                    min-height: 74px;
                    display: flex;
                    align-items: end;
                    padding-bottom: 12px;
                }

                .dropdown-list {
                    top: 76px;
                }

            }

            .col-left,
            .col-right {
                float: left;
                width: 49%
            }

            .col-left {
                margin-right: 1%;
            }

            .col-right {
                margin-left: 1%;
            }

            .group-type-information-container {
                float: left;
                width: 100%;
                margin-top: 15px;

                table {
                    width: 100%;
                    border-spacing: 5px;
                    border-collapse: separate;

                    .toggle {
                        position: relative;

                        label {
                            padding-left: 0;
                            position: absolute;
                            top: 0;
                            left: 27px;
                        }
                    }
                }
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
