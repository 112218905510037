<template>
    <div class="modal" v-if="!loading" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ title }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon"><md-icon>close</md-icon></button>
            </div>

            <div class="modal-box-body">
                <div class="input-wrapper" :class="{'error' : errors.productId}">
                    <label class="text-input-label">{{ $t('group_category_product.modal.field.product') }}</label>
                    <custom-select
                        :filterable="true"
                        :modal="true"
                        :selectedItem="product"
                        :options="products"
                        @changed="(el) => {product = el}"
                        :large="true"
                    ></custom-select>
                    <span class="input-error">{{errors.productId}}</span>
                </div>
                <div class="button-container">
                    <button class="btn btn-primary button-save" type="button" @click="save" :disabled="loading">
                        {{ $t('group_category_product.modal.button.save') }}
                    </button>

                    <button class="btn btn-plain" type="button" @click="$emit('close')" :disabled="loading">{{ $t('group_category_product.modal.button.cancel') }}</button>
                </div>
            </div>
        </div>
        <div class="modal-layer"></div>

        <notification></notification>
    </div>
</template>

<script>
import Vue from 'vue'

import { MdIcon } from 'vue-material/dist/components'
import CustomSelect from '../../components/dropdowns/CustomSelect.vue'
import Notification from '../../components/Notification.vue'

Vue.use(MdIcon)

export default {
    name: 'ModalGroupCategoryProductEdit',
    props: ['open', 'groupId', 'categoryId', 'productId', 'createTarget'],

    data() {
        return {
            loading: false,
            errors: {},
            title: null,
            product: null,
            products: []
        }
    },

    watch: {
        'open': function (opened) {
            if (opened) {
                this.errors = {}
                this.loading = true
                this.$store.dispatch('FETCH_GROUP_CATEGORY_PRODUCT', {
                    groupId: this.groupId,
                    categoryId: this.categoryId,
                    productId: this.productId,
                    createTarget: this.createTarget,
                }).then(data => {
                    this.title = data.title
                    this.product = data.product
                    this.products = data.products
                    this.loading = false
                }).catch(() => {
                    this.$emit('close')
                })
            }
        }
    },

    methods: {
        save() {
            this.errors = {}

            let payload = {
                groupId: this.groupId,
                categoryId: this.categoryId,
                productId: this.productId,
                createTarget: this.createTarget,
                data: {
                    productId: this.product ? this.product.id : null
                }
            }

            this.$store.dispatch('SEND_GROUP_CATEGORY_PRODUCT', payload).then(() => {
                this.$emit('close', true)
            }).catch(result => {
                this.errors = result.errors || {}
            })
        }
    },

    components: { Notification, CustomSelect }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {
        width: 800px;


        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {

            h2 {
                font-weight: 600;
                margin-bottom: 20px;
            }

            input {
                width: 100%;
            }

            .input-wrapper--checkbox {
                margin-left: 20px;
            }

            .button-container {
                float: left;
                width: 100%;
                margin-top: 20px;
            }

            .button-save {
                margin-right: 25px;
            }
        }
    }
}
</style>
