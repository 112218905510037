<template>
    <div class="product-list-page" v-if="userHasProductAdmin">
        <main-navigation></main-navigation>

        <div class="dashboard-header">
            <div class="dashboard-header__new dashboard-header__new--wide" v-click-outside="() => {this.openGroupListDropdown = false}">
                <button class="btn btn-primary" type="button" @click="openGroupListDropdown = !openGroupListDropdown">
                    <md-icon>edit</md-icon> {{ $t('product_admin.page.list.button.groups') }}
                </button>
                <button
                    @click="newColor"
                    class="btn btn-secondary"
                    type="button">
                    <md-icon>add_circle</md-icon> {{ $t('color.page.list.button.create') }}
                </button>
                <product-type-dropdown
                    :open="openGroupListDropdown"
                    :productTypes="productGroups"
                    @selectedType="selectedProductType">
                </product-type-dropdown>
            </div>

            <div class="dashboard-header__filters">
                <div class="product-list-page__header-search-flex">
                    <div class="search-container">
                        <search :searchData="searchData" @searchPhrase="(value) => {this.searchPhrase = value}"></search>
                    </div>
                </div>
                <div class="product-list-page__header-controls">
                    <button type="button" @click="refresh()">
                        <md-icon>refresh</md-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="filter-column">
            <product-admin-pages selected-page="ColorList"></product-admin-pages>
            <basic-links position="bottom"></basic-links>
        </div>

        <div class="list-column" v-if="colorRows.length > 0">
            <vue-good-table
                :columns="tableColumns"
                :rows="getRows()"
                @on-row-click="onRowClick"
                @on-row-mouseleave="onRowMouseleave">
                <template slot="table-row" slot-scope="props">
                    <div v-if="Array.isArray(props.formattedRow[props.column.field])">
                        <div v-for="(value, index) in props.formattedRow[props.column.field]" :key="index">{{ value }}</div>
                    </div>
                    <div class="col-options" v-else-if="props.column.field === 'options' && props.row.actions.length > 0">
                        <button @click="props.row.showDropdown = !props.row.showDropdown" class="element-options">
                            <md-icon class="element-options">more_vert</md-icon>
                        </button>

                        <dropdown
                            @clicked="onDropdownClick($event, props.page)"
                            :open="props.row.showDropdown"
                            :options="props.row.actions"
                            :elementId="props.row.id"
                            width="280"
                        ></dropdown>
                    </div>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
                <div slot="emptystate">{{ $t('color.page.list.text.empty_state') }}</div>
            </vue-good-table>
        </div>

        <modal-color-edit
            :open="openColorModal"
            :color-id="selectedColorId"
            @close="colorEditEnd">
        </modal-color-edit>

        <modal-confirmation
            :open="openConfirmationDeleteModal"
            :data="confirmationDeleteModalData"
            @accepted="colorDelete"
            @close="openConfirmationDeleteModal = false"
        ></modal-confirmation>

        <notification></notification>

        <div class="is-loading" v-show="loading"></div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {VueGoodTable} from 'vue-good-table'
import {MdIcon} from 'vue-material/dist/components'
import vClickOutside from 'v-click-outside'
import ProductTypeDropdown from '../components/dropdowns/ProductTypeDropdown.vue'

import MainNavigation from '../components/navigations/MainNavigation.vue'
import Search from '../components/Search.vue'
import ModalColorEdit from '../components/modals/ModalColorEdit.vue'
import Notification from '../components/Notification.vue'
import Dropdown from '../components/dropdowns/Dropdown.vue'
import ModalConfirmation from '../components/modals/ModalConfirmation'
import BasicLinks from '../components/BasicLinks.vue'
import ProductAdminPages from "@/components/sidebars/ProductAdminPages";

Vue.use(MdIcon)
Vue.use(VueGoodTable)

export default {
    name: 'ColorList',

    data() {
        return {
            loading: true,
            openColorModal: false,
            selectedColorId: null,

            openGroupListDropdown: false,
            productGroups: [],

            searchData: {
                placeholder: this.$t('color.page.list.search.placeholder'),
                tooltipText: this.$t('color.page.list.search.tooltip'),
                countText: ''
            },

            searchPhrase: '',

            deleteColorId: null,
            openConfirmationDeleteModal: false,
            confirmationDeleteModalData: {
                acceptText: this.$t('color.confirmation.delete.ok'),
                cancelText: this.$t('color.confirmation.delete.cancel'),
                message: this.$t('color.confirmation.delete.question')
            },

            tableColumns: [
                {
                    label: this.$t('color.page.list.column.name'),
                    field: 'name',
                    width: '94%',
                },
                {
                    label: '',
                    field: 'options',
                    width: '6%'
                }
            ]
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_CURRENT_USER').then(() => {
            if (this.userHasProductAdmin) {
                this.$store.dispatch('FETCH_PRODUCT_GROUPS').then(data => {
                    this.productGroups = data;
                    this.fetchData()
                })
            } else {
                this.$router.push('/')
            }
        }).catch((redirect) => {
            redirect && this.$router.push('/');
        })
    },

    watch: {
        '$route'() {
            this.$store.commit('resetColorList')
            this.fetchData()
        }
    },

    methods: {
        getRows() {
            let tableData = this.colorRows, searchPhrase

            if (this.searchPhrase !== '') {
                searchPhrase = this.searchPhrase.toLowerCase().split(/\s+/)
                tableData = tableData.filter(row => {
                    let valid = true
                    for (let value of searchPhrase) {
                        valid = valid && row.search.toLowerCase().indexOf(value) > -1
                    }
                    return valid
                })
            }

            this.searchData.countText = this.$tc('color.page.list.search.name', tableData.length)

            return tableData
        },

        newColor() {
            this.selectedColorId = null
            this.openColorModal = true
        },

        onRowMouseleave(el) {
            el.row.showDropdown = false
        },

        onRowClick(params) {
            if (!params.event.target.className.includes('element-options')) {
                params.row.showDropdown = false
                this.selectedColorId = params.row.id
                this.openColorModal = true
            }
        },

        onDropdownClick(val) {
            switch (val.action.action) {
            case 'delete':
                this.deleteColorId = val.id
                this.openConfirmationDeleteModal = true
                break
            }
        },

        colorDelete() {
            let payload = {
                colorId: this.deleteColorId
            }

            this.$store.dispatch('DELETE_COLOR', payload).then(() => {
                this.$store.commit('setNotification', [this.$t('color.page.list.confirmation.delete.notification.success')])
                this.deleteColorId = null
                this.refresh()
            }).catch(() => {
                this.$store.commit('setNotification', [this.$t('color.page.list.confirmation.delete.notification.failed')])
            }).finally(() => {
                this.openConfirmationDeleteModal = false
            })
        },

        fetchData() {
            this.$store.dispatch('FETCH_COLORS').then(() => {
                this.loading = false;
            })
        },

        colorEditEnd(refresh) {
            this.openColorModal = false
            if (refresh) {
                this.refresh()
            }
        },

        selectedProductType(payload) {
            this.openProductTypeModal = false
            this.openGroupListDropdown = false
            this.$router.push({name: 'GroupAdmin', params: {groupId: payload.id}})
        },

        refresh() {
            this.loading = true
            this.fetchData()
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    },

    computed: {
        ...mapGetters(['colorRows', 'userHasProductAdmin'])
    },

    components: {
        ProductAdminPages,
        MainNavigation,
        VueGoodTable,
        ProductTypeDropdown,
        Search,
        ModalColorEdit,
        Notification,
        Dropdown,
        BasicLinks,
        ModalConfirmation
    }
}
</script>

<style lang="scss">
@import '../assets/sass/ui/tooltip.scss';
@import '../assets/sass/ui/vuetable.scss';
@import '../assets/sass/ui/loading.scss';
</style>

<style lang="scss" scoped>
@import '../assets/sass/toolkit.scss';
@import '../assets/sass/ui/input.scss';
@import '../assets/sass/ui/button.scss';
@import '../assets/sass/partials/dashboard-header.scss';
@import '../assets/sass/layout/list-page.scss';

.product-list-page {
    .dashboard-header__new {
        &--wide {
            flex-basis: 400px;
        }

        .btn-secondary {
            margin-left: 10px;
            width: 165px;
        }
    }

    .dashboard-header {
        .search-container {
            width: 100%;
            max-width: 640px;
        }

        .checkbox {
            margin-left: auto;
            margin-right: 32px;
        }

        .filter-component {
            margin-left: 15px;
        }
    }

    &__header-search-flex {
        display: flex;
        align-items: center;
        flex-basis: calc(100% - 70px);
    }

    &__header-controls {
        padding-right: 30px;
    }

    .list-column {
        font-weight: 500;
    }

    .filter-column {
        max-width: calc(100vh - 140px);
        overflow-x: hidden;
        overflow-y: auto;
    }
}
</style>
