<template>
    <div class="user-role-dropdown-component" :class="[{'from-bottom' : fromBottom}, {'is-open' : open}]">
        <ul>
            <li v-for="role in userRoles" :key="role.id">
                <button role="button" @click="selectUserRole(role)">{{role.name}}</button>
            </li>
        </ul>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdIcon } from 'vue-material/dist/components'

import { isInViewport } from '@/plugins/helpers'

Vue.use(MdIcon)

export default {
    name: 'UserRoleDropdown',
    props: ['open', 'userRoles'],

    data() {
        return {
            fromBottom: false
        }
    },

    watch: {
        open: {
            handler: function() {
                let thisElement = document.querySelector('.user-role-dropdown-component')
                this.fromBottom = !isInViewport(thisElement);
            }
        }
    },

    methods: {
        selectUserRole(payload) {
            this.$emit('selectedType', payload)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/button.scss';

.user-role-dropdown-component {
    position: absolute;
    top: 15px;
    left: 45px;
    width: 300px;
    padding: 0;
    border: 1px solid $color-gray-border;
    background: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;

    &.is-open {
        visibility: visible;
        opacity: 1;
    }

    &.from-bottom {
        top: auto;
        bottom: 0;
    }

    ul {
        list-style-type: none;

        li {
            padding: 5px 25px;

            &:hover {
                background: $color-gray-bg-2;
            }
        }

        button {
            font-size: 14px;
            width: 100%;
            padding: 7px 0;
            margin: 3px 0;
            text-align: left;
        }
    }
}
</style>
