<template>
    <div class="modal" :class="{ 'is-open': open }">
        <div class="modal-box dark">
            <div class="modal-box-header">
                <span class="title">{{ $t('settings.title') }}</span>
                <button type="button" @click="$emit('close')" class="btn btn-plain btn-plain-wicon">
                    <md-icon>close</md-icon>
                </button>
            </div>

            <div class="modal-box-body">
                <span class="label">{{ $t('settings.email_notifications.title') }}</span>

                <div class="toggle" v-for="item in userSettings.emailSettingLabels" :key="item.key">
                    <input type="checkbox" :value="item.key" :id="item.key" v-model="emailSettings">
                    <label :for="item.key">{{ item.label }}</label>
                </div>

                <template v-if="userHasOffers">
                    <span class="label label-prices">{{ $t('settings.prices.title') }}</span>
                    <div class="button-group">
                        <span class="label">{{ $t('settings.prices.field.price_display') }}</span>

                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : showNetPrice, 'btn-simple' : !showNetPrice}"
                            @click="showNetPrice = true"
                        >{{ $t('settings.prices.option.price_display.net') }}</button>
                        <button
                            type="button"
                            class="btn"
                            :class="{'btn-secondary' : !showNetPrice, 'btn-simple' : showNetPrice}"
                            @click="showNetPrice = false"
                        >{{ $t('settings.prices.option.price_display.gross') }}</button>
                    </div>
                </template>

                <div class="privacy-box">
                    <span class="label">{{ $t('settings.privacy.title') }}</span>

                    <div class="input-wrapper">
                        <div class="checkbox">
                            <input type="checkbox" id="privacy" v-model="privacy" @click="showConfirmPrivacy">
                            <label for="privacy">{{ $t('settings.privacy.checkbox_label') }}</label>
                        </div>

                        <md-icon
                            v-tooltip="{content: $t('settings.privacy.info'), placement: 'left', classes: 'tooltip-privacy'}">info_outline
                        </md-icon>
                    </div>

                    <router-link :to="{name: 'Privacy'}" target="_blank" class="btn btn-plain button-privacy">{{ $t('settings.privacy.anchor') }}</router-link>

                </div>

                <button class="btn btn-primary button-submit" type="button" @click="submitSettings">
                    {{ $t('settings.button.save') }}
                </button>
                <button class="btn btn-plain" type="button" @click="$emit('close')">
                    {{ $t('settings.button.cancel') }}
                </button>
            </div>
        </div>

        <div class="modal-layer"></div>

        <modal-confirmation
            :open="openConfirmationModal"
            :data="confirmationModalData"
            @accepted="openConfirmationModal = false"
            @close="resetPrivacyField"
        ></modal-confirmation>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { MdIcon } from 'vue-material/dist/components'
import VTooltip from 'v-tooltip'

import config from '../../plugins/config.js'
import axios from 'axios'

import ModalConfirmation from '../../components/modals/ModalConfirmation.vue'

Vue.use(MdIcon)
Vue.use(VTooltip)

export default {
    name: 'ModalProfileSettings',
    props: ['open'],

    data() {
        return {
            emailSettings: [],
            showNetPrice: true,
            privacy: true,
            openConfirmationModal: false,
            confirmationModalData: {
                acceptText: this.$t('settings.privacy.confirmation.ok'),
                cancelText: this.$t('settings.privacy.confirmation.cancel'),
                message: this.$t('settings.privacy.confirmation.question')
            }
        }
    },

    mounted() {
        this.$store.dispatch('FETCH_USER_SETTINGS')

        this.emailSettings = this.userSettings.emailSettings
        this.showNetPrice = this.userSettings.showNetPrice

        this.privacy = this.userSettings.privacy
    },

    watch: {
        'open': function() {
            this.emailSettings = this.userSettings.emailSettings
            this.showNetPrice = this.userSettings.showNetPrice
            this.privacy = this.userSettings.privacy
        }
    },

    methods: {
        submitSettings() {
            let data = {
                emailSettings: this.emailSettings,
                showNetPrice: this.showNetPrice,
                privacy: this.privacy
            }

            return axios.put('user-settings', data, config.AXIOS_DEFAULTS)
                .then(data => {
                    this.$store.commit('setUserSettings', data)
                    if(this.privacy === false) {
                        this.$store.commit('clearUser')
                        this.$router.push('/')
                    }
                    let successMessage = [this.$t('settings.notification')]
                    this.$store.commit('setNotification', successMessage)

                    this.$emit('close')
                })
        },
        labelCheck(field) {
            return !!this.userSettings.emailSettings.filter(item => item === field).length;
        },
        showConfirmPrivacy() {
            if (this.privacy == true) {
                this.openConfirmationModal = true
            }
        },
        resetPrivacyField() {
            this.privacy = true
            this.openConfirmationModal = false
        }
    },

    computed: {
        ...mapGetters(['userSettings', 'userHasOffers']),

        showNetPriceC() {
            return this.userSettings.showNetPrice
        }
    },

    components: { ModalConfirmation }
}
</script>

<style lang="scss" scoped>
@import '../../assets/sass/toolkit.scss';
@import '../../assets/sass/ui/input.scss';
@import '../../assets/sass/ui/button.scss';
@import '../../assets/sass/ui/modal.scss';

.modal {
    &-box {

        @media only screen and (max-width: 600px) {
            width: 95%!important;
        }

        &-body {
            .label {
                font-size: 15px;
                display: block;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 25px;

                &.label-prices {
                    margin-top: 35px;
                }
            }

            .toggle {
                display: block;
                margin-bottom: 15px;
            }

            .button-group {
                margin-bottom: 35px;

                .label {
                    display: inline-block;
                    font-size: 13px;
                    margin-right: 10px;
                }

                .btn {
                    &:nth-child(2) {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        margin-right: -3px;
                    }

                    &:nth-child(3) {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            .privacy-box {
                margin-bottom: 30px;

                .md-icon {
                    position: relative;
                    top: 4px;
                    font-size: 20px !important;
                }

                .button-privacy {
                    display: inline-block;
                    margin: 6px 0 0 29px;
                }
            }

            .button-submit {
                margin-right: 15px;
            }
        }
    }
}
</style>
<style lang="scss">
.tooltip-privacy {
    &::before,
    &::after {
        display: none;
    }
}
</style>
